import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    balanceContainer: {
        height: '100%',
        width: '100%',
        background: theme.palette.primary.heather,
        boxShadow: `0px 3px 1px -2px ${theme.palette.primary.boxShadow2Dark}, 0px 2px 2px ${theme.palette.primary.boxShadow2Dark}, 0px 1px 5px ${theme.palette.primary.boxShadow2Dark}`,
        borderRadius: '10px 50px 50px 10px',
        padding: '25px 43.5px 68px 42px',

        [theme.breakpoints.down('xs')]: {
            padding: '16px 0 39px 0',
            width: '100%',
            height: 'unset',
            background: theme.palette.primary.heather,
            boxShadow: 'none',
            borderRadius: '10px 10px 25px 25px',
        },
        '@media (min-width: 600px) and (max-width:1019px)': {
            padding: '17px 0 26px 0',
            height: 'unset',
            maxWidth: '100%',
            background: theme.palette.primary.heather,
            borderRadius: '10px',
        },
    },
    balanceText: {
        '&.MuiTypography-body1': {
            fontFamily: theme.typography.h3.fontFamily,
            fontStyle: 'normal',
            // fontWeight: '700',
            fontSize: theme.typography.h3.fontSize,
            lineHeight: theme.typography.h3.lineHeight,
            display: 'flex',
            alignItems: 'center',
            letterSpacing: '0.25px',
            color: theme.palette.primary.backgroundColor2,

            [theme.breakpoints.down('xs')]: {
                fontSize: '24px',
                // padding: '16px 0px 0px 0px',
                justifyContent: 'center',
                textAlign: 'center',
                color: theme.palette.primary.backgroundColor2,
            },
            '@media (min-width: 600px) and (max-width:1019px)': {
                fontSize: '24px',
                color: theme.palette.primary.backgroundColor2,
                justifyContent: 'center',
                textAlign: 'center',
                padding: '0',
            },
        },
    },
    myBalanceInfo: {
        marginTop: '-15px',
        '@media (max-width:1019px)': {
            display: 'flex',
            justifyContent: 'center',
        },
    },
    myBalanceBill: {
        fontFamily: theme.typography.h1.fontFamily,
        fontStyle: 'normal',
        // fontWeight: '700',
        fontSize: theme.largeFont.fontSize.xxxl,
        color: theme.palette.primary.backgroundColor2,

        marginTop: '65px',
        textShadow: `0px 0px 80px ${theme.palette.primary.backgroundColor2}, 0px 0px 40px ${theme.palette.primary.boxShadow3}`,
        [theme.breakpoints.down('xs')]: {
            fontFamily: theme.typography.h1.fontFamily,
            fontStyle: 'normal',
            // fontWeight: '700',
            fontSize: theme.largeFont.fontSize.xxxl,
            marginTop: '26px',
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.primary.backgroundColor2,
            textShadow: `0px 0px 68.2396px ${theme.palette.primary.backgroundColor2}, 0px 0px 34.1198px ${theme.palette.primary.boxShadow3}`,
        },
        '@media (min-width: 600px) and (max-width:1019px)': {
            fontFamily: theme.typography.h1.fontFamily,
            fontStyle: 'normal',
            // fontWeight: '700',
            fontSize: theme.largeFont.fontSize.xxl,

            display: 'flex',
            margin: '0 auto',
            color: theme.palette.primary.backgroundColor2,
            textShadow: `0px 0px 68.2396px ${theme.palette.primary.backgroundColor2}, 0px 0px 34.1198px ${theme.palette.primary.boxShadow3}`,
        },
    },
    myBill: {
        fontFamily: theme.typography.h1.fontFamily,
        fontStyle: 'normal',
        // fontWeight: '700',
        fontSize: theme.largeFont.fontSize.xxxl,
        color: theme.palette.primary.backgroundColor2,
        display: 'flex',
        flexDirection: 'row',
        textShadow: `0px 0px 80px ${theme.palette.primary.backgroundColor2}, 0px 0px 40px ${theme.palette.primary.boxShadow3}`,
        height: '93px',
        alignItems: 'center',

        '@media (max-width:599px)': {
            height: '56px',
            fontSize: theme.largeFont.fontSize.xxl,
        },
        '@media (min-width: 600px) and (max-width:1019px)': {
            fontFamily: theme.typography.h1.fontFamily,
            fontStyle: 'normal',
            // fontWeight: '700',
            fontSize: theme.largeFont.fontSize.xxl,
            display: 'flex',
            flexDirection: 'row',
            height: '56px',
            marginTop: '16px',
            color: theme.palette.primary.backgroundColor2,
            textShadow: `0px 0px 68.2396px ${theme.palette.primary.backgroundColor2}, 0px 0px 34.1198px ${theme.palette.primary.boxShadow3}`,
        },
    },
    myBillTest: {
        fontFamily: theme.typography.body2.fontFamily,
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: theme.largeFont.fontSize.xxl,
        color: theme.palette.primary.backgroundColor2,

        paddingLeft: '4px',
        height: '93px',
        display: 'flex',
        alignItems: 'center',
        '@media (max-width:599px)': {
            height: '56px',
            fontSize: theme.typography.h4.fontSize,
        },
        '@media (min-width: 600px) and (max-width:1019px)': {
            fontFamily: theme.typography.body2.fontFamily,
            fontStyle: 'normal',
            fontWeight: '600',
            fontSize: theme.typography.h4.fontSize,
            height: '56px',
            color: theme.palette.primary.backgroundColor2,

            paddingLeft: '0.5px',
        },
    },
    myBalanceText: {
        fontSize: theme.typography.overline.fontSize,
        fontFamily: theme.typography.body3Medium.fontFamily,
        color: theme.palette.primary.backgroundColor2,
        textAlign: 'left',
        // fontWeight: '600',
        opacity: '0.87',
        lineHeight: theme.typography.body3Medium.lineHeight,
        marginBottom: '34px',
        [theme.breakpoints.down('xs')]: {
            fontSize: theme.typography.tooltip.fontSize,
            fontFamily: theme.typography.tooltip.fontFamily,
            lineHeight: theme.typography.tooltip.lineHeight,
            color: theme.palette.primary.backgroundColor2,
            // fontWeight: '400',
            margin: '0 auto',
            textAlign: 'center',
        },
        '@media (min-width: 600px) and (max-width:1019px)': {
            padding: '0',
            fontSize: theme.typography.tooltip.fontSize,
            fontFamily: theme.typography.tooltip.fontFamily,
            lineHeight: theme.typography.tooltip.lineHeight,
            color: theme.palette.primary.backgroundColor2,
            // fontWeight: '400',
            margin: '0 auto',
            textAlign: 'center',
        },
    },
    myBalanceErrorText: {
        fontSize: theme.typography.overline.fontSize,
        fontFamily: theme.typography.body3Medium.fontFamily,
        color: theme.palette.primary.white,
        textAlign: 'left',
        // fontWeight: '600',
        marginTop: '13px',
        '&.MuiTypography-body1': {
            fontSize: theme.typography.overline.fontSize,
            fontFamily: `${theme.typography.body3Medium.fontFamily} !important`,
            color: theme.palette.primary.white,
            // fontWeight: '600',
            marginTop: '13px',
            [theme.breakpoints.down('xs')]: {
                textAlign: 'center',
            },
            '@media (min-width: 600px) and (max-width:1019px)': {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            },
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: theme.typography.tooltip.fontSize,
            fontFamily: theme.typography.body3Medium.fontFamily,
            lineHeight: theme.typography.body3Medium.lineHeight,
            color: theme.palette.primary.white,
            // fontWeight: '400',
            margin: '0 auto',
            textAlign: 'center',
            marginTop: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        '@media (min-width: 600px) and (max-width:1019px)': {
            fontSize: theme.typography.tooltip.fontSize,
            fontFamily: theme.typography.body3Medium.fontFamily,
            lineHeight: theme.typography.body3Medium.lineHeight,
            color: theme.typography.body3Medium.fontFamily,
            // fontWeight: '400',
            margin: '0 auto',
            textAlign: 'center',
            marginTop: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
    },
    makePayContainer: {
        width: '175px',
        // background: '#D111C9',
        // boxShadow: '0px 4px 45px -9px ${theme.palette.primary.boxShadow1Darkest}',
        // borderRadius: '4100px',
        height: '40px',
        alignItems: 'center',
        // margin: '0px 0px 0px 41px',
        '@media (max-width:1019px)': {
            margin: '0 auto',
            marginTop: '28px',
        },
    },
    makePayContainerDisabled: {
        width: '175px',
        background: '#ECECEC',
        boxShadow: `0px 4px 45px -9px ${theme.palette.primary.boxShadow1Darkest}`,
        borderRadius: '4100px',
        height: '40px',
        alignItems: 'center',
        // margin: '0px 0px 0px 41px',
        '@media (max-width:1019px)': {
            margin: '0 auto',
            marginTop: '28px',
        },
    },

    makePayMobileContainer: {
        width: '143px',
        background: theme.palette.primary.sunset,
        boxShadow: `0px 4px 45px -9px ${theme.palette.primary.boxShadow1Darkest}`,
        borderRadius: '4100px',
        margin: '0 auto',
        marginTop: '24px',
        lineHeight: '24px',
    },
    makePayMobileContainerDisabled: {
        width: '143px',
        background: '#ECECEC',
        boxShadow: `0px 4px 45px -9px ${theme.palette.primary.boxShadow1Darkest}`,
        borderRadius: '4100px',
        margin: '0 auto',
        marginTop: '24px',
        lineHeight: '24px',
    },
    paymentContent: {
        // width: '127px',
        fontSize: theme.typography.body1.fontSize,
        textTransform: 'unset',
        fontFamily: theme.typography.body1.fontFamily,
        // lineHeight:'24px',
        color: theme.palette.primary.white,
        margin: '0 auto',
        fontWeight: '700',
        '&:disabled': {
            color: theme.palette.primary.gray550,
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: theme.typography.subtitle2.fontSize,
            textTransform: 'unset',
            lineHeight: '22px',
            color: theme.palette.primary.white,
            fontWeight: '700',
            marginTop: '2px',
        },
        '@media (min-width: 600px) and (max-width:1019px)': {
            fontSize: theme.typography.subtitle2.fontSize,
            lineHeight: '22px',
            width: 'fit-content',
            marginTop: '2px',
        },
    },

    parentBalanceContainer: {
        height: '100%',
        [theme.breakpoints.down('xs')]: {
            backgroundColor: theme.palette.primary.white,
            borderRadius: '13px 13px 0px 0px',
        },
    },
}));

export default useStyles;
