import React, { useState, Fragment, useEffect } from 'react';
import { Grid, TextField, Button, Tooltip, InputAdornment, IconButton } from '@material-ui/core';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import errormesages from '../my-eir-register/errormessages';
import HelpIcon from '@material-ui/icons/Help';
import { Controller, useForm, ErrorMessage } from 'react-hook-form';
import ENDPOINT from '../graphql/GraphQLConstants';
import { useApolloClient } from '@apollo/client';
import { MY_EIR_CONST } from '../myeirconstant/myEirConstant';

const theme = createMuiTheme({
    typography: {
        textTransform: 'none',
        textAlign: 'left',
        letterSpacing: '0px',
        fontFamily: 'gt-walsheim-regular ',
        opacity: 1,
        fontSize: '16px',
    },
    palette: {
        primary: {
            main: '#892BA0 !important',
        },
    },
    overrides: {
        MuiInput: {
            input: {
                '&::placeholder': {
                    color: 'gray',
                },
                color: 'white',
            },
        },
        MuiFormControlLabel: {
            label: {
                '@media (max-width: 1024px)': {
                    fontSize: '14px',
                },
            },
        },
        MuiFormLabel: {
            asterisk: {
                color: '#f44336',
            },
        },

        MuiTextField: {
            root: {
                fontSize: '16px',
                color: 'black',
                '@media (max-width: 1024px)': {
                    fontSize: '14px',
                },
            },
        },

        MuiInputLabel: {
            root: {
                color: 'black',
                fontSize: '16px',
                '@media (min-width:768px) and (max-width: 1024px)': {
                    fontSize: '14px',
                },
                '@media (min-width:322px) and (max-width: 415px)': {
                    fontSize: '12px',
                },
                '@media (min-width:281px) and (max-width: 321px)': {
                    fontSize: '10px',
                },
                '@media (min-width:279px) and (max-width: 280px)': {
                    fontSize: '8px',
                },
            },
            shrink: {
                fontSize: '16px',
                '@media (min-width:320px) and (max-width: 541px)': {
                    fontSize: '14px',
                },
                '@media (min-width:280px) and (max-width: 321px)': {
                    fontSize: '12px',
                },
            },
        },
    },
});
function MyEirCreatePasswordForm(props) {
    const { validation } = errormesages;
    // const { token } = useParams();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [PasswordError, setPasswordError] = useState(false);
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);
    const [confirmPasswordMsg, setConfirmPasswordMsg] = useState(false);
    const graphql = useApolloClient();
    const { register, control, errors, handleSubmit, setValue, clearError } = useForm({
        mode: 'onChange',
    });
    const [tokenValue, setTokenValue] = useState('');
    useEffect(() => {
        let searchParams = new URLSearchParams(window.location.search);
        setTokenValue(searchParams.get('token'));

        if (
            searchParams.get('token') == '' ||
            searchParams.get('token') == null ||
            searchParams.get('token') == undefined
        ) {
            sessionStorage.clear();
            window.location.href = MY_EIR_CONST.url.REDIRECT_URL;
        }
    }, []);
    const handlePasswordChange = event => {
        const passwordValue = event.target.value;
        setPassword(passwordValue);
        var regex = /^(?=.*\d)(?=.*[A-Z]).{8,}$/;
        if (errors.password) {
            setPasswordError(false);
        }
        if (regex.test(passwordValue) == false) {
            setPasswordError(true);
            if (errors.password) {
                clearError('password');
            }
        } else {
            setPasswordError(false);
        }
    };
    const handleConfirmPasswordChange = event => {
        const confirmpasswordValue = event.target.value;
        if (errors.confirmPassword) {
            setConfirmPasswordError(false);
        }
        if (confirmpasswordValue == password) {
            setConfirmPasswordError(false);
        } else {
            setConfirmPasswordError(true);
        }
    };

    const onSubmit = async data => {
        console.log(data);
        // console.log('ttttttt', { token });
        try {
            const res = await graphql.mutate({
                mutation: ENDPOINT.SET_PASSWORD,
                variables: {
                    password: data.password,
                    token: tokenValue,
                },
            });

            if (res.data.setPassword) {
                setConfirmPasswordMsg(true);
            }
        } catch (err) {}
    };

    return (
        <Fragment>
            {!confirmPasswordMsg && (
                <Fragment>
                    {props.pwd == 'createPWD' && (
                        <h1 className='paddingTop'>Create your password</h1>
                    )}
                    {props.pwd != 'createPWD' && (
                        <h1 className='paddingTop'>Change/Reset Password</h1>
                    )}
                </Fragment>
            )}
            <form onSubmit={handleSubmit(onSubmit)} className='createForm'>
                {!confirmPasswordMsg && (
                    <div>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12}>
                                <div>
                                    <Controller
                                        as={<TextField type='password' />}
                                        onChange={([event]) => {
                                            handlePasswordChange(event);
                                            return event.target.value;
                                        }}
                                        required
                                        control={control}
                                        fullWidth
                                        id='password'
                                        name='password'
                                        label='Your password'
                                        variant='outlined'
                                        size='small'
                                        defaultValue={password}
                                        rules={{
                                            required: true,
                                        }}
                                    />
                                </div>
                                {errors.password && errors.password.type === 'required' && (
                                    <span className='errorMessage'>Please enter password</span>
                                )}
                                {PasswordError && !errors.password && (
                                    <span className='errorMessage'>
                                        Please enter a valid password
                                    </span>
                                )}
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12}>
                                <div>
                                    <Controller
                                        as={<TextField type='password' />}
                                        onChange={([event]) => {
                                            handleConfirmPasswordChange(event);
                                            return event.target.value;
                                        }}
                                        required
                                        control={control}
                                        fullWidth
                                        id='passconfirmPasswordword'
                                        name='confirmPassword'
                                        label='Confirm your password'
                                        variant='outlined'
                                        size='small'
                                        defaultValue={confirmPassword}
                                        rules={{
                                            required: true,
                                        }}
                                    />
                                </div>
                                {errors.confirmPassword &&
                                    errors.confirmPassword.type === 'required' && (
                                        <span className='errorMessage'>Please enter password</span>
                                    )}
                                {confirmPasswordError && !errors.confirmPassword && (
                                    <span className='errorMessage'>
                                        Please make sure the Passwords match.
                                    </span>
                                )}
                            </Grid>
                        </Grid>
                        <p className='text-left' style={{ marginTop: '16px' }}>
                            Your password must be at least 8 characters long. For security reasons
                            it must include at least one capital letter (R), one lowercase letter
                            (p) and one number (7).
                        </p>

                        <Grid item xs={12} sm={12} md={12}>
                            <div className='mobileFloat'>
                                <Button
                                    onClick={handleSubmit(onSubmit)}
                                    type='submit'
                                    className='emailBtn savePwd'
                                >
                                    Save password
                                </Button>
                            </div>
                        </Grid>
                    </div>
                )}
                {confirmPasswordMsg && (
                    <Fragment>
                        <h1 style={{ marginTop: '30px' }}>Good news!</h1>
                        <p className='notification' style={{ textAlign: 'center' }}>
                            Password updated successfully.
                        </p>

                        <div className='forRegform'>
                            <a className='link' href='/prepay/login'>
                                <span className='arrow-right'></span>
                                Back to log in
                            </a>
                        </div>
                    </Fragment>
                )}
            </form>
        </Fragment>
    );
}

export default MyEirCreatePasswordForm;
