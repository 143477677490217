import { makeStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles(theme => ({
    title: {
        fontFamily: theme.typography.body2.fontFamily,
        fontStyle: 'normal',
        // fontWeight: 400,
        fontSize: theme.typography.h4.fontSize,
        lineHeight: '18px',
        textAlign: 'center',
        marginBottom: '10px',
        color: theme.palette.primary.heather,
    },
    description: {
        fontFamily: theme.typography.body2.fontFamily,
        fontStyle: 'normal',
        fontWeight: ' 400',
        fontSize: theme?.typography?.inputLabel?.fontSize,
        lineHeight: '20px',
        textAlign: 'center',
        letterSpacing: '0.0691456px',
        color: theme.palette.primary.heather,
        width: '371px',
        height: '40px',
        margin: 'auto',
        '@media (min-width: 600px) and (max-width:1019px)': {
            marginTop: '19.5px',
        },
        [theme.breakpoints.down('sm')]: {
            width: '71% ',
            height: '100px',
        },
    },
    container: {
        // position: 'absolute',
        width: '100%',
        height: '100%',
        // margin: '32px 32px 32px 0px',
        background: theme.palette.primary.backgroundColor2,
        borderRadius: '10px',

        [theme.breakpoints.down('sm')]: {},
        '@media (min-width: 600px) and (max-width:1019px)': {},
    },
    imgIcon: {
        textAlign: 'center',
        // marginTop: '45px',
        marginBottom: '18px',
    },
    emptyIcon: {
        marginTop: '45px',
    },
    containerBox: {
        margin: '0 auto',
        margin: '40px 48.5px 0px 50.5px',
        [theme.breakpoints.down('xs')]: {
            padding: '0',
            margin: '24px 15px 0px 16px',
        },
        '@media (min-width: 600px) and (max-width:1019px)': {
            padding: '29px 24px 0px 29px',
            margin: '0 auto',
            maxWidth: '406px',
        },
    },
    inactiveBillUserContainer: {
        Width: '438px',
        height: '100%',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            height: 'max-content',
            marginTop: '0px',
        },
        '@media (min-width: 600px) and (max-width:1019px)': {
            marginTop: '2px',
        },
    },

    inactiveBill: {
        Width: '438px',
        height: '210px',
        marginTop: '-14px',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            height: 'max-content',
            marginTop: '0px',
        },
        '@media (min-width: 600px) and (max-width:1019px)': {
            marginTop: '2px',
        },
    },

    emptyIconBills: {
        height: '199px',
        marginTop: '-32px',
    },
    titleBills: {
        fontFamily: theme.typography.body2.fontFamily,
        fontStyle: 'normal',
        // fontWeight: 600,
        fontSize: theme.typography.body2.fontSize,
        marginTop: ' -46px',
        textAlign: 'center',
        color: theme.palette.primary.heather,
    },

    descriptionBills: {
        fontFamily: theme.typography.body2.fontFamily,
        fontStyle: 'normal',
        // fontWeight: ' 400',
        fontSize: theme?.typography?.tooltip?.fontSize,
        lineHeight: theme?.typography?.tooltip?.lineHeight,
        marginTop: '7.5px',
        textAlign: 'center',
        letterSpacing: '0.0691456px',
        color: theme.palette.primary.heather,
        '@media (min-width: 600px) and (max-width:1019px)': {
            marginTop: '10px',
        },
    },
}));
