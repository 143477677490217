import React from 'react';
import { Box, Typography } from '@material-ui/core';
import useStyles from './myServicesStyles';
import { useWindowDimension } from '../../../hooks/windowDimensions';
import Offers from '../offers-section/Offers';

const MyEirServiceBannerImg = ({
    banner,
    numCards,
    miniText,
    name,
    price,
    validityText,
    background,
    subscriptionId,
    propositionId,
    attributes,
    serviceGroup,
    modalImage,
    servicesOffer,
    adobeOffer_tariff_code,
    contractType,
    hasNoOffer,
    onOfferPage,
    finalArr,
}) => {
    const classes = useStyles();
    const [width, height] = useWindowDimension();
    const strippedText = new DOMParser().parseFromString(name, 'text/html').body.innerText;
    return (
        <Box className={banner ? classes.mainBanner : classes.none}>
            {banner && (
                <>
                    <Box
                        component='img'
                        src={banner}
                        alt='img'
                        style={{
                            borderRadius:
                                onOfferPage === true && width <= 1020
                                    ? '10px'
                                    : numCards === 1
                                    ? '0 10px 10px 0'
                                    : '0px 0px 10px 10px',
                        }}
                        className={
                            numCards === 1
                                ? width < 600
                                    ? classes.imagebanner
                                    : classes.singleBanner
                                : classes.imagebanner
                        }
                    />
                    {!hasNoOffer && (
                        <Offers
                            validityText={validityText}
                            miniText={miniText}
                            name={strippedText}
                            price={price}
                            background={background}
                            subscriptionId={subscriptionId}
                            propositionId={propositionId}
                            attributes={attributes}
                            serviceGroup={serviceGroup}
                            modalImage={modalImage}
                            onOfferPage={onOfferPage}
                            adobeOffer_tariff_code={adobeOffer_tariff_code}
                            contractType={contractType}
                            finalArr={finalArr}
                        />
                    )}
                </>
            )}
        </Box>
    );
};
export default MyEirServiceBannerImg;
