import React, { useEffect, useState, useRef } from 'react';
import { useLoginFormStyle } from '../css/useLoginFormStyle';
import MyEirLoginHeader from './MyEirLoginHeader';
import MyEirLoginFooter from './MyEirLoginFooter';
import { useHistory } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useApolloClient } from '@apollo/client';
import MyEirEmailConfirmationModal from '../../sharedcomponents/MyEirPostpayRegistration/MyEirEmailConfirmationModal';
import TerminatedTile from '../reusable-components/TerminatedTile';
import MyEirErrorHandle from '../eir-porting/MyEirErrorHandle';
import useRecaptchaV3 from '../../hooks/useRecaptchaV3';
import { handleSubmitLoginOperation, submitForgotResetPassword } from './loginOperation.util';
import ReCAPTCHA from 'react-google-recaptcha';

const MyEirLoginOperation = props => {
    const { executeRecaptcha } = useRecaptchaV3('login');
    const [userName, setUserName] = useState('');
    const [userPassword, setUserPassword] = useState('');
    const [passwordVisibility, setPasswordVisibility] = useState(false);
    const [registerToggle, setRegisterToggle] = useState(false);
    const [enableSubmit, setEnableSubmit] = useState(false);
    const [enableLogin, setEnableLogin] = useState(false);
    const validUserName = /^([a-zA-Z0-9_\-\.+]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
    const validPassword = /^.{6,}$/;
    const styleClass = useLoginFormStyle();
    const graphql = useApolloClient();
    const history = useHistory();
    const recaptchaRef = useRef(null);
    const [invalidText, setInvalidText] = useState('');
    const [captchaV2, setCaptchaV2] = useState('');
    const [captchaV2Error, setCaptchaV2Error] = useState(false);

    // error state variables
    const [waitingApiResponse, setWaitingApiResponse] = useState(false);
    const [openError, setOpenError] = useState(false);
    const [invalidError, setInvalidError] = useState(false);
    const [isOpen2, setIsopen2] = useState(false);
    const [isClicked, setIsClicked] = useState(false);

    const toggleRegister = () => {
        setRegisterToggle(!registerToggle);
    };

    function handleUserVal(setname) {
        setUserName(setname);
    }
    async function handleSubmitLogin(enteredName, userPassword) {
        const recaptchaV3Token = await executeRecaptcha();
        const submitAction = await handleSubmitLoginOperation({
            graphql,
            enteredName,
            userPassword,
            recaptchaV3Token,
            setWaitingApiResponse,
            handleUserVal,
            setInvalidError,
            invalidError,
            toggleRegister,
            setOpenError,
            openError,
            setLoginScreen: props.setLoginScreen,
            setInvalidText,
            recaptchaRef,
            captchaV2,
            setCaptchaV2Error,
            captchaV2Error,
        });
    }

    function handleUserPassword(event) {
        setUserPassword(event.target.value);
    }

    function handlePasswordVisibility(event) {
        setPasswordVisibility(!passwordVisibility);
    }

    function validateUserName(name) {
        if (validUserName.test(name)) {
            setEnableSubmit(true);
        } else {
            setEnableSubmit(false);
        }
    }

    function handleUserName(event) {
        const name = event.target.value;
        setUserName(name);
        setInvalidError(false);
        validateUserName(name);
    }
    function handleSubmitUsername() {
        handleSubmitLogin(userName, userPassword);
    }
    function handleRegister() {
        history.push('/register');
    }

    function handleForgotUsername() {
        history.push('/forgot-email');
    }

    function handlePasswordVisibility(event) {
        setPasswordVisibility(!passwordVisibility);
    }
    function handleUserPassword(event) {
        const password = event.target.value;
        setUserPassword(password);
        setInvalidError(false);
        if (validPassword.test(password)) {
            setEnableLogin(true);
        } else {
            setEnableLogin(false);
        }
    }

    const toggle2 = () => {
        if (userName == '') {
            setEnableSubmit(false);
        } else {
            setIsopen2(!isOpen2);
        }
    };
    const toggleError = () => {
        setOpenError(!openError);
    };
    const onSubmit = async data => {
        // console.log('forgotten:', data);
        setIsClicked(true);
        const submitReset = await submitForgotResetPassword({
            setWaitingApiResponse,
            graphql,
            userName,
            toggle2,
            toggleError,
        });
    };

    const handleKeyDown = e => {
        if (e.key === 'Enter') {
            handleSubmitLogin(userName, userPassword);
        }
    };

    function handleOnChange(value) {
        if (value == null) {
            setEnableLogin(false);
            // setEnableSubmit(false);
            setCaptchaV2Error(!captchaV2Error);
        } else {
            setCaptchaV2Error(!captchaV2Error);
            setCaptchaV2(value);
            setTimeout(() => {
                setEnableLogin(true);
                setEnableSubmit(true);
                setInvalidError(false);
            }, 1000);
        }
    }

    useEffect(() => {
        if (userName) {
            setUserName(userName);
            validateUserName(userName);
        }
    }, []);

    return (
        <div className={styleClass.loginContainer}>
            <form
                onSubmit={e => {
                    e.preventDefault();
                }}
                className={styleClass.cardContainer}
            >
                <MyEirLoginHeader headerText='Log in' flag='login' />
                <div className={styleClass.bodyContainer}>
                    <div className='text-field'>
                        <input
                            className={
                                'input-text' +
                                (isClicked && userName == ''
                                    ? ' text-entered-invalid'
                                    : !userName
                                    ? ' text-empty'
                                    : enableSubmit
                                    ? ' text-entered'
                                    : ' text-entered-invalid')
                            }
                            type='email'
                            id='email'
                            placeholder='Enter email'
                            value={userName}
                            onKeyDown={handleKeyDown}
                            autoComplete='username'
                            onChange={handleUserName}
                            disabled={waitingApiResponse}
                        />

                        {isClicked && userName == '' ? (
                            <Typography
                                variant='inputLabel'
                                className='invalid-email-format'
                                style={{ textAlign: 'left', fontSize: '14px', lineHeight: '20px' }}
                            >
                                Enter your email address and we'll send you a reset password link
                                email.
                            </Typography>
                        ) : (
                            !enableSubmit &&
                            userName != '' &&
                            !captchaV2Error && (
                                <Typography variant='inputLabel' className='invalid-email-format'>
                                    Please enter a valid email ID
                                </Typography>
                            )
                        )}

                        <Typography
                            variant='body2'
                            className='forgotten-input-text'
                            onClick={handleForgotUsername}
                            style={{ marginBottom: '32px' }}
                        >
                            Forgotten your email?
                        </Typography>
                        {!userName ? (
                            <></>
                        ) : (
                            <Typography
                                variant='inputLabel'
                                className={
                                    'text-field-name' +
                                    (enableSubmit ? '' : ' text-field-name-invalid')
                                }
                            >
                                Email
                            </Typography>
                        )}
                    </div>

                    {/*password field starts--*/}
                    <div className='text-field'>
                        {passwordVisibility ? (
                            <VisibilityOffIcon
                                className='visibility-off'
                                onClick={handlePasswordVisibility}
                            />
                        ) : (
                            <VisibilityIcon
                                className='visibility-on'
                                onClick={handlePasswordVisibility}
                            />
                        )}
                        <input
                            className={
                                'input-text visibility-icon-padding' +
                                (!userPassword ? ' text-empty' : 'text-entered')
                            }
                            type={passwordVisibility ? 'text' : 'password'}
                            placeholder='Enter password'
                            value={userPassword}
                            onKeyDown={handleKeyDown}
                            autoComplete='current-password'
                            onChange={handleUserPassword}
                            disabled={waitingApiResponse}
                        />

                        <div className='error-and-reset'>
                            <Typography
                                variant='body2'
                                className='forgotten-input-text'
                                onClick={onSubmit}
                            >
                                Forgotten your password?
                            </Typography>
                        </div>

                        {!userPassword ? (
                            <></>
                        ) : (
                            <Typography variant='inputLabel' className={'text-field-name'}>
                                Password
                            </Typography>
                        )}
                    </div>
                    {/* password field end-- */}

                    {/**Recaptcha v2 incase v3 failed */}
                    {invalidText === 'Invalid reCAPTCHA!' && (
                        <div className={styleClass.reCaptchaError}>
                            <div className={styleClass.reCaptcha}>
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    className={'reCaptchaRobot'}
                                    sitekey='6LfZsRYTAAAAAJMBqawqK3sy7NmHDC8M9rKdkX36'
                                    render='explicit'
                                    onChange={handleOnChange}
                                />
                            </div>
                        </div>
                    )}
                    {invalidError && !captchaV2Error && invalidText != 'Invalid reCAPTCHA!' && (
                        <div className='terminateTile'>
                            <TerminatedTile terminatedCopy={invalidText} />
                        </div>
                    )}
                </div>

                <MyEirLoginFooter
                    button1Text='Submit'
                    button1Enabled={
                        captchaV2 != ''
                            ? !captchaV2Error &&
                              enableSubmit &&
                              enableLogin &&
                              !waitingApiResponse &&
                              !invalidError
                            : !captchaV2Error &&
                              enableSubmit &&
                              enableLogin &&
                              !waitingApiResponse &&
                              !invalidError
                    }
                    handleButton1Click={handleSubmitUsername}
                    button2Text='Register'
                    handleButton2Click={handleRegister}
                />
                <MyEirEmailConfirmationModal
                    // handleNext1={handleNextFlow}
                    handleNext1=''
                    registeredEmail={userName}
                    toggle={toggle2}
                    isOpen={isOpen2}
                    heading={'Reset Password'}
                    subHeading={'We’ve just sent you an email.'}
                    text={
                        'Please check your email and follow the instructions to reset your password'
                    }
                    flag='security'
                />
                {openError ? <MyEirErrorHandle open={openError} setOpen={toggleError} /> : ''}
            </form>
        </div>
    );
};

export default MyEirLoginOperation;
