import React, { useState, Fragment, useEffect, useRef } from 'react';
import { useRegisterFormStyle } from './useRegisterFormStyle';
import { usePrepayFormStyle } from '../../sharedcomponents/css/usePrepayFormStyle';
import errormesages from './errormessages';
import HelpIcon from '@material-ui/icons/Help';
import MaskedInput from 'react-text-mask';
import MomentUtils from '@date-io/moment';
import moment from 'moment';

import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
// import { ErrorMessage } from '@hookform/error-message';
import Inputmask from 'inputmask';
import { useFormContext, Controller, useForm, ErrorMessage } from 'react-hook-form';
import {
    Grid,
    TextField,
    FormControl,
    InputLabel,
    FormControlLabel,
    Checkbox,
    FormLabel,
    Box,
    InputAdornment,
    Tooltip,
    IconButton,
    Container,
    Divider,
    Input,
} from '@material-ui/core';

import SmartphoneOutlinedIcon from '@material-ui/icons/SmartphoneOutlined';
function MyEirCustomerDetails(props) {
    console.log('Customer Details props', props);
    const defaultValue = '';
    const DATE_FORMAT = 'DD/MM/YYYY';
    // const classes = useRegisterFormStyle();
    const classes = usePrepayFormStyle();
    const { validation } = errormesages;
    const [allowEmail, setAllowEmail] = useState(false);
    const [birthDate, setbirthDate] = useState('');
    const [dobError, setDobError] = useState(false);
    const firstNameInputRef = useRef();
    const lastNameInputRef = useRef();
    const mobileNumberInputRef = useRef();
    const birthDateInputRef = useRef();
    const emailAddressInputRef = useRef();
    const isDateValid = dateOfBirth => {
        var date = moment(dateOfBirth, DATE_FORMAT, true);
        setDobError(false);
        //if date invalid , in future then set error
        if (!date.isValid()) {
            return false;
        } else if (date.isAfter()) {
            return false;
        } else {
            // check if 18 yr old
            const dateDiff = moment().diff(date, 'years');
            if (dateDiff < 18) {
                setDobError(true);
                return false;
            }
        }
        return true;
    };
    const { register, control, errors, setValue } = useFormContext();

    useEffect(() => {
        Inputmask().mask(document.querySelectorAll('#birthDate'));
        var s = document.querySelector('#birthDate');
    }, [birthDate]);
    const onSubmit = data => {
        setMobileNumber(data.MobileNumber);
    };

    return (
        <Fragment>
            <Container>
                <div className='accountAddress'>
                    <p className={classes.customerDetailsHeader}>Customer details</p>
                </div>
                <Grid className={classes.customerDetailsLabel} container spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <Controller
                            as={
                                <TextField
                                    className={classes.textField}
                                    inputRef={firstNameInputRef}
                                />
                            }
                            control={control}
                            fullWidth
                            id='firstName'
                            name='firstName'
                            label='First name'
                            variant='outlined'
                            size='small'
                            required
                            onFocus={() => {
                                firstNameInputRef.current.focus();
                            }}
                            defaultValue={defaultValue}
                            rules={{
                                required: { value: true, message: validation.firstnameRequired },
                                minLength: { value: 2, message: validation.nameMinLength },
                                maxLength: { value: 100, message: validation.nameMaxLength },
                                pattern: {
                                    value: /^([^0-9]*)$/,
                                    message: validation.firstNamePattern,
                                },
                            }}
                        />

                        <ErrorMessage errors={errors} name='firstName'>
                            {({ message }) => <p className={classes.errorMessage}>{message}</p>}
                        </ErrorMessage>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <Controller
                            as={
                                <TextField
                                    className={classes.textField}
                                    inputRef={lastNameInputRef}
                                />
                            }
                            control={control}
                            fullWidth
                            id='lastName'
                            name='lastName'
                            label='Last name'
                            variant='outlined'
                            size='small'
                            defaultValue={defaultValue}
                            required
                            onFocus={() => {
                                lastNameInputRef.current.focus();
                            }}
                            rules={{
                                required: { value: true, message: validation.lastNameRequired },
                                minLength: { value: 2, message: validation.nameMinLength },
                                maxLength: { value: 100, message: validation.nameMaxLength },
                                pattern: {
                                    value: /^([^0-9]*)$/,
                                    message: validation.lastNamePattern,
                                },
                            }}
                        />
                        <ErrorMessage errors={errors} name='lastName'>
                            {({ message }) => <p className={classes.errorMessage}>{message}</p>}
                        </ErrorMessage>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Controller
                            as={
                                <TextField
                                    className={classes.textField}
                                    inputRef={emailAddressInputRef}
                                />
                            }
                            control={control}
                            fullWidth
                            id='emailAddress'
                            name='emailAddress'
                            label='Email address'
                            variant='outlined'
                            size='small'
                            defaultValue={defaultValue}
                            onFocus={() => {
                                emailAddressInputRef.current.focus();
                            }}
                            required
                            // InputProps={{
                            //     endAdornment: (
                            //         <InputAdornment position='end'>
                            //             <Tooltip
                            //                 enterTouchDelay={20}
                            //                 leaveTouchDelay={50000}
                            //                 title={
                            //                     'This eamil address will be used as your username.'
                            //                 }
                            //                 size='small'
                            //             >
                            //                 <IconButton className='iconUsage' size='small'>
                            //                     <HelpIcon />
                            //                 </IconButton>
                            //             </Tooltip>
                            //         </InputAdornment>
                            //     ),
                            // }}
                            rules={{
                                required: { value: true, message: validation.emailAddressError },
                                pattern: {
                                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                    message: validation.emailAddressError,
                                },
                            }}
                        />
                        {props.emailVerified == '' ? (
                            <></>
                        ) : (
                            <p className={classes.errorMessage}>{props.emailVerified}</p>
                        )}
                        <ErrorMessage errors={errors} name='emailAddress'>
                            {({ message }) => <p className={classes.errorMessage}>{message}</p>}

                            {/* {props.emailVerified} */}
                        </ErrorMessage>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Controller
                            as={
                                <TextField
                                    className={classes.textField}
                                    inputRef={mobileNumberInputRef}
                                />
                            }
                            control={control}
                            fullWidth
                            id='mobileNumber'
                            name='mobileNumber'
                            label='Contact mobile number'
                            variant='outlined'
                            size='small'
                            required
                            onFocus={() => {
                                mobileNumberInputRef.current.focus();
                            }}
                            defaultValue={defaultValue}
                            // InputProps={{
                            //     endAdornment: (
                            //         <InputAdornment position='end'>
                            //             <Tooltip
                            //                 enterTouchDelay={20}
                            //                 leaveTouchDelay={50000}
                            //                 title={
                            //                     'This mobile number will be used in case we need to contact you regarding your delivery.'
                            //                 }
                            //                 size='small'
                            //             >
                            //                 <IconButton className='iconUsage' size='small'>
                            //                     <HelpIcon />
                            //                 </IconButton>
                            //             </Tooltip>
                            //         </InputAdornment>
                            //     ),
                            // }}
                            rules={{
                                required: { value: true, message: validation.mobileNumberRequired },
                                pattern: {
                                    value: /^08[0-9]{8}$/,
                                    message: validation.mobileNumberPattern,
                                },
                            }}
                        />
                        <ErrorMessage errors={errors} name='mobileNumber'>
                            {({ message }) => <p className={classes.errorMessage}>{message}</p>}
                        </ErrorMessage>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        {/* <MuiThemeProvider theme={customTheme}> */}
                        <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                            <Controller
                                // as={
                                //     <TextField
                                //         className={classes.textField}
                                //         inputRef={birthDateInputRef}
                                //         InputProps={{
                                //             classes: { input: classes.input },
                                //         }}
                                //         inputProps={{
                                //             'data-inputmask-alias': 'datetime',
                                //             'data-inputmask-inputformat': 'dd/mm/yyyy',
                                //             inputMode: 'numeric',
                                //         }}
                                //     />
                                // }
                                as={
                                    <KeyboardDatePicker
                                        id='date-picker-dialog'
                                        label='Date picker dialog'
                                        // format="MM/dd/yyyy"
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        helperText={''}
                                        className={classes.textField}
                                        inputRef={birthDateInputRef}
                                        inputProps={{ readOnly: false }}
                                    />
                                }
                                // theme={customTheme}
                                defaultValue={null}
                                fullWidth
                                inputVariant='outlined'
                                format='DD/MM/YYYY'
                                autoOk
                                variant='outlined'
                                control={control}
                                id='birthDate'
                                label='Date of birth'
                                name='birthDate'
                                size='small'
                                // defaultValue={defaultValue}
                                placeholder='DD/MM/YYYY'
                                required
                                onFocus={() => {
                                    birthDateInputRef.current.focus();
                                }}
                                rules={{
                                    required: { value: true, message: validation.birthDateError },
                                    validate: value => isDateValid(value),
                                }}
                            />

                            <ErrorMessage errors={errors} name='birthDate'>
                                {({ message }) => <p className={classes.errorMessage}>{message}</p>}
                            </ErrorMessage>
                            {errors.birthDate &&
                                errors.birthDate.type === 'validate' &&
                                !dobError && (
                                    <span className={classes.errorMessage}>
                                        {validation.birthDateError}
                                    </span>
                                )}

                            {dobError && (
                                <span className={classes.errorMessage}>
                                    {validation.birthDateAgeError}
                                </span>
                            )}
                        </MuiPickersUtilsProvider>
                        {/* </MuiThemeProvider> */}
                    </Grid>
                </Grid>
            </Container>
        </Fragment>
    );
}

export default MyEirCustomerDetails;
