import React, { useEffect, useState } from 'react';
import { commonRegisterStyles } from '../css/myEirRegistrationStyles/commonRegisterStyles';
import { useRegisterEmailStyle } from '../css/myEirRegistrationStyles/useRegisterEmailStyle';
import {
    Grid,
    FormControl,
    InputLabel,
    OutlinedInput,
    TextField,
    Typography,
} from '@material-ui/core';
import MyEirRegisterHeader from './reusable/MyEirRegisterHeader';
import MyEirRegisterFooter from './reusable/MyEirRegisterFooter';
import MyEirEmailConfirmationModal from './MyEirEmailConfirmationModal';
import { useApolloClient } from '@apollo/client';
import ENDPOINT from '../../mobilecomponents/graphql/GraphQLConstants';
import MyEirRegistrationErrorsPopup from './MyEirRegistrationErrorsPopup';

const MyEirRegisterEmail = props => {
    const [accountNumber, setAccountNumber] = useState('');
    const [email, setEmail] = useState('');
    const [validEmail, setValidEmail] = useState(false);
    const [confirmEmail, setConfirmEmail] = useState('');
    const [validConfirmEmail, setValidConfirmEmail] = useState(false);
    const [emailsMatch, setEmailsMatch] = useState(false);
    const [showEmailLabel, setShowEmailLabel] = useState(false);
    const [showConfirmEmailLabel, setShowConfirmEmailLabel] = useState(false);
    const [validEntry, setValidEntry] = useState(false);
    const [invalidEntry, setInvalidEntry] = useState(false);
    const [openError, setOpenError] = useState(false);

    const [errorText, setErrorText] = useState({
        headText: '',
        subText: '',
    });

    const graphql = useApolloClient();

    const validEmailFormat = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
    const [isOpen, setIsopen] = useState(false);
    const toggle = () => {
        setIsopen(!isOpen);
    };
    const changeEmail = async () => {
        try {
            const respchangeEmail = await graphql.mutate({
                mutation: ENDPOINT.CHANGE_EMAIL,
                variables: {
                    oldEmailAddress: sessionStorage.getItem('reg_email'),
                    newEmailAddress: email,
                },
            });
            // console.log('response_email>>', respchangeEmail.data);
            if (respchangeEmail.data.changeIonUsername.statusCode == 200) {
                toggle();
            }
        } catch (err) {
            setOpenError(!openError);

            if (err.message === 'The email address entered already exists') {
                setErrorText({
                    headText:
                        'This email address already exists. Please use another email address to proceed with your account registration',
                    subText: '',
                });
            } else if (err.message === 'No response from Ion') {
                setErrorText({
                    headText: 'Opps something went wrong',
                    subText: 'please allow some time for email to be changed or try again later',
                });
            } else {
                setErrorText({
                    headText: 'Opps something went wrong',
                    subText: 'please try again later',
                });
            }
        }
        // return "Failure";
    };

    //message when email doesnt match = Email addresses do not match

    function validateEmail(valFun, val) {
        if (validEmailFormat.test(val)) {
            valFun(true);
        } else {
            valFun(false);
        }
    }

    const handleEmailChange = event => {
        validateEmail(setValidEmail, event.target.value);
        checkEmailMatching(event.target.value, confirmEmail);
        setEmail(event.target.value);
    };
    const handleConfirmEmailChange = event => {
        validateEmail(setValidConfirmEmail, event.target.value);
        checkEmailMatching(email, event.target.value);
        setConfirmEmail(event.target.value);
    };

    const handleEmailLabel = val => {
        setShowEmailLabel(val);
    };
    const handleConfirmEmailLabel = val => {
        setShowConfirmEmailLabel(val);
    };

    const commonStyleClass = commonRegisterStyles();
    const styleClass = useRegisterEmailStyle();

    function checkEmailMatching(val1, val2) {
        if (val1.toUpperCase() === val2.toUpperCase()) {
            setEmailsMatch(true);
        } else if (emailsMatch) {
            setEmailsMatch(false);
        }
    }

    const handleBackButton = () => {
        props.handleBackButton();
    };
    const handleNext = () => {
        changeEmail();
        // props.handleNext();
    };

    // useEffect(()=>{
    //     if(email===confirmEmail){
    //         setEmailsMatch(true);
    //     }
    //     else if(emailsMatch)
    //     {
    //         setEmailsMatch(false);
    //     }
    // },[email,confirmEmail]);

    // console.log("Account number",accountNumber);
    // console.log(confirmEmail,!!confirmEmail,typeof confirmEmail);
    // console.log(email,confirmEmail,validEmail && validConfirmEmail && emailsMatch);

    return (
        <div className={commonStyleClass.registrationContainer}>
            <div className={commonStyleClass.cardContainer}>
                <MyEirRegisterHeader
                    headerText='Register'
                    handleBackButton={handleBackButton}
                    //  registerScreen={props.registerScreen} handleBackButton={handleBackButton} //define these while integrating all the screens together
                />
                <div className={styleClass.bodyContainer}>
                    <Grid container direction='column' className='register-email-body'>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            className='register-email-text-information'
                        >
                            <div className='register-email-text-content'>
                                Please enter the email address which you will use to login to my eir
                            </div>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            className='register-email-grid-email'
                        >
                            <TextField
                                className='register-grid-input-text-field'
                                id='account-number'
                                label={showEmailLabel || !!email ? 'Email' : 'Enter your email'}
                                type='email'
                                //  placeholder="Enter your account number"
                                variant='outlined'
                                color='primary'
                                value={email}
                                InputLabelProps={{
                                    classes: {
                                        root: 'text-field-input-label-props-root',
                                        shrink:
                                            'text-field-input-label-props-shrink-tempRemoval' +
                                            (!validEmail && !!email
                                                ? ' text-field-input-label-props-shrink-invalid'
                                                : ''),
                                        focused:
                                            'text-field-input-label-props-focused' +
                                            (!validEmail && !!email
                                                ? ' text-field-input-label-props-focused-invalid'
                                                : ''),
                                    },
                                }}
                                InputProps={{
                                    classes: {
                                        root:
                                            'text-field-input-props-root' +
                                            (!!email ? ' text-field-input-props-root-filled' : ''),
                                        focused: 'text-field-input-props-focused',
                                        notchedOutline:
                                            (showEmailLabel
                                                ? 'text-field-input-props-notchedOutline'
                                                : 'text-field-input-props-notchedOutline-notFocus') +
                                            (!validEmail && !!email
                                                ? ' text-field-input-props-notchedOutline-invalid'
                                                : ''),
                                        input: 'text-field-input-props-input',
                                    },
                                }}
                                onFocus={() => {
                                    handleEmailLabel(true);
                                }}
                                onBlur={() => {
                                    handleEmailLabel(false);
                                }}
                                onChange={handleEmailChange}
                                // inputProps={{ inputmode: 'numeric', pattern: '[0-9]*', maxLength: 8, }}
                            />
                            {!validEmail && !!email && (
                                <Typography variant='inputLabel' className='invalid-email-format'>
                                    Please enter a valid email ID
                                </Typography>
                            )}
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            className='register-email-grid-confirm-email'
                        >
                            <TextField
                                className='register-grid-input-text-field'
                                id='account-number'
                                label={
                                    showConfirmEmailLabel || !!confirmEmail
                                        ? 'Confirm email'
                                        : 'Confirm email'
                                }
                                type='email'
                                //  placeholder="Enter your account number"
                                variant='outlined'
                                color='primary'
                                value={confirmEmail}
                                InputLabelProps={{
                                    classes: {
                                        root: 'text-field-input-label-props-root',
                                        shrink:
                                            'text-field-input-label-props-shrink-tempRemoval' +
                                            (validEmail && validConfirmEmail && !emailsMatch
                                                ? ' text-field-input-label-props-shrink-nonMatch'
                                                : '') +
                                            (!validConfirmEmail && !!confirmEmail
                                                ? ' text-field-input-label-props-shrink-invalid'
                                                : ''),
                                        focused:
                                            'text-field-input-label-props-focused' +
                                            (!validConfirmEmail && !!confirmEmail
                                                ? ' text-field-input-label-props-focused-invalid'
                                                : ''),
                                    },
                                }}
                                InputProps={{
                                    classes: {
                                        root:
                                            'text-field-input-props-root' +
                                            (!!confirmEmail
                                                ? ' text-field-input-props-root-filled'
                                                : ''),
                                        focused: 'text-field-input-props-focused',
                                        notchedOutline:
                                            (showConfirmEmailLabel
                                                ? 'text-field-input-props-notchedOutline'
                                                : 'text-field-input-props-notchedOutline-notFocus') +
                                            ((!validConfirmEmail && !!confirmEmail) ||
                                            (validEmail && validConfirmEmail && !emailsMatch)
                                                ? ' text-field-input-props-notchedOutline-invalid'
                                                : ''),
                                        input: 'text-field-input-props-input',
                                    },
                                }}
                                onFocus={() => {
                                    handleConfirmEmailLabel(true);
                                }}
                                onBlur={() => {
                                    handleConfirmEmailLabel(false);
                                }}
                                onChange={handleConfirmEmailChange}
                                //  inputProps={{ inputmode: 'numeric', pattern: '[0-9]*', maxLength: 8, }}
                            />
                            {!validConfirmEmail && !!confirmEmail && (
                                <Typography variant='inputLabel' className='invalid-email-format'>
                                    Please enter a valid email ID
                                </Typography>
                            )}
                            {validEmail && validConfirmEmail && !emailsMatch && (
                                <Typography variant='inputLabel' className='invalid-email-format'>
                                    Email addresses do not match
                                </Typography>
                            )}
                            {/* enable while API integration
                            <div className="invalid-account-number-entered">Please check your account number and try again</div> */}
                        </Grid>
                    </Grid>
                </div>
                <MyEirRegisterFooter
                    button1Text='Confirm'
                    button1Enabled={validEmail && validConfirmEmail && emailsMatch}
                    handleButton1Click={handleNext}
                    //  handleButton1Click={handleSubmitUsername} // define while integrating all the registration screen flows
                />
                <MyEirEmailConfirmationModal
                    toggle={toggle}
                    isOpen={isOpen}
                    registeredEmail={email}
                    dummyUser={sessionStorage.getItem('reg_email_status') == 'Dummy' ? true : false}
                    heading={
                        sessionStorage.getItem('reg_email_status') == 'Dummy'
                            ? 'You’re halfway there'
                            : "You're almost there"
                    }
                    subHeading={'Your account registration is not yet complete'}
                    text={
                        sessionStorage.getItem('reg_email_status') == 'Dummy'
                            ? 'Please follow the instructions to confirm your email address.'
                            : 'To complete the registration process, please verify your email and follow the instructions to set your password'
                    }
                />
                {openError ? (
                    <MyEirRegistrationErrorsPopup
                        open={openError}
                        setOpen={setOpenError}
                        headText={errorText.headText}
                        subText={errorText.subText}
                    />
                ) : (
                    ''
                )}
            </div>
        </div>
    );
};

export default MyEirRegisterEmail;
