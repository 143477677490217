import TagManager from 'react-gtm-module';

const useGTMPageView = () => {
    const tagManagerArgs = {
        dataLayer: {
            app: 'my-eir-ion',
            event: 'VirtualPageview',
            virtualPageURL: window.location.href,
            virtualPageTitle: window.document.title,
        },
        dataLayerName: 'datalayer',
    };
    TagManager.dataLayer(tagManagerArgs);
};

export default useGTMPageView;