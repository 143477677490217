import { DOMAINS, SERVICE_GROUPS } from '../../../components/myeirconstant/myEirConstant';
import { getCookie } from '../redesign.api';

const { MOBILE, IPTV } = DOMAINS;

export const handlingRedirection = ({
    reference,
    subscriptionId,
    cartType,
    serviceGroup,
    MY_EIR_CONST,
    setOpenOffer,
    setOpenError,
    openOffer,
    openError,
    isModalOpen,
    setIsModalOpen,
}) => {
    try {
        const channel = 'MYEIR';

        const emailAddress = sessionStorage.getItem('LoggedinUser');
        const customerName = sessionStorage.getItem('LoggedinUserFirstName');
        const accountId = sessionStorage.getItem('LoggedinUserAccountID');
        let page = '';

        if (serviceGroup === SERVICE_GROUPS.DUALPLAY || serviceGroup === SERVICE_GROUPS.TV) {
            page = 'broadband';
        } else if (cartType === 'CHANGE_OFFER' && serviceGroup === SERVICE_GROUPS.MOBILE) {
            page = 'mobile/handsets';
        } else if (serviceGroup === SERVICE_GROUPS.MOBILE && getCookie('CS_SIM_ONLY') === 'false') {
            page = 'mobile/handsets';
        } else if (serviceGroup === SERVICE_GROUPS.MOBILE && getCookie('CS_SIM_ONLY') === 'true') {
            page = 'mobile';
        }
        const url = MY_EIR_CONST.url.ACQ_URL + 'shop/redirect';

        let redirectPath =
            subscriptionId !== undefined || subscriptionId !== ''
                ? `${url}?page=${page}&channel=${channel}&cartType=${cartType}&reference=${reference}&customerName=${customerName}&emailAddress=${emailAddress}&subscriptionId=${subscriptionId}&accountId=${accountId}`
                : `${url}?page=${page}&channel=${channel}&cartType=${cartType}&reference=${reference}&customerName=${customerName}&emailAddress=${emailAddress}&accountId=${accountId}`;

        return redirectPath;
    } catch (error) {
        setOpenOffer(!openOffer);
        setOpenError(!openError);
        setIsModalOpen(!isModalOpen);
        console.log('error while redirecting', error);
    }
};
