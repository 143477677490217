import { checkApvEligibilityApi, getAmazonContentsApi, getApvTVServiceIdApi } from './apv.api';
import { getActiveAddons } from '../manage-my-addons/manageAddons.api';

/**
 * @name getApvDetails - gets apv contents from ocat and sets it to contentData useState variable
 * @param {Object} Obj - attributes for the function
 * @param {GraphQL} Obj.graphql - appoloclient graphql constant
 * @param {useState} Obj.setApvCustomerType - useState function which sets customer status based on apv eligibility
 * @param {useState} Obj.setApvOffer - useState function which sets apv offer
 * @param {useState} Obj.setApvOfferStatus - useState function which sets customer status based on apv offer
 * @param {GraphQL} graphql - appoloclient graphql constant
 * @param {useState} setApvCustomerType - useState function which sets customer status based on apv eligibility
 * @param {useState} setApvOffer - useState function which sets apv offer
 * @param {useState} setApvOfferStatus - useState function which sets customer status based on apv offer
 * @param {useState} apvId - useState function which will signify apvId
 */
export const getApvDetails = async ({
    graphql,
    setApvCustomerType,
    setApvOffer,
    setApvOfferStatus,
    value,
    apvId,
}) => {
    try {
        const apvServiceId = await getApvTVServiceIdApi({ graphql });
        // getApvTVServiceId returns serviceId,hasTV,hasAPV,addonId
        if (apvServiceId.serviceId) {
            const apvEligibility = await checkApvEligibilityApi({
                graphql,
                serviceId: apvServiceId.serviceId,
            });

            /** checkApvEligibility returns
             * offerCode: eir-prime-video-full or eir-prime-video-tv based on broadband or tv service
             * statusCode as 200 if eligible for APV, 201 if already using/active APV or 202 if not eligible for APV
             * status: NO_ONGOING_REQUEST, ON_GOING_ADDITION, ON_GOING_TERMINATION this tells the status of APV addon/offer for that serviceid
             */
            if (apvId) {
                // if addons code is equal to "AMAZON_TV" OR "FREE_AMAZON_TV" then show "cancel apv link" else "sign up apv link"
                let addons;
                try {
                    addons = await getActiveAddons({
                        graphql,
                        servicesIds: apvId,
                    });

                    if (addons?.getCurrentlySubscribedAddons?.length !== 0) {
                        const statusFilter = addons.getCurrentlySubscribedAddons.filter(
                            addon =>
                                addon.status === 'NO_ONGOING_REQUEST' &&
                                (addon.code === 'AMAZON_TV' || addon.code === 'FREE_AMAZON_TV')
                        );

                        if (statusFilter.length > 0) {
                            setApvOfferStatus(apvEligibility.status);
                            value.dispatch({
                                type: 'myServices',
                                payload: { name: 'apvOfferStatus', value: apvEligibility.status },
                            });
                            setApvCustomerType('active');
                            value.dispatch({
                                type: 'myServices',
                                payload: { name: 'apvCustomerType', value: 'active' },
                            });
                        }
                    } else if (apvEligibility.statusCode === 200) {
                        setApvOffer(apvEligibility.offerCode);
                        value.dispatch({
                            type: 'myServices',
                            payload: { name: 'apvOffer', value: apvEligibility.offerCode },
                        });

                        setApvOfferStatus(apvEligibility.status);
                        value.dispatch({
                            type: 'myServices',
                            payload: { name: 'apvOfferStatus', value: apvEligibility.status },
                        });

                        setApvCustomerType(apvServiceId.hasTV === true ? 'TV' : 'Broadband');

                        value.dispatch({
                            type: 'myServices',
                            payload: {
                                name: 'apvCustomerType',
                                value: apvServiceId.hasTV === true ? 'TV' : 'Broadband',
                            },
                        });
                    } else if (apvEligibility.statusCode === 201) {
                        setApvOfferStatus(apvEligibility.status);
                        value.dispatch({
                            type: 'myServices',
                            payload: { name: 'apvOfferStatus', value: apvEligibility.status },
                        });

                        setApvCustomerType('active');
                        value.dispatch({
                            type: 'myServices',
                            payload: { name: 'apvCustomerType', value: 'active' },
                        });
                    } else if (apvEligibility.statusCode === 202) {
                        console.log(`not eligible for apv`);
                    }
                } catch (error) {
                    console.log('error while fetching getCurrentlySubscribedAddons =====>', error);
                }
            }
        } else {
            console.log(`not eligible for apv`);
        }
    } catch (error) {
        console.log(`getApvDetails error: ${error}`);
    }
};
