import { Box, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import MyEirServicesContainer from '../my-services/MyEirServicesContainer';
import RecomendedOffersContainer from '../recomended-offers/RecomendedOffersContainer';
import useStyle from './styles.js';

const MyEirOfferDetail = () => {
    const classes = useStyle();
    return (
        <>
            <Box className={classes.container}>
                <Box>
                    {sessionStorage.getItem('NO_OFFER') ? (
                        ''
                    ) : (
                        <MyEirServicesContainer onOfferPage={true} />
                    )}
                </Box>
                <RecomendedOffersContainer onOfferPage={true} />
            </Box>
        </>
    );
};

export default MyEirOfferDetail;
