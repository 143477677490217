import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import { InMemoryCache, ApolloProvider } from '@apollo/client';
import client from '../utils/GraphqlClient';
import TagManager from 'react-gtm-module';
import { persistCache, SessionStorageWrapper } from 'apollo3-cache-persist';
import { initialisePWA } from '../pwa/pwa';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import theme from '../theme/MyEirTheme';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { RECAPTCHA_KEY_V3 } from '../../src/components/myeirconstant/myEirConstant';

initialisePWA();

const cache = new InMemoryCache();

(async () => {
    await persistCache({
        cache,
        storage: new SessionStorageWrapper(window.sessionStorage),
    });
})();

const tagManagerArgs = {
    gtmId: 'GTM-TVSCTPJ',
    dataLayerName: 'datalayer',
};

TagManager.initialize(tagManagerArgs);

const element = document.getElementById('app');

ReactDOM.render(
    <ApolloProvider client={client}>
        <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY_V3}>
            <Router>
                <ThemeProvider theme={theme}>
                    <App />
                </ThemeProvider>
            </Router>
        </GoogleReCaptchaProvider>
    </ApolloProvider>,
    element
);

export default module;
