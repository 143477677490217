import React, { useRef, useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import useStyles from './addonsStyles';
import { useMaxHeightCard } from '../redesign.util';
import { useWindowDimension } from '../../../hooks/windowDimensions';
import RemoveAddonPopup from './RemoveAddonPopup';
import { ADDON_STATUS } from '../../../components/myeirconstant/myEirConstant';
import { getAddonStatus } from './manageAddons.util';

const { NO_ONGOING_REQUEST, ONGOING_ADDITION, ONGOING_TERMINATION } = ADDON_STATUS;
const ActiveAddonsCard = ({
    card,
    index,
    setShowAddons,
    showAddons,
    collectionStatus,
    accountStatus,
    activeAddons,
    ActiveAddonContainerReload,
}) => {
    const classes = useStyles();
    const [width, height] = useWindowDimension();
    const ongoingStatus = card?.status === ONGOING_ADDITION || card?.status === ONGOING_TERMINATION;

    return (
        <Box className={classes.mainCard} style={{ alignSelf: 'stretch' }}>
            <Box className={classes.top}>
                <Box className={ongoingStatus ? classes.alignLeft : classes.left}>
                    <Typography className={classes.titleMax}>
                        {card?.title}
                        <Typography component='span' className={classes.subTitle}>
                            {card?.subtitle ? (
                                <Typography component='span' className={classes.hyphen}>
                                    -
                                </Typography>
                            ) : (
                                ''
                            )}

                            <span dangerouslySetInnerHTML={{ __html: card?.subtitle }} />
                        </Typography>
                    </Typography>
                </Box>

                <Box className={ongoingStatus ? classes.alignHorizontal : classes.rightRemove}>
                    <Typography className={classes.title}>
                        &euro;{(card?.price / 100).toFixed(2)}
                    </Typography>
                    <Typography className={classes.month}>
                        {card?.billingType === 'ONE_OFF' ? 'one off' : 'a month'}
                    </Typography>
                    {/* {width > 600 ? (
                        <>
                          <Typography className={classes.title}>
                                &euro;{(card?.price / 100).toFixed(2)}
                            </Typography>
                            <Typography component='span' className={classes.month}>
                                {' '}
                                {card?.billingType === 'ONE_OFF' ? 'one off' : 'a month'}
                            </Typography> 
                        </>
                    ) : (
                        <>
                            <Typography className={classes.title}>
                                &euro;{(card?.price / 100).toFixed(2)}
                            </Typography>
                            <Typography className={classes.month}>
                                {' '}
                                {card?.billingType === 'ONE_OFF' ? 'one off' : 'a month'}
                            </Typography>
                        </>
                    )} */}
                </Box>
            </Box>
            {card?.status !== NO_ONGOING_REQUEST && (
                <Box
                    className={`${classes.addonStatus}
                        ${
                            card?.status === ONGOING_ADDITION
                                ? classes.activationStatus
                                : classes.terminationStatus
                        }
                        `}
                >
                    {getAddonStatus(card?.status)}
                </Box>
            )}
            {card?.status !== ONGOING_ADDITION && card?.status !== ONGOING_TERMINATION && (
                <Box className={classes.removeAddon}>
                    <Typography>
                        <RemoveAddonPopup
                            linkName='Remove Add-on'
                            index={index}
                            card={card}
                            setShowAddons={setShowAddons}
                            showAddons={showAddons}
                            collectionStatus={collectionStatus}
                            accountStatus={accountStatus}
                            activeAddons={activeAddons}
                            ActiveAddonContainerReload={ActiveAddonContainerReload}
                        />
                    </Typography>
                </Box>
            )}
        </Box>
    );
};

export default ActiveAddonsCard;
