import React, { useState, Fragment, useEffect } from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { useWhyMyEirStyle } from './useWhyMyEirStyle';
import { Grid, Container, Button, Icon } from '@material-ui/core';
const theme = createMuiTheme({
    typography: {
        textTransform: 'none',
        textAlign: 'left',
        letterSpacing: '0px',
        fontFamily: 'gt-walsheim-regular !important',
        fontSize: '1rem !important',
        opacity: 1,
        fontSize: '1rem',
    },
});

function MyEirWhyMyEir() {
    const classes = useWhyMyEirStyle();

    return (
        <Fragment>
            <ThemeProvider theme={theme}>
                <div className={classes.featureSection}>
                    <Container>
                        <h1>Why my eir?</h1>
                        <Grid container spacing={6}>
                            <Grid item xs={12} sm={3} md={3}>
                                <img src='/opencms/export/system/modules/my.portal.opencms/myeir_ion/images/my-eir-check-my-balance.png'></img>
                                <h3>Check your balance</h3>
                                <p>See your credit balance, your offer details & add ons.</p>
                            </Grid>
                            <Grid item xs={12} sm={3} md={3}>
                                <img src='/opencms/export/system/modules/my.portal.opencms/myeir_ion/images/my-eir-track-my-usage.png'></img>
                                <h3>Track your usage</h3>
                                <p>Track your calls, text and data usage.</p>
                            </Grid>
                            <Grid item xs={12} sm={3} md={3}>
                                <img src='/opencms/export/system/modules/my.portal.opencms/myeir_ion/images/my-eir-make-a-payment.png'></img>
                                <h3>Make a payment</h3>
                                <p>Add credit to your account using your debit or credit card.</p>
                            </Grid>
                            <Grid item xs={12} sm={3} md={3}>
                                <img src='/opencms/export/system/modules/my.portal.opencms/myeir_ion/images/my-eir-manage-my-account.png'></img>
                                <h3>Manage your account</h3>
                                <p>
                                    Update your details, view your PIN and PUK and manage your
                                    preferences.
                                </p>
                            </Grid>
                        </Grid>
                        {/* <Button className='learnMoreBtn'>
                            <a href='#'>Learn more about my eir</a>
                        </Button> */}
                    </Container>
                </div>
            </ThemeProvider>
        </Fragment>
    );
}

export default MyEirWhyMyEir;
