import React, { useState, useEffect, useRef } from 'react';
import { usePhoneNumber } from '../css/usePhoneNumber';
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Grid,
    Button,
    Typography,
    useTheme,
} from '@material-ui/core';
import ReCAPTCHA from 'react-google-recaptcha';
import ENDPOINT from '../../components/graphql/GraphQLConstants';
import { useApolloClient } from '@apollo/client';
import { commonRegisterStyles } from '../css/myEirRegistrationStyles/commonRegisterStyles';
import MyEirRegisterHeader from './reusable/MyEirRegisterHeader';
import MyEirRegisterFooter from './reusable/MyEirRegisterFooter';
import { MY_EIR_CONST } from '../../components/myeirconstant/myEirConstant';

function MyEirPhoneNumber(props) {
    const theme = useTheme();
    const classes = usePhoneNumber();
    const commonStyle = commonRegisterStyles();
    const [code, setCode] = useState('');
    const [areaCodeOption, setAreaCodeOption] = useState([]);
    const [captchaValue, setCaptchaValue] = useState('');
    const [showLabelPhone, setShowLabelPhone] = useState(false);
    const [captchaFailed, setCaptchaFailed] = useState(false);
    const [isNextClicked, setIsNextClicked] = useState(false);
    const [invalidPhone, setInvalidPhone] = useState(false);
    const [emailAddress, setEmailAddress] = useState(false);
    const handleLabelPhoneNum = val => {
        setShowLabelPhone(val);
    };
    const graphql = useApolloClient();
    const recaptchaRef = useRef(null);
    const loc = window.location.href.indexOf('/forgot-email') > -1;
    async function areaCodeOptions() {
        try {
            const { data: allAreaCodes } = await graphql.query({
                query: ENDPOINT.AREA_CODES,
            });

            if (allAreaCodes !== null) {
                // console.log("area codes:", response.data.areaCodes)
                setAreaCodeOption(allAreaCodes.areaCodes);
            } else {
                console.log('area codes are null values!!');
                setAreaCodeOption([]);
                // setPhoneNum({error: { value: true, message: 'Something went wrong!' }})
            }
        } catch (error) {
            console.log('area codes error:', error);
            setAreaCodeOption([]);
            // setPhoneNum({error: { value: true, message: 'Something went wrong!' }})
        }
    }

    const [formSuccess, setFormSuccess] = useState({
        isPhoneNumber: false,
        isCodeNumber: false,
        isRecaptcha: false,
    });
    const handleCodeChange = event => {
        setCode(event.target.value);
        setFormSuccess({ ...formSuccess, isCodeNumber: true, isPhoneNumber: Boolean(phoneNum) });
        if (Boolean(phoneNum)) {
            setPhoneNum({
                ...phoneNum,
                error: { value: false, message: '' },
            });
        }
        setInvalidPhone(!Boolean(phoneNum));
    };
    const [phoneNum, setPhoneNum] = React.useState({
        value: null,
        displayNumber: '',
        pattern: /^[0-9]*$/,
        error: { value: false, message: 'Please enter a valid number' },
    });
    const handlePhoneNumber = event => {
        // console.log(event.target.value, /^[0-9]*$/.test(event.target.value), phoneNum);
        if (/^[0-9]*$/.test(event.target.value)) {
            setPhoneNum({
                ...phoneNum,
                value: event.target.value,
                displayNumber: event.target.value,
            });
            setInvalidPhone(false);

            if (event.target.value.length < 5) {
                setPhoneNum({
                    ...phoneNum,
                    value: event.target.value,
                    displayNumber: event.target.value,
                    error: { value: true, message: 'Please enter a valid number' },
                });
                setFormSuccess({ ...formSuccess, isPhoneNumber: false });
                setInvalidPhone(true);
            } else {
                setPhoneNum({
                    ...phoneNum,
                    value: event.target.value,
                    displayNumber: event.target.value,
                    error: { value: false, message: '' },
                });
                setFormSuccess({ ...formSuccess, isPhoneNumber: true });
                setInvalidPhone(false);
            }
        } else {
            // console.log('Invalid number');
            setPhoneNum({
                ...phoneNum,
                error: { value: true, message: 'Please enter a valid number' },
            });
            setFormSuccess({ ...formSuccess, isPhoneNumber: false });
        }
    };

    function handleOnChange(value) {
        if (value == null) setFormSuccess({ ...formSuccess, isRecaptcha: false });
        else {
            setCaptchaValue(value);
            setCaptchaFailed('');
            setFormSuccess({ ...formSuccess, isRecaptcha: true });
        }
    }

    const handleBackButton = () => {
        props.handleBackButton();
    };
    const handleNext = () => {
        numberValidation();
    };
    async function numberValidation() {
        // console.log('====MyEirPhoneNumber=====', code + phoneNum.displayNumber);
        setIsNextClicked(true);
        try {
            const res = await graphql.mutate({
                mutation: ENDPOINT.NUMBER_VALIDATION,
                variables: {
                    registerWith: 'NUMBER',
                    registerValue: code + phoneNum.displayNumber,
                    recaptcha: captchaValue,
                },
            });

            setIsNextClicked(false);

            if (
                res.data.validateUser.statusCode == 201 ||
                res.data.validateUser.statusCode == 202
            ) {
                sessionStorage.setItem('reg_phone_number', code + phoneNum.displayNumber);
                sessionStorage.setItem('reg_uuid', res.data.validateUser.otpUuid);
                sessionStorage.setItem('reg_primary_number', res.data.validateUser.mobileNumber);
                res.data.validateUser.statusCode == 201 &&
                    sessionStorage.setItem('reg_bill_type', 'PREPAY');
                res.data.validateUser.statusCode == 202 &&
                    sessionStorage.setItem('reg_bill_type', 'BILL');
                props.handleNext();
            }
            if (res.data.validateUser.statusCode == 203) {
                // redirect to portal
                sessionStorage.setItem('reg_phone_number', code + phoneNum.displayNumber);
                if (props.forgotUserName) {
                    // console.log('====MyEirPhoneNumber===== recaptcha response', captchaValue);
                    window.location.href =
                        '/bundles/forgotten-email?phoneNumber=' +
                        code +
                        '-' +
                        phoneNum.displayNumber +
                        '&response=' +
                        captchaValue;
                } else {
                    window.location.href =
                        '/bundles/register/account?phoneNumber=' + code + phoneNum.displayNumber;
                }
            }
        } catch (err) {
            console.error(err);
            if (err.message === 'Invalid phone number') {
                setPhoneNum({
                    ...phoneNum,
                    error: { value: true, message: 'Invalid phone number' },
                });
                setFormSuccess({ ...formSuccess, isPhoneNumber: false });
                setInvalidPhone(true);
            } else if (err.message === 'Captcha is not valid') {
                setCaptchaFailed(err.message);
                setFormSuccess({ ...formSuccess, isRecaptcha: false });
            } else if (err.message.includes('getaddrinfo') || err.message.includes('EAI_AGAIN')) {
                setPhoneNum({
                    ...phoneNum,
                    error: {
                        value: true,
                        message: 'Something went wrong, please try again later.',
                    },
                });
                setFormSuccess({ ...formSuccess, isPhoneNumber: false });
                setInvalidPhone(true);
            } else {
                setPhoneNum({ ...phoneNum, error: { value: true, message: err.message } });
                setFormSuccess({ ...formSuccess, isPhoneNumber: false });
                setInvalidPhone(true);
            }
            setFormSuccess({ ...formSuccess, isRecaptcha: false });
            setIsNextClicked(false);
            recaptchaRef?.current?.reset();
        }
        // props.handleNext();
    }
    const handleEmailAddress = () => {
        props.handleNext(1);
    };

    useEffect(() => {
        areaCodeOptions();
    }, []);

    return (
        <form
            className={commonStyle.registrationContainer}
            onSubmit={e => {
                e.preventDefault();
            }}
        >
            <div className={commonStyle.cardContainer}>
                <MyEirRegisterHeader
                    headerText={props.headerText}
                    handleBackButton={handleBackButton}
                />

                <div className={classes.enterPhoneBody}>
                    <Grid container spacing={0} className={classes.phoneCodeGrid}>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            className='register-grid-text-information'
                        >
                            <Typography variant='body1' className='register-grid-text-content'>
                                Please enter your eir mobile, landline or broadband reference number
                            </Typography>
                        </Grid>
                        <Grid item sm={2} xs={4} md={3} className={classes.codeNumber}>
                            <FormControl variant='outlined' className={classes.formControl}>
                                <InputLabel
                                    style={
                                        invalidPhone ? { color: theme.palette.primary.error } : {}
                                    }
                                >
                                    Prefix
                                </InputLabel>
                                <Select
                                    labelId='select'
                                    id='code-select'
                                    value={code}
                                    onChange={handleCodeChange}
                                    label='Prefix'
                                    error={invalidPhone}
                                    //variant="outlined"
                                >
                                    {areaCodeOption.map(({ AREA_CODE, index }) => (
                                        <MenuItem value={AREA_CODE}>{AREA_CODE}</MenuItem>
                                    ))}
                                    {/* // <MenuItem value={'081'}>081</MenuItem>
                                        // <MenuItem value={'082'}>082</MenuItem>
                                        // <MenuItem value={'083'}>083</MenuItem> */}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item sm={4} xs={8} md={9} className={classes.phoneNumber}>
                            <TextField
                                className={classes.textField}
                                //error={phoneNum.error.value}
                                type='tel'
                                label='Phone number'
                                //label={showLabelPhone ? 'Phone number' : 'Your phone number'}
                                variant='outlined'
                                value={phoneNum.displayNumber}
                                onChange={handlePhoneNumber}
                                error={invalidPhone}
                                inputProps={{
                                    maxLength: 7,
                                    // pattern: /^[0-9]*$/,
                                }}
                                onFocus={() => {
                                    handleLabelPhoneNum(true);
                                }}
                                onBlur={() => {
                                    handleLabelPhoneNum(false);
                                }}
                            ></TextField>
                        </Grid>
                        <Grid item xs={12} className={classes.errormessage}>
                            {phoneNum.error.value ? (
                                <div className='invalid-messages'>{phoneNum.error.message}</div>
                            ) : (
                                ''
                            )}
                        </Grid>
                    </Grid>
                    <Grid container sm={12} xs={12} md={12} className={classes.gridRecaptcha}>
                        <div className={classes.reCaptcha}>
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                className={classes.reCaptchaRobot}
                                sitekey='6LfZsRYTAAAAAJMBqawqK3sy7NmHDC8M9rKdkX36'
                                render='explicit'
                                onChange={handleOnChange}
                            />
                        </div>
                        <Grid item xs={12} className={classes.errormessage}>
                            {captchaFailed ? (
                                <div className='invalid-messages'>{captchaFailed}</div>
                            ) : (
                                ''
                            )}
                        </Grid>

                        {/* Hiding email address link in register flow for now */}
                        {/* {!loc &&
                        <Grid item xs={12} sm={12} md={12} lg={12} className={classes.emailText}>
                            <Typography className={classes.msg}>
                                Alternatively, you can continue by providing your eir &nbsp;
                                <Typography
                                    component='span'
                                    className={classes.emailLink}
                                    onClick={handleEmailAddress}
                                >
                                    email address
                                </Typography>
                                <Typography component='span' className={classes.secondMsg}>
                                    &nbsp;used when placing your order
                                </Typography>
                            </Typography>
                        </Grid>
                        } */}
                    </Grid>
                </div>

                <MyEirRegisterFooter
                    button1Text='Next'
                    handleButton1Click={handleNext}
                    button1Enabled={
                        formSuccess.isPhoneNumber &&
                        formSuccess.isCodeNumber &&
                        formSuccess.isRecaptcha &&
                        !isNextClicked
                    }
                />
            </div>
        </form>
    );
}

export default MyEirPhoneNumber;
