import React from 'react';
import { Badge } from '@material-ui/core';
import { iconBadgeStyle } from './iconBadgeStyle';

/**
 * @name IconBadge - pass component which needs a badge as children of IconBadge
 * @param {String} variant - badge type('standard' or 'dot'), default is standard
 * @param {String} badgeContent - content that should be displayed in the badge
 * @param {String} border - to have white border around the badge
 * @param {Boolean} invisible - to hide the badge
 * @param {Boolean} showZero - to show the badge when content is 0
 * @param {String} verticalPosition - badge type('top' or 'bottom'), default is top
 * @param {String} horizontalPosition - badge type('right' or 'left'), default is right
 * @returns
 */
const IconBadge = props => {
    const classes = iconBadgeStyle();
    return (
        <Badge
            variant={props.variant ? props.variant : 'standard'}
            badgeContent={props.badgeContent}
            color='primary'
            invisible={props.invisible}
            showZero={props.showZero}
            anchorOrigin={{
                vertical: props.verticalPosition ? props.verticalPosition : 'top',
                horizontal: props.horizontalPosition ? props.horizontalPosition : 'right',
            }}
            className={`${classes.commonStyle} ${
                props.variant === 'dot' ? classes.dotStyle : classes.standardStyle
            } ${
                props.border
                    ? props.variant === 'dot'
                        ? classes.dotborderStyle
                        : classes.standardborderStyle
                    : ''
            }`}
        >
            {props.children}
        </Badge>
    );
};

export default IconBadge;
