import CustomUpdatedPopUpModal from '../../eir-porting/CustomModal/CustomUpdatedPopUpModal';
import { Box, Typography, Button } from '@material-ui/core';
import newClose from '../images/cancel.png';
import useStyle from './style.js';
import { useState } from 'react';
import React from 'react';
import EirButton from '../../../theme/EirButton';

const MyEirNewErrorPopup = props => {
    const [openPopup, setOpenPopup] = useState(props.open);
    const [cta1, setCTA1] = useState({ text: '', action: () => {}, disable: false });
    const [cta2, setCTA2] = useState({ text: '', action: () => {}, disable: false });
    const styles = useStyle();

    const handleCloseError = async () => {
        setOpenPopup(false);
        props.setOpen(false);
    };

    return (
        <Box maxWidth='md'>
            <CustomUpdatedPopUpModal
                open={openPopup}
                id='myeir-modal-window-not-eligible'
                cta1={cta1}
                cta2={cta2}
                toggle={handleCloseError}
            >
                <Typography className={styles.imgText}>{props.message}</Typography>
                <Box className='styles.imgcontent'>
                    <Box component='img' src={newClose} alt='sad-smily' className={styles.sadImg} />
                </Box>
                <Box>
                    <EirButton
                        variants='primaryButtonMedium'
                        style={{ padding: '0.5rem 2.5rem' }}
                        // className={styles.cancelBtn}
                        onClick={handleCloseError}
                    >
                        Close
                    </EirButton>
                </Box>
            </CustomUpdatedPopUpModal>
        </Box>
    );
};

export default MyEirNewErrorPopup;
