import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { recomendedOfferStyles } from './recomendedOfferStyles';

const OfferBox = ({
    leftTopHeading,
    leftBottomHeading,
    rightTopHeading,
    rightBottomHeading,
    rightBottomLastHeading,
    background,
    divider2Color,
    divider1Color,
    links,
    apvToggle,
    setApvToggle,
    fontColor,
}) => {
    const classes = recomendedOfferStyles();
    const handleClick = () => {
        setApvToggle(true);
    };
    return (
        <>
            <Box
                style={{ display: 'flex', flexDirection: 'row', background: background }}
                className={classes.offerBoxContainer}
                onClick={() => handleClick()}
            >
                <Box
                    style={{ display: 'flex', flexDirection: 'column' }}
                    className={classes.leftTextContainer}
                >
                    <Typography style={{ color: fontColor }} className={classes.leftTopHeading}>
                        {leftTopHeading}
                    </Typography>
                    <Typography style={{ color: fontColor }} className={classes.leftBottomHeading}>
                        {leftBottomHeading} {links}
                    </Typography>
                </Box>
                <Box
                    className={classes.divider}
                    style={{
                        borderLeft: `0.8px solid ${divider1Color}`,
                        borderRight: `0.8px solid ${divider2Color}`,
                    }}
                ></Box>
                <Box
                    style={{ display: 'flex', flexDirection: 'column' }}
                    className={classes.rightTextContainer}
                >
                    <Typography style={{ color: fontColor }} className={classes.rightTopHeading}>
                        {rightTopHeading}
                    </Typography>
                    <Typography style={{ color: fontColor }} className={classes.rightBottomHeading}>
                        {rightBottomHeading}
                    </Typography>
                    <Typography style={{ color: fontColor }} className={classes.rightTopHeading}>
                        {rightBottomLastHeading}
                    </Typography>
                </Box>
            </Box>
        </>
    );
};

export default OfferBox;
