import { makeStyles } from '@material-ui/core/styles';
export const offersStyles = makeStyles(theme => ({
    offerContainer: {
        position: 'absolute',
        width: '164px',
        top: '50%',
        height: '130px',
        right: '0',
        // padding: '18px 16px 16px 23px',
        backdropFilter: 'blur(9.24274px)',
        // borderRadius: '24px 0px 10px 24px',
        color: theme.palette.primary.heather,
        transform: 'translateY(-50%)',
        [theme.breakpoints.down('sm')]: {
            height: '134px',
            top: '51%',
        },
    },
    miniText: {
        fontFamily: theme.typography.body2.fontFamily,
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: theme.typography.tooltip.fontSize,
        lineHeight: '8px',
    },
    offerName: {
        fontFamily: theme.typography.body3Medium.fontFamily,
        fontStyle: 'normal',
        // fontWeight: 700,
        fontSize: theme.typography.subtitle2.fontSize,
        lineHeight: '18px',
        '@media(max-width: 600px)': {
            lineHeight: '18px',
        },
    },
    addonName: {
        fontFamily: theme.typography.subtitle1.fontFamily,
        fontStyle: 'normal',
        // fontWeight: 700,
        fontSize: theme.typography.subtitle1.fontSize,
        lineHeight: '28px',
        '@media(max-width: 600px)': {
            lineHeight: '18px',
        },
    },
    offerPrice: {
        fontFamily: theme.typography.subtitle1.fontFamily,
        fontStyle: 'normal',
        // fontWeight: 700,
        fontSize: theme.typography.h4.fontSize,
        lineHeight: '31px',
        paddingTop: '15px',
    },
    offerValidity: {
        fontFamily: theme.typography.body2.fontFamily,
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: theme.typography.tooltip.fontSize,
        lineHeight: '8px',
        paddingLeft: '4px',
    },
    readMore: {
        fontFamily: theme.typography.subtitle1.fontFamily,
        fontStyle: 'normal',
        // fontWeight: 700,
        fontSize: theme.typography.inputLabel.fontSize,
        lineHeight: '18px',
        cursor: 'pointer',
        // marginTop: '-7.97px',
        position: 'absolute',
        bottom: '15px',
    },
}));
