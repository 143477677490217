import { makeStyles } from '@material-ui/core/styles';
export const useWhyMyEirStyle = makeStyles(theme => ({
    paper: {
        marginTop: ' 0px !important',
        marginBottom: theme.spacing(3),
        background: '#FFFFFF 0% 0% no-repeat',
        boxShadow: '0px 3px 25px #00000024',
        borderRadius: '0px 0px 9px 9px',
        opacity: '1',
        [theme.breakpoints.up(1000 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
        '@media  (max-width: 1025px)': {
            paddingBottom: '24px',
        },
    },
    featureSection: {
        background: '#fff',
        margin: '40px',
        '& img': {
            width: '130px',
            '@media(max-width:769px)': {
                width: '130px',
            },
            '@media(min-width:768px) and (max-width:770px)': {
                marginLeft: '-19px',
            },
        },
        '& h1': {
            fontSize: '50px',
            color: '#470A68',
            marginBottom: '25px',
            fontFamily: 'eir-medium',
            fontWeight: 'normal',
            '@media(max-width:769px)': {
                fontSize: '40px',
            },
        },
        '& h3': {
            fontSize: '27px',
            color: '#323435',
            fontFamily: 'eir-medium',
            fontWeight: 'normal',
            '@media(max-width:769px)': {
                fontSize: '20px',
            },
        },
        '& p': {
            color: '#323435',
            fontSize: '14px',
            fontWeight: '600',
            fontFamily: 'gt-walsheim-regular',
            '@media(max-width:769px)': {
                fontSize: '14px',
            },
        },
        '& .learnMoreBtn': {
            border: '2px solid #DB12A9',
            borderRadius: '32px',
            padding: '8px 30px',
            outline: 'none',
            background: '#fff',
            marginBottom: '20px',
            '@media(min-width:281px) and (max-width:769px)': {
                padding: '8px 23px',
            },
            '@media (max-width:280px)': {
                padding: '8px 4px',
            },
            '&:hover': {
                background: '#fff',
            },
            '& a': {
                color: '#470A68',
                fontSize: '19px',
                fontFamily: 'gt-walsheim-medium',
                textDecoration: 'none',
                textTransform: 'initial',
                '@media(max-width:769px)': {
                    fontSize: '14px',
                },
            },
        },
    },
}));
