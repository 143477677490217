import React, { useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import info from '../redesign-5.2/images/infoBlue.png';
import noticeStyles from './noticeStyles';
import cancel from '../redesign-5.2/images/cancelGrey.png';
import PortalResetPasswordScreen from '../../sharedcomponents/MyEirPostpayRegistration/PortalResetPasswordScreen';
import axios from 'axios';
import { MY_EIR_CONST } from '../../components/myeirconstant/myEirConstant';
import { resetPassword } from './loginOperation.api';
import { useApolloClient } from '@apollo/client';

const AlertOfForgotPassword = ({ email }) => {
    const classes = noticeStyles();
    const [isCanceled, setIsCanceled] = useState(false);
    const [isClicked, setIsClicked] = useState(false);
    const graphql = useApolloClient();

    const closeAction = () => {
        setIsCanceled(true);
    };

    const resetPortalPassword = async () => {
        try {
            const res = await resetPassword({ graphql, emailAddress: email, brand: 'R6' });
            if (res.data.resetPassword) {
                setIsClicked(true);
            } else {
                console.error('something went wrong while triggering forgot/reset password');
            }
        } catch (error) {
            console.log('error while resetting password:', error);
        }
    };
    const handlePortalPasswordReset = () => {
        console.log('R6 reset password');
        resetPortalPassword();
    };
    return (
        <>
            {!isCanceled && (
                <Box className={classes.alertContainer}>
                    <Box className={classes.noticeElements}>
                        <Box component='img' src={info} alt='info' className={classes.info} />
                        <Box className={classes.alertText}>
                            If you have more than one account connected to this email address you
                            will need to reset your password for your other account(s). To reset
                            your password for your other account(s) you can{' '}
                            <Typography
                                component='span'
                                className={classes.textBold}
                                onClick={handlePortalPasswordReset}
                            >
                                click here
                            </Typography>{' '}
                            . Please ignore this message if you do not have more than one account
                        </Box>
                    </Box>
                </Box>
            )}
            {isClicked && <PortalResetPasswordScreen isOpen={isClicked} email={email} />}
        </>
    );
};

export default AlertOfForgotPassword;
