import React, { useState, Fragment, createContext, useEffect } from 'react';
import { useMyEirUsageStyle } from './useMyEirUsageStyle';
import MyEirUsageSummary from './MyEirUsageSummary';
import MyEirDetailedUsage from './MyEirDetailedUsage';
import { useApolloClient } from '@apollo/client';
import MyEirFaq from '../my-eir-dashboard/MyEirFaq';
import moment from 'moment';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { Container, Paper } from '@material-ui/core';
import ENDPOINT from '../graphql/GraphQLConstants';
import MyEirUsageFilter from './MyEirUsageFilter';
import Loading from '../shared/Loading';
export const UsageDetailsContext = createContext();

const theme = createMuiTheme({
    typography: {
        textTransform: 'none',
        textAlign: 'left',
        letterSpacing: '0px',
        fontFamily: 'gt-walsheim-regular',
        opacity: 1,
        fontSize: '16px',
    },
    palette: {
        primary: {
            main: '#892BA0',
        },
    },
    overrides: {
        MuiFormLabel: {
            asterisk: {
                color: '#f44336',
            },
        },
        MuiSelect: {
            icon: {
                color: '#892BA0',
            },
        },
        MuiTextField: {
            root: {
                fontSize: '16px',
                color: 'black',
                '@media (max-width: 1024px)': {
                    fontSize: '14px',
                },
            },
        },

        MuiInputLabel: {
            root: {
                color: 'black',
                fontSize: '16px',
                '@media (max-width: 1024px)': {
                    fontSize: '14px',
                },
            },
        },
    },
});

function MyEirUsageContainer() {
    const classes = useMyEirUsageStyle();
    const [selectedTimePeriod, setSelectedTimePeriod] = useState(moment().format('YYYYMM'));
    const [selectedType, setSelectedType] = useState('all');
    const [usageDetailsList, setUsageDetailsList] = useState(null);
    const [usageSummaryList, setUsageSummaryList] = useState(null);
    const [usageTypeList, setUsageTypeList] = useState();
    const graphql = useApolloClient();
    const [isLoading, setIsLoading] = useState(false);
    const [detailLoading, setDetailLoading] = useState(false);
    const token = sessionStorage.getItem('access_token');
    const serviceId = parseInt(sessionStorage.getItem('ServiceId'));
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [listItem, setlistItem] = useState([]);
    const ITEMS_TO_SHOW = 6;
    const [usageError, setUsageError] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        if (selectedType != null && serviceId && token) {
            getUsageDetails(
                selectedType,
                serviceId,
                token,
                selectedTimePeriod,
                currentPage,
                rowsPerPage
            )
                .then(data => {
                    setUsageDetailsList(data.getMyUsageDetails.usage.content);
                    setTotalPages(data.getMyUsageDetails.usage.totalPages);
                    setDetailLoading(false);
                })
                .catch(err => {
                    setDetailLoading(false);
                    setUsageError(true);
                    console.error(
                        'Error encountered while fetching Usage Details : ' + err.message
                    );
                });
        } else {
            console.log('Unable to fetch Usage Details : Invalid Parameter(s)');
            setUsageError(true);
        }
    }, [selectedTimePeriod, selectedType, currentPage, rowsPerPage]);
    useEffect(() => {
        if (token && selectedTimePeriod && serviceId) {
            getUsageSummary(selectedTimePeriod, token, serviceId)
                .then(data => {
                    setUsageSummaryList(data.getMyUsageSummary.usageSummary);
                    setUsageTypeList(data.getMyUsageSummary.usageSummary);
                    groupUsageSummary(data.getMyUsageSummary.usageSummary, selectedType);
                })
                .catch(err => {
                    setIsLoading(false);
                    setUsageError(true);
                    console.error(
                        'Error encountered while fetching Usage Summary : ' + err.message
                    );
                });
        } else {
            console.log('Unable to fetch Usage Summary : Invalid Parameter(s)');
            setUsageError(true);
        }
    }, []);

    const handleTimePeriodChange = timePeriod => {
        setSelectedTimePeriod(timePeriod);
        setCurrentPage(0);
        getUsageSummary(timePeriod).then(data => {
            setUsageSummaryList(data.getMyUsageSummary.usageSummary);
            setUsageTypeList(data.getMyUsageSummary.usageSummary);
            groupUsageSummary(data.getMyUsageSummary.usageSummary, selectedType);
        });
    };
    const handleUsageTypeChange = usageType => {
        setIsLoading(true);
        setSelectedType(usageType);
        setCurrentPage(0);
        groupUsageSummary(usageSummaryList, usageType);
    };
    const getUsageSummary = async (timeperiod, token, serviceId) => {
        setIsLoading(true);
        const usageSummary = await graphql.query({
            query: ENDPOINT.GET_USAGE_SUMMARY,
            variables: {
                token: token,
                serviceId: serviceId,
                serviceType: 'PREPAY',
                billingPeriod: timeperiod,
            },
        });
        return usageSummary.data;
    };
    const groupUsageSummary = async (usageSummary, usageType) => {
        let filteredUsageSummary = usageSummary;
        if (usageType != null && usageType != 'all') {
            filteredUsageSummary = usageSummary.filter(
                usage => usage.ratingSubtotalTypeKey == usageType
            );
        }
        const summaryItemList = [];
        if (filteredUsageSummary.length > ITEMS_TO_SHOW) {
            for (var j = 0; j <= filteredUsageSummary.length; j = j + ITEMS_TO_SHOW) {
                let item = filteredUsageSummary.slice(j, j + ITEMS_TO_SHOW);
                summaryItemList.push(item);
            }
        } else if (filteredUsageSummary.length > 0) {
            summaryItemList.push(filteredUsageSummary);
        }
        setlistItem(summaryItemList);
        setIsLoading(false);
    };
    const getUsageDetails = async (
        selectedType,
        serviceId,
        token,
        selectedTimePeriod,
        currentPage,
        rowsPerPage
    ) => {
        setDetailLoading(true);
        const usageDetails = await graphql.query({
            query: ENDPOINT.GET_USAGE_DETAILS,
            variables: {
                token: token,
                usageDetailsInput: {
                    serviceId: serviceId,
                    serviceType: 'PREPAY',
                    billingPeriod: selectedTimePeriod,
                    usageType: selectedType === 'all' ? '' : selectedType,
                    pageNumber: currentPage,
                    size: rowsPerPage,
                },
            },
        });
        return usageDetails.data;
    };

    return (
        <Fragment>
            <ThemeProvider theme={theme}>
                <UsageDetailsContext.Provider
                    value={{
                        selectedTimePeriod,
                        selectedType,
                        handleTimePeriodChange,
                        handleUsageTypeChange,
                    }}
                >
                    <div className={classes.UsageContainer}>
                        <Container>
                            <div className='usageSectionHolder'>
                                <h1 className='usageTitle'>My detailed usage</h1>
                                <Paper className={classes.paper}>
                                    <div className='usageContent'>
                                        {isLoading && (
                                            <span className='paddingTop'>
                                                <Loading></Loading>
                                            </span>
                                        )}
                                        {!isLoading && usageTypeList && (
                                            <MyEirUsageFilter usageTypeList={usageTypeList} />
                                        )}
                                        {!isLoading && listItem && (
                                            <MyEirUsageSummary listItem={listItem} />
                                        )}
                                        {!isLoading && !detailLoading && usageError && (
                                            <span className='errorMessage'>
                                                Currently unable to retrieve usage information,
                                                please try again later.
                                            </span>
                                        )}
                                        {detailLoading && !isLoading && (
                                            <span className='paddingTop'>
                                                <Loading></Loading>
                                            </span>
                                        )}
                                        {!detailLoading && (
                                            <MyEirDetailedUsage
                                                usageDetailsList={usageDetailsList}
                                                totalPages={totalPages}
                                                setCurrentPage={setCurrentPage}
                                                currentPage={currentPage}
                                                rowsPerPage={rowsPerPage}
                                                setRowsPerPage={setRowsPerPage}
                                            />
                                        )}
                                        <p className='usagetext paddingTop'>
                                            Your data usage is displayed as a series of daily totals
                                            reflecting types of data consumed – i.e. in-bundle, out
                                            of bundle and day pass usage.
                                        </p>
                                        <p className='usagetext'>
                                            Prices shown are exclusive of any applicable VAT at 23%.
                                        </p>
                                        <p className='usagetext'>
                                            While every effort has been made to ensure the accuracy
                                            of this report it is intended as a guide to your usage
                                            of your phone only. Actual usage charges may vary.
                                            Please allow up to 24 hours for calls and texts to be
                                            rated correctly from your bundle(s). There will be a
                                            longer time delay in showing roaming calls / texts and
                                            charges. Your inclusive minutes will be deducted from
                                            your account in the order they are rated by our systems
                                            and may not be in chronological order.
                                        </p>
                                        <p className='paddingBottomDetail'></p>
                                    </div>
                                </Paper>
                                <Paper className={classes.paper}>
                                    <MyEirFaq />
                                </Paper>
                            </div>
                        </Container>
                    </div>
                </UsageDetailsContext.Provider>
            </ThemeProvider>
        </Fragment>
    );
}

export default MyEirUsageContainer;
