import { useApolloClient } from '@apollo/client';
import { Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import OfferBox from './OfferBox';
import {
    getRecomendedOffersEligibity,
    getRecomendedOffersProspectUtil,
} from './recomendedOffers.util';
import { recomendedOfferStyles } from './recomendedOfferStyles';
import { getAdobeAnalyticsApi } from '../my-services/myServices.util';
import MyEirNewErrorPopup from '../my-services/MyEirNewErrorPopup';
import { MY_EIR_CONST } from '../../../components/myeirconstant/myEirConstant';
import { handlingRedirection } from '../offers-section/Offers.util.js';
import MyEirErrorHandle from '../../eir-porting/MyEirErrorHandle';
import MyEirRegistrationErrorsPopup from '../../../sharedcomponents/MyEirPostpayRegistration/MyEirRegistrationErrorsPopup';
import { DOMAINS, SERVICE_GROUPS } from '../../../components/myeirconstant/myEirConstant';
import { useWindowDimension } from '../../../hooks/windowDimensions';
import { getCookie } from '../redesign.api';

const RecomendedOfferCard = ({
    cardImg,
    leftTopHeading,
    leftBottomHeading,
    rightTopHeading,
    rightBottomHeading,
    rightBottomLastHeading,
    background,
    divider2Color,
    divider1Color,
    propositionId,
    serviceGroup,
    card,
    setIsModalOpen,
    setSelectedCard,
    setHideCard,
    hideCard,
    parentId = '',
    csTvData,
    fontColor,
}) => {
    const [checkOffersEligibity, setCheckOffersEligibity] = useState(null);

    const [showDropper, setShowDropper] = useState(false);
    const [showError, setShowError] = useState(false);
    const [errorText, setErrorText] = useState({
        headText: '',
        subText: '',
    });
    const ineligibleMessage = 'Unfortunately you are not eligible to make changes at this time';
    const openModal = card => {
        setIsModalOpen(true);
        setSelectedCard(card);
    };

    const { MOBILE, IPTV } = DOMAINS;
    const classes = recomendedOfferStyles();
    const [openError, setOpenError] = useState(false);
    const [openOffer, setOpenOffer] = useState(false);
    const [openChangeError, setOpenChangeError] = useState(false);
    const graphql = useApolloClient();
    const strippedText = new DOMParser().parseFromString(leftBottomHeading, 'text/html').body
        .innerText;

    const [isMobile, setIsMobile] = useState(false);
    const [width, height] = useWindowDimension();
    let reference;
    let cookieCheckInterval;

    //choose the screen size
    const handleResize = () => {
        if (width < 720) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    };
    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize();
    });
    const handleRedirectValues = reference => {
        const redirectPath = handlingRedirection({
            reference,
            cartType: 'CROSS_SELL',
            serviceGroup: MOBILE,
            MY_EIR_CONST,
            setOpenOffer,
            setOpenError,
            openOffer,
            openError,
        });

        return redirectPath;
    };
    const checkCookie = () => {
        const cookieValue = getCookie('CS_SIM_ONLY');
        if (cookieValue) {
            clearInterval(cookieCheckInterval);

            let redirectPath = handleRedirectValues(reference);

            if (redirectPath !== undefined) window.location.href = redirectPath;
        } else {
            console.log('cookie value not set yet');
        }
    };

    const handleCreateCrossSell = async (res, serviceGroup) => {
        const filterTrue = res.filter(itm => itm.serviceGroup === serviceGroup);
        const eligibleForCS = filterTrue[0].eligible;

        if (serviceGroup !== SERVICE_GROUPS.MOBILE) {
            if (eligibleForCS === true) {
                openModal(card);
            } else {
                setOpenChangeError(!openChangeError);
            }
        } else {
            if (eligibleForCS === true) {
                setSelectedCard(card);

                cookieCheckInterval = setInterval(checkCookie, 2000);
            } else {
                setOpenChangeError(!openChangeError);
            }
        }
    };

    const getRecomendedOffersEligibityUtil = async value => {
        if (serviceGroup === SERVICE_GROUPS.MOBILE) {
            await getAdobeAnalyticsApi({ graphql, value, propositionId });
            const { data } = await getRecomendedOffersProspectUtil({
                graphql,
                setOpenError,
                openError,
                setIsModalOpen,
            });

            reference = data.createCrossSellProspect;

            const res = await getRecomendedOffersEligibity({
                graphql,
                setCheckOffersEligibity,
                setOpenError,
                reference,
                openError,
            });
            if (
                res?.data?.checkCrossSellEligibility?.serviceGroups?.find(
                    item => item.serviceGroup === SERVICE_GROUPS.MOBILE && item.eligible === true
                )
            ) {
                handleCreateCrossSell(
                    res.data.checkCrossSellEligibility.serviceGroups,
                    serviceGroup
                );
            } else {
                setOpenChangeError(!openChangeError);
            }
        }

        if (serviceGroup === SERVICE_GROUPS.DUALPLAY) {
            const res = await getRecomendedOffersEligibity({
                graphql,
                setCheckOffersEligibity,
                setOpenError,
                openError,
            });
            handleCreateCrossSell(res.data.checkCrossSellEligibility.serviceGroups, serviceGroup);
        } else if (serviceGroup === SERVICE_GROUPS.TV) {
            if (csTvData?.getCSTVDetails?.length === 0) {
                setOpenChangeError(!openChangeError);
            } else if (csTvData?.getCSTVDetails?.length === 1) {
                const parentSubscriptionId = JSON.stringify(parentId);
                const response = await getRecomendedOffersEligibity({
                    graphql,
                    setCheckOffersEligibity,
                    parentSubscriptionId,
                    setOpenError,
                    openError,
                });
                // openModal(card);
                handleCreateCrossSell(
                    response.data.checkCrossSellEligibility.serviceGroups,
                    serviceGroup
                );
            } else {
                openModal(card);
            }
        }
    };
    const handleMouseEnter = () => {
        setShowDropper(true);
    };

    const handleMouseLeave = () => {
        setShowDropper(false);
    };
    const handleNotInterested = async value => {
        await getAdobeAnalyticsApi({ graphql, value, propositionId });

        setHideCard(propositionId);
        setShowError(!openError);
        setErrorText({
            headText: '',
            subText:
                'Sorry you are not interested in this offer. Keep an eye out for some other great offers next time you log in.',
        });
    };
    return (
        <>
            {hideCard === propositionId ? (
                ''
            ) : (
                <>
                    <Box
                        className={classes.cardContainer}
                        onMouseEnter={() => handleMouseEnter()}
                        onMouseLeave={() => handleMouseLeave()}
                        style={{ borderRadius: showDropper ? '10px 10px 0px 0px' : '' }}
                        onClick={() => getRecomendedOffersEligibityUtil(3)}
                    >
                        <Box component='img' src={cardImg} className={classes.cardImg} />

                        <Box>
                            <OfferBox
                                leftTopHeading={leftTopHeading}
                                leftBottomHeading={strippedText}
                                rightTopHeading={rightTopHeading}
                                rightBottomHeading={rightBottomHeading}
                                rightBottomLastHeading={rightBottomLastHeading}
                                background={background}
                                divider1Color={divider1Color}
                                divider2Color={divider2Color}
                                fontColor={fontColor}
                            />
                        </Box>

                        {openChangeError ? (
                            <MyEirNewErrorPopup
                                open={openChangeError}
                                setOpen={setOpenChangeError}
                                message={ineligibleMessage}
                            />
                        ) : (
                            ''
                        )}
                        {openError ? (
                            <MyEirErrorHandle open={openError} setOpen={setOpenError} />
                        ) : (
                            ''
                        )}
                    </Box>
                    {showDropper && !isMobile && (
                        <Box
                            className={classes.dropper}
                            onMouseEnter={() => handleMouseEnter()}
                            onMouseLeave={() => handleMouseLeave()}
                            onClick={() => handleNotInterested(5)}
                        >
                            I’m not interested right now
                        </Box>
                    )}

                    {isMobile && (
                        <Box className={classes.mobile} onClick={() => handleNotInterested(5)}>
                            I’m not interested right now
                        </Box>
                    )}
                </>
            )}

            {showError ? (
                <MyEirRegistrationErrorsPopup
                    open={showError}
                    setOpen={setShowError}
                    headText={errorText.headText}
                    subText={errorText.subText}
                />
            ) : (
                ''
            )}
        </>
    );
};

export default RecomendedOfferCard;
