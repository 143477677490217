import { gql } from '@apollo/client';
import { CHANNEL } from '../../../components/myeirconstant/myEirConstant';

const ADDON_SCHEMA = `
  offerType
  serviceGroup
  code
  description
  channel
  charges{
      price
    }
  displayName
  details
  attributes {
    type
    value
    sortOrder
    icon
}
`;
const ENDPOINT = {
    GET_ELIGIBILITY_FOR_MANAGE_ADDONS: gql`
        query getAccountStatusAndCollectionStatus($accountId: String!, $token: String!) {
            getAccountStatus(accountId: $accountId, token: $token) {
                status
                brand
            }
            getAccountCollectionStatus(accountId: $accountId, token: $token)
        }
    `,
    GET_ACTIVE_ADDONS: gql`
        query getCurrentlySubscribedAddons($serviceId: Int!, $token: String!) {
            getCurrentlySubscribedAddons(serviceId: $serviceId, token: $token) {
                id
                code
                description
                itemGroupCode
                status
                charges {
                    priceVatIncluded
                    billingType
                }
                activatedAt
                terminatedAt
            }
        }
    `,

    GET_ADDONS: gql`
    query getAddOns(
      $offerTypeCode: String
      $serviceGroupCode: String
      $channel:String
      $codes: [String]
    ) {
      getAddOns(
          offerTypeCode: $offerTypeCode
          serviceGroupCode: $serviceGroupCode
          channel: $channel
          codes: $codes
      ) {
        ${ADDON_SCHEMA}
      }
    }
  `,
    ADD_ADDON_TO_SERVICE: gql`
        mutation addAddonToService($addonRequest: AddAddonInput!, $token: String!) {
            addAddonToService(addonRequest: $addonRequest, token: $token)
        }
    `,
    REMOVE_ADDONS: gql`
        mutation deleteAddons($addOnId: Int!, $serviceId: Int, $token: String!) {
            deleteAddons(addOnId: $addOnId, serviceId: $serviceId, token: $token)
        }
    `,
};

/**
 * @name getEligibilityForManageAddons - gets eligiblity checks for managing addons
 * @param {Object} Obj - attributes for the function
 * @param {GraphQL} Obj.graphql - appoloclient graphql constant
 * @param {GraphQL} graphql - appoloclient graphql constant
 * @returns {AccountAndCollectionStatus} - account's & collection status
 */
export const getEligibilityForManageAddons = async ({
    graphql,
    setCollectionStatus,
    setAccountStatus,
}) => {
    try {
        const { data } = await graphql.query({
            query: ENDPOINT.GET_ELIGIBILITY_FOR_MANAGE_ADDONS,
            variables: {
                accountId: sessionStorage.getItem('LoggedinUserAccountNumber'),
                token: sessionStorage.getItem('access_token'),
            },
        });
        setCollectionStatus(data.getAccountCollectionStatus);
        setAccountStatus(data.getAccountStatus.status);
        return data;
    } catch (error) {
        throw new Error(error);
    }
};
/**
 * @name getAddOnsApi - to get Addons
 * @param {Object} Obj - attributes for the function
 * @param {GraphQL} Obj.graphql - appoloclient graphql constant
 * @param {GraphQL} graphql - appoloclient graphql constant
 * @returns
 */
export const getAddOnsApi = async ({ graphql, serviceGroupCode, codes }) => {
    const { data } = await graphql.query({
        query: ENDPOINT.GET_ADDONS,
        variables: {
            // offerTypeCode: 'POSTPAY',
            // serviceGroupCode,
            channel: CHANNEL,
            codes,
        },
    });
    return data.getAddOns;
};

/**
 * @name getActiveAddons - gets active addons for managing addons
 * @param {Object} Obj - attributes for the function
 * @param {GraphQL} Obj.graphql - appoloclient graphql constant
 * @param {GraphQL} graphql - appoloclient graphql constant
 * @returns {ActiveAddons} - active addons
 */
export const getActiveAddons = async ({ graphql, servicesIds }) => {
    try {
        const { data } = await graphql.query({
            query: ENDPOINT.GET_ACTIVE_ADDONS,
            variables: {
                serviceId: servicesIds,
                token: sessionStorage.getItem('access_token'),
            },
        });

        return data;
    } catch (error) {
        throw new Error(error);
    }
};

export const addAddonToServiceApi = async ({ graphql, addonRequest }) => {
    try {
        const { data } = await graphql.mutate({
            mutation: ENDPOINT.ADD_ADDON_TO_SERVICE,
            variables: {
                addonRequest,
                token: sessionStorage.getItem('access_token'),
            },
        });
        return data;
    } catch (error) {
        throw new Error(error);
    }
};

export const removeAddonApi = async ({ graphql, addOnId, serviceId }) => {
    try {
        const { data } = await graphql.mutate({
            mutation: ENDPOINT.REMOVE_ADDONS,
            variables: {
                addOnId,
                serviceId,
                token: sessionStorage.getItem('access_token'),
            },
        });

        return data;
    } catch (error) {
        throw new Error(error);
    }
};
