import React, { useEffect, useState } from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
// import InputAdornment from '@mui/material/InputAdornment';
// import IconButton from '@mui/material/IconButton';
// import Visibility from '@mui/icons-material/Visibility';
// import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import {
    Grid,
    InputAdornment,
    IconButton,
    FormControl,
    InputLabel,
    OutlinedInput,
    TextField,
} from '@material-ui/core';

import { commonRegisterStyles } from '../css/myEirRegistrationStyles/commonRegisterStyles';
import { useCreatePasswordStyle } from '../css/useCreatePasswordStyle';
import { useWindowDimension } from '../../hooks/windowDimensions';
import MyEirRegisterHeader from './reusable/MyEirRegisterHeader';
import EirButton from '../../theme/EirButton';

const MyEirCreatePassword = () => {
    const [values, setValues] = React.useState({
        amount: '',
        password: '',
        weight: '',
        weightRange: '',
        showPassword: false,
    });

    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showLabel, setShowLabel] = useState(false);
    const [validEntry, setValidEntry] = useState(false);
    const [invalidEntry, setInvalidEntry] = useState(false);
    const [width, height] = useWindowDimension();

    const handleChange = event => {
        setConfirmPassword(event.target.value);
    };

    const handleLabel = val => {
        setShowLabel(val);
    };

    const handleChange2 = prop => event => {
        setValues({ ...values, [prop]: event.target.value });
    };
    // Validation Still needs to be done
    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleMouseDownPassword = event => {
        event.preventDefault();
    };

    const commonStyleClass = commonRegisterStyles();
    const styleClass = useCreatePasswordStyle();

    return (
        <div className={commonStyleClass.registrationContainer}>
            <div className={commonStyleClass.cardContainer}>
                <MyEirRegisterHeader headerText='Create password' />
                <div className={styleClass.bodyContainer}>
                    <Grid container className='create-password-body'>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            className='create-new-password-grid-input-field'
                        >
                            <FormControl
                                className='create-new-password-grid-input-text-field'
                                variant='outlined'
                            >
                                <InputLabel
                                    classes={{
                                        root: 'new-password-input-label',
                                        shrink: 'MuiInputLabel-shrink',
                                        focused: 'Mui-focused',
                                    }}
                                    htmlFor='outlined-adornment-password'
                                >
                                    New password
                                </InputLabel>
                                <OutlinedInput
                                    id='outlined-adornment-password'
                                    classes={{
                                        root: 'new-password-input-field-root',
                                        focused: 'Mui-focused',
                                        notchedOutline: 'field-notchedOutline',
                                    }}
                                    type={values.showPassword ? 'text' : 'password'}
                                    value={values.password}
                                    onChange={handleChange2('password')}
                                    endAdornment={
                                        <InputAdornment position='end'>
                                            <IconButton
                                                aria-label='toggle password visibility'
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge='end'
                                            >
                                                {values.showPassword ? (
                                                    <Visibility />
                                                ) : (
                                                    <VisibilityOff />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    labelWidth={108}
                                />
                            </FormControl>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            className='create-password-grid-input-field'
                        >
                            <TextField
                                className='create-password-grid-input-text-field'
                                id='confirm-password'
                                label={
                                    showLabel || !!confirmPassword
                                        ? 'Confirm password'
                                        : 'Confirm your password'
                                }
                                type='text'
                                variant='outlined'
                                color='primary'
                                value={confirmPassword}
                                InputLabelProps={{
                                    classes: {
                                        root: 'text-field-input-label-props-root',
                                        shrink:
                                            'text-field-input-label-props-shrink' +
                                            (invalidEntry
                                                ? ' text-field-input-label-props-shrink-invalid'
                                                : ''),
                                        focused:
                                            'text-field-input-label-props-focused' +
                                            (invalidEntry
                                                ? ' text-field-input-label-props-focused-invalid'
                                                : ''),
                                    },
                                }}
                                InputProps={{
                                    classes: {
                                        root:
                                            'text-field-input-props-root' +
                                            (!!confirmPassword
                                                ? ' text-field-input-props-root-filled'
                                                : ''),
                                        focused: 'text-field-input-props-focused',
                                        notchedOutline:
                                            'text-field-input-props-notchedOutline' +
                                            (invalidEntry
                                                ? ' text-field-input-props-notchedOutline-invalid'
                                                : ''),
                                        input: 'text-field-input-props-input',
                                    },
                                }}
                                onFocus={() => {
                                    handleLabel(true);
                                }}
                                onBlur={() => {
                                    handleLabel(false);
                                }}
                                onChange={handleChange}
                                // inputProps={{
                                //     inputmode: 'numeric',
                                //     pattern: '[0-9]*',
                                //     maxLength: 8,
                                // }}
                            />
                            {/* {invalidEntry && (
                                <div className='invalid-account-number-entered'>
                                    Please enter numbers only
                                </div>
                            )} */}
                            {/* enable while API integration
                            <div className="invalid-account-number-entered">Please check your account number and try again</div> */}
                        </Grid>
                    </Grid>
                </div>

                <div className={styleClass.createPasswordFooter}>
                    <EirButton
                        variants='primaryButtonMedium'
                        style={{ width: '11.5rem' }}
                        className={'primary-cta footer-cta'}
                    >
                        Next
                    </EirButton>

                    <div className='secondary-cta footer-cta'>
                        <span style={{ cursor: 'pointer' }}>Cancel</span>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default MyEirCreatePassword;
