import React, { useEffect, useState } from 'react';
import { Grid, Icon, LinearProgress, Button,Typography } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

function MyEirbalance(props) {
    return (
        <div className='balanceSection'>
            <h1>
                <span className='iconLayout'>
                    {' '}
                    <img src='/opencms/export/system/modules/my.portal.opencms/myeir_ion/images/my-eir-balance.png'></img>
                </span>
                My balance
            </h1>
            {props.subscription?
            (props.balanceDetails?
                <h1 className='purpleText'>&euro;{(props.balanceDetails.balance / 100).toFixed(2)}</h1>
                :
                <Grid container justify="center">
                <Grid list md={12} xs={12} sm={12}>
                    <span className='errorText'>There was an error loading your details</span>
                </Grid>
                <Grid list md={12} xs={12} sm={12}>
                    <a className='tryAgain' onClick={()=>window.location.reload()}> Click here to try again</a>
                </Grid>    
            </Grid>
                )
            :<Grid container justify="center">
                <Grid list md={12} xs={12} sm={12}>
                    <span className='errorText'>There was an error loading your details</span>
                </Grid>
                <Grid list md={12} xs={12} sm={12}>
                    <a className='tryAgain' onClick={()=>window.location.reload()}> Click here to try again</a>
                </Grid>    
            </Grid>
            }
            

            <Grid item xs={12} sm={12} md={12}>
                <div>
                    <Button className='faqBtn topupbtn'>
                        <a href='/prepay/topup'>Top up</a>
                    </Button>
                </div>
            </Grid>
        </div>
    );
}


export default MyEirbalance;
