import React from 'react';
import { Box, Card, Typography } from '@material-ui/core';
import useStyles from './myServicesStyles';
import ServicesLink from './ServicesLink';
import ServicesInfo from './ServicesInfo';
import ServicesIcon from './ServicesIcon';
import MyEirServiceBannerImg from './MyEirServiceBannerImg';
import { useWindowDimension } from '../../../hooks/windowDimensions';
import { ACCOUNT_STATUS } from '../../../components/myeirconstant/myEirConstant';

const MyEirServicesCard = ({
    isActiveTv,
    links,
    icon,
    numCards,
    banner,
    title,
    offerName,
    number,
    errorMessage,
    miniText,
    name,
    price,
    validityText,
    background,
    subscriptionId,
    propositionId,
    attributes,
    serviceGroup,
    modalImage,
    servicesOffer,
    hasNoOffer,
    finalArr,
    onOfferPage,
    adobeOffer_tariff_code,
    contractType,
}) => {
    const classes = useStyles();
    const [width, height] = useWindowDimension();
    const userStatus = sessionStorage.getItem('Account_status');

    return (
        <Box>
            {width < 600 ? (
                <Card
                    className={classes.card}
                    style={{
                        marginBottom: onOfferPage === true ? '20px' : '24.7px',
                    }}
                >
                    {onOfferPage === false && (
                        <Box className={classes.info}>
                            <ServicesInfo
                                title={title}
                                offerName={offerName}
                                number={number}
                                errorMessage={errorMessage}
                                contractType={contractType}
                            />
                            <Box className={classes.cardLinks}>
                                <ServicesLink
                                    links={links}
                                    errorMessage={errorMessage}
                                    isActiveTv={isActiveTv}
                                />
                            </Box>
                            <ServicesIcon userStatus={userStatus} icon={icon} numCards={numCards} />
                        </Box>
                    )}
                    {(name || (hasNoOffer && finalArr.length === 1)) && (
                        <Box className={classes.image}>
                            <MyEirServiceBannerImg
                                banner={banner}
                                numCards={numCards}
                                validityText={validityText}
                                miniText={miniText}
                                name={name}
                                price={price}
                                background={background}
                                subscriptionId={subscriptionId}
                                propositionId={propositionId}
                                attributes={attributes}
                                serviceGroup={serviceGroup}
                                modalImage={modalImage}
                                hasNoOffer={hasNoOffer}
                                onOfferPage={onOfferPage}
                                adobeOffer_tariff_code={adobeOffer_tariff_code}
                                contractType={contractType}
                                finalArr={finalArr}
                            />
                        </Box>
                    )}
                </Card>
            ) : (
                <Card
                    className={numCards === 1 ? classes.singleServiceCard : classes.card}
                    style={{
                        maxHeight:
                            userStatus === ACCOUNT_STATUS.BILLABLE_EMPTY ||
                            userStatus === ACCOUNT_STATUS.UNBILLABLE
                                ? '205px'
                                : numCards === 1
                                ? '162px'
                                : '291.95px',
                        height:
                            userStatus === ACCOUNT_STATUS.BILLABLE_EMPTY ||
                            userStatus === ACCOUNT_STATUS.UNBILLABLE
                                ? '205px'
                                : '',
                    }}
                >
                    {onOfferPage === false && (
                        <Box className={numCards === 1 ? classes.singleServiceinfo : classes.info}>
                            <ServicesInfo
                                title={title}
                                offerName={offerName}
                                number={number}
                                errorMessage={errorMessage}
                                contractType={contractType}
                            />
                            <Box className={classes.cardLinks}>
                                <ServicesLink
                                    links={links}
                                    errorMessage={errorMessage}
                                    isActiveTv={isActiveTv}
                                />
                            </Box>
                            <ServicesIcon userStatus={userStatus} icon={icon} numCards={numCards} />
                        </Box>
                    )}
                    {(name || (hasNoOffer && finalArr.length === 1)) && (
                        <Box
                            className={numCards === 1 ? classes.singleServiceImage : classes.image}
                            style={{
                                height:
                                    numCards === 1 &&
                                    (userStatus === ACCOUNT_STATUS.BILLABLE_EMPTY ||
                                        userStatus === ACCOUNT_STATUS.UNBILLABLE)
                                        ? '205px'
                                        : numCards === 1
                                        ? '162.73px'
                                        : '130px',
                            }}
                        >
                            <MyEirServiceBannerImg
                                banner={banner}
                                finalArr={finalArr}
                                numCards={numCards}
                                validityText={validityText}
                                miniText={miniText}
                                name={name}
                                price={price}
                                background={background}
                                subscriptionId={subscriptionId}
                                propositionId={propositionId}
                                attributes={attributes}
                                serviceGroup={serviceGroup}
                                modalImage={modalImage}
                                servicesOffer={servicesOffer}
                                hasNoOffer={hasNoOffer}
                                adobeOffer_tariff_code={adobeOffer_tariff_code}
                                contractType={contractType}
                                onOfferPage={onOfferPage}
                            />
                        </Box>
                    )}
                </Card>
            )}
        </Box>
    );
};
export default MyEirServicesCard;
