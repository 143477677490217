import React, { useState,useEffect } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import useStyles from './styles';
// import { useMyBalance } from '../../css/useMyBalance';
import PaymentFlowModal from '../../payment-flow/PaymentFlowModal';
import { useWindowDimension } from '../../../hooks/windowDimensions';
import MyEirLoggedInModal from '../../loggedInModal/MyEirLoggedInModal';
import MyEirBalanceText, { formattingDate } from './MyEirBalanceText';
import { myBalanceStyles } from './myBalanceStyles';
import { formatDate, suffixChecker } from '../current-usage/currentUsage.util';
import moment from 'moment-timezone';
import EirButton from '../../../theme/EirButton';
import { getEligibilityForManageAddonsApi } from '../redesign.api';
import { useApolloClient } from '@apollo/client';
import { ACCOUNT_STATUS } from '../../../components/myeirconstant/myEirConstant';

const MyEirBalance = ({
    balance,
    getMyBalance,
    isError,
    balanceOverdue,
    debitDate,
    billData,
    DebitCard,
    lastBillDueDate,
}) => {
    const classes = useStyles();
    // const classes1 = useMyBalance();
    const classes1 = myBalanceStyles();
    const [openPayment, setPaymentModal] = useState(false);
    const [width, height] = useWindowDimension();

    const togglePayment = () => {
        setPaymentModal(!openPayment);
    };

    const signSymbol = Math.sign(balance) === 1 ? '-' : '';
    const mainbalance = (Math.abs(balance) / 100).toFixed(2);
    const bal = mainbalance.toString().split('.');
    const balanceCondition = parseInt(signSymbol + (Math.abs(balance) / 100).toFixed(2));
    const dueDate = new Date(debitDate);
    const graphql = useApolloClient();
    const [ceasedUser,setCeasedUser]=useState(false);
    
    const getAccountStatus = async () => {
        const res = await getEligibilityForManageAddonsApi({ graphql });
        sessionStorage.setItem('Account_status', res.getAccountStatus.status);
        if(res.getAccountStatus.status==ACCOUNT_STATUS.BILLABLE_EMPTY){
             setCeasedUser(true);
        }
    }
    useEffect(()=>{
     getAccountStatus();
    },[]);

    return (
        <>
            <Box className={classes.parentBalanceContainer}>
                <Box className={classes.balanceContainer}>
                    <Box>
                        <Typography className={classes.balanceText}>My balance</Typography>
                    </Box>
                    {width >= 1020 ? (
                        <Box className={classes1.myEirBalanceTextComponent}>
                            {billData?.length === 0 ? (
                                ''
                            ) : DebitCard?.length === 0 ? (
                                ''
                            ) : debitDate === 'NA' || balanceCondition <= 0 ? (
                                ' '
                            ) : (
                                <MyEirBalanceText
                                    debitDate={debitDate}
                                    bal={bal}
                                    lastBillDueDate={lastBillDueDate}
                                />
                            )}
                        </Box>
                    ) : (
                        ''
                    )}
                    <Box className={classes.myBalanceInfo}>
                        <Box
                            className={`${
                                isError ? classes.myBalanceErrorText : classes.myBalanceBill
                            }`}
                        >
                            <Box className={classes.myBill}>
                                {!isNaN(balance) && (
                                    <>
                                        {signSymbol} €{bal[0]}.
                                        <Box className={classes.myBillTest}>{bal[1]}</Box>{' '}
                                        {balanceOverdue && ''}{' '}
                                    </>
                                )}
                            </Box>

                            {balance == 'NA' &&
                                (!isError ? (
                                    <></>
                                ) : (
                                    <Typography className={classes.myBalanceErrorText}>
                                        We are having trouble loading your Balance, please try again
                                        later
                                    </Typography>
                                ))}
                        </Box>
                    </Box>
                    <Typography className={classes.myBalanceText}>
                        Includes latest bill, overdue payments and adjustments.
                    </Typography>
                    <>
                        {width >= 1020 ? (
                            // <Box
                            //     className={
                            //         mainbalance >= 0
                            //             ? classes.makePayContainerDisabled
                            //             : classes.makePayContainer
                            //     }
                            // >
                            //     <Button
                            //         className={classes.paymentContent}
                            //         onClick={togglePayment}
                            //         disabled={mainbalance >= 0 ? true : false}
                            //     >
                            //         Make a payment
                            //     </Button>
                            // </Box>

                            <>
                                {balance == 'NA' ? (
                                    ''
                                ) : (
                                    <>
                                        {((!isNaN(balanceCondition) && balanceCondition <= -100) ||(ceasedUser && mainbalance==0.00)) ? (
                                            ''
                                        ) : (
                                            <Box className={classes.makePayContainer}>
                                                <EirButton
                                                    variants='primaryButtonMedium'
                                                    style={{ width: '11rem' }}
                                                    // className={classes.paymentContent}
                                                    onClick={togglePayment}
                                                >
                                                    Make a payment
                                                </EirButton>
                                            </Box>
                                        )}
                                    </>
                                )}
                            </>
                        ) : (
                            ''
                        )}
                    </>

                    <PaymentFlowModal
                        toggle={togglePayment}
                        open={openPayment}
                        amount={(Math.abs(balance) / 100).toFixed(2)}
                        balance={balance}
                    />
                </Box>
            </Box>
            {/* {width > 1020 ? (
                <Box className={classes.makePayMobileContainer}>
                    <Button className={classes.paymentContent} onClick={togglePayment}>
                        Make a payment
                    </Button>
                </Box>
            ) : (
                ''
            )} */}
            <>
                {width < 1020 ? (
                    <Box>
                        <Box>
                            {billData.length === 0 ? (
                                ''
                            ) : DebitCard.length === 0 ? (
                                ''
                            ) : debitDate === 'NA' || balanceCondition <= 0 ? (
                                ' '
                            ) : (
                                <Typography
                                    variant='h5'
                                    component='h2'
                                    align='center'
                                    className={classes1.text}
                                >
                                    Your payment will be applied for on or after
                                    <br />
                                    <Typography
                                        component='span'
                                        variant='body2'
                                        className={classes1.paymentDueText}
                                    >
                                        {formattingDate(lastBillDueDate)}
                                    </Typography>
                                </Typography>
                            )}
                        </Box>
                        <>
                            {/* <Box
                                className={
                                    mainbalance >= 0
                                        ? classes.makePayMobileContainerDisabled
                                        : classes.makePayMobileContainer
                                }
                            >
                                <Button
                                    className={classes.paymentContent}
                                    onClick={togglePayment}
                                    disabled={mainbalance >= 0 ? true : false}
                                >
                                    Make a payment
                                </Button>
                            </Box> */}
                            {balance == 'NA' ? (
                                ''
                            ) : (
                                <>
                                    {!isNaN(balanceCondition) && balanceCondition <= -100 ? (
                                        ''
                                    ) : (
                                        <Box className={classes.makePayContainer}>
                                            <EirButton
                                                style={{ width: '11rem' }}
                                                variants='primaryButtonMedium'
                                                // className={classes.paymentContent}
                                                onClick={togglePayment}
                                            >
                                                Make a payment
                                            </EirButton>
                                        </Box>
                                    )}
                                </>
                            )}
                        </>
                    </Box>
                ) : (
                    ''
                )}
            </>
        </>
    );
};

export default MyEirBalance;
