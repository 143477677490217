import { makeStyles } from '@material-ui/core/styles';

export const useCreatePasswordStyle = makeStyles(theme => ({
    bodyContainer: {
        minHeight: '155px',
        paddingTop: '42px',
        paddingBottom: '8px',
        position: 'relative',

        '@media(max-width: 599px)': {
            minHeight: '294px',
            paddingTop: '24px',
            paddingBottom: '24px',
            paddingLeft: '16px',
            paddingRight: '16px',
        },

        '& .create-password-body': {
            maxWidth: '376px',
            margin: '0px auto',
        },

        '& .create-new-password-grid-input-field': {
            marginBottom: '32px',
            '@media(max-width: 599px)': {
                marginBottom: '23px',
            },

            ' & .create-new-password-grid-input-text-field': {
                width: '100%',
                fontFamily: theme.typography.body2.fontFamily,
                fontStyle: 'normal',
                fontWeight: 400,

                '&:hover': {
                    '& .MuiInputLabel-shrink': {
                        color: theme.palette.primary.heather,
                        lineHeight: '16px',
                    },
                },
                ' & .MuiInputLabel-root': {
                    color: theme.palette.primary.heather80,
                    fontFamily: theme.typography.body2.fontFamily,
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: theme.typography.body1.fontSize,
                    lineHeight: '19px',
                    letterSpacing: '0.15px',
                },

                '& .MuiInputLabel-shrink.Mui-focused': {
                    color: theme.palette.primary.heather,
                    lineHeight: '16px',
                },

                '& .MuiOutlinedInput-root': {
                    color: theme.palette.primary.heather,
                    fontFamily: theme.typography.body2.fontFamily,
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: theme.typography.body1.fontSize,
                    // lineHeight: '19px',
                    letterSpacing: '0.15px',

                    // padding: '19px 12px 18px 12px',
                },

                '& .new-password-input-field-root.Mui-focused, & .new-password-input-field-root:hover': {
                    ' & .field-notchedOutline': {
                        borderColor: theme.palette.primary.heather,
                    },
                },

                // ' & .MuiOutlinedInput-notchedOutline': {
                //     borderColor: theme.palette.primary.heather,
                //     borderWidth: '2px',
                // },
            },
        },

        '& .create-password-grid-input-field': {
            marginBottom: '32px',
            '@media(max-width: 599px)': {
                marginBottom: '23px',
            },
        },

        ' & .create-password-grid-input-field': {
            ' & .create-password-grid-input-text-field': {
                width: '100%',

                fontFamily: theme.typography.body2.fontFamily,
                fontStyle: 'normal',
                fontWeight: 400,

                ' & .text-field-input-label-props-root': {
                    color: theme.palette.primary.heather80,
                    fontFamily: theme.typography.body2.fontFamily,
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: theme.typography.body1.fontSize,
                    lineHeight: '19px',
                    letterSpacing: '0.15px',
                },
                '& .text-field-input-label-props-focused, & .text-field-input-label-props-shrink': {
                    color: theme.palette.primary.heather,
                    // fontSize: "12px",
                    lineHeight: '16px',
                },
                '& .text-field-input-label-props-focused-invalid, & .text-field-input-label-props-shrink-invalid': {
                    color: theme.palette.primary.error,
                },
                ' & .text-field-input-props-root': {
                    '& .text-field-input-props-input': {
                        color: theme.palette.primary.heather,
                        fontFamily: theme.typography.body2.fontFamily,
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: theme.typography.body1.fontSize,
                        lineHeight: '19px',
                        letterSpacing: '0.15px',

                        padding: '19px 12px 18px 12px',
                    },
                },
                ' & .text-field-input-props-root-filled': {
                    ' & .text-field-input-props-notchedOutline': {
                        borderColor: theme.palette.primary.heather,
                        borderWidth: '2px',
                    },
                    ' & .text-field-input-props-notchedOutline-invalid': {
                        borderColor: theme.palette.primary.error,
                    },
                },
                ' & .text-field-input-props-focused, & .text-field-input-props-root:hover': {
                    ' & .text-field-input-props-notchedOutline': {
                        borderColor: theme.palette.primary.heather,
                    },
                    ' & .text-field-input-props-notchedOutline-invalid': {
                        borderColor: theme.palette.primary.error,
                    },
                },
            },

            '& .invalid-account-number-entered': {
                fontFamily: theme.typography.body2.fontFamily,
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: theme.typography.inputLabel.fontSize,
                lineHeight: '20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                letterSpacing: '0.4px',
                color: theme.palette.primary.error,

                marginTop: '3px',
            },
        },
    },
    inputColor: {
        color: theme.palette.primary.heather,
    },
    createPasswordFooter: {
        textAlign: 'center',
        minHeight: '80px',
        // marginTop: '150px',
        paddingBottom: '62px',

        '@media (min-width: 0px) and (max-width: 1019px)': {
            // marginTop: '137px',
            paddingBottom: '32px',
        },

        '& .footer-cta': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '0 auto',
            width: '184px',
            borderRadius: '4100px',
            fontFamily: theme.typography.subtitle1.fontFamily,
            fontStyle: 'normal',
            fontWeight: theme.typography.subtitle1.fontWeight,
            fontSize: theme.typography.subtitle1.fontSize,
            lineHeight: theme.typography.subtitle1.lineHeight,
            textAlign: 'center',

            '@media (min-width: 0px) and (max-width: 1019px)': {
                width: '264px',
            },
        },

        '& .primary-cta': {
            minHeight: '42px',
            marginBottom: '3px',
            borderColor: 'transparent',
            color: theme.palette.primary.white,
            background: theme.palette.primary.gray550,
        },

        '& .secondary-cta': {
            minHeight: '41px',
            color: theme.palette.primary.sunset,
        },
    },
}));
