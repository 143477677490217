import React, { useEffect, useRef, useState, useContext } from 'react';
import { useWindowDimension } from '../../../hooks/windowDimensions.js';
import RecomendedOfferCard from './RecomendedOfferCard';
import cardImg from '../images/RecommendOffer1.png';
import whiteBackground from '../images/ffff.png';
import eye from '../images/remove-red-eye.png';
import { Box, IconButton, Step, StepIcon, Stepper, Typography, Button } from '@material-ui/core';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import { recomendedOfferStyles } from './recomendedOfferStyles';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { getAdobeOffers, getAdobeAnalyticsApi } from '../my-services/myServices.util';
import { useApolloClient } from '@apollo/client';
import {
    getDpEligibilityAndAdressInfo,
    getRecomendedOffers,
    getRecomendedOffersProspectUtil,
} from './recomendedOffers.util.js';
import theme from '../../../theme/MyEirTheme';
import { removeDuplicateArrayElements, structureData } from '../../../utils/Format.util.js';
import OfferUpgradeModal from '../Offer-upgrade-modal/OfferUpgradeModal.js';
import { handlingRedirection } from '../offers-section/Offers.util.js';
import MyEirErrorHandle from '../../eir-porting/MyEirErrorHandle';
import {
    DOMAINS,
    MY_EIR_CONST,
    SERVICE_GROUPS,
} from '../../../components/myeirconstant/myEirConstant';
import Loading from '../../../components/loading/Loading.js';
import { setCookie } from '../redesign.api.js';
import { useHistory } from 'react-router-dom';
import { getApvDetails } from '../amazon-prime-video/apv.util';
import { getAmazonContents } from '../../eir-amazon-prime-video/amazonContents.util';
import { getMyPlanDetails } from '../my-services/myServices.util';
import { RedesignContext } from '../../../global-context-redesign';
import ApvOfferCard from './ApvOfferCard.js';
import MyEirAmazonPrimeVideo from '../../eir-amazon-prime-video/AmazonPrimeVideo';
import EirButton from '../../../theme/EirButton.js';

SwiperCore.use([Navigation]);

const RecomendedOffersContainer = ({ onOfferPage = false }) => {
    const value = useContext(RedesignContext);
    const classes = recomendedOfferStyles();
    const history = useHistory();
    const [finalArr, setFinalArr] = useState([]);
    const [swiper, setSwiper] = useState(null);
    const [recomemdedOffer, setRecomemdedOffer] = useState([]);
    const [getOffers, setGetOffers] = useState([]);
    const [isCTALoading, setIsCTALoading] = useState(false); // cta loader
    const [loading, setLoading] = useState(true);
    const [activeStep, setActiveStep] = useState(0);
    const [width, height] = useWindowDimension();
    const [isModalOpen, setIsModalOpen] = useState();
    const [selectedCard, setSelectedCard] = useState(null);
    const [openError, setOpenError] = useState(false);
    const [openOffer, setOpenOffer] = useState(isModalOpen);
    const [csTvData, setCsTvData] = useState({});
    const [attributesData, setAttributesData] = useState({});
    const [openSelectionModal, setOpenSelectionModal] = useState(false);
    const [selectedParentSubId, setSelectedParentSubId] = useState(null);
    const [selectedServiceGroup, setSelectedServiceGroup] = useState('');
    const [isOffersPage, setIsOffersPage] = useState(false);
    const [parentId, setParentId] = useState('');
    const [hideCard, setHideCard] = useState('');
    const swiperRef = useRef(null);
    const graphql = useApolloClient();
    const [isBlanket, setIsBlanket] = useState(false);
    const { MOBILE, IPTV } = DOMAINS;
    const [apvCustomerType, setApvCustomerType] = useState(
        value?.state?.myServices?.apvCustomerType
    );
    const [apvOffer, setApvOffer] = useState(value?.state?.myServices?.apvOffer);
    const [apvOfferStatus, setApvOfferStatus] = useState(value?.state?.myServices?.apvOfferStatus);
    const [apvWithOffers, setApvWithOffers] = useState([]);
    const [planArr, setPlanArr] = useState(value?.state?.myServices?.planArr);
    const dualplayObjects = [];
    const pstnObjects = [];
    const tvObjects = [];
    const mobileObjects = [];
    const mbbObjects = [];
    const [apvId, setApvId] = useState('');
    const [isContractsError, setIsContractsError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isActiveTv, setIsActiveTv] = useState(false);
    const [apvToggle, setApvToggle] = useState(false);
    const blanketCover =
        window.location.origin === 'https://my.eir.ie'
            ? window.location.origin + '/uploads/blanket_cover_0fcbf1be01.png'
            : window.location.origin + '/uploads/blanket_cover_579e5dc675.png';
    const blanketPic =
        window.location.origin === 'https://my.eir.ie'
            ? window.location.origin + '/uploads/blanket_pic_6ea57aaa36.png'
            : window.location.origin + '/uploads/blanket_pic_acd7765d31.png';
    const [contentData, setContentData] = useState({});
    const [match, setMatch] = useState({});

    const handleViewOffers = () => {
        setIsBlanket(true);
    };

    const getMyPlanDetailsUtil = async () => {
        await getMyPlanDetails({
            graphql,
            setIsContractsError,
            setPlanArr,
            dualplayObjects,
            pstnObjects,
            tvObjects,
            mobileObjects,
            mbbObjects,
            setIsLoading,
            value,
        });
    };

    const getApvDetailsUtil = async apvId => {
        await getApvDetails({
            graphql,
            setApvCustomerType,
            setApvOffer,
            setApvOfferStatus,
            value,
            apvId,
            setIsLoading,
        });
    };

    const getAmazonPrime = async () => {
        try {
            const ocatData = await getAmazonContents({ graphql, customerType: apvCustomerType });
            setContentData(ocatData);
        } catch (error) {
            console.log('amazon prime error:', error);
        }
    };
    console.log('content data', contentData);

    const ocatApv = [
        {
            type: 'apv',
        },
    ];
    const handleRedirectValues = reference => {
        const redirectPath = handlingRedirection({
            reference,
            cartType: 'CROSS_SELL',
            serviceGroup: attributesData.serviceGroup,
            subscriptionId:
                attributesData.serviceGroup === SERVICE_GROUPS.TV
                    ? selectedParentSubId || csTvData?.getCSTVDetails[0]?.id
                    : '',
            MY_EIR_CONST,
            setOpenOffer,
            setOpenError,
            openOffer,
            openError,
            isModalOpen,
            setIsModalOpen,
        });

        return redirectPath;
    };

    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const handleOffers = () => {
        history.push('/account/preferences');
        goToTop();
    };

    const handlingUpgrade = async value => {
        await getAdobeAnalyticsApi({ graphql, value, propositionId: attributesData.propositionId });

        if (csTvData?.getCSTVDetails?.length === 1) {
            setIsCTALoading(true);
            setOpenSelectionModal(false);
            const { data } = await getRecomendedOffersProspectUtil({
                graphql,
                setOpenError,
                openError,
                setIsModalOpen,
            });
            setSelectedParentSubId(csTvData?.getCSTVDetails[0]?.id);
            const reference = data.createCrossSellProspect;
            const redirectPath = handleRedirectValues(reference);
            if (redirectPath !== undefined) window.location.href = redirectPath;
        } else {
            if (selectedCard.serviceGroupCode === SERVICE_GROUPS.DUALPLAY) {
                setIsCTALoading(true);
                setOpenSelectionModal(false);
                const { data } = await getRecomendedOffersProspectUtil({
                    graphql,
                    setOpenError,
                    openError,
                    setIsModalOpen,
                });

                const reference = data.createCrossSellProspect;
                const redirectPath = handleRedirectValues(reference);
                if (redirectPath !== undefined) window.location.href = redirectPath;
            } else {
                setIsCTALoading(false);
                setOpenSelectionModal(true);
            }
        }
    };

    const handleNotInterested = async value => {
        await getAdobeAnalyticsApi({ graphql, value, propositionId: attributesData.propositionId });
        setIsModalOpen(false);
    };
    const convertCentsToPounds = cents => {
        const pounds = cents / 100;
        return pounds.toFixed(2);
    };

    const goNext = () => {
        if (swiper) {
            swiper.slideNext();
        }
    };

    const goPrev = () => {
        if (swiper) {
            swiper.slidePrev();
        }
    };
    const calculateSlidesPerView = () => {
        if (getOffers.length <= 2) {
            return getOffers.length;
        } else {
            return 3;
        }
    };

    const handleStepClick = stepIndex => {
        const swiper = swiperRef.current.swiper;
        setActiveStep(stepIndex);
        swiper.slideTo(stepIndex);
    };
    const getAdobeOffersUtil = async () => {
        const res = await getAdobeOffers({ graphql });
        if (res) {
            setRecomemdedOffer(res?.recommendedOffers);
        } else {
            setRecomemdedOffer([]);
        }
    };
    const getRecomendedOffersUtil = async () => {
        await getRecomendedOffers({ graphql, recomemdedOffer, setGetOffers, setLoading });
    };
    const getDpEligibilityAndAdressInfoUtil = async () => {
        await getDpEligibilityAndAdressInfo({ graphql, setCsTvData });
    };

    useEffect(() => {
        getAdobeOffersUtil();
        if (planArr?.length == 0) {
            getMyPlanDetailsUtil();
        }
        getApvDetailsUtil();
    }, []);
    useEffect(() => {
        getDpEligibilityAndAdressInfoUtil();
    }, [csTvData]);
    useEffect(() => {
        getRecomendedOffersUtil();
    }, [recomemdedOffer]);
    useEffect(() => {
        getAmazonPrime();
    }, [apvCustomerType]);
    useEffect(() => {
        if (getOffers?.length > 0) {
            setLoading(true);
            const finalArray = getOffers.map(item => {
                const matchOffer =
                    hideCard === ''
                        ? recomemdedOffer.find(offer => offer.tariff_code === item.code)
                        : recomemdedOffer.find(
                              offer =>
                                  offer.tariff_code === item.code && offer.propositionId != hideCard
                          );

                if (matchOffer) {
                    return {
                        ...item,
                        adobeOffer: matchOffer,
                        apv: ocatApv,
                    };
                }
                return item;
            });
            const uniqueArray = removeDuplicateArrayElements(finalArray, 'code');

            setFinalArr(uniqueArray.sort((a, b) => a.adobeOffer.rank - b.adobeOffer.rank));
            setLoading(false);
        }
        setApvWithOffers([...ocatApv, ...getOffers]);
    }, [getOffers, recomemdedOffer]);

    useEffect(() => {
        if (selectedCard) {
            setAttributesData(structureData(selectedCard));
            setCookie('CS', 'SIM_ONLY', selectedCard?.simOnly);
            setCookie('CS', 'CODE', selectedCard?.code);
            setCookie('CS', 'CATALOG_CODE', selectedCard?.offerCode);
            setCookie('CS', 'SERVICE_GROUP', selectedCard?.serviceGroupCode);
            setCookie('CS', 'PORT_IN', selectedCard?.portIn);

            setCookie('CS', 'COMMITMENT_DURATION', selectedCard?.commitmentDuration);
        }
    }, [selectedCard]);

    useEffect(() => {
        if (window.location.href.indexOf('/offers') > -1) {
            setIsOffersPage(true);
        }
    }, []);

    useEffect(() => {
        if (csTvData?.getCSTVDetails?.length === 1) {
            let id = csTvData?.getCSTVDetails[0]?.id;

            let isEligible = csTvData?.getCSTVDetails[0]?.csEligiblity?.dpEligibity?.[0]?.eligible;
            let status = csTvData?.getCSTVDetails[0]?.status;

            if (status === 'ACTIVE' && isEligible) {
                setParentId(id);
            }
        }
    }, [csTvData]);

    useEffect(() => {
        const tvSession = planArr.find(
            session => session.status === 'ACTIVE' && session.serviceGroup === SERVICE_GROUPS.TV
        );

        if (tvSession) {
            const tvSession = planArr.find(
                session => session.status === 'ACTIVE' && session.serviceGroup === SERVICE_GROUPS.TV
            );

            if (tvSession) {
                setIsActiveTv(true);
            }
            const tvParentId = tvSession?.dependsOn;
            const broadbandSession = planArr.find(
                session =>
                    session.status === 'ACTIVE' &&
                    session.serviceGroup === SERVICE_GROUPS.DUALPLAY &&
                    session.subId === tvParentId
            );

            if (broadbandSession) {
                const bbId = broadbandSession.serviceId;
                setApvId(bbId);
                getApvDetailsUtil(bbId);
            } else {
                const firstBB = planArr.find(
                    session =>
                        session.status === 'ACTIVE' &&
                        session.serviceGroup === SERVICE_GROUPS.DUALPLAY
                );

                if (firstBB) {
                    const firstBBId = firstBB.serviceId;
                    setApvId(firstBBId);
                    getApvDetailsUtil(firstBBId);
                }
            }
        } else {
            const firstBB = planArr.find(
                session =>
                    session.status === 'ACTIVE' && session.serviceGroup === SERVICE_GROUPS.DUALPLAY
            );

            if (firstBB) {
                const firstBBId = firstBB.serviceId;
                setApvId(firstBBId);
                getApvDetailsUtil(firstBBId);
            }
        }
    }, [planArr]);
    return (
        <>
            {loading ? (
                <Loading
                    className='services-loading-animation'
                    style={{ justifyContent: 'center' }}
                />
            ) : (
                <>
                    {(width >= 1020 && finalArr.length > 0) || isOffersPage ? (
                        <>
                            {!isBlanket && !isOffersPage && (
                                <Box className={classes.offersContainer}>
                                    <Box
                                        component='img'
                                        src={blanketCover}
                                        className={classes.cover}
                                    />
                                    <Box
                                        component='img'
                                        src={blanketPic}
                                        className={classes.blanketPic}
                                    />
                                    <Box className={classes.offersContent}>
                                        <Typography className={classes.offersText}>
                                            Discover some recommended offers for you
                                        </Typography>
                                        <Box
                                            className={classes.makePayContainer}
                                            onClick={handleViewOffers}
                                        >
                                            <EirButton
                                                style={{ width: '9rem' }}
                                                variants='primaryButtonMedium'
                                                // className={classes.paymentContent}
                                            >
                                                View Offers
                                                <Typography
                                                    component='span'
                                                    className={classes.eye}
                                                >
                                                    <Box
                                                        component='img'
                                                        src={eye}
                                                        className={classes.eyepic}
                                                    />
                                                </Typography>
                                            </EirButton>
                                        </Box>
                                    </Box>
                                </Box>
                            )}

                            {isOffersPage && finalArr.length > 0 ? (
                                <Box
                                    className={classes.mainContainer}
                                    style={{
                                        paddingTop:
                                            onOfferPage === true && width <= 1020 ? '20px' : '0px',
                                    }}
                                >
                                    <Typography
                                        style={{
                                            paddingBottom: finalArr.length < 4 ? '20px' : '0px',
                                        }}
                                        className={classes.mainHeading}
                                    >
                                        Recommended offers for you
                                    </Typography>
                                    {width >= 601 && finalArr.length > 3 ? (
                                        <Box style={{ textAlign: 'right' }}>
                                            <IconButton
                                                className={classes.navigateIcon}
                                                onClick={goPrev}
                                            >
                                                <NavigateBeforeIcon />
                                            </IconButton>
                                            <IconButton
                                                className={classes.navigateIcon}
                                                onClick={goNext}
                                            >
                                                <NavigateNextIcon />
                                            </IconButton>
                                        </Box>
                                    ) : (
                                        ''
                                    )}

                                    <Box className={classes.swiperContainer}>
                                        <Swiper
                                            className='mainSwiper'
                                            ref={swiperRef}
                                            onSwiper={setSwiper}
                                            slidesPerView={
                                                width < 601 ? 1.5 : calculateSlidesPerView()
                                            }
                                            slidesPerGroup={width < 601 ? 1 : 3}
                                            onSlideChange={swiper => {
                                                setActiveStep(swiper.activeIndex);
                                            }}
                                            allowTouchMove={width >= 601 ? false : true}
                                        >
                                            {contentData?.getAmazonPrimeContents?.positionOfTile ===
                                                'before' && (
                                                <SwiperSlide style={{ display: 'flex' }}>
                                                    {apvCustomerType &&
                                                        apvOfferStatus === 'NO_ONGOING_REQUEST' && (
                                                            <ApvOfferCard
                                                                cardImg={
                                                                    origin +
                                                                    contentData
                                                                        ?.getAmazonPrimeContents
                                                                        ?.desktopTile?.imageURL
                                                                }
                                                                leftTopHeading='Sign up now'
                                                                leftBottomHeading='Amazon Prime Video on us'
                                                                strippedText='amazon'
                                                                rightTopHeading={
                                                                    apvOffer ===
                                                                    'eir-prime-video-tv'
                                                                        ? 'For'
                                                                        : 'Only'
                                                                }
                                                                rightBottomHeading={
                                                                    apvOffer ===
                                                                    'eir-prime-video-tv'
                                                                        ? 'free'
                                                                        : '5.99'
                                                                }
                                                                rightBottomLastHeading={
                                                                    apvOffer ===
                                                                    'eir-prime-video-tv'
                                                                        ? ''
                                                                        : 'a month'
                                                                }
                                                                background={
                                                                    theme.palette.primary.heather
                                                                }
                                                                fontColor={
                                                                    theme.palette.primary.white
                                                                }
                                                                divider1Color='#fbfbfb'
                                                                divider2Color='#003380'
                                                                apvToggle={apvToggle}
                                                                setApvToggle={setApvToggle}
                                                                links={
                                                                    <span
                                                                        className={
                                                                            classes.amazonPrime
                                                                        }
                                                                    >
                                                                        <MyEirAmazonPrimeVideo
                                                                            apvCustomerType={
                                                                                apvCustomerType
                                                                            }
                                                                            apvOfferCode={apvOffer}
                                                                            apvOfferStatus={
                                                                                apvOfferStatus
                                                                            }
                                                                            flag='offer'
                                                                            apvToggle={apvToggle}
                                                                            setApvToggle={
                                                                                setApvToggle
                                                                            }
                                                                            // isActiveTv={
                                                                            //     isActiveTv
                                                                            // }
                                                                            // numCards={numCards}
                                                                        />
                                                                    </span>
                                                                }
                                                            />
                                                        )}
                                                </SwiperSlide>
                                            )}
                                            {finalArr.map((card, index) => (
                                                <SwiperSlide
                                                    style={{ display: 'flex' }}
                                                    key={index}
                                                >
                                                    <RecomendedOfferCard
                                                        parentId={parentId}
                                                        key={parentId}
                                                        csTvData={csTvData}
                                                        leftTopHeading={
                                                            card?.serviceGroupCode ===
                                                            SERVICE_GROUPS.MOBILE
                                                                ? 'Add eir Mobile'
                                                                : card?.serviceGroupCode ===
                                                                  SERVICE_GROUPS.TV
                                                                ? 'Add eir TV'
                                                                : card?.serviceGroupCode ===
                                                                  SERVICE_GROUPS.DUALPLAY
                                                                ? 'Add eir Broadband'
                                                                : 'Add a Phone'
                                                        }
                                                        leftBottomHeading={(card?.attributes || [])
                                                            .filter(
                                                                attr =>
                                                                    attr.type ===
                                                                    'MyEirOfferCardDisplayName'
                                                            )
                                                            .map(attr => attr.value)
                                                            .slice(0, 2)
                                                            .join(' ')}
                                                        rightTopHeading={
                                                            card?.serviceGroupCode ===
                                                            SERVICE_GROUPS.MOBILE
                                                                ? card?.adobeOffer?.price === 'free'
                                                                    ? 'For'
                                                                    : 'Only'
                                                                : card?.displayPrice === 'free'
                                                                ? 'For'
                                                                : 'Only'
                                                        }
                                                        rightBottomHeading={
                                                            card?.serviceGroupCode ===
                                                            SERVICE_GROUPS.MOBILE
                                                                ? isNaN(card?.adobeOffer?.price)
                                                                    ? card?.adobeOffer?.price
                                                                    : `€${convertCentsToPounds(
                                                                          card?.adobeOffer?.price
                                                                      )}`
                                                                : isNaN(card?.displayPrice)
                                                                ? card?.displayPrice
                                                                : `€${convertCentsToPounds(
                                                                      card.displayPrice
                                                                  )}`
                                                        }
                                                        rightBottomLastHeading={
                                                            card?.serviceGroupCode ===
                                                            SERVICE_GROUPS.MOBILE
                                                                ? card?.adobeOffer?.price === 0
                                                                    ? ''
                                                                    : 'a month'
                                                                : card?.displayPrice === 0
                                                                ? ''
                                                                : 'a month'
                                                        }
                                                        cardImg={
                                                            card?.attributes
                                                                .filter(
                                                                    attr =>
                                                                        attr.type ===
                                                                        'myEirRecommendedOfferImage'
                                                                )
                                                                .map(attr => attr.value)[0] ===
                                                            undefined
                                                                ? whiteBackground
                                                                : window.location.origin +
                                                                  card?.attributes
                                                                      .filter(
                                                                          attr =>
                                                                              attr.type ===
                                                                              'myEirRecommendedOfferImage'
                                                                      )
                                                                      .map(attr => attr.value)[0]
                                                        }
                                                        background={
                                                            card?.serviceGroupCode ===
                                                            SERVICE_GROUPS.MOBILE
                                                                ? theme.palette.primary
                                                                      .backgroundColor2
                                                                : card?.serviceGroupCode ===
                                                                  SERVICE_GROUPS.TV
                                                                ? theme.palette.primary.heather
                                                                : card?.serviceGroupCode ===
                                                                  SERVICE_GROUPS.DUALPLAY
                                                                ? theme.palette.primary.heather
                                                                : theme.palette.primary
                                                                      .backgroundColor2
                                                        }
                                                        fontColor={
                                                            card?.serviceGroupCode ===
                                                            SERVICE_GROUPS.MOBILE
                                                                ? theme.palette.primary.heather
                                                                : card?.serviceGroupCode ===
                                                                  SERVICE_GROUPS.TV
                                                                ? theme.palette.primary.white
                                                                : card?.serviceGroupCode ===
                                                                  SERVICE_GROUPS.DUALPLAY
                                                                ? theme.palette.primary.white
                                                                : theme.palette.primary.heather
                                                        }
                                                        divider1Color='#fbfbfb'
                                                        divider2Color={
                                                            card?.serviceGroupCode ===
                                                            SERVICE_GROUPS.MOBILE
                                                                ? '#2E0644'
                                                                : card?.serviceGroupCode ===
                                                                  SERVICE_GROUPS.TV
                                                                ? '#003380'
                                                                : card?.serviceGroupCode ===
                                                                  SERVICE_GROUPS.DUALPLAY
                                                                ? '#2E0644'
                                                                : '#003380'
                                                        }
                                                        propositionId={
                                                            card?.adobeOffer?.propositionId
                                                        }
                                                        serviceGroup={card?.serviceGroupCode}
                                                        card={card}
                                                        setIsModalOpen={setIsModalOpen}
                                                        setSelectedCard={setSelectedCard}
                                                        hideCard={hideCard}
                                                        setHideCard={setHideCard}
                                                    />
                                                </SwiperSlide>
                                            ))}
                                            {contentData?.getAmazonPrimeContents?.positionOfTile ===
                                                'after' && (
                                                <SwiperSlide style={{ display: 'flex' }}>
                                                    {apvCustomerType &&
                                                        apvOfferStatus === 'NO_ONGOING_REQUEST' && (
                                                            <ApvOfferCard
                                                                cardImg={
                                                                    origin +
                                                                    contentData
                                                                        ?.getAmazonPrimeContents
                                                                        ?.desktopTile?.imageURL
                                                                }
                                                                leftTopHeading='Sign up now'
                                                                leftBottomHeading='Amazon Prime Video on us'
                                                                strippedText='amazon'
                                                                rightTopHeading={
                                                                    apvOffer ===
                                                                    'eir-prime-video-tv'
                                                                        ? 'For'
                                                                        : 'Only'
                                                                }
                                                                rightBottomHeading={
                                                                    apvOffer ===
                                                                    'eir-prime-video-tv'
                                                                        ? 'free'
                                                                        : '5.99'
                                                                }
                                                                rightBottomLastHeading={
                                                                    apvOffer ===
                                                                    'eir-prime-video-tv'
                                                                        ? ''
                                                                        : 'a month'
                                                                }
                                                                background={
                                                                    theme.palette.primary.heather
                                                                }
                                                                fontColor={
                                                                    theme.palette.primary.white
                                                                }
                                                                divider1Color='#fbfbfb'
                                                                divider2Color='#003380'
                                                                apvToggle={apvToggle}
                                                                setApvToggle={setApvToggle}
                                                                links={
                                                                    <span
                                                                        className={
                                                                            classes.amazonPrime
                                                                        }
                                                                    >
                                                                        <MyEirAmazonPrimeVideo
                                                                            apvCustomerType={
                                                                                apvCustomerType
                                                                            }
                                                                            apvOfferCode={apvOffer}
                                                                            apvOfferStatus={
                                                                                apvOfferStatus
                                                                            }
                                                                            flag='offer'
                                                                            apvToggle={apvToggle}
                                                                            setApvToggle={
                                                                                setApvToggle
                                                                            }
                                                                            // isActiveTv={
                                                                            //     isActiveTv
                                                                            // }
                                                                            // numCards={numCards}
                                                                        />
                                                                    </span>
                                                                }
                                                            />
                                                        )}
                                                </SwiperSlide>
                                            )}
                                        </Swiper>
                                    </Box>
                                    {onOfferPage ? (
                                        ''
                                    ) : (
                                        <Box>
                                            <Typography className={classes.preferences}>
                                                You can easily stop seeing all the recommended
                                                offers by pausing them in your{' '}
                                                <Typography
                                                    component='span'
                                                    className={classes.preferencesLight}
                                                    onClick={handleOffers}
                                                >
                                                    Preferences
                                                </Typography>{' '}
                                                anytime.
                                            </Typography>
                                        </Box>
                                    )}

                                    {selectedCard && (
                                        <OfferUpgradeModal
                                            attributesData={attributesData}
                                            isLoading={isCTALoading}
                                            setIsLoading={setIsCTALoading}
                                            csTvData={csTvData}
                                            openSelectionModal={openSelectionModal}
                                            setOpenSelectionModal={setOpenSelectionModal}
                                            handleUpgrade={() => handlingUpgrade(3)}
                                            handleNotInterested={() => handleNotInterested(5)}
                                            handleRedirectValues={handleRedirectValues}
                                            isModalOpen={isModalOpen}
                                            attributes={selectedCard}
                                            setIsModalOpen={setIsModalOpen}
                                            selectedParentSubId={selectedParentSubId}
                                            setSelectedParentSubId={setSelectedParentSubId}
                                            ctaName={
                                                selectedCard?.serviceGroupCode === SERVICE_GROUPS.TV
                                                    ? 'Get eir TV'
                                                    : 'Get upgrade'
                                            }
                                            modalImage={
                                                width >= 600
                                                    ? window.location.origin +
                                                      selectedCard?.attributes
                                                          .filter(
                                                              attr =>
                                                                  attr.type ===
                                                                  'myEirRecommendedOfferImage'
                                                          )
                                                          .map(attr => attr.value)[1]
                                                    : window.location.origin +
                                                      selectedCard?.attributes
                                                          .filter(
                                                              attr =>
                                                                  attr.type ===
                                                                  'myEirRecommendedOfferImage'
                                                          )
                                                          .map(attr => attr.value)[2]
                                            }
                                        />
                                    )}
                                    {width >= 600 ? (
                                        ''
                                    ) : (
                                        <Stepper
                                            activeStep={activeStep}
                                            connector={null}
                                            className={classes.dotsContainer}
                                        >
                                            {apvWithOffers.map((_, index) => (
                                                <Step
                                                    key={index}
                                                    completed={false}
                                                    onClick={() => handleStepClick(index)}
                                                    className={classes.stepDot}
                                                >
                                                    <StepIcon />
                                                </Step>
                                            ))}
                                        </Stepper>
                                    )}
                                </Box>
                            ) : (
                                isBlanket && (
                                    <Box className={classes.mainContainer}>
                                        <Typography
                                            style={{
                                                paddingBottom: finalArr.length < 4 ? '20px' : '0px',
                                            }}
                                            className={classes.mainHeading}
                                        >
                                            Recommended offers for you
                                        </Typography>
                                        {width >= 601 && finalArr.length > 3 ? (
                                            <Box style={{ textAlign: 'right' }}>
                                                <IconButton
                                                    className={classes.navigateIcon}
                                                    onClick={goPrev}
                                                >
                                                    <NavigateBeforeIcon />
                                                </IconButton>
                                                <IconButton
                                                    className={classes.navigateIcon}
                                                    onClick={goNext}
                                                >
                                                    <NavigateNextIcon />
                                                </IconButton>
                                            </Box>
                                        ) : (
                                            ''
                                        )}

                                        <Box className={classes.swiperContainer}>
                                            <Swiper
                                                className='mainSwiper'
                                                ref={swiperRef}
                                                onSwiper={setSwiper}
                                                slidesPerView={
                                                    width < 601 ? 1.5 : calculateSlidesPerView()
                                                }
                                                centeredSlides={width < 601 ? true : false}
                                                slidesPerGroup={width < 601 ? 1 : 3}
                                                onSlideChange={swiper => {
                                                    setActiveStep(swiper.activeIndex);
                                                }}
                                                allowTouchMove={width >= 601 ? false : true}
                                            >
                                                {contentData?.getAmazonPrimeContents
                                                    ?.positionOfTile === 'before' && (
                                                    <SwiperSlide style={{ display: 'flex' }}>
                                                        {apvCustomerType &&
                                                            apvOfferStatus ===
                                                                'NO_ONGOING_REQUEST' && (
                                                                <ApvOfferCard
                                                                    cardImg={
                                                                        origin +
                                                                        contentData
                                                                            ?.getAmazonPrimeContents
                                                                            ?.desktopTile?.imageURL
                                                                    }
                                                                    leftTopHeading='Sign up now'
                                                                    leftBottomHeading='Amazon Prime Video on us'
                                                                    strippedText='amazon'
                                                                    rightTopHeading={
                                                                        apvOffer ===
                                                                        'eir-prime-video-tv'
                                                                            ? 'For'
                                                                            : 'Only'
                                                                    }
                                                                    rightBottomHeading={
                                                                        apvOffer ===
                                                                        'eir-prime-video-tv'
                                                                            ? 'free'
                                                                            : '5.99'
                                                                    }
                                                                    rightBottomLastHeading={
                                                                        apvOffer ===
                                                                        'eir-prime-video-tv'
                                                                            ? ''
                                                                            : 'a month'
                                                                    }
                                                                    background={
                                                                        theme.palette.primary
                                                                            .heather
                                                                    }
                                                                    fontColor={
                                                                        theme.palette.primary.white
                                                                    }
                                                                    divider1Color='#fbfbfb'
                                                                    divider2Color='#003380'
                                                                    apvToggle={apvToggle}
                                                                    setApvToggle={setApvToggle}
                                                                    links={
                                                                        <span
                                                                            className={
                                                                                classes.amazonPrime
                                                                            }
                                                                        >
                                                                            <MyEirAmazonPrimeVideo
                                                                                apvCustomerType={
                                                                                    apvCustomerType
                                                                                }
                                                                                apvOfferCode={
                                                                                    apvOffer
                                                                                }
                                                                                apvOfferStatus={
                                                                                    apvOfferStatus
                                                                                }
                                                                                flag='offer'
                                                                                apvToggle={
                                                                                    apvToggle
                                                                                }
                                                                                setApvToggle={
                                                                                    setApvToggle
                                                                                }
                                                                                // isActiveTv={
                                                                                //     isActiveTv
                                                                                // }
                                                                                // numCards={numCards}
                                                                            />
                                                                        </span>
                                                                    }
                                                                />
                                                            )}
                                                    </SwiperSlide>
                                                )}
                                                {finalArr.map((card, index) => (
                                                    <SwiperSlide
                                                        key={index}
                                                        style={{ display: 'flex' }}
                                                    >
                                                        <RecomendedOfferCard
                                                            parentId={parentId}
                                                            key={parentId}
                                                            csTvData={csTvData}
                                                            leftTopHeading={
                                                                card?.serviceGroupCode ===
                                                                SERVICE_GROUPS.MOBILE
                                                                    ? 'Add eir Mobile'
                                                                    : card?.serviceGroupCode ===
                                                                      SERVICE_GROUPS.TV
                                                                    ? 'Add eir TV'
                                                                    : card?.serviceGroupCode ===
                                                                      SERVICE_GROUPS.DUALPLAY
                                                                    ? 'Add eir Broadband'
                                                                    : 'Add a Phone'
                                                            }
                                                            leftBottomHeading={(
                                                                card?.attributes || []
                                                            )
                                                                .filter(
                                                                    attr =>
                                                                        attr.type ===
                                                                        'MyEirOfferCardDisplayName'
                                                                )
                                                                .map(attr => attr.value)
                                                                .slice(0, 2)
                                                                .join(' ')}
                                                            rightTopHeading={
                                                                card?.serviceGroupCode ===
                                                                SERVICE_GROUPS.MOBILE
                                                                    ? card?.adobeOffer?.price ===
                                                                      'free'
                                                                        ? 'For'
                                                                        : 'Only'
                                                                    : card?.displayPrice === 'free'
                                                                    ? 'For'
                                                                    : 'Only'
                                                            }
                                                            rightBottomHeading={
                                                                card?.serviceGroupCode ===
                                                                SERVICE_GROUPS.MOBILE
                                                                    ? isNaN(card?.adobeOffer?.price)
                                                                        ? card?.adobeOffer?.price
                                                                        : `€${convertCentsToPounds(
                                                                              card?.adobeOffer
                                                                                  ?.price
                                                                          )}`
                                                                    : isNaN(card?.displayPrice)
                                                                    ? card?.displayPrice
                                                                    : `€${convertCentsToPounds(
                                                                          card.displayPrice
                                                                      )}`
                                                            }
                                                            rightBottomLastHeading={
                                                                card?.serviceGroupCode ===
                                                                SERVICE_GROUPS.MOBILE
                                                                    ? card?.adobeOffer?.price === 0
                                                                        ? ''
                                                                        : 'a month'
                                                                    : card?.displayPrice === 0
                                                                    ? ''
                                                                    : 'a month'
                                                            }
                                                            cardImg={
                                                                card?.attributes
                                                                    .filter(
                                                                        attr =>
                                                                            attr.type ===
                                                                            'myEirRecommendedOfferImage'
                                                                    )
                                                                    .map(attr => attr.value)[0] ===
                                                                undefined
                                                                    ? whiteBackground
                                                                    : window.location.origin +
                                                                      card?.attributes
                                                                          .filter(
                                                                              attr =>
                                                                                  attr.type ===
                                                                                  'myEirRecommendedOfferImage'
                                                                          )
                                                                          .map(
                                                                              attr => attr.value
                                                                          )[0]
                                                            }
                                                            background={
                                                                card?.serviceGroupCode ===
                                                                SERVICE_GROUPS.MOBILE
                                                                    ? theme.palette.primary
                                                                          .backgroundColor2
                                                                    : card?.serviceGroupCode ===
                                                                      SERVICE_GROUPS.TV
                                                                    ? theme.palette.primary.heather
                                                                    : card?.serviceGroupCode ===
                                                                      SERVICE_GROUPS.DUALPLAY
                                                                    ? theme.palette.primary.heather
                                                                    : theme.palette.primary
                                                                          .backgroundColor2
                                                            }
                                                            fontColor={
                                                                card?.serviceGroupCode ===
                                                                SERVICE_GROUPS.MOBILE
                                                                    ? theme.palette.primary.heather
                                                                    : card?.serviceGroupCode ===
                                                                      SERVICE_GROUPS.TV
                                                                    ? theme.palette.primary.white
                                                                    : card?.serviceGroupCode ===
                                                                      SERVICE_GROUPS.DUALPLAY
                                                                    ? theme.palette.primary.white
                                                                    : theme.palette.primary.heather
                                                            }
                                                            divider1Color='#fbfbfb'
                                                            divider2Color={
                                                                card?.serviceGroupCode ===
                                                                SERVICE_GROUPS.MOBILE
                                                                    ? '#2E0644'
                                                                    : card?.serviceGroupCode ===
                                                                      SERVICE_GROUPS.TV
                                                                    ? '#003380'
                                                                    : card?.serviceGroupCode ===
                                                                      'DUALPLAY'
                                                                    ? '#2E0644'
                                                                    : '#003380'
                                                            }
                                                            propositionId={
                                                                card?.adobeOffer?.propositionId
                                                            }
                                                            serviceGroup={card?.serviceGroupCode}
                                                            card={card}
                                                            setIsModalOpen={setIsModalOpen}
                                                            setSelectedCard={setSelectedCard}
                                                            hideCard={hideCard}
                                                            setHideCard={setHideCard}
                                                        />
                                                    </SwiperSlide>
                                                ))}
                                                {contentData?.getAmazonPrimeContents
                                                    ?.positionOfTile === 'after' && (
                                                    <SwiperSlide style={{ display: 'flex' }}>
                                                        {apvCustomerType &&
                                                            apvOfferStatus ===
                                                                'NO_ONGOING_REQUEST' && (
                                                                <ApvOfferCard
                                                                    cardImg={
                                                                        origin +
                                                                        contentData
                                                                            ?.getAmazonPrimeContents
                                                                            ?.desktopTile?.imageURL
                                                                    }
                                                                    leftTopHeading='Sign up now'
                                                                    leftBottomHeading='Amazon Prime Video on us'
                                                                    strippedText='amazon'
                                                                    rightTopHeading={
                                                                        apvOffer ===
                                                                        'eir-prime-video-tv'
                                                                            ? 'For'
                                                                            : 'Only'
                                                                    }
                                                                    rightBottomHeading={
                                                                        apvOffer ===
                                                                        'eir-prime-video-tv'
                                                                            ? 'free'
                                                                            : '5.99'
                                                                    }
                                                                    rightBottomLastHeading={
                                                                        apvOffer ===
                                                                        'eir-prime-video-tv'
                                                                            ? ''
                                                                            : 'a month'
                                                                    }
                                                                    background={
                                                                        theme.palette.primary
                                                                            .heather
                                                                    }
                                                                    fontColor={
                                                                        theme.palette.primary.white
                                                                    }
                                                                    divider1Color='#fbfbfb'
                                                                    divider2Color='#003380'
                                                                    apvToggle={apvToggle}
                                                                    setApvToggle={setApvToggle}
                                                                    links={
                                                                        <span
                                                                            className={
                                                                                classes.amazonPrime
                                                                            }
                                                                        >
                                                                            <MyEirAmazonPrimeVideo
                                                                                apvCustomerType={
                                                                                    apvCustomerType
                                                                                }
                                                                                apvOfferCode={
                                                                                    apvOffer
                                                                                }
                                                                                apvOfferStatus={
                                                                                    apvOfferStatus
                                                                                }
                                                                                flag='offer'
                                                                                apvToggle={
                                                                                    apvToggle
                                                                                }
                                                                                setApvToggle={
                                                                                    setApvToggle
                                                                                }
                                                                                // isActiveTv={
                                                                                //     isActiveTv
                                                                                // }
                                                                                // numCards={numCards}
                                                                            />
                                                                        </span>
                                                                    }
                                                                />
                                                            )}
                                                    </SwiperSlide>
                                                )}
                                            </Swiper>
                                        </Box>
                                        {onOfferPage ? (
                                            ''
                                        ) : (
                                            <Box>
                                                <Typography className={classes.preferences}>
                                                    You can easily stop seeing all the recommended
                                                    offers by pausing them in your{' '}
                                                    <Typography
                                                        component='span'
                                                        className={classes.preferencesLight}
                                                        onClick={handleOffers}
                                                    >
                                                        Preferences
                                                    </Typography>{' '}
                                                    anytime.
                                                </Typography>
                                            </Box>
                                        )}

                                        {selectedCard && (
                                            <OfferUpgradeModal
                                                isLoading={isCTALoading}
                                                setIsLoading={setIsCTALoading}
                                                attributesData={attributesData}
                                                csTvData={csTvData}
                                                openSelectionModal={openSelectionModal}
                                                setOpenSelectionModal={setOpenSelectionModal}
                                                handleUpgrade={() => handlingUpgrade(3)}
                                                handleNotInterested={() => handleNotInterested(5)}
                                                handleRedirectValues={handleRedirectValues}
                                                isModalOpen={isModalOpen}
                                                attributes={selectedCard}
                                                setIsModalOpen={setIsModalOpen}
                                                selectedParentSubId={selectedParentSubId}
                                                setSelectedParentSubId={setSelectedParentSubId}
                                                ctaName={
                                                    selectedCard?.serviceGroupCode ===
                                                    SERVICE_GROUPS.TV
                                                        ? 'Get eir TV'
                                                        : 'Get upgrade'
                                                }
                                                modalImage={
                                                    width >= 600
                                                        ? window.location.origin +
                                                          selectedCard?.attributes
                                                              .filter(
                                                                  attr =>
                                                                      attr.type ===
                                                                      'myEirRecommendedOfferImage'
                                                              )
                                                              .map(attr => attr.value)[1]
                                                        : window.location.origin +
                                                          selectedCard?.attributes
                                                              .filter(
                                                                  attr =>
                                                                      attr.type ===
                                                                      'myEirRecommendedOfferImage'
                                                              )
                                                              .map(attr => attr.value)[2]
                                                }
                                            />
                                        )}
                                        {width >= 600 ? (
                                            ''
                                        ) : (
                                            <Stepper
                                                activeStep={activeStep}
                                                connector={null}
                                                className={classes.dotsContainer}
                                            >
                                                {apvWithOffers.map((_, index) => (
                                                    <Step
                                                        key={index}
                                                        completed={false}
                                                        onClick={() => handleStepClick(index)}
                                                        className={classes.stepDot}
                                                    >
                                                        <StepIcon />
                                                    </Step>
                                                ))}
                                            </Stepper>
                                        )}
                                    </Box>
                                )
                            )}
                        </>
                    ) : (
                        ''
                    )}
                </>
            )}

            {openError ? <MyEirErrorHandle open={openError} setOpen={setOpenError} /> : ''}
        </>
    );
};

export default RecomendedOffersContainer;
