import { makeStyles } from '@material-ui/core/styles';
import theme from '../../../theme/MyEirTheme';
export const MyPaymentStyle = makeStyles(theme => ({
    root: {
        width: '100%',
        overflowX: 'auto',
        borderRadius: '10px',
        boxShadow: 'none',
    },
    visibleScrollbar: {
        display: 'block',
        width: '300px',
        overflow: 'auto',
        height: '300px',
    },
    invisibleScrollbar: {
        display: 'block',
        width: '300px',
        overflow: 'auto',
        height: '300px',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    mostlyCustomizedScrollbar: {
        maxHeight: 'auto',
        maxWidth: '100%',
        margin: '0 auto',
        display: 'block',

        overflow: 'auto',

        '&::-webkit-scrollbar': {
            width: '6px',
            height: '6px',
            backgroundColor: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
            background: theme.palette.primary.heather,
            borderRadius: '3px',
        },
        '& table': {
            [theme.breakpoints.down('sm')]: {
                width: '30.5rem',
            },
        },
    },
    // container: {
    //     maxHeight: 'auto',
    //     maxWidth: '100%',
    //     margin: '0 auto',

    //     // '&::-webkit-scrollbar': {
    //     //     webkitAppearance: 'none',
    //     //     backgroundColor: '#FFF',
    //     //     width: '18px',
    //     //     borderRadius: '20px',
    //     //     display: 'block !important',
    //     // },
    //     // '&::-webkit-scrollbar-track': {
    //     //     backgroundColor: '#FFF',
    //     //     borderRadius: '20px',
    //     //     display: 'block !important',
    //     // },
    //     // '&::-webkit-scrollbar-thumb': {
    //     //     background: '#D111C9',
    //     //     border: '4px solid #FFF',
    //     //     borderRadius: '50px',
    //     // },

    //     '& table': {
    //         [theme.breakpoints.down('sm')]: {
    //             width: '30.5rem',
    //         },
    //     },
    // },
    content: {
        // fontWeight: 400,
        // fontSize: theme.typography.subtitle2.fontSize,
        // fontFamily: `${theme.typography.overline.fontFamily} !important`,
        color: theme.palette.primary.heather,
        marginBottom: '20px',
        paddingTop: '1rem',
        // color: '#D111C9',
        [theme.breakpoints.down('xs')]: {
            marginBottom: '0px',
        },
    },

    headingData: {
        color: theme.palette.primary.heather,
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },

        '& .MuiTableCell-head': {
            // fontWeight: 700,
            fontSize: theme.typography.body1.fontSize,
            fontFamily: theme.typography.h5.fontFamily,
            backgroundColor: theme.palette.primary.white,
            color: theme.palette.primary.heather,
        },
        '& tr': {
            [theme.breakpoints.up('sm')]: {
                display: 'flex',
                justifyContent: 'space-between',
            },
            '& th': {
                maxWidth: '100px',
                width: '100%',
                [theme.breakpoints.down('sm')]: {
                    width: '8.75rem',
                    position: 'static',
                },
            },

            borderBottom: `1px solid ${theme.palette.primary.borderColor}`,
        },
    },
    rowData: {
        '& .MuiTableRow-root:nth-of-type(odd)': {
            backgroundColor: '#F8F9FA',
        },
        '& .MuiTableCell-root': {
            color: `${theme.palette.primary.heather}`,
            fontFamily: theme.typography.subtitle1.fontFamily,
            // fontWeight: 400,
            fontSize: theme.typography.body1.fontSize,
            [theme.breakpoints.down('sm')]: {
                fontSize: theme.typography.subtitle2.fontSize,
            },
        },
        '& tr': {
            [theme.breakpoints.up('sm')]: {
                display: 'flex',
                justifyContent: 'space-between',
            },

            '& td': {
                maxWidth: '100px',
                width: '100%',
                [theme.breakpoints.down('sm')]: {
                    width: '8.75rem',
                },
            },
            borderBottom: `1px solid ${theme.palette.primary.borderColor}`,
        },
    },

    pagination: {
        maxHeight: 'auto',
        maxWidth: '100%',
        margin: '0 auto',
        overflowX: 'hidden',
        [theme.breakpoints.down('sm')]: {
            '& .MuiToolbar-root': {
                paddingLeft: '2px',

                '& .MuiInputBase-root': {
                    marginLeft: '4px',
                    marginRight: '23px',
                },
                '& .MuiTablePagination-actions': {
                    marginLeft: '10px',
                },
            },
        },
    },
}));
