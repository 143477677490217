import React, { Fragment, useContext } from 'react';
import {
    Box,
    Container,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    MenuItem,
    Grid,
} from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { UsageDetailsContext } from './MyEirUsageContainer';
import { useMyEirUsageStyle } from './useMyEirUsageStyle';
import moment from 'moment';

import Pagination from '@material-ui/lab/Pagination';

const StyledTableCell = withStyles(theme => ({
    head: {
        background:
            'transparent linear-gradient(180deg, #892BA0 0%, #5F0A8D 100%) 0% 0% no-repeat padding-box',
        color: theme.palette.common.white,
        fontSize: 16,
        fontFamily: 'eir-medium',
        padding: '10px',
        [theme.breakpoints.down('sm')]: {
            fontSize: 12,
            padding: '10px 5px 10px 10px',
        },
    },
    body: {
        fontSize: 16,
        fontFamily: 'eir-medium !imporatnt',
        padding: '10px',
        border: 'none',
        [theme.breakpoints.down('sm')]: {
            fontSize: 12,
            padding: '5px 5px 5px 10px',
        },
    },
}))(TableCell);
const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(even)': {
            background: '#892ba014 0% 0% no-repeat padding-box',
            borderTop: '2px solid #892ba036',
            borderBottom: '2px solid #892ba036',
        },
    },
}))(TableRow);

const StyledTableCellNoBorder = withStyles(theme => ({
    body: {
        fontSize: 16,
        fontFamily: 'eir-bold',
        border: 'none',
        borderBottom: 0,
        padding: '10px 0px 0px 10px',
        [theme.breakpoints.down('sm')]: {
            fontSize: 12,
        },
    },
}))(TableCell);

function MyEirDetailedUsage(props) {
    const classes = useMyEirUsageStyle();
    const {
        usageDetailsList,
        totalPages,
        currentPage,
        setCurrentPage,
        rowsPerPage,
        setRowsPerPage,
    } = props;
    const { selectedType } = useContext(UsageDetailsContext);

    const columns = [
        { id: 'type', label: 'Type', width: '15%' },
        { id: 'value', label: 'Value', width: '22%' },
        { id: 'charge', label: 'Charge', width: '15%' },
        { id: 'number', label: 'Number', width: '30%' },
        { id: 'location', label: 'Location', width: '18%' },
    ];

    const rowsList = [
        { key: 10, label: 10 },
        { key: 20, label: 20 },
        { key: 50, label: 50 },
        { key: 100, label: 100 },
    ];

    const convertToEuro = amount => {
        return (amount / 100).toFixed(2);
    };
    const handlePageChange = (event, value) => {
        setCurrentPage(value - 1);
    };
    const handleRowsPerPageChange = value => {
        setRowsPerPage(value);
        setCurrentPage(0);
    };
    const formatUsageDetail = usageDetail => {
        switch (usageDetail.measuringUnit) {
            case 'SECOND':
                return {
                    quantity: moment.utc(usageDetail.duration * 1000).format('HH:mm:ss'),
                };
            case 'B':
                return {
                    quantity: `${(usageDetail.quantity / 1073741824).toFixed(3)}GB`,
                };
            case 'EVENT':
                return {
                    quantity: usageDetail.quantity,
                };
            case 'KB':
                return {
                    quantity: `${(usageDetail.quantity / 1073741824).toFixed(3)}GB`,
                };
            default:
                return {
                    quantity: usageDetail.quantity,
                };
        }
    };
    return (
        <div>
            <Container className='usagePaddingTop'>
                {usageDetailsList != null && usageDetailsList.length > 0 && (
                    <Fragment>
                        <Box
                            display={{
                                xs: 'block',
                                sm: 'none',
                                md: 'none',
                            }}
                        >
                            <TableContainer>
                                <Table size='small' aria-label='a dense table'>
                                    <TableHead>
                                        <StyledTableRow key='tableheaderrow'>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        {columns.map(column => (
                                                            <StyledTableCell
                                                                key={column.id}
                                                                style={{
                                                                    width: column.width,
                                                                }}
                                                            >
                                                                {column.label}
                                                            </StyledTableCell>
                                                        ))}
                                                    </TableRow>
                                                </TableHead>
                                            </Table>
                                        </StyledTableRow>
                                    </TableHead>
                                    <TableBody>
                                        {usageDetailsList.map(usageDetail => (
                                            <Fragment>
                                                <StyledTableRow key={usageDetail.chargedUsageId}>
                                                    <Table>
                                                        <TableBody>
                                                            <TableRow>
                                                                <StyledTableCellNoBorder
                                                                    colSpan={5}
                                                                >
                                                                    {moment(
                                                                        usageDetail.chargeStartDateTime
                                                                    ).format('DD/MM/YYYY HH:mm')}
                                                                </StyledTableCellNoBorder>
                                                            </TableRow>
                                                            <TableRow>
                                                                <StyledTableCell
                                                                    style={{
                                                                        width: '15%',
                                                                    }}
                                                                >
                                                                    {usageDetail.invoiceText}
                                                                </StyledTableCell>
                                                                <StyledTableCell
                                                                    style={{
                                                                        width: '22%',
                                                                    }}
                                                                >
                                                                    {
                                                                        formatUsageDetail(
                                                                            usageDetail
                                                                        ).quantity
                                                                    }
                                                                </StyledTableCell>
                                                                <StyledTableCell
                                                                    style={{
                                                                        width: '15%',
                                                                    }}
                                                                >
                                                                    &euro;
                                                                    {convertToEuro(
                                                                        usageDetail.amount
                                                                    )}
                                                                </StyledTableCell>
                                                                <StyledTableCell
                                                                    style={{
                                                                        width: '30%',
                                                                    }}
                                                                >
                                                                    {usageDetail.destinationNumber}
                                                                </StyledTableCell>
                                                                <StyledTableCell
                                                                    style={{
                                                                        width: '18%',
                                                                    }}
                                                                >
                                                                    {
                                                                        usageDetail.destinationCountryCode
                                                                    }
                                                                </StyledTableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </StyledTableRow>
                                            </Fragment>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                        <Box
                            display={{
                                xs: 'none',
                                sm: 'block',
                                md: 'block',
                            }}
                        >
                            <TableContainer>
                                <Table size='small' aria-label='a dense table'>
                                    <TableHead>
                                        <StyledTableRow key='tableheaderrow'>
                                            <StyledTableCell>Date/time</StyledTableCell>
                                            <StyledTableCell>Type</StyledTableCell>
                                            <StyledTableCell>Value</StyledTableCell>
                                            <StyledTableCell>Charge</StyledTableCell>
                                            <StyledTableCell>Number</StyledTableCell>
                                            <StyledTableCell>Location</StyledTableCell>
                                        </StyledTableRow>
                                    </TableHead>
                                    <TableBody>
                                        {usageDetailsList.map(usageDetail => (
                                            <Fragment>
                                                <StyledTableRow key={usageDetail.chargedUsageId}>
                                                    <StyledTableCell>
                                                        {moment(
                                                            usageDetail.chargeStartDateTime
                                                        ).format('DD/MM/YYYY HH:mm')}
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        {usageDetail.invoiceText}
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        {formatUsageDetail(usageDetail).quantity}
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        &euro;
                                                        {convertToEuro(usageDetail.amount)}
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        {usageDetail.destinationNumber}
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        {usageDetail.destinationCountryCode}
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            </Fragment>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Fragment>
                )}
                {selectedType == null && (
                    <Box
                        display='flex'
                        justifyContent='center'
                        color='primary'
                        style={{ marginTop: '20px' }}
                    >
                        <p className='boldFont'> Select usage type to get the details.</p>
                    </Box>
                )}
                {usageDetailsList != null && usageDetailsList.length > 0 && (
                    <div style={{ marginTop: '20px' }} className={classes.UsagePaging}>
                        <Grid container spacing={0}>
                            <Grid item xs={12} sm={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={7} sm={5} className='rowsPerPageStyle'>
                                        Show results per page:
                                    </Grid>
                                    <Grid item xs={5} sm={7} style={{ textAlign: 'start' }}>
                                        <TextField
                                            fullWidth
                                            size='small'
                                            id='rowsPerPage'
                                            label=''
                                            select
                                            value={rowsPerPage}
                                            variant='outlined'
                                            onChange={event =>
                                                handleRowsPerPageChange(event.target.value)
                                            }
                                            className='dropDownStyle'
                                        >
                                            {rowsList.map(rows => (
                                                <MenuItem key={rows.key} value={rows.key}>
                                                    {rows.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={0} sm={1}></Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Grid container className='pagingPlacing' spacing={0}>
                                    <Grid item>
                                        <Box
                                            display={{
                                                xs: 'block',
                                                sm: 'block',
                                                md: 'none',
                                            }}
                                        >
                                            <Pagination
                                                variant='outlined'
                                                color='primary'
                                                count={totalPages}
                                                page={currentPage + 1}
                                                onChange={handlePageChange}
                                                size='small'
                                            />
                                        </Box>
                                        <Box
                                            display={{
                                                xs: 'none',
                                                sm: 'none',
                                                md: 'block',
                                            }}
                                        >
                                            <Pagination
                                                variant='outlined'
                                                color='primary'
                                                count={totalPages}
                                                page={currentPage + 1}
                                                onChange={handlePageChange}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                )}
                {usageDetailsList != null && selectedType != null && usageDetailsList.length == 0 && (
                    <Box display='flex' justifyContent='center' color='primary'>
                        <p className='messageBoldFont'>
                            Looks like you haven’t generated any usage for this month.{' '}
                        </p>
                    </Box>
                )}
            </Container>
        </div>
    );
}
export default MyEirDetailedUsage;
