import React, { useEffect, useState } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
    Hidden,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    useTheme,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { MyPaymentStyle } from './myPaymentsStyle';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import ENDPOINT from '../../graphql/GraphQLConstants';
import { useApolloClient } from '@apollo/client';
import theme from '../../../theme/MyEirTheme';

const MyEirPaymentDetails = ({ paymentHistory }) => {
    const [expanded, setExpanded] = useState(false);
    const [formattedAmount, setFormattedAmount] = useState('');
    const [formattedTotalBalance, setFormattedTotalBalance] = useState('');
    const [formattedDate, setFormattedDate] = useState('');
    const [formattedTransactionType, SetFormattedTransactionType] = useState('');
    const [paymentDetail, setPaymentDetail] = useState({});
    const [formattedPaymentTime, setFormattedPaymentTime] = useState('');
    const [formattedPaymentSource, setFormattedPaymentSource] = useState('');
    const [FormattedPartialDetails, setFormattedPartialDetails] = useState('');
    const [error, setError] = useState(false);
    const classes = MyPaymentStyle();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    // const theme = useTheme();

    function convertDateFormat(paymentDate) {
        let formattedPaymentDate = paymentDate.split('-');
        let yyyy = formattedPaymentDate[0];
        let mm = formattedPaymentDate[1];
        let dd = formattedPaymentDate[2].split('T')[0];
        return dd + '/' + mm + '/' + yyyy;
    }

    function convertPaymentSource(str) {
        str = str.toLowerCase();
        return str.replace('_', ' ');
    }

    function convertTimeFormat(dateInput) {
        let time = dateInput.getHours() % 12;
        let minutes = dateInput.getMinutes();
        return `${time}.${minutes < 10 ? '0' + minutes : minutes} ${
            dateInput.getHours() >= 12 ? 'PM' : 'AM'
        }`;
    }

    //Adding € at the beginning for amount and Balance
    function convertAmount(amount) {
        amount = (amount / 100).toFixed(2).toString();
        let amountWithEuro = amount.split('');
        if (amountWithEuro[0] === '-') {
            amountWithEuro.splice(0, 1, ' €');
            return amountWithEuro.join('');
        } else if (amountWithEuro[0] === '0') {
            amountWithEuro.splice(0, 0, ' €');
            return amountWithEuro.join('');
        } else {
            amountWithEuro.splice(0, 0, '-', ' €');
            return amountWithEuro.join('');
        }
    }

    function capitalizeFirstLetter(string) {
        string = string.replace('_', ' ');
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }

    function createData(dates, type, amount, balance) {
        return { dates, type, amount, balance };
    }

    const rows = [];
    paymentHistory.forEach(data =>
        rows.push(
            createData(
                convertDateFormat(data.dateTime),
                capitalizeFirstLetter(data.transactionType),
                convertAmount(data.amount),
                convertAmount(data.totalBalance)
            )
        )
    );

    const columns = [
        { id: 'dates', label: 'Date', align: 'left' },
        { id: 'type', label: 'Type', align: 'left' },
        {
            id: 'amount',
            label: 'Amount',
            align: 'right',

            color: rows.map(data =>
                data.amount.charAt(0) !== '-' ? theme.palette.primary.error : ''
            ),
        },
        {
            id: 'balance',
            label: 'Balance',
            align: 'right',
            color: rows.map(data =>
                data.balance.charAt(0) !== '-' ? theme.palette.primary.error : ''
            ),
        },
    ];

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    const graphql = useApolloClient();

    return (
        <ThemeProvider theme={theme}>
            <Paper className={classes.root}>
                <Typography
                    variant='body2'
                    style={{ color: theme.palette.primary.heather }}
                    className={classes.content}
                >
                    See all your transitions history up to 12 months.
                </Typography>
                <TableContainer className={classes.mostlyCustomizedScrollbar}>
                    <Table stickyHeader aria-label='sticky table'>
                        <TableHead className={classes.headingData}>
                            <TableRow>
                                {columns.map(column => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{
                                            borderBottom: 'none',
                                        }}
                                    >
                                        <Typography
                                            style={{ color: theme.palette.primary.heather }}
                                            variant='subtitle1'
                                        >
                                            {column.label}
                                        </Typography>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody className={classes.rowData}>
                            {rows
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map(row => {
                                    return (
                                        <TableRow hover role='checkbox' tabIndex={-1}>
                                            {columns.map(column => {
                                                const value = row[column.id];
                                                return (
                                                    <TableCell
                                                        style={{
                                                            color: theme.palette.primary.heather,
                                                            borderBottom: 'none',
                                                        }}
                                                        key={column.id}
                                                        align={column.align}
                                                    >
                                                        <Typography
                                                            style={{
                                                                color:
                                                                    theme.palette.primary.heather,
                                                            }}
                                                        >
                                                            {value}
                                                        </Typography>
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    className={classes.pagination}
                    rowsPerPageOptions={[5, 10, 25]}
                    component='div'
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
        </ThemeProvider>
    );
};
export default MyEirPaymentDetails;
