import React from 'react';

import { Grid } from '@material-ui/core';
import { useToggleBtnStyle } from './useToggleBtnStyle';

function MyEirTogglebtn() {
    const classes = useToggleBtnStyle();
    const toggleClass = event => {
        var arrow = document.createElement('SPAN');
        arrow.id = 'triangleSpan';
        arrow.classList.add('arrow-down');
        if (
            document.querySelector('#triangleSpan') != null ||
            document.querySelector('#triangleSpan') != undefined
        ) {
            document.querySelector('#triangleSpan').remove();
            document.querySelector('.arrow-down').remove();
        }
        for (let i = 0; i < document.querySelectorAll('.toggleBtn').length; i++) {
            document.querySelectorAll('.toggleBtn')[i].classList.remove('ActiveBtn');
        }
        event.target.classList.add('ActiveBtn');
        event.target.parentNode.appendChild(arrow);
    };
    return (
        <div className={classes.toggleSection}>
            <Grid container spacing={6}>
                <Grid item xs={12} sm={12} md={12}>
                    <a href='/prepay/login'>
                        <div className='alignbtn primary'>
                            <input
                                type='button'
                                value='Log in '
                                className='toggleBtn primary '
                                onClick={toggleClass}
                            />
                            {/* <span id='triangleSpan' className='arrow-down'></span> */}
                        </div>
                    </a>
                    <a href='/prepay/register'>
                        <div className='alignbtn secondary'>
                            <input
                                type='submit'
                                value='Register'
                                className='toggleBtn secondary regbtn'
                                onClick={toggleClass}
                            />
                        </div>
                    </a>
                </Grid>
            </Grid>
        </div>
    );
}

export default MyEirTogglebtn;
