import React, { useState, useEffect, useRef } from 'react';
import { usePhoneNumber } from '../css/usePhoneNumber';
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Grid,
    Button,
    Typography,
} from '@material-ui/core';
import ReCAPTCHA from 'react-google-recaptcha';
import ENDPOINT from '../../components/graphql/GraphQLConstants';
import { useApolloClient } from '@apollo/client';
import { commonRegisterStyles } from '../css/myEirRegistrationStyles/commonRegisterStyles';
import MyEirRegisterHeader from './reusable/MyEirRegisterHeader';
import MyEirRegisterFooter from './reusable/MyEirRegisterFooter';

const MyEirEmailAddress = props => {
    const classes = usePhoneNumber();
    const commonStyle = commonRegisterStyles();
    const [captchaValue, setCaptchaValue] = useState('');
    const [showLabelPhone, setShowLabelPhone] = useState(false);
    const [captchaFailed, setCaptchaFailed] = useState(false);
    const [isNextClicked, setIsNextClicked] = useState(false);
    const [emailAddress, setEmailAddress] = useState(false);
    const [email, setEmail] = useState('');
    const [validEmail, setValidEmail] = useState(false);
    const [formSuccess, setFormSuccess] = useState({
        isEmailAddress: false,
        isRecaptcha: false,
    });
    const [emailError, setEmailError] = useState();

    const graphql = useApolloClient();
    const recaptchaRef = useRef(null);
    const validEmailFormat = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;

    function validateEmail(valFun, val) {
        if (validEmailFormat.test(val)) {
            valFun(true);
            setFormSuccess({ ...formSuccess, isEmailAddress: true });
        } else {
            valFun(false);
            setFormSuccess({ ...formSuccess, isEmailAddress: false });
        }
    }

    function handleOnChange(value) {
        if (value == null) setFormSuccess({ ...formSuccess, isRecaptcha: false });
        else {
            setCaptchaValue(value);
            setCaptchaFailed('');
            setFormSuccess({ ...formSuccess, isRecaptcha: true });
        }
    }
    const handleEmailChange = event => {
        validateEmail(setValidEmail, event.target.value);
        setEmail(event.target.value);
    };
    const handleLabelEmail = val => {
        setShowLabelPhone(val);
    };

    const handleBackButton = () => {
        props.setFlag(true);
        props.handleBackButton();
    };

    async function numberValidation() {
        setIsNextClicked(true);
        try {
            const res = await graphql.mutate({
                mutation: ENDPOINT.NUMBER_VALIDATION,
                variables: {
                    registerWith: 'EMAIL',
                    registerValue: email,
                    recaptcha: captchaValue,
                },
            });
            setIsNextClicked(false);

            if (
                res.data.validateUser.statusCode == 201 ||
                res.data.validateUser.statusCode == 202
            ) {
                sessionStorage.setItem('reg_email', email);
                sessionStorage.setItem('reg_uuid', res.data.validateUser.otpUuid);
                sessionStorage.setItem('reg_primary_number', res.data.validateUser.mobileNumber);
                res.data.validateUser.statusCode == 201 &&
                    sessionStorage.setItem('reg_bill_type', 'PREPAY');
                res.data.validateUser.statusCode == 202 &&
                    sessionStorage.setItem('reg_bill_type', 'BILL');
                props.handleNext();
            }
            if (res.data.validateUser.statusCode == 203) {
                // redirect to portal
                sessionStorage.setItem('reg_email', email);
                if (props.forgotUserName) {
                    window.location.href =
                        '/bundles/forgotten-email?phoneNumber=' +
                        code +
                        '-' +
                        phoneNum.displayNumber +
                        '&response=' +
                        captchaValue;
                } else {
                    window.location.href = '/bundles/register/account?phoneNumber=' + email;
                }
            }
        } catch (err) {
            if (
                err.graphQLErrors[0].extensions.code ===
                'Supplied number is not found on Ion and Portal'
            ) {
                setEmailError(
                    'We could not find the email address entered above, please use your eir mobile, landline or broadband reference number to complete the resigtration. '
                );
            }
            if (err.message === 'Invalid email') {
                setPhoneNum({
                    ...phoneNum,
                    error: { value: true, message: 'Invalid email' },
                });
                setFormSuccess({ ...formSuccess, isEmailAddress: false });
                setInvalidPhone(true);
            } else if (err.message === 'Captcha is not valid') {
                setCaptchaFailed(err.message);
                setFormSuccess({ ...formSuccess, isRecaptcha: false });
            } else {
                setPhoneNum({ ...phoneNum, error: { value: true, message: err.message } });
                setFormSuccess({ ...formSuccess, isEmailAddress: false });
                setInvalidPhone(true);
            }
            setFormSuccess({ ...formSuccess, isRecaptcha: false });
            setIsNextClicked(false);
            recaptchaRef?.current?.reset();
        }
        // props.handleNext();
    }

    const handleNext = () => {
        numberValidation();
    };

    return (
        <form
            className={commonStyle.registrationContainer}
            onSubmit={e => {
                e.preventDefault();
            }}
        >
            <div className={commonStyle.cardContainer}>
                <MyEirRegisterHeader
                    headerText={props.headerText}
                    handleBackButton={handleBackButton}
                />

                <div className={classes.enterPhoneBody}>
                    <Grid container spacing={0} className={classes.phoneCodeGrid}>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            className='register-grid-text-information'
                        >
                            <Typography variant='body1' className='register-grid-text-content'>
                                Please enter your eir email address
                            </Typography>
                        </Grid>

                        <Grid item sm={4} xs={8} md={9} className={classes.emailAddress}>
                            <TextField
                                required
                                className={classes.textField}
                                type='email'
                                label='Email address'
                                variant='outlined'
                                value={email}
                                id='EmailAddress'
                                name='EmailAddress'
                                onChange={handleEmailChange}
                                onFocus={() => {
                                    handleLabelEmail(true);
                                }}
                                onBlur={() => {
                                    handleLabelEmail(false);
                                }}
                            />
                            {!validEmail && !!email && (
                                <div className={classes.invalidEmailFormat}>
                                    Please enter a valid email ID
                                </div>
                            )}
                            <Grid item xs={12} className={classes.errormessage}>
                                {emailError ? (
                                    <div className='invalid-messages'>{emailError}</div>
                                ) : (
                                    ''
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container sm={12} xs={12} md={12} className={classes.gridRecaptcha}>
                        <div className={classes.reCaptcha}>
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                className={classes.reCaptchaRobot}
                                sitekey='6LfZsRYTAAAAAJMBqawqK3sy7NmHDC8M9rKdkX36'
                                render='explicit'
                                onChange={handleOnChange}
                            />
                        </div>
                        <Grid item xs={12} className={classes.errormessage}>
                            {captchaFailed ? (
                                <div className='invalid-messages'>{captchaFailed}</div>
                            ) : (
                                ''
                            )}
                        </Grid>
                    </Grid>
                </div>

                <MyEirRegisterFooter
                    button1Text='Next'
                    handleButton1Click={handleNext}
                    button1Enabled={
                        formSuccess.isEmailAddress && formSuccess.isRecaptcha && !isNextClicked
                    }
                />
            </div>
        </form>
    );
};

export default MyEirEmailAddress;
