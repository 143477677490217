import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { useApolloClient } from '@apollo/client';
import MyEirInactiveUser from '../Inactive-user/MyEirInactiveUser';
import {
    FormControl,
    InputLabel,
    NativeSelect,
    InputBase,
    withStyles,
    MenuItem,
    Select,
    Box,
    Typography,
} from '@material-ui/core';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {
    handleUsageQuantity,
    convertDataUsage,
    convertDuration,
    convertTimeFormat,
    convertDateFormat,
    convertTimeOnly,
    convertDateOnly,
    convertDateAndTime,
    hideAndUnhideSummary,
    handleBillingPeriod,
    servicesInfo,
    getUsageDetailsByUsageType,
    getUsageSummary,
    getUsageDetails,
    retryCurrentUsage,
    filterDetails,
    getUsageAmount,
    formattingDate,
} from './currentUsage.util';
import { convertPhoneNumber } from '../redesign.util';
import Loading from '../../../components/loading/Loading';
import { useWindowDimension } from '../../../hooks/windowDimensions';
import { currentUsageStyles } from './currentUsageStyles';
import Icon from '../images/inactiveIcon.png';
import { ArrowBackIcon } from '../../../sharedcomponents/inputs/svg-icons/CustomSvgIcons';

const BootstrapInput = withStyles(theme => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    formControl: {
        // minHeight: "56px",
    },
    input: {
        minHeight: '56px',
        border: `1px solid ${theme.palette.primary.borderColor}`,
        boxSizing: 'border-box',
        borderRadius: '4px',
        fontFamily: theme.typography.body2.fontFamily,
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: theme.typography.body1.fontSize,
        lineHeight: '19px',
        letterSpacing: '0.15px',
        color: theme.palette.primary.boxShadow4Dark,

        position: 'relative',
        backgroundColor: theme.palette.background.paper,

        padding: '18px 43px 17px 11px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
    },
}))(InputBase);

function SeeCurrentUsage(props) {
    const [width, height] = useWindowDimension();
    const [usageQuantity, setUsageQuantity] = useState(0);
    const [usageUnit, setUsageUnit] = useState('');
    const [usageCost, setUsageCost] = useState(0);
    const [chargedTotal, setChargedTotal] = useState({ quantity: 0, amount: 0 });
    const [freeTotal, setFreeTotal] = useState({ quantity: 0, amount: 0 });
    const [callTotal, setCallTotal] = useState({ quantity: 0, amount: 0 });
    const [smsTotal, setSMSTotal] = useState({ quantity: 0, amount: 0 });
    const [dataTotal, setDataTotal] = useState({ quantity: 0, amount: 0 });
    const [dataNormal, setDataNormal] = useState({ quantity: 0, amount: 0 });
    const [dataRomaing, setDataRoaming] = useState({ quantity: 0, amount: 0 });
    const [currentUsageLoadingError, setCurrentUsageLoadingError] = useState(false);
    const [currentUsageLoading, setCurrentUsageLoading] = useState(true);
    const [serviceName, setServiceName] = useState([]);
    const [serviceNameId, setServiceNameId] = useState([]);
    const [serviceNameDomain, setServiceNameDomain] = useState('');
    const [isPSTN, setIsPSTN] = useState(false);
    const [usageSummary, setUsageSummary] = useState([]);
    const [usageDetails, setUsageDetails] = useState([]);
    const [usageDetailsCharged, setUsageDetailsCharged] = useState([]);
    const [usageDetailsFree, setUsageDetailsFree] = useState([]);
    const [usageDetailsCall, setUsageDetailsCall] = useState([]);
    const [usageDetailsText, setUsageDetailsText] = useState([]);
    const [usageDetailsOLDText, setUsageDetailsOLDText] = useState([]);
    const [usageDetailsData, setUsageDetailsData] = useState([]);
    const [usageAmountAndCost, setUsageAmountAndCost] = useState([]);
    const [selectedServiceIndex, setSelectedServiceIndex] = useState(0);
    const [initialUser, setInitialUser] = useState(false);
    const [amountAndUsage, setAmountAndUsage] = useState([
        chargedTotal,
        freeTotal,
        callTotal,
        smsTotal,
        dataTotal,
    ]);

    const graphql = useApolloClient();

    const toggle = () => props.setIsOpen(!props.isopen);
    const [valueComputed, setValueComputed] = useState(true);
    const [usageBillingPeriod, setUsageBillingPeriod] = useState('');
    const description = `As a new customer, you don't have any usage to display yet. Check back soon to view your usage.`;
    const [tabIndex, setTabIndex] = useState(0);
    const billingPeriod = new Date(usageBillingPeriod);
    const [age, setAge] = useState(`068 27 377`);
    const handleServiceIDChange = event => {
        setUsageQuantity(0);
        setUsageUnit('');
        setUsageCost(0);
        setCurrentUsageLoading(true);
        setCurrentUsageLoadingError(false);
        setSelectedServiceIndex(event.target.value);
        if (event.target.value === serviceNameDomain) {
            setIsPSTN(true);
        } else {
            setIsPSTN(false);
        }
    };

    const classes = currentUsageStyles();
    useEffect(() => {
        if (usageDetailsText.length != 0) {
            const tempArr = usageDetailsText.reduce(function (a, b) {
                return { amount: a.amount + b.amount }; // returns object with property x
            });
        } else {
            // console.log("NO TEMPORARY",usageDetailsText.length);
        }
    }, [selectedServiceIndex, usageDetailsText]);

    useEffect(() => {
        handleBillingPeriod({ graphql, setUsageBillingPeriod });
        hideAndUnhideSummary({ setValueComputed });
        console.log('idx', serviceName[selectedServiceIndex]);
        console.log({ serviceName }, { selectedServiceIndex });
    }, [selectedServiceIndex]);

    useEffect(() => {
        setAmountAndUsage([chargedTotal, freeTotal, callTotal, smsTotal, dataTotal]);
    }, [chargedTotal, freeTotal, callTotal, smsTotal, dataTotal]);

    useEffect(() => {
        handleUsageQuantity({ tabIndex, amountAndUsage, setUsageQuantity, setUsageUnit });
        setUsageCost(amountAndUsage[tabIndex].amount);
    }, [tabIndex, selectedServiceIndex, amountAndUsage]);

    useEffect(() => {
        filterDetails({
            usageDetails,
            setUsageDetailsCharged,
            setUsageDetailsFree,
            setUsageDetailsCall,
            setUsageDetailsText,
            setUsageDetailsData,
            setChargedTotal,
            setFreeTotal,
            setUsageDetailsOLDText,
        });
        servicesInfo({
            serviceName,
            setServiceName,
            setServiceNameId,
            setCurrentUsageLoadingError,
        });
    }, [usageDetails]);

    useEffect(() => {
        getUsageSummary({
            graphql,

            index: selectedServiceIndex,
            setUsageSummary,
            setUsageDetailsText,
            setCurrentUsageLoadingError,
        });
        getUsageDetails({
            graphql,
            index: selectedServiceIndex,
            setUsageDetails,
            setCurrentUsageLoading,
            setCurrentUsageLoadingError,
        });
    }, [selectedServiceIndex]);

    useEffect(() => {
        getUsageAmount({
            usageSummary,
            setCallTotal,
            setSMSTotal,
            setDataTotal,
            setDataNormal,
            setDataRoaming,
        });
    }, [usageSummary]);

    useEffect(() => {
        const sessionValue = JSON.parse(sessionStorage.getItem('LoggedinServices'));
        const pstnData = sessionValue?.find(ele => ele.domain === 'PSTN');
        const isInitial = sessionValue?.find(user => user.status === 'INITIAL');

        if (isInitial) {
            setInitialUser(true);
            setCurrentUsageLoadingError(false);
            setCurrentUsageLoading(false);
        }

        if (pstnData) {
            setServiceNameDomain(pstnData.phoneNumber);
        }
    }, []);
    useEffect(() => {
        if (serviceName.length > 0 && serviceName[selectedServiceIndex] === serviceNameDomain) {
            setIsPSTN(true);
        } else {
            setIsPSTN(false);
        }
    }, [serviceName, selectedServiceIndex]);
    return (
        <React.Fragment>
            <Modal
                className='currentUsagemain'
                id='myeir-modal-window-my-usage'
                // wrapClassName="currentUsagewrap"
                wrapClassName={classes.CurrentUsageWrapper}
                modalClassName='currentUsagemodal'
                backdropClassName='currentUsagebackdrop'
                contentClassName='currentUsagecontent'
                isOpen={props.isopen}
                toggle={toggle}
                modalTransition={{ timeout: 300 }}
                centered
            >
                <ModalBody className='currentUsagebody'>
                    <Box className='currentUsage-header'>
                        <Box className='currentUsage-backButtonWrapper'>
                            <ArrowBackIcon className='currentUsage-backButton' onClick={toggle} />
                        </Box>
                        <Box className='currentUsage-titleUsage'>
                            <Box className='currentUsage-headerTitle'>Current usage</Box>
                            <Box
                                className='currentUsage-headerUsage'
                                style={
                                    currentUsageLoadingError || currentUsageLoading || valueComputed
                                        ? { visibility: 'hidden' }
                                        : {}
                                }
                            >
                                <Box className='currentUsage-headerUsageCharge'>
                                    {tabIndex == 1 ? 'Free' : '€' + (usageCost / 100).toFixed(2)}
                                </Box>
                                <Box
                                    className='currentUsage-headerUsageTotal'
                                    style={
                                        tabIndex == 0 || tabIndex == 1
                                            ? { visibility: 'hidden' }
                                            : {}
                                    }
                                >
                                    <Typography
                                        component='span'
                                        className='currentUsage-headerUsageValue'
                                    >
                                        {usageQuantity}
                                    </Typography>
                                    <Typography
                                        component='span'
                                        className='currentUsage-headerUsageProperty'
                                    >
                                        &nbsp; {usageUnit}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Box className='currentUsage-headerUnbilledAndAccounts'>
                            <Box className='currentUsage-headerUnbilled'>
                                <Box className='currentUsage-headerUnbilledPadding'>
                                    {!!usageBillingPeriod &&
                                        !(usageBillingPeriod.indexOf('undefined ') > -1) && (
                                            <>
                                                Unbilled usage since the{' '}
                                                {formattingDate(billingPeriod)}
                                            </>
                                        )}
                                </Box>
                            </Box>

                            {serviceName.length != 0 && (
                                <Box className='currentUsage-headerAccounts'>
                                    <Box className='currentUsage-headerAccountsFor'>For:</Box>
                                    <FormControl
                                        disabled={serviceName.length > 1 ? false : true}
                                        className='currentUsage-headerAccountsDropdown'
                                    >
                                        <Select
                                            classes={{
                                                select: 'currentUsage-dropdownSelect',
                                                icon: 'currentUsage-dropdownIcon',
                                                disabled: 'currentUsage-disabled',
                                            }}
                                            id='demo-customized-select-native'
                                            value={selectedServiceIndex}
                                            // defaultValue={10}
                                            onChange={handleServiceIDChange}
                                            input={<BootstrapInput />}
                                            MenuProps={{
                                                anchorOrigin: {
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                },
                                                transformOrigin: {
                                                    vertical: 'top',
                                                    horizontal: 'left',
                                                },
                                                getContentAnchorEl: null,
                                            }}
                                        >
                                            {serviceName.map((item, index) => {
                                                return (
                                                    <MenuItem
                                                        className={classes.MenuItemsWrapper}
                                                        classes={{ selected: classes.DisplayNone }}
                                                        value={index}
                                                    >
                                                        {convertPhoneNumber(item)}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                </Box>
                            )}
                        </Box>
                    </Box>
                    {currentUsageLoading ? (
                        <Box
                            className='currentUsage-loader'
                            style={{
                                justifyContent: 'center',
                                padding: '20px',
                                textAlign: 'center',
                            }}
                        >
                            {currentUsageLoadingError ? (
                                <Box className='currentUsage-content-loading'>
                                    Something went wrong, please try again later.
                                    <Typography
                                        component='span'
                                        className='currentUsage-retry-loading'
                                        style={{
                                            cursor: 'pointer',
                                            textDecoration: 'underline',
                                        }}
                                        onClick={() => {
                                            setCurrentUsageLoadingError(false);
                                            retryCurrentUsage({
                                                graphql,
                                                selectedServiceIndex,
                                                setUsageSummary,
                                                setUsageDetails,
                                                setUsageDetailsText,
                                                setCurrentUsageLoading,
                                                setCurrentUsageLoadingError,
                                            });
                                        }}
                                    >
                                        Retry?
                                    </Typography>
                                </Box>
                            ) : (
                                <Loading className='currentUsage-loading-animation' />
                            )}
                        </Box>
                    ) : (
                        <Tabs
                            className='currentUsage-tabs'
                            selectedTabClassName='currentUsage-categorySelected'
                            selectedIndex={tabIndex}
                            onSelect={index => {
                                hideAndUnhideSummary({ setValueComputed });
                                setTabIndex(index);
                            }}
                        >
                            <TabList className='currentUsage-categorySelection'>
                                <Tab className='currentUsage-categories'>Charged</Tab>
                                <Tab className='currentUsage-categories'>Free</Tab>
                                <Tab className='currentUsage-categories'>Calls</Tab>
                                {isPSTN ? (
                                    ''
                                ) : (
                                    <>
                                        <Tab className='currentUsage-categories'>SMS</Tab>
                                        <Tab className='currentUsage-categories'>Data</Tab>
                                    </>
                                )}
                            </TabList>

                            <TabPanel>
                                {width > 1019 ? (
                                    <>
                                        {usageDetailsCharged.length != 0 ? (
                                            <>
                                                <Box className='currentUsage-tabHeadings'>
                                                    <Box className='currentUsage-dateTime'>
                                                        Date & time
                                                    </Box>
                                                    <Box className='currentUsage-destination'>
                                                        Destination
                                                    </Box>
                                                    <Box className='currentUsage-duration'>
                                                        Duration/Quantity
                                                    </Box>
                                                    <Box className='currentUsage-cost'>Cost</Box>
                                                </Box>
                                                {usageDetailsCharged.map((item, index) => {
                                                    return (
                                                        <Box className='currentUsage-tabData'>
                                                            {' '}
                                                            {/*map from api data */}
                                                            <Box className='currentUsage-dateTime'>
                                                                {convertDateOnly(
                                                                    item.chargeStartDateTime
                                                                )}
                                                                <>&nbsp;&nbsp;&nbsp;</>
                                                                {convertTimeOnly(
                                                                    item.chargeStartDateTime
                                                                )}
                                                            </Box>
                                                            <Box className='currentUsage-destination'>
                                                                {convertPhoneNumber(
                                                                    item.destinationNumber
                                                                )}
                                                            </Box>
                                                            <Box className='currentUsage-duration'>
                                                                {item.quantity == 0
                                                                    ? convertDuration(item.duration)
                                                                    : !!item.destinationNumber
                                                                    ? item.quantity
                                                                    : convertDataUsage(
                                                                          item.quantity
                                                                      )}
                                                            </Box>
                                                            <Box className='currentUsage-cost'>
                                                                €{(item.amount / 100).toFixed(2)}
                                                            </Box>
                                                        </Box>
                                                    );
                                                })}
                                            </>
                                        ) : (
                                            <>
                                                {initialUser ? (
                                                    <Box className={classes.inActiveUserContainer}>
                                                        <MyEirInactiveUser
                                                            emptyIcon={Icon}
                                                            title='Welcome to My eir!'
                                                            description={description}
                                                        />
                                                    </Box>
                                                ) : (
                                                    <Box className='currentUsage-tabData-empty'>
                                                        <InfoOutlinedIcon className='currentUsage-tabData-emptyIcon' />
                                                        <Box className='currentUsage-tabData-emptyText'>
                                                            No charged usage since the last bill
                                                        </Box>
                                                    </Box>
                                                )}
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {usageDetailsCharged.length != 0 ? (
                                            usageDetailsCharged.map((item, index) => {
                                                return (
                                                    <Box className='currentUsage-tabHeadingsAndtabData'>
                                                        <Box className='currentUsage-mobDateAndTime'>
                                                            <Box className='currentUsage-tabHeadingsAndtabData-heading'>
                                                                Date & time
                                                            </Box>
                                                            <Box className='currentUsage-tabHeadingsAndtabData-data'>
                                                                {convertDateOnly(
                                                                    item.chargeStartDateTime
                                                                )}
                                                                <>&nbsp;&nbsp;&nbsp;</>
                                                                {convertTimeOnly(
                                                                    item.chargeStartDateTime
                                                                )}
                                                            </Box>
                                                        </Box>
                                                        <Box className='currentUsage-mobDestination'>
                                                            <Box className='currentUsage-tabHeadingsAndtabData-heading'>
                                                                Destination
                                                            </Box>
                                                            <Box className='currentUsage-tabHeadingsAndtabData-data'>
                                                                {convertPhoneNumber(
                                                                    item.destinationNumber
                                                                )}
                                                            </Box>
                                                        </Box>
                                                        <Box className='currentUsage-mobDuration'>
                                                            <Box className='currentUsage-tabHeadingsAndtabData-heading'>
                                                                Duration/Quantity
                                                            </Box>
                                                            <Box className='currentUsage-tabHeadingsAndtabData-data'>
                                                                {item.quantity == 0
                                                                    ? convertDuration(item.duration)
                                                                    : !!item.destinationNumber
                                                                    ? item.quantity
                                                                    : convertDataUsage(
                                                                          item.quantity
                                                                      )}
                                                            </Box>
                                                        </Box>
                                                        <Box className='currentUsage-mobCost'>
                                                            <Box className='currentUsage-tabHeadingsAndtabData-heading'>
                                                                Cost
                                                            </Box>
                                                            <Box className='currentUsage-tabHeadingsAndtabData-data'>
                                                                €{(item.amount / 100).toFixed(2)}
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                );
                                            })
                                        ) : (
                                            <>
                                                {initialUser ? (
                                                    <Box className={classes.inActiveUserContainer}>
                                                        <MyEirInactiveUser
                                                            emptyIcon={Icon}
                                                            title='Welcome to My eir!'
                                                            description={description}
                                                        />
                                                    </Box>
                                                ) : (
                                                    <Box className='currentUsage-tabData-empty'>
                                                        <InfoOutlinedIcon className='currentUsage-tabData-emptyIcon' />
                                                        <Box className='currentUsage-tabData-emptyText'>
                                                            No charged usage since the last bill
                                                        </Box>
                                                    </Box>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}
                            </TabPanel>
                            <TabPanel>
                                {width > 1019 ? (
                                    <>
                                        {usageDetailsFree.length != 0 ? (
                                            <>
                                                <Box className='currentUsage-tabHeadings'>
                                                    <Box className='currentUsage-dateTime'>
                                                        Date & time
                                                    </Box>
                                                    <Box className='currentUsage-destination'>
                                                        Destination
                                                    </Box>
                                                    <Box className='currentUsage-duration'>
                                                        Duration/Quantity
                                                    </Box>
                                                    <Box className='currentUsage-cost'>Cost</Box>
                                                </Box>
                                                {usageDetailsFree.map((item, index) => {
                                                    return (
                                                        <Box className='currentUsage-tabData'>
                                                            {' '}
                                                            {/*map from api data */}
                                                            <Box className='currentUsage-dateTime'>
                                                                {convertDateOnly(
                                                                    item.chargeStartDateTime
                                                                )}
                                                                <>&nbsp;&nbsp;&nbsp;</>
                                                                {convertTimeOnly(
                                                                    item.chargeStartDateTime
                                                                )}
                                                            </Box>
                                                            <Box className='currentUsage-destination'>
                                                                {convertPhoneNumber(
                                                                    item.destinationNumber
                                                                )}
                                                            </Box>
                                                            <Box className='currentUsage-duration'>
                                                                {item.quantity == 0
                                                                    ? convertDuration(item.duration)
                                                                    : !!item.destinationNumber
                                                                    ? item.quantity
                                                                    : convertDataUsage(
                                                                          item.quantity
                                                                      )}
                                                            </Box>
                                                            <Box className='currentUsage-cost'>
                                                                Free
                                                            </Box>
                                                        </Box>
                                                    );
                                                })}
                                            </>
                                        ) : (
                                            <>
                                                {initialUser ? (
                                                    <MyEirInactiveUser
                                                        emptyIcon={Icon}
                                                        title='Welcome to My eir!'
                                                        description={description}
                                                    />
                                                ) : (
                                                    <Box className='currentUsage-tabData-empty'>
                                                        <InfoOutlinedIcon className='currentUsage-tabData-emptyIcon' />
                                                        <Box className='currentUsage-tabData-emptyText'>
                                                            No free rated usage since the last bill
                                                        </Box>
                                                    </Box>
                                                )}
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {usageDetailsFree.length != 0 ? (
                                            usageDetailsFree.map((item, index) => {
                                                return (
                                                    <Box className='currentUsage-tabHeadingsAndtabData'>
                                                        <Box className='currentUsage-mobDateAndTime'>
                                                            <Box className='currentUsage-tabHeadingsAndtabData-heading'>
                                                                Date & time
                                                            </Box>
                                                            <Box className='currentUsage-tabHeadingsAndtabData-data'>
                                                                {convertDateOnly(
                                                                    item.chargeStartDateTime
                                                                )}
                                                                <>&nbsp;&nbsp;&nbsp;</>
                                                                {convertTimeOnly(
                                                                    item.chargeStartDateTime
                                                                )}
                                                            </Box>
                                                        </Box>
                                                        <Box className='currentUsage-mobDestination'>
                                                            <Box className='currentUsage-tabHeadingsAndtabData-heading'>
                                                                Destination
                                                            </Box>
                                                            <Box className='currentUsage-tabHeadingsAndtabData-data'>
                                                                {convertPhoneNumber(
                                                                    item.destinationNumber
                                                                )}
                                                            </Box>
                                                        </Box>
                                                        <Box className='currentUsage-mobDuration'>
                                                            <Box className='currentUsage-tabHeadingsAndtabData-heading'>
                                                                Duration/Quantity
                                                            </Box>
                                                            <Box className='currentUsage-tabHeadingsAndtabData-data'>
                                                                {item.quantity == 0
                                                                    ? convertDuration(item.duration)
                                                                    : !!item.destinationNumber
                                                                    ? item.quantity
                                                                    : convertDataUsage(
                                                                          item.quantity
                                                                      )}
                                                            </Box>
                                                        </Box>
                                                        <Box className='currentUsage-mobCost'>
                                                            <Box className='currentUsage-tabHeadingsAndtabData-heading'>
                                                                Cost
                                                            </Box>
                                                            <Box className='currentUsage-tabHeadingsAndtabData-data'>
                                                                Free
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                );
                                            })
                                        ) : (
                                            <>
                                                {initialUser ? (
                                                    <Box className={classes.inActiveUserContainer}>
                                                        <MyEirInactiveUser
                                                            emptyIcon={Icon}
                                                            title='Welcome to My eir!'
                                                            description={description}
                                                        />
                                                    </Box>
                                                ) : (
                                                    <Box className='currentUsage-tabData-empty'>
                                                        <InfoOutlinedIcon className='currentUsage-tabData-emptyIcon' />
                                                        <Box className='currentUsage-tabData-emptyText'>
                                                            No free rated usage since the last bill
                                                        </Box>
                                                    </Box>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}
                            </TabPanel>
                            <TabPanel>
                                {width > 1019 ? (
                                    <>
                                        {usageDetailsCall.length != 0 ? (
                                            <>
                                                <Box className='currentUsage-tabHeadings'>
                                                    <Box className='currentUsage-dateTime'>
                                                        Date & time
                                                    </Box>
                                                    <Box className='currentUsage-destination'>
                                                        Destination
                                                    </Box>
                                                    <Box className='currentUsage-duration'>
                                                        Duration
                                                    </Box>
                                                    <Box className='currentUsage-cost'>Cost</Box>
                                                </Box>
                                                {usageDetailsCall.map((item, index) => {
                                                    return (
                                                        <Box className='currentUsage-tabData'>
                                                            {' '}
                                                            {/*map from api data */}
                                                            <Box className='currentUsage-dateTime'>
                                                                {convertDateOnly(
                                                                    item.chargeStartDateTime
                                                                )}
                                                                <>&nbsp;&nbsp;&nbsp;</>
                                                                {convertTimeOnly(
                                                                    item.chargeStartDateTime
                                                                )}
                                                            </Box>
                                                            <Box className='currentUsage-destination'>
                                                                {convertPhoneNumber(
                                                                    item.destinationNumber
                                                                )}
                                                            </Box>
                                                            <Box className='currentUsage-duration'>
                                                                {convertDuration(item.duration)}
                                                            </Box>
                                                            <Box className='currentUsage-cost'>
                                                                {item.amount == 0
                                                                    ? 'Free'
                                                                    : '€' +
                                                                      (item.amount / 100).toFixed(
                                                                          2
                                                                      )}
                                                            </Box>
                                                        </Box>
                                                    );
                                                })}
                                            </>
                                        ) : (
                                            <>
                                                {initialUser ? (
                                                    <Box className={classes.inActiveUserContainer}>
                                                        <MyEirInactiveUser
                                                            emptyIcon={Icon}
                                                            title='Welcome to My eir!'
                                                            description={description}
                                                        />
                                                    </Box>
                                                ) : (
                                                    <Box className='currentUsage-tabData-empty'>
                                                        <InfoOutlinedIcon className='currentUsage-tabData-emptyIcon' />
                                                        <Box className='currentUsage-tabData-emptyText'>
                                                            No calls made since the last bill
                                                        </Box>
                                                    </Box>
                                                )}
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {usageDetailsCall.length != 0 ? (
                                            usageDetailsCall.map((item, index) => {
                                                return (
                                                    <Box className='currentUsage-tabHeadingsAndtabData'>
                                                        <Box className='currentUsage-mobDateAndTime'>
                                                            <Box className='currentUsage-tabHeadingsAndtabData-heading'>
                                                                Date & time
                                                            </Box>
                                                            <Box className='currentUsage-tabHeadingsAndtabData-data'>
                                                                {convertDateOnly(
                                                                    item.chargeStartDateTime
                                                                )}
                                                                <>&nbsp;&nbsp;&nbsp;</>
                                                                {convertTimeOnly(
                                                                    item.chargeStartDateTime
                                                                )}
                                                            </Box>
                                                        </Box>
                                                        <Box className='currentUsage-mobDestination'>
                                                            <Box className='currentUsage-tabHeadingsAndtabData-heading'>
                                                                Destination
                                                            </Box>
                                                            <Box className='currentUsage-tabHeadingsAndtabData-data'>
                                                                {convertPhoneNumber(
                                                                    item.destinationNumber
                                                                )}
                                                            </Box>
                                                        </Box>
                                                        <Box className='currentUsage-mobDuration'>
                                                            <Box className='currentUsage-tabHeadingsAndtabData-heading'>
                                                                Duration
                                                            </Box>
                                                            <Box className='currentUsage-tabHeadingsAndtabData-data'>
                                                                {convertDuration(item.duration)}
                                                            </Box>
                                                        </Box>
                                                        <Box className='currentUsage-mobCost'>
                                                            <Box className='currentUsage-tabHeadingsAndtabData-heading'>
                                                                Cost
                                                            </Box>
                                                            <Box className='currentUsage-tabHeadingsAndtabData-data'>
                                                                {item.amount == 0
                                                                    ? 'Free'
                                                                    : '€' +
                                                                      (item.amount / 100).toFixed(
                                                                          2
                                                                      )}
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                );
                                            })
                                        ) : (
                                            <>
                                                {initialUser ? (
                                                    <Box className={classes.inActiveUserContainer}>
                                                        <MyEirInactiveUser
                                                            emptyIcon={Icon}
                                                            title='Welcome to My eir!'
                                                            description={description}
                                                        />
                                                    </Box>
                                                ) : (
                                                    <Box className='currentUsage-tabData-emptyText'>
                                                        <InfoOutlinedIcon className='currentUsage-tabData-emptyIcon' />
                                                        <Box className='currentUsage-tabData-emptyText'>
                                                            No calls made since the last bill
                                                        </Box>
                                                    </Box>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}
                            </TabPanel>
                            <TabPanel>
                                {width > 1019 ? (
                                    <>
                                        {usageDetailsText.length != 0 ? (
                                            <>
                                                <Box className='currentUsage-tabHeadings'>
                                                    <Box className='currentUsage-dateTime'>
                                                        Date & time
                                                    </Box>
                                                    <Box className='currentUsage-destination'>
                                                        Destination
                                                    </Box>
                                                    <Box className='currentUsage-duration'>
                                                        Quantity
                                                    </Box>
                                                    <Box className='currentUsage-cost'>Cost</Box>
                                                </Box>
                                                {usageDetailsText.map((item, index) => {
                                                    return (
                                                        <Box className='currentUsage-tabData'>
                                                            {' '}
                                                            {/*map from api data */}
                                                            <Box className='currentUsage-dateTime'>
                                                                {convertDateOnly(
                                                                    item.chargeStartDateTime
                                                                )}
                                                                <>&nbsp;&nbsp;&nbsp;</>
                                                                {convertTimeOnly(
                                                                    item.chargeStartDateTime
                                                                )}
                                                            </Box>
                                                            <Box className='currentUsage-destination'>
                                                                {convertPhoneNumber(
                                                                    item.destinationNumber
                                                                )}
                                                            </Box>
                                                            <Box className='currentUsage-duration'>
                                                                {item.quantity}
                                                            </Box>
                                                            <Box className='currentUsage-cost'>
                                                                {item.amount == 0
                                                                    ? 'Free'
                                                                    : '€' +
                                                                      (item.amount / 100).toFixed(
                                                                          2
                                                                      )}
                                                            </Box>
                                                        </Box>
                                                    );
                                                })}
                                            </>
                                        ) : (
                                            <>
                                                {initialUser ? (
                                                    <Box className={classes.inActiveUserContainer}>
                                                        <MyEirInactiveUser
                                                            emptyIcon={Icon}
                                                            title='Welcome to My eir!'
                                                            description={description}
                                                        />
                                                    </Box>
                                                ) : (
                                                    <Box className='currentUsage-tabData-empty'>
                                                        <InfoOutlinedIcon className='currentUsage-tabData-emptyIcon' />
                                                        <Box className='currentUsage-tabData-emptyText'>
                                                            No SMS sent since the last bill
                                                        </Box>
                                                    </Box>
                                                )}
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {usageDetailsText.length != 0 ? (
                                            usageDetailsText.map((item, index) => {
                                                return (
                                                    <Box className='currentUsage-tabHeadingsAndtabData'>
                                                        <Box className='currentUsage-mobDateAndTime'>
                                                            <Box className='currentUsage-tabHeadingsAndtabData-heading'>
                                                                Date & time
                                                            </Box>
                                                            <Box className='currentUsage-tabHeadingsAndtabData-data'>
                                                                {convertDateOnly(
                                                                    item.chargeStartDateTime
                                                                )}
                                                                <>&nbsp;&nbsp;&nbsp;</>
                                                                {convertTimeOnly(
                                                                    item.chargeStartDateTime
                                                                )}
                                                            </Box>
                                                        </Box>
                                                        <Box className='currentUsage-mobDestination'>
                                                            <Box className='currentUsage-tabHeadingsAndtabData-heading'>
                                                                Destination
                                                            </Box>
                                                            <Box className='currentUsage-tabHeadingsAndtabData-data'>
                                                                {convertPhoneNumber(
                                                                    item.destinationNumber
                                                                )}
                                                            </Box>
                                                        </Box>
                                                        <Box className='currentUsage-mobDuration'>
                                                            <Box className='currentUsage-tabHeadingsAndtabData-heading'>
                                                                Quantity
                                                            </Box>
                                                            <Box className='currentUsage-tabHeadingsAndtabData-data'>
                                                                {item.quantity}
                                                            </Box>
                                                        </Box>
                                                        <Box className='currentUsage-mobCost'>
                                                            <Box className='currentUsage-tabHeadingsAndtabData-heading'>
                                                                Cost
                                                            </Box>
                                                            <Box className='currentUsage-tabHeadingsAndtabData-data'>
                                                                {item.amount == 0
                                                                    ? 'Free'
                                                                    : '€' +
                                                                      (item.amount / 100).toFixed(
                                                                          2
                                                                      )}
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                );
                                            })
                                        ) : (
                                            <>
                                                {initialUser ? (
                                                    <Box className={classes.inActiveUserContainer}>
                                                        <MyEirInactiveUser
                                                            emptyIcon={Icon}
                                                            title='Welcome to My eir!'
                                                            description={description}
                                                        />
                                                    </Box>
                                                ) : (
                                                    <Box className='currentUsage-tabData-empty'>
                                                        <InfoOutlinedIcon className='currentUsage-tabData-emptyIcon' />
                                                        <Box className='currentUsage-tabData-emptyText'>
                                                            No SMS sent since the last bill
                                                        </Box>
                                                    </Box>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}
                            </TabPanel>
                            <TabPanel>
                                {!(dataNormal.quantity == 0 && dataRomaing.quantity == 0) ? (
                                    <Box className='currentUsage-tabHeadingsAndData'>
                                        <Box className='currentUsage-regularData'>
                                            <Box className='currentUsage-tabHeadingsAndData-heading'>
                                                Regular data
                                            </Box>
                                            <Box className='currentUsage-tabHeadingsAndData-data'>
                                                {convertDataUsage(dataNormal.quantity)}
                                            </Box>
                                        </Box>
                                        <Box className='currentUsage-roamingData'>
                                            <Box className='currentUsage-tabHeadingsAndData-heading'>
                                                Roaming data
                                            </Box>
                                            <Box className='currentUsage-tabHeadingsAndData-data'>
                                                {convertDataUsage(dataRomaing.quantity)}
                                            </Box>
                                        </Box>
                                        <Box className='currentUsage-dataCost'>
                                            <Box className='currentUsage-tabHeadingsAndData-heading'>
                                                Cost
                                            </Box>
                                            <Box className='currentUsage-tabHeadingsAndData-data'>
                                                {usageCost == 0
                                                    ? 'Free'
                                                    : '€' + (usageCost / 100).toFixed(2)}
                                            </Box>
                                        </Box>
                                    </Box>
                                ) : (
                                    <>
                                        {initialUser ? (
                                            <Box className={classes.inActiveUserContainer}>
                                                <MyEirInactiveUser
                                                    emptyIcon={Icon}
                                                    title='Welcome to My eir!'
                                                    description={description}
                                                />
                                            </Box>
                                        ) : (
                                            <Box className='currentUsage-tabData-empty'>
                                                <InfoOutlinedIcon className='currentUsage-tabData-emptyIcon' />
                                                <Box className='currentUsage-tabData-emptyText'>
                                                    No data used since the last bill
                                                </Box>
                                            </Box>
                                        )}
                                    </>
                                )}
                                {/* <Box className="currentUsage-historicalUsageData">View historical usage</Box> */}
                            </TabPanel>
                        </Tabs>
                    )}
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
}

export default SeeCurrentUsage;
