import { gql } from '@apollo/client';

const ENDPOINT = {
    CHECK_OFFERS_ELIGIBITY: gql`
        query crossSell(
            $accountId: Int!
            $token: String!
            $reference: String
            $parentSubscriptionId: String
        ) {
            checkCrossSellEligibility(
                accountId: $accountId
                token: $token
                reference: $reference
                parentSubscriptionId: $parentSubscriptionId
            ) {
                offerTypes {
                    causes
                    eligible
                    offerType
                }
                serviceGroups {
                    causes
                    eligible
                    serviceGroup
                }
                error
            }
        }
    `,
    GET_TARIFF: gql`
        query getTariffRecomendedOffers(
            $serviceGroupCode: String!
            $acquisitionType: String!
            $catalogTariffCode: [String]
        ) {
            getTariffs(
                serviceGroupCode: $serviceGroupCode
                acquisitionType: $acquisitionType
                catalogTariffCode: $catalogTariffCode
            ) {
                tariffs {
                    code
                    offerCode
                    commitmentDuration
                    acquisitionType
                    discountDuration
                    description
                    displayPrice
                    displayName
                    defaultPrice
                    portIn
                    simOnly
                    serviceGroupCode
                    attributes {
                        type
                        value
                        sortOrder
                        icon
                    }
                }
            }
        }
    `,
    CROSS_SELL_PROSPECT: gql`
        mutation createCrossSell(
            $accountId: String!
            $offerType: String!
            $channel: String!
            $email: String
            $token: String!
        ) {
            createCrossSellProspect(
                accountId: $accountId
                offerType: $offerType
                channel: $channel
                email: $email
                token: $token
            )
        }
    `,
    DUALPLAY_ELIGIBLITY_ADDRESS_INFO: gql`
        query dualplayEligiblityandAddressInfo($accountId: String!, $token: String!) {
            getCSTVDetails(accountId: $accountId, token: $token) {
                status
                id
                serviceGroup
                servicesForSubscription {
                    sipNumber
                    dsl
                    broadbandDsl
                    msisdn
                    name
                }
                addressInfo {
                    addressLine1
                    addressLine2
                    addressLine3
                    county
                    code
                    town
                }
                csEligiblity {
                    dpEligibity {
                        eligible
                        serviceGroup
                    }
                }
            }
        }
    `,
};

/**
 *
 * @name getRecomendedOffersEligibityApi - Function to call the api to check the eligiblity of the offers
 * @param {Object} Obj - attributes for the function
 * @param {GraphQL} Obj.graphql - appoloclient graphql constant
 * @param {Number} Obj.accountId - Account number of the users
 * @param {String} Obj.token - Jwt token of the loggedIn user
 *
 * @returns {Object} - returns the offers eligiblity
 */
export const getRecomendedOffersEligibityApi = async ({
    graphql,
    accountId,
    token,
    reference,
    parentSubscriptionId,
}) => {
    try {
        const response = await graphql.query({
            query: ENDPOINT.CHECK_OFFERS_ELIGIBITY,
            variables: {
                token: token,
                accountId: accountId,
                reference: reference,
                parentSubscriptionId: parentSubscriptionId,
            },
        });
        return response;
    } catch (error) {
        throw new Error(error);
    }
};
export const getRecomendedOffersApi = async ({ graphql, serviceGroupCode, catalogTariffCode }) => {
    try {
        const res = await graphql.query({
            query: ENDPOINT.GET_TARIFF,
            variables: {
                serviceGroupCode: serviceGroupCode,
                catalogTariffCode: catalogTariffCode,
                acquisitionType: 'CROSS_SELL',
            },
        });
        if (res.data) {
            return res.data;
        } else {
            throw 'Recomended offers was not returned from API';
        }
    } catch (error) {
        throw new Error(error);
    }
};

/**
 *
 * @name getRecomendedOffersProspect - Function to call the api to create cross sell prospect of the offers
 * @param {Object} Obj - attributes for the function
 * @param {GraphQL} Obj.graphql - appoloclient graphql constant
 *
 * @returns {Object} - returns createCrossSellProspect
 */
export const getRecomendedOffersProspect = async ({ graphql }) => {
    try {
        const response = await graphql.mutate({
            mutation: ENDPOINT.CROSS_SELL_PROSPECT,
            variables: {
                token: sessionStorage.getItem('access_token'),
                accountId: sessionStorage.getItem('LoggedinUserAccountID'),
                email: sessionStorage.getItem('LoggedinUser'),
                channel: 'MYEIR',
                offerType: 'POSTPAY',
            },
        });
        return response;
    } catch (error) {
        throw new Error(error);
    }
};

export const getDpEligibilityAndAdressInfoApi = async ({ graphql, accountId, token }) => {
    try {
        const response = await graphql.query({
            query: ENDPOINT.DUALPLAY_ELIGIBLITY_ADDRESS_INFO,
            variables: {
                token: token,
                accountId: accountId,
            },
        });
        return response;
    } catch (error) {
        throw new Error(error);
    }
};
