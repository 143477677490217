import React, { useState, Fragment, useEffect } from 'react';
// import { Grid, TextField, Button, Tooltip, InputAdornment, IconButton } from '@material-ui/core';
// import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import {
    Grid,
    InputAdornment,
    IconButton,
    FormControl,
    InputLabel,
    OutlinedInput,
    TextField,
    Typography,
    Box,
} from '@material-ui/core';
import errormesages from '../../components/my-eir-register/errormessages';
import HelpIcon from '@material-ui/icons/Help';
import { Controller, useForm, ErrorMessage } from 'react-hook-form';
import ENDPOINT from '../../components/graphql/GraphQLConstants';
import { useApolloClient } from '@apollo/client';
import { MY_EIR_CONST } from '../../components/myeirconstant/myEirConstant';
import { commonRegisterStyles } from '../css/myEirRegistrationStyles/commonRegisterStyles';
import { useEnterAcccountNumberStyle } from '../css/myEirRegistrationStyles/useEnterAcccountNumberStyle';
import { useVerificationCodeStyles } from '../css/useVerificationCodeStyles';
import MyEirRegisterHeader from '../MyEirPostpayRegistration/reusable/MyEirRegisterHeader';
import MyEirRegisterFooter from '../MyEirPostpayRegistration/reusable/MyEirRegisterFooter';
import MyEirAlreadyRegistered from '../MyEirPostpayRegistration/MyEirAlreadyRegistered';
import { useRegisterEmailStyle } from '../css/myEirRegistrationStyles/useRegisterEmailStyle';
import { useResetPasswordFormStyles } from '../css/myEirRegistrationStyles/useResetPasswordFormStyles';
import MyEirErrorHandle from '../../mobilecomponents/eir-porting/MyEirErrorHandle';
import tick from '../images/CheckFilled.svg';
import cross from '../images/CloseFilled.svg';
import TerminatedTile from '../../mobilecomponents/reusable-components/TerminatedTile';
import { useWindowDimension } from '../../hooks/windowDimensions';

function MyEirCreatePasswordFormPage(props) {
    const { validation } = errormesages;
    // const { token } = useParams();
    const [password, setPassword] = useState('');
    const [showPassLabel, setShowPassLabel] = useState(false);
    const [showConfirmPassLabel, setShowConfirmPassLabel] = useState(false);
    const [show, setShow] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [errMsg, setErrMsg] = useState(false);
    const [errorMsg, setErrorMsg] = useState(false);
    const [confirmBtn, setConfirmBtn] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [PasswordError, setPasswordError] = useState(false);
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);
    const [confirmPasswordMsg, setConfirmPasswordMsg] = useState(false);
    const graphql = useApolloClient();
    const [isOpen1, setIsopen1] = useState(false);
    const [isOpen2, setIsopen2] = useState(false);
    const [openError, setOpenError] = useState(false);
    const [waitingApiResponse, setWaitingApiResponse] = useState(false);
    const [isPasswordHistoryError, setIsPasswordHistoryError] = useState(false);
    const [requirments, setRequirments] = useState({
        minLength: false,
        hasLowerCase: false,
        hasUpperCase: false,
        hasNumber: false,
        hasSpecialChar: false,
    });
    const [isTyping, setIsTyping] = useState(false);
    const [width, height] = useWindowDimension();

    const handleNextClick = () => {
        toggle2();
    };
    const toggle1 = () => {
        setIsopen1(!isOpen1);
    };

    const toggle2 = () => {
        setIsopen2(!isOpen2);
    };

    const toggleError = () => {
        setOpenError(!openError);
    };

    const { register, control, errors, handleSubmit, setValue, clearError } = useForm({
        mode: 'onChange',
    });
    const [tokenValue, setTokenValue] = useState('');
    useEffect(() => {
        let searchParams = new URLSearchParams(window.location.search);
        setTokenValue(searchParams.get('token'));
        if (
            searchParams.get('token') == '' ||
            searchParams.get('token') == null ||
            searchParams.get('token') == undefined
        ) {
            sessionStorage.clear();
            window.location.href = MY_EIR_CONST.url.REDIRECT_URL;
            //window.location.href= window.location.origin;
        }
    }, []);
    const handlePasswordChange = event => {
        const passwordValue = event.target.value;
        setPassword(passwordValue);
        var regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^a-zA-Z0-9]).{8,}$/u;
        if (!isTyping) {
            setIsTyping(true);
        }
        if (errors.password) {
            setPasswordError(false);
            setErrMsg(false);
        }
        if (passwordValue) {
            setRequirments({
                minLength: passwordValue.length >= 8,
                hasLowerCase: /[a-z]/.test(passwordValue),
                hasUpperCase: /[A-Z]/.test(passwordValue),
                hasNumber: /[0-9]/.test(passwordValue),
                hasSpecialChar: /[^a-zA-Z0-9]/u.test(passwordValue),
            });
        }
        if (isPasswordHistoryError) {
            if (confirmPassword == password) {
                setConfirmPasswordError(false);
                setErrorMsg(false);
                setConfirmBtn(true);
            } else {
                setConfirmPasswordError(true);
                setErrorMsg(true);
                setConfirmBtn(false);
            }
        }
        if (regex.test(passwordValue) == false) {
            setPasswordError(true);
            setErrMsg(true);
            if (errors.password) {
                clearError('password');
            }
        } else {
            setPasswordError(false);
            setErrMsg(false);
        }
    };
    const handleConfirmPasswordChange = event => {
        const confirmpasswordValue = event.target.value;
        setConfirmPassword(confirmpasswordValue);

        if (errors.confirmPassword) {
            setConfirmPasswordError(false);
            setErrorMsg(false);
        }
        if (confirmpasswordValue == password) {
            setConfirmPasswordError(false);
            setIsPasswordHistoryError(false);
            setErrorMsg(false);
            setConfirmBtn(true);
        } else {
            setConfirmPasswordError(true);
            setErrorMsg(true);
            setConfirmBtn(false);
        }
    };

    const onSubmit = async data => {
        try {
            setWaitingApiResponse(true);
            const res = await graphql.mutate({
                mutation: ENDPOINT.SET_PASSWORD,
                variables: {
                    password: data.password,
                    token: tokenValue,
                },
            });

            setWaitingApiResponse(false);

            if (res.data.setPassword) {
                toggle2();
            } else {
                console.error('something went wrong while changing password');
                toggleError();
            }
        } catch (err) {
            console.error('something went wrong while changing password: ', err.message);
            setWaitingApiResponse(false);
            var regex = /\blast\b.*password\b|\bpassword\b.*\blast\b/i;
            if (regex.test(err.message)) {
                setIsPasswordHistoryError(true);
            } else {
                toggleError();
            }
        }
    };

    const commonStyle = commonRegisterStyles();
    const useStyle = useVerificationCodeStyles();
    const registerStyle = useRegisterEmailStyle();
    const resetStyles = useResetPasswordFormStyles();

    const handleClickShow = () => {
        setShow(!show);
    };

    const handleMouseDownPass = () => {
        setShow(!show);
    };
    const handleClickShowConfirm = () => {
        setShowConfirm(!showConfirm);
    };

    const handleMouseDownPassConfirm = () => {
        setShowConfirm(!showConfirm);
    };
    const handleCancel = () => {
        window.location.href = '/';
    };

    const handlePassLabel = val => {
        setShowPassLabel(val);
    };
    const handleConfirmPassLabel = val => {
        setShowConfirmPassLabel(val);
    };

    return (
        <div className={commonStyle.registrationContainer}>
            <div className={commonStyle.cardContainer}>
                <MyEirRegisterHeader headerText='Reset password' registerScreen='1' />

                <form onSubmit={handleSubmit(onSubmit)} className='createForm'>
                    {!confirmPasswordMsg && (
                        <div className={`${resetStyles.bodyContainer} `}>
                            <Grid
                                container
                                spacing={2}
                                className='register-email-body'
                                style={
                                    PasswordError && !errors.password
                                        ? { width: 'auto', marginBottom: '24px' }
                                        : { width: 'auto' }
                                }
                            >
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    className='register-email-grid-email'
                                    style={{ padding: '0px' }}
                                >
                                    <div>
                                        <Controller
                                            as={
                                                <TextField
                                                    type={show ? 'text' : 'password'}
                                                    className='register-grid-input-text-field'
                                                    style={{ height: '56px' }}
                                                    variant='outlined'
                                                    color='primary'
                                                    InputLabelProps={{
                                                        classes: {
                                                            root:
                                                                'text-field-input-label-props-root',

                                                            shrink:
                                                                'text-field-input-label-props-shrink-tempRemoval' +
                                                                (errMsg || isPasswordHistoryError
                                                                    ? ' text-field-input-label-props-shrink-invalid'
                                                                    : password != ''
                                                                    ? ' label-success'
                                                                    : ''),
                                                            focused:
                                                                'text-field-input-label-props-focused' +
                                                                (errMsg && !!password
                                                                    ? ' text-field-input-label-props-focused-invalid'
                                                                    : ''),
                                                        },
                                                    }}
                                                    InputProps={{
                                                        classes: {
                                                            root:
                                                                'new-password-root ' +
                                                                (errMsg || isPasswordHistoryError
                                                                    ? 'new-password-error'
                                                                    : password != ''
                                                                    ? 'new-password-success'
                                                                    : ''),

                                                            focused: 'new-password-input-focused',
                                                            notchedOutline:
                                                                'new-password-input-notched',
                                                            input: 'new-password-input',
                                                        },
                                                        endAdornment: (
                                                            <InputAdornment position='end'>
                                                                <IconButton
                                                                    aria-label='toggle password visibility'
                                                                    onClick={handleClickShow}
                                                                    onMouseDown={
                                                                        handleMouseDownPass
                                                                    }
                                                                >
                                                                    {show ? (
                                                                        <VisibilityOff />
                                                                    ) : (
                                                                        <Visibility />
                                                                    )}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    onFocus={() => {
                                                        handlePassLabel(true);
                                                    }}
                                                    onBlur={() => {
                                                        handlePassLabel(false);
                                                    }}
                                                    disabled={waitingApiResponse}
                                                />
                                            }
                                            onChange={([event]) => {
                                                handlePasswordChange(event);
                                                return event.target.value;
                                            }}
                                            control={control}
                                            fullWidth
                                            id='password'
                                            name='password'
                                            label={
                                                showPassLabel || !!password
                                                    ? 'New password'
                                                    : 'New password'
                                            }
                                            variant='outlined'
                                            size='small'
                                            value={password}
                                            defaultValue={password}
                                        />
                                    </div>
                                    {/* {errors.password && errors.password.type === 'required' && (
                                    <span className='invalid-messages'>Please enter password</span>
                                )} */}
                                    {PasswordError && !errors.password && (
                                        <Typography
                                            variant='inputLabel'
                                            className='invalid-email-format'
                                            style={{
                                                textAlign: 'left',

                                                fontSize: '12px',
                                            }}
                                        >
                                            Password does not meet requirements, try again.
                                        </Typography>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                spacing={2}
                                className='register-email-body'
                                style={
                                    confirmPasswordError && !errors.confirmPassword
                                        ? { width: 'auto', marginBottom: '24px' }
                                        : { width: 'auto' }
                                }
                            >
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    className='register-email-grid-confirm-email'
                                    style={{ padding: '0px' }}
                                >
                                    <div>
                                        <Controller
                                            as={
                                                <TextField
                                                    type={showConfirm ? 'text' : 'password'}
                                                    className='register-grid-input-text-field'
                                                    variant='outlined'
                                                    color='primary'
                                                    InputLabelProps={{
                                                        classes: {
                                                            root:
                                                                'text-field-input-label-props-root',
                                                            shrink:
                                                                'text-field-input-label-props-shrink-tempRemoval' +
                                                                (isPasswordHistoryError || errorMsg
                                                                    ? ' text-field-input-label-props-shrink-nonMatch'
                                                                    : confirmBtn &&
                                                                      !isPasswordHistoryError
                                                                    ? ' label-success'
                                                                    : '') +
                                                                (isPasswordHistoryError ||
                                                                (errMsg && !!confirmPassword)
                                                                    ? ' text-field-input-label-props-shrink-invalid'
                                                                    : confirmBtn &&
                                                                      !isPasswordHistoryError
                                                                    ? ' label-success'
                                                                    : ''),
                                                            focused:
                                                                'text-field-input-label-props-focused' +
                                                                (errMsg && !!confirmPassword
                                                                    ? ' text-field-input-label-props-focused-invalid'
                                                                    : ''),
                                                        },
                                                    }}
                                                    InputProps={{
                                                        classes: {
                                                            root:
                                                                'new-password-root ' +
                                                                (errorMsg || isPasswordHistoryError
                                                                    ? 'new-password-error'
                                                                    : confirmBtn
                                                                    ? ' new-password-success'
                                                                    : ''),

                                                            focused: 'new-password-input-focused',
                                                            notchedOutline:
                                                                'new-password-input-notched',
                                                            input: 'new-password-input',
                                                        },
                                                        // classes: {
                                                        //   root:
                                                        //     "text-field-input-props-root" +
                                                        //     (!!confirmPassword
                                                        //       ? " text-field-input-props-root-filled"
                                                        //       : ""),
                                                        //   focused: "text-field-input-props-focused",
                                                        //   notchedOutline:
                                                        //     (showConfirmPassLabel
                                                        //       ? "text-field-input-props-notchedOutline"
                                                        //       : "text-field-input-props-notchedOutline-notFocus") +
                                                        //     ((errorMsg && !!confirmPassword) ||
                                                        //     (!errMsg && errorMsg)
                                                        //       ? " text-field-input-props-notchedOutline-invalid"
                                                        //       : ""),
                                                        //   input: "text-field-input-props-input",
                                                        // },
                                                        endAdornment: (
                                                            <InputAdornment position='end'>
                                                                <IconButton
                                                                    aria-label='toggle password visibility'
                                                                    onClick={handleClickShowConfirm}
                                                                    onMouseDown={
                                                                        handleMouseDownPassConfirm
                                                                    }
                                                                >
                                                                    {showConfirm ? (
                                                                        <VisibilityOff />
                                                                    ) : (
                                                                        <Visibility />
                                                                    )}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    onFocus={() => {
                                                        handleConfirmPassLabel(true);
                                                    }}
                                                    onBlur={() => {
                                                        handleConfirmPassLabel(false);
                                                    }}
                                                    disabled={waitingApiResponse}
                                                />
                                            }
                                            onChange={([event]) => {
                                                handleConfirmPasswordChange(event);
                                                return event.target.value;
                                            }}
                                            control={control}
                                            fullWidth
                                            id='confirmPassword'
                                            name='confirmPassword'
                                            label={
                                                showConfirmPassLabel || !!confirmPassword
                                                    ? 'Confirm password'
                                                    : 'Confirm password'
                                            }
                                            variant='outlined'
                                            size='small'
                                            value={confirmPassword}
                                            defaultValue={confirmPassword}
                                        />
                                    </div>
                                    {/* {errors.confirmPassword &&
                                    errors.confirmPassword.type === 'required' && (
                                        <span className='invalid-messages'>Please enter password</span>
                                    )} */}
                                    {confirmPasswordError && !errors.confirmPassword && (
                                        <Typography
                                            variant='inputLabel'
                                            className='invalid-email-format'
                                            style={{
                                                textAlign: 'left',

                                                fontSize: '12px',
                                            }}
                                        >
                                            Please make sure the passwords match.
                                        </Typography>
                                    )}
                                </Grid>
                            </Grid>
                            {isPasswordHistoryError ? (
                                <TerminatedTile
                                    terminatedCopy='You have used this password in the last 10 attempts. Please try a different password.'
                                    flag='resetPassword'
                                />
                            ) : (
                                <>
                                    <div className={resetStyles.listTag}>Password requirements</div>
                                    {width <= 600 ? (
                                        <Grid container spacing={2} className='register-email-body'>
                                            <Grid
                                                item
                                                xs={6}
                                                sm={6}
                                                md={6}
                                                className='register-email-grid-confirm-email'
                                                style={{ textAlign: 'start', padding: '0px' }}
                                            >
                                                <ul className={resetStyles.list}>
                                                    <li className={resetStyles.listOptions}>
                                                        {isTyping ? (
                                                            requirments.minLength ? (
                                                                <Box
                                                                    component='img'
                                                                    src={tick}
                                                                    alt='icon'
                                                                    className={resetStyles.icon}
                                                                    style={{
                                                                        dispaly: isTyping
                                                                            ? 'block'
                                                                            : 'none',
                                                                    }}
                                                                />
                                                            ) : (
                                                                <Box
                                                                    component='img'
                                                                    src={cross}
                                                                    alt='icon'
                                                                    className={resetStyles.icon}
                                                                    style={{
                                                                        dispaly: isTyping
                                                                            ? 'block'
                                                                            : 'none',
                                                                    }}
                                                                />
                                                            )
                                                        ) : (
                                                            <Box className={resetStyles.icon}></Box>
                                                        )}{' '}
                                                        <Box className={resetStyles.policy}>
                                                            Min. 8 characters
                                                        </Box>
                                                    </li>
                                                    <li className={resetStyles.listOptions}>
                                                        {isTyping ? (
                                                            requirments.hasLowerCase ? (
                                                                <Box
                                                                    component='img'
                                                                    src={tick}
                                                                    alt='icon'
                                                                    className={resetStyles.icon}
                                                                />
                                                            ) : (
                                                                <Box
                                                                    component='img'
                                                                    src={cross}
                                                                    alt='icon'
                                                                    className={resetStyles.icon}
                                                                />
                                                            )
                                                        ) : (
                                                            <Box className={resetStyles.icon}></Box>
                                                        )}{' '}
                                                        <Box className={resetStyles.policy}>
                                                            1 lowercase letter
                                                        </Box>
                                                    </li>
                                                    <li className={resetStyles.listOptions}>
                                                        {isTyping ? (
                                                            requirments.hasUpperCase ? (
                                                                <Box
                                                                    component='img'
                                                                    src={tick}
                                                                    alt='icon'
                                                                    className={resetStyles.icon}
                                                                />
                                                            ) : (
                                                                <Box
                                                                    component='img'
                                                                    src={cross}
                                                                    alt='icon'
                                                                    className={resetStyles.icon}
                                                                />
                                                            )
                                                        ) : (
                                                            <Box className={resetStyles.icon}></Box>
                                                        )}{' '}
                                                        <Box className={resetStyles.policy}>
                                                            1 capital letter
                                                        </Box>
                                                    </li>
                                                </ul>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={6}
                                                sm={6}
                                                md={6}
                                                className='register-email-grid-confirm-email'
                                                style={{ textAlign: 'start', padding: '0px' }}
                                            >
                                                <ul className={resetStyles.list}>
                                                    <li className={resetStyles.listOptions}>
                                                        {isTyping ? (
                                                            requirments.hasSpecialChar ? (
                                                                <Box
                                                                    component='img'
                                                                    src={tick}
                                                                    alt='icon'
                                                                    className={resetStyles.icon}
                                                                />
                                                            ) : (
                                                                <Box
                                                                    component='img'
                                                                    src={cross}
                                                                    alt='icon'
                                                                    className={resetStyles.icon}
                                                                />
                                                            )
                                                        ) : (
                                                            <Box className={resetStyles.icon}></Box>
                                                        )}{' '}
                                                        <Box className={resetStyles.policy}>
                                                            1 special character
                                                        </Box>
                                                    </li>
                                                    <li className={resetStyles.listOptions}>
                                                        {isTyping ? (
                                                            requirments.hasNumber ? (
                                                                <Box
                                                                    component='img'
                                                                    src={tick}
                                                                    alt='icon'
                                                                    className={resetStyles.icon}
                                                                />
                                                            ) : (
                                                                <Box
                                                                    component='img'
                                                                    src={cross}
                                                                    alt='icon'
                                                                    className={resetStyles.icon}
                                                                />
                                                            )
                                                        ) : (
                                                            <Box className={resetStyles.icon}></Box>
                                                        )}{' '}
                                                        <Box className={resetStyles.policy}>
                                                            1 number
                                                        </Box>
                                                    </li>
                                                </ul>
                                            </Grid>
                                        </Grid>
                                    ) : (
                                        <Grid container spacing={2} className='register-email-body'>
                                            <Grid
                                                item
                                                xs={4}
                                                sm={4}
                                                md={4}
                                                className='register-email-grid-confirm-email'
                                                style={{ textAlign: 'start', padding: '0px' }}
                                            >
                                                <ul className={resetStyles.list}>
                                                    <li className={resetStyles.listOptions}>
                                                        {isTyping ? (
                                                            requirments.minLength ? (
                                                                <Box
                                                                    component='img'
                                                                    src={tick}
                                                                    alt='icon'
                                                                    className={resetStyles.icon}
                                                                    style={{
                                                                        dispaly: isTyping
                                                                            ? 'block'
                                                                            : 'none',
                                                                    }}
                                                                />
                                                            ) : (
                                                                <Box
                                                                    component='img'
                                                                    src={cross}
                                                                    alt='icon'
                                                                    className={resetStyles.icon}
                                                                    style={{
                                                                        dispaly: isTyping
                                                                            ? 'block'
                                                                            : 'none',
                                                                    }}
                                                                />
                                                            )
                                                        ) : (
                                                            <Box className={resetStyles.icon}></Box>
                                                        )}{' '}
                                                        <Box className={resetStyles.policy}>
                                                            Min. 8 characters
                                                        </Box>
                                                    </li>
                                                    <li className={resetStyles.listOptions}>
                                                        {isTyping ? (
                                                            requirments.hasLowerCase ? (
                                                                <Box
                                                                    component='img'
                                                                    src={tick}
                                                                    alt='icon'
                                                                    className={resetStyles.icon}
                                                                />
                                                            ) : (
                                                                <Box
                                                                    component='img'
                                                                    src={cross}
                                                                    alt='icon'
                                                                    className={resetStyles.icon}
                                                                />
                                                            )
                                                        ) : (
                                                            <Box className={resetStyles.icon}></Box>
                                                        )}{' '}
                                                        <Box className={resetStyles.policy}>
                                                            1 lowercase letter
                                                        </Box>
                                                    </li>
                                                </ul>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={4}
                                                sm={4}
                                                md={4}
                                                className='register-email-grid-confirm-email'
                                                style={{ textAlign: 'start', padding: '0px' }}
                                            >
                                                <ul className={resetStyles.list}>
                                                    <li className={resetStyles.listOptions}>
                                                        {isTyping ? (
                                                            requirments.hasUpperCase ? (
                                                                <Box
                                                                    component='img'
                                                                    src={tick}
                                                                    alt='icon'
                                                                    className={resetStyles.icon}
                                                                />
                                                            ) : (
                                                                <Box
                                                                    component='img'
                                                                    src={cross}
                                                                    alt='icon'
                                                                    className={resetStyles.icon}
                                                                />
                                                            )
                                                        ) : (
                                                            <Box className={resetStyles.icon}></Box>
                                                        )}{' '}
                                                        <Box className={resetStyles.policy}>
                                                            1 capital letter
                                                        </Box>
                                                    </li>
                                                    <li className={resetStyles.listOptions}>
                                                        {isTyping ? (
                                                            requirments.hasSpecialChar ? (
                                                                <Box
                                                                    component='img'
                                                                    src={tick}
                                                                    alt='icon'
                                                                    className={resetStyles.icon}
                                                                />
                                                            ) : (
                                                                <Box
                                                                    component='img'
                                                                    src={cross}
                                                                    alt='icon'
                                                                    className={resetStyles.icon}
                                                                />
                                                            )
                                                        ) : (
                                                            <Box className={resetStyles.icon}></Box>
                                                        )}{' '}
                                                        <Box className={resetStyles.policy}>
                                                            1 special character
                                                        </Box>
                                                    </li>
                                                </ul>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={4}
                                                sm={4}
                                                md={4}
                                                className='register-email-grid-confirm-email'
                                                style={{ textAlign: 'start', padding: '0px' }}
                                            >
                                                <ul className={resetStyles.list}>
                                                    <li className={resetStyles.listOptions}>
                                                        {isTyping ? (
                                                            requirments.hasNumber ? (
                                                                <Box
                                                                    component='img'
                                                                    src={tick}
                                                                    alt='icon'
                                                                    className={resetStyles.icon}
                                                                />
                                                            ) : (
                                                                <Box
                                                                    component='img'
                                                                    src={cross}
                                                                    alt='icon'
                                                                    className={resetStyles.icon}
                                                                />
                                                            )
                                                        ) : (
                                                            <Box className={resetStyles.icon}></Box>
                                                        )}{' '}
                                                        <Box className={resetStyles.policy}>
                                                            1 number
                                                        </Box>
                                                    </li>
                                                </ul>
                                            </Grid>
                                        </Grid>
                                    )}
                                </>
                            )}
                            {/* <p className='text-left' style={{ marginTop: '16px' }}>
                            Your password must be at least 8 characters long. For security reasons
                            it must include at least one capital letter (R), one lowercase letter
                            (p) and one number (7).
                        </p> */}

                            {/* <Grid item xs={12} sm={12} md={12}>
                            <div className='mobileFloat'>
                                <Button
                                    onClick={handleSubmit(onSubmit)}
                                    type='submit'
                                    className='emailBtn savePwd'
                                >
                                    Save password
                                </Button>
                            </div>
                        </Grid> */}
                        </div>
                    )}
                    <MyEirRegisterFooter
                        button1Text='Confirm'
                        button1Enabled={
                            !PasswordError &&
                            !isPasswordHistoryError &&
                            confirmBtn &&
                            !waitingApiResponse
                                ? true
                                : false
                        }
                        handleButton1Click={handleSubmit(onSubmit)}
                        button2Text='Cancel'
                        handleButton2Click={handleCancel}
                    />

                    {/* {confirmPasswordMsg && (
                    <Fragment>
                        <h1 style={{ marginTop: '30px' }}>Good news!</h1>
                        <p className='notification' style={{ textAlign: 'center' }}>
                            Password updated successfully.
                        </p>

                        <div className='forRegform'>
                            <a className='link' href='/prepay/login'>
                                <span className='arrow-right'></span>
                                Back to log in
                            </a>
                        </div>
                    </Fragment>
                )} */}
                    <MyEirAlreadyRegistered
                        toggle={toggle2}
                        isOpen={isOpen2}
                        heading={'Reset password'}
                        headingMobile={'Reset password'}
                        newsContentText={'Good news!'}
                        verifiedText={'Password updated successfully'}
                        verifiedTextMobile={'Password updated successfully'}
                        subtext={' '}
                        buttonText={'Close'}
                        footerText={''}
                    />
                    {openError ? <MyEirErrorHandle open={openError} setOpen={toggleError} /> : ''}
                </form>
            </div>
        </div>
    );
}

export default MyEirCreatePasswordFormPage;
