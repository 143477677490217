import { makeStyles } from '@material-ui/core/styles';
export const onBoardingStyles = makeStyles(theme => ({
    stepperContainer: {
        position: 'absolute',
        top: '84.375%',
        left: '50%',
        transform: 'translateX(-50%)',
        width: '100%',
        zIndex: '1',
        [theme.breakpoints.up('sm')]: {
            width: '296px',
        },
    },

    modalContainer: {
        boxShadow: `0px 3px 1px -2px ${theme.palette.primary.boxShadow2Dark}, 0px 2px 2px ${theme.palette.primary.boxShadow2Dark}, 0px 1px 5px ${theme.palette.primary.boxShadow2Dark}`,

        margin: 0,

        '& .modal-content': {
            border: 'unset !important',
            maxWidth: '480px',
            maxHeight: '580px',
            margin: '0 auto',
        },
        '& .modal': {
            display: 'flex !important',
            justifyContent: 'center',
            alignItems: 'center',
        },

        '& .modal-dialog': {
            maxWidth: 'none',
        },
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            width: '480px',
            height: '579px',
            maxWidth: 'none',
            borderRadius: '10px',
            margin: 'auto',
        },
    },
    modalBody: {
        position: 'relative',
        width: '100%',
        height: '610px',
        backgroundColor: 'transparent',
        overflow: 'hidden',
        borderRadius: '10px',
        '&:focus-visible': {
            outline: 'none',
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            height: '100%',
            borderRadius: '0px',
        },
    },

    stepperDots: {
        backgroundColor: 'transparent',
        justifyContent: 'center',
        paddingBottom: '18px',
        '& .MuiMobileStepper-dot': {
            backgroundColor: theme.palette.primary.borderColor,
        },
        '& .MuiMobileStepper-dotActive': {
            backgroundColor: theme.palette.primary.heather,
        },
    },

    stepperButton: {
        fontSize: theme.typography.body1.fontSize,
        backgroundColor: `${theme.palette.primary.sunset} !important`,
        width: '82.2%',
        maxWidth: '296px',
        margin: '0 auto',
        display: 'flex',
        alignItems: 'center',
        height: '41px',
        borderRadius: '6',
        // boxShadow: '0px 4px 45px -9px #d111c966',
        color: theme.palette.primary.white,
        fontFamily: theme.typography.body3Medium.fontFamily,
        // fontWeight: '700',
        lineHeight: '24px',
        textAlign: 'center',
        textTransform: 'none',
        [theme.breakpoints.up('sm')]: {
            width: '296px',
        },
    },
    welcomeContainer: {
        position: 'relative',
        width: '100%',
        height: '100%',
    },
    welcomeGradient: { position: 'absolute', top: '0', bottom: '0', width: '100%', height: '100%' },
    welcomeImage: {
        width: '100%',
        height: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '100%',
            height: '100%',
            borderRadius: '10px',
        },
    },
    welcomeText: {
        fontFamily: theme.typography.h5.fontFamily,
        fontStyle: 'normal',
        // fontWeight: '700',
        color: theme.palette.primary.white,
        lineHeight: '32px',
        position: 'absolute',
        top: '67.3%',
        width: '100%',
        fontSize: theme.typography.body3Medium.fontSize,
        left: '50%',
        maxWidth: 'fit-content',
        transform: 'translateX(-50%)',
    },

    otherScreenContainer: {
        background: 'white',
        height: '100%',

        [theme.breakpoints.up('sm')]: {
            maxWidth: '480px',
            height: '610px',
            borderRadius: '10px',
        },
    },

    otherScreenImageContainer: {
        maxWidth: '100%',
        maxHeight: '100%',
        '@media (min-width: 361px) and (max-width: 600px)': {
            margin: '0 auto',
        },
    },
    otherScreenTextContainer: {
        display: 'flex',

        flexDirection: 'column',
        // alignItems: 'center',
        padding: '24px 9%',
        position: 'absolute',
        background: 'white',
        borderRadius: '25px 25px 0px 0px',
        width: '100%',
        height: '100%',
        [theme.breakpoints.up('sm')]: {
            maxWidth: '480px',
            padding: '24px 32px 12px 32px',
            borderRadius: '25px 25px 10px 10px',
            height: '313px',
        },
    },
    otherScreenTitle: {
        fontFamily: theme.typography.subtitle1.fontFamily,
        fontStyle: 'normal',
        // fontWeight: '700',
        fontSize: '32px',
        lineHeight: '40px',
        letterSpacing: '0.2px',
        color: theme.palette.primary.heather,
        textAlign: 'left',
        paddingBottom: '12px',
        '@media (min-width: 0px) and (max-width: 330px)': {
            fontSize: theme.typography.h4.fontSize,
            lineHeight: '30px',
        },
    },
    otherScreenDescription: {
        fontFamily: theme.typography.body2.fontFamily,
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: theme.typography.body1.fontSize,
        lineHeight: '24px',
        color: theme.palette.primary.heather,
        textAlign: 'left',
        [theme.breakpoints.up('sm')]: {
            padding: '0px 25px 24px 0px',
        },
        '@media (min-width: 0px) and (max-width: 300px)': {
            fontSize: theme.typography.subtitle2.fontSize,
            lineHeight: '24px',
        },
    },

    imageGridContainer: {
        height: 'calc(251 / 360 * 100vw)',
        display: 'flex',
        [theme.breakpoints.up('sm')]: {
            borderRadius: '10px 10px 0px 0px',
            height: '285px',
        },
    },
    descriptionGridContainer: {
        background: 'rgb(232 221 254)',
        borderRadius: '0px 0px 10px 10px',
        flexGrow: 1,
        [theme.breakpoints.up('sm')]: {
            height: '313px',
            flexGrow: 'unset',
        },
    },

    swiperContainer: {
        width: '100%',
        height: '100%',
        [theme.breakpoints.up('sm')]: {
            maxWidth: '480px',
            height: '610px',
        },
    },

    imgText: {
        color: theme.palette.primary.heather,
        fontFamily: theme.typography.body2.fontFamily,
        fontWeight: '700',
        fontSize: theme.typography.subtitle1.fontSize,
        textAlign: 'center',
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '32px',
        },
    },

    sadText: {
        color: theme.palette.primary.heather,
        fontFamily: theme.typography.body2.fontFamily,
        fontWeight: '600',
        fontSize: theme.typography.body1.fontSize,
        textAlign: 'center',
        paddingBottom: '35px',
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            justifyContent: 'center',
        },
    },
    sadImg: {
        margin: '0 auto',
        paddingTop: '35px',
        paddingBottom: '23px',
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            width: '77px',
            paddingBottom: '30px',
            paddingTop: '30px',
            justifyContent: 'center',
        },
    },
    cancelBtn: {
        textTransform: 'none',
        fontStyle: 'normal',
        // fontWeight: '700',
        fontSize: '1rem',
        fontFamily: theme.typography.body3Medium.fontFamily,
        lineHeight: '1.5',
        padding: '.5rem 2rem',
        margin: '0 auto ',
        background: theme.palette.primary.sunset,
        width: '100%',
        maxWidth: '200px',
        borderRadius: '4100px',
        color: theme.palette.primary.white,
        '&:hover': {
            background: theme.palette.primary.sunset30,
        },
        justifyContent: 'center',
        display: 'flex',
        [theme.breakpoints.down('md', 'sm')]: {
            maxWidth: '200px',
            width: '100%',
        },
        [theme.breakpoints.only('xs')]: {
            maxWidth: '264px',
            width: '100%',
            height: '41px',
            left: '.69%',
            right: '7.69%',
        },
    },
    closeBtn: {
        marginTop: '10px',
        textTransform: 'none',
        fontStyle: 'normal',
        // fontWeight: '700',
        fontSize: '1rem',
        fontFamily: theme.typography.body3Medium.fontFamily,
        lineHeight: '1.5',
        padding: '.5rem 2rem',
        margin: '0 auto ',
        background: theme.palette.primary.sunset,
        width: '100%',
        maxWidth: '200px',
        borderRadius: '4100px',
        color: theme.palette.primary.white,
        '&:hover': {
            background: theme.palette.primary.sunset,
        },
        justifyContent: 'center',
        display: 'flex',
        [theme.breakpoints.down('md', 'sm')]: {
            maxWidth: '200px',
            width: '100%',
        },
        [theme.breakpoints.only('xs')]: {
            maxWidth: '264px',
            width: '100%',
            height: '41px',
            left: '.69%',
            right: '7.69%',
        },
    },
    imgContent: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        backgroundColor: theme.palette.primary.white,
    },
    loaderContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
    },
    dotsContainer: {
        background: 'transparent',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0px 0px 18px 0px',
    },
    stepDot: {
        '& .MuiStepIcon-root': {
            width: '8px',
            height: '8px',
            color: theme.palette.primary.gray550,
        },
        '& .MuiStepIcon-text': {
            display: 'none',
        },
        '& .MuiStepIcon-active': {
            color: theme.palette.primary.heather,
        },
        paddingLeft: '2px',
        paddingRight: '2px',
        cursor: 'pointer',
    },
}));
