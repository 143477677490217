import React, { useState, useEffect, Fragment } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button, Grid } from '@material-ui/core';
import { MY_EIR_CONST } from '../myeirconstant/myEirConstant';
import ENDPOINT from '../graphql/GraphQLConstants';
import { useApolloClient } from '@apollo/client';
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import { theme } from '../../theme/MyEirTheme';
import EirButton from '../../theme/EirButton';

const useSessionStyle = makeStyles(theme => ({
    dialogPaper: {
        maxWidth: '760px',
        alignItems: 'center',
        height: '70vh',
        overflowY: 'unset',
        maxHeight: 640,
        width: '80vw',
        justifyContent: 'space-around',
        padding: '0 32px',
        '@media (max-width: 600px)': {
            margin: 'auto 24px',
            justifyContent: 'flex-start',
            padding: '16px 15px 28px',
            maxHeight: '361px',
        },
    },

    eirDialogContainer: {
        textAlign: 'center',
    },

    eirDialogTitle: {
        // borderBottom: '1px solid #e0e0e0',
        padding: '0 0 14px',
        width: '100%',
        borderBottom: `1px solid${theme.palette.primary.borderColor}`,
        '& h2': {
            fontFamily: theme.typography.body3Medium.fontFamily,
            color: theme.palette.primary.heather,
            fontSize: 24,
            lineHeight: 2,
        },
        '@media (max-width: 600px)': {
            borderBottom: 'none',
            padding: '0 0 24px',
            '& h2': {
                fontSize: 20,
            },
        },
    },

    eirDialogContent: {
        // padding: '84px 134px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflow: 'unset',
        flex: 'unset',
        '@media (max-width: 600px)': {
            padding: 0,
        },
    },
    notification: {
        color: theme.palette.primary.heather,
        fontSize: theme.typography.body1.fontSize,
        textAlign: 'center',
        // padding: '0px',
        fontFamily: theme.typography.body3.fontFamily,
        lineHeight: '25px',
        '@media (max-width: 600px)': {
            fontSize: theme.typography.body1.fontSize,
            lineHeight: '25px',
            marginBottom: 33,
            '& p': {
                width: '80%',
                margin: '0 auto',
            },
        },
    },
    eirDialogAction: {
        padding: 0,
    },
    eirDialog: {
        display: 'flex',
        flexDirection: 'column',
        '@media (max-width: 600px)': {
            padding: 0,
        },
    },

    button: { outline: 'none' },

    eirButton: {
        fontSize: 16,
        lineHeight: 1.5,
        padding: '8px 32px 0',
        // background: '#d011c9',
        color: theme.palette.primary.sunset,
        fontFamily: theme.typography.body3Medium.fontFamily,
        textTransform: 'unset',
        '&:hover': { color: '#d011c9', background: 'white' },
    },

    purpleBtn: {
        fontSize: 16,
        lineHeight: 1.5,
        padding: '8px 32px',
        background: '#d011c9',
        color: 'white',
        fontFamily: theme.typography.body3Medium.fontFamily,
        textTransform: 'unset',
        borderRadius: 40,
        '&:hover': {
            background: '#d011c9',
            color: 'white',
        },
    },

    infoIcon: {
        backgroundImage: `url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.0006 4.15527C9.34645 4.15527 8.8252 4.67653 8.8252 5.33072C8.8252 5.9849 9.34645 6.50616 10.0006 6.50616C10.6548 6.50616 11.1761 5.9849 11.1761 5.33072C11.1761 4.67653 10.6583 4.15527 10.0006 4.15527Z' fill='white'/%3E%3Cpath d='M10.8423 7.78418H9.16309V15.8443H10.8423V7.78418Z' fill='white'/%3E%3Cpath d='M10.0018 0C4.47788 0 0 4.47787 0 10.0017C0 12.3456 0.808119 14.5041 2.16198 16.2078C2.16198 16.2078 2.58877 16.4212 2.97009 16.0329C3.35141 15.6481 3.166 15.1723 3.1695 15.1758C2.07802 13.738 1.42732 11.9433 1.42732 9.99825C1.42732 5.265 5.265 1.42732 9.99825 1.42732C14.7315 1.42732 18.5692 5.265 18.5692 9.99825C18.5692 14.7315 14.7315 18.5692 9.99825 18.5692C8.03568 18.5692 6.23054 17.9115 4.78923 16.8025C4.78923 16.8025 4.32045 16.6486 3.96362 17.0439C3.60679 17.4392 3.80619 17.852 3.80969 17.852C5.51338 19.1954 7.66136 20 9.99825 20C15.5221 20 20 15.5221 20 9.99825C20 4.47438 15.5256 0 10.0018 0Z' fill='white'/%3E%3C/svg%3E%0A")`,
        height: 84,
        width: 84,
        backgroundRepeat: `no-repeat`,
        backgroundColor: theme.palette.primary.sunset,
        backgroundPosition: 'center',
        borderRadius: 64,
        marginBottom: 39,
        '@media (max-width: 600px)': {
            height: 64,
            width: 64,
            marginBottom: 16,
        },
    },
}));

const Session = () => {
    const classes = useSessionStyle();
    const graphql = useApolloClient();
    const [showSessionWarning, setShowSessionWarning] = useState(false);
    const [timeLeftInSession, setTimeLeftInSession] = useState(0);

    const logout = () => {
        console.log('session expired');
        setShowSessionWarning(false);
        sessionStorage.clear();
        window.location.href = MY_EIR_CONST.url.REDIRECT_URL;
        // window.location.href = 'https://myeir-test01.eir.ie/';
    };

    const extendSession = async () => {
        try {
            console.log('extend session');
            const res = await graphql.query({
                query: ENDPOINT.EXTEND_SESSION,
                variables: {
                    refresh_token: sessionStorage.getItem('refresh_token'),
                },
            });
            if (res.data) {
                const refreshTokenExpiresIn = res.data.refreshToken.refresh_expires_in * 1000;
                const now = new Date().getTime();
                const sessionExpiryTime = new Date(now + refreshTokenExpiresIn).getTime();
                const myEirSession = {
                    sessionExpiryTime,
                    refreshTokenExpiresIn,
                };
                sessionStorage.setItem('myEirSession', JSON.stringify(myEirSession));
                sessionStorage.setItem('access_token', res.data.refreshToken.access_token);
                sessionStorage.setItem('refresh_token', res.data.refreshToken.refresh_token);
                setShowSessionWarning(false);
            }
        } catch (error) {
            console.error(error);
            logout();
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            const now = new Date().getTime();
            const myEirSession = JSON.parse(sessionStorage.getItem('myEirSession'));

            if (myEirSession != null && myEirSession.sessionExpiryTime != null) {
                setTimeLeftInSession(
                    Math.trunc(
                        (JSON.parse(sessionStorage.getItem('myEirSession')).sessionExpiryTime -
                            now) /
                            1000
                    )
                );

                if (myEirSession.sessionExpiryTime - now < 0) {
                    logout();
                } else if (myEirSession.sessionExpiryTime - now < 60000) {
                    setShowSessionWarning(true);
                }
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [timeLeftInSession, showSessionWarning]);

    return (
        <Fragment>
            <ThemeProvider theme={theme}>
                {true && (
                    <Dialog
                        PaperProps={{ className: classes.dialogPaper }}
                        open={showSessionWarning}
                        onClose={logout}
                        maxWidth={'xs'}
                        fullWidth={true}
                        disableBackdropClick={true}
                        aria-labelledby='alert-dialog-title'
                        aria-describedby='alert-dialog-description'
                        className={classes.eirDialogContainer}
                    >
                        <DialogTitle id='alert-dialog-title' className={classes.eirDialogTitle}>
                            Session Information
                        </DialogTitle>
                        <DialogContent className={classes.eirDialogContent}>
                            <div className={classes.infoIcon}></div>
                            <DialogContentText
                                id='alert-dialog-description'
                                className={classes.notification}
                            >
                                Your session will expire in <b>{timeLeftInSession} seconds</b>
                                <br />
                                <p>Do you want to keep your session active?</p>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions className={classes.eirDialogAction}>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                align='center'
                                className={classes.eirDialog}
                            >
                                <EirButton
                                    variants='primaryButtonMedium'
                                    style={{ padding: '0.5rem 2.5rem' }}
                                    onClick={extendSession}
                                    // className={classes.purpleBtn}
                                >
                                    Stay logged in
                                </EirButton>
                                <Button
                                    onClick={logout}
                                    className={classes.eirButton}
                                    color='primary'
                                >
                                    Log out
                                </Button>
                            </Grid>
                        </DialogActions>
                    </Dialog>
                )}
            </ThemeProvider>
        </Fragment>
    );
};

export default Session;
