import React, { useState, Fragment, useEffect } from 'react';
import { Grid, Container, Button, Paper } from '@material-ui/core';
import { useAddonStyle } from './useAddonStyle';

function MyEirRoamingAddon() {
    const classes = useAddonStyle();
    return (
        <Fragment>
            <Container>
                <Grid container spacing={2} className='profileMargin'>
                    <Grid item xs={12} sm={7}>
                        <h2>Roaming add ons</h2>
                        <p>
                            Heading abroad? Be sure to choose one of our great value roaming Add-ons
                            to ensure you get the best value on data, calls and texts.
                        </p>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <div className='mobileFloat'>
                            <img
                                className='addonImg'
                                src='/opencms/export/system/modules/my.portal.opencms/myeir_ion/images/my-eir-add-on-roaming.png'
                            ></img>
                        </div>
                    </Grid>
                </Grid>
                <Grid container spacing={2} className='profileMargin'>
                    <Grid item xs={12} sm={6}>
                        <Paper className={classes.paper + ' ' + 'boxLayout '}>
                            <p>PAYG Roaming Internet Bundle USA 30 Days</p>
                            <h3>€XX.99</h3>

                            <p className='freeText'>
                                <span className='iconLayout'>
                                    {' '}
                                    <img src='/opencms/export/system/modules/my.portal.opencms/myeir_ion/images/my-eir-text.png'></img>
                                </span>
                                Freetext <span>Data USACAN</span> to <span>50104</span>
                            </p>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Paper className={classes.paper + ' ' + 'boxLayout marginLeft'}>
                            <p>PAYG Roaming Internet Bundle ROW 30 Days</p>
                            <h3>€XX.99</h3>

                            <p className='freeText'>
                                <span className='iconLayout'>
                                    {' '}
                                    <img src='/opencms/export/system/modules/my.portal.opencms/myeir_ion/images/my-eir-text.png'></img>
                                </span>
                                Freetext<span> Data ROW</span> to <span>50104</span>
                            </p>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </Fragment>
    );
}
export default MyEirRoamingAddon;
