import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    arrow: {
        marginLeft: '8px',
        marginTop: '7px',
        width: '11px',
        height: '11px',
    },

    curve: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: '1',
    },

    modalContainer: {
        bboxShadow: `0px 3px 1px -2px ${theme.palette.primary.boxShadow2Dark}, 0px 2px 2px ${theme.palette.primary.boxShadow2Dark}, 0px 1px 5px ${theme.palette.primary.boxShadow2Dark}`,

        margin: 0,

        width: '100%',
        border: 'none',
        margin: '0 auto',
        borderRadius: '10px',

        justifyContent: 'center',
        backdropFilter: 'blur(15px)',
        display: 'flex',
        marginTop: 'auto',
        marginBottom: 'auto',
        overflowY: 'auto',
        background: 'transparent',
        height: '100%',
        alignItems: 'center',

        '& .modal': {
            display: 'flex !important',
            justifyContent: 'center',
            alignItems: 'center !important',
        },

        '& .modal-dialog': {
            maxWidth: 'none',
            alignItems: 'center !important',
        },
    },
    modalBody: {
        position: 'relative',
        width: '100%',
        maxWidth: '778px',
        backgroundColor: theme.palette.primary.white,
        overflow: 'hidden',
        borderRadius: '10px',
        height: 'auto',
        margin: '0 auto',
        '&:focus-visible': {
            outline: 'none',
        },
        [theme.breakpoints.down('xs')]: {
            maxWidth: 'calc(344 / 360 * 100vw)',
            width: '100%',
            // height: 'fit-content',
            borderRadius: '10px 10px 0 0',
            height: '100%',
            marginTop: '8px',
            overflowY: 'auto',
            marginBottom: 'auto',
        },
        '@media(min-width: 600px) and (max-width: 1019px)': {
            width: '93%',
            border: 0,
        },
    },
    bannerImage: {
        height: '180px',
        width: '100%',

        [theme.breakpoints.down('xs')]: {
            width: '100%',
            marginTop: '-2px',
        },
    },

    banner: {
        position: 'relative',
    },
    arrow: {
        position: 'absolute',
        left: 0,
        width: '16px',
        height: '16px',
        marginTop: '20px',
        marginLeft: '20px',
        cursor: 'pointer',
        [theme.breakpoints.down('xs')]: {
            width: '13px',
            height: '13px',
            marginTop: '12px',
            marginLeft: '12px',
        },
    },
    heading: {
        color: theme.palette.primary.heather,
        fontFamily: theme.typography.h6.fontFamily,
        textAlign: 'center',
        fontSize: '28px',
        fontStyle: 'normal',
        // fontWeight: 700,
        lineHeight: '36px',
        padding: '30px 0px 40px 0px',
        [theme.breakpoints.down('xs')]: {
            fontSize: '23px',
            lineHeight: '31px',
            padding: '22px 40px 32px 40px',
        },
    },
    selectionContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: '40px',
        zIndex: 1,
        position: 'relative',

        '& .MuiOutlinedInput-root.Mui-focused': {
            borderColor: theme.palette.primary.heather,
        },
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            paddingBottom: '32px',
        },
    },
    image: {
        position: 'absolute',
        top: '50%',
    },
    selectComponent: {
        display: 'flex',
        width: '400px',
        height: '40px',
        padding: ' 0px 12px !important',
        flexDirection: ' column',
        justifyContent: 'center',
        flexShrink: 0,
        borderRadius: '100px',
        border: `1px solid ${theme.palette.primary.gray550}`,

        ' & .MuiSelect-icon': {
            color: theme.palette.primary.sunset,
            left: '90%',
        },
        '& .MuiSelect-select': {
            paddingRight: '0px !important',
            color: theme.palette.primary.heather,
            fontFamily: theme.typography.body3Medium.fontFamily,
            fontWeight: 400,
        },
        ' & .MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },
        '& .MuiSelect-select:focus': {
            backgroundColor: 'transparent',
            ' & .MuiOutlinedInput-notchedOutline': {
                border: `1px solid ${theme.palette.primary.borderColor}`,
            },
        },

        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            width: '100%',
        },
    },

    formControl: {
        paddingRight: '24px',

        [theme.breakpoints.down('xs')]: {
            width: '100%',
            paddingLeft: '24px',
            paddingRight: '24px',
        },
    },
    confirmButton: {
        borderRadius: '4100px',
        background: theme.palette.primary.sunset,
        boboxShadow: `0px 3px 1px -2px ${theme.palette.primary.boxShadow2Dark}, 0px 2px 2px ${theme.palette.primary.boxShadow2Dark}, 0px 1px 5px ${theme.palette.primary.boxShadow2Dark}`,
        fontFamily: theme.typography.h6.fontFamily,
        fontSize: theme.typography.body1.fontSize,
        fontStyle: 'normal',
        // fontWeight: 700,
        lineHeight: '24px',
        color: theme.palette.primary.white,
        textTransform: 'none',
        width: 'fit-content',
        height: '40px',
        cursor: 'pointer',
        '&:hover': {
            background: theme.palette.primary.sunset,
        },
        '&.Mui-disabled': {
            background: `${theme.palette.primary.borderColor} !important`,
            color: `${theme.palette.primary.gray550} !important`,
        },
        [theme.breakpoints.down('xs')]: {
            position: 'absolute',
            top: '52vh',
            width: '82.2%',
            margin: '0 auto',
        },
    },
    defaultMenuItemText: {
        color: '#3234358A',
        fontFamily: theme.typography.body3Medium.fontFamily,
        fontSize: theme.typography.body1.fontSize,
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '24px',
        paddingLeft: '6px',
    },
    menuItems: {
        color: theme.palette.primary.heather,
        fontFamily: theme.typography.body3Medium.fontFamily,
        fontSize: theme.typography.body1.fontSize,
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '24px',
        padding: '8px 8px',
        gap: '10px',
        alignSelf: 'stretch',
        '&:hover': {
            background: '#FBECFB !important',
        },
    },
}));

export default useStyles;
