import { gql } from '@apollo/client';

const ENDPOINT = {
    GET_SUBSCRIPTION_ID_AND_CONTACT: gql`
        query getMySubscriptionsAndContactDetails($token: String!) {
            getMySubscriptions(token: $token) {
                content {
                    id
                    accountId
                    type
                    catalogOfferCode
                    serviceGroup
                    dependsOn
                    terminatedAt
                }
            }
            getMyContactDetails(token: $token) {
                #createdAt
                #idLastCheckedAt
                #gdprValidatedAt
                #gdprLanguage
                #communicationPreference
                #allowThirdParties
                uuid
                #identityDocuments
                addresses {
                    addressLine1
                    addressLine2
                    addressLine3
                    town
                    county
                    eircode
                    code
                    type
                }
                #title
                firstName
                lastName
                #birthDate
                phoneNumbers {
                    phoneNumber
                    type
                    id
                }
                emails {
                    email
                }
            }
        }
    `,
    GET_AUTHORISED_USER: gql`
        query getMyAuthorizedUser($token: String!, $accountId: String!) {
            getMyAuthorizedUsers(token: $token, accountId: $accountId) {
                firstName
                lastName
            }
        }
    `,
    GET_MY_PAYMENT_METHOD_DETAILS: gql`
        query getPaymentMethod($token: String!, $account_id: String!) {
            getPaymentMethod(token: $token, account_id: $account_id) {
                accountId
                brand
                payerContactUuid
                payerRef
                paymentMethods {
                    canceled
                    canceledAt
                    default
                    expirationDate
                    partialDigits
                    paymentMethodId
                    paymentMethodType
                    status
                    providerReference
                    type
                    iban
                }
            }
        }
    `,

    GET_MY_CONTRACTS: gql`
        query getContracts($token: String!, $subscriptionId: Int!) {
            getMyServiceContract(token: $token, subscriptionId: $subscriptionId) {
                id
                startAt
                endedAt
            }
        }
    `,
};
export const getMySubscriptionsAndContactDetailsApi = async ({ graphql, token }) => {
    try {
        const response = await graphql.query({
            query: ENDPOINT.GET_SUBSCRIPTION_ID_AND_CONTACT,
            variables: {
                token: token,
            },
        });

        return response;
    } catch (error) {
        throw new Error(error);
    }
};
export const getMyAuthorizedUserApi = async ({ graphql, token, accountId }) => {
    try {
        const response = await graphql.query({
            query: ENDPOINT.GET_AUTHORISED_USER,
            variables: {
                token: token,
                accountId: accountId,
            },
        });
        return response;
    } catch (error) {
        throw new Error(error);
    }
};
export const getPaymentMethodApi = async ({ graphql }) => {
    try {
        const response = await graphql.query({
            query: ENDPOINT.GET_MY_PAYMENT_METHOD_DETAILS,
            variables: {
                token: sessionStorage.getItem('access_token'),
                account_id: sessionStorage.getItem('LoggedinUserAccountID'),
            },
        });
        return response;
    } catch (error) {
        throw new Error(error);
    }
};
export const getContractsApi = async ({ graphql, subscriptionId }) => {
    try {
        const response = await graphql.query({
            query: ENDPOINT.GET_MY_CONTRACTS,
            variables: {
                token: sessionStorage.getItem('access_token'),
                subscriptionId: parseInt(subscriptionId),
            },
        });
        return response;
    } catch (error) {
        throw new Error(error);
    }
};
