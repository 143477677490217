import React from 'react';
import { Grid, Skeleton, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import styles from '../eir-channel-modal/styles';
/**
 * @param {string} title - Title is string parameter.
 * @param {string|Array[string]} description - Description can be a string for one paragraph. To get multiple paragraph use Array of strings.
 * @param {object} style - style title, description
 * @param {string} textAlign - To align both title and description(s). By default 'center'.  (left | right | center* | justify)
 */
const EirInformation = ({
    title = 'Title',
    description = '',
    textAlign = 'center',
    loading,
    style,
    subtitle = '',
    isMbb,
}) => {
    const theme = useTheme();
    const classes = styles();
    return (
        <Grid
            item
            container
            spacing={1}
            justifyContent='center'
            style={{ width: '100%', margin: 0, alignContent: 'center' }}
        >
            <Grid item xs={12} sm={12} style={{ padding: 0 }}>
                {loading ? (
                    <Skeleton
                        height={40}
                        width={220}
                        style={textAlign ? { m: '0 auto 0rem', bgcolor: 'gray' } : {}}
                    />
                ) : (
                    <Typography textAlign={textAlign} className={classes.title}>
                        {title}
                        {subtitle && (
                            <span
                                style={{ fontFamily: theme.typography.body2.fontFamily }}
                            >{` ${subtitle}`}</span>
                        )}
                    </Typography>
                )}
            </Grid>
            <Grid item xs={12} sm={12} style={{ padding: 0 }}>
                {loading ? (
                    <>
                        <Skeleton
                            height={16}
                            width='80%'
                            style={textAlign ? { m: '0 auto' } : {}}
                        />
                        <Skeleton
                            height={16}
                            width='60%'
                            style={textAlign ? { m: '0 auto' } : {}}
                        />
                    </>
                ) : (
                    <>
                        {typeof description === 'string' && <Typography>{description}</Typography>}
                        {description === 'object' &&
                            description.map(text => <Typography>{text}</Typography>)}
                    </>
                )}
            </Grid>
        </Grid>
    );
};
EirInformation.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    textAlign: PropTypes.oneOf(['center', 'left', 'right', 'justify']),
};
EirInformation.defaultProps = {
    textAlign: 'center',
    description: '',
};

export default EirInformation;
