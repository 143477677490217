import { gql } from '@apollo/client';

const ENDPOINT = {
    GET_USAGE_DETAILS: gql`
        query getMyUsageDetails($token: String!, $usageDetailsInput: UsageDetailsInput!) {
            getMyUsageDetails(token: $token, usageDetailsInput: $usageDetailsInput) {
                usage {
                    pageable {
                        pageNumber
                        pageSize
                    }
                    first
                    last
                    number
                    numberOfElements
                    size
                    totalPages
                    totalElements
                    sort {
                        sorted
                    }
                    content {
                        chargedUsageId
                        amount
                        chargedNumber
                        chargeStartDateTime
                        destinationCountryCode
                        duration
                        invoiceText
                        quantity
                        destinationNumber
                        measuringUnit
                    }
                }
            }
        }
    `,
    GET_USAGE_SUMMARY: gql`
        query getMyUsageSummary(
            $token: String!
            $billingPeriod: String!
            $serviceType: String!
            $serviceId: Int!
        ) {
            getMyUsageSummary(
                token: $token
                billingPeriod: $billingPeriod
                serviceType: $serviceType
                serviceId: $serviceId
            ) {
                serviceId
                usageSummary {
                    amount
                    duration
                    eventCount
                    measuringUnit
                    quantity
                    ratingSubtotalId
                    ratingSubtotalTypeId
                    ratingSubtotalTypeKey
                    ratingSubtotalTypeLevel
                    ratingSubtotalTypeDisplayName
                    ratingSubtotalTypeId
                }
            }
        }
    `,
};

/**
 * @name getUsageDetailsApi - calls usage details api and returns response
 * @param {Object} Obj - attributes for the function
 * @param {GraphQL} Obj.graphql - appoloclient graphql constant
 * @param {String} Obj.token - user access token
 * @param {Object} Obj.usageDetailsInput - usageDetailsInput object
 * @param {String} Obj.usageDetailsInput.serviceId - service id for which usage details is being requested
 * @param {String} Obj.usageDetailsInput.serviceType - service type for which usage details is being requested
 * @param {String} Obj.usageDetailsInput.billingPeriod - billing period for which usage details is being requested
 * @param {String} Obj.usageDetailsInput.usageType - usage type for which usage details is being requested
 * @param {String} Obj.usageDetailsInput.pageNumber - page number of the response
 * @param {String} Obj.usageDetailsInput.size - page size of the response
 * @returns
 */
export const getUsageDetailsApi = async ({ graphql, token, usageDetailsInput }) => {
    try {
        const data = await graphql.query({
            query: ENDPOINT.GET_USAGE_DETAILS,
            variables: {
                token,
                usageDetailsInput,
            },
        });
        return data;
    } catch (error) {
        throw new Error(error);
    }
};

/**
 * @name getUsageSummaryApi - calls usage summary api and returns response
 * @param {Object} Obj - attributes for the function
 * @param {GraphQL} Obj.graphql - appoloclient graphql constant
 * @param {String} Obj.token - user access token
 * @param {String} Obj.billingPeriod - billing period for which usage summary is being requested
 * @param {String} Obj.serviceType - service type for which usage summary is being requested
 * @param {String} Obj.serviceId - service id for which usage summary is being requested
 * @returns
 */
export const getUsageSummaryApi = async ({
    graphql,
    token,
    billingPeriod,
    serviceType,
    serviceId,
}) => {
    try {
        const data = await graphql.query({
            query: ENDPOINT.GET_USAGE_SUMMARY,
            variables: {
                token,
                billingPeriod,
                serviceType,
                serviceId,
            },
        });
        return data;
    } catch (error) {
        throw new Error(error);
    }
};
