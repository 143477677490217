import { makeStyles } from '@material-ui/core/styles';
export const myBalanceStyles = makeStyles(theme => ({
    container: {
        margin: 'auto',
        maxWidth: '1076px',
        marginBottom: '60px',
        position: 'relative',
        // marginTop: '80px',
        marginTop: '16px',

        '@media (max-width:599px)': {
            width: '100%',
            padding: ' 0 24px',
            marginBottom: '38px',
            marginTop: '13px',
        },

        '@media (min-width: 600px) and (max-width:1019px)': {
            maxWidth: '694px',
            paddingLeft: '30px',
            paddingRight: '30px',
            marginBottom: '51px',
            marginTop: 'unset',
        },

        '@media (min-width: 1020px) and (max-width:1199px)': {
            maxWidth: '1076px',
            margin: 'auto',
            marginTop: '40px',
            paddingLeft: '30px',
            marginBottom: '40px',
            paddingRight: '30px',
        },
    },
    mainContainer: {
        position: 'relative',
        backgroundColor: 'white',
        borderRadius: '13px',
        // overflow: 'hidden',
        boxShadow: `0px 3px 1px -2px ${theme.palette.primary.boxShadow2Dark}, 0px 2px 2px ${theme.palette.primary.boxShadow2Dark}, 0px 1px 5px ${theme.palette.primary.boxShadow2Dark}`,
        '@media (min-width: 1200px) ': {
            maxWidth: '1076px',
            margin: 'auto',
        },
        '@media (min-width: 600px) and (max-width:1019px)': {
            overflow: 'inherit',
        },
    },

    balanceBox: {
        maxHeight: '384px',
        '@media (max-width:599px)': {
            maxHeight: 'fit-content',
            height: '100%',
        },
        '@media (min-width: 600px) and (max-width:1019px)': {
            maxWidth: '100%',
            maxHeight: 'fit-content',
            height: '100%',
        },
    },

    billBox: {
        display: 'flex',
        flexDirection: 'row',
        flexBasis: '50%',
        '@media (max-width:599px)': {
            flexBasis: '100%',
        },
        '@media (min-width: 600px) and (max-width:1019px)': {
            flexBasis: 'auto',
            width: '100%',
            boxShadow: `0px 3px 1px -2px ${theme.palette.primary.boxShadow2Dark}, 0px 2px 2px ${theme.palette.primary.boxShadow2Dark}, 0px 1px 5px ${theme.palette.primary.boxShadow2Dark}`,
            borderRadius: '0 0 10px 10px',
        },
    },

    billInnerBox: {
        flexGrow: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignContent: 'space-between',
    },

    billInnerGrid: {
        flexGrow: '1',
    },

    buttonContainer: {
        flexBasis: '0',
        minHeight: '90px',
        width: '100%',
        flexGrow: 1,
        '@media(max-width: 1019px)': { minHeight: '40px' },
        [theme.breakpoints.down('xs')]: { minHeight: '45px' },
    },
    mainBillContainer: {
        margin: '0 auto',
        margin: '40px 48.5px 0px 50.5px',

        [theme.breakpoints.down('xs')]: {
            padding: '0',
            margin: '24px 15px 0px 16px',
        },
        '@media (min-width: 600px) and (max-width:1019px)': {
            padding: '29px 24px 0px 29px',
            margin: '0 auto',
            maxWidth: '406px',
        },
    },
    gridContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // padding: '0 16px',
        [theme.breakpoints.down('xs')]: {
            padding: '0',
        },
    },
    gridContent: {
        display: 'flex',
        justifyContent: 'space-between',
    },

    itemHeading: {
        fontFamily: theme.typography.h6.fontFamily,
        // fontWeight: '700',
        fontSize: theme.typography.h6.fontSize,
        lineHeight: '157%',
        color: theme.palette.primary.heather,
        marginBottom: '24px',
        '@media (min-width: 600px) and (max-width:1019px)': {
            fontSize: theme.typography.subtitle2.fontSize,
            lineHeight: '22px',
            marginBottom: '8px',
        },

        [theme.breakpoints.down('xs')]: {
            fontSize: theme.typography.subtitle2.fontSize,
            lineHeight: '22px',
            marginBottom: '8px',
        },
    },
    itemTitle: {
        fontFamily: theme.typography.body2.fontFamily,
        fontWeight: '400',
        fontSize: theme.typography.body1.fontSize,
        lineHeight: '24px',
        color: theme.palette.primary.heather,
        marginBottom: '8px',
        '@media (min-width: 600px) and (max-width:1019px)': {
            fontSize: theme.typography.subtitle2.fontSize,
            lineHeight: '22px',
            color: theme.palette.primary.heather,
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: theme.typography.subtitle2.fontSize,
            lineHeight: '22px',
            color: theme.palette.primary.heather,
        },
    },
    pdfImg: {
        fontSize: '20px',
        marginLeft: '2px',
        cursor: 'pointer',
        [theme.breakpoints.down('xs')]: {
            fontSize: theme.typography.body1.fontSize,
        },
    },
    itemValue: {
        fontFamily: theme.typography.body2.fontFamily,
        fontWeight: '400',
        fontSize: theme.typography.body1.fontSize,
        lineHeight: '24px',
        color: theme.palette.primary.heather,
        marginBottom: '8px',
        '@media (min-width: 600px) and (max-width:1019px)': {
            fontSize: theme.typography.subtitle2.fontSize,
            lineHeight: '22px',
            color: theme.palette.primary.heather,
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: theme.typography.subtitle2.fontSize,
            lineHeight: '22px',
            color: theme.palette.primary.heather,
        },
    },

    itemBold: {
        fontFamily: theme.typography.subtitle1.fontFamily,
        // fontWeight: '700',
        fontSize: theme.typography.body1.fontSize,
        lineHeight: '157%',
        color: theme.palette.primary.heather,
        marginBottom: '8px',
        '@media (min-width: 600px) and (max-width:1019px)': {
            fontSize: theme.typography.subtitle2.fontSize,
            lineHeight: '22px',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: theme.typography.subtitle2.fontSize,
            lineHeight: '22px',
        },
    },
    item: {
        display: 'flex',
        marginTop: '12px',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            marginTop: '8px',
        },
    },

    itemPdf: {
        fontFamily: theme.typography.subtitle1.fontFamily,
        // fontWeight: '700',
        fontSize: '12.5px',
        lineHeight: '15px',
        color: theme.palette.primary.heather,
        '@media (min-width: 600px) and (max-width:1019px)': {
            fontSize: theme.typography.tooltip.fontSize,
            lineHeight: '12px',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: theme.typography.tooltip.fontSize,
            lineHeight: '12px',
        },
    },
    itemLinks: {
        fontFamily: theme.typography.body2.fontFamily,
        fontWeight: '400',
        fontSize: theme.typography.subtitle2.fontSize,
        lineHeight: '22px',
        color: theme.palette.primary.sunset,
        textDecoration: 'underline',
        marginTop: '12px',
        '@media (min-width: 600px) and (max-width:1019px)': {
            fontSize: theme.typography.inputLabel.fontSize,
            lineHeight: '20px',
            marginTop: '8px',
        },
        cursor: 'pointer',
        [theme.breakpoints.down('xs')]: {
            fontSize: theme.typography.inputLabel.fontSize,
            lineHeight: '20px',
            marginTop: '8px',
        },
    },
    //  --------------------- Balance Text Styles ------------------------------

    balanceTextContainer: {},
    balanceTextImageOne: {
        width: '167.71px',
        height: '100.67px',
        transform: 'rotate(6.24deg)',
    },
    balanceTextImageTwo: {
        width: '7.53px',
        height: '86.69px',
        transform: 'rotate(6deg)',
    },
    balanceTextImageThree: {
        width: '49.97px',
        height: '55.68px',
        marginTop: '15px',
        transform: 'rotate(8deg)',
    },

    textContainer: {
        width: '141.45',
    },

    text: {
        borderBottom: '1px solid rgba(209,17,201,0.1)',
        borderLeft: '1px solid rgba(209,17,201,0.1)',
        borderRight: '1px solid rgba(209,17,201,0.1)',
        '@media (min-width: 600px) and (max-width:1019px)': {
            padding: '5px 0',
            fontSize: theme.typography.inputLabel.fontSize,
            fontFamily: theme.typography.body3Medium.fontFamily,
            lineHeight: '20px',
            color: theme.palette.primary.heather,
            fontWeight: '400',
            margin: '0 auto',
            textAlign: 'center',
            transform: 'rotate(0deg)',
            borderRadius: '0px 0px 25px 25px',
            background: theme.palette.primary.backgroundColor2,
            boxShadow: '0px 0px 14px rgb(0 0 0 / 7%), 0px 0px 18px rgb(0 0 0 / 12%)',
        },
        [theme.breakpoints.down('xs')]: {
            marginBottom: '28px',
            padding: '6px 33px 2px 33px;',
            fontSize: theme.typography.inputLabel.fontSize,
            fontFamily: theme.typography.body3Medium.fontFamily,
            lineHeight: '20px',
            color: theme.palette.primary.heather,
            fontWeight: '400',
            margin: '0 auto',
            textAlign: 'center',
            transform: 'rotate(0deg)',
            borderRadius: '0px 0px 25px 25px',
            background: theme.palette.primary.backgroundColor2,
            boxShadow: `0px 3px 1px -2px ${theme.palette.primary.boxShadow2Dark}, 0px 2px 2px ${theme.palette.primary.boxShadow2Dark}, 0px 1px 5px ${theme.palette.primary.boxShadow2Dark}`,
        },
    },

    paymentDueText: {
        fontFamily: theme.typography.subtitle1.fontFamily,
        fontSize: theme.typography.subtitle2.fontSize,
        '@media (min-width: 0px) and (max-width:1019px)': {
            fontSize: theme.typography.inputLabel.fontSize,
            paddingLeft: '5px',
        },
    },
    myEirBalanceTextContainer: {
        position: 'absolute',
        top: '6px',
        right: '51%',
    },
    myEirFourDigit: {
        position: 'absolute',
        top: '-29px',
        right: '49%',
    },

    texts: {
        position: 'absolute',
        top: '47%',
        left: '58%',
        transform: 'translate(-50%,-50%) rotate(355deg)',
        lineHeight: '22px',
        fontSize: theme.typography.subtitle2.fontSize,
        textAlign: 'center',
        color: 'white',
        height: '25%',
        width: '53%',
        fontFamily: theme.typography.body2.fontFamily,
    },

    // styles for MyEirBalanceButtons

    balanceButtonsContainer: {
        position: 'relative',
        padding: '0px 6.5px',
        marginTop: '40px',
        '@media(max-width: 599px)': { marginTop: '24px' },
        '@media(max-width: 1019px)': { padding: '0px' },
        '@media (min-width: 600px) and (max-width:1019px)': {
            marginTop: '24px',
        },
    },
    balanceButtonsContainerBills: {
        marginTop: '9px',
        marginLeft: '7px',
        marginRight: '7px',
        [theme.breakpoints.down('sm')]: {
            marginLeft: '0px',
            marginRight: '0px',
        },
        '@media(max-width: 1019px)': {
            marginLeft: '0px',
            marginRight: '0px',
            position: 'relative',
        },
        '@media (min-width: 600px) and (max-width:1019px)': {
            marginLeft: '0px',
            marginRight: '0px',
        },
    },
    balanceButtonsGridItem: {
        cursor: 'pointer',
        padding: '0px 4px',
        '@media(max-width: 1019px)': { padding: '0px' },
    },
    balanceButtonsGridItemBox: {
        background: theme.palette.primary.heather,
        mixBlendMode: 'normal',
        borderRadius: '10px 10px 0px 0px',
        minHeight: '90px',
        padding: '16px 0px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        '@media (min-width: 0px) and (max-width:300px)': {
            minHeight: '62px',
        },
        '@media (min-width: 301px) and (max-width:1019px)': {
            background: theme.palette.primary.heather,
            // borderRadius: '0px 0px 10px 0px',
            minHeight: '40px',
            padding: '9px 0px',
            flexDirection: 'row',
            justifyContent: 'center',
            columnGap: '8px',
        },
    },
    balanceButtonsGridItemIcon: {
        width: '24px',
        height: '24px',
        '@media(max-width: 1019px)': {
            width: '17.17px',
            height: '16px',
        },
    },
    balanceButtonsGridItemText: {
        fontFamily: theme.typography.h6.fontFamily,
        fontStyle: 'normal',
        // fontWeight: 700,
        fontSize: theme.typography.subtitle2.fontSize,
        lineHeight: '24px',
        color: theme.palette.primary.backgroundColor2,
        '@media(max-width: 1019px)': {
            lineHeight: '22px',
        },
        [theme.breakpoints.down('xs')]: {
            lineHeight: '22px',
            fontWeight: 400,
        },
    },
    balanceButtonsSeperatorLine: {
        '@media(max-width: 1019px)': {
            position: 'absolute',
            background: theme.palette.primary.gray250,
            height: '34px',
            width: '1px',
            bottom: '0',
            right: '50%',
            top: '50%',
            transform: 'translate(-50%,-50%)',
        },
    },
}));
