import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    MainContainer: {
        maxWidth: '1076px',
        marginBottom: '60px',

        '@media (min-width: 1020px) and (max-width:1199px)': {
            maxWidth: '1076px',
            margin: 'auto',
            paddingLeft: '30px',
            paddingRight: '30px',
        },

        '@media (min-width: 600px) and (max-width:1019px)': {
            maxWidth: '694px',
            paddingLeft: '30px',
            paddingRight: '30px',
        },

        [theme.breakpoints.down('sm')]: {
            width: '100%',
            padding: '0px 24px',
            marginBottom: '44px',
        },
    },
    iconHeading: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '16px',
        marginTop: '60px',
        [theme.breakpoints.down('xs')]: {
            marginTop: '40px',
            marginBottom: '8px',
        },
    },
    plus: {
        paddingTop: '4px',
    },
    heading: {
        fontFamily: theme.typography.subtitle1.fontFamily,
        fontStyle: 'normal',
        // fontWeight: '700',
        fontSize: theme.typography.body1.fontSize,
        lineHeight: '24px',
        color: theme.palette.primary.heather,
        display: 'flex',
        alignItems: 'center',
        letterSpacing: '0.249px',
        paddingLeft: '5px',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
            fontWeight: '400',
        },
        '@media (min-width: 600px) and (max-width: 1019px)': {
            justifyContent: 'flex-start',
        },
    },
    containerMain: {
        [theme.breakpoints.down('xs')]: {
            '& .MuiGrid-root': {
                '&.MuiGrid-item': {
                    paddingTop: '8px',
                    paddingBottom: '8px',
                    padding: '0',
                },
            },
        },
    },
    mainCard: {
        maxHeight: '291.95px',
        borderRadius: '10px',
        textAlign: 'left',
        background: 'white',
        boxShadow: 'none',
        padding: '10px 15px',
        position: 'relative',
        marginBottom: '48.5px',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        filter:
            'drop-shadow(0px 5.97857px 5.97857px rgba(0, 0, 0, 0.1)) drop-shadow(0px 3.98571px 13.95px rgba(0, 0, 0, 0.07)) drop-shadow(0px 3.98571px 17.9357px rgba(0, 0, 0, 0.12))',
        [theme.breakpoints.down('sm')]: {
            maxHeight: '100%',
            marginBottom: '4px',
            maxWidth: 'calc(312 / 351 * 100vw)',
            marginLeft: '11px',
        },

        '@media (min-width: 600px) and (max-width:1019px)': {
            marginLeft: '-1px',
        },
        '&.MuiCard-root': {
            overflow: 'unset !important',
        },
    },
    top: {
        display: 'flex',
        justifyContent: 'space-between',
        // marginBottom: '35px',
    },
    left: {
        width: '90%',
        // marginRight: '28px',
        maxWidth: '259px',
        [theme.breakpoints.down('sm')]: {
            width: '79%',
        },
        [theme.breakpoints.between('md', 'lg')]: {
            width: '79%',
        },
        [theme.breakpoints.up('lg')]: {
            width: '67%',
        },
    },
    alignLeft: {
        width: '70%',
        marginBottom:'8px',
    },
    title: {
        fontFamily: theme.typography.subtitle1.fontFamily,
        // fontWeight: '700',
        maxHeight: '100px',
        lineHeight: '157%',
        color: theme.palette.primary.heather,
        fontSize: theme.typography.body1.fontSize,
        [theme.breakpoints.down('xs')]: {
            fontWeight: '400',
        },
    },
    titleMax: {
        fontFamily: theme.typography.subtitle1.fontFamily,
        // fontWeight: '700',
        maxHeight: '100px',
        lineHeight: '157%',
        color: theme.palette.primary.heather,
        fontSize: theme.typography.body1.fontSize,
        // marginBottom: '12px',
        [theme.breakpoints.down('xs')]: {
            fontWeight: '400',
        },
    },
    subTitle: {
        fontFamily: theme.typography.body2.fontFamily,
        fontWeight: '400',
        lineHeight: '157%',
        color: theme.palette.primary.heather,
        fontSize: theme.typography.body1.fontSize,
        width: '100%',
    },
    month: {
        fontFamily: theme.typography.body2.fontFamily,
        fontWeight: '600',
        lineHeight: '15%',
        // position: 'absolute',
        // bottom: '-10px',
        // right: '5px',
        color: theme.palette.primary.heather,
        fontSize: theme.typography.inputLabel.fontSize,
        marginLeft: '3px',
        [theme.breakpoints.down('md')]: {
            // marginLeft: '-5px',
            // bottom: '0',
            // right: '0',
        },
    },
    link: {
        fontFamily: theme.typography.body2.fontFamily,
        fontWeight: '400',
        lineHeight: '157%',
        color: theme.palette.primary.sunset,
        fontSize: theme.typography.inputLabel.fontSize,
        textDecoration: 'underline',
        cursor: 'pointer',
    },
    deleteContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    imgSad: {
        marginTop: '50px',
        marginBottom: '18px',
        [theme.breakpoints.only('xs')]: {
            marginTop: '54px',
            marginBottom: '18px',
        },
    },
    deleteText: {
        fontSize: theme.typography.h6.fontSize,
        lineHeight: '26px',
        fontFamily: theme.typography.body3Medium.fontFamily,
        // fontWeight: '700',
        color: theme.palette.primary.heather,
        marginBottom: '56px',
        width: '380px',
        textAlign: 'center',
        [theme.breakpoints.only('xs')]: {
            fontSize: theme.typography.h6.fontSize,
            width: '272px',
        },
    },
    successPopup: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },

    successImg: {
        marginTop: '27px',
        marginBottom: '43px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '19px',
            marginTop: '67px',
        },
    },

    imgTextAdditional: {
        fontSize: theme.typography.body1.fontSize,
        lineHeight: '157%',
        fontFamily: theme.typography.body3Medium.fontFamily,
        fontWeight: '600',
        color: theme.palette.primary.heather,
        marginBottom: '48px',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            marginRight: '30px',
            marginLeft: '30px',
            marginBottom: '45px',
        },
    },
    right: {
        display: 'flex',
        alignItems: 'flex-end',
        flexDirection: 'row',
    },
    rightRemove: {
        display: 'flex',
        // flexDirection: 'column',
        flexGrow: '1',
        alignItems: 'baseline',
        // position: 'relative',
        height: '25px',
        marginLeft: '5px',

        [theme.breakpoints.down('md')]: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: '0',
            alignItems: 'flex-start',
            // position: 'relative',
            height: '37px',
        },
    },
    alignHorizontal: {
        display: 'flex',
        flexDirection: 'row',
        flexGrow: '1',
        alignItems: 'baseline',
        [theme.breakpoints.down('md')]: {
            height: '37px',
            flexGrow: '0',
        },
    },
    removeAddon: {
        // bottom: '0',
        // position: 'absolute',
        // marginBottom: '16px',
        marginTop: 'auto',
    },
    hyphen: {
        marginLeft: '5px',
        marginRight: '5px',
    },
    modalContainer: {
        boxShadow: `0px 3px 1px -2px ${theme.palette.primary.boxShadow2Dark}, 0px 2px 2px ${theme.palette.primary.boxShadow2Dark}, 0px 1px 5px ${theme.palette.primary.boxShadow2Dark}`,

        margin: 0,

        width: '100%',
        border: 'none',
        margin: '0 auto',
        borderRadius: '10px',

        justifyContent: 'center',
        backdropFilter: 'blur(15px)',
        display: 'flex',
        marginTop: 'auto',
        marginBottom: 'auto',
        overflowY: 'auto',
        background: 'rgba(0, 0, 0, 0.5)',
        height: '100%',
        alignItems: 'baseline',

        '& [aria-hidden="true"]': {
            background: 'transparent !important',
        },
        '& .modal': {
            display: 'flex !important',
            justifyContent: 'center',
            alignItems: 'center !important',
        },

        '& .modal-dialog': {
            maxWidth: 'none',
            alignItems: 'center !important',
        },
        [theme.breakpoints.down('xs')]: {
            alignItems: 'center',
        },
    },
    modalBody: {
        position: 'relative',
        top: '15%',
        width: '100%',
        maxWidth: '778px',
        backgroundColor: theme.palette.primary.white,
        overflow: 'hidden',
        borderRadius: '10px',
        margin: '0 auto 15% auto',
        '&:focus-visible': {
            outline: 'none',
        },
        [theme.breakpoints.down('xs')]: {
            maxWidth: 'calc(344 / 360 * 100vw)',
            top: 'unset',
            width: '100%',
            height: '100%',
            borderRadius: '10px',
            marginTop: '8px',
            overflowY: 'auto',
            marginBottom: 'auto',
        },
        '@media(min-width: 600px) and (max-width: 1019px)': {
            width: '93%',
            border: 0,
        },
    },
    ctaContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '2rem 0 4.4rem 0',
        cursor: 'pointer',
        [theme.breakpoints.down('xs')]: {
            margin: '1rem auto',
            justifyContent: 'center',
        },
    },
    successTitle: {
        padding: '3.5rem 6.25rem 1.5rem 6.25rem',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            padding: '3.5rem 3.75rem 1.5rem 3.75rem',
        },
    },
    successSubTitle: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            padding: '1.25rem',
        },
    },
    bannerImage: {
        height: '180px',
        width: '100%',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            marginTop: '-2px',
        },
    },
    banner: {
        position: 'relative',
    },
    addonStatus: {
        borderRadius: '1.25rem',
        color: theme.palette.primary.white,
        padding: '3px 4px',
        width: 'fit-content',
        marginBottom: '32px',
        fontSize:'10px',
        height:'16px',
        width:'120px',
        textAlign:'center',
        justifyContent:'center',
        alignItems:'center',
        display:'flex',
    },
    activationStatus: {
        background: theme.palette.primary.heather,
    },
    terminationStatus: {
        background: theme.palette.primary.sunset,
    },
}));

export default useStyles;
