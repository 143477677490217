import React, { useState, Fragment, useEffect } from 'react';
import { useRegisterFormStyle } from './useRegisterFormStyle';
import errormesages from './errormessages';
// import { ErrorMessage } from '@hookform/error-message';
import { Controller, useForm, ErrorMessage } from 'react-hook-form';
import {
    Grid,
    TextField,
    Container,
    Button,
    Icon,
    Paper,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core';
import SmartphoneOutlinedIcon from '@material-ui/icons/SmartphoneOutlined';
import { useApolloClient } from '@apollo/client';
import ENDPOINT from '../graphql/GraphQLConstants';
import Loading from '../shared/Loading';
function MyEirRegisterMobileNumber(props) {
    const graphql = useApolloClient();
    const defaultValue = '';
    const { validation } = errormesages;
    const [mobileNumber, setMobileNumber] = useState('');
    const [loading, setLoading] = useState(false);
    const [verificatinCode, setverificatinCode] = useState('');
    const [showVerificationCode, setShowVerificationCode] = useState(false);
    const [apiError, setApiError] = useState('');
    const [validateCodeBtn, setvalidateCodeBtn] = useState(true);
    const [uuid, setuuid] = useState('');
    const { register, control, errors, handleSubmit, setValue } = useForm({
        mode: 'onChange',
    });
    const requestForRetryOTP = () => {
        setShowVerificationCode(false);
        setverificatinCode('');
        setApiError('');
        props.getFormstatus(false);
    };
    const handleMobileChange = event => {
        setApiError('');
    };
    const handleOtpChange = event => {
        setApiError('');
        let otpValue = event.target.value;
        var regex = /^[0-9]{6}$/;
        if (regex.test(otpValue) == true) {
            setvalidateCodeBtn(false);
        }
    };
    const onSubmitMobile = async data => {
        if (props.forgotEmail == 'forgotEmail') {
            console.log('fromforgot email');
        }

        if (showVerificationCode) {
            setLoading(true);

            try {
                const res = await graphql.mutate({
                    mutation: ENDPOINT.VALIDATE_OTP_EMAIL_RECOVERY,
                    variables: { otpUuid: uuid, otpCode: data.Verification_code },
                });

                if (res.data.validateOTPForEmailRecovery) {
                    setverificatinCode(data.Verification_code);
                    setLoading(false);
                    setApiError('');
                    if (res.data.validateOTPForEmailRecovery.verified) {
                        if (props.forgotEmail == 'forgotEmail') {
                            props.getVerifiedEmail(res.data.validateOTPForEmailRecovery.email);
                            props.getFormstatus(true);
                            props.getErrorstatus(false);
                        } else {
                            props.getFormstatus(false);
                            props.getalreadyRegistered(true);
                            props.getEmailStatus(false);
                        }
                    }
                    if (!res.data.validateOTPForEmailRecovery.verified) {
                        if (
                            props.forgotEmail == 'forgotEmail' &&
                            res.data.validateOTPForEmailRecovery.email != undefined
                        ) {
                            props.getErrorstatus(false);
                            props.getVerifiedEmail(res.data.validateOTPForEmailRecovery.email);
                            props.getFormstatus(false);
                            props.getverifiedEmailNull(true);
                        }
                        if (
                            props.forgotEmail == 'forgotEmail' &&
                            res.data.validateOTPForEmailRecovery.email == undefined
                        ) {
                            props.getErrorstatus(true);
                            props.getFormstatus(false);
                            props.getverifiedEmailNull(false);
                        }

                        // console.log('above undefined');
                        if (res.data.validateOTPForEmailRecovery.email == undefined) {
                            // console.log(' undefined');
                            if (
                                props.forgotEmail != 'forgotEmail' ||
                                props.forgotEmail == undefined
                            ) {
                                props.getFormstatus(true);
                                props.getErrorstatus(false);
                                props.getalreadyRegistered(false);
                            }
                        }
                        if (res.data.validateOTPForEmailRecovery.email != undefined) {
                            if (
                                props.forgotEmail != 'forgotEmail' ||
                                props.forgotEmail == undefined
                            ) {
                                props.getFormstatus(false);
                                props.getalreadyRegistered(false);
                                props.getemailValue(res.data.validateOTPForEmailRecovery.email);
                                // console.log('!= undefined');
                                props.getEmailStatus(true);
                                // try {
                                //     const response = await graphql.mutate({
                                //         mutation: ENDPOINT.SEND_VERIFICATION_EMAIL,
                                //         variables: {
                                //             email: res.data.validateOTPForEmailRecovery.email,
                                //         },
                                //     });
                                //     if (response.data.sendVerificationEmail) {
                                //         // console.log('email sent');
                                //         props.getalreadyRegistered(false);
                                //         props.getEmailStatus(true);
                                //         props.getemailValue(
                                //             res.data.validateOTPForEmailRecovery.email
                                //         );
                                //     }
                                // } catch (err) {
                                //     // setLoading(false);
                                //     // setApiError(err.message);
                                // }
                            }
                        }
                    }
                    // console.log('data.mobileNumber', data.mobileNumber);
                }
            } catch (err) {
                setLoading(false);
                setApiError(err.message);
            }
        } else {
            setMobileNumber(data.mobileNumber);
            setLoading(true);
            try {
                const res = await graphql.query({
                    query: ENDPOINT.REQUEST_EMAIL_RECOVERY,
                    variables: { msisdn: data.mobileNumber },
                });

                if (res.data.requestEmailRecovery) {
                    // console.log('data.mobileNumber', data.mobileNumber);
                    props.getMobileNumber(data.mobileNumber);
                    setLoading(false);
                    setuuid(res.data.requestEmailRecovery.otpUuid);
                    setShowVerificationCode(true);
                    setApiError('');
                }
            } catch (err) {
                setLoading(false);
                setApiError(err.message);
            }
        }
    };

    return (
        <Fragment>
            <p>Enter your mobile number</p>
            <Container>
                <form onSubmit={handleSubmit(onSubmitMobile)}>
                    {!showVerificationCode && (
                        <div>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Controller
                                        as={
                                            <TextField
                                                InputProps={{
                                                    endAdornment: (
                                                        <SmartphoneOutlinedIcon className='Icon' />
                                                    ),
                                                }}
                                            />
                                        }
                                        control={control}
                                        fullWidth
                                        defaultValue={mobileNumber}
                                        id='mobileNumber'
                                        name='mobileNumber'
                                        label='Please enter your eir mobile number:'
                                        variant='outlined'
                                        size='small'
                                        required
                                        onChange={([event]) => {
                                            handleMobileChange(event);
                                            return event.target.value;
                                        }}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: validation.mobileNumberRequired,
                                            },
                                            pattern: {
                                                value: /^08[0-9]{8}$/,
                                                message: validation.mobileNumberPattern,
                                            },
                                        }}
                                    />
                                    <ErrorMessage errors={errors} name='mobileNumber'>
                                        {({ message }) => <p className='errorMessage'>{message}</p>}
                                    </ErrorMessage>
                                </Grid>
                            </Grid>
                            <p className='errorMessage'>{apiError}</p>
                            <Grid item xs={12} sm={12} md={12}>
                                <div className='mobileFloat'>
                                    <Button
                                        onClick={handleSubmit(onSubmitMobile)}
                                        type='submit'
                                        className='loginBtn'
                                    >
                                        Next<span className='fontLarge'></span>
                                    </Button>
                                </div>
                            </Grid>
                        </div>
                    )}

                    {showVerificationCode && (
                        <div>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Controller
                                        as={<TextField />}
                                        fullWidth
                                        disabled={!showVerificationCode}
                                        control={control}
                                        defaultValue={verificatinCode}
                                        id='Verification_code'
                                        name='Verification_code'
                                        label='Verification code'
                                        variant='outlined'
                                        size='small'
                                        required
                                        onChange={([event]) => {
                                            handleOtpChange(event);
                                            return event.target.value;
                                        }}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: validation.verificatinCodeRequired,
                                            },
                                            pattern: {
                                                value: /^[0-9]{6}$/,
                                                message: validation.verificatinCodePattern,
                                            },
                                        }}
                                    />
                                    <ErrorMessage errors={errors} name='Verification_code'>
                                        {({ message }) => <p className='errorMessage'>{message}</p>}
                                    </ErrorMessage>
                                    <p className='errorMessage'>{apiError}</p>
                                    <p className='notification'>
                                        Please check your phone for verification code. Please verify
                                        this code to move to the next step. This code will expire in
                                        1 minute.
                                    </p>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <Button
                                    variant='contained'
                                    className='retryBtn loginBtn'
                                    onClick={requestForRetryOTP}
                                >
                                    Retry
                                </Button>
                                <Button
                                    variant='contained'
                                    disabled={validateCodeBtn}
                                    color={validateCodeBtn ? 'default' : 'primary'}
                                    className={
                                        validateCodeBtn ? 'confirmBtn' : 'confirmBtn hoverEffect'
                                    }
                                    onClick={handleSubmit(onSubmitMobile)}
                                >
                                    Confirm
                                </Button>
                            </Grid>
                        </div>
                    )}
                    {loading && <Loading />}
                </form>
            </Container>
        </Fragment>
    );
}

export default MyEirRegisterMobileNumber;
