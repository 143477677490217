import { makeStyles } from '@material-ui/core/styles';

const portalStyles = makeStyles(theme => ({
    modalContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'darkgrey',
    },

    container: {
        maxWidth: '760px',
        paddingRight: '32px',
        paddingLeft: '32px',
        margin: '0 auto',
        marginTop: '-20px',
        borderRadius: '10px',
        backgroundColor: '#ffffff',
        boxShadow:
            '0px 6px 6px -3px rgba(136, 139, 141, 0.2), 0px 10px 14px 1px rgba(136, 139, 141, 0.14), 0px 4px 18px 3px rgba(136, 139, 141, 0.12)',

        '@media screen and (max-width:599px)': {
            // box-shadow: 0px 6px 6px -3px rgba(136, 139, 141, 0.2), 0px 10px 14px 1px rgba(136, 139, 141, 0.14), 0px 4px 18px 3px rgba(136, 139, 141, 0.12),
            borderRadius: '10px 10px 10px 10px',
            width: '95.5%',
            height: '100%',
            marginLeft: '8px',
            marginRight: '8px !important',
            marginTop: '-41px',
            paddingRight: '16px',
            paddingLeft: '16px',
        },
        '@media screen and (min-width:600px) and (max-width:1019px)': {
            width: '75%',
        },
    },
    img: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },

    heading: {
        width: '100%',
        fontSize: '24px',
        lineHeight: '26px',
        color: '#892BA0',
        fontFamily: 'Eir Medium',
        fontWeight: 'bold',
        textAlign: 'center',
        // max-height: 32px,

        paddingTop: '68px',
        borderBottom: '1px solid #E0E0E0',
        paddingBottom: '14px',

        '@media screen and ( max-width: 599px)': {
            fontSize: '20px',
            lineHeight: '26px',
            paddingTop: '51px',
            borderBottom: '1px solid #E0E0E0',
            paddingBottom: '13px',
            color: '#892BA0',
            fontFamily: 'Eir Medium',
            fontWeight: 'bold',
            textAlign: 'center',
        },
    },
    inner: {
        width: '100%',
        height: '100%',
    },

    spam: {
        fontSize: '16px',
        lineHeight: '25.12px',
        color: 'rgba(50, 52, 53, 0.87)',
        fontFamily: 'GT Walsheim Regular',
        fontWeight: 'normal',
        textAlign: 'center',
        padding: 0,
        margin: '0 auto',

        '@media screen and (max-width:599px)': {
            fontSize: '14px',
            lineHeight: '25.12px',
            paddingTop: '32px',
            paddingBottom: 0,
        },
    },
    close: {
        display: 'flex',
        justifyContent: 'center',
    },
    cta: {
        fontSize: '16px',
        lineHeight: '24px',
        color: '#ffffff',
        fontFamily: 'Eir Medium',
        fontWeight: 'bold',
        textAlign: 'center',
        marginTop: '96px',
        marginBottom: '98px',
        width: '184px',
        maxWidth: '184px',
        height: '41px',
        background: '#D011C9',
        borderRadius: '4100px',
        borderColor: 'unset',
        border: 'none',
        boxShadow: '0px 3px 1px -2px #d0d0d0, 0px 2px 2px #d0d0d0, 0px 1px 5px #d0d0d0',

        '@media screen and (max-width:599px)': {
            width: '264px',
            maxWidth: '264px',
            marginTop: '57px',
            marginBottom: '68.96px',
        },
    },
    email: {
        fontSize: '16px',
        lineHeight: '25.12px',
        color: 'rgba(50, 52, 53, 0.87)',
        fontFamily: 'GT Walsheim Regular',
        fontWeight: 'bold',
        textAlign: 'center',
        paddingTop: '20px',
        paddingBottom: '44px',
        margin: '0 auto',

        '@media screen and (max-width:599px)': {
            fontSize: '14px',
            lineHeight: '25.12px',
            paddingTop: '40px',
            paddingBottom: 0,
        },
    },
    user: {
        fontSize: '16px',
        lineHeight: '25.12px',
        color: 'rgba(50, 52, 53, 0.87)',
        fontFamily: 'GT Walsheim Regular',
        fontWeight: 'normal',
        // text-align: center,
        margin: '0 auto',

        '@media screen and (max-width:599px)': {
            fontSize: '14px',
            lineHeight: '25.12px',
        },
    },

    smile: {
        width: '84px',
        height: '84px',
        margin: '0 auto',
        // backgroundImage: 'url("data:image/svg+xml,%3Csvg width='84' height='84' viewBox='0 0 84 84' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cellipse cx='28.2689' cy='33.1156' rx='4.03846' ry='4.03846' fill='%23D111C9'/%3E%3Cellipse cx='55.7299' cy='33.1156' rx='4.03846' ry='4.03846' fill='%23D111C9'/%3E%3Cpath d='M27.4609 53.8623C31.7288 56.6117 36.5207 58.1538 41.5842 58.1538C46.9789 58.1538 52.0652 56.4034 56.5379 53.3076' stroke='%23D111C9' stroke-width='3.9375' stroke-linecap='round'/%3E%3Cpath d='M12.5367 67.2007C6.73482 60.4237 3.23047 51.6214 3.23047 42.0007C3.23047 20.589 20.588 3.23145 41.9997 3.23145C63.4114 3.23145 80.7689 20.589 80.7689 42.0007C80.7689 63.4123 63.4114 80.7699 41.9997 80.7699C35.9337 80.7699 30.1931 79.3768 25.0803 76.893' stroke='%23D111C9' stroke-width='3.9375' stroke-linecap='round'/%3E%3C/svg%3E")',
        marginTop: '84px',
        marginBottom: '39px',

        '@media screen and (max-width:599px)': {
            width: '64px',
            height: '64px',
            marginTop: '51px',
            marginBottom: '27px',
            // background-image: url("data:image/svg+xml,%3Csvg width='64' height='64' viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cellipse cx='21.5383' cy='25.2307' rx='3.07692' ry='3.07692' fill='%23D111C9'/%3E%3Cellipse cx='42.4617' cy='25.2307' rx='3.07692' ry='3.07692' fill='%23D111C9'/%3E%3Cpath d='M20.9229 41.0378C24.1745 43.1326 27.8255 44.3075 31.6835 44.3075C35.7936 44.3075 39.6689 42.9739 43.0767 40.6152' stroke='%23D111C9' stroke-width='3' stroke-linecap='round'/%3E%3Cpath d='M9.55188 51.1999C5.1314 46.0365 2.46143 39.33 2.46143 31.9999C2.46143 15.6862 15.6862 2.46143 31.9999 2.46143C48.3135 2.46143 61.5383 15.6862 61.5383 31.9999C61.5383 48.3135 48.3135 61.5383 31.9999 61.5383C27.3782 61.5383 23.0044 60.4769 19.1089 58.5845' stroke='%23D111C9' stroke-width='3' stroke-linecap='round'/%3E%3C/svg%3E"),
        },
    },
    title: {
        fontSize: '22px',
        lineHeight: '26px',
        color: '#892BA0',
        fontFamily: 'Eir Medium',
        fontWeight: 'bold',
        textAlign: 'center',
        paddingBottom: '32px',
        paddingTop: 0,
        margin: '0 auto',

        '@media screen and (max-width:599px)': {
            fontSize: '20px',
            lineHeight: '26px',
            paddingBottom: '23px',
            paddingTop: 0,
        },
    },
    desc: {
        fontSize: '16px',
        lineHeight: '25.12px',
        color: 'rgba(50, 52, 53, 0.87)',
        fontFamily: 'GT Walsheim Regular',
        fontWeight: 'normal',
        margin: '0 auto',
        width: '54%',
        textAlign: 'center',
        padding: 0,
        '@media screen and (max-width:599px)': {
            fontSize: '14px',
            lineHeight: '25.12px',
            width: '90%',
            padding: 0,
        },
    },
}));

export default portalStyles;
