import { gql } from '@apollo/client';

const ENDPOINT = {
    UPDATE_NDDPREFERENCE: gql`
        mutation nddPreference($token: String!, $serviceId: Int!, $nddPreference: String!) {
            updateMyNDD(token: $token, serviceId: $serviceId, nddPreference: $nddPreference)
        }
    `,
    GET_NDDPREFERENCE: gql`
        query getNDDPreference($token: String!, $serviceId: Int!) {
            getMyNDDPreference(token: $token, serviceId: $serviceId) {
                nddPreference
            }
        }
    `,
    UPDATE_CONTACT_PERMISSION_GROUP: gql`
        mutation updateMyContactPermission(
            $token: String!
            $allowThirdParty: Boolean!
            $permissionGroups: [PermissionGroupsResponseInput!]
        ) {
            updateMyContactPermission(
                token: $token
                allowThirdParty: $allowThirdParty
                permissionGroups: $permissionGroups
            )
        }
    `,
    GET_PERMISSION: gql`
        query permission($token: String!) {
            getMyPermissionDetails(token: $token) {
                permissionGroupResponse {
                    name
                    permissions {
                        name
                        enabled
                        permission
                    }
                }
                allowThirdParty
            }
        }
    `,
    UPDATE_BILLDELIVERYTYPE: gql`
        mutation updateBillDeliveryType(
            $token: String!
            $accountId: String!
            $invoiceDeliveryMethod: String!
        ) {
            updateBillDeliveryType(
                token: $token
                accountId: $accountId
                invoiceDeliveryMethod: $invoiceDeliveryMethod
            )
        }
    `,
    GET_PROFILE_DETAILS: gql`
        query getProfileDetails($token: String!, $serviceId: Int!) {
            getMyContactDetails(token: $token) {
                uuid
                phoneNumbers {
                    phoneNumber
                }
                emails {
                    email
                    type
                    validated
                }
                addresses {
                    addressLine1
                    addressLine2
                    addressLine3
                    town
                    county
                    eircode
                    code
                }
                birthDate
                firstName
                lastName
            }
            getMyNDDPreference(token: $token, serviceId: $serviceId) {
                nddPreference
            }
            getMyPermissionDetails(token: $token) {
                permissionGroupResponse {
                    name
                    permissions {
                        name
                        enabled
                        permission
                    }
                }
                allowThirdParty
            }
        }
    `,
    GET_ACCOUNT_NUMBER: gql`
        query getMyAccountID($token: String!, $accountId: String!) {
            getMyAccountID(token: $token, accountId: $accountId) {
                billingAccountId
                invoiceDeliveryMethod
            }
        }
    `,
};
/**
 * @name updateNDDPREFERENCEApi - update NDD Preference
 * @param {graphql} Obj.graphql - appoloclient graphql constant
 * @param {String} Obj.token - user access token
 * @param {String} Obj.serviceId - service id for which NDDServiceId is being requested
 * @param {String} Obj.nddPreference - nddPreference for which value is being requested
 * @returns
 */
export const updateNDDPREFERENCEApi = async ({ graphql, serviceId, token, nddPreference }) => {
    try {
        const res = await graphql.mutate({
            mutation: ENDPOINT.UPDATE_NDDPREFERENCE,
            variables: {
                token,
                serviceId,
                nddPreference,
            },
        });
        return res;
    } catch (error) {
        throw new Error(error);
    }
};
/**
 * @name getNDDPREFERENCEApi - calls getNDDPREFERENCE api and returns response
 * @param {GraphQL} Obj.graphql - appoloclient graphql constant
 * @param {String} Obj.token - user access token
 * @param {String} Obj.serviceId - service id for which usage summary is being requested
 * @returns 
 */
export const getNDDPREFERENCEApi = async ({ graphql, token, serviceId }) => {
    try {
        const res = await graphql.mutate({
            mutation: ENDPOINT.GET_NDDPREFERENCE,
            variables: {
                token,
                serviceId,
            },
        });
        return res;
    } catch (error) {
        throw new Error(error);
    }
};
/**
 * @name updateMyContactPermissionApi - update contact permission group
 * @param {graphql} Obj.graphql - appoloclient graphql constant
 * @param {String} Obj.token - user access token
 * @param {String} Obj.allowThirdParty - allowThirdParty is being requested
 * @param {String} Obj.permissionGroups - permissionGroups is being requested
 * @returns
 */

export const updateMyContactPermissionApi = async ({
    graphql,
    token,
    allowThirdParty,
    permissionGroups,
}) => {
    try {
        const res = await graphql.mutate({
            mutation: ENDPOINT.UPDATE_CONTACT_PERMISSION_GROUP,
            variables: {
                token,
                allowThirdParty,
                permissionGroups,
            },
        });
        return res;
    } catch (error) {
        throw new Error(error);
    }
};
/**
 * @name permissionApi - calls getpermission api and returns response
 * @param {GraphQL} Obj.graphql - appoloclient graphql constant
 * @param {String} Obj.token - user access token
 * @param {String} Obj.serviceId - service id for which permissionApi is being requested
 * @returns 
 */
export const permissionApi = async ({ graphql, token, serviceId }) => {
    try {
        const res = await graphql.query({
            query: ENDPOINT.GET_PERMISSION,
            variables: {
                token: token,
                serviceId: serviceId,
            },
        });

        return res;
    } catch (error) {
        throw new Error(error);
    }
};
/**
 * @name updateBillDeliveryTypeApi - updating Bill Delivery Type
 * @param {graphql} Obj.graphql - appoloclient graphql constant
 * @param {String} Obj.token - user access token
 * @param {String} Obj.accountId - accountId is being requested
 * @param {String} Obj.invoiceDeliveryMethod - invoice method is being requested
 * @returns
 */
export const updateBillDeliveryTypeApi = async ({
    graphql,
    token,
    accountId,
    invoiceDeliveryMethod,
}) => {
    try {
        const resp = await graphql.mutate({
            mutation: ENDPOINT.UPDATE_BILLDELIVERYTYPE,
            variables: {
                token,
                accountId,
                invoiceDeliveryMethod,
            },
        });
        return resp;
    } catch (error) {
        console.log('error123', error);
        throw new Error(error);
    }
};
/**
 * @name getProfileDetailsApi - calls getprofiledetails api and returns response
 * @param {GraphQL} Obj.graphql - appoloclient graphql constant
 * @param {String} Obj.token - user access token
 * @param {String} Obj.serviceId - service id for which getprofiledetails is being requested
 * @returns 
 */
export const getProfileDetailsApi = async ({ graphql, token, serviceId }) => {
    try {
        const data = await graphql.query({
            query: ENDPOINT.GET_PROFILE_DETAILS,
            variables: {
                token,
                serviceId,
            },
            errorPolicy: 'all',
        });
        return data;
    } catch (error) {
        throw new Error(error);
    }
};
/**
 * @name getMyAccountIDApi - calls getaccountnumber api and returns response
 * @param {String} Obj.token - user access token
 * @param {String} Obj.serviceId - service id for which get account number is being requested
 * @returns 
 */
export const getMyAccountIDApi = async ({ graphql, token, accountId }) => {
    try {
        const response = await graphql.query({
            query: ENDPOINT.GET_ACCOUNT_NUMBER,
            variables: {
                token,
                accountId,
            },
        });
        return response;
    } catch (error) {
        throw new Error(error);
    }
};
