import {
    getPinPukApi,
    getOfferDetailsApi,
    updateAdobeOffersApi,
    getEligibilityForManageAddonsApi,
} from '../redesign.api';
import {
    getAdobeOffersApi,
    getMyServicesOffersApi,
    getOfferElligibiltyApi,
    getChangeOfferEligibiltyApi,
    createChaneOfferProspectApi,
} from './myServices.api';
import broadbandImage from '../images/router.png';
import mobileImage from '../images/mobileIcon.png';
import tvImage from '../images/live-tv.png';
import {
    DOMAINS,
    SERVICE_GROUPS,
    ACCOUNT_STATUS,
    ADDON_CODES,
} from '../../../components/myeirconstant/myEirConstant';
import { getActiveAddons, getAddOnsApi } from '../manage-my-addons/manageAddons.api';

const { FIBER, IPTV, MOBILE } = DOMAINS;

/**
 * @name updateLastChild - update the last child of each category array with a boolean paramter, which defines it a last child and helps us in dividing with a line in frontend
 * @param {Array} array - array of each category(DUALPLAY, TV, MOBILE, MBB)
 * @returns newState - update array with last child with boolean parameter
 */
export const updateLastChild = array => {
    const newState = array.map((obj, index) => {
        if (index === array.length - 1) {
            return { ...obj, lastChild: true };
        }
        return obj;
    });
    return newState;
};

export const getOfferForPlan = async ({
    graphql,
    subscriptions,
    services,
    broadbandImage,
    tvImage,
    serviceGroup,
    dualplayObjects = [],
    tvObjects = [],
    pstnObjects = [],
}) => {
    const offerName = await getOfferDetailsApi({
        graphql,
        catalogOfferCode: subscriptions.catalogOfferCode,
    });

    if (serviceGroup === 'DUALPLAY') {
        return dualplayObjects.push({
            image: broadbandImage,
            title: 'Broadband & Landline',
            count: dualplayObjects.length,
            serviceGroup: subscriptions.serviceGroup,
            serviceId: services.id,
            subId: services.subscriptionId,
            sipNumber: services.sipNumber,
            dsl: services.dsl,
            offerName: offerName,
            status: services.status,
            dependsOn: subscriptions.dependsOn,
            domain: services.domain,
        });
    } else if (serviceGroup === 'DUALPLAY_PSTN') {
        return dualplayObjects.push({
            image: broadbandImage,
            title: 'Broadband & Landline',
            count: dualplayObjects.length,
            serviceGroup: subscriptions.serviceGroup,
            serviceId: services.id,
            subId: services.subscriptionId,
            sipNumber: services.sipNumber,
            phoneNumber: services.phoneNumber,
            dsl: services.dsl,
            offerName: offerName,
            status: services.status,
            dependsOn: subscriptions.dependsOn,
            domain: services.domain,
        });
    } else if (serviceGroup === 'SA_PSTN') {
        return pstnObjects.push({
            image: broadbandImage,
            title: 'Landline',
            count: pstnObjects.length,
            serviceGroup: subscriptions.serviceGroup,
            serviceId: services.id,
            subId: services.subscriptionId,
            phoneNumber: services.phoneNumber,
            dsl: services.dsl,
            sipNumber: services.sipNumber,
            offerName: offerName,
            status: services.status,
            dependsOn: subscriptions.dependsOn,
            domain: services.domain,
        });
    } else if (serviceGroup === 'TV') {
        return tvObjects.push({
            image: tvImage,
            title: 'TV',
            count: tvObjects.length,
            serviceGroup: subscriptions.serviceGroup,
            serviceId: services.id,
            subId: services.subscriptionId,
            broadbandDsl: services.bbDsl,
            offerName: offerName,
            status: services.status,
            dependsOn: subscriptions.dependsOn,
            domain: services.domain,
        });
    }
};

export const getMobileMbbPlanDetails = async ({
    graphql,
    subscriptions,
    services,
    mobileImage,
    mobileObjects = [],
    mbbObjects = [],
    serviceGroup,
}) => {
    let offerName;
    let eligibility;
    let pinPuk;
    try {
        offerName = await getOfferDetailsApi({
            graphql,
            catalogOfferCode: subscriptions.catalogOfferCode,
        });
    } catch (error) {
        console.log('error while fetching getOfferDetailsApi in mobile services =====>', error);
    }
    try {
        eligibility = await getOfferElligibiltyApi({
            graphql,
            subscriptionId: services.subscriptionId,
        });
    } catch (error) {
        console.log('error while fetching getOfferElligibiltyApi in mobile services =====>', error);
    }

    try {
        pinPuk = await getPinPukApi({
            graphql,
            serviceId: services.id,
        });
    } catch (error) {
        console.log('error while fetching getPinPukApi in mobile services =====>', error);
    }
    if (serviceGroup === 'MOBILE') {
        return mobileObjects.push({
            image: mobileImage,
            title: 'Mobile',
            count: mobileObjects.length,
            serviceGroup: subscriptions.serviceGroup,
            serviceId: services.id,
            subId: services.subscriptionId,
            msisdn: services.msisdn,
            offerName: offerName,
            eligibility: eligibility,
            pinPuk: pinPuk,
            status: services.status,
            dependsOn: subscriptions.dependsOn,
            domain: services.domain,
        });
    } else if (serviceGroup === 'MBB') {
        return mbbObjects.push({
            image: mobileImage,
            title: 'MBB',
            count: mbbObjects.length,
            serviceGroup: subscriptions.serviceGroup,
            serviceId: services.id,
            subId: services.subscriptionId,
            msisdn: services.msisdn,
            offerName: offerName,
            eligibility: eligibility,
            pinPuk: pinPuk,
            status: services.status,
            dependsOn: subscriptions.dependsOn,
            domain: services.domain,
        });
    }
};

/**
 * @name getMyPlanDetails - get the my services details using the subscription and services and calling respective API for each category
 * @param {Object} Obj - attributes for the function
 * @param {GraphQL} Obj.graphql - appoloclient graphql constant
 * @param {useState} Obj.setIsContractsError - useState function which sets error in my plan
 * @param {useState} Obj.setPlanArr - useState function which sets my plan array
 * @param {GraphQL} graphql - appoloclient graphql constant
 * @param {useState} setIsContractsError - useState function which sets error in my plan
 * @param {useState} setPlanArr - useState function which sets my plan array
 */
export const getMyPlanDetails = async ({
    graphql,
    setIsContractsError,
    setPlanArr,
    dualplayObjects,
    pstnObjects,
    tvObjects,
    mobileObjects,
    mbbObjects,
    setIsLoading,
}) => {
    try {
        const subscriptions = JSON.parse(
            sessionStorage.getItem('LoggedinUserSubscriptionIDandAccountID')
        );
        const services = JSON.parse(sessionStorage.getItem('LoggedinServices'));
        const data = await getEligibilityForManageAddonsApi({ graphql });
        const userStatus = data.getAccountStatus.status;
        if (subscriptions != null && services != null) {
            for (let i = 0; i < subscriptions.length; i++) {
                for (let j = 0; j < services.length; j++) {
                    if (
                        userStatus === ACCOUNT_STATUS.BILLABLE ||
                        userStatus === ACCOUNT_STATUS.INITIAL
                    ) {
                        if (services[j].status !== 'TERMINATED') {
                            if (subscriptions[i].serviceGroup === SERVICE_GROUPS.DUALPLAY) {
                                if (subscriptions[i].id === services[j].subscriptionId) {
                                    try {
                                        await getOfferForPlan({
                                            graphql,
                                            subscriptions: subscriptions[i],
                                            services: services[j],
                                            broadbandImage,
                                            dualplayObjects,
                                            serviceGroup: 'DUALPLAY',
                                        });
                                    } catch (error) {
                                        console.log(
                                            'error while fetching getOfferDetailsApi in dualplay =====> ',
                                            error
                                        );
                                    }
                                }
                            } else if (
                                subscriptions[i].serviceGroup === SERVICE_GROUPS.DUALPLAY_PSTN
                            ) {
                                if (subscriptions[i].id === services[j].subscriptionId) {
                                    try {
                                        await getOfferForPlan({
                                            graphql,
                                            subscriptions: subscriptions[i],
                                            services: services[j],
                                            broadbandImage,
                                            dualplayObjects,
                                            serviceGroup: 'DUALPLAY_PSTN',
                                        });
                                    } catch (error) {
                                        console.log(
                                            'error while fetching getOfferDetailsApi in dualplay =====> ',
                                            error
                                        );
                                    }
                                }
                            } else if (subscriptions[i].serviceGroup === SERVICE_GROUPS.SA_PSTN) {
                                if (subscriptions[i].id === services[j].subscriptionId) {
                                    try {
                                        await getOfferForPlan({
                                            graphql,
                                            subscriptions: subscriptions[i],
                                            services: services[j],
                                            broadbandImage,
                                            pstnObjects,
                                            serviceGroup: 'SA_PSTN',
                                        });
                                    } catch (error) {
                                        console.log(
                                            'error while fetching getOfferDetailsApi in dualplay =====> ',
                                            error
                                        );
                                    }
                                }
                            } else if (subscriptions[i].serviceGroup === SERVICE_GROUPS.TV) {
                                if (subscriptions[i].id === services[j].subscriptionId) {
                                    try {
                                        await getOfferForPlan({
                                            graphql,
                                            subscriptions: subscriptions[i],
                                            services: services[j],
                                            tvImage,
                                            tvObjects,
                                            serviceGroup: 'TV',
                                        });
                                    } catch (error) {
                                        console.log(
                                            ' error while fetching getOfferDetailsApi in TV =====>',
                                            error
                                        );
                                    }
                                }
                            } else if (
                                subscriptions[i].serviceGroup === SERVICE_GROUPS.MOBILE &&
                                subscriptions[i].type !== 'PREPAY'
                            ) {
                                if (subscriptions[i].id === services[j].subscriptionId) {
                                    try {
                                        await getMobileMbbPlanDetails({
                                            graphql,
                                            subscriptions: subscriptions[i],
                                            services: services[j],
                                            mobileImage,
                                            mobileObjects,
                                            serviceGroup: 'MOBILE',
                                        });
                                    } catch (error) {
                                        console.log(
                                            'error while fetching mobile services =====>',
                                            error
                                        );
                                    }
                                }
                            } else if (subscriptions[i].serviceGroup === SERVICE_GROUPS.MBB) {
                                if (subscriptions[i].id === services[j].subscriptionId) {
                                    try {
                                        await getMobileMbbPlanDetails({
                                            graphql,
                                            subscriptions: subscriptions[i],
                                            services: services[j],
                                            mobileImage,
                                            mbbObjects,
                                            serviceGroup: 'MBB',
                                        });
                                    } catch (error) {
                                        console.log(
                                            'error while fetching mobile services =====>',
                                            error
                                        );
                                    }
                                }
                            }
                        }
                    } else if (
                        userStatus === ACCOUNT_STATUS.BILLABLE_EMPTY ||
                        userStatus === ACCOUNT_STATUS.UNBILLABLE
                    ) {
                        if (services[j].status === 'TERMINATED') {
                            if (subscriptions[i].serviceGroup === SERVICE_GROUPS.DUALPLAY) {
                                if (subscriptions[i].id === services[j].subscriptionId) {
                                    try {
                                        await getOfferForPlan({
                                            graphql,
                                            subscriptions: subscriptions[i],
                                            services: services[j],
                                            broadbandImage,
                                            dualplayObjects,
                                            serviceGroup: 'DUALPLAY',
                                        });
                                    } catch (error) {
                                        console.log(
                                            'error while fetching getOfferDetailsApi in dualplay =====> ',
                                            error
                                        );
                                    }
                                }
                            } else if (
                                subscriptions[i].serviceGroup === SERVICE_GROUPS.DUALPLAY_PSTN
                            ) {
                                if (subscriptions[i].id === services[j].subscriptionId) {
                                    try {
                                        await getOfferForPlan({
                                            graphql,
                                            subscriptions: subscriptions[i],
                                            services: services[j],
                                            broadbandImage,
                                            dualplayObjects,
                                            serviceGroup: 'DUALPLAY_PSTN',
                                        });
                                    } catch (error) {
                                        console.log(
                                            'error while fetching getOfferDetailsApi in dualplay =====> ',
                                            error
                                        );
                                    }
                                }
                            } else if (subscriptions[i].serviceGroup === SERVICE_GROUPS.SA_PSTN) {
                                if (subscriptions[i].id === services[j].subscriptionId) {
                                    try {
                                        await getOfferForPlan({
                                            graphql,
                                            subscriptions: subscriptions[i],
                                            services: services[j],
                                            broadbandImage,
                                            pstnObjects,
                                            serviceGroup: 'SA_PSTN',
                                        });
                                    } catch (error) {
                                        console.log(
                                            'error while fetching getOfferDetailsApi in dualplay =====> ',
                                            error
                                        );
                                    }
                                }
                            } else if (subscriptions[i].serviceGroup === SERVICE_GROUPS.TV) {
                                if (subscriptions[i].id === services[j].subscriptionId) {
                                    try {
                                        await getOfferForPlan({
                                            graphql,
                                            subscriptions: subscriptions[i],
                                            services: services[j],
                                            tvImage,
                                            tvObjects,
                                            serviceGroup: 'TV',
                                        });
                                    } catch (error) {
                                        console.log(
                                            ' error while fetching getOfferDetailsApi in TV =====>',
                                            error
                                        );
                                    }
                                }
                            } else if (
                                subscriptions[i].serviceGroup === SERVICE_GROUPS.MOBILE &&
                                subscriptions[i].type !== 'PREPAY'
                            ) {
                                if (subscriptions[i].id === services[j].subscriptionId) {
                                    try {
                                        await getMobileMbbPlanDetails({
                                            graphql,
                                            subscriptions: subscriptions[i],
                                            services: services[j],
                                            mobileImage,
                                            mobileObjects,
                                            serviceGroup: 'MOBILE',
                                        });
                                    } catch (error) {
                                        console.log(
                                            'error while fetching mobile services =====>',
                                            error
                                        );
                                    }
                                }
                            } else if (subscriptions[i].serviceGroup === SERVICE_GROUPS.MBB) {
                                if (subscriptions[i].id === services[j].subscriptionId) {
                                    try {
                                        await getMobileMbbPlanDetails({
                                            graphql,
                                            subscriptions: subscriptions[i],
                                            services: services[j],
                                            mobileImage,
                                            mbbObjects,
                                            serviceGroup: 'MBB',
                                        });
                                    } catch (error) {
                                        console.log(
                                            'error while fetching mobile services =====>',
                                            error
                                        );
                                    }
                                }
                            }
                        }
                    } else {
                        console.log(
                            `something went wrong. Account status: ${userStatus}, does not exist!`
                        );
                    }
                }
            }
            /* commented below code so may be we would not concat the different addray into one as its different cards now.
             */
            if (
                dualplayObjects.length > 0 ||
                pstnObjects.length > 0 ||
                tvObjects.length > 0 ||
                mobileObjects.length > 0 ||
                mbbObjects.length > 0
            ) {
                setIsLoading(false);
                let filterDualPlay = dualplayObjects.reduce((a, itm) => {
                    var filteredObject = a.filter(e => e.subId == itm.subId);
                    if (filteredObject.length > 0) {
                        if (filteredObject[0].sipNumber != null) {
                            filteredObject[0].sipNumber +=
                                ' | ' + itm.dsl + ' | ' + (itm.phoneNumber ? itm.phoneNumber : '');
                            if (itm.domain === 'FIBER') {
                                filteredObject[0].domain = itm.domain;
                                filteredObject[0].serviceId = itm.serviceId;
                            }
                        } else {
                            filteredObject[0].sipNumber =
                                itm.sipNumber +
                                ' | ' +
                                filteredObject[0].dsl +
                                ' | ' +
                                (itm.phoneNumber ? itm.phoneNumber : '');
                            if (itm.domain === 'FIBER') {
                                filteredObject[0].domain = itm.domain;
                                filteredObject[0].serviceId = itm.serviceId;
                            }
                        }
                    } else {
                        a.push(itm);
                    }
                    return a;
                }, []);
                let filterPstn = pstnObjects.reduce((a, itm) => {
                    var filteredObj =
                        pstnObjects.length > 1
                            ? a.filter(e => e.subId == itm.subId)
                            : [...pstnObjects];
                    if (filteredObj.length > 0) {
                        if (filteredObj[0].sipNumber != null) {
                            filteredObj[0].sipNumber += itm.phoneNumber;
                        } else {
                            filteredObj[0].sipNumber = itm.phoneNumber;
                        }
                    }
                    a.push(itm);

                    return a;
                }, []);
                filterDualPlay = updateLastChild(filterDualPlay);
                filterPstn = updateLastChild(filterPstn);
                tvObjects = updateLastChild(tvObjects);
                mobileObjects = updateLastChild(mobileObjects);
                mbbObjects = updateLastChild(mbbObjects);
                let tempArr = [];
                filterDualPlay.length > 0 ? (tempArr = tempArr.concat(filterDualPlay)) : '';
                filterPstn.length > 0 ? (tempArr = tempArr.concat(filterPstn)) : '';
                tvObjects.length > 0 ? (tempArr = tempArr.concat(tvObjects)) : '';
                mobileObjects.length > 0 ? (tempArr = tempArr.concat(mobileObjects)) : '';
                mbbObjects.length > 0 ? (tempArr = tempArr.concat(mbbObjects)) : '';
                const uniqueArray = tempArr.filter((value, index, self) => {
                    return (
                        index ==
                        self.findIndex(
                            obj => obj.serviceId === value.serviceId && obj.subId === value.subId
                        )
                    );
                });
                setPlanArr(uniqueArray);
                value.dispatch({
                    type: 'myServices',
                    payload: { name: 'planArr', value: uniqueArray },
                });
            } else {
                throw new Error('No services');
            }
        } else {
            throw new Error('No subscription or services');
        }
    } catch (error) {
        setIsContractsError(true);
        console.log('error in fetching plans ', error.message);
    }
};

/**
 * @name getAdobeOffers - get my services & recommended offers
 * @param {GraphQL} Obj.graphql - appoloclient graphql constant
 * @param {useState} Obj.setServiceOffers - useState function which sets offers in services
 * @param {GraphQL} graphql - appoloclient graphql constant
 * @param {useState} setServiceOffers - useState function which sets offers in services
 * @returns
 */

export const getAdobeOffers = async ({ graphql }) => {
    try {
        const data = await getAdobeOffersApi({ graphql });
        if (data.getAdobeOffers) {
            return data.getAdobeOffers;
        }
    } catch (error) {
        console.error('error in fetching adobe offers ', error.message);
    }
};

/**
 * @name getWifiMeshAddOn - get my services & recommended offers
 * @param {GraphQL} Obj.graphql - appoloclient graphql constant
 * @returns
 */

export const getAddonsForService = async ({ graphql, codes }) => {
    try {
        const data = await getAddOnsApi({ graphql, codes });
        if (data) {
            return data;
        }
    } catch (error) {
        console.error('error in fetching wifiAddon ', error.message);
    }
};

/**
 * @name getCurrentlySubscribedAddonsForService - get currently subscribbed addons
 * @param {GraphQL} Obj.graphql - appoloclient graphql constant
 * @returns
 */

export const getCurrentlySubscribedAddonsForService = async ({ graphql, servicesIds }) => {
    try {
        const data = await getActiveAddons({ graphql, servicesIds });
        if (data) {
            return data?.getCurrentlySubscribedAddons;
        }
    } catch (error) {
        console.error('error in fetching CurrentlySubscribedAddons ', error.message);
    }
};

/**
 * @name getChangeOfferEligibiltyApi - to check whether user is eligible for change offer
 * @param {GraphQL} Obj.graphql - appoloclient graphql constant
 * @param {String} Obj.subscriptionId - subscription id of the subscription
 * @param {useState} Obj.setOpenError - useState function which sets error modal
 * @param {useState} Obj.openError - useState function which handles error modal
 * @param {useState} Obj.setOpen - useState function which sets offer upgrade modal
 * @param {useState} Obj.open - useState function which handles offer upgrade modal
 * @returns
 */

export const getChangeOfferUpgradeEligibility = async ({
    graphql,
    subscriptionId,
    setOpenError,
    openError,
}) => {
    try {
        const data = await getChangeOfferEligibiltyApi({ graphql, subscriptionId });
        if (data) {
            return data.changeOfferUpgradeEligibility;
        }
    } catch (error) {
        console.log('error in fetching change offers ', error.message);
        setOpenError(!openError);
    }
};

/**
 * @name getAdobeAnalyticsApi - to check whether user has clicked on upgrade or not interested link
 * @param {Int} Obj.value - pass 3 for accept & 5 to reject
 * @param {String} Obj.propositionId - propositionId from adobe offers
 * @returns
 */

export const getAdobeAnalyticsApi = async ({ graphql, value, propositionId }) => {
    try {
        await updateAdobeOffersApi({ graphql, value, propositionId });
    } catch (error) {
        console.log('error in fetching analytics adobe ', error.message);
    }
};

/**
 * @name getMyServicesOffers - to get data for services offers
 * @param {GraphQL} Obj.graphql - appoloclient graphql constant
 * @param {String} Obj.servicesOffer - servicesOffer is an useState array
 * @param {String} Obj.setGetOffers - useState to set offers valuse
 * @returns
 */
export const getMyServicesOffers = async ({ graphql, servicesOffer, setGetOffers }) => {
    try {
        const subscriptions = JSON.parse(
            sessionStorage.getItem('LoggedinUserSubscriptionIDandAccountID')
        );
        if (subscriptions.length > 0) {
            for (let i = 0; i < subscriptions.length; i++) {
                const serviceGroupCode = subscriptions[i]?.serviceGroup;
                if (servicesOffer.length > 0) {
                    let TariffCodes = [];
                    for (let j = 0; j < servicesOffer.length; j++) {
                        if (servicesOffer[j]?.type !== 'addon') {
                            const catalogTariffCode = servicesOffer[j]?.tariff_code;
                            TariffCodes.push(catalogTariffCode);
                        }
                    }
                    const data = await getMyServicesOffersApi({
                        graphql,
                        catalogTariffCode: TariffCodes,
                        serviceGroupCode,
                    });
                    setGetOffers(data.getTariffs.tariffs);
                }
            }
        }
    } catch (error) {
        console.error('error in fetching myservices offers ', error.message);
    }
};
/**
 * @name createChaneOfferProspectUtil - to create change offer
 * @param {GraphQL} Obj.graphql - appoloclient graphql constant
 * @param {String} Obj.subscriptionId - subscription id of the subscription
 * @param {useState} Obj.setOpenError - useState function which sets error modal
 * @param {useState} Obj.openError - useState function which handles error modal
 * @param {useState} Obj.setOpen - useState function which sets offer upgrade modal
 * @param {useState} Obj.open - useState function which handles offer upgrade modal
 * @returns
 */

export const createChaneOfferProspectUtil = async ({
    graphql,
    subscriptionId,
    setOpenError,
    openError,
    setOpenOffer,
    openOffer,
}) => {
    try {
        const data = await createChaneOfferProspectApi({ graphql, subscriptionId });
        if (data) {
            return data.createChangeOffer;
        }
    } catch (error) {
        console.log('error in fetching change offers ', error.message);
        setOpenOffer(!openOffer);
        setOpenError(!openError);
    }
};
