import React, { useState, useEffect, useRef, useContext } from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';

import '../../sass/mobile-header.scss';
import { useWindowDimension } from '../../hooks/windowDimensions';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { useApolloClient } from '@apollo/client';
import ENDPOINT from '../../mobilecomponents/global-context';
import { publish, subscribe } from 'pubsub-js';
import { MobileContext } from '../../mobilecomponents/global-context';
import { getNameAndAccountID } from '../../mobilecomponents/getNameAndAccountNumber';
import { useHistory } from 'react-router-dom';
import myeirLogo from '../../sharedcomponents/images/LOGO.png';
import myeirLogoMobile from '../../sharedcomponents/images/LOGOM.png';
import hamMenuBackground from '../../sharedcomponents/images/navigation_M.png';
import { getMsisdns } from '../redesign-5.2/sim-replacement/simReplacement.util';
import { clearAllCookies, triggerAdobeApi } from './redesign.api';
import EirButton from '../../theme/EirButton';

function MobileHeaderRedesign(props) {
    const [width, height] = useWindowDimension();
    const [isopen, setIsOpen] = useState(false);
    const [isLoggedIn, setLogIn] = useState(true);
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [accountNumber, setAccountNumber] = useState();
    const [retryAPIcall, setRetryAPIcall] = useState(4);
    const value = useContext(MobileContext);
    const graphql = useApolloClient();
    const history = useHistory();
    const [msisdnVal, setMsisdnVal] = useState([]);
    const toggle = () => {
        setIsOpen(!isopen);
    };
    const toggleLogIn = () => setLogIn(!isLoggedIn);
    // for future use keeping it as it is
    const onNavClick = (navItem, actionType) => {
        navItem >= 0 && value.dispatch({ type: actionType, payload: navItem });
        if (!(location.href.indexOf('/account/dashboard') > -1)) {
            history.push('/account/dashboard');
        }

        setIsOpen(!isopen);
    };

    const handleClickClose = () => {
        history.push('/account/dashboard');
        toggle();
    };

    useEffect(() => {
        subscribe('openhamburger', toggle);
        // subscribe('closehamburger', handleClickClose);
    }, []);

    async function getNameAndAccountNumber() {
        if (
            sessionStorage.getItem('LoggedinUserFirstName') == undefined ||
            sessionStorage.getItem('LoggedinUserLastName') == undefined ||
            sessionStorage.getItem('LoggedinUserAccountNumber') == undefined ||
            sessionStorage.getItem('LoggedinUserAccountNumber') == ''
        ) {
            const response = await getNameAndAccountID(graphql);
            // console.log('response[0]', response[0]);
            if (
                sessionStorage.getItem('LoggedinUserFirstName') == undefined ||
                sessionStorage.getItem('LoggedinUserLastName') == undefined ||
                sessionStorage.getItem('LoggedinUserAccountNumber') == undefined ||
                sessionStorage.getItem('LoggedinUserAccountNumber') == ''
            ) {
                setTimeout(function () {
                    // console.log('retrying to fetch Name and Account number');
                    // console.log('number of retries left', retryAPIcall - 1);
                    setRetryAPIcall(retryAPIcall - 1);
                }, 10000);
            }
            if (
                sessionStorage.getItem('LoggedinUserAccountNumber') != undefined &&
                sessionStorage.getItem('LoggedinUserAccountNumber') != ''
            ) {
                setAccountNumber(String(sessionStorage.getItem('LoggedinUserAccountNumber')));
            }

            if (sessionStorage.getItem('LoggedinUserFirstName') != undefined) {
                setFirstName(sessionStorage.getItem('LoggedinUserFirstName'));
            }

            if (sessionStorage.getItem('LoggedinUserLastName') != undefined) {
                setLastName(sessionStorage.getItem('LoggedinUserLastName'));
            }
        } else {
            setAccountNumber(String(sessionStorage.getItem('LoggedinUserAccountNumber')));
            setFirstName(sessionStorage.getItem('LoggedinUserFirstName'));
            setLastName(sessionStorage.getItem('LoggedinUserLastName'));
        }
    }

    const getMisisdnsUtil = async () => {
        await getMsisdns({ setMsisdnVal });
    };

    useEffect(() => {
        // console.log('mobile header useeffect 1')
        if (props.loginSuccess == true) {
            getNameAndAccountNumber();
        }
    }, [props.loginSuccess]);

    useEffect(() => {
        // console.log('mobile header useeffect 2')
        if (retryAPIcall > 0 && retryAPIcall < 4) {
            getNameAndAccountNumber();
        }
    }, [retryAPIcall]);

    useEffect(() => {
        getMisisdnsUtil();
    }, []);

    const handleContracts = async () => {
        history.push('/account/contracts');
        toggle();
        const contractfetch = await triggerAdobeApi({ graphql, name: 'contract' });
    };

    const handlePreferences = async () => {
        history.push('/account/preferences');
        toggle();
        const preferencesfetch = await triggerAdobeApi({ graphql, name: 'prefer' });
    };

    return (
        <div className='my-eir-header-container'>
            <div className='my-eir-header'>
                <div
                    onClick={() => {
                        // location.href = 'https://my.eir.ie';
                        history.push('/account/dashboard');
                    }}
                    className='my-eir-logo'
                >
                    <picture>
                        <source media='(max-width: 599px)' srcSet={myeirLogoMobile} />
                        <img src={myeirLogo} alt='MyEirLogo' />
                    </picture>
                </div>
                {props.loginSuccess && window.location.href.indexOf('/account') > -1 && (
                    <div className='modal-test'>
                        <div className='account-information'>
                            <div className='account-name'>
                                {!!firstName && <>{firstName}&nbsp;</>}
                                {!!lastName && lastName}
                            </div>
                            <div className='my-account-text'>My account</div>
                        </div>
                        <React.Fragment>
                            <div
                                className={'burger-menu' + (isopen ? ' open' : '')}
                                onClick={toggle}
                            >
                                <div className='burger-menu-button'></div>
                            </div>
                            <Modal
                                className='hamBmain'
                                wrapClassName='hamBwrap'
                                modalClassName='hamBmodal'
                                backdropClassName='hamBbackdrop'
                                contentClassName='hamBcontent'
                                isOpen={isopen}
                                toggle={toggle}
                                modalTransition={{ timeout: 300 }}
                            >
                                <ModalBody
                                    className='hamBbody'
                                    // style={{
                                    //     background: `url(${hamMenuBackground}) no-repeat`,
                                    //     backgroundSize: '100% 143%',
                                    // }}
                                >
                                    <div className='hamburger-menu'>
                                        <div
                                            className={'burger-menu' + (!isopen ? ' open' : '')}
                                            onClick={toggle}
                                        >
                                            <div className='burger-menu-button'></div>
                                        </div>
                                        <div className='account-information'>
                                            <div className='account-name'>
                                                {!!firstName && <>{firstName}&nbsp;</>}
                                                {!!lastName && lastName}
                                            </div>
                                            <div className='account-number'>
                                                {!!accountNumber && (
                                                    <>Account number: {accountNumber}</>
                                                )}
                                            </div>
                                        </div>

                                        <div className='account-navigations'>
                                            <div
                                                className='account-navigations-home'
                                                onClick={handleClickClose}
                                            >
                                                <span style={{ cursor: 'pointer' }}>Home</span>
                                            </div>

                                            <div
                                                className='account-navigations-eir-account'
                                                onClick={() => {
                                                    history.push('/account/details');
                                                    toggle();
                                                }}
                                            >
                                                <span style={{ cursor: 'pointer' }}>
                                                    Account details
                                                </span>
                                            </div>

                                            <div
                                                className='account-navigations-Contracts'
                                                onClick={handleContracts}
                                            >
                                                <span style={{ cursor: 'pointer' }}>Contracts</span>
                                            </div>
                                            <div className='account-preferences'>
                                                <div
                                                    className='account-navigations-Preferences'
                                                    onClick={handlePreferences}
                                                >
                                                    <span style={{ cursor: 'pointer' }}>
                                                        Preferences
                                                    </span>
                                                </div>
                                            </div>
                                            {msisdnVal.length !== 0 && (
                                                <div
                                                    className='account-navigations-simReplacement'
                                                    onClick={() => {
                                                        history.push('/account/simreplacement');
                                                        toggle();
                                                    }}
                                                >
                                                    <span style={{ cursor: 'pointer' }}>
                                                        SIM replacement
                                                    </span>
                                                </div>
                                            )}
                                            <div
                                                className='account-navigations-help'
                                                onClick={() => {
                                                    toggle();
                                                    window.open(
                                                        'https://www.eir.ie/support/',
                                                        '_blank'
                                                    );
                                                }}
                                            >
                                                <span style={{ cursor: 'pointer' }}>Help</span>
                                            </div>
                                        </div>
                                        <div className='signOutButton'>
                                            <EirButton
                                                variants='primaryButtonMedium'
                                                style={{ padding: '0.5rem 1.8rem' }}
                                                // className='sign-out-button'
                                                onClick={() => {
                                                    sessionStorage.clear();
                                                    clearAllCookies();
                                                    window.location.href = '/';
                                                }}
                                            >
                                                Sign out
                                                {/* <ArrowForwardIcon className='sign-out-arrow' /> */}
                                            </EirButton>
                                        </div>
                                    </div>
                                </ModalBody>
                            </Modal>
                        </React.Fragment>
                    </div>
                )}
            </div>
        </div>
    );
}
export default MobileHeaderRedesign;
