import React from 'react';
import { Box, Typography } from '@material-ui/core';
import useStyles from './myServicesStyles';
import horizontalCurve from '../images/iconCurve.png';
import verticalCurve from '../images/verticalCurve.png';
import { useWindowDimension } from '../../../hooks/windowDimensions';

const ServicesIcon = ({ icon, numCards }) => {
    const classes = useStyles();
    const [width, height] = useWindowDimension();

    return (
        <Box>
            {width < 600 ? (
                <Box className={icon ? classes.horizontalCurve : classes.none}>
                    {icon && (
                        <Box className={classes.curve}>
                            <Box component='img' src={horizontalCurve} alt='img' />

                            <Box className={numCards == 1 ? classes.verticalIcon : classes.imgIcon}>
                                <Box component='img' src={icon} alt='img' />
                            </Box>

                            <Box className={classes.imgIcon}>
                                <Box component='img' src={icon} alt='img' />
                            </Box>
                        </Box>
                    )}
                </Box>
            ) : (
                <Box
                    className={
                        icon
                            ? numCards == 1
                                ? classes.verticalCurve
                                : classes.horizontalCurve
                            : classes.none
                    }
                >
                    {icon && (
                        <Box className={classes.curve}>
                            <Box component='img' src={numCards == 1 ?verticalCurve:horizontalCurve} alt='img' />
                            
                                <Box
                                    className={
                                        numCards == 1 ? classes.verticalIcon : classes.imgIcon
                                    }
                                >
                                    <Box component='img' src={icon} alt='img' />
                                </Box>
                           
                        </Box>
                    )}
                </Box>
            )}
        </Box>
    );
};
export default ServicesIcon;
