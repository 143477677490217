import { getNameAndAccountID } from '../../getNameAndAccountNumber';
import {
    getDpEligibilityAndAdressInfoApi,
    getRecomendedOffersApi,
    getRecomendedOffersEligibityApi,
    getRecomendedOffersProspect,
} from './recomendedOffers.api';

/**
 * @name getRecomendedOffersEligibity - to check whether user is eligible for cross sell offer
 * @param {GraphQL} Obj.graphql - appoloclient graphql constant
 * @param {String} Obj.parentSubscriptionId - subscription id of the broadband for tv
 * @param {useState} Obj.setOpenError - useState function which sets error modal
 * @param {useState} Obj.openError - useState function which handles error modal
 * @param {useState} Obj.setCheckOffersEligibity - useState function which sets offer
 * @returns
 */
export const getRecomendedOffersEligibity = async ({
    graphql,
    setCheckOffersEligibity,
    parentSubscriptionId,
    setOpenError,
    reference = '',
    openError,
}) => {
    try {
        const token = sessionStorage.getItem('access_token');
        let accountId = parseInt(sessionStorage.getItem('LoggedinUserAccountID'));
        if (!accountId) {
            await getNameAndAccountID(graphql);
            accountId = sessionStorage.getItem('LoggedinUserAccountID');
        }
        if (token && accountId) {
            const response = await getRecomendedOffersEligibityApi({
                graphql,
                token,
                accountId,
                reference,
                parentSubscriptionId,
            });
            setCheckOffersEligibity(response.data.checkCrossSellEligibility);
            return response;
        }
    } catch (err) {
        console.error('Error in fetching eligibity : ', err.message);
        setOpenError(!openError);
    }
};

export const getRecomendedOffers = async ({
    graphql,
    recomemdedOffer,
    setGetOffers,
    setLoading,
}) => {
    try {
        const subscriptions = JSON.parse(
            sessionStorage.getItem('LoggedinUserSubscriptionIDandAccountID')
        );
        if (subscriptions.length > 0) {
            if (recomemdedOffer.length === 0) {
                setLoading(false);
            } else {
                setLoading(true);
                for (let i = 0; i < subscriptions.length; i++) {
                    const serviceGroupCode = subscriptions[i]?.serviceGroup;
                    if (recomemdedOffer.length > 0) {
                        let TariffCodes = [];
                        for (let j = 0; j < recomemdedOffer.length; j++) {
                            const catalogTariffCode = recomemdedOffer[j]?.tariff_code;
                            TariffCodes.push(catalogTariffCode);
                        }
                        try {
                            const data = await getRecomendedOffersApi({
                                graphql,
                                catalogTariffCode: TariffCodes,
                                serviceGroupCode,
                            });

                            setGetOffers(data.getTariffs.tariffs);
                        } catch {
                            setLoading(false);
                        }
                    } else {
                        setGetOffers([]);
                    }
                }
            }
        } else {
            setLoading(false);
        }
    } catch (error) {
        console.error('error in fetching Recomededed offers ', error.message);
        setLoading(false);
    }
};

/**
 * @name getRecomendedOffersProspectUtil - to create cross sell offer
 * @param {GraphQL} Obj.graphql - appoloclient graphql constant
 * @param {useState} Obj.setOpenError - useState function which sets error modal
 * @param {useState} Obj.openError - useState function which handles error modal
 * @param {useState} Obj.setIsModalOpen - useState function which sets offer modal
 * @returns
 */
export const getRecomendedOffersProspectUtil = async ({
    graphql,
    setOpenError,
    openError,
    setIsModalOpen,
}) => {
    try {
        const response = await getRecomendedOffersProspect({ graphql });
        return response;
    } catch (err) {
        console.error('Error in fetching eligibity : ', err.message);
        setIsModalOpen(false);
        setOpenError(!openError);
    }
};

export const getDpEligibilityAndAdressInfo = async ({ graphql, setCsTvData }) => {
    try {
        const token = sessionStorage.getItem('access_token');
        let accountId = sessionStorage.getItem('LoggedinUserAccountID');
        if (!accountId) {
            await getNameAndAccountID(graphql);
            accountId = sessionStorage.getItem('LoggedinUserAccountID');
        }
        if (token && accountId) {
            const response = await getDpEligibilityAndAdressInfoApi({
                graphql,
                token,
                accountId,
            });
            setCsTvData(response.data);
            return response;
        }
    } catch (err) {
        console.error('Error in fetching CS TV eligibity : ', err.message);
    }
};
