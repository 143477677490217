import { makeStyles } from '@material-ui/core/styles';
export const useEmailConfirmationModal = makeStyles(theme => ({
    notRegisteredModal: {
        background: theme.palette.primary.white,
        borderRadius: '10px',
        margin: '0 auto',
        padding: '32px',
        paddingTop: '0px',
        [theme.breakpoints.down('xs')]: {
            // left: '0',
            // right: '0',
            padding: '16px',
        },
        '@media(min-width:600px) and (max-width:1019px)': {
            padding: '16px',
        },
    },
    notRegisteredTitle: {
        fontFamily: theme.typography.body3Medium.fontFamily,
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '24px',
        lineHeight: '26px',
        textAlign: 'center',
        color: theme.palette.primary.heather,
        padding: '51px 32px 0px 32px',
        [theme.breakpoints.down('xs')]: {
            fontSize: theme.typography.h4.fontSize,
            padding: '35px 28px 0px 28px',
        },
        '@media(min-width:600px) and (max-width:1019px)': {
            padding: '35px 28px 0px 28px',
        },
    },
    notRegisteredTitleHeight: {
        fontFamily: theme.typography.body3Medium.fontFamily,
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '24px',
        lineHeight: '26px',
        textAlign: 'center',
        color: theme.palette.primary.heather,
        padding: '51px 32px 0px 32px',
        [theme.breakpoints.down('xs')]: {
            fontSize: theme.typography.h4.fontSize,
            padding: '35px 28px 0px 28px',
        },
        '@media(min-width:600px) and (max-width:1019px)': {
            padding: '35px 28px 0px 28px',
        },
    },
    notRegisteredBorder: {
        paddingBottom: '14px',
        borderBottom: `1px solid ${theme.palette.primary.borderColor}`,
    },
    notRegisteredImage: {
        margin: '0 auto',
        paddingTop: '50.4px',
        display: 'flex',
        justifyContent: 'center',
        width: '59px',
        [theme.breakpoints.down('xs')]: {
            paddingTop: '54.4px',
        },
        '@media(min-width:600px) and (max-width:1019px)': {
            paddingTop: '50.4px',
        },
    },
    notRegisteredImageHeight: {
        margin: '0 auto',
        paddingTop: '20.4px',
        display: 'flex',
        justifyContent: 'center',
        width: '59px',
        [theme.breakpoints.down('xs')]: {
            paddingTop: '20.4px',
        },
        '@media(min-width:600px) and (max-width:1019px)': {
            paddingTop: '20.4px',
        },
    },
    notRegisteredComplete: {
        fontFamily: theme.typography.h5.fontFamily,
        fontStyle: 'normal',
        // fontWeight: 'bold',
        fontSize: theme.typography.h5.fontSize,
        lineHeight: '26px',
        textAlign: 'center',
        color: theme.palette.primary.heather,
        width: '45%',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '18.4px',
        [theme.breakpoints.down('xs')]: {
            // marginTop: '27px',
            fontSize: theme.typography.h4.fontSize,
            width: '78%',
        },
        '@media(min-width:600px) and (max-width:1019px)': {
            // marginTop: '18.4px',
            width: '52%',
        },
    },
    notRegisteredCompleteHeight: {
        fontFamily: theme.typography.h5.fontFamily,
        fontStyle: 'normal',
        // fontWeight: 'bold',
        fontSize: theme.typography.h5.fontSize,
        lineHeight: '26px',
        textAlign: 'center',
        color: theme.palette.primary.heather,
        width: '45%',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '8.4px',
        [theme.breakpoints.down('xs')]: {
            // marginTop: '27px',
            fontSize: theme.typography.h4.fontSize,
            width: '78%',
        },
        '@media(min-width:600px) and (max-width:1019px)': {
            // marginTop: '18.4px',
            width: '52%',
        },
    },

    notRegisteredProcess: {
        fontFamily: theme.typography.body2.fontFamily,
        fontWeight: '400',
        fontSize: theme.typography.body1.fontSize,
        lineHeight: '25px',
        color: theme.palette.primary.heather,
        textAlign: 'center',
        width: '60%',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '1rem',
        [theme.breakpoints.down('xs')]: {
            marginTop: '2rem',
            fontSize: theme.typography.subtitle2.fontSize,
            width: '78%',
        },
        '@media(min-width:600px) and (max-width:1019px)': {
            marginTop: '2rem',
            width: '56%',
        },
    },
    notRegisteredProcessHeight: {
        fontFamily: theme.typography.body2.fontFamily,
        fontWeight: '400',
        fontSize: theme.typography.body1.fontSize,
        lineHeight: '25px',
        color: theme.palette.primary.heather,
        textAlign: 'center',
        width: '60%',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '1rem',
        [theme.breakpoints.down('xs')]: {
            marginTop: '1rem',
            fontSize: theme.typography.subtitle2.fontSize,
            width: '78%',
        },
        '@media(min-width:600px) and (max-width:1019px)': {
            marginTop: '1rem',
            width: '56%',
        },
    },
    notRegisteredEmail: {
        fontFamily: theme.typography.subtitle1.fontFamily,
        // fontWeight: '700',
        fontSize: theme.typography.body1.fontSize,
        lineHeight: '25px',
        color: theme.palette.primary.heather,
        textAlign: 'center',
        width: '45%',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '24px',
        [theme.breakpoints.down('xs')]: {
            // marginTop: '16px',
            fontSize: theme.typography.subtitle2.fontSize,
            width: '66%',
            minWidth: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        '@media(min-width:600px) and (max-width:1019px)': {
            // marginTop: '1.5rem',
            width: '66%',
        },
    },
    notRegisteredEmailHeight: {
        fontFamily: theme.typography.subtitle1.fontFamily,
        // fontWeight: '700',
        fontSize: theme.typography.body1.fontSize,
        lineHeight: '25px',
        color: theme.palette.primary.heather,
        textAlign: 'center',
        width: '45%',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '14px',
        [theme.breakpoints.down('xs')]: {
            // marginTop: '16px',
            fontSize: theme.typography.subtitle2.fontSize,
            width: '66%',
            minWidth: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        '@media(min-width:600px) and (max-width:1019px)': {
            // marginTop: '1.5rem',
            width: '66%',
        },
    },
    notRegisteredEmailCopy: {
        fontFamily: theme.typography.body2.fontFamily,
        fontWeight: '400',
    },
    notRegisteredSpamEmail: {
        fontFamily: theme.typography.body2.fontFamily,
        fontWeight: '400',
        fontSize: theme.typography.body1.fontSize,
        lineHeight: '25px',
        color: theme.palette.primary.heather,
        textAlign: 'center',
        marginTop: '24px',
        [theme.breakpoints.down('xs')]: {
            // marginTop: '16px',
            fontSize: theme.typography.subtitle2.fontSize,
        },
        '@media(min-width:600px) and (max-width:1019px)': {
            // marginTop: '16px',
        },
    },
    notRegisteredSpamEmailHeight: {
        fontFamily: theme.typography.body2.fontFamily,
        fontWeight: '400',
        fontSize: theme.typography.body1.fontSize,
        lineHeight: '25px',
        color: theme.palette.primary.heather,
        textAlign: 'center',
        marginTop: '14px',
        [theme.breakpoints.down('xs')]: {
            // marginTop: '16px',
            fontSize: theme.typography.subtitle2.fontSize,
        },
        '@media(min-width:600px) and (max-width:1019px)': {
            // marginTop: '16px',
        },
    },

    notRegisteredButtonClose: {
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        paddingTop: '3.5rem',
        paddingBottom: '3.6rem',

        [theme.breakpoints.down('xs')]: {
            paddingBottom: '65px',
            paddingTop: '82px',
            position: 'absolute',
            bottom: '0',
            width: '100%',
            left: '0',
        },
        '@media(min-width:600px) and (max-width:1019px)': {
            padding: '48px 40px 52px 40px',
        },
    },
    notRegisteredButtonCloseHeight: {
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        paddingTop: '3.5rem',
        paddingBottom: '3.6rem',

        [theme.breakpoints.down('xs')]: {
            paddingBottom: '65px',
            paddingTop: '82px',
            position: 'absolute',
            bottom: '-40px',
            width: '100%',
            left: '0',
        },
        '@media(min-width:600px) and (max-width:1019px)': {
            padding: '48px 40px 52px 40px',
        },
    },
    notRegisteredButton: {
        background: theme.palette.primary.sunset,
        boxShadow: `0px 3px 1px -2px ${theme.palette.primary.boxShadow2Dark}, 0px 2px 2px ${theme.palette.primary.boxShadow2Dark}, 0px 1px 5px ${theme.palette.primary.boxShadow2Dark}`,
        borderRadius: '6px',
        padding: '8px 70px',
        fontFamily: theme.typography.body3Medium.fontFamily,
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: theme.typography.body1.fontSize,
        lineHeight: '24px',
        color: theme.palette.primary.white,
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        textTransform: 'capitalize',
        '&:hover': {
            outline: 'none',
            backgroundColor: theme.palette.primary.sunset30,
        },
        [theme.breakpoints.down('xs')]: {
            padding: '8px 110px',
        },
        '@media(min-width:600px) and (max-width:1019px)': {
            padding: '8px 110px',
        },
    },
    notRegisteredFooter: {
        paddingBottom: '62px',

        '@media (min-width: 0px) and (max-width: 1019px)': {
            paddingBottom: '32px',
        },
    },
    notRegistredMain: {
        marginLeft: 'auto',
        marginRight: 'auto',
        // marginTop: '100px',
        padding: '0 30px',
        maxWidth: '820px',

        '@media(min-width:600px) and (max-width:1019px)': {
            padding: '0 99px',
            maxWidth: '858px',
        },
        '@media(max-width: 599px)': {
            margin: '8px 8px 0 8px',
            padding: '0',
            // width: 'auto',
            height: '100%',
        },
    },
    notRegistredMainWrapper: {
        background: 'white',
    },
    notRegistredMainModal: {
        background: theme.palette.primary.gray550,
        backdropFilter: 'blur(4.5px)',
        '@media(min-width: 1020px)': {
            // overflowY: 'hidden !important',
        },
    },
    notRegistredMainContent: {
        border: 'none',
        '@media(max-width: 599px)': {
            height: '100%',
        },
        '@media(min-width: 1020px) and (max-width: 1439px)': {
            // transform: 'scale(0.75)',
            // bottom: '11rem',
        },
        '@media(min-width: 1440px)': {
            // top: '100px',
            // bottom: '0px',
        },
        '@media(min-width:600px) and (max-width:1019px)': {
            // transform: 'scale(0.8)',
            // bottom: '30px',
        },
    },
    notRegisteredWrapper: {
        padding: '0px',
    },
}));
