import React, { useState, useEffect } from 'react';
import { useEmailConfirmationModal } from '../css/useEmailConfirmationModal';
import { Button, Typography } from '@material-ui/core';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import smileyIcon from '../images/smileyIcon.png';
import MyEirCreatePasswordFormPage from '../my-eir-createPassword/MyEirCreatePasswordFormPage';
import EirButton from '../../theme/EirButton';
import AlertOfForgotPassword from '../../mobilecomponents/my-login/AlertOfForgotPassword';
import { useWindowDimension } from '../../hooks/windowDimensions';

function MyEirEmailConfirmationModal(props) {
    const classes = useEmailConfirmationModal();
    const [resetScreen, setResetScreen] = useState(false);
    const [width, height] = useWindowDimension();
    // const registeredEmail = props.email;
    // const [isopen, setIsOpen] = useState(false);
    // const toggle = () => setIsOpen(!isopen);

    const handleClose = () => {
        window.location.href = '/';
    };

    return (
        <React.Fragment>
            {/* <div className={classes.notRegisteredFooter} onClick={toggle}>
                <button className={'primary-cta footer-cta'}>click here to view the modal</button>
            </div> */}
            <Modal
                className={classes.notRegistredMain}
                wrapClassName={classes.notRegistredMainWrapper}
                modalClassName={classes.notRegistredMainModal}
                backdropClassName={classes.notRegistredMainBackDrop}
                contentClassName={classes.notRegistredMainContent}
                isOpen={props.isOpen}
                toggle={props.toggle}
                modalTransition={{ timeout: 300 }}
                centered
            >
                <ModalBody className={classes.notRegisteredWrapper}>
                    <div className={classes.notRegisteredModal}>
                        <div className={classes.notRegisteredBorder}>
                            <div className={classes.notRegisteredTitle}>{props.heading}</div>
                        </div>
                        {props.flag === 'security' && (
                            <div className='notice'>
                                <AlertOfForgotPassword
                                    email={
                                        window.location.href.indexOf('/forgot-password') > -1
                                            ? props.emailAddress
                                            : props.registeredEmail
                                    }
                                />
                            </div>
                        )}
                        <img
                            className={
                                props.flag === 'security' && height < 690
                                    ? classes.notRegisteredImageHeight
                                    : classes.notRegisteredImage
                            }
                            src={smileyIcon}
                            alt='smileyIcon'
                        />
                        <Typography
                            variant='subtitle2'
                            className={
                                props.flag === 'security' && height < 690
                                    ? classes.notRegisteredCompleteHeight
                                    : classes.notRegisteredComplete
                            }
                        >
                            {props.subHeading}
                        </Typography>
                        <div
                            className='dummyModal'
                            style={{
                                display: 'flex',
                                flexDirection: `${props.dummyUser ? 'column-reverse' : 'column'}`,
                            }}
                        >
                            <div
                                className={
                                    props.flag === 'security' && height < 690
                                        ? classes.notRegisteredProcessHeight
                                        : classes.notRegisteredProcess
                                }
                            >
                                {props.text}
                            </div>
                            <div
                                className={
                                    props.flag === 'security' && height < 690
                                        ? classes.notRegisteredEmailHeight
                                        : classes.notRegisteredEmail
                                }
                            >
                                An email has been sent to
                                <div className={classes.notRegisteredEmailCopy}>
                                    {/* {props.registeredEmail+"test"}  */}
                                    {window.location.href.indexOf('/forgot-password') > -1
                                        ? props.emailAddress
                                        : props.registeredEmail}
                                </div>
                            </div>
                        </div>
                        {props.dummyUser ? (
                            <div className={classes.notRegisteredProcess}>
                                After you’ve verified your email please go through the my eir
                                registration process to set your password for my eir.
                            </div>
                        ) : (
                            ''
                        )}
                        <div
                            className={
                                props.flag === 'security' && height < 690
                                    ? classes.notRegisteredSpamEmailHeight
                                    : classes.notRegisteredSpamEmail
                            }
                        >
                            Don't forget to check spam email!
                        </div>

                        <div
                            className={
                                props.flag === 'security' && width < 340
                                    ? classes.notRegisteredButtonCloseHeight
                                    : classes.notRegisteredButtonClose
                            }
                        >
                            <EirButton
                                variants='primaryButtonMedium'
                                style={{ padding: '0.5rem 2.8rem' }}
                                // className={classes.notRegisteredButton}
                                onClick={handleClose}
                            >
                                Close
                            </EirButton>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
            {resetScreen && <MyEirCreatePasswordFormPage />}
        </React.Fragment>
    );
}

export default MyEirEmailConfirmationModal;
