import { gql } from '@apollo/client';

const ENDPOINT = {
    GET_LOGIN_OPERATION: gql`
        query login(
            $email: String!
            $password: String!
            $recaptchaVersion: RecaptchaVersion!
            $recaptchaToken: String!
            $brand: BillingSystem
        ) {
            login(
                email: $email
                password: $password
                recaptchaVersion: $recaptchaVersion
                recaptchaToken: $recaptchaToken
                brand: $brand
            ) {
                accessToken
                accessTokenExpiresIn
                refreshToken
                refreshTokenExpiresIn
                statusCode
                passwordResetToken
                numberOfAuthenticationAttemptsRemaining
                isION
                isR6
            }
        }
    `,
    RESET_PASSWORD: gql`
        mutation resetPassword($emailAddress: String!, $brand: BillingSystem) {
            resetPassword(emailAddress: $emailAddress, brand: $brand) {
                message
            }
        }
    `,

    GET_ACCOUNT_TYPE: gql`
        query accountType($token: String!) {
            accountType(token: $token) {
                postpay
                prepay
            }
        }
    `,
};

/**
 * @name getLoginOperation - To authenticate users
 * @param {Object} Obj - attributes for the function
 * @param {GraphQL} Obj.graphql - appoloclient graphql constant
 * @param {GraphQL} graphql - appoloclient graphql constant
 * @returns
 */
export const getLoginOperation = async ({
    graphql,
    email,
    password,
    recaptcha,
    recaptchaVersion,
}) => {
    try {
        const data = await graphql.query({
            query: ENDPOINT.GET_LOGIN_OPERATION,
            variables: {
                email,
                password,
                recaptchaToken: recaptcha,
                recaptchaVersion,
            },
        });

        return data;
    } catch (error) {
        throw new Error(error);
    }
};

/**
 * @name resetPassword - To reset users password
 * @param {Object} Obj - attributes for the function
 * @param {GraphQL} Obj.graphql - appoloclient graphql constant
 * @param {GraphQL} graphql - appoloclient graphql constant
 * @returns
 */
export const resetPassword = async ({ graphql, emailAddress, brand }) => {
    try {
        const variables = {
            emailAddress,
        };
        if (brand) {
            variables.brand = brand;
        }
        const res = await graphql.mutate({
            mutation: ENDPOINT.RESET_PASSWORD,
            variables,
        });

        return res;
    } catch (error) {
        throw new Error(error);
    }
};

export const getAccountType = async ({ graphql, token }) => {
    const { data } = await graphql.query({
        query: ENDPOINT.GET_ACCOUNT_TYPE,
        variables: {
            token,
        },
    });
    return data;
};
