import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useStyles } from './styles';
const MyEirInactiveUser = ({ emptyIcon, title, description, flag }) => {
    const classes = useStyles();
    return (
        <>
            <Box className={flag && flag === 'bills' ?classes.inactiveBill :classes.inactiveBillUserContainer}>
                <Box className={classes.container}>
                    <Box
                        className={
                            flag && flag === 'bills' ? classes.imgIconBills : classes.imgIcon
                        }
                    >
                        <Box
                            className={
                                flag && flag === 'bills'
                                    ? classes.emptyIconBills
                                    : classes.emptyIcon
                            }
                            component='img'
                            src={emptyIcon}
                            alt='img'
                        />
                    </Box>
                    <Typography
                        className={flag && flag === 'bills' ? classes.titleBills : classes.title}
                    >
                        {' '}
                        {title}{' '}
                    </Typography>
                    <Typography
                        className={
                            flag && flag === 'bills'
                                ? classes.descriptionBills
                                : classes.description
                        }
                    >
                        {description}
                    </Typography>
                </Box>
            </Box>
        </>
    );
};
export default MyEirInactiveUser;
