import React, { useEffect, useState } from 'react';
import useStyles from './navbarStyles';
import { Box, Typography } from '@material-ui/core';
import { useWindowDimension } from '../../../hooks/windowDimensions';
import {
    BillsIcon,
    ServicesIcon,
    HomesIcon,
    OffersIcon,
    HelpIcon,
} from '../../../sharedcomponents/inputs/svg-icons/CustomSvgIcons';
import MyEirNavItems from './MyEirNavItems';
import { useHistory } from 'react-router-dom';
import { getAdobeOffers } from '../my-services/myServices.util';
import { useApolloClient } from '@apollo/client';
import theme from '../../../theme/MyEirTheme';

const MyEirNavigationBarContainer = () => {
    const classes = useStyles();
    const [width, height] = useWindowDimension();
    const [isShowNav, setIsShowNav] = useState(false);
    const [selectedNav, setSelectedNav] = useState('');
    const [offersVal, setOffersVal] = useState([]);
    const [count, setCount] = useState(0);
    const graphql = useApolloClient();
    const history = useHistory();

    const hideNavbar = () => {
        if (
            window.location.href.indexOf('/account/dashboard') > -1 ||
            window.location.href.indexOf('/account/payments') > -1 ||
            window.location.href.indexOf('/account/offers') > -1 ||
            window.location.href.indexOf('/account/bills') > -1
        ) {
            setIsShowNav(true);
        } else {
            setIsShowNav(false);
        }
    };

    const allNavOptions = [
        {
            id: 1,
            name: 'Home',
            icon: active => (
                <HomesIcon
                    stroke1={
                        active
                            ? theme.palette.primary.heather
                            : theme.palette.primary.backgroundColor2
                    }
                    stroke1width={active ? '0px' : '1px'}
                    stroke1opacity={active ? '1' : '0.6'}
                    fill1={
                        active
                            ? theme.palette.primary.heather
                            : theme.palette.primary.backgroundColor2
                    }
                />
            ),
        },
        {
            id: 2,
            name: 'Bills',
            icon: active => (
                <BillsIcon
                    stroke1={
                        active
                            ? theme.palette.primary.heather
                            : theme.palette.primary.backgroundColor2
                    }
                    fill1={
                        active
                            ? theme.palette.primary.heather
                            : theme.palette.primary.backgroundColor2
                    }
                    stroke2={
                        active
                            ? theme.palette.primary.heather
                            : theme.palette.primary.backgroundColor
                    }
                    style={{
                        marginRight: active ? '2px' : '',
                    }}
                />
            ),
        },
        {
            id: 3,
            name: 'Services',
            icon: active => (
                <ServicesIcon
                    stroke1={
                        active
                            ? theme.palette.primary.heather
                            : theme.palette.primary.backgroundColor2
                    }
                    stroke1width={'1px'}
                    stroke1opacity={'1px'}
                    fill1={
                        active
                            ? theme.palette.primary.heather
                            : theme.palette.primary.backgroundColor2
                    }
                />
            ),
        },

        {
            id: 4,
            name: 'Offers',
            badge: true,
            badgeContent: count,
            badgeColor: theme.palette.primary.white,
            icon: active => (
                <OffersIcon
                    stroke3={
                        active
                            ? theme.palette.primary.backgroundColor2
                            : theme.palette.primary.heather
                    }
                    stroke1={
                        active
                            ? theme.palette.primary.backgroundColor2
                            : theme.palette.primary.heather
                    }
                    stroke1width={'1px'}
                    stroke3Width={'1px'}
                    stroke2Width={'1px'}
                    stroke3Opacity={active ? '1' : '0.5'}
                    stroke1opacity={active ? '1' : '0.2'}
                    stroke2Opacity={active ? '1' : '0.5'}
                    stroke2={
                        active
                            ? theme.palette.primary.heather
                            : theme.palette.primary.backgroundColor2
                    }
                    fill2={
                        active
                            ? theme.palette.primary.heather
                            : theme.palette.primary.backgroundColor2
                    }
                    fill1={
                        active
                            ? theme.palette.primary.heather
                            : theme.palette.primary.backgroundColor2
                    }
                />
            ),
        },
        {
            id: 5,
            name: 'Help',
            icon: active => (
                <HelpIcon
                    stroke1={
                        active
                            ? theme.palette.primary.heather
                            : theme.palette.primary.backgroundColor2
                    }
                    fill1={
                        active
                            ? theme.palette.primary.heather
                            : theme.palette.primary.backgroundColor2
                    }
                    stroke1opacity={active ? '1' : '0.6'}
                    stroke1width={'1px'}
                    stroke2={
                        active
                            ? theme.palette.primary.heather
                            : theme.palette.primary.backgroundColor2
                    }
                    stroke2Opacity={active ? '1' : '0.6'}
                    stroke2Width={'1px'}
                />
            ),
        },
    ];

    const getAdobeOffersUtil = async () => {
        const res = await getAdobeOffers({ graphql });

        if (res) {
            window.sessionStorage.setItem('offers-nav', JSON.stringify(res));
            setOffersVal(res);
        } else {
            window.sessionStorage.removeItem('offers-nav');
            setOffersVal([]);
        }
    };

    const getOffersNotification = async () => {
        const leastRanks = {};
        const recommendedOfferCount = offersVal?.recommendedOffers?.length;
        offersVal?.availableOffers?.forEach(item => {
            const { subscriptionID, rank } = item;
            if (!(subscriptionID in leastRanks) || rank < leastRanks[subscriptionID]) {
                leastRanks[subscriptionID] = rank;
            }
        });

        const groupArray = Object.entries(leastRanks).map(([subscriptionID, rank]) => ({
            subscriptionID: parseInt(subscriptionID),
            rank,
        }));
        const totalOffersCount = recommendedOfferCount + groupArray?.length;

        if (totalOffersCount) setCount(totalOffersCount);
        else setCount('');
    };

    useEffect(() => {
        getOffersNotification();
    }, [offersVal]);
    const navNumbers = offersVal.length === 0 ? 4 : 5;

    useEffect(() => {
        hideNavbar();
        if (!window.sessionStorage.getItem('offers-nav')) {
            getAdobeOffersUtil();
        } else {
            setOffersVal(JSON.parse(window.sessionStorage.getItem('offers-nav') || '{}'));
        }
    }, []);

    return (
        width < 1020 &&
        isShowNav && (
            <Box className={classes.mainNavContainer}>
                <Box className={classes.innerNavbar}>
                    {allNavOptions.map((item, i) => (
                        <MyEirNavItems
                            item={item}
                            selectedNav={selectedNav}
                            setSelectedNav={setSelectedNav}
                            offersVal={offersVal}
                            navNumbers={navNumbers}
                        />
                    ))}
                </Box>
            </Box>
        )
    );
};

export default MyEirNavigationBarContainer;
