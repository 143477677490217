import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Grid, Typography } from '@material-ui/core';
import { useWindowDimension } from '../../../hooks/windowDimensions';
import { myBalanceStyles } from './myBalanceStyles';
import theme from '../../../theme/MyEirTheme';
import {
    BillsIcon,
    PaymentCardIcon,
    UsageIcon,
} from '../../../sharedcomponents/inputs/svg-icons/CustomSvgIcons';
import SeeCurrentUsage from '../current-usage/SeeCurrentUsage';
import { triggerAdobeApi } from '../redesign.api';
import { useApolloClient } from '@apollo/client';

const MyEirBalanceButtons = ({ flag }) => {
    const history = useHistory();
    const classes = myBalanceStyles();
    const [width, height] = useWindowDimension();
    const [isopen, setIsOpen] = useState(false);
    const graphql = useApolloClient();

    const handleRouting = path => {
        history.push(path);
    };

    const handleMyUsage = async () => {
        setIsOpen(!isopen);
        const usagefetch = await triggerAdobeApi({ graphql, name: 'usage' });
    };

    const handleMyBills = () => {
        handleRouting('/account/bills');
    };

    const buttonsHTML = ({ Icon, text, clickFunc }) => {
        return (
            <Grid
                item
                xs={6}
                md={width >= 1020 ? 4 : 6}
                // md={4}
                lg={width >= 1020 ? 4 : 6}
                className={classes.balanceButtonsGridItem}
                onClick={clickFunc}
            >
                <Box className={classes.balanceButtonsGridItemBox}>
                    <Icon
                        className={classes.balanceButtonsGridItemIcon}
                        stroke1={theme.palette.primary.backgroundColor2}
                        fill1='transparent'
                        stroke2={theme.palette.primary.backgroundColor2}
                        fill2='transparent'
                    />
                    <Typography className={classes.balanceButtonsGridItemText}>{text}</Typography>
                </Box>
            </Grid>
        );
    };

    return (
        <Box
            className={
                flag && flag === 'bills'
                    ? classes.balanceButtonsContainerBills
                    : classes.balanceButtonsContainer
            }
        >
            <Grid container className={classes.balanceButtonsGridContainer}>
                {buttonsHTML({
                    Icon: PaymentCardIcon,
                    text: 'My payments',
                    clickFunc: () => {
                        handleRouting('/account/payments');
                    },
                })}
                {width >= 1020 &&
                    buttonsHTML({
                        Icon: BillsIcon,
                        text: 'My bills',
                        clickFunc: () => {
                            handleMyBills();
                        },
                    })}
                {buttonsHTML({
                    Icon: UsageIcon,
                    text: 'My usage',
                    clickFunc: () => {
                        handleMyUsage();
                    },
                })}
            </Grid>
            <Box className={classes.balanceButtonsSeperatorLine}></Box>
            {isopen ? <SeeCurrentUsage isopen={isopen} setIsOpen={setIsOpen} /> : ' '}
        </Box>
    );
};

export default MyEirBalanceButtons;
