import { gql } from '@apollo/client';

const ENDPOINT = {
    GET_AMAZON_PRIME_CONTENTS: gql`
        query getAmazonPrimeContents($customerType: String!) {
            getAmazonPrimeContents(customerType: $customerType) {
                customerType
                desktopBanner {
                    imageAtlText
                    imageURL
                }
                mobileBanner {
                    imageAtlText
                    imageURL
                }
                marketingText
                marketingTextColor
                additionalInfo
                additionalInfoDesktopBanner {
                    imageAtlText
                    imageURL
                }
                additionalInfoMobileBanner {
                    imageAtlText
                    imageURL
                }
                overlayText
                overlayTextColor
            }
        }
    `,
    GET_APV_TV_SERVICE_ID: gql`
        query getTVServiceId($account: String!, $token: String!) {
            getTVServiceId(account: $account, token: $token) {
                serviceId
                hasTV
                hasAPV
                addonId
            }
        }
    `,
    CHECK_APV_ADDON_ELIGIBILITY: gql`
        mutation amazonPrimeMutation($serviceId: String!, $token: String!, $channelCode: String!) {
            isEligibleForApv(serviceId: $serviceId, token: $token, channelCode: $channelCode) {
                status
                statusCode
                offerCode
            }
        }
    `,
};

/**
 * @name getAmazonContentsApi - gets contents to be displayed in amazon cards from ocat
 * @param {Object} Obj - attributes for the function
 * @param {GraphQL} Obj.graphql - appoloclient graphql constant
 * @param {String} Obj.customerType - eir customer type - TV or Broadband
 * @param {GraphQL} graphql - appoloclient graphql constant
 * @param {String} customerType - eir customer type - TV or Broadband
 * @returns {AmazonContentsObject} - amazon cards data from ocat
 */
export const getAmazonContentsApi = async ({ graphql, customerType }) => {
    try {
        const { data } = await graphql.query({
            query: ENDPOINT.GET_AMAZON_PRIME_CONTENTS,
            variables: {
                customerType,
            },
        });

        return data;
    } catch (error) {
        throw new Error(error);
    }
};

/**
 * @name getApvTVServiceIdApi - gets TV service id for logged in eir account id
 * @param {Object} Obj - attributes for the function
 * @param {GraphQL} Obj.graphql - appoloclient graphql constant
 * @param {GraphQL} graphql - appoloclient graphql constant
 * @returns {Number} - TVServiceId
 */
export async function getApvTVServiceIdApi({ graphql }) {
    try {
        if (
            sessionStorage.getItem('LoggedinUserAccountNumber') == undefined ||
            sessionStorage.getItem('LoggedinUserAccountNumber') == ''
        ) {
            const accountIDResponse = await getNameAndAccountID(graphql);
        }

        const { data } = await graphql.query({
            query: ENDPOINT.GET_APV_TV_SERVICE_ID,
            variables: {
                account: sessionStorage.getItem('LoggedinUserAccountNumber'),
                token: sessionStorage.getItem('access_token'),
            },
        });
        if (data.getTVServiceId) {
            return data.getTVServiceId;
        } else {
            throw 'something went wrong while fetching getApvTVServiceId';
        }
    } catch (err) {
        console.error('Error while fetching getApvTVServiceId', err);
        throw new Error(`${err.code || ''} ${err.message}`);
    }
}

/**
 * @name checkApvEligibilityApi - gets apv eligibility status for a given service id
 * @param {Object} Obj - attributes for the function
 * @param {GraphQL} Obj.graphql - appoloclient graphql constant
 * @param {String} Obj.serviceId - service id for which apv eligibility is being checked
 * @param {GraphQL} graphql - appoloclient graphql constant
 * @param {String} serviceId - service id for which apv eligibility is being checked
 * @returns {ApvEligibilityObject}  - apv eligibilty for passes service id
 */
export async function checkApvEligibilityApi({ graphql, serviceId }) {
    try {
        const { data } = await graphql.mutate({
            mutation: ENDPOINT.CHECK_APV_ADDON_ELIGIBILITY,
            variables: {
                serviceId: `${serviceId}`,
                token: sessionStorage.getItem('access_token'),
                channelCode: 'MYEIR',
            },
        });
        if (data.isEligibleForApv) {
            return data.isEligibleForApv;
        } else {
            throw 'something went wrong while checking apvEligibility';
        }
    } catch (err) {
        console.error('Error while checking apvEligibility', err);
        throw new Error(`${err.code || ''} ${err.message}`);
    }
}
