import { makeStyles } from '@material-ui/core/styles';

export const useDashboardStyle = makeStyles(theme => ({
    paper: {
        marginTop: ' 0px !important',
        marginBottom: theme.spacing(3),
        boxShadow: '0px 3px 25px #00000024 !important',
        borderRadius: '9px 9px 9px 9px !important',
        opacity: '1',
        [theme.breakpoints.up(1000 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(4),
        },
    },
    dialogPaper: {
        overflowY: 'visible !important',
        maxWidth: '500px',
    },
    logoutBtn: {
        borderRadius: '2rem',
        fontSize: 18,
        textTransform: 'initial',
        padding: '8px 48px',
        border: '1px solid #892ba0',
        background: '#fff',
        color: '#892ba0',
        outline: 'none',
        marginRight: '10px',
        '@media (min-width:280px) and (max-width: 320px)': {
            fontSize: 12,
            padding: '6px 24px',
        },
        '@media (min-width:321px) and (max-width: 767px)': {
            padding: '8px 32px',
            fontSize: 16,
        },
        '@media (min-width:768px) and (max-width: 1024px)': {
            padding: '8px 48px',
            fontSize: 16,
        },
        '&:focus,&:hover': {
            outline: 'none',
        },
    },
    dropdownStyle: { borderRadius: '16px' },
    dashboardContainer: {
        '& .height': {
            '@media  (min-width: 1025px)': {
                height: '622px',
            },
            '@media  (min-width: 769px) and (max-width: 1025px)': {
                height: '630px',
            },
            '@media  (min-width: 767px) and (max-width: 768px)': {
                height: '663px',
            },
        },
        '& .noPaddingTop': {
            '@media  (max-width: 767px)': {
                paddingTop: '0px',
            },
        },
        '& .noPaddingBottom': {
            '@media  (max-width: 767px)': {
                paddingBottom: '0px',
            },
        },
        '& .errorText': {
            '@media  (max-width: 767px)': {
                fontSize:'16px !important',
            },
            '@media  (min-width: 769px) and (max-width: 1025px)': {
                fontSize:'18px !important',
            },
            fontFamily:'gt-walsheim-regular !important',
        },
        '& .errorTextPadding': {
            '@media  (max-width: 767px)': {
                paddingTop:30,
                paddingBottom:30,
            },
            // '@media  (min-width: 769px) and (max-width: 1025px)': {
            //     paddingTop:30,
            //     paddingBottom:30,
            // },
            paddingTop:200,
            paddingBottom:180,
        },
        '& .tryAgain': {
            '&:hover': {
                cursor:'pointer',
            },
            '@media  (max-width: 767px)': {
                fontSize:'16px !important',
            },
            '@media  (min-width: 769px) and (max-width: 1025px)': {
                fontSize:'18px !important',
            },
            fontFamily:'gt-walsheim-medium !important',
            color: '#892ba0 !important',
            textDecoration: 'underline',
        },
        '& .myEirBanner': {
            textAlign: 'center',
            marginTop: '18px',
            boxShadow: '0px 3px 25px #00000024',

            '& .UserInfo': {
                padding: '20px',

                backgroundImage:
                    'url("/opencms/export/system/modules/my.portal.opencms/myeir_ion/images/dashboard-header.jpg")',
                backgroundRepeat: 'repeat',
                '@media  (max-width: 767px)': {
                    backgroundImage:
                        'url("/opencms/export/system/modules/my.portal.opencms/myeir_ion/images/dashboard-header-mobile.jpg")',
                    backgroundRepeat: 'no-repeat',
                },
                '& p': {
                    color: '#fff',
                    textAlign: 'left',
                    fontSize: theme.spacing(3.75),
                    fontFamily: 'eir-medium',
                    marginBottom: '4px',
                    '@media  (max-width: 767px)': {
                        fontSize: '20px ',
                        textAlign: 'center !important',
                    },
                    '@media  (max-width: 1024px)': {
                        fontSize: '20px ',
                    },
                },
                '& h1': {
                    color: '#fff',
                    textAlign: 'left',
                    fontSize: theme.spacing(5),
                    fontFamily: 'eir-medium',
                    '@media  (max-width: 767px)': {
                        textAlign: 'center !important',
                    },
                    '@media  (max-width: 1024px)': { fontSize: '32px !important' },
                },
            },
            '& .mobileText': {
                fontSize: '22px !important',
                fontFamily: 'gt-walsheim-medium  !important',
                color: '#470A68!important',
                borderRadius: '29px',
                border: '1px solid #DB12A9;',
                width: '75%',
                marginTop: '30px',
                textAlign: 'center !important',
                background: '#fff',
                marginBottom: '0px',
                padding: theme.spacing(1),
                '@media  (max-width: 768px)': {
                    width: '100%',
                    // marginTop: '0px',
                },
                '@media  (max-width: 767px)': {
                    marginTop: '0px !important',
                },
                '@media  (max-width: 1024px)': { fontSize: '18px !important', marginTop: '20px' },
            },
            '& .MuiInput-underline:before,& .Mui-focused:before': {
                borderBottom: 'transparent',
            },

            '& .arrow-downs ': {
                border: 'solid #470A68',
                borderWidth: '0 3px 3px 0',
                display: 'inline-block',
                padding: '4px',
                transform: 'rotate(45deg)',
                marginLeft: '4px',
                marginBottom: '4px',
            },
        },
        '& .dashboardContentHolder': {
        '& .errorText': {
            '@media  (max-width: 767px)': {
                fontSize:'16px !important',
            },
            '@media  (min-width: 769px) and (max-width: 1025px)': {
                fontSize:'18px !important',
            },
            fontFamily:'gt-walsheim-regular !important',
        },
        '& .tryAgain': {
            '&:hover': {
                cursor:'pointer',
            },
            '@media  (max-width: 767px)': {
                fontSize:'16px !important',
            },
            '@media  (min-width: 769px) and (max-width: 1025px)': {
                fontSize:'18px !important',
            },
            fontFamily:'gt-walsheim-medium !important',
            color: '#892ba0 !important',
            textDecoration: 'underline',
        },
            padding: '3% 16%',
            background: '#F4F5F9',
            '@media  (max-width: 767px)': {
                padding: '9% 0%',
            },
            '@media  (min-width: 768px) and (max-width: 1024px)': {
                padding: '4% 0%',
            },
            '& .purpleHeading': {
                color: '#892BA0',
                fontSize: '24px !important',
                fontFamily: 'gt-walsheim-medium !important',
                '@media  (max-width: 767px)': { fontSize: '20px !important' },
            },
            '& .greenHeading': {
                color: '#00A0BD',
                fontSize: '18px !important',
                float: 'right',
                fontFamily: 'gt-walsheim-medium !important',
                '@media  (max-width: 768px)': { fontSize: '14px !important' },
            },
            '& .myEirPlans': {
                '& .paper': {
                    boxShadow: '0px 3px 15px #00000024 !important',
                    borderRadius: '9px',
                    padding: theme.spacing(3),
                    marginBottom: '16px',
                },
                '& p': {
                    fontSize: '16px',
                    fontFamily: 'gt-walsheim-medium !important',

                    '@media (max-width: 768px)': { fontSize: '14px' },
                },
                '& .planIcon': {
                    color: '#892BA0',
                    fontSize: '16px !important',
                    marginRight: '8px',
                    '@media (max-width: 1024px)': { marginBottom: '9px' },
                },
                '& .dateIcon': {
                    marginBottom: '2px',
                    '@media (max-width: 1024px)': { marginBottom: '9px' },
                },
            },

            '& .myEirDetails': {
                '& .paper': {
                    boxShadow: '0px 3px 15px #00000024 !important',
                    borderRadius: '9px',
                    padding: theme.spacing(1, 2),
                    marginBottom: '29px',
                    height: theme.spacing(6),
                    '@media  (max-width: 767px)': { marginBottom: '20px' },
                    '@media  (min-width: 768px) and (max-width: 998px)': { marginBottom: '40px' },
                    '@media  (min-width: 998px) and (max-width: 1024px)': { marginBottom: '45px' },
                },
                '& .iconUsage': {
                    color: '#892BA0',
                    margin: '5px 10px 10px',
                    '@media  (max-width: 768px)': { margin: '5px 0px 12px ' },
                },
                '& .iconUsagePink': {
                    color: '#DB12A9',
                    margin: '5px 10px 10px',
                    '@media  (max-width: 768px)': { margin: '5px 0px 12px ' },
                },
                '& p': {
                    fontSize: '24px',
                    fontFamily: 'gt-walsheim-regular !important',
                    '@media  (max-width: 281px)': { fontSize: '14px' },
                    '@media (min-width: 281px) and (max-width: 1024px)': { fontSize: '18px' },
                },

                '& a': {
                    '@media  (max-width: 281px)': { fontSize: '13px' },
                },
            },
            '& .iconLayout': {
                '& img': {
                    height: '40px',
                    width: '40px',
                    marginRight: '10px',
                    marginBottom: '10px',
                    padding: '7px',
                    background: '#DB12A9',
                    borderRadius: '100%',
                    '@media  (max-width: 1024px)': {
                        height: '35px',
                        width: '35px',
                    },
                },
            },
            '& .purpleIcon': {
                color: 'purple',
                fontSize: '40px',
                marginBottom: '10px',
            },
            '& .balanceSection': {
                '@media  (min-width: 767px) and (max-width: 768px)': {
                    padding: '41px 24px 40px',
                },
            },
            '& .balanceSection , & .myEirDetails,& .myEirPlans': {
                padding: theme.spacing(3, 3, 2),
                marginBottom: theme.spacing(4),
                '@media  (max-width: 767px)': {
                    padding: theme.spacing(3),
                    marginBottom: theme.spacing(0),
                },
                '& .offerLeft': {
                    '@media  (max-width: 280px)': {
                        marginLeft: '9px',
                    },
                    '@media (min-width: 281px)and (max-width: 767px)': {
                        marginLeft: '20px',
                    },
                },
                '& .offerBtn': {
                    padding: '8px 24px !important',
                    margin: '20px 0px 20px !important',
                    '@media (min-width: 321px)and (max-width: 767px)': {
                        padding: '8px 14px !important',
                    },
                    '@media  (max-width: 321px)': {
                        padding: '8px 6px !important',
                    },
                },
                '& .topupbtn': {
                    background: '#fff ',
                    '&:hover': {
                        '& a': {
                            color: '#fff !important',
                        },
                    },
                    '& a': {
                        color: '#470A68 !important',
                    },
                },
                '& .purpleText': {
                    color: '#892BA0',
                    fontSize: '40px',
                    '@media  (max-width: 1024px)': {
                        fontSize: '40px !important',
                    },
                },
                '& h1': {
                    color: '#323435',
                    fontSize: '30px',
                    textAlign: 'center',
                    fontFamily: 'gt-walsheim-regular !important',

                    '@media  (max-width: 767px)': {
                        fontSize: '25px !important',
                    },
                },
            },
            '& .usageSection': {
                '& .padding': {
                    padding: theme.spacing(0, 4, 4),
                },

                marginBottom: theme.spacing(4),
                '@media  (max-width: 767px)': {
                    marginBottom: theme.spacing(3),
                },

                '& .usageBtn': {
                    background: '#fff ',
                    '&:hover': {
                        '& a': {
                            color: '#fff !important',
                        },
                    },
                    '@media (min-width: 281px)and (max-width: 767px)': {
                        width: '205px',
                        margin: '15px auto',
                    },
                    '@media  (max-width: 281px)': {
                        width: '170px',
                        padding: ' 8px 10px',
                        margin: '15px auto',
                    },

                    '& a': {
                        color: '#470A68 !important',
                    },
                },

                '& h1': {
                    padding: theme.spacing(4),
                    color: '#323435',
                    fontSize: '30px',
                    textAlign: 'center',
                    fontFamily: 'gt-walsheim-regular !important',
                    paddingBottom: theme.spacing(2),
                    '@media (min-width: 281px) and (max-width: 767px)': {
                        padding: theme.spacing(4, 2, 2),
                    },
                    '@media  (max-width: 281px)': {
                        padding: '32px 4px 16px',
                    },
                    '@media  (max-width: 767px)': {
                        fontSize: '25px !important',
                    },
                },

                '& p': {
                    fontFamily: 'gt-walsheim-regular !important',
                    '@media  (max-width: 281px)': {
                        fontSize: '12px !important',
                    },
                },
                '& .AlignRight': {
                    textAlign: 'right',
                    '@media  (max-width: 1025px)': {
                        textAlign: 'left',
                    },
                },
            },
            '& .faq-card__body': {
                '& p': {
                    fontFamily: 'gt-walsheim-regular !important',
                },
            },
            '& h1': {
                textAlign: 'left',
                color: '#892BA0',
                fontFamily: 'eir-medium',
                fontSize: '36px',
                '@media  (max-width: 767px)': {
                    fontSize: '25px !important',
                },
            },
            '& .faqSection': {
                padding: theme.spacing(4),
            },
            '& .faqLink,& .faqLink:hover': {
                color: '#892BA0',
            },
            '& .faq-card': {
                border: 0,
                boxShadow: 'none',
                '&.MuiAccordion-root:before': {
                    backgroundColor: 'transparent',
                },
            },
            '& .iconColor': {
                color: '',
            },
            '& .faqBtn': {
                background: '#FFFFFF 0% 0% no-repeat ',
                border: '2px solid #DB12A9',
                borderRadius: '29px',
                color: '#470A68',
                fontFamily: 'gt-walsheim-medium !important',
                outline: 'none',
                fontSize: '18px !important',
                textTransform: 'initial',
                padding: theme.spacing(1, 7),
                margin: '20px',
                '&:hover': {
                    background: '#892BA0 0% 0% no-repeat  !important',
                    border: '2px solid #892BA0',

                    '& a': {
                        color: '#fff !important',
                    },
                },
                '@media  (max-width: 320px)': {
                    padding: ' 8px 16px',
                    fontSize: '14px !important',
                    marginBottom: '15px',
                },
                '@media  (min-width: 321px) and (max-width: 767px)': {
                    fontSize: '14px !important',
                    padding: theme.spacing(1, 4),
                    marginBottom: '15px',
                },
                '@media  (min-width: 768px) and (max-width: 1025px)': {
                    fontSize: '16px !important',
                    padding: theme.spacing(1, 4),
                    marginBottom: '15px',
                },
                '& a': {
                    color: '#470A68',
                    textDecoration: 'none',
                    '&:hover': {
                        color: '#FFFFFF',
                    },
                },
            },
            '& .faqCaption': {
                textAlign: 'left',
                color: '#323435',
                fontFamily: 'eir-medium',
                fontSize: '20px',
                borderBottom: '2px dotted hsla(0,0%,78%,.4)',
                '@media  (max-width: 768px)': {
                    fontSize: '16px !important',
                },
            },
            '& p': {
                textAlign: 'left',
                color: '#323435',
                fontFamily: 'eir-medium',
                fontSize: '16px',
                '@media  (max-width: 768px)': {
                    fontSize: '14px',
                },
            },
        },
    },
}));
