import React, { useEffect, useState } from 'react';
import { Box, Typography, Button } from '@material-ui/core';
import CustomModal from '../../eir-porting/CustomModal/CustomModal';
import MyEirSimCostDisplay from './MyEirSimCostDisplay';
import MyEirSimEircode from './MyEirSimEircode';
import CustomUpdatedPopUpModal from '../../eir-porting/CustomModal/CustomUpdatedPopUpModal';
import useStyles from './style';
import { Controller, useForm, ErrorMessage } from 'react-hook-form';
import success from '../.././images/Large-icon-Success.png';
import { Scrollbars } from 'react-custom-scrollbars';
import { useApolloClient } from '@apollo/client';
import MyEirInactiveSim from './MyEirInactiveSim';
import { ReFormatCounty } from '../../../mobilecomponents/addressFormat';
import { handleSubmitSimRequest, getSimChargesResponse } from './simReplacement.util';
import EirButton from '../../../theme/EirButton';

const MyEirSimReplacementContainer = props => {
    const classes = useStyles();
    const serviceId = props.serviceId;
    const [openSim, setOpenSimModal] = useState(false);
    const [openCustomPopup, setOpenCustomPopup] = useState(false);
    const [submitData, setSubmitData] = useState('');
    const [simCost, setSimCost] = useState(0);
    const [openInactiveModal, setOpenInactiveModal] = useState(false);
    const [isEnabled, setIsEnabled] = useState(true);
    const [isTicked, setIsTicked] = useState(false);
    const [isError, setIsError] = useState(false);

    const [cta1, setCTA1] = useState({ text: '', action: () => {}, disable: false });
    const [cta2, setCTA2] = useState({ text: '', action: () => {}, disable: false });

    const { register, control, errors, handleSubmit, setValue, setError, clearError } = useForm({
        reValidateMode: 'onChange',
        mode: 'onChange',
    });

    const [submitFormData, setSubmitFormData] = useState({
        address: {
            addressLine1: '',
            addressLine2: '',
            addressLine3: '',
            code: '',
            town: '',
            county: '',
        },
        email: '',
        phoneNum: '',
        firstName: '',
        lastName: '',
    });

    const submitFormAction = addressValues => {
        setSubmitFormData({
            ...submitFormData,
            address: addressValues.address,
        });
    };

    // submit action

    const handleSubmitSimRequestUtil = async () => {
        await handleSubmitSimRequest({
            submitFormData,
            ReFormatCounty,
            graphql,
            isTicked,
            serviceId,
            setOpenSimModal,
            setOpenCustomPopup,
            setOpenInactiveModal,
            setIsError,
        });
    };

    const graphql = useApolloClient();

    const getSimChargesResponseUtil = async () => {
        await getSimChargesResponse({ graphql, serviceId, setSimCost });
    };

    const handleOpen = () => {
        setOpenCustomPopup(false);
        setOpenSimModal(true);
        // setCTA1({ text: 'Submit', action: (props.flag ? handleReorderSubmit : handleSimCtaClick), disable: false })
        // setCTA2({ text: 'Cancel', action: () => setOpenSimModal(false), disable: false })
    };

    const handleSaveData = () => {
        handleSubmitSimRequestUtil();
        setOpenSimModal(false);
        // setOpenCustomPopup(true);
    };

    //re-order submit
    const handleReorderSubmit = () => {
        setOpenSimModal(false);
        setOpenInactiveModal(!openInactiveModal);
    };

    // handle back arrow action
    const backArrowAction = () => {
        setOpenSimModal(!openSim);
        setIsError(false);
    };

    //handle popup button
    const handleAltCta = () => {
        props.getSimEligibilityResponse();
        setOpenCustomPopup(false);
        props.setOpen(false);
    };

    const handleClose = () => {
        console.log('cross icon clicked');
    };

    const handleTogglePopup = () => {
        setOpenCustomPopup(!openCustomPopup);
    };

    useEffect(() => {
        props.open && handleOpen();
    }, [props.open]);

    useEffect(() => {
        getSimChargesResponseUtil();
    }, []);

    useEffect(() => {
        props.flag
            ? setSubmitFormData({
                  address: {
                      addressLine1: props.reorderForm?.address?.addressLine1,
                      addressLine2: props.reorderForm?.address?.addressLine2,
                      addressLine3: props.reorderForm?.address?.addressLine3,
                      code: props.reorderForm?.address?.code,
                      town: props.reorderForm?.address?.town,
                      county: props.reorderForm?.address?.county,
                  },
                  email: props.ContactDetails?.emails[0].email,
                  phoneNum: props.ContactDetails?.phoneNumbers[0]?.phoneNumber,
                  firstName: props.ContactDetails?.firstName,
                  lastName: props.ContactDetails?.lastName,
              })
            : setSubmitFormData({
                  address: {
                      addressLine1: props.ContactDetails?.addresses[0]?.addressLine1,
                      addressLine2: props.ContactDetails?.addresses[0]?.addressLine2,
                      addressLine3: props.ContactDetails?.addresses[0]?.addressLine3,
                      code: props.ContactDetails?.addresses[0]?.code,
                      town: props.ContactDetails?.addresses[0]?.town,
                      county: props.ContactDetails?.addresses[0]?.county,
                  },
                  email: props.ContactDetails?.emails[0].email,
                  phoneNum: props.ContactDetails?.phoneNumbers[0]?.phoneNumber,
                  firstName: props.ContactDetails?.firstName,
                  lastName: props.ContactDetails?.lastName,
              });
    }, [props]);

    return (
        <Box id='sim-replacement'>
            <Typography component='span' onClick={handleOpen} className={classes.simLink}>
                {props.linkName}
            </Typography>

            <CustomModal
                open={openSim}
                toggle={backArrowAction}
                cta1={cta1}
                cta2={cta2}
                header={{ text: 'Replace my SIM', arrowAction: backArrowAction, center: false }}
            >
                <Scrollbars className={classes.scrollbar}>
                    <MyEirSimCostDisplay cost={props.flag ? props.reorderCost : props.simCost} />
                    <MyEirSimEircode
                        ContactDetails={props.ContactDetails}
                        register={register}
                        control={control}
                        errors={errors}
                        setValue={setValue}
                        submitFormAction={submitFormAction}
                        submitFormData={submitFormData}
                        setSubmitFormData={setSubmitFormData}
                        setIsEnabled={setIsEnabled}
                        setIsTicked={setIsTicked}
                        reorderForm={props.reorderForm}
                        flag={props.flag}
                    />
                </Scrollbars>
                <Box className={classes.buttonBox}>
                    {isError ? (
                        <Box className={classes.err}>
                            Something went wrong, Please try again later!
                        </Box>
                    ) : (
                        ''
                    )}
                    <EirButton
                        variants='primaryButtonMedium'
                        style={{ padding: '0.5rem 1.5rem' }}
                        onClick={props.flag ? handleReorderSubmit : handleSaveData}
                        // className={`${classes.simCta}  ${
                        //     isEnabled ? classes.simCta1 : classes.simCta1Disable
                        // } `}
                        disabled={!isEnabled}
                    >
                        Submit
                    </EirButton>
                    <Button
                        onClick={backArrowAction}
                        className={`${classes.simCta} ${classes.simCta2} `}
                    >
                        Cancel
                    </Button>
                </Box>
            </CustomModal>

            <Box id='my-eir-pop-up'>
                <CustomUpdatedPopUpModal
                    open={openCustomPopup}
                    toggle={handleTogglePopup}
                    cta1={{ text: 'Close', action: handleAltCta, disable: false }}
                    cta2={{ text: '', action: () => {} }}
                >
                    <Box className={classes.successPopup}>
                        <Box className={classes.successImg}>
                            <Typography component='img' src={success} alt='success' />
                        </Box>

                        <Typography className={classes.imgTextAdditional}>
                            Your replacement SIM has been ordered
                        </Typography>
                    </Box>
                </CustomUpdatedPopUpModal>
            </Box>
            {openInactiveModal && (
                <MyEirInactiveSim
                    open={openInactiveModal}
                    setOpen={setOpenInactiveModal}
                    setOpenSimModal={setOpenSimModal}
                    setOpenCustomPopup={setOpenCustomPopup}
                    handleSubmitSimRequest={handleSubmitSimRequestUtil}
                />
            )}
        </Box>
    );
};

export default MyEirSimReplacementContainer;
