import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import CustomModal from '../../eir-porting/CustomModal/CustomModal';
import useStyles from './addonsStyles';
import failure from '../../images/sad.png';
import success from '../../images/Large-icon-Success.png';
import CustomUpdatedPopUpModal from '../../eir-porting/CustomModal/CustomUpdatedPopUpModal';
import { useApolloClient } from '@apollo/client';
import MyEirErrorHandle from '../../eir-porting/MyEirErrorHandle';
import { getActiveAddons, removeAddonApi } from './manageAddons.api';
import { DOMAINS } from '../../../components/myeirconstant/myEirConstant';

const RemoveAddonPopup = props => {
    const classes = useStyles();
    const graphql = useApolloClient();
    const [openCancel, setOpenCancel] = useState(false);
    const [openErrorAddon, setOpenErrorAddon] = useState(false);
    const [openSuccessPopup, setOpenSuccessPopup] = useState(false);
    const [cta1, setCTA1] = useState({ text: '', action: () => {}, disable: false });
    const [cta2, setCTA2] = useState({ text: '', action: () => {}, disable: false });
    const addOnId = props.card.addonId;
    const serviceId = props.card.serviceId;
    const { VOIP, MOBILE, IPTV } = DOMAINS;

    const handleOpenLink = () => {
        setOpenCancel(true);
        setCTA1({ text: 'Yes, remove', action: handleConfirm, disable: false });
        setCTA2({ text: "No, i'll keep it", action: handleInactiveCancel });
    };

    const handleInactiveCancel = () => {
        setOpenCancel(false);
    };

    const handleToggleCancel = () => {
        setOpenCancel(!openCancel);
    };

    const handleConfirm = async () => {
        try {
            if (props.card.serviceGroup === MOBILE && props.card.billingType === 'UPFRONT') {
                setOpenErrorAddon(true);
                setOpenCancel(false);
                console.error('Mobile Addon cannot be removed');
            } else if (
                props.card.serviceGroup === VOIP &&
                props.collectionStatus === false &&
                props.accountStatus !== 'BILLABLE'
            ) {
                setOpenErrorAddon(true);
                setOpenCancel(false);
                console.error('VOIP Addon cannot be removed');
            } else if (props.card.serviceGroup === IPTV && props.accountStatus !== 'BILLABLE') {
                setOpenErrorAddon(true);
                setOpenCancel(false);
                console.error('TV Addon cannot be removed');
            } else {
                await removeAddonApi({ graphql, addOnId, serviceId });
                setOpenCancel(false);
                setOpenSuccessPopup(true);
            }
        } catch (error) {
            console.error(error);
            setOpenErrorAddon(true);
            setOpenCancel(false);
        }
    };

    const handleAltSuccessCta = () => {
        // props.setShowAddons(props.showAddons.filter(item => item.addonId !== addOnId));
        props.ActiveAddonContainerReload();
        setOpenSuccessPopup(false);
    };

    //close icon
    const handleClose = () => {
        setOpenCancel(false);
    };

    return (
        <Box id='remove-addon'>
            <Box className={classes.cardLink}>
                <Typography component='span' onClick={handleOpenLink} className={classes.link}>
                    {props.linkName}
                </Typography>
            </Box>

            <CustomModal
                cssId={'myeir-modal-window-remove-add-ons-tile-' + `${props.index + 1}`}
                open={openCancel}
                toggle={handleToggleCancel}
                cta1={cta1}
                cta2={cta2}
                header={{ text: 'Remove Add-on', arrowAction: () => {}, center: true }}
            >
                <Box className={classes.deleteContainer}>
                    <Box className={classes.imgSad}>
                        <Typography component='img' src={failure} alt='failure' />
                    </Box>
                    <Typography className={classes.deleteText}>
                        Are you sure you want to remove {props?.card?.title}?
                    </Typography>
                    {(props?.card?.code === 'WIFI_MESH_6' ||
                        props?.card?.code === 'WIFI_MESH_7' ||
                        props?.card?.code === 'MULTI_ROOM_2') && (
                        <Typography className={classes.deleteText}>
                            You will receive an email with the details for returning your equipment.
                            <br />A charge will be applied if you do not return this equipment.
                        </Typography>
                    )}
                </Box>
            </CustomModal>
            <Box id='my-eir-pop-up'>
                <CustomUpdatedPopUpModal
                    open={openSuccessPopup}
                    key={openSuccessPopup}
                    cta1={{ text: 'Close', action: handleAltSuccessCta, disable: false }}
                    cta2={{ text: '', action: () => {} }}
                >
                    <Box className={classes.successPopup}>
                        <Box className={classes.successImg}>
                            <Typography component='img' src={success} alt='success' />
                        </Box>

                        <Typography className={classes.deleteText}>
                            Addon has been successfully removed.
                        </Typography>
                        {(props?.card?.code === 'WIFI_MESH_6' ||
                            props?.card?.code === 'WIFI_MESH_7' ||
                            props?.card?.code === 'MULTI_ROOM_2') && (
                            <Typography className={classes.deleteText}>
                                Please check your email for details on returning your equipment.
                            </Typography>
                        )}
                    </Box>
                </CustomUpdatedPopUpModal>
            </Box>

            {openErrorAddon ? (
                <MyEirErrorHandle
                    open={openErrorAddon}
                    setOpen={setOpenErrorAddon}
                    handleClose={handleClose}
                />
            ) : (
                ''
            )}
        </Box>
    );
};

export default RemoveAddonPopup;
