import React, { useEffect, useState } from 'react';
import { isMobile, isMobileOnly, isTablet, isBrowser } from 'react-device-detect';
import { useApolloClient } from '@apollo/client';
import ENDPOINT from '../graphql/GraphQLConstants';
function MyEirRTM(props) {
    const graphql = useApolloClient();
    const [rtmBanner, setRtmBanner] = useState(null);
    let currentDate = new Date();
    let currentTime = currentDate.getTime();
    useEffect(() => { 
        if (isMobileOnly) {
            console.log('mobile');
            setRtmBanner('https://m.rtm.eir.ie/webApp/MyOfferMobileView?msisdn='+props.msisdn+'&clientid=1&ts=' + currentTime);
        } else if (isTablet) {
            console.log('tablet');
            setRtmBanner('https://m.rtm.eir.ie/webApp/MyOfferTabletView?msisdn=' + props.msisdn +'&clientid=1&ts=' + currentTime);
        } else {
            console.log('desktop');
            setRtmBanner("https://m.rtm.eir.ie/webApp/MyOfferOneCall?msisdn="+ props.msisdn +'&clientid=1&ts=' + currentTime);
        }      
    }, []);
    return (
        <div className='rtm'>
            {rtmBanner?<iframe src={rtmBanner} scrolling='no' style={{ overflow: 'hidden' }}></iframe>:''}
        </div>
    );
}

export default MyEirRTM;
