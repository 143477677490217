import { makeStyles } from '@material-ui/core/styles';
export const iconBadgeStyle = makeStyles(theme => ({
    commonStyle: {
        '& .MuiBadge-badge': {
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            fontFamily: theme.typography.body3Medium.fontFamily,
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '8px',
            lineHeight: '9px',
            backgroundColor: theme.palette.primary.error,
        },
    },
    standardStyle: {
        '& .MuiBadge-badge': {
            minWidth: '13px',
            minHeight: '13px',
            height: 'unset',
            width: 'unset',
            padding: '0px',
        },
        '& .MuiBadge-anchorOriginTopRightRectangle': {
            top: '6px',
        },
        '& .MuiBadge-anchorOriginBottomRightRectangle': {
            bottom: '6px',
        },
        '& .MuiBadge-anchorOriginTopLeftRectangle': {
            top: '6px',
        },
        '& .MuiBadge-anchorOriginBottomLeftRectangle': {
            bottom: '6px',
        },
    },
    dotStyle: {
        '& .MuiBadge-badge': {
            minWidth: '6px',
            minHeight: '6px',
            height: 'unset',
            width: 'unset',
            padding: '0px',
        },
        '& .MuiBadge-anchorOriginTopRightRectangle': {
            top: '3px',
            right: '3px',
        },
        '& .MuiBadge-anchorOriginBottomRightRectangle': {
            bottom: '3px',
            right: '3px',
        },
        '& .MuiBadge-anchorOriginTopLeftRectangle': {
            top: '3px',
            left: '3px',
        },
        '& .MuiBadge-anchorOriginBottomLeftRectangle': {
            bottom: '3px',
            left: '3px',
        },
    },
    dotborderStyle: {
        '& .MuiBadge-badge': {
            border: '1.5px solid #FFFFFF',
            borderRadius: '1000px',

            minWidth: '9.5px',
            minHeight: '9.5px',
        },
    },
    standardborderStyle: {
        '& .MuiBadge-badge': {
            border: '1px solid #FFFFFF',
            borderRadius: '1000px',
        },
    },
}));
