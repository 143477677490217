import React, { Fragment } from 'react';
import { Grid } from '@material-ui/core';

function MyEirForgotPasswordResponse(props) {
    return (
        <div className='forRegform'>
            <Fragment>
                {(props.formSuccessMsg || props.emailConfirmationDisplay) && (
                    <Fragment>
                        <h1 className='paddingTop'>You’re almost there</h1>
                        <h2 className='marginTop'>
                            Your account registration is not yet complete.
                            <br />
                            To complete the registration process, please verify your
                            <br />
                            email address and set your password. <br />
                        </h2>
                        <h2>
                            An email has been sent to <br /> {props.emailAddress}
                        </h2>
                        <p>Check your email and follow the instructions to set your password</p>
                        <p>NOTE: Don't forget to check spam email!</p>
                    </Fragment>
                )}
                {(props.formSuccessMsg == undefined ||
                    props.formSuccessMsg == null ||
                    props.formSuccessMsg == '') &&
                    !props.emailConfirmationDisplay && (
                        <Fragment>
                            <h1 className='paddingTop'>Reset your password</h1>
                            <h2 className='marginTop'>Nearly there – check your email</h2>
                            <h2>
                                An email has been sent to <br /> {props.emailAddress}
                            </h2>
                            <p>
                                Check your email and follow the instructions to reset your password
                            </p>
                            <p>NOTE: Don't forget to check spam email!</p>
                        </Fragment>
                    )}
                <div className='paddingBottom'>
                    <a className='link' href='/prepay/login'>
                        <span className='arrow-right'></span>Back to log in
                    </a>
                </div>
            </Fragment>
        </div>
    );
}

export default MyEirForgotPasswordResponse;
