import { createContext } from 'react';

export const initialState = {
    myBalance:{
        billData:[],
        balance:'NA',
        paymentDue:'NA',
        monthlyCharges:0,
        forPeriod:'NA',
        toDate:'NA',
        billDate:'NA',
        usage:0,
        totalBalance:0,
        oneoffCharges:'NA',
        paymentMethods:[],
        debitDate: 'NA',
        billDueDate: 'NA'
    },
    myServices:{
        planArr:[],
        apvOffer:'',
        apvOfferStatus:0,
        apvCustomerType:'',
    },
    myAddons:{
        showAddons:[],
    },
};

export const redesignReducer = (state, action) => {
    switch (action.type) {
        case 'myBalance': {

            return {
                ...state,
                myBalance:{
                    ...state.myBalance,
                    [ action.payload.name] : action.payload.value
                }
            };
        }
        case 'myServices': {

            return {
                ...state,
                myServices:{
                    ...state.myServices,
                    [ action.payload.name] : action.payload.value
                }
            };
        }
        case 'myAddons': {

            return {
                ...state,
                myAddons:{
                    ...state.myAddons,
                    [ action.payload.name] : action.payload.value
                }
            };
        }
        default: {

            return state;
        }
    }
};

export const RedesignContext = createContext();
