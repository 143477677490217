import { useApolloClient } from '@apollo/client';
import { Box, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { ADDON_CODES, MY_EIR_CONST } from '../../../components/myeirconstant/myEirConstant.js';
import { structureData } from '../../../utils/Format.util.js';
import {
    createChaneOfferProspectUtil,
    getAdobeAnalyticsApi,
    getChangeOfferUpgradeEligibility,
} from '../my-services/myServices.util.js';
import OfferUpgradeModal from '../Offer-upgrade-modal/OfferUpgradeModal.js';
import { setCookie, setTokenInCookies } from '../redesign.api.js';
import { offersStyles } from './offersStyles.js';
import { handlingRedirection } from './Offers.util.js';
import { useWindowDimension } from '../../../hooks/windowDimensions.js';
import MyEirNewErrorPopup from '../my-services/MyEirNewErrorPopup';
import { getBllingAddress } from '../manage-my-addons/manageAddons.util.js';
import { addAddonToServiceApi } from '../manage-my-addons/manageAddons.api.js';
import AddAddonSuccessModal from '../manage-my-addons/AddAddonSuccessModal.js';
import { DOMAINS } from '../../../components/myeirconstant/myEirConstant';
import MyEirErrorHandle from '../../eir-porting/MyEirErrorHandle';
function Offers({
    miniText,
    name,
    price,
    validityText,
    background,
    subscriptionId,
    propositionId,
    attributes,
    serviceGroup,
    modalImage,
    adobeOffer_tariff_code,
    contractType,
    onOfferPage,
    finalArr,
}) {
    const [attributesData, setAttributesData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const graphql = useApolloClient();
    const [openOffer, setOpenOffer] = useState(false);
    const [openError, setOpenError] = useState(false);
    const [openChangeError, setOpenChangeError] = useState(false);
    const [openChangeError2, setOpenChangeError2] = useState(false);
    const [addonSuccessModal, setAddonSuccessModal] = useState(false);
    const [billingAddress, setBillingAddress] = useState(null);
    const [changeOfferResponse, setChangeOfferResponse] = useState(null);
    const [notInterestedPopUp, setNotInterestedPopUp] = useState(false);
    const [errorText, setErrorText] = useState({
        headText: '',
        subText: '',
    });
    const [width, height] = useWindowDimension();
    const classes = offersStyles();
    const ineligibleMessage = 'Unfortunately you are not eligible to make changes at this time';
    const ineligibleMessage2 =
        ' Unfortunately cannot change the offer online as they are in contract and to contact customer care';
    const handleOfferUpgrade = async () => {
        setCookie('CO', 'CODE', attributes?.code);
        setCookie('CO', 'CATALOG_CODE', attributes?.offerCode);
        setCookie('CO', 'SERVICE_GROUP', attributes?.serviceGroupCode);
        setCookie('CO', 'PORT_IN', attributes?.portIn);
        setCookie('CO', 'SIM_ONLY', attributes?.simOnly);
        setCookie('CO', 'COMMITMENT_DURATION', attributes?.commitmentDuration);
        if (contractType === 'addon') {
            setOpenOffer(true);
        } else if (
            changeOfferResponse &&
            changeOfferResponse.contractRenewal === true &&
            changeOfferResponse.earlyCeaseChargeList?.totalAmountVatIncluded <= 0
        ) {
            setOpenOffer(true);
        } else if (changeOfferResponse && changeOfferResponse.contractRenewal === false) {
            setOpenChangeError(true); // error displayed as 'Unfortunately you are not eligible to make changes at this time'
        } else if (
            changeOfferResponse &&
            changeOfferResponse.contractRenewal === true &&
            changeOfferResponse.earlyCeaseChargeList?.totalAmountVatIncluded > 0
        ) {
            setOpenChangeError2(true); // error displayed as ' Unfortunately cannot change the offer online as they are in contract and to contact customer care'
        } else if (changeOfferResponse === null) {
            setOpenError(true); // opps something went wrong error
        }
    };
    // Passing cookies and data from myeir to eir.ie
    const { FIBER } = DOMAINS;
    const handleRedirectValues = reference => {
        const redirectPath = handlingRedirection({
            reference,
            subscriptionId,
            cartType: 'CHANGE_OFFER',
            serviceGroup,
            MY_EIR_CONST,
            setOpenOffer,
            setOpenError,
            openOffer,
            openError,
        });

        return redirectPath;
    };

    const addAddon = async () => {
        try {
            setIsLoading(true);
            const data = await getBllingAddress(graphql);
            setBillingAddress(data?.billingAddress);
            let BroadbandService = JSON.parse(sessionStorage.getItem('LoggedinServices')).filter(
                item => item?.subscriptionId === JSON.parse(subscriptionId) && item?.domain === FIBER
            )[0];
            let addonRequest = {
                addonCode: adobeOffer_tariff_code,
                deliveryContactUuid: JSON.stringify(data?.billingAddressId),
                channelCode: 'MYEIR',
                serviceId: BroadbandService ? BroadbandService?.id : null,
            };
            const res = await addAddonToServiceApi({ graphql, addonRequest });
            if (res?.addAddonToService) {
                setIsLoading(false);
                setOpenOffer(false);
                setAddonSuccessModal(true);
            } else {
                setIsLoading(false);
                console.error('Error in Adding wifi mesh addon to service : ', error);
                setOpenError(true); // opps something went wrong error
            }
        } catch (error) {
            setIsLoading(false);
            console.error('Error in Adding wifi mesh addon to service : ', error);
            setOpenError(true); // opps something went wrong error
        }
    };

    const handleUpgrade = async value => {
        if (contractType === 'addon') {
            addAddon();
        } else {
            try {
                setIsLoading(true);
                const [adobeResult, changeOfferResult] = await Promise.all([
                    getAdobeAnalyticsApi({ graphql, value, propositionId: propositionId }),
                    createChaneOfferProspectUtil({
                        graphql,
                        subscriptionId: parseInt(subscriptionId),
                        setOpenError,
                        openError,
                        setOpenOffer,
                        openOffer,
                    }),
                ]);
                const reference = changeOfferResult.uuid;
                const redirectPath = handleRedirectValues(reference);
                if (redirectPath !== undefined) window.location.href = redirectPath;
            } catch (error) {
                setIsLoading(false);
                console.error('Error in handle upgrade of available offers : ', error);
                setOpenError(true); // opps something went wrong error
            }
        }
    };

    const strippedText = new DOMParser().parseFromString(name, 'text/html').body.innerText;

    // const removeParenthesesFromString = string => {
    //     const stringWithoutParentheses = string.replace(/\(|\)/g, '');
    //     return stringWithoutParentheses;
    // };

    const handleNotInterested = async value => {
        await getAdobeAnalyticsApi({ graphql, value, propositionId: propositionId });

        setOpenOffer(false);
        setNotInterestedPopUp(true);
        setErrorText({
            headText: '',
            subText:
                'Sorry you are not interested in this offer. Keep an eye out for some other great offers next time you log in.',
        });
    };

    useEffect(() => {
        setOpenOffer(openOffer);
    }, [subscriptionId, propositionId, attributes, serviceGroup]);

    useEffect(() => {
        setAttributesData(structureData(attributes));
    }, [attributes]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getChangeOfferUpgradeEligibility({
                    graphql,
                    subscriptionId,
                    setOpenError,
                    openError,
                });

                setChangeOfferResponse(response);
            } catch (error) {
                console.error('Error in fetching getChangeOfferUpgradeEligibility', error);
            }
        };
        fetchData();
    }, []);
    return (
        <Box>
            <Box
                style={{
                    background: background,
                    borderRadius:
                        onOfferPage === true ? ' 24px 10px 10px 24px' : ' 24px 0px 9px 24px',
                    padding: finalArr.length > 2 ? '16px 12.6px 16px 16px' : '18px 16px 16px 23px',
                    height:
                        finalArr.length === 1
                            ? width < 600
                                ? '134px'
                                : '100%'
                            : width < 600
                            ? '134px'
                            : '130px',
                }}
                className={classes.offerContainer}
            >
                <Typography className={classes.miniText}> {miniText}</Typography>
                <Typography
                    className={contractType === 'addon' ? classes.addonName : classes.offerName}
                >
                    {strippedText}
                </Typography>
                {/* {contractType === 'addon' && (
                    <Typography className={classes.offerPrice}>
                        €{(attributes?.charges?.price / 100).toFixed(2)}
                        <Typography className={classes.offerValidity} component='span'>
                            {validityText}
                        </Typography>
                    </Typography>
                )} */}
                <Typography className={classes.readMore} onClick={() => handleOfferUpgrade()}>
                    Read more »
                </Typography>
            </Box>
            {openError ? (
                <MyEirErrorHandle open={openError} setOpen={setOpenError} />
            ) : (
                <OfferUpgradeModal
                    attributesData={attributesData}
                    handleUpgrade={() => handleUpgrade(3)}
                    handleNotInterested={() => handleNotInterested(5)}
                    openError={openError}
                    setOpenError={setOpenError}
                    openChangeError={openChangeError}
                    setOpenChangeError={setOpenChangeError}
                    isModalOpen={openOffer}
                    subscriptionId={subscriptionId}
                    propositionId={propositionId}
                    attributes={attributes}
                    serviceGroup={serviceGroup}
                    setIsModalOpen={setOpenOffer}
                    modalImage={modalImage}
                    ctaName={contractType === 'addon' ? 'Get add-on' : 'Get upgrade'}
                    notInterestedPopUp={notInterestedPopUp}
                    setNotInterestedPopUp={setNotInterestedPopUp}
                    errorText={errorText}
                    price={price}
                    contractType={contractType}
                    setErrorText={setErrorText}
                    isLoading={isLoading}
                />
            )}
            {addonSuccessModal && (
                <AddAddonSuccessModal
                    addonSuccessModal={addonSuccessModal}
                    setAddonSuccessModal={setAddonSuccessModal}
                    billingAddress={billingAddress}
                    modalImage={modalImage}
                    addonSuccessTitle={attributesData?.addonSuccessTitle}
                    addonSuccessSubTitle={attributesData?.addonSuccessSubTitle}
                    ctaName='Back to  My account'
                ></AddAddonSuccessModal>
            )}
            {openChangeError ? (
                <MyEirNewErrorPopup
                    open={openChangeError}
                    setOpen={setOpenChangeError}
                    message={ineligibleMessage}
                />
            ) : (
                ''
            )}
            {openChangeError2 ? (
                <MyEirNewErrorPopup
                    open={openChangeError2}
                    setOpen={setOpenChangeError2}
                    message={ineligibleMessage2}
                />
            ) : (
                ''
            )}
            {openError ? <MyEirErrorHandle open={openError} setOpen={setOpenError} /> : ''}
        </Box>
    );
}

export default Offers;
