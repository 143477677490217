import React, { useState, useEffect, useRef, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { Typography, Box } from '@material-ui/core';
import MyEirServicesCard from './MyEirServicesCard';
import useStyles from './myServicesStyles';
import bb from '../images/router.png';
import tv from '../images/live-tv.png';
import mobile from '../images/mobileIcon.png';
import BannerImage from '../images/broadbandBanner.png';
import BroadBand from '../images/BroadBand_Banner.png';
import TV_Banner from '../images/TV_Banner.png';
// import defaultFinalArrImage from '../images/banner.png';
import { useWindowDimension } from '../../../hooks/windowDimensions';
import {
    getMyPlanDetails,
    getAdobeOffers,
    getMyServicesOffersUtil,
    getMyServicesOffers,
    getAddonsForService,
    getCurrentlySubscribedAddonsForService,
} from './myServices.util';
import { useApolloClient } from '@apollo/client';
import Loading from '../../../components/loading/Loading';
import { subscribe } from 'pubsub-js';
import MyEirTvPinContainer from '../../my-plan/manage-tv-pin/TvPinContainer';
import MyEirDeviceManagement from '../../my-plan/manage-my-device/DeviceManagement';
import MyEirAmazonPrimeVideo from '../../eir-amazon-prime-video/AmazonPrimeVideo';
import MyEirPortingContainer from '../../eir-porting/MyEirPortingContainer';
import { getApvDetails } from '../amazon-prime-video/apv.util';
import CancelAPVPopup from '../../eir-amazon-prime-video/CancelAPVPopup';
import MyEirDisplayPINPUK from '../../my-account/MyEirDisplayPINPUK';
import { RedesignContext } from '../../../global-context-redesign';
import { removeDuplicateArrayElements } from '../../../utils/Format.util';
import theme from '../../../theme/MyEirTheme';
import { DOMAINS, SERVICE_GROUPS } from '../../../components/myeirconstant/myEirConstant';

const mockData = {
    categories: {
        broadband: {
            miniText: 'Recontract your',
            name: '1Gb Fibre to home',
            price: '0.00',
            validity: 'a month',
            background: theme.palette.primary.backgroundColor2,
        },
        tv: {
            miniText: 'Available offer',
            name: '+100 channels',
            price: '0.00',
            validity: 'a month',
            background: theme.palette.primary.backgroundColor2,
        },
        mobile: {
            miniText: 'Available offer',
            name: '+100 channels',
            price: '0.00',
            validity: 'a month',
            background: theme.palette.primary.backgroundColor2,
        },

        mbb: {
            miniText: 'Recontract ',
            name: '1Gb Fibre to home',
            price: '0.00',
            validity: 'a month',
            background: theme.palette.primary.backgroundColor2,
        },
    },
};

const MyEirServicesContainer = ({ onOfferPage = false }) => {
    const value = useContext(RedesignContext);
    const classes = useStyles();
    const [width, height] = useWindowDimension();
    const [isTvPin, setIsTvPin] = useState(true);
    const [apvCustomerType, setApvCustomerType] = useState(
        value?.state?.myServices?.apvCustomerType
    );
    const [apvOffer, setApvOffer] = useState(value?.state?.myServices?.apvOffer);
    const [apvOfferStatus, setApvOfferStatus] = useState(value?.state?.myServices?.apvOfferStatus);
    const [planArr, setPlanArr] = useState(value?.state?.myServices?.planArr);
    const [hasNoOffer, setHasNoOffer] = useState(false);
    const graphql = useApolloClient();
    const [availableAddons, setAvailableAddons] = useState([]);
    const [activeAddons, setActiveAddons] = useState([]);
    const [finalArr, setFinalArr] = useState([]);
    const [isContractsError, setIsContractsError] = useState(false);
    const [serviceArrayReloading, setServiceArrayReloading] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isInactiveUser, setIsInactiveuser] = useState(true);
    const [servicesOffer, setServiceOffers] = useState([]);
    const [getOffers, setGetOffers] = useState([]);
    const [broadbandError, setBroadbandError] = useState(false);
    const [mobileError, setMobileError] = useState(false);
    const [mbbError, setMbbError] = useState(false);
    const [inactiveBBId, setInactiveBBId] = useState('');
    const [inactiveMobId, setInactiveMobId] = useState('');
    const dualplayObjects = [];
    const pstnObjects = [];
    const tvObjects = [];
    const mobileObjects = [];
    const mbbObjects = [];
    const [apvId, setApvId] = useState('');
    const [isActiveTv, setIsActiveTv] = useState(false);
    const { FIBER, IPTV, MOBILE } = DOMAINS;

    const defaultImage =
        window.location.origin === 'https://my.eir.ie'
            ? window.location.origin + '/uploads/banner_9b3cde8d20.png'
            : window.location.origin + '/uploads/banner_f9d93b9a51.png';

    const getApvDetailsUtil = async apvId => {
        await getApvDetails({
            graphql,
            setApvCustomerType,
            setApvOffer,
            setApvOfferStatus,
            value,
            setIsLoading,
            apvId,
        });
    };
    const numCards = planArr?.length;
    const gridMdProp = numCards === 1 ? 12 : numCards === 2 ? 6 : 4;
    const gridMdProp1 = numCards === 1 ? 12 : 6;
    const serviceRef = useRef(null);

    const getMyPlanDetailsUtil = async () => {
        await getMyPlanDetails({
            graphql,
            setIsContractsError,
            setPlanArr,
            dualplayObjects,
            pstnObjects,
            tvObjects,
            mobileObjects,
            mbbObjects,
            setIsLoading,
            value,
        });
    };

    const getMyServicesOffersUtil = async () => {
        await getMyServicesOffers({ graphql, servicesOffer, setGetOffers });
    };

    useEffect(() => {
        const offsetTop = serviceRef.current.offsetTop;
        const scrollToSection = () => {
            window.scrollTo({
                top: offsetTop,
                behavior: 'smooth',
                left: 0,
            });
        };
        subscribe('SCROLL_TO_SERVICES', scrollToSection);
    });

    // useEffect(() => {
    //     const tempFunc = async () => {
    //         await getMyPlanDetailsUtil();
    //         await getApvDetailsUtil();
    //         await getAdobeOffersUtil();
    //     };
    //     tempFunc();
    // }, []);
    useEffect(() => {
        getMyServicesOffersUtil();
    }, [servicesOffer]);

    useEffect(() => {
        const removeDuplicateArrayElements = (arr, propertyToCheck) => {
            return arr?.filter((element, index, self) => {
                const firstIndex = self.findIndex(
                    e => e[propertyToCheck] === element[propertyToCheck]
                );

                return index === firstIndex;
            });
        };

        const uniqueArray = removeDuplicateArrayElements(getOffers, 'code');
        // const finalArray = planArr
        //     ?.map(item => {
        //         const matchedOffers = servicesOffer?.find(
        //             offer => offer.subscriptionID === item.subId.toString()
        //         );
        //         // Iterating Adobe Offers per Service Group
        //         if (matchedOffers?.length > 0) {
        //             const offersPerServiceGroup = matchedOffers?.map(matchOffer => {
        //                 if (matchOffer) {
        //                     const uniqueItem = uniqueArray.find(
        //                         uniqueItem => uniqueItem?.code === matchOffer?.tariff_code
        //                     );
        //                     if (uniqueItem) {
        //                         return {
        //                             ...item,
        //                             adobeOffer: matchOffer,
        //                             servicesOffer: uniqueItem,
        //                         };
        //                     }
        //                     if (matchOffer?.type === 'addon') {
        //                         if (
        //                             !activeAddons?.find(
        //                                 addon => addon?.code === matchOffer?.tariff_code
        //                             )
        //                         ) {
        //                             let AddonData = availableAddons?.find(
        //                                 addon => addon?.code === matchOffer?.tariff_code
        //                             );
        //                             return {
        //                                 ...item,
        //                                 adobeOffer: matchOffer,
        //                                 servicesOffer: AddonData,
        //                             };
        //                         } else {
        //                             // Returning only item because matched addon is already been added to service
        //                             return {
        //                                 ...item,
        //                             };
        //                         }
        //                     }
        //                 }
        //                 return {
        //                     ...item,
        //                     adobeOffer: matchOffer,
        //                 };
        //             });
        //             return offersPerServiceGroup;
        //         } else {
        //             return {
        //                 ...item,
        //             };
        //         }
        //     })
        //     .flat(); //flat function is used to remove inner offersPerServiceGroup array
        const finalArray = planArr?.map(item => {
            const matchOffer = servicesOffer?.find(
                offer => offer.subscriptionID === item.subId.toString()
            );

            if (matchOffer) {
                const uniqueItem = uniqueArray.find(
                    uniqueItem => uniqueItem.code === matchOffer.tariff_code
                );
                if (uniqueItem) {
                    return {
                        ...item,
                        adobeOffer: matchOffer,
                        servicesOffer: uniqueItem,
                    };
                }
                if (matchOffer?.type === 'addon') {
                    if (!activeAddons?.find(addon => addon?.code === matchOffer?.tariff_code)) {
                        let AddonData = availableAddons?.find(
                            addon => addon?.code === matchOffer?.tariff_code
                        );
                        return {
                            ...item,
                            adobeOffer: matchOffer,
                            servicesOffer: AddonData,
                        };
                    } else {
                        // Returning only item because matched addon is already been added to service
                        return {
                            ...item,
                        };
                    }
                }
            }
            return {
                ...item,
                adobeOffer: matchOffer,
            };
        });
        setHasNoOffer(
            finalArray?.length > 0 &&
                !finalArray.some(item => item?.hasOwnProperty('servicesOffer'))
        );

        if (onOfferPage) {
            setFinalArr(
                finalArray?.filter(item => {
                    if (item?.servicesOffer) return item;
                })
            );
        } else {
            setFinalArr(finalArray);
            if (!finalArray.some(item => item?.hasOwnProperty('servicesOffer'))) {
                sessionStorage.setItem('NO_OFFER', true);
            } else {
                sessionStorage.removeItem('NO_OFFER');
            }
        }
        if (serviceArrayReloading) {
            setServiceArrayReloading(false);
            setIsLoading(false);
        }
    }, [planArr, servicesOffer, getOffers, onOfferPage, activeAddons]);

    const getAdobeOffersUtil = async () => {
        const res = await getAdobeOffers({ graphql });
        const sortedResult = res?.availableOffers
            ? [...res.availableOffers].sort((a, b) => a.rank - b.rank)
            : [];
        if (sortedResult.filter(e => e.type === 'addon')) {
            const availableAddons = await getAddonsForService({ graphql, codes: [] });
            if (availableAddons) {
                setAvailableAddons(availableAddons);
            }
            const activeAddons = await getCurrentlySubscribedAddonsForService({
                graphql,
                servicesIds: sessionStorage.getItem('LoggedinServices')
                    ? JSON.parse(sessionStorage.getItem('LoggedinServices'))[0].id
                    : null,
            });
            if (activeAddons) {
                setActiveAddons(activeAddons);
            }
        }
        setServiceOffers(sortedResult);
    };

    const checkStatusAndSetError = service => {
        if (service?.domain === FIBER) {
            if (service?.status === 'INITIAL') {
                setInactiveBBId(service?.id);
                setIsInactiveuser(true);
                setBroadbandError(true);
            } else if (service?.status === 'TERMINATED') {
                setInactiveBBId(service?.id);
                setIsInactiveuser(true);
                setBroadbandError(true);
            } else {
                setIsInactiveuser(false);
            }
        } else if (service?.domain === MOBILE) {
            if (service?.status === 'INITIAL') {
                setInactiveMobId(service?.id);
                setIsInactiveuser(true);
                setMobileError(true);
            } else if (service?.status === 'TERMINATED') {
                setInactiveMobId(service?.id);
                setIsInactiveuser(true);
                setMobileError(true);
            } else {
                setIsInactiveuser(false);
            }
        }
    };

    useEffect(() => {
        if (planArr?.length == 0) {
            getMyPlanDetailsUtil();
        }
        getAdobeOffersUtil();
    }, []);

    useEffect(() => {
        const sessionValue = JSON.parse(sessionStorage.getItem('LoggedinServices'));

        sessionValue?.forEach(service => {
            checkStatusAndSetError(service);
        });
    }, []);
    useEffect(() => {
        const tvSession = planArr.find(
            session => session?.status === 'ACTIVE' && session?.serviceGroup === 'TV'
        );

        if (tvSession) {
            setIsActiveTv(true);
            const tvParentId = tvSession?.dependsOn;
            const broadbandSession = planArr?.find(
                session =>
                    session?.status === 'ACTIVE' &&
                    (session?.domain === 'FIBER' || session?.domain == 'DSL') &&
                    (session?.serviceGroup === 'DUALPLAY' ||
                        session?.serviceGroup == 'DUALPLAY_PSTN') &&
                    session?.subId === tvParentId
            );

            if (broadbandSession) {
                const bbId = broadbandSession.serviceId;
                setApvId(bbId);
                getApvDetailsUtil(bbId);
            }
        } else {
            const firstBB = planArr?.find(
                session =>
                    session?.status === 'ACTIVE' &&
                    (session?.domain === 'FIBER' || session?.domain == 'DSL') &&
                    (session?.serviceGroup === 'DUALPLAY' ||
                        session?.serviceGroup == 'DUALPLAY_PSTN')
            );

            if (firstBB) {
                const firstBBId = firstBB.serviceId;
                setApvId(firstBBId);
                getApvDetailsUtil(firstBBId);
            }
        }
    }, [planArr]);

    const ServiceArrayReload = async () => {
        setServiceArrayReloading(true);
        setIsLoading(true);
        setIsContractsError(false);
        getAdobeOffersUtil();
        // let activeAddons = await getCurrentlySubscribedAddonsForService({
        //     graphql,
        //     servicesIds: sessionStorage.getItem('LoggedinServices')
        //         ? JSON.parse(sessionStorage.getItem('LoggedinServices'))[0].id
        //         : null,
        // });
        // if (activeAddons) {
        //     setActiveAddons(activeAddons);
        // }
    };
    subscribe('ADDON_ADDED', ServiceArrayReload);

    return (
        <Container ref={serviceRef} maxWidth='lg' className={classes.container} disableGutters>
            {onOfferPage === true && !hasNoOffer ? (
                <Box>
                    <Typography className={classes.heading1}>Offers for your service</Typography>
                    <Typography className={classes.description}>
                        Your broadband plan is the base for all available offers and upgrades, be
                        aware that any changes you make to the plan can affect what offers and
                        upgrades are available to you.
                    </Typography>
                </Box>
            ) : onOfferPage === false ? (
                <Typography variant='h3' className={classes.heading}>
                    My Services
                </Typography>
            ) : (
                ''
            )}

            <Grid container spacing={3}>
                {isLoading ? (
                    <div
                        className='services-loader'
                        style={{ width: '100%', justifyContent: 'center' }}
                    >
                        {isContractsError ? (
                            <div className='services-content-loading'>
                                Something went wrong, please try again later.
                                <span
                                    className='services-retry-loading'
                                    style={{
                                        cursor: 'pointer',
                                        textDecoration: 'underline',
                                    }}
                                    onClick={() => {
                                        setIsContractsError(false);
                                        getMyPlanDetailsUtil();
                                    }}
                                >
                                    Retry?
                                </span>
                            </div>
                        ) : (
                            <Loading
                                className='services-loading-animation'
                                style={{ justifyContent: 'center' }}
                            />
                        )}
                    </div>
                ) : (
                    finalArr?.length > 0 &&
                    finalArr?.map((card, index) => (
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={width >= 1020 ? gridMdProp : gridMdProp1}
                            key={index}
                        >
                            <MyEirServicesCard
                                isActiveTv={isActiveTv}
                                links={
                                    card.serviceGroup === SERVICE_GROUPS.DUALPLAY ||
                                    card.serviceGroup === SERVICE_GROUPS.DUALPLAY_PSTN
                                        ? !isActiveTv && card.serviceId === apvId
                                            ? [
                                                  {
                                                      url: '#',
                                                      text:
                                                          apvCustomerType &&
                                                          (apvCustomerType === 'active' ? (
                                                              apvOfferStatus ===
                                                              'ONGOING_TERMINATION' ? (
                                                                  <span
                                                                      className={
                                                                          classes.amazonPrime
                                                                      }
                                                                  >
                                                                      Your Amazon Prime Video
                                                                      subscription will be cancelled
                                                                      shortly
                                                                  </span>
                                                              ) : (
                                                                  <span
                                                                      className={
                                                                          classes.amazonPrime
                                                                      }
                                                                  >
                                                                      <CancelAPVPopup
                                                                          linkName='Cancel Amazon Prime'
                                                                          flag='redesign'
                                                                          isActiveTv={isActiveTv}
                                                                          numCards={numCards}
                                                                      />
                                                                  </span>
                                                              )
                                                          ) : (
                                                              <span className={classes.amazonPrime}>
                                                                  <MyEirAmazonPrimeVideo
                                                                      apvCustomerType={
                                                                          apvCustomerType
                                                                      }
                                                                      apvOfferCode={apvOffer}
                                                                      apvOfferStatus={
                                                                          apvOfferStatus
                                                                      }
                                                                      flag='redesign'
                                                                      isActiveTv={isActiveTv}
                                                                      numCards={numCards}
                                                                  />
                                                              </span>
                                                          )),
                                                  },
                                              ]
                                            : ''
                                        : card.serviceGroup === SERVICE_GROUPS.MOBILE
                                        ? [
                                              {
                                                  url: '#',
                                                  text:
                                                      card?.pinPuk &&
                                                      card?.pinPuk?.pin1Code !== null &&
                                                      card?.pinPuk?.puk1Code !== null ? (
                                                          <MyEirDisplayPINPUK
                                                              linkName='PIN & PUK'
                                                              key={index}
                                                              pin={card?.pinPuk?.pin1Code}
                                                              puk={card?.pinPuk?.puk1Code}
                                                              flag='redesign'
                                                          />
                                                      ) : (
                                                          ''
                                                      ),
                                              },
                                              {
                                                  url: '#',
                                                  text: card?.msisdn ? (
                                                      <MyEirPortingContainer
                                                          linkName='Keep my old number'
                                                          phoneNumber={card?.msisdn?.replace(
                                                              /(\d{3})(\d{3})(\d{4})/,
                                                              '$1 $2 $3'
                                                          )}
                                                          classes={{
                                                              link: classes.redesignLink,
                                                          }}
                                                          serviceId={card?.serviceId}
                                                          errorMessage={mobileError}
                                                          inactiveMobId={inactiveMobId}
                                                      />
                                                  ) : (
                                                      ''
                                                  ),
                                              },
                                          ]
                                        : card.serviceGroup === SERVICE_GROUPS.TV
                                        ? isActiveTv && isTvPin
                                            ? [
                                                  {
                                                      url: '#',
                                                      text:
                                                          apvCustomerType &&
                                                          (apvCustomerType === 'active' ? (
                                                              apvOfferStatus ===
                                                              'ONGOING_TERMINATION' ? (
                                                                  <span
                                                                      className={
                                                                          classes.amazonPrime
                                                                      }
                                                                  >
                                                                      Your Amazon Prime Video
                                                                      subscription will be cancelled
                                                                      shortly
                                                                  </span>
                                                              ) : (
                                                                  <span
                                                                      className={
                                                                          classes.amazonPrime
                                                                      }
                                                                  >
                                                                      <CancelAPVPopup
                                                                          linkName='Cancel Amazon Prime'
                                                                          flag='redesign'
                                                                          isActiveTv={isActiveTv}
                                                                          numCards={numCards}
                                                                      />
                                                                  </span>
                                                              )
                                                          ) : (
                                                              <span className={classes.amazonPrime}>
                                                                  <MyEirAmazonPrimeVideo
                                                                      apvCustomerType={
                                                                          apvCustomerType
                                                                      }
                                                                      apvOfferCode={apvOffer}
                                                                      apvOfferStatus={
                                                                          apvOfferStatus
                                                                      }
                                                                      flag='redesign'
                                                                      isActiveTv={isActiveTv}
                                                                      numCards={numCards}
                                                                  />
                                                              </span>
                                                          )),
                                                  },
                                                  {
                                                      url: '',
                                                      text: (
                                                          <MyEirTvPinContainer
                                                              linkName='Manage my PIN'
                                                              setIsTvPin={setIsTvPin}
                                                              flag='redesign'
                                                          />
                                                      ),
                                                  },
                                                  {
                                                      url: '#123',
                                                      text: (
                                                          <MyEirDeviceManagement flag='redesign' />
                                                      ),
                                                  },
                                              ]
                                            : // : ''
                                            !isActiveTv && isTvPin
                                            ? [
                                                  {
                                                      url: '',
                                                      text: (
                                                          <MyEirTvPinContainer
                                                              linkName='Manage my PIN'
                                                              setIsTvPin={setIsTvPin}
                                                              flag='redesign'
                                                          />
                                                      ),
                                                  },
                                                  {
                                                      url: '#123',
                                                      text: (
                                                          <MyEirDeviceManagement flag='redesign' />
                                                      ),
                                                  },
                                              ]
                                            : [
                                                  {
                                                      url: '#123',
                                                      text: (
                                                          <MyEirDeviceManagement flag='redesign' />
                                                      ),
                                                  },
                                              ]
                                        : // : card.serviceGroup === 'MBB'
                                          // ? [
                                          //       {
                                          //           url: '#',
                                          //           text:
                                          //               card?.pinPuk &&
                                          //               card?.pinPuk?.pin !== null &&
                                          //               card?.pinPuk?.puk !== null ? (
                                          //                   <MyEirDisplayPINPUK
                                          //                       key={index}
                                          //                       pin={card?.pinPuk?.pin}
                                          //                       puk={card?.pinPuk?.puk}
                                          //                       flag='redesign'
                                          //                   />
                                          //               ) : (
                                          //                   ''
                                          //               ),
                                          //       },
                                          //   ]
                                          []
                                }
                                icon={card.image}
                                numCards={numCards}
                                hasNoOffer={hasNoOffer}
                                finalArr={finalArr}
                                onOfferPage={onOfferPage}
                                banner={
                                    width > 1020
                                        ? finalArr.length === 1
                                            ? hasNoOffer
                                                ? defaultImage
                                                : window.location.origin +
                                                  card?.servicesOffer?.attributes
                                                      .filter(
                                                          attr =>
                                                              attr?.type ===
                                                              'myEirAvailableOfferImage'
                                                      )
                                                      .map(attr => attr?.value)[0]
                                            : finalArr.length === 2
                                            ? window.location.origin +
                                              card?.servicesOffer?.attributes
                                                  .filter(
                                                      attr =>
                                                          attr?.type === 'myEirAvailableOfferImage'
                                                  )
                                                  .map(attr => attr?.value)[1]
                                            : window.location.origin +
                                              card?.servicesOffer?.attributes
                                                  .filter(
                                                      attr =>
                                                          attr?.type === 'myEirAvailableOfferImage'
                                                  )
                                                  .map(attr => attr?.value)[2]
                                        : hasNoOffer
                                        ? defaultImage
                                        : window.location.origin +
                                          card?.servicesOffer?.attributes
                                              .filter(
                                                  attr => attr?.type === 'myEirAvailableOfferImage'
                                              )
                                              .map(attr => attr?.value)[2]
                                }
                                modalImage={
                                    width >= 600
                                        ? window.location.origin +
                                          card?.servicesOffer?.attributes
                                              .filter(
                                                  attr => attr?.type === 'myEirAvailableOfferImage'
                                              )
                                              .map(attr => attr?.value)[3]
                                        : window.location.origin +
                                          card?.servicesOffer?.attributes
                                              .filter(
                                                  attr => attr?.type === 'myEirAvailableOfferImage'
                                              )
                                              .map(attr => attr?.value)[4]
                                }
                                title={card?.title}
                                offerName={card?.offerName?.description}
                                number={
                                    card.serviceGroup == SERVICE_GROUPS.DUALPLAY ||
                                    card.serviceGroup == SERVICE_GROUPS.DUALPLAY_PSTN ||
                                    card.serviceGroup == SERVICE_GROUPS.SA_PSTN
                                        ? card.sipNumber
                                        : card.serviceGroup == MOBILE ||
                                          card.serviceGroup == SERVICE_GROUPS.MBB
                                        ? card.msisdn
                                        : ''
                                }
                                errorMessage={
                                    isInactiveUser
                                        ? card.serviceGroup == SERVICE_GROUPS.DUALPLAY ||
                                          card.serviceGroup == SERVICE_GROUPS.DUALPLAY_PSTN ||
                                          card.serviceGroup == SERVICE_GROUPS.SA_PSTN
                                            ? broadbandError
                                                ? card?.status === 'INITIAL'
                                                    ? 'Your service numbers will be available soon'
                                                    : ''
                                                : ''
                                            : card?.serviceGroup == SERVICE_GROUPS.MOBILE
                                            ? mobileError
                                                ? card?.status === 'INITIAL'
                                                    ? 'Your mobile number will be available soon'
                                                    : ''
                                                : ''
                                            : card?.serviceGroup == 'MBB'
                                            ? mobileError
                                                ? card?.status === 'INITIAL'
                                                    ? 'Your service number will be available soon'
                                                    : ''
                                                : ''
                                            : ''
                                        : ''
                                }
                                miniText={
                                    card?.adobeOffer
                                        ? card?.adobeOffer?.type === 'addon'
                                            ? 'Add on'
                                            : card?.adobeOffer?.type === 'RECONTRACT'
                                            ? 'Recontract your'
                                            : 'Available offer'
                                        : ''
                                }
                                name={
                                    card?.adobeOffer?.type === 'addon'
                                        ? card?.servicesOffer?.attributes
                                              .filter(
                                                  attr => attr?.type === 'MyEirOfferCardDisplayName'
                                              )
                                              .map(attr => attr?.value)[0]
                                        : (card?.servicesOffer?.attributes || [])
                                              .filter(
                                                  attr => attr.type === 'MyEirOfferCardDisplayName'
                                              )
                                              .map(attr => attr.value)
                                              .slice(0, 2)
                                              .join(' ')
                                }
                                price={card?.adobeOffer?.price}
                                validityText='a month'
                                background={
                                    card.serviceGroup == SERVICE_GROUPS.DUALPLAY ||
                                    card.serviceGroup == SERVICE_GROUPS.DUALPLAY_PSTN ||
                                    card.serviceGroup == SERVICE_GROUPS.SA_PSTN
                                        ? mockData?.categories?.broadband?.background
                                        : card?.serviceGroup === SERVICE_GROUPS.TV
                                        ? mockData?.categories?.tv?.background
                                        : card?.serviceGroup === SERVICE_GROUPS.MOBILE
                                        ? mockData?.categories?.mobile?.background
                                        : mockData?.categories?.mbb?.background
                                }
                                subscriptionId={card?.adobeOffer?.subscriptionID}
                                propositionId={card?.adobeOffer?.propositionId}
                                attributes={card?.servicesOffer}
                                serviceGroup={card?.serviceGroup}
                                adobeOffer_tariff_code={card?.adobeOffer?.tariff_code}
                                contractType={card?.adobeOffer?.type}
                            />
                        </Grid>
                    ))
                )}
            </Grid>
        </Container>
    );
};
export default MyEirServicesContainer;
