import { gql } from '@apollo/client';

const ENDPOINT = {
    GET_BILLING_HISTORY: gql`
        query getBillingHistory($token: String!, $accountId: String!) {
            getBillingHistory(token: $token, accountId: $accountId) {
                amount
                dueDate
                invoiceDate
                invoiceNumber
            }
        }
    `,

    GET_BALANCE: gql`
        query getBalance($token: String!, $billingAccountId: String!) {
            getBalanceDetails(token: $token, billingAccountId: $billingAccountId) {
                nonOverdueAmount
                overdueAmount
                lastBillOverdueDate
                date
            }
        }
    `,

    GET_MY_BILL_DETAILS: gql`
        query getMyBillDetails($token: String!, $invoiceId: String!, $billingAccountId: String!) {
            getMyBillDetails(
                token: $token
                invoiceId: $invoiceId
                billingAccountId: $billingAccountId
            ) {
                bills {
                    billAmount {
                        invoiceAmount
                        oneOffAmount
                        recurringAmount
                        usageAmount
                        vatAmount
                    }
                    billPeriod
                    effectiveDate
                    invoiceId
                }
            }
        }
    `,
};

export const getBalanceApi = async ({ graphql, token, billingAccountId }) => {
    try {
        const data = await graphql.query({
            query: ENDPOINT.GET_BALANCE,
            variables: {
                token: token,
                billingAccountId: billingAccountId,
            },
        });
        return data;
    } catch (error) {
        throw new Error(error);
    }
};

export const getMyBillDetailsApi = async ({ graphql, token, invoiceId }) => {
    try {
        const data = await graphql.query({
            query: ENDPOINT.GET_MY_BILL_DETAILS,
            variables: {
                token: token,
                invoiceId: invoiceId,
                billingAccountId: sessionStorage.getItem('LoggedinUserAccountID'),
            },
        });

        return data;
    } catch (error) {
        throw new Error(error);
    }
};
