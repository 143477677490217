import { makeStyles } from '@material-ui/core/styles';
export const usePhoneNumber = makeStyles(theme => ({
    enterPhoneBody: {
        margin: '0 auto',
        minHeight: '155px',
        paddingTop: '42px',
        paddingBottom: '40px',
        position: 'relative',
        paddingLeft: '123px',
        paddingRight: '123px',

        [theme.breakpoints.down('xs')]: {
            minHeight: '294px',
            marginTop: '39px',
            padding: '0 16px',
        },
        '@media(min-width:600px) and (max-width:1019px)': {
            // marginTop: '39px',
            padding: '0 16px',
            paddingTop: '42px',
            paddingBottom: '40px',
        },
        '& .register-grid-text-information': {
            marginBottom: '32px',
            '@media(max-width: 599px)': {
                marginBottom: '23px',
            },
        },
        '& .register-grid-text-content': {
            // fontFamily: theme.typography.body2.fontFamily,
            // fontStyle: 'normal',
            // fontWeight: 400,
            // fontSize:theme.typography.subtitle2.fontSize,
            // lineHeight: '22px',
            color: theme.palette.primary.heather,
            textAlign: 'left',
        },
    },

    reCaptcha: {
        paddingTop: '40px',

        [theme.breakpoints.down('xs')]: {
            paddingTop: '39px',
            transform: 'scale(0.77)',
            transformOrigin: '0 0',
        },
        '@media(min-width:600px) and (max-width:1019px)': {
            paddingTop: '39px',
            marginLeft: '75px',
        },
    },
    errormessage: {
        '& .invalid-messages': {
            fontSize: theme.typography.inputLabel.fontSize,
            lineHeight: theme.typography.body1.lineHeight,
            letterSpacing: '0.4px',
            color: theme.palette.primary.error,
            marginTop: '3px',
            fontFamily: theme.typography.body1.fontFamily,
            // fontStyle: 'normal',
            fontWeight: theme.typography.body1.fontWeight,
            textAlign: 'left',
        },
    },
    codeNumber: {
        maxWidth: '94px',
        width: '100%',
        padding: '0 !important',
    },

    formControl: {
        width: '100%',

        '& label.MuiFormLabel-root': {
            fontFamily: theme.typography.body1.fontFamily,
            // fontStyle: 'normal',
            fontWeight: theme.typography.body1.fontWeight,
            fontSize: theme.typography.body1.fontSize,
            lineHeight: theme.typography.body1.lineHeight,
            color: theme.palette.primary.heather80,
        },
        '& label.Mui-focused': {
            color: theme.palette.primary.heather80,
        },
        '& label.Mui-error': {
            color: theme.palette.primary.error,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: theme.palette.primary.heather80,
        },
        '& .MuiOutlinedInput-root': {
            '& .MuiOutlinedInput-input, .MuiSelect-nativeInput': {
                fontFamily: theme.typography.body1.fontFamily,
                // fontStyle: 'normal',
                fontWeight: theme.typography.body1.fontWeight,
                fontSize: theme.typography.body1.fontSize,
                lineHeight: theme.typography.body1.lineHeight,
                color: theme.palette.primary.heather80,
            },
            '& fieldset': {
                borderColor: theme.palette.primary.heather80,
            },
            '&:hover fieldset': {
                borderColor: theme.palette.primary.heather80,
            },
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.primary.heather80,
            },
            '&.Mui-error fieldset': {
                borderColor: theme.palette.primary.error,
            },
        },
    },

    textField: {
        width: '100%',

        '& label.MuiFormLabel-root': {
            fontFamily: theme.typography.body1.fontFamily,
            // fontStyle: 'normal',
            fontWeight: theme.typography.body1.fontWeight,
            fontSize: theme.typography.body1.fontSize,
            lineHeight: theme.typography.body1.lineHeight,
            color: theme.palette.primary.heather80,
            color: theme.palette.primary.heather80,
        },
        '& label.Mui-focused': {
            color: theme.palette.primary.heather80,
        },
        '& label.Mui-error': {
            color: theme.palette.primary.error,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: theme.palette.primary.heather80,
        },
        '& .MuiOutlinedInput-root': {
            '& .MuiOutlinedInput-input': {
                fontFamily: theme.typography.body1.fontFamily,
                fontStyle: 'normal',
                fontWeight: theme.typography.body1.fontWeight,
                fontSize: theme.typography.body1.fontSize,
                lineHeight: theme.typography.body1.lineHeight,
                color: theme.palette.primary.heather80,
            },
            '&.MuiOutlinedInput-root.Mui-error': {
                '& .MuiInputBase-input': {
                    borderColor: theme.palette.primary.heather80,
                },
                '& .fieldset': {
                    borderColor: theme.palette.primary.heather80,
                },
            },
            '& fieldset': {
                borderColor: theme.palette.primary.heather80,
            },
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.primary.heather80,
            },
            '&.Mui-error fieldset': {
                borderColor: theme.palette.primary.error,
            },
        },
    },

    phoneNumber: {
        maxWidth: '274px',
        padding: '0 !important',
        width: '100%',
        [theme.breakpoints.down('xs')]: {
            minWidth: '130px',
            maxWidth: '100%',
            marginLeft: '8px',
            flex: '1',
        },
        '@media(min-width:600px) and (max-width:1019px)': {
            minWidth: '130px',
            maxWidth: '100%',
            marginLeft: '8px',
            flex: '1',
        },
    },

    phoneCodeGrid: {
        display: 'flex',
        justifyContent: 'space-between',
        maxWidth: '384px',
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'flex-start',
            maxWidth: '100%',
            margin: '0',
        },
        '@media(min-width:600px) and (max-width:1019px)': {
            // justifyContent: 'flex-start',
            // maxWidth: '100%',
            // margin: '0'
            justifyContent: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
            maxWidth: '75%',
        },
    },
    emailAddress: {
        maxWidth: '274px',
        padding: '0 !important',
        width: '100%',
        [theme.breakpoints.down('xs')]: {
            minWidth: '130px',
            maxWidth: '100%',
            flex: '1',
        },
        '@media(min-width:600px) and (max-width:1019px)': {
            minWidth: '130px',
            maxWidth: '100%',
            marginLeft: '8px',
            flex: '1',
        },
    },
    invalidEmailFormat: {
        fontFamily: theme.typography.inputLabel.fontFamily,
        fontStyle: 'normal',
        fontWeight: theme.typography.inputLabel.fontWeight,
        fontSize: theme.typography.inputLabel.fontSize,
        lineHeight: theme.typography.inputLabel.lineHeight,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        letterSpacing: '0.4px',
        color: theme.palette.primary.error,
        marginTop: '3px',
    },
    msg: {
        maxHeight: '44px',
        textAlign: 'left',
        fontWeight: theme.typography.body2.fontWeight,
        fontSize: theme.typography.body2.fontSize,
        lineHeight: '157%',
        color: theme.palette.primary.heather80,
        fontFamily: theme.typography.inputLabel.fontFamily,
        marginTop: '30px',
        paddingRight: '12px',
        [theme.breakpoints.down('xs')]: {
            marginTop: '30px',
            paddingRight: '0px',
        },
        '@media(min-width:600px) and (max-width:1019px)': {
            paddingLeft: '72px',
            paddingRight: '27px',
        },
    },
    emailText: {
        [theme.breakpoints.down('xs')]: {
            marginBottom: '56px',
        },
    },
    secondMsg: {
        maxHeight: '44px',
        textAlign: 'left',
        fontWeight: theme.typography.inputLabel.fontWeight,
        fontSize: theme.typography.subtitle2.fontSize,
        lineHeight: '157%',
        color: theme.palette.primary.heather,
        fontFamily: theme.typography.inputLabel.fontFamily,
        textDecoration: 'none',
        [theme.breakpoints.down('xs')]: {
            marginBottom: '56px',
        },
    },
    emailLink: {
        color: theme.palette.primary.heather,
        textDecoration: 'underline',
        fontWeight: theme.typography.body2.fontWeight,
        fontSize: theme.typography.body2.fontSize,
        fontFamily: theme.typography.inputLabel.fontFamily,
        cursor: 'pointer',
        textAlign: 'left',
    },
}));
