import React, { useContext, useEffect, useState } from 'react';
import Icon from '../images/inactiveIcon.png';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
    Hidden,
    Box,
} from '@material-ui/core';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { useMyPaymentsStyle } from './useMyPaymentsStyle';
import { useWindowDimension } from '../../../hooks/windowDimensions';
import myPaymentsIcon from '../../images/myPayments.png';
import ENDPOINT from '../../graphql/GraphQLConstants';
import { useApolloClient } from '@apollo/client';
import Loading from '../../../components/loading/Loading';
import MyEirPaymentDetails from './MyEirPaymentDetails';
import { MobileContext } from '../../global-context';
import { getNameAndAccountID } from '../../getNameAndAccountNumber';
import MyEirLoginHeader from '../../my-login/MyEirLoginHeader';
import { useHistory } from 'react-router-dom';
import MyEirInactiveUser from '../Inactive-user/MyEirInactiveUser';
import { ArrowBackIcon } from '../../../sharedcomponents/inputs/svg-icons/CustomSvgIcons';

const MyEirMyPaymentsNew = () => {
    const [collapse, setcollapse] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [initialUser, setInitialUser] = useState(false);
    const [seeMore, setSeeMore] = useState(true);
    const [showMore, setShowMore] = useState();
    const [showLess, setShowLess] = useState();
    const [moreLess, setMoreLess] = useState();
    const [paymentsLoadingError, setPaymentsLoadingError] = useState(false);
    const [loading, setLoading] = useState(true);
    const classes = useMyPaymentsStyle();
    const [paymentHistory, setPaymentHistory] = useState([]);

    // console.log("Payment History data", paymentHistory);
    const description = `As a new customer, you don't have any payments to display yet. Check back soon to view your payments.`;
    const toggle = () => {
        setcollapse(!collapse);
    };

    const theme = createMuiTheme({
        //Overriding default breakpoints of material UI with custom breakpoints.
        breakpoints: {
            values: {
                xs: 0,
                sm: 599,
                md: 600,
                lg: 1019,
                xl: 1020,
            },
        },
    });

    const graphql = useApolloClient();

    const history = useHistory();

    async function getPaymentHistory() {
        try {
            const token = sessionStorage?.getItem('access_token');
            let billingAccountId = sessionStorage?.getItem('LoggedinUserAccountNumber');
            if (!billingAccountId) {
                await getNameAndAccountID(graphql);
                billingAccountId = sessionStorage?.getItem('LoggedinUserAccountNumber');
            }
            if (token && billingAccountId) {
                const response = await graphql.query({
                    query: ENDPOINT.GET_MY_BALANCE,
                    variables: {
                        token: token,
                        billingAccountId: billingAccountId,
                    },
                });
                if (response.data.getAccountSummary != undefined) {
                    setPaymentHistory(response.data.getAccountSummary);
                }
            } else {
                console.log('Unable to fetch payment history****');
            }
            setLoading(false);
        } catch (error) {
            setPaymentsLoadingError(true);
            console.error('Error in fetching payment history : ', error.message);
        }
    }

    useEffect(() => {
        getPaymentHistory();
    }, []);

    useEffect(() => {
        const sessionValue = JSON.parse(sessionStorage.getItem('LoggedinServices'));
        const initial = sessionValue?.find(user => user.status === 'INITIAL');

        if (initial) {
            setInitialUser(true);
        }
    }, []);

    const arrowAction = () => {
        history.push('/account/dashboard');
    };

    return (
        <>
            <ThemeProvider theme={theme}>
                <Box className={classes.MyPaymentsWrapper}>
                    <Box className='myPaymentsContainer'>
                        <Box className={classes.headerBackArrow}>
                            <ArrowBackIcon onClick={arrowAction} />
                        </Box>
                        <Box className={classes.detailsHeaderText}>My payments</Box>
                        <Box className={classes.bottomLine}></Box>
                        {loading ? (
                            <Box
                                className='payment-loader'
                                style={{ justifyContent: 'center', padding: '20px' }}
                            >
                                {paymentsLoadingError ? (
                                    <Box className='payments-content-loading'>
                                        Something went wrong, please try again later.
                                        <span
                                            className='payments-retry-loading'
                                            style={{
                                                cursor: 'pointer',
                                                textDecoration: 'underline',
                                            }}
                                            onClick={() => {
                                                setPaymentsLoadingError(false);
                                                getPaymentHistory();
                                            }}
                                        >
                                            Retry?
                                        </span>
                                    </Box>
                                ) : (
                                    <Loading className='mypayments-loading-animation' />
                                )}
                            </Box>
                        ) : (
                            <>
                                {initialUser ? (
                                    <Box className={classes.inActiveUserContainer}>
                                        <MyEirInactiveUser
                                            title='Welcome to My eir!'
                                            description={description}
                                            emptyIcon={Icon}
                                        />
                                    </Box>
                                ) : (
                                    <>
                                        {paymentHistory.length != 0 ? (
                                            <MyEirPaymentDetails paymentHistory={paymentHistory} />
                                        ) : (
                                            <Box className='payment-transaction-history-container'>
                                                <Box className='payment-transaction-history-text'>
                                                    Account has no transaction history.
                                                </Box>
                                            </Box>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </Box>
                </Box>
            </ThemeProvider>
        </>
    );
};

export default MyEirMyPaymentsNew;
