import React, { useState } from 'react';
import EirButton from '../../theme/EirButton';
import { useLoginFormStyle } from '../css/useLoginFormStyle';

const MyEirLoginFooter = props => {
    const styleClass = useLoginFormStyle();

    return (
        <div
            className={
                styleClass.footerContainer +
                ' ' +
                (props.button2Text == undefined || props.button1Text == undefined
                    ? styleClass.footerContainer1
                    : '') +
                ' ' +
                (props.button2Text == undefined && props.button1Text == undefined
                    ? styleClass.footerContainerNone
                    : '')
            }
        >
            {props.button1Text != undefined && (
                <EirButton
                    variants='primaryButtonMedium'
                    style={{ width: '7.125rem' }}
                    disabled={!props.button1Enabled}
                    onClick={props.handleButton1Click}
                >
                    {props.button1Text}
                </EirButton>
            )}
            {props.button2Text != undefined && (
                <div className='secondary-cta footer-cta'>
                    <span style={{ cursor: 'pointer' }} onClick={props.handleButton2Click}>
                        {props.button2Text}
                    </span>
                </div>
            )}
        </div>
    );
};

export default MyEirLoginFooter;
