import React, { useState } from 'react';
import pdflogo from '../../images/pdf.png';
import BillDetails from './BillDetails';
import { convertDateFormat } from '../redesign.util';
import { downloadInvoicePDF } from './myBills.util';
import { useApolloClient } from '@apollo/client';
import { Box } from '@material-ui/core';

const MyEirBills = ({ index, item, width, page, lastBillDueDate }) => {
    const [hidePDF, setHidePDF] = useState(false);
    const graphql = useApolloClient();
    const invoiceNumber = item.invoiceNumber;
    // const [balanceFlag, setbalanceFlag] = useState(true);
    const [isopen, setIsOpen] = useState(true);
    const [billDetail, setbillDetail] = useState(false);

    const toggle = () => {
        setIsOpen(!isopen);
        setbillDetail(true);
    };
    const downloadInvoicePDFUtil = async () => {
        await downloadInvoicePDF({ graphql, invoiceNumber, setHidePDF });
    };
    function convertDueDate(str) {
        const strDate = str?.split('T');
        const dateVal = `${strDate[0]}`;
        const dateArr = dateVal.split('-');
        const myDate = new Date(`${dateArr[0]}-${dateArr[1]}-${dateArr[2]}`);
        const myMonth = myDate.toLocaleString('default', { month: 'short' });
        return `${dateArr[2]} ${myMonth} ${dateArr[0]}`;
    }

    return (
        <Box className={'bills-summary bill-' + (index + 1)}>
            <div className='issuedOn'>
                <div className='issuedOnProp'>{width > 1019 ? 'Issued on' : 'Bill date'}</div>
                <div className='issuedOnValue'>{convertDateFormat(item.invoiceDate)}</div>
            </div>
            <div className='amountDue'>
                <div className='amountDueProp'>Amount due</div> {/*due or not based on paid info*/}
                <div className='amountDueValue'>€{(item.amount / 100).toFixed(2)}</div>
            </div>
            {/* display either due message or paid message based on API data */}
            {(JSON.parse(sessionStorage.getItem('myBalance')) / 100).toFixed(2) < 0 && (
                <div className='dueMessage'>
                    {index === 0 && page === 0 && (
                        <>
                            Direct debit due on{' '}
                            {lastBillDueDate === null ? '' : convertDueDate(lastBillDueDate)}
                        </>
                    )}
                </div>
            )}
            {width > 1019 ? (
                <>
                    {/* <div className='billDetails'>{fullbill}</div> */}
                    {item?.billingSource === 'ION' ? (
                        <>
                            <div
                                className={'billDetails' + (isopen ? ' open' : '')}
                                onClick={toggle}
                            >
                                See bill details
                            </div>
                            <BillDetails
                                isopen={billDetail}
                                setIsOpen={setbillDetail}
                                invoiceNumber={item.invoiceNumber}
                                invoiceDate={item.invoiceDate}
                                dueDate={item.dueDate}
                                // balanceFlag={balanceFlag}
                                index={index}
                                lastBillDueDate={lastBillDueDate}
                            />
                        </>
                    ) : (
                        <div className={'billDetails'}></div>
                    )}
                    <div
                        className={'pdfDownload' + (hidePDF ? ' hide' : ' ')}
                        onClick={() => {
                            downloadInvoicePDFUtil(item.invoiceNumber);
                        }}
                    >
                        <img className='pdfDownloadImage' src={pdflogo} alt='PDF download' />
                    </div>
                </>
            ) : (
                <div className='billDetailsPDF'>
                    {/* <div className='billDetails'>See bill details</div> */}
                    {item?.billingSource === 'ION' ? (
                        <>
                            <div
                                className={'billDetails' + (isopen ? ' open' : '')}
                                onClick={toggle}
                            >
                                See bill details
                            </div>
                            <BillDetails
                                isopen={billDetail}
                                setIsOpen={setbillDetail}
                                invoiceNumber={item.invoiceNumber}
                                invoiceDate={item.invoiceDate}
                                dueDate={item.dueDate}
                                index={index}
                                lastBillDueDate={lastBillDueDate}
                            />
                        </>
                    ) : (
                        <div className={'billDetails'}></div>
                    )}
                    <div
                        className={'pdfDownload' + (hidePDF ? ' hide' : ' ')}
                        onClick={() => {
                            downloadInvoicePDFUtil(item.invoiceNumber);
                        }}
                    >
                        <img className='pdfDownloadImage' src={pdflogo} alt='PDF download' />
                    </div>
                </div>
            )}
        </Box>
    );
};

export default MyEirBills;
