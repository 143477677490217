import React, { useEffect, useState } from 'react';
import Switch from '@material-ui/core/Switch';
import { convertPhoneNumber } from '../redesign.util';
import {
    Grid,
    Tooltip,
    FormControl,
    InputBase,
    withStyles,
    MenuItem,
    Select,
    Box,
} from '@material-ui/core';
import { publish } from 'pubsub-js';
import '../../../sass/mobile-header.scss';
import IconButton from '@material-ui/core/IconButton';
import { useHistory } from 'react-router-dom';
import { useMyAccountStyle } from '../../redesign-5.2/account-details/useMyAccountStyle';
import {
    handleToggleChange2,
    updateProfileDetails,
    updateBillDeliveryType,
    getNDDNumbers,
    getNDDForNumber,
    fetchProfileDetails,
} from './myPreference.util';
import { useApolloClient } from '@apollo/client';
import Loading from '../../../components/loading/Loading';
import { useLoggedInStyle } from '../../css/useLoggedInStyle';
import DirectoryOptions from '../../my-account/directory-options/DirectoryOptions';
import { useWindowDimension } from '../../../hooks/windowDimensions';
import { triggerAdobeApi } from '../redesign.api';
import { ArrowBackIcon } from '../../../sharedcomponents/inputs/svg-icons/CustomSvgIcons';
import { ACCOUNT_STATUS } from '../../../components/myeirconstant/myEirConstant';

const BootstrapInput = withStyles(theme => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    formControl: {
        // minHeight: "56px",
    },
    input: {
        minHeight: '56px',
        border: '1px solid ${theme.palette.primary.heather80}',
        boxSizing: 'border-box',
        borderRadius: '4px',
        fontFamily: theme.typography.body2.fontFamily,
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '19px',
        letterSpacing: '0.15px',
        color: theme.palette.primary.heather,

        position: 'relative',
        backgroundColor: theme.palette.background.paper,

        padding: '18px 43px 17px 11px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
    },
}))(InputBase);

const MyPreferences = () => {
    const classes = useMyAccountStyle();
    // const classes1 = useStyles();
    const loaderClasses = useLoggedInStyle();
    const [loading, setLoading] = useState(true);
    const [retry, setRetry] = useState('');
    const [width, height] = useWindowDimension();
    const token = sessionStorage.getItem('access_token');
    const serviceId = sessionStorage.getItem('LoggedinServices')
        ? JSON.parse(sessionStorage.getItem('LoggedinServices'))[0].id
        : null;
    const [nddNumbers, setNddNumbers] = useState([]);
    const [selectedServiceId, setselectedServiceId] = useState('');
    const [directoryPreferences, setDirectoryPreferences] = useState({});
    const history = useHistory();

    const userStatus = sessionStorage.getItem('Account_status');

    const [state, setState] = React.useState({
        activeCustomer_email: true,
        activeCustomer_sms: true,
        activeCustomer_phone: true,
        activeCustomer_ftf: true,
        activeCustomer_directMail: true,
        nonactiveCustomer_email: true,
        nonactiveCustomer_sms: true,
        nonactiveCustomer_phone: true,
        nonactiveCustomer_ftf: true,
        nonactiveCustomer_directMail: true,
        allowThirdParty: true,
        invoiceDeliveryType: false,
        recommendedOffers: true,
    });

    const graphql = useApolloClient();

    const handlesetServiceId = id => {
        localStorage.setItem('NDDServiceId', id);
    };

    const handleChangeNDDPreference = value => {
        setLoading(true);
        handleToggleChange2({
            setLoading,
            graphql,
            selectedServiceId,
            value,
            setRetry,
            nddNumbers,
            setDirectoryPreferences,
        });
    };

    // Directory Options - on select of a mobile service
    const handleServiceIDChange = async event => {
        setLoading(true);
        setselectedServiceId(event.target.value);
        await getNDDForNumber({
            number: nddNumbers.filter(number => number.serviceId === event.target.value)[0].phoneNo,
            serviceId: Number(event.target.value),
            setDirectoryPreferences,
            setLoading,
            graphql,
            token,
            setRetry,
        });
    };
    const handleupdateBillDeliveryType = async event => {
        await updateBillDeliveryType({
            event,
            setLoading,
            setState,
            state,
            graphql,
            token,
            setRetry,
        });
    };

    const handleRecommendedOffers = async event => {
        setState({
            ...state,
            [event.target.name]: event.target.checked,
        });
        let recommendedOption;
        if (!state.recommendedOffers) {
            recommendedOption = 'optInRecommendedOffers';
        } else {
            recommendedOption = 'optOutRecommendedOffers';
        }

        await triggerAdobeApi({ graphql, name: recommendedOption });
    };

    const handleNDDNumbers = async () => {
        await getNDDNumbers({
            setNddNumbers,
            setselectedServiceId,
            setDirectoryPreferences,
            setLoading,
            graphql,
            token,
            setRetry,
        });
    };

    const handlefetchProfileDetails = async () => {
        await fetchProfileDetails({
            setState,
            setLoading,
            graphql,
            token,
            serviceId,
            setRetry,
            state,
        });
    };

    const handleChange = event => {
        event.preventDefault();
        setState({
            ...state,
            [event.target.name]: event.target.checked,
        });
        var allow_third_party = event.target.name == 'allowThirdParty' ? true : false;
        setLoading(true);
        const permissionGroups = [
            {
                permissionGroup: 'ACTIVE_CUSTOMER',
                permissions: [
                    {
                        enabled:
                            event.target.name == 'activeCustomer_email'
                                ? !state.activeCustomer_email
                                : state.activeCustomer_email,
                        permission: 'ALLOW_EMAIL_CONTACT',
                    },
                    {
                        enabled:
                            event.target.name == 'activeCustomer_sms'
                                ? !state.activeCustomer_sms
                                : state.activeCustomer_sms,
                        permission: 'ALLOW_SMS_CONTACT',
                    },
                    {
                        enabled:
                            event.target.name == 'activeCustomer_phone'
                                ? !state.activeCustomer_phone
                                : state.activeCustomer_phone,
                        permission: 'ALLOW_PHONE_CONTACT',
                    },
                    {
                        enabled:
                            event.target.name == 'activeCustomer_ftf'
                                ? !state.activeCustomer_ftf
                                : state.activeCustomer_ftf,
                        permission: 'ALLOW_FOTS_CONTACT',
                    },
                    {
                        enabled:
                            event.target.name == 'activeCustomer_directMail'
                                ? !state.activeCustomer_directMail
                                : state.activeCustomer_directMail,
                        permission: 'ALLOW_DIRECT_MAIL_CONTACT',
                    },
                ],
            },
            {
                permissionGroup: 'NO_LONGER_CUSTOMER',
                permissions: [
                    {
                        enabled:
                            event.target.name == 'nonactiveCustomer_email'
                                ? !state.nonactiveCustomer_email
                                : state.nonactiveCustomer_email,
                        permission: 'ALLOW_EMAIL_CONTACT',
                    },
                    {
                        enabled:
                            event.target.name == 'nonactiveCustomer_sms'
                                ? !state.nonactiveCustomer_sms
                                : state.nonactiveCustomer_sms,
                        permission: 'ALLOW_SMS_CONTACT',
                    },
                    {
                        enabled:
                            event.target.name == 'nonactiveCustomer_phone'
                                ? !state.nonactiveCustomer_phone
                                : state.nonactiveCustomer_phone,
                        permission: 'ALLOW_PHONE_CONTACT',
                    },
                    {
                        enabled:
                            event.target.name == 'nonactiveCustomer_ftf'
                                ? !state.nonactiveCustomer_ftf
                                : state.nonactiveCustomer_ftf,
                        permission: 'ALLOW_FOTS_CONTACT',
                    },
                    {
                        enabled:
                            event.target.name == 'nonactiveCustomer_directMail'
                                ? !state.nonactiveCustomer_directMail
                                : state.nonactiveCustomer_directMail,
                        permission: 'ALLOW_DIRECT_MAIL_CONTACT',
                    },
                ],
            },
        ];

        updateProfileDetails({
            updatedpermissionGroups: permissionGroups,
            allow_third_party,
            setLoading,
            graphql,
            state,
            token,
            serviceId,
            setRetry,
        });
    };

    const tooltipText = (
        <div class='p-3 pt-4 pb-4' style={{ fontSize: '12px', lineHeight: '15px' }}>
            <strong>Listed:</strong> Available from directory enquiries and the on-line phonebook{' '}
            <br />
            <br />
            <strong>Unlisted:</strong> Available from directory enquiries but not in the on-line
            phonebook <br />
            <br />
            <strong>Ex-directory:</strong> Not in National Directory Database and not in the
            Phonebook
        </div>
    );

    const tooltipTextMobile = (
        <div class='p-2' style={{ fontSize: '12px', lineHeight: '15px' }}>
            <strong>Listed:</strong> Available from directory
            <br /> enquiries and the on-line
            <br /> phonebook <br />
            <br />
            <strong>Unlisted:</strong> Available from
            <br /> directory enquiries but
            <br /> not in the on-line phonebook <br />
            <br />
            <strong>Ex-directory:</strong> Not in National
            <br /> Directory Database and
            <br /> not in the Phonebook
        </div>
    );

    useEffect(() => {
        handlefetchProfileDetails();
    }, []);

    useEffect(() => {
        handleNDDNumbers();
    }, []);

    const arrowAction = () => {
        history.push('/account/dashboard');
        publish('openhamburger');
    };

    return (
        <>
            <Box className={classes.AccountWrapper}>
                <Box className={classes.DetailsContainer}>
                    <Box className={classes.headerBackicon}>
                        <ArrowBackIcon onClick={arrowAction} />
                    </Box>
                    <Box className={classes.detailsHeaderbackText}>Preferences</Box>

                    <Grid container className={classes.PreferenceWrapper}>
                        {loading && (
                            <Box className={loaderClasses.boxx}>
                                <div className={'loader-position ' + loaderClasses.loader}>
                                    <Loading />
                                </div>
                            </Box>
                        )}
                        <>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={width >= 1020 ? 6 : 12}
                                lg={width >= 1020 ? 6 : 12}
                                className='details-grid-first-half'
                            >
                                <div className='details-topic'>
                                    <div className='details-topic-title'>Paperless billing</div>
                                    <div className='details-topic-title-change'>
                                        <div className='details-topic-content mr-4'>
                                            Receive bills digitally, not via post. If you choose to
                                            receive paper bills you will incur a charge of €5.99
                                        </div>
                                        <div className='details-topic-change'>
                                            <Switch
                                                name={'invoiceDeliveryType'}
                                                disabled={
                                                    userStatus === ACCOUNT_STATUS.BILLABLE_EMPTY ||
                                                    userStatus === ACCOUNT_STATUS.UNBILLABLE
                                                        ? true
                                                        : false
                                                }
                                                onChange={handleupdateBillDeliveryType}
                                                checked={state.invoiceDeliveryType}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='vertical-divider'></div>
                                <div className='details-topic'>
                                    <div className='details-topic-title'>Recommended Offers</div>
                                    <div className='details-topic-title-change'>
                                        <div className='details-topic-content mr-4'>
                                            Based on the products you already have, we'll recommend
                                            the most relevant offers.
                                        </div>
                                        <div className='details-topic-change'>
                                            <Switch
                                                name={'recommendedOffers'}
                                                disabled={
                                                    userStatus === ACCOUNT_STATUS.BILLABLE_EMPTY ||
                                                    userStatus === ACCOUNT_STATUS.UNBILLABLE
                                                        ? true
                                                        : false
                                                }
                                                onChange={handleRecommendedOffers}
                                                checked={state.recommendedOffers}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='vertical-divider'></div>
                                {
                                    <div className='details-topic'>
                                        <div className='details-topic-title'>
                                            Marketing preferences
                                        </div>
                                        <div className='details-topic-content'>
                                            <div className='details-topic-content-heading'>
                                                {' '}
                                                As an eir customer{' '}
                                            </div>
                                            From time to time we'd like to contact you to keep you
                                            updated on our promotions, products, services and market
                                            research. Please let us know how you wish to be
                                            contacted.
                                        </div>
                                        <div className='mt-3 mb-3'>
                                            <div className='details-topic-title-change'>
                                                <div className='details-topic-sub-title'>Email</div>
                                                <div className='details-topic-change'>
                                                    {' '}
                                                    <Switch
                                                        onChange={handleChange}
                                                        disabled={
                                                            userStatus ===
                                                                ACCOUNT_STATUS.BILLABLE_EMPTY ||
                                                            userStatus === ACCOUNT_STATUS.UNBILLABLE
                                                                ? true
                                                                : false
                                                        }
                                                        name={'activeCustomer_email'}
                                                        checked={!!state.activeCustomer_email}
                                                    />
                                                </div>
                                            </div>
                                            <div className='details-topic-title-change'>
                                                <div className='details-topic-sub-title'>SMS</div>
                                                <div className='details-topic-change'>
                                                    <Switch
                                                        onChange={handleChange}
                                                        disabled={
                                                            userStatus ===
                                                                ACCOUNT_STATUS.BILLABLE_EMPTY ||
                                                            userStatus === ACCOUNT_STATUS.UNBILLABLE
                                                                ? true
                                                                : false
                                                        }
                                                        name={'activeCustomer_sms'}
                                                        checked={!!state.activeCustomer_sms}
                                                    />
                                                </div>
                                            </div>
                                            <div className='details-topic-title-change'>
                                                <div className='details-topic-sub-title'>Phone</div>
                                                <div className='details-topic-change'>
                                                    <Switch
                                                        defaultChecked
                                                        onChange={handleChange}
                                                        disabled={
                                                            userStatus ===
                                                                ACCOUNT_STATUS.BILLABLE_EMPTY ||
                                                            userStatus === ACCOUNT_STATUS.UNBILLABLE
                                                                ? true
                                                                : false
                                                        }
                                                        name={'activeCustomer_phone'}
                                                        checked={!!state.activeCustomer_phone}
                                                    />
                                                </div>
                                            </div>
                                            <div className='details-topic-title-change'>
                                                <div className='details-topic-sub-title'>
                                                    Face to face
                                                </div>
                                                <div className='details-topic-change'>
                                                    <Switch
                                                        onChange={handleChange}
                                                        disabled={
                                                            userStatus ===
                                                                ACCOUNT_STATUS.BILLABLE_EMPTY ||
                                                            userStatus === ACCOUNT_STATUS.UNBILLABLE
                                                                ? true
                                                                : false
                                                        }
                                                        name={'activeCustomer_ftf'}
                                                        checked={state.activeCustomer_ftf}
                                                    />
                                                </div>
                                            </div>
                                            <div className='details-topic-title-change'>
                                                <div className='details-topic-sub-title'>
                                                    Direct mail
                                                </div>
                                                <div className='details-topic-change'>
                                                    <Switch
                                                        onChange={handleChange}
                                                        name={'activeCustomer_directMail'}
                                                        disabled={
                                                            userStatus ===
                                                                ACCOUNT_STATUS.BILLABLE_EMPTY ||
                                                            userStatus === ACCOUNT_STATUS.UNBILLABLE
                                                                ? true
                                                                : false
                                                        }
                                                        checked={state.activeCustomer_directMail}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='details-topic-content'>
                                            <div className='details-topic-content-heading'>
                                                No longer an eir customer{' '}
                                            </div>
                                            Because we are always adding to our range of products
                                            and services, if you ever leave us we hope that you will
                                            consider us again in the future. To keep up to date on
                                            our offers let us know how you wish to be contacted.
                                        </div>

                                        <div className='mt-3 mb-3'>
                                            <div className='details-topic-title-change'>
                                                <div className='details-topic-sub-title'>Email</div>
                                                <div className='details-topic-change'>
                                                    {' '}
                                                    <Switch
                                                        onChange={handleChange}
                                                        name={'nonactiveCustomer_email'}
                                                        checked={state.nonactiveCustomer_email}
                                                    />
                                                </div>
                                            </div>
                                            <div className='details-topic-title-change'>
                                                <div className='details-topic-sub-title'>SMS</div>
                                                <div className='details-topic-change'>
                                                    <Switch
                                                        onChange={handleChange}
                                                        name={'nonactiveCustomer_sms'}
                                                        checked={state.nonactiveCustomer_sms}
                                                    />
                                                </div>
                                            </div>
                                            <div className='details-topic-title-change'>
                                                <div className='details-topic-sub-title'>Phone</div>
                                                <div className='details-topic-change'>
                                                    <Switch
                                                        onChange={handleChange}
                                                        name={'nonactiveCustomer_phone'}
                                                        checked={state.nonactiveCustomer_phone}
                                                    />
                                                </div>
                                            </div>
                                            <div className='details-topic-title-change'>
                                                <div className='details-topic-sub-title'>
                                                    Face to face
                                                </div>
                                                <div className='details-topic-change'>
                                                    <Switch
                                                        onChange={handleChange}
                                                        name={'nonactiveCustomer_ftf'}
                                                        checked={state.nonactiveCustomer_ftf}
                                                    />
                                                </div>
                                            </div>
                                            <div className='details-topic-title-change'>
                                                <div className='details-topic-sub-title'>
                                                    Direct mail
                                                </div>
                                                <div className='details-topic-change'>
                                                    <Switch
                                                        onChange={handleChange}
                                                        name={'nonactiveCustomer_directMail'}
                                                        checked={state.nonactiveCustomer_directMail}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={width >= 1020 ? 6 : 12}
                                lg={width >= 1020 ? 6 : 12}
                                className='details-grid-second-half'
                            >
                                {width >= 1020 && <div className='divider'></div>}

                                <div className='details-topic'>
                                    <div className='details-topic-title'>Allow third party use</div>
                                    <div className='details-topic-title-change'>
                                        <div className='details-topic-content mr-4'>
                                            From time to time allow our partners to send you details
                                            about their products or services
                                        </div>
                                        <div className='details-topic-change'>
                                            <Switch
                                                name={'allowThirdParty'}
                                                onChange={handleChange}
                                                disabled={
                                                    userStatus === ACCOUNT_STATUS.BILLABLE_EMPTY ||
                                                    userStatus === ACCOUNT_STATUS.UNBILLABLE
                                                        ? true
                                                        : false
                                                }
                                                checked={state.allowThirdParty}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className='vertical-divider'></div>

                                <div className='details-topic ndd'>
                                    <div className='details-topic-title'>
                                        <p>Directory options</p>
                                        <Tooltip
                                            enterTouchDelay={20}
                                            leaveTouchDelay={15000}
                                            title={width < 600 ? tooltipTextMobile : tooltipText}
                                            style={{ overflow: 'visible' }}
                                            placement={width < 600 ? 'bottom-end' : 'right'}
                                            arrow
                                        >
                                            <IconButton>
                                                <div className='iconUsage'></div>
                                            </IconButton>
                                        </Tooltip>
                                    </div>

                                    <div className='details-topic-title-change'>
                                        <div className='details-topic-content directory-options'>
                                            <div>
                                                You can decide whether your phone numbers will be
                                                available in the directory
                                            </div>
                                        </div>
                                    </div>
                                    {nddNumbers.length > 0 && (
                                        <>
                                            <div className='details-topic-content mt-3'>
                                                <div className='details-topic-content-heading pr-2'>
                                                    <FormControl
                                                        disabled={
                                                            userStatus ===
                                                                ACCOUNT_STATUS.BILLABLE_EMPTY ||
                                                            userStatus === ACCOUNT_STATUS.UNBILLABLE
                                                                ? true
                                                                : nddNumbers.length > 1
                                                                ? false
                                                                : true
                                                        }
                                                        className='details-headerAccountsDropdown'
                                                    >
                                                        <Select
                                                            classes={{
                                                                select: 'details-dropdownSelect',
                                                                icon: 'details-dropdownIcon',
                                                                disabled: 'details-disabled',
                                                            }}
                                                            id='demo-customized-select-native'
                                                            value={selectedServiceId}
                                                            onChange={handleServiceIDChange}
                                                            input={<BootstrapInput />}
                                                            MenuProps={{
                                                                anchorOrigin: {
                                                                    vertical: 'bottom',
                                                                    horizontal: 'left',
                                                                },
                                                                transformOrigin: {
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                },
                                                                getContentAnchorEl: null,
                                                            }}
                                                        >
                                                            {nddNumbers.map((item, index) => {
                                                                return (
                                                                    <MenuItem
                                                                        className={
                                                                            classes.MenuItemsWrapper
                                                                        }
                                                                        classes={{
                                                                            selected:
                                                                                classes.DisplayNone,
                                                                        }}
                                                                        value={item.serviceId}
                                                                    >
                                                                        {convertPhoneNumber(
                                                                            item.phoneNo
                                                                        )}
                                                                    </MenuItem>
                                                                );
                                                            })}
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    <DirectoryOptions
                                        directoryPreferences={directoryPreferences}
                                        handleChangeNDDPreference={handleChangeNDDPreference}
                                        userStatus={userStatus}
                                    />
                                </div>
                            </Grid>
                        </>
                        {/* ) )} */}
                    </Grid>
                </Box>
            </Box>
        </>
    );
};

export default MyPreferences;
