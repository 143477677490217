/**
 * useToggle Hook
 * @module useToggle
 */

import { useState } from 'react';

/**
 * @function
 * @param {Boolean} value - Boolean value (true|false*).
 * Hook is used for toggle boolean value.
 *
 * It can be used for open and close of Modal, toggle buttons.
 * @returns
 * @property {Boolean} value - returned toggle value
 * @property {Function} toggle - Function to toggle the value
 * @property {Function} enable - Function to set value to True
 * @property {Function} disable - Function to set value to False
 *
 */
export const useToggle = (defaultValue = false) => {
    const [value, setToggle] = useState(defaultValue);
    return {
        value,
        toggle: () => setToggle(!value),
        enable: () => setToggle(true),
        disable: () => setToggle(false),
    };
};

export default useToggle;
