// use this file to add reusable functions/methods created durin 5.2 redesign

/**
 * @name convertDateFormat - takes date in yyyy-mm-dd format
 * @param {String} str - date
 * @returns date in DD MMM YYYY format
 */
export const convertDateFormat = str => {
    try {
        const dateArr = str.split('-');
        const myDate = new Date(`${dateArr[0]}-${dateArr[1]}-${dateArr[2]}`);
        const myMonth = myDate.toLocaleString('default', { month: 'short' });
        return `${dateArr[2]} ${myMonth} ${dateArr[0]}`;
    } catch (error) {
        console.log('error while converting date to required format', error);
        return '';
    }
};

/**
 * @name convertPhoneNumber - removes if there is any '00' at the begining of a number
 * @param {Number} num - phone number
 * @returns phone number as string
 */
export const convertPhoneNumber = num => {
    const strNum = String(num);
    if (strNum.slice(0, 2) == '00') {
        return `+${strNum.slice(2)}`;
    }
    return strNum;
};

/**
 * @name addressFormatter - Method for formatting the address object recieved by AddressList API, into a string for showing into the dropdown
 * @param {object} option - It is the address Object return by AddressList API
 * @returns {string} - Formatted address
 */
const addressFormatter = option => {
    let addressLine1 = '';
    let addressLine2 = '';
    let addressLine3 = '';
    let town = '';
    let county = '';
    let flatNumber = '';
    if (option?.flatNumber) {
        flatNumber = option?.flatNumber;
        flatNumber = `${flatNumber},`;
    }
    if (option?.addressLine1) {
        addressLine1 = option.addressLine1
            .toLowerCase()
            .split(' ')
            .map(s => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        addressLine1 = ` ${addressLine1}`;
    }

    if (option?.addressLine2) {
        addressLine2 = option.addressLine2
            .toLowerCase()
            .split(' ')
            .map(s => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        addressLine2 = `, ${addressLine2}`;
    }
    if (option?.addressLine3) {
        addressLine3 = option.addressLine3
            .toLowerCase()
            .split(' ')
            .map(s => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        addressLine3 = `, ${addressLine3}`;
    }
    if (option?.town) {
        town = option.town
            .toLowerCase()
            .split(' ')
            .map(s => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        town = `, ${town}`;
    }
    if (option?.county) {
        county = option.county;
        county = county.slice(3);
        county = county
            .toLowerCase()
            .split(' ')
            .map(s => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        county = `, ${county}`;
    }
    return `${
        flatNumber && flatNumber
    }${addressLine1}${addressLine2}${addressLine3}${town}${county}`;
};

export default addressFormatter;
