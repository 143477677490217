import { getNameAndAccountID, getAccountNumber } from '../getNameAndAccountNumber';
import { getServiceId } from '../getServiceID';
import { setCookie, setTokenInCookies, triggerAdobeApi } from '../redesign-5.2/redesign.api';
import { getAccountType, getLoginOperation, resetPassword } from './loginOperation.api';

export const handleSubmitLoginOperation = async ({
    graphql,
    enteredName,
    userPassword,
    recaptchaV3Token,
    setWaitingApiResponse,
    handleUserVal,
    setInvalidError,
    invalidError,
    toggleRegister,
    setOpenError,
    openError,
    setLoginScreen,
    setInvalidText,
    recaptchaRef,
    captchaV2,
    setCaptchaV2Error,
    captchaV2Error,
}) => {
    try {
        setWaitingApiResponse(true);
        handleUserVal(enteredName);

        const loginOperation = await getLoginOperation({
            graphql,
            email: enteredName,
            password: userPassword,
            recaptcha: captchaV2 ? captchaV2 : recaptchaV3Token,
            recaptchaVersion: captchaV2 ? 'V2' : 'V3',
        });
        if (
            loginOperation.data.login.accessToken != undefined ||
            loginOperation.data.login.refreshToken != undefined
        ) {
            const token = loginOperation.data.login.accessToken;

            if (token.startsWith('ey')) {
                const type = await getAccountType({ graphql, token });
                sessionStorage.setItem('LoggedinUser', enteredName);
                setCookie('ION', 'EMAIL_ADDRESS', enteredName);
                sessionStorage.setItem('LoggedinUserBrand', 'ion');
                sessionStorage.setItem('hasION', loginOperation.data.login.isION);
                sessionStorage.setItem('hasR6', loginOperation.data.login.isR6);
                sessionStorage.setItem('access_token', loginOperation.data.login.accessToken);
                sessionStorage.setItem('R6_status_code', loginOperation.data.login.statusCode);
                sessionStorage.setItem(
                    'R6_passwordResetToken',
                    loginOperation.data.login.passwordResetToken
                );
                sessionStorage.setItem(
                    'R6_numberOfAuthenticationAttemptsRemaining',
                    loginOperation.data.login.numberOfAuthenticationAttemptsRemaining
                );

                /* Required data for redirection from my-eir to eir.ie*/

                setTokenInCookies(token, new Date().getTime() + 60 * 60 * 1000);
                const refreshToken = loginOperation.data.login.refreshToken;
                setCookie('ION', 'refresh_Token', refreshToken);
                sessionStorage.setItem('refresh_token', refreshToken);
                const refreshTokenExpiresIn = loginOperation.data.login.refreshTokenExpiresIn * 1000;               
                const sessionExpiryTime = new Date(
                    new Date().getTime() + refreshTokenExpiresIn
                ).getTime();
                sessionStorage.setItem(
                    'myEirSession',
                    JSON.stringify({
                        sessionExpiryTime,
                        refreshTokenExpiresIn,
                    })
                );
                if (type.accountType.prepay && type.accountType.postpay) {
                    setLoginScreen(1);
                    setWaitingApiResponse(false);
                } else if (type.accountType.prepay && !type.accountType.postpay) {
                    sessionStorage.setItem('LoggedinUserBillType', 'Prepay');

                    setWaitingApiResponse(false);
                    window.location.href = `/prepay/dashboard`;
                } else if (type.accountType.postpay && !type.accountType.prepay) {
                    sessionStorage.setItem('LoggedinUserBillType', 'Bill');

                    console.log('call analytcs......');
                    const response2 = await getNameAndAccountID(graphql);
                    const serviceResponse = await getServiceId(graphql);
                    setWaitingApiResponse(false);
                    await triggerAdobeApi({ graphql, name: 'login' });

                    window.location.href = `/account/dashboard`;
                }
            } else if (token.startsWith('AQI')) {
                // window.location.href = '/bundles/login?email=' + enteredName;
                const baseUrl = '/bundles/login?email=' + enteredName;
                const isIonValue = loginOperation.data.login.isION;
                const token = loginOperation.data?.login?.accessToken;

                const contextVal = {
                    isIon: isIonValue,
                    token: token,
                    isNoticeClicked: false,
                };

                const context = encodeURIComponent(JSON.stringify(contextVal));
                window.location.href = `${baseUrl}&context=${context}`;
            }
            sessionStorage.setItem('loginSuccess', true);
        }
        setWaitingApiResponse(false);
    } catch (error) {
        setWaitingApiResponse(false);
        console.error('error message after getbrand', error.message, openError);
        if (error.message.includes('Invalid reCAPTCHA!')) {
            setInvalidError(!invalidError);
            setCaptchaV2Error(!captchaV2Error);
            setInvalidText('Invalid reCAPTCHA!');
        } else if (error.message.includes('Invalid credentials!')) {
            setInvalidError(!invalidError);
            setInvalidText('Your email or password may be incorrect');
        } else if (error.message.includes('Actions pending to complete registration')) {
            toggleRegister();
        } else if (error.message.includes('Internal server error')) {
            console.log('internal error');
            setOpenError(!openError);
        }
        recaptchaRef?.current?.reset();
    }
};

export const submitForgotResetPassword = async ({
    setWaitingApiResponse,
    graphql,
    userName,
    toggle2,
    toggleError,
}) => {
    try {
        setWaitingApiResponse(true);
        const res = await resetPassword({ graphql, emailAddress: userName });
        setWaitingApiResponse(false);

        if (res.data.resetPassword) {
            toggle2();
        } else {
            console.error('something went wrong while triggering forgot/reset password');
            toggleError();
        }
    } catch (err) {
        setWaitingApiResponse(false);
        // setApiError(err.message);
        // toggleError();
        toggle2();
    }
};
