import React, { Fragment, useEffect, useState } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Grid, Checkbox, Tooltip, IconButton, FormLabel, Box, Container } from '@material-ui/core';

import HelpIcon from '@material-ui/icons/Help';
import { MyEirSwitch } from './MyEirSwitch';
import { MARKETING_PREFERENCES_NOTE_PREPAY } from './constants';
import errormesages from './errormessages';
import { useRegisterFormStyle } from './useRegisterFormStyle';
import { usePrepayFormStyle } from '../../sharedcomponents/css/usePrepayFormStyle';

export const MyEirMarketingPreferences = props => {
    // const classes = useRegisterFormStyle();
    const classes = usePrepayFormStyle();
    const { register, control, errors, clearError } = useFormContext();
    const { contactGroupList, setContactGroupList } = props;

    const { validation } = errormesages;
    useEffect(() => { }, []);
    const handleSwitchChange = (isChecked, contactGroup) => () => {
        const newChecked = [...contactGroupList];
        const selectedGroupIndex = newChecked.findIndex(
            group => group.permission === contactGroup.permission
        );
        const selectedGroup = newChecked[selectedGroupIndex];
        selectedGroup.enabled = isChecked;
        if (!isChecked && selectedGroup.preferenceList) {
            selectedGroup.preferenceList.forEach(preference => (preference.enabled = false));
        }
        setTimeout(() => {
            setContactGroupList(newChecked);
        }, 300);
        console.log('>>>>>>>>', contactGroupList);
    };

    const handleCheckboxChange = (isChecked, contactGroup, contactPreference) => () => {
        const newChecked = [...contactGroupList];
        const selectedGroupIndex = newChecked.findIndex(
            group => group.permission === contactGroup.permission
        );
        const selectedGroup = newChecked[selectedGroupIndex];
        const preferenceIndex = selectedGroup.preferenceList.findIndex(
            preference => preference.permission === contactPreference.permission
        );

        selectedGroup.preferenceList[preferenceIndex].enabled = isChecked;
        if (isChecked) {
            selectedGroup.enabled = true;
            if (selectedGroup.permission == 'active') {
                if (errors.active) {
                    clearError('active');
                }
            }
            if (selectedGroup.permission == 'inactive') {
                if (errors.inactive) {
                    clearError('inactive');
                }
            }
        } else {
            if (selectedGroup.enabled) {
                const preferenceIndex = selectedGroup.preferenceList.findIndex(
                    preference => preference.enabled === true
                );
                if (preferenceIndex < 0) {
                    selectedGroup.enabled = false;
                }
            }
        }
        setTimeout(() => {
            setContactGroupList(newChecked);
        }, 300);
    };
    const isPreferneceChecked = contactGroup => () => {
        const groupList = [...contactGroupList];
        const selectedGroupIndex = groupList.findIndex(
            group => group.permission === contactGroup.permission
        );
        const selectedGroup = groupList[selectedGroupIndex];
        if (selectedGroup.permission === '3P') {
            return true;
        }
        if (selectedGroup.enabled) {
            const preferenceIndex = selectedGroup.preferenceList.findIndex(
                preference => preference.enabled === true
            );
            if (preferenceIndex < 0) {
                return false;
            }
        }
        return true;
    };
    const tableHeader = [
        ...new Set(
            contactGroupList.flatMap(conatctGroup =>
                conatctGroup.preferenceList
                    ? conatctGroup.preferenceList.map(group => group.name)
                    : []
            )
        ),
    ];
    return (
        <Fragment>
            <Container>
                <div className='accountAddress'>
                    <p className={classes.marketingPreferencesHeader}>Marketing preferences</p>

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {/* <p className='formLabelColor boxWidth'> */}
                            <p className={classes.marketingPreferencesPrepayCopy}>
                                <p className={classes.marketingPreferencesCopy}>As an eir customer</p>
                                {' '}
                                {MARKETING_PREFERENCES_NOTE_PREPAY}{' '}
                            </p>
                        </Grid>
                    </Grid>
                    <Grid container spacing={4}>
                        <Grid item md={3} >
                            <Box display={{ xs: 'none', md: 'none' }}>
                                <Grid container>
                                    <Grid item>
                                        <Grid container spacing={2}>
                                            <Grid
                                                item
                                                key='empty'
                                                xs
                                                className={classes.tableHeader}
                                            />
                                            {contactGroupList != '' &&
                                                contactGroupList.map(group => (
                                                    <Grid item xs={12} key={group.name}>
                                                        <Grid
                                                            container
                                                            alignItems='center'
                                                            spacing={1}
                                                            className={classes.tableRow}
                                                        >
                                                            <Grid
                                                                item
                                                                key={group.name}
                                                                className={classes.contactGroupNames}
                                                            >
                                                                <FormLabel className={classes.formLabelColor}>
                                                                    {' '}
                                                                    {group.name}
                                                                </FormLabel>
                                                            </Grid>

                                                            {group.tooltip && (
                                                                <Grid item>
                                                                    <Tooltip
                                                                        enterTouchDelay={20}
                                                                        leaveTouchDelay={50000}
                                                                        title={group.tooltip}
                                                                        size='small'
                                                                        className={
                                                                            classes.marginleft
                                                                        }
                                                                    >
                                                                        <IconButton
                                                                            className={classes.iconUsageTwo}
                                                                            size='small'
                                                                            className={
                                                                                (classes.marginleft,
                                                                                    classes.iconUsageTwo)
                                                                            }
                                                                        >
                                                                            <HelpIcon />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </Grid>
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                ))}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>

                        <Grid item md={3}>
                            <Box display={{ xs: 'none', md: 'none' }}>
                                <Grid container justify='center'>
                                    <Grid item className={classes.tableHeader}>
                                        I want to be contacted
                                    </Grid>
                                    <Grid item>
                                        <Grid container spacing={2}>
                                            {contactGroupList.map(group => (
                                                <Grid item xs={12} key={group.permission}>
                                                    <Grid
                                                        key={group.permission}
                                                        container
                                                        spacing={1}
                                                        className={classes.prefernceRow}
                                                    >
                                                        <Grid item>No</Grid>
                                                        <Grid item>
                                                            <Controller
                                                                as={MyEirSwitch}
                                                                control={control}
                                                                name={group.permission}
                                                                id={group.permission}
                                                                inputRef={register}
                                                                checked={group.enabled}
                                                                onChange={([event]) =>
                                                                    handleSwitchChange(
                                                                        Boolean(
                                                                            event.target.checked
                                                                        ),
                                                                        group
                                                                    )
                                                                }
                                                                rules={{
                                                                    validate: isPreferneceChecked(
                                                                        group
                                                                    ),
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item>Yes</Grid>
                                                    </Grid>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item md={6}>
                            <Box display={{ xs: 'none', md: 'none' }}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Grid container className={classes.centerAlign}>
                                            {tableHeader.map(headerName => (
                                                <Grid
                                                    item
                                                    key={headerName}
                                                    xs
                                                    className={classes.prefernceHeader}
                                                >
                                                    {headerName}
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            {contactGroupList.map(group => (
                                                <Grid item xs={12} key={group.permission}>
                                                    <Grid
                                                        container
                                                        className={classes.prefernceRow}
                                                    >
                                                        {group.preferenceList.map(preference => (
                                                            <Grid
                                                                item
                                                                xs
                                                                key={preference.permission}
                                                            >
                                                                <Controller
                                                                    as={Checkbox}
                                                                    control={control}
                                                                    name={
                                                                        group.permission +
                                                                        preference.permission
                                                                    }
                                                                    id={
                                                                        group.permission +
                                                                        preference.permission
                                                                    }
                                                                    inputRef={register}
                                                                    color='primary'
                                                                    classes={
                                                                        group.enabled
                                                                            ? {
                                                                                root:
                                                                                    classes.whiteCheckbox,
                                                                            }
                                                                            : {}
                                                                    }
                                                                    checked={preference.enabled}
                                                                    onChange={([event]) =>
                                                                        handleCheckboxChange(
                                                                            Boolean(
                                                                                event.target.checked
                                                                            ),
                                                                            group,
                                                                            preference
                                                                        )
                                                                    }
                                                                />
                                                            </Grid>
                                                        ))}
                                                    </Grid>
                                                </Grid>
                                            ))}
                                            {errors.active && errors.active.type === 'validate' && (
                                                <span className={classes.errorMessage}>
                                                    {validation.serviceActiveChecked}
                                                </span>
                                            )}
                                            {errors.inactive &&
                                                errors.inactive.type === 'validate' && (
                                                    <span className={classes.errorMessage}>
                                                        {validation.serviceInactiveChecked}
                                                    </span>
                                                )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid className={classes.prepayFormMarketing} item xs={12}>
                            <Box display={{ xs: 'block', md: 'block' }}>
                                <Grid container spacing={4}>
                                    {contactGroupList.map(group => (
                                        <Grid item xs={12} key={group.name}>
                                            <Grid container spacing={2} justify='center'>
                                                <Grid item xs={12} key={group.name}>
                                                    <Grid
                                                        container
                                                        spacing={1}
                                                        className={classes.tableRow}
                                                    >
                                                        <Grid item>
                                                            <FormLabel className={classes.formLabelColor}>
                                                                {' '}
                                                                {group.name}
                                                            </FormLabel>
                                                        </Grid>
                                                        {group.tooltip && (
                                                            <Grid item >

                                                                <Tooltip
                                                                    enterTouchDelay={20}
                                                                    leaveTouchDelay={50000}
                                                                    title={group.tooltip}
                                                                    size='small'
                                                                    className={
                                                                        classes.permissionToolTip
                                                                    }
                                                                >
                                                                    <IconButton
                                                                        className={classes.iconUsageTwo}
                                                                        size='small'
                                                                    >
                                                                        <HelpIcon />
                                                                    </IconButton>
                                                                </Tooltip>

                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid
                                                        container
                                                        className={classes.tableRow}
                                                        spacing={1}
                                                    >
                                                        <Grid item xs={12}>
                                                            <Grid container spacing={0}>
                                                                <Grid
                                                                    item
                                                                    xs={7}
                                                                    className={classes.marginLeft}
                                                                >
                                                                    <FormLabel
                                                                        className={
                                                                            classes.preferenceName
                                                                        }
                                                                    >
                                                                        {' '}
                                                                        I want to be contacted
                                                                    </FormLabel>
                                                                </Grid>
                                                                <Grid className={
                                                                    classes.preferenceContainer
                                                                } item xs={4}>
                                                                    <Grid
                                                                        container
                                                                        spacing={1}
                                                                        justify='center'
                                                                    >
                                                                        <Grid
                                                                            item
                                                                            className={
                                                                                classes.prefernceHeader
                                                                            }
                                                                        >
                                                                            No
                                                                        </Grid>
                                                                        <Grid
                                                                            item
                                                                            className={
                                                                                classes.prefernceHeader
                                                                            }
                                                                        >
                                                                            <Controller
                                                                                as={MyEirSwitch}
                                                                                control={control}
                                                                                name={
                                                                                    group.permission
                                                                                }
                                                                                id={
                                                                                    group.permission
                                                                                }
                                                                                inputRef={register}
                                                                                checked={
                                                                                    group.enabled
                                                                                }
                                                                                onChange={([
                                                                                    event,
                                                                                ]) =>
                                                                                    handleSwitchChange(
                                                                                        Boolean(
                                                                                            event
                                                                                                .target
                                                                                                .checked
                                                                                        ),
                                                                                        group
                                                                                    )
                                                                                }
                                                                                rules={{
                                                                                    validate: isPreferneceChecked(
                                                                                        group
                                                                                    ),
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                        <Grid
                                                                            item
                                                                            className={
                                                                                classes.prefernceHeader
                                                                            }
                                                                        >
                                                                            Yes
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                {group.enabled && group.preferenceList.length > 0 && (
                                                    <Grid item xs={12}>
                                                        <Grid
                                                            container
                                                            className={classes.tableRow}
                                                            spacing={1}
                                                        >
                                                            {group.preferenceList.map(
                                                                preference => (
                                                                    <Grid
                                                                        item
                                                                        xs={6}
                                                                        key={preference.permission}
                                                                    >
                                                                        <Grid container>
                                                                            <Grid item>
                                                                                <Controller
                                                                                    className={classes.marketingCheckboxColor}
                                                                                    as={Checkbox}
                                                                                    control={
                                                                                        control
                                                                                    }
                                                                                    name={
                                                                                        group.permission +
                                                                                        preference.permission
                                                                                    }
                                                                                    id={
                                                                                        group.permission +
                                                                                        preference.permission
                                                                                    }
                                                                                    inputRef={
                                                                                        register
                                                                                    }
                                                                                    color='primary'

                                                                                    classes={{
                                                                                        root:
                                                                                            classes.whiteCheckbox,
                                                                                    }}
                                                                                    checked={
                                                                                        preference.enabled
                                                                                    }
                                                                                    onChange={([
                                                                                        event,
                                                                                    ]) =>
                                                                                        handleCheckboxChange(
                                                                                            Boolean(
                                                                                                event
                                                                                                    .target
                                                                                                    .checked
                                                                                            ),
                                                                                            group,
                                                                                            preference
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </Grid>
                                                                            <Grid item>
                                                                                <FormLabel
                                                                                    className={
                                                                                        classes.prefName
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        preference.name
                                                                                    }
                                                                                </FormLabel>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                )
                                                            )}
                                                        </Grid>
                                                        {group.permission == 'active' &&
                                                            errors.active &&
                                                            errors.active.type === 'validate' && (
                                                                <span className={classes.errorMessage}>
                                                                    {
                                                                        validation.serviceActiveCheckedMarketing
                                                                    }
                                                                </span>
                                                            )}
                                                        {group.permission == 'inactive' &&
                                                            errors.inactive &&
                                                            errors.inactive.type === 'validate' && (
                                                                <span className={classes.errorMessage}>
                                                                    {
                                                                        validation.serviceInactiveCheckedMarketing
                                                                    }
                                                                </span>
                                                            )}
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                            <input type="hidden" ref={register} id='permissionGroupList' name='permissionGroupList' value={JSON.stringify(contactGroupList)} />
                        </Grid>
                    </Grid>
                </div>
            </Container>
        </Fragment>
    );
};
