import { Box, Grid, Typography } from '@material-ui/core';
import React, { useEffect, useState, useContext } from 'react';
import { myBalanceStyles } from './myBalanceStyles';
import MyEirBalanceButtons from './MyEirBalanceButtons';
import MyEirBillBrokedown from './MyEirBillBrokedown';
import MyEirBalance from './MyEirBalance';
import { getMyBalance, billingCycle, monthlyCharge, payDue } from './myBalance.util';
import { getBillingHistory } from '../my-bills/myBills.util';
import { useApolloClient } from '@apollo/client';
import { subscribe } from 'pubsub-js';
import MyEirLoggedInModal from '../../loggedInModal/MyEirLoggedInModal';
import { useWindowDimension } from '../../../hooks/windowDimensions';
import { RedesignContext } from '../../../global-context-redesign';
import { getPaymentMethodApi } from '../account-details/myAccount.api';
import { getEligibilityForManageAddonsApi } from '../redesign.api';

const MyEirBalanceContainer = () => {
    const value = useContext(RedesignContext);
    const classes = myBalanceStyles();
    const [width, height] = useWindowDimension();
    const graphql = useApolloClient();
    const [balanceOverdue, setBalanceOverdue] = useState(false);
    const [balance, setBalance] = useState(value?.state?.myBalance?.balance);
    const [isError, setIsError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [forPeriod, setForPeriod] = useState(value?.state?.myBalance?.forPeriod);
    const [toDate, setToDate] = useState(value?.state?.myBalance?.toDate);
    const [billDate, setBillDate] = useState(value?.state?.myBalance?.billDate);
    const [billData, setBillData] = useState(value?.state?.myBalance?.billData);
    const [numberOfBills, setNumberOfBills] = useState();
    const [billsLoadingError, setBillsLoadingError] = useState(false);
    const [debitDate, setDebitDate] = useState(value?.state?.myBalance?.debitDate);
    const [paymentDue, setPaymentDue] = useState(value?.state?.myBalance?.paymentDue);
    const [monthlyCharges, setMonthlyCharges] = useState(value?.state?.myBalance?.monthlyCharges);
    const [usage, setUsage] = useState(value?.state?.myBalance?.usage);
    const [totalBalance, setTotalBalance] = useState(value?.state?.myBalance?.totalBalance);
    const [oneoffCharges, setOneoffCharges] = useState(value?.state?.myBalance?.oneoffCharges);
    const [updatebillbreakLink, setUpdatebillbreakLink] = useState(false);
    const [paymentMethods, setPaymentMethods] = useState(value?.state?.myBalance?.paymentMethods);
    const [lastBillDueDate, setLastBillDueDate] = useState(value?.state?.myBalance?.billDueDate);

    const token = sessionStorage.getItem('access_token');
    let billingAccountId = sessionStorage.getItem('LoggedinUserAccountID');

    const getAccountStatus = async () => {
        const res = await getEligibilityForManageAddonsApi({ graphql });
        sessionStorage.setItem('Account_status', res.getAccountStatus.status);
    };

    const getMyBalanceUtil = async () => {
        await getMyBalance({
            graphql,
            billingAccountId,
            token,
            setBalanceOverdue,
            setBalance,
            setIsError,
            setLoading,
            setBillDate,
            value,
            setLastBillDueDate,
        });
    };

    const billingCycleUtil = async () => {
        await billingCycle({ graphql, setForPeriod, setToDate, setIsError, value });
    };

    const getBillingHistoryUtil = async () => {
        await getBillingHistory({
            graphql,
            setBillData,
            setLoading,
            setBillsLoadingError,
            setNumberOfBills,
            setUpdatebillbreakLink,
            value,
        });
    };
    const getPaymentMethodDetailsApi = async () => {
        const response = await getPaymentMethodApi({ graphql });
        setPaymentMethods(response?.data?.getPaymentMethod?.paymentMethods);
        value?.dispatch({
            type: 'myBalance',
            payload: {
                name: 'paymentMethods',
                value: response?.data?.getPaymentMethod?.paymentMethods,
            },
        });
    };

    const DebitCard = paymentMethods?.filter(
        item =>
            item.paymentMethodType === 'DIRECT_DEBIT' || item.paymentMethodType === 'CREDIT_CARD'
    );

    /*  paymentDue is now taken from getBillingHistory api instead of getBalance api */

    // const payDueUtil = async () => {
    //     await payDue({
    //         billingAccountId,
    //         graphql,
    //         token,
    //         setPaymentDue,
    //         setDebitDate,
    //         setIsError,
    //         value,
    //     });
    // };

    const monthlyChargeUtil = async () => {
        await monthlyCharge({
            billingAccountId,
            token,
            graphql,
            setBillDate,
            setPaymentDue,
            setDebitDate,
            setMonthlyCharges,
            setUsage,
            setTotalBalance,
            setOneoffCharges,
            setIsError,
            value,
        });
    };

    useEffect(() => {
        if (billData?.length == 0) {
            getBillingHistoryUtil();
        }
        if (balance == 'NA') {
            getMyBalanceUtil();
        }
        if (forPeriod == 'NA') {
            billingCycleUtil();
        }

        // if (
        //     window.location.href.indexOf('mytt') > 0 ||
        //     window.location.href.indexOf('myeir-test') > 0 ||
        //     window.location.href.indexOf('localhost') > 0 ||
        //     window.location.href.indexOf('myeir-dev') > 0
        // ) {
        //     payDueUtil();
        // }
        getAccountStatus();
        subscribe('UPDATE_BALANCE', (msg, data) => {
            getMyBalanceUtil();
        });
    }, []);

    useEffect(() => {
        if (paymentDue == 'NA') {
            monthlyChargeUtil();
        }
        if (paymentMethods?.length === 0) {
            getPaymentMethodDetailsApi();
        }
    }, [balance]);

    return (
        <Box className={classes.container}>
            <Grid container spacing={0} className={classes.mainContainer}>
                {balance === 'NA' && !isError && <MyEirLoggedInModal />}
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={width >= 1020 ? 6 : 12}
                    lg={width >= 1020 ? 6 : 12}
                    className={classes.balanceBox}
                >
                    <MyEirBalance
                        getMyBalance={getMyBalance}
                        balance={balance}
                        isError={isError}
                        balanceOverdue={balanceOverdue}
                        debitDate={debitDate}
                        billData={billData}
                        DebitCard={DebitCard}
                        lastBillDueDate={lastBillDueDate}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={width >= 1020 ? 6 : 12}
                    className={classes.billBox}
                >
                    <Grid container spacing={0} direction='column' className={classes.billInnerBox}>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            className={classes.billInnerGrid}
                        >
                            <MyEirBillBrokedown
                                paymentDue={paymentDue}
                                monthlyCharges={monthlyCharges}
                                usage={usage}
                                totalBalance={totalBalance}
                                balanceOverdue={balanceOverdue}
                                billData={billData}
                                updatebillbreakLink={updatebillbreakLink}
                                lastBillDueDate={lastBillDueDate}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            className={classes.buttonContainer}
                        >
                            <MyEirBalanceButtons flag='bills' />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default MyEirBalanceContainer;
