import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles(theme => ({
    imgText: {
        color: theme.palette.primary.heather,
        fontFamily: theme.typography.subtitle1.fontFamily,
        // fontWeight: '700',
        fontSize: theme.typography.subtitle1.fontSize,
        textAlign: 'center',
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '32px',
            paddingLeft: '12px',
            paddingRight: '12px',
        },
    },

    sadText: {
        color: theme.palette.primary.heather,
        fontFamily: theme.typography.body2.fontFamily,
        fontWeight: '600',
        fontSize: theme.typography.body1.fontSize,
        textAlign: 'center',
        paddingBottom: '35px',
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            justifyContent: 'center',
        },
    },
    sadImg: {
        margin: '0 auto',
        paddingTop: '35px',
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down('xs')]: {
            width: '77px',
            paddingTop: '30px',
        },
    },
    cancelBtn: {
        textTransform: 'none',
        fontStyle: 'normal',
        //   fontWeight: '700',
        fontSize: '1rem',
        fontFamily: theme.typography.body3Medium.fontFamily,
        lineHeight: '1.5',
        padding: '.5rem 2rem',
        margin: '0 auto ',
        background: theme.palette.primary.sunset,
        width: '100%',
        maxWidth: '200px',
        borderRadius: '4100px',
        color: theme.palette.primary.white,
        '&:hover': {
            background: theme.palette.primary.sunset30,
        },
        justifyContent: 'center',
        display: 'flex',
        [theme.breakpoints.down('md', 'sm')]: {
            maxWidth: '200px',
            width: '100%',
        },
        [theme.breakpoints.only('xs')]: {
            maxWidth: '264px',
            width: '100%',
            height: '41px',
            left: '.69%',
            right: '7.69%',
        },
    },
}));

export default useStyle;
