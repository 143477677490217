import React, { useState } from 'react';
import { useLoginFormStyle } from '../css/useLoginFormStyle';
import MyEirLoginOperation from './MyEirLoginOperation';
import AccountSelection from './AccountSelection';

const LoginContainer = () => {
    const [loginScreen, setLoginScreen] = useState(0);
    const styleClasses = useLoginFormStyle();
    function handleBackButton() {
        setLoginScreen(loginScreen - 1);
    }
    return (
        <>
            <div className={styleClasses.loginContainer}>
                {
                    {
                        0: (
                            <MyEirLoginOperation
                                loginScreen={loginScreen}
                                setLoginScreen={setLoginScreen}
                            />
                        ),
                        1: (
                            <AccountSelection
                                loginScreen={loginScreen}
                                setLoginScreen={setLoginScreen}
                                handleBackButton={handleBackButton}
                            />
                        ),
                    }[loginScreen]
                }
            </div>
        </>
    );
};
export default LoginContainer;
