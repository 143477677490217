import React, { useState, Fragment, useEffect } from 'react';
import { useRegisterFormStyle } from '../my-eir-register/useRegisterFormStyle';
import errormesages from '../my-eir-register/errormessages';
import HelpIcon from '@material-ui/icons/Help';
import moment from 'moment';
import Inputmask from 'inputmask';
import { useFormContext, Controller, useForm, ErrorMessage } from 'react-hook-form';
import ENDPOINT from '../graphql/GraphQLConstants';
import { useApolloClient } from '@apollo/client';
import {
    Grid,
    TextField,
    FormControl,
    InputLabel,
    FormControlLabel,
    Checkbox,
    FormLabel,
    Box,
    InputAdornment,
    Tooltip,
    Button,
    IconButton,
    Container,
    Divider,
    Input,
} from '@material-ui/core';

function MyEirContactDetails(props) {
    const contactDetails = props;
    const graphql = useApolloClient();
    const defaultValue = '';
    const DATE_FORMAT = 'DD/MM/YYYY';
    const classes = useRegisterFormStyle();
    const { validation } = errormesages;
    const [allowEmail, setAllowEmail] = useState(false);
    const [birthDate, setbirthDate] = useState('');
    const [rowcount, setrowcount] = useState('');
    const [showMobile, setShowMobile] = useState(true);
    const isDateValid = dateOfBirth => {
        var date = moment(dateOfBirth, DATE_FORMAT, true);

        //if date invalid , in future then set error
        if (!date.isValid()) {
            return false;
        } else if (date.isAfter()) {
            return false;
        }
        return true;
    };
    const { register, control, errors, setValue, getValues } = useFormContext();
    const [notification, setNotification] = useState(false);
    const renderContent = content => {
        return { __html: content };
    };
    const toSentenceCase = input => {
        let inputLowerCase = input.toLowerCase();
        return `${inputLowerCase.charAt(0).toUpperCase()}${inputLowerCase.slice(1)}`;
    };
    useEffect(() => {
        // Inputmask().mask(document.querySelectorAll('#birthDate'));
        // var s = document.querySelector('#birthDate');
        //console.log('props,contactDetails', props.contactDetails.contactAddresses[0].address);
        // console.log('contactDetails', contactDetails.person);
        if (props.profileStatus) {
            if (contactDetails) {
                setValue('firstName', props.contactDetails.firstName);
                setValue('lastName', props.contactDetails.lastName);
                setValue('birthDate', moment(props.contactDetails.birthDate).format(DATE_FORMAT));
                setValue('emailAddress', props.contactDetails.emails[0].email);
                if (
                    props.contactDetails.phoneNumbers == '' ||
                    props.contactDetails.phoneNumbers == null
                ) {
                    setValue('mobileNumber', '');
                    setShowMobile(false);
                } else {
                    setValue('mobileNumber', props.contactDetails.phoneNumbers[0].phoneNumber);
                }
                setValue('password', props.contactDetails.uuid);
                let rowcount = 1;
                let addressLine1 =
                    props.contactDetails.addresses[0].addressLine1 != null
                        ? props.contactDetails.addresses[0].addressLine1 + '\n'
                        : '';
                let addressLine2 =
                    props.contactDetails.addresses[0].addressLine2 != null
                        ? props.contactDetails.addresses[0].addressLine2 + '\n'
                        : '';
                let addressLine3 =
                    props.contactDetails.addresses[0].addressLine3 != null
                        ? props.contactDetails.addresses[0].addressLine3 + '\n'
                        : '';
                let town =
                    props.contactDetails.addresses[0].town != null
                        ? props.contactDetails.addresses[0].town + '\n'
                        : '';
                let countyWithunderscore =
                    props.contactDetails.addresses[0].county != null
                        ? props.contactDetails.addresses[0].county + '\n'
                        : '';
                let county = '';
                countyWithunderscore.split('_').map((countyVal, index) => {
                    index == 0
                        ? (county = county + toSentenceCase(countyVal) + '. ')
                        : (county = county + toSentenceCase(countyVal));
                });
                let eircode =
                    props.contactDetails.addresses[0].code != null
                        ? props.contactDetails.addresses[0].code + '\n'
                        : '';
                setValue(
                    'yourAddress',

                    addressLine1 + addressLine2 + addressLine3 + town + county + eircode
                );
                if (addressLine1 != '') {
                    rowcount = rowcount + 1;
                }
                if (addressLine2 != '') {
                    rowcount = rowcount + 1;
                }
                if (addressLine3 != '') {
                    rowcount = rowcount + 1;
                }
                if (town != '') {
                    rowcount = rowcount + 1;
                }
                if (county != '') {
                    rowcount = rowcount + 1;
                }
                if (eircode != '') {
                    rowcount = rowcount + 1;
                }
                setrowcount(rowcount);
                console.log('rowcount', rowcount);
            }
        }
    }, []);
    const onSubmit = data => {
        setMobileNumber(data.MobileNumber);
    };
    const editPassword = async () => {
        console.log('getValues(emailAddress)', getValues('emailAddress'));
        try {
            const res = await graphql.mutate({
                mutation: ENDPOINT.RESET_PASSWORD,
                variables: {
                    emailAddress: getValues('emailAddress'),
                },
            });

            if (res.data.resetPassword) {
                setNotification(true);
            }
        } catch (err) {}
    };
    return (
        <Fragment>
            <Container>
                <div className='accountAddress'>
                    <p>Contact details</p>
                </div>
                {props.profileStatus ? (
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                as={
                                    <TextField
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                }
                                control={control}
                                fullWidth
                                id='firstName'
                                name='firstName'
                                label='First name'
                                variant='outlined'
                                size='small'
                                InputLabelProps={{ shrink: true }}
                                required
                                defaultValue={defaultValue}
                                rules={{
                                    required: {
                                        value: true,
                                        message: validation.firstnameRequired,
                                    },
                                    minLength: { value: 2, message: validation.nameMinLength },
                                    maxLength: { value: 100, message: validation.nameMaxLength },
                                    pattern: {
                                        value: /^([^0-9]*)$/,
                                        message: validation.firstNamePattern,
                                    },
                                }}
                            />

                            <ErrorMessage errors={errors} name='firstName'>
                                {({ message }) => <p className='errorMessage'>{message}</p>}
                            </ErrorMessage>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                as={
                                    <TextField
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                }
                                control={control}
                                fullWidth
                                id='lastName'
                                name='lastName'
                                label='Last name'
                                variant='outlined'
                                size='small'
                                InputLabelProps={{ shrink: true }}
                                defaultValue={defaultValue}
                                required
                                rules={{
                                    required: { value: true, message: validation.lastNameRequired },
                                    minLength: { value: 2, message: validation.nameMinLength },
                                    maxLength: { value: 100, message: validation.nameMaxLength },
                                    pattern: {
                                        value: /^([^0-9]*)$/,
                                        message: validation.lastNamePattern,
                                    },
                                }}
                            />
                            <ErrorMessage errors={errors} name='lastName'>
                                {({ message }) => <p className='errorMessage'>{message}</p>}
                            </ErrorMessage>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                as={<TextField />}
                                control={control}
                                fullWidth
                                id='emailAddress'
                                name='emailAddress'
                                label='Email address'
                                variant='outlined'
                                size='small'
                                defaultValue={defaultValue}
                                required
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    readOnly: true,
                                    // endAdornment: (
                                    //     <InputAdornment position='end'>
                                    //         <Tooltip
                                    //             enterTouchDelay={20}
                                    //             leaveTouchDelay={50000}
                                    //             title={
                                    //                 'This eamil address will be used as your username.'
                                    //             }
                                    //             size='small'
                                    //         >
                                    //             <IconButton
                                    //                 style={{ color: '#892BA0' }}
                                    //                 size='small'
                                    //             >
                                    //                 <HelpIcon />
                                    //             </IconButton>
                                    //         </Tooltip>
                                    //     </InputAdornment>
                                    // ),
                                }}
                                rules={{
                                    required: {
                                        value: true,
                                        message: validation.emailAddressError,
                                    },
                                    pattern: {
                                        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                        message: validation.emailAddressError,
                                    },
                                }}
                            />
                            <ErrorMessage errors={errors} name='emailAddress'>
                                {({ message }) => <p className='errorMessage'>{message}</p>}
                            </ErrorMessage>
                        </Grid>
                        {showMobile && (
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    as={<TextField />}
                                    control={control}
                                    fullWidth
                                    id='mobileNumber'
                                    name='mobileNumber'
                                    label='Mobile number'
                                    variant='outlined'
                                    size='small'
                                    required
                                    InputLabelProps={{ shrink: true }}
                                    defaultValue={defaultValue}
                                    InputProps={{
                                        readOnly: true,
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                <Tooltip
                                                    enterTouchDelay={20}
                                                    leaveTouchDelay={50000}
                                                    title={
                                                        'This number will be used in case we need to contact you.'
                                                    }
                                                    size='small'
                                                >
                                                    <IconButton
                                                        style={{ color: '#892BA0' }}
                                                        size='small'
                                                    >
                                                        <HelpIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: validation.mobileNumberRequired,
                                        },
                                        pattern: {
                                            value: /^08[0-9]{8}$/,
                                            message: validation.mobileNumberPattern,
                                        },
                                    }}
                                />
                                <ErrorMessage errors={errors} name='mobileNumber'>
                                    {({ message }) => <p className='errorMessage'>{message}</p>}
                                </ErrorMessage>
                            </Grid>
                        )}
                        <Grid item xs={12} sm={6}>
                            <Controller
                                as={
                                    <TextField
                                        InputProps={{
                                            classes: { input: classes.input },
                                            readOnly: true,
                                        }}
                                        inputProps={{
                                            'data-inputmask-alias': 'datetime',
                                            'data-inputmask-inputformat': 'dd/mm/yyyy',
                                            inputMode: 'numeric',
                                        }}
                                    />
                                }
                                fullWidth
                                variant='outlined'
                                control={control}
                                id='birthDate'
                                label='Date of birth'
                                name='birthDate'
                                size='small'
                                defaultValue={defaultValue}
                                placeholder='DD/MM/YYYY'
                                InputLabelProps={{ shrink: true }}
                                required
                                rules={{
                                    required: { value: true, message: validation.birthDateError },
                                    validate: value => isDateValid(value),
                                }}
                            />

                            <ErrorMessage errors={errors} name='birthDate'>
                                {({ message }) => <p className='errorMessage'>{message}</p>}
                            </ErrorMessage>
                            {errors.birthDate && errors.birthDate.type === 'validate' && (
                                <span className='errorMessage'>{validation.birthDateError}</span>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <Controller
                                        as={
                                            <TextField
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                            />
                                        }
                                        control={control}
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                        id='yourAddress'
                                        name='yourAddress'
                                        label='Your address'
                                        variant='outlined'
                                        size='small'
                                        defaultValue={defaultValue}
                                        inputRef={register}
                                        multiline
                                        rows={rowcount}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <div className='accountAddress'>
                                <p>Password</p>
                            </div>
                            {notification && (
                                <Fragment>
                                    <p className='notification' style={{ color: '#892BA0' }}>
                                        We've just sent you an email. Please follow the instructions
                                        to change your password.
                                    </p>
                                    <p className='notification'>
                                        NOTE: Don't forget to check spam email!
                                    </p>
                                </Fragment>
                            )}
                            {!notification && (
                                <Fragment>
                                    <p className='notification'>
                                        Please click below to change your my eir password.
                                    </p>
                                </Fragment>
                            )}
                            <Grid container spacing={2} className='profileMargin'>
                                {/* <Grid item xs={12} sm={6}>
                                <Controller
                                    as={
                                        <TextField
                                            InputProps={{
                                                readOnly: true,
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        <Tooltip
                                                            enterTouchDelay={20}
                                                            leaveTouchDelay={50000}
                                                            title={
                                                                'Your password must be at least 8 characters long. For security reasons it must include at least one capital letter (R), one lowercase letter (p) and one number (7).'
                                                            }
                                                            size='small'
                                                        >
                                                            <IconButton
                                                                style={{ color: '#892BA0' }}
                                                                size='small'
                                                            >
                                                                <HelpIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            type='password'
                                        />
                                    }
                                    control={control}
                                    fullWidth
                                    // InputLabelProps={{ shrink: true }}
                                    id='password'
                                    name='password'
                                    // label='password'
                                    variant='outlined'
                                    size='small'
                                    defaultValue={defaultValue}
                                    inputRef={register}
                                />
                            </Grid> */}
                                <Grid item xs={12} sm={6}>
                                    <div className='mobileFloat'>
                                        <Button className='editBtn' onClick={editPassword}>
                                            Change password
                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container justify='center'>
                        <Grid list md={12} xs={12} sm={12}>
                            <span className='errorText'>
                                There was an error loading your details
                            </span>
                        </Grid>
                        <Grid list md={12} xs={12} sm={12}>
                            <a className='tryAgain' onClick={() => window.location.reload()}>
                                {' '}
                                Click here to try again
                            </a>
                        </Grid>
                    </Grid>
                )}
            </Container>
        </Fragment>
    );
}

export default MyEirContactDetails;
