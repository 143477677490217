import React, { useEffect, useState, useContext } from 'react';
import { Box, Paper, TableBody, TableCell, TableRow } from '@material-ui/core';
import { useMyBillsStyle } from './useMyBillsStyle';
import { useWindowDimension } from '../../../hooks/windowDimensions';
import { useApolloClient } from '@apollo/client';
import { getBillingHistory } from './myBills.util';
import TablePagination from '@material-ui/core/TablePagination';
import { useHistory } from 'react-router-dom';
import Loading from '../../../components/loading/Loading';
import MyEirBills from './MyEirBills';
import MyEirInactiveUser from '../Inactive-user/MyEirInactiveUser';
import Icon from '../images/inactiveIcon.png';
import { RedesignContext } from '../../../global-context-redesign';
import { getMyBalance } from '../my-balance/myBalance.util';
import { ArrowBackIcon } from '../../../sharedcomponents/inputs/svg-icons/CustomSvgIcons';

function MyEirBillContainer() {
    const value = useContext(RedesignContext);
    const [initialUser, setInitialUser] = useState(false);
    const [isNoBills, setIsNoBills] = useState(false);
    const [billData, setBillData] = useState(value?.state?.myBalance?.billData);
    const [numberOfBills, setNumberOfBills] = useState();
    const [billsLoadingError, setBillsLoadingError] = useState(false);
    const [loading, setLoading] = useState(true);
    const classes = useMyBillsStyle();
    const history = useHistory();
    const [width, height] = useWindowDimension();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [lastBillDueDate, setLastBillDueDate] = useState('NA');
    const token = sessionStorage.getItem('access_token');
    let billingAccountId = sessionStorage.getItem('LoggedinUserAccountID');
    const [balanceOverdue, setBalanceOverdue] = useState(false);
    const [balance, setBalance] = useState(value?.state?.myBalance?.balance);
    const [isError, setIsError] = useState(false);

    const [billDate, setBillDate] = useState(value?.state?.myBalance?.billDate);
    const graphql = useApolloClient();
    const description = `As a new customer, you don't have any bills to display yet. Check back soon to view your first bill. `;
    const noBillsDesc = `There are currently no bills available to display on your account. Due to temporary technical issue, a small number of customers may have difficulty accessing online bill pdfs. Please contact us on 1901.`;
    const getBillingHistoryUtil = async () => {
        await getBillingHistory({
            graphql,
            setBillData,
            setLoading,
            setBillsLoadingError,
            setNumberOfBills,
            value,
            setInitialUser,
            setIsNoBills,
        });
    };

    const getMyBalanceUtil = async () => {
        await getMyBalance({
            graphql,
            billingAccountId,
            token,
            setBalanceOverdue,
            setBalance,
            setIsError,
            setLoading,
            setBillDate,
            value,
            setLastBillDueDate,
        });
    };

    useEffect(() => {
        getBillingHistoryUtil();
        getMyBalanceUtil();
    }, []);

    useEffect(() => {
        const sessionValue = JSON.parse(sessionStorage.getItem('LoggedinServices'));
        const initial = sessionValue?.find(user => user.status === 'INITIAL');

        if (initial != undefined) {
            setInitialUser(true);
        } else {
            setInitialUser(false);
        }
    }, []);

    const arrowAction = () => {
        history.push('/account/dashboard');
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, billData?.length - page * rowsPerPage);
    return (
        <Box className={classes.MyBillsWrapper}>
            <Box className='myBillsContainer'>
                <Box className={classes.BillContainer}>
                    <Box className={classes.BillheaderBackicon}>
                        <ArrowBackIcon onClick={arrowAction} />
                    </Box>
                    <Box className={classes.detailsHeaderbackText}>
                        My bills {numberOfBills != undefined && `(${numberOfBills})`}
                    </Box>

                    {loading ? (
                        <Box
                            className='bill-loader'
                            style={{ justifyContent: 'center', padding: '20px' }}
                        >
                            {billsLoadingError ? (
                                <div className='bills-content-loading'>
                                    Something went wrong, please try again later.
                                    <span
                                        className='bills-retry-loading'
                                        style={{
                                            cursor: 'pointer',
                                            textDecoration: 'underline',
                                        }}
                                        onClick={() => {
                                            setBillsLoadingError(false);
                                            getBillingHistoryUtil();
                                        }}
                                    >
                                        Retry?
                                    </span>
                                </div>
                            ) : (
                                <Loading className='mybills-loading-animation' />
                            )}
                        </Box>
                    ) : (
                        <>
                            {initialUser ? (
                                <Box className={classes.inactiveuserContainer}>
                                    <MyEirInactiveUser
                                        emptyIcon={Icon}
                                        title='Welcome to My eir!'
                                        description={description}
                                    />
                                </Box>
                            ) : isNoBills ? (
                                <Box className={classes.inactiveuserContainer}>
                                    <MyEirInactiveUser
                                        emptyIcon={Icon}
                                        title='Welcome to My eir!'
                                        description={noBillsDesc}
                                    />
                                </Box>
                            ) : (
                                <>
                                    {numberOfBills != undefined &&
                                        numberOfBills > 0 &&
                                        billData
                                            .slice(
                                                page * rowsPerPage,
                                                page * rowsPerPage + rowsPerPage
                                            )
                                            .map((item, index) => (
                                                <MyEirBills
                                                    key={index}
                                                    index={index}
                                                    item={item}
                                                    width={width}
                                                    page={page}
                                                    lastBillDueDate={lastBillDueDate}
                                                />
                                            ))}
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25]}
                                        component='div'
                                        count={billData.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                        className={classes.pagination}
                                    />
                                </>
                            )}
                        </>
                    )}
                </Box>
            </Box>
        </Box>
    );
}

export default MyEirBillContainer;
