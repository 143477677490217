import React, { useState, useEffect, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { Typography, Box } from '@material-ui/core';
import useStyles from './addonsStyles';
import { getAddOns } from './manageAddons.util';
import ActiveAddonsCard from './ActiveAddonsCard';
import { useWindowDimension } from '../../../hooks/windowDimensions';
import { PlusIcon } from '../../../sharedcomponents/inputs/svg-icons/CustomSvgIcons';
import { getEligibilityForManageAddons, getActiveAddons } from './manageAddons.api';
import { getCurrentlyActiveAddons } from './manageAddons.util';
import { useApolloClient } from '@apollo/client';
import { getMyPlanDetails } from '../my-services/myServices.util';
import Loading from '../../../components/loading/Loading';
import { RedesignContext } from '../../../global-context-redesign';
import theme from '../../../theme/MyEirTheme';
import { subscribe } from 'pubsub-js';
const ActiveAddonsContainer = () => {
    const value = useContext(RedesignContext);
    const classes = useStyles();
    const [width, height] = useWindowDimension();
    const graphql = useApolloClient();
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showAddons, setShowAddons] = useState(value?.state?.myAddons?.showAddons);
    const [collectionStatus, setCollectionStatus] = useState(true);
    const [accountStatus, setAccountStatus] = useState('');
    const [uniqueShowArr, setUniqueShowArr] = useState([]);
    const numCards = showAddons?.length;
    // const gridMdProp = numCards === 1 ? 12 : numCards === 2 ? 6 : 4;
    const gridMdProp = 4;
    const gridMdProp1 = numCards === 1 ? 12 : 6;

    const sessionValue = JSON.parse(sessionStorage.getItem('LoggedinServices'));

    const eligiblitltyCheck = async () => {
        await getEligibilityForManageAddons({ graphql, setCollectionStatus, setAccountStatus });
    };

    const activeAddons = async () => {
        const addonsData = await getCurrentlyActiveAddons({
            graphql,
            sessionValue,
            setShowAddons,
            setIsLoading,
            setIsError,
            value,
        });
    };

    const removeDuplicateAddons = arr => {
        const uniqueShowArr = new Set();
        const result = [];
        arr?.forEach(show => {
            const key = `${show.title}_${show.price}`;
            if (!uniqueShowArr[key]) {
                uniqueShowArr[key] = show;
                result.push(show);
            }
        });

        return result;
    };

    useEffect(() => {
        if (showAddons?.length === 0) {
            activeAddons();
        }
    }, []);

    useEffect(() => {
        // onPageLoad();
        eligiblitltyCheck();
    }, []);

    useEffect(() => {
        const uniqueValues = removeDuplicateAddons(showAddons);
        setUniqueShowArr(uniqueValues);
    }, [showAddons]);

    const ActiveAddonContainerReload = async () => {
        setIsLoading(true);
        setIsError(false);
        activeAddons();
    };
    subscribe('ADDON_ADDED', ActiveAddonContainerReload);

    return (
        <>
            {uniqueShowArr.length === 0 ? (
                ''
            ) : (
                <Container maxWidth='lg' className={classes.MainContainer} disableGutters>
                    <>
                        <Box className={classes.iconHeading}>
                            <Box className={classes.plus}>
                                <PlusIcon fill={theme.palette.primary.sunset} />
                            </Box>
                            <Box>
                                <Typography className={classes.heading}> Active Add-ons</Typography>
                            </Box>
                        </Box>

                        <Grid container spacing={3} className={classes.containerMain}>
                            {isLoading ? (
                                <div
                                    className='services-loader'
                                    style={{ width: '100%', justifyContent: 'center' }}
                                >
                                    {isError ? (
                                        <div className='services-content-loading'>
                                            Something went wrong, please try again later.
                                        </div>
                                    ) : (
                                        <Loading
                                            className='services-loading-animation'
                                            style={{ justifyContent: 'center' }}
                                        />
                                    )}
                                </div>
                            ) : (
                                uniqueShowArr?.map((card, index) => {
                                    if (
                                        card?.code == 'AMAZON_TV' ||
                                        card?.code == 'FREE_AMAZON_TV'
                                    ) {
                                        <></>;
                                    } else {
                                        if (card?.title !== '' || card?.subtitle !== '') {
                                            return (
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={width >= 1020 ? gridMdProp : gridMdProp1}
                                                    key={index}
                                                    style={{ display: 'flex' }}
                                                >
                                                    <ActiveAddonsCard
                                                        card={card}
                                                        index={index}
                                                        setShowAddons={setShowAddons}
                                                        showAddons={uniqueShowArr}
                                                        collectionStatus={collectionStatus}
                                                        accountStatus={accountStatus}
                                                        activeAddons={activeAddons}
                                                        ActiveAddonContainerReload={ActiveAddonContainerReload}
                                                    />
                                                </Grid>
                                            );
                                        }
                                    }
                                })
                            )}
                        </Grid>
                    </>
                </Container>
            )}
        </>
    );
};

export default ActiveAddonsContainer;
