import React, { useEffect, useState } from 'react';
import { Box, Modal, Typography } from '@material-ui/core';
import EirButton from '../../../theme/EirButton.js';
import useStyles from './addonsStyles';
import { publish } from 'pubsub-js';
import { useApolloClient } from '@apollo/client';
import { getBllingAddress } from './manageAddons.util';

const AddAddonSuccessModal = ({
    ctaName,
    modalImage,
    addonSuccessTitle,
    addonSuccessSubTitle,
    addonSuccessModal,
    setAddonSuccessModal,
    billingAddress,
}) => {
    const classes = useStyles();

    const handleClose = () => {
        setAddonSuccessModal(false);
        publish('ADDON_ADDED');
    };
    return (
        <Box className={classes.classDiv}>
            <Modal
                id='add-addon-success-modal'
                open={addonSuccessModal}
                onClose={handleClose}
                className={classes.modalContainer}
            >
                <Box className={classes.modalBody}>
                    <Box className={classes.banner}>
                        <Box
                            component='img'
                            className={classes.bannerImage}
                            src={modalImage}
                            alt='banner'
                        />
                    </Box>

                    <Box className={classes.successTitle}>
                        <Typography variant='h1'>
                            {addonSuccessTitle}
                        </Typography>
                    </Box>
                    <Box className={classes.successSubTitle}>
                        <Typography variant='body1'>
                            {addonSuccessSubTitle}
                        </Typography>
                        <Typography variant='h6'>{billingAddress}</Typography>
                    </Box>

                    <Box className={classes.ctaContainer}>
                        <EirButton
                            variants='primaryButtonMedium'
                            style={{ padding: '0.5rem 2.5rem' }}
                            onClick={handleClose}
                        >
                            {ctaName}
                        </EirButton>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
};

export default AddAddonSuccessModal;
