import React from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import styles from './styles';

import EirChannelList from './EirChannelList';

const EirChannelCategory = ({ data }) => {
    const theme = useTheme();
    const classes = styles();

    return (
        <Grid
            container
            item
            spacing={2}
            style={{ xs: { paddingRight: '0rem' }, md: { paddingRight: '1rem' } }}
        >
            <Grid item xs={12}>
                <Box style={{ display: 'flex' }} className={classes.category}>
                    <Box
                        component='img'
                        style={{
                            width: { md: '20px', xs: '13.33px' },
                            height: { md: '20px', xs: '13.33px' },
                            marginRight: '8px',
                        }}
                        alt={
                            data?.CategoryIcon?.data?.attributes?.alternativeText || 'channel-icon'
                        }
                        src={`${window.origin}${data?.CategoryIcon?.data?.attributes?.url}`}
                    />
                    <Typography className={classes.categoryName} style={{ marginRight: '8px' }}>
                        {data?.CategoryName}
                    </Typography>
                    <Typography className={classes.categoryCount}>
                        ({data?.ContentLogo?.length})
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12} sm={11} md={11} lg={11} className={classes.channelListMain}>
                <EirChannelList icons={data.ContentLogo} />
            </Grid>
        </Grid>
    );
};

export default EirChannelCategory;
