import { getBillingPeriod } from '../../getBillingCycle';
import { getMobileServiceID, getMobileVobbPSTNServiceID } from '../../my-bills/getMobileServiceID';
import { convertDateFormat } from '../redesign.util';
import { getUsageDetailsApi, getUsageSummaryApi } from './currentUsage.api';

/**
 * @name handleUsageQuantity - sets the usage quantity and unit for the selected/active tab in current usage
 * @param {Object} Obj - attributes for the function
 * @param {Number} Obj.tabIndex - number which indicates the tab being displayed in current usage
 * @param {Array} Obj.amountAndUsage - array which contains usage details for all tabs
 * @param {useState} Obj.setUsageQuantity - useState function which sets complete usage quantity of the selected tab
 * @param {useState} Obj.setUsageUnit - useState function which sets unit for the usage in the selected tab
 * @param {Number} tabIndex - number which indicates the tab being displayed in current usage
 * @param {Array} amountAndUsage - array which contains usage details for all tabs
 * @param {useState} setUsageQuantity - useState function which sets complete usage quantity of the selected tab
 * @param {useState} setUsageUnit - useState function which sets unit for the usage in the selected tab
 */
const handleUsageQuantity = ({ tabIndex, amountAndUsage, setUsageQuantity, setUsageUnit }) => {
    if (tabIndex == 0) {
        if (amountAndUsage[0].quantity >= 60) {
            setUsageQuantity((amountAndUsage[0].quantity / 60).toFixed(2));
            setUsageUnit('min');
        } else {
            setUsageQuantity(amountAndUsage[0].quantity);
            setUsageUnit('sec');
        }
    } else if (tabIndex == 1) {
        if (amountAndUsage[1].quantity >= 60) {
            setUsageQuantity((amountAndUsage[1].quantity / 60).toFixed(2));
            setUsageUnit('min');
        } else {
            setUsageQuantity(amountAndUsage[1].quantity);
            setUsageUnit('sec');
        }
    } else if (tabIndex == 2) {
        if (amountAndUsage[2].quantity >= 60) {
            setUsageQuantity((amountAndUsage[2].quantity / 60).toFixed(2));
            setUsageUnit('min');
        } else {
            setUsageQuantity(amountAndUsage[2].quantity);
            setUsageUnit('sec');
        }
    } else if (tabIndex == 3) {
        setUsageQuantity(amountAndUsage[3].quantity);
        setUsageUnit('sms');
    } else if (tabIndex == 4) {
        if (amountAndUsage[4].quantity >= 1048576) {
            if (amountAndUsage[4].quantity >= 1073741824) {
                setUsageQuantity((amountAndUsage[4].quantity / 1073741824).toFixed(2));
                setUsageUnit('GB');
            } else {
                setUsageQuantity((amountAndUsage[4].quantity / 1048576).toFixed(2));
                setUsageUnit('MB');
            }
        } else if (amountAndUsage[4].quantity == 0) {
            setUsageQuantity((amountAndUsage[4].quantity / 1073741824).toFixed(2));
            setUsageUnit('GB');
        } else {
            setUsageQuantity((amountAndUsage[4].quantity / 1024).toFixed(2));
            setUsageUnit('KB');
        }
    }
};

/**
 * @name convertDataUsage - converts data value from bits to GB
 * @param {Number} val - number of bits of data
 * @returns data in GB
 */
const convertDataUsage = val => {
    if (val == 0) {
        return `${(val / 1073741824).toFixed(1)} GB`;
    }
    return `${(val / 1073741824).toFixed(3)} GB`;
};

/**
 * @name convertDuration - takes time in secs and returns in HH:MM:SS format
 * @param {Number} sec - time in seconds
 * @returns time as HHhrs MMmins SSs
 */
const convertDuration = sec => {
    const hours = Math.floor(sec / 3600); // get hours
    let minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
    let seconds = sec - hours * 3600 - minutes * 60; //  get seconds

    // if (hours < 10) {hours = "0"+hours;}
    if (minutes < 10) {
        minutes = `0${minutes}`;
    }
    if (seconds < 10) {
        seconds = `0${seconds}`;
    }
    return `${hours}hrs ${minutes}mins ${seconds}s`; // Return is HH : MM : SS
};

/**
 * @name convertTimeFormat - takes a string and returns first 5 characters of it
 * @param {String} str - string
 * @returns first 5 characters of input
 */
const convertTimeFormat = str => {
    return str.slice(0, 5);
};

/**
 * @name convertTimeOnly - takes date time
 * @param {String} str - date time
 * @returns time only
 */
const convertTimeOnly = str => {
    const dateTimeArray = str.split('T');
    const newDate = convertDateFormat(dateTimeArray[0]);
    const newTime = convertTimeFormat(dateTimeArray[1]);
    return newTime;
};

/**
 * @name convertDateOnly - takes date time
 * @param {String} str - date time
 * @returns date only
 */
const convertDateOnly = str => {
    const dateTimeArray = str.split('T');
    const newDate = convertDateFormat(dateTimeArray[0]);
    const newTime = convertTimeFormat(dateTimeArray[1]);
    return newDate;
};

/**
 * @name convertDateAndTime - takes date time
 * @param {String} str - date time
 * @returns returns date and time seperated with a space
 */
const convertDateAndTime = str => {
    const dateTimeArray = str.split('T');
    const newDate = convertDateFormat(dateTimeArray[0]);
    const newTime = convertTimeFormat(dateTimeArray[1]);
    return `${newDate}   ${newTime}`;
};

/**
 * @name hideAndUnhideSummary - toggles and untoggles valueComputed
 * @param {Object} Obj - attributes for the function
 * @param {useState} Obj.setValueComputed - useState function which sets valueComputed to true or false
 * @param {useState} setValueComputed - useState function which sets valueComputed to true or false
 */
const hideAndUnhideSummary = ({ setValueComputed }) => {
    setValueComputed(true);
    setTimeout(() => {
        setValueComputed(false);
    }, 500);
};

/**
 * @name handleBillingPeriod - gets latest billing period and sets it to usageBillingPeriod state variable
 * @param {Object} Obj - attributes for the function
 * @param {GraphQL} Obj.graphql - appoloclient graphql constant
 * @param {useState} Obj.setUsageBillingPeriod - useState function which sets  billing period
 * @param {GraphQL} graphql - appoloclient graphql constant
 * @param {useState} setUsageBillingPeriod - useState function which sets  billing period
 */
const handleBillingPeriod = async ({ graphql, setUsageBillingPeriod }) => {
    try {
        if (
            sessionStorage.getItem('lastInvoiceDate') == undefined ||
            sessionStorage.getItem('lastInvoiceDate') == ''
        ) {
            const billCycleResponse = await getBillingPeriod(graphql);
        }

        setUsageBillingPeriod(convertDateFormat(sessionStorage.getItem('lastInvoiceDate')));
    } catch (error) {
        console.error('error while fetching BILLINGPERIOD', error);
    }
};

/**
 * @name servicesInfo - gets serviceId's from session storage and sets it to useState Variables
 * @param {Object} Obj - attributes for the function
 * @param {useState} Obj.serviceName - useState variable which has service names
 * @param {useState} Obj.setServiceName - useState function which sets service names
 * @param {useState} Obj.setServiceNameId - useState function which sets service name id's
 * @param {useState} Obj.setCurrentUsageLoadingError - useState function which sets currentUsageLoadingError
 * @param {useState} serviceName - useState variable which has service names
 * @param {useState} setServiceName - useState function which sets service names
 * @param {useState} setServiceNameId - useState function which sets service name id's
 * @param {useState} setCurrentUsageLoadingError - useState function which sets currentUsageLoadingError
 */
const servicesInfo = async ({
    serviceName,
    setServiceName,
    setServiceNameId,
    setCurrentUsageLoadingError,
}) => {
    try {
        if (
            sessionStorage.getItem('MobileVobbPSTNServiceFiltered') == undefined ||
            sessionStorage.getItem('MobileVobbPSTNServiceFiltered') == ''
        ) {
            const serviceResponse = await getMobileVobbPSTNServiceID(graphql);
        }

        if (serviceName.length == 0) {
            sessionStorage.getItem('MobileVobbPSTNServiceFiltered') != null &&
                JSON.parse(sessionStorage.getItem('MobileVobbPSTNServiceFiltered')).map(item => {
                    setServiceName(
                        item.domain === 'PSTN'
                            ? prev => [...prev, item.phoneNumber]
                            : item.msisdn === null
                            ? prev => [...prev, item.sipNumber]
                            : prev => [...prev, item.msisdn]
                    );
                    setServiceNameId(prev => [...prev, item.id]);
                });
        }
    } catch (error) {
        setCurrentUsageLoadingError(true);
        console.error('error in seperation of service name and id ', error.message);
    }
};

/**
 * @name getUsageDetailsByUsageType - gets latest billing period and sets it to usageBillingPeriod state variable
 * @param {Object} Obj - attributes for the function
 * @param {GraphQL} Obj.graphql - appoloclient graphql constant
 * @param {Number} Obj.index - index of ServiceID array for which current usage is being displayed for
 * @param {useState} Obj.setUsageDetailsText - useState function which sets usage details text
 * @param {useState} Obj.setCurrentUsageLoadingError - useState function which sets usage loading error
 * @param {GraphQL} graphql - appoloclient graphql constant
 * @param {Number} index - index of ServiceID array for which current usage is being displayed for
 * @param {useState} setUsageDetailsText - useState function which sets usage details text
 * @param {useState} setCurrentUsageLoadingError - useState function which sets usage loading error
 */
const getUsageDetailsByUsageType = async ({
    graphql,
    index,
    usgType,
    setUsageDetailsText,
    setCurrentUsageLoadingError,
}) => {
    try {
        if (
            sessionStorage.getItem('billingPeriod') == undefined ||
            sessionStorage.getItem('billingPeriod') == ''
        ) {
            const billCycleResponse = await getBillingPeriod(graphql);
        }
        if (
            sessionStorage.getItem('MobileVobbPSTNServiceFiltered') == undefined ||
            sessionStorage.getItem('MobileVobbPSTNServiceFiltered') == ''
        ) {
            const serviceResponse = await getMobileVobbPSTNServiceID(graphql);
        }

        const idIndex = index != undefined ? index : 0;
        let tempDetails = [];
        if (sessionStorage.getItem('MobileVobbPSTNServiceFiltered') != null) {
            const response = await getUsageDetailsApi({
                graphql,
                token: sessionStorage.getItem('access_token'),
                usageDetailsInput: {
                    serviceId: JSON.parse(sessionStorage.getItem('MobileVobbPSTNServiceFiltered'))[
                        idIndex
                    ].id,
                    serviceType: 'POSTPAY',
                    billingPeriod: sessionStorage.getItem('billingPeriod'),
                    usageType: usgType,
                    pageNumber: 0,
                    size: 1000,
                },
            });

            const { data } = response;
            if (data.getMyUsageDetails.usage.content) {
                if (data.getMyUsageDetails.usage.totalPages > 1) {
                    tempDetails = data.getMyUsageDetails.usage.content;
                    for (let pgNum = 1; pgNum < data.getMyUsageDetails.usage.totalPages; pgNum++) {
                        const response2 = await getUsageDetailsApi({
                            graphql,
                            token: sessionStorage.getItem('access_token'),
                            usageDetailsInput: {
                                serviceId: JSON.parse(
                                    sessionStorage.getItem('MobileVobbPSTNServiceFiltered')
                                )[idIndex].id,
                                serviceType: 'POSTPAY',
                                billingPeriod: sessionStorage.getItem('billingPeriod'),
                                usageType: usgType,
                                pageNumber: pgNum,
                                size: 1000,
                            },
                        });
                        const data2 = response2.data;
                        if (data2.getMyUsageDetails.usage.content) {
                            tempDetails = tempDetails.concat(data2.getMyUsageDetails.usage.content);
                        } else {
                            // console.log("TEMPDETAILS ERROR",tempDetails);
                        }
                    }
                    setUsageDetailsText(prev => [...prev, ...tempDetails]);
                    return [true, tempDetails];
                }
                setUsageDetailsText(prev => [...prev, ...data.getMyUsageDetails.usage.content]);
                return [true, data.getMyUsageDetails.usage.content];
            }
        }
    } catch (error) {
        setCurrentUsageLoadingError(true);

        console.error('error in fetching bills ', error.message);
        if (error.response != undefined && error.response.status != 200) {
            // setCurrentUsageLoadingError(true);
        }
        // console.log(error);
        return [false, error];
    }
};

/**
 * @name getUsageSummary - gets usageSummary of a given serviceID and sets it to usageSummary state variable
 * @param {Object} Obj - attributes for the function
 * @param {GraphQL} Obj.graphql - appoloclient graphql constant
 * @param {Number} Obj.index - index of ServiceID array for which current usage is being displayed for
 * @param {useState} Obj.setUsageSummary - useState function which sets billing period
 * @param {useState} Obj.setUsageDetailsText - useState function which sets usage detials text
 * @param {useState} Obj.setCurrentUsageLoadingError - useState function which sets usage loading error
 * @param {GraphQL} graphql - appoloclient graphql constant
 * @param {Number} index - index of ServiceID array for which current usage is being displayed for
 * @param {useState} setUsageSummary - useState function which sets billing period
 * @param {useState} setUsageDetailsText - useState function which sets usage detials text
 * @param {useState} setCurrentUsageLoadingError - useState function which sets usage loading error
 */
const getUsageSummary = async ({
    graphql,
    index,
    setUsageSummary,
    setUsageDetailsText,
    setCurrentUsageLoadingError,
}) => {
    try {
        if (!sessionStorage.getItem('billingPeriod')) {
            await getBillingPeriod(graphql);
        }

        if (!sessionStorage.getItem('MobileVobbPSTNServiceFiltered')) {
            await getMobileVobbPSTNServiceID(graphql);
        }

        const idIndex = index != undefined ? index : 0;
        let tempSMSDetails = [];
        const token = sessionStorage.getItem('access_token');
        const billingPeriod = sessionStorage.getItem('billingPeriod');
        const serviceId =
            sessionStorage.getItem('MobileVobbPSTNServiceFiltered') != null
                ? JSON.parse(sessionStorage.getItem('MobileVobbPSTNServiceFiltered'))[idIndex].id
                : null;
        if (token && billingPeriod && serviceId) {
            const response = await getUsageSummaryApi({
                graphql,
                token,
                billingPeriod,
                serviceType: 'POSTPAY',
                serviceId,
            });

            const { data } = response;
            if (data.getMyUsageSummary.usageSummary) {
                data.getMyUsageSummary.usageSummary.map(item => {
                    if (item.ratingSubtotalTypeDisplayName.toUpperCase().indexOf('SMS') > -1) {
                        const usgTypeData = getUsageDetailsByUsageType({
                            graphql,
                            index: idIndex,
                            usgType: item.ratingSubtotalTypeKey,
                            setUsageDetailsText,
                            setCurrentUsageLoadingError,
                        });
                        if (usgTypeData[0] == true) {
                            tempSMSDetails = tempSMSDetails.concat(usgTypeData[1]);
                        }
                    }
                });
                setUsageSummary(data.getMyUsageSummary.usageSummary);
            }
        } else {
            setCurrentUsageLoadingError(true);
        }
    } catch (error) {
        setCurrentUsageLoadingError(true);
        console.error('Error in fetching usage summary : ', error.message);
    }
};

/**
 * @name getUsageDetails - gets getUsageDetails of a given serviceID and sets it to getUsageDetails state variable
 * @param {Object} Obj - attributes for the function
 * @param {GraphQL} Obj.graphql - appoloclient graphql constant
 * @param {Number} Obj.index - index of ServiceID array for which current usage is being displayed for
 * @param {useState} Obj.setUsageDetails - useState function which sets usage details
 * @param {useState} Obj.setCurrentUsageLoading - useState function which sets loader for current usage
 * @param {useState} Obj.setCurrentUsageLoadingError - useState function which sets usage loading error
 * @param {GraphQL} graphql - appoloclient graphql constant
 * @param {Number} index - index of ServiceID array for which current usage is being displayed for
 * @param {useState} setUsageDetails - useState function which sets usage details
 * @param {useState} setCurrentUsageLoading - useState function which sets loader for current usage
 * @param {useState} setCurrentUsageLoadingError - useState function which sets usage loading error
 */
const getUsageDetails = async ({
    graphql,
    index,
    setUsageDetails,
    setCurrentUsageLoading,
    setCurrentUsageLoadingError,
}) => {
    try {
        if (
            sessionStorage.getItem('billingPeriod') == undefined ||
            sessionStorage.getItem('billingPeriod') == ''
        ) {
            const billCycleResponse = await getBillingPeriod(graphql);
            // console.log("BILLINGCYCLE called inside getUsageDetails");
        }
        if (
            sessionStorage.getItem('MobileVobbPSTNServiceFiltered') == undefined ||
            sessionStorage.getItem('MobileVobbPSTNServiceFiltered') == ''
        ) {
            const serviceResponse = await getMobileVobbPSTNServiceID(graphql);
        }

        const idIndex = index != undefined ? index : 0;
        let tempDetails = [];
        if (sessionStorage.getItem('MobileVobbPSTNServiceFiltered') != null) {
            const response = await getUsageDetailsApi({
                graphql,
                token: sessionStorage.getItem('access_token'),
                usageDetailsInput: {
                    serviceId: JSON.parse(sessionStorage.getItem('MobileVobbPSTNServiceFiltered'))[
                        idIndex
                    ].id,
                    serviceType: 'POSTPAY',
                    billingPeriod: sessionStorage.getItem('billingPeriod'),
                    usageType: '',
                    pageNumber: 0,
                    size: 1000,
                },
            });
            const { data } = response;
            if (data.getMyUsageDetails.usage.content) {
                if (data.getMyUsageDetails.usage.totalPages > 1) {
                    tempDetails = data.getMyUsageDetails.usage.content;
                    for (let pgNum = 1; pgNum < data.getMyUsageDetails.usage.totalPages; pgNum++) {
                        const response2 = await getUsageDetailsApi({
                            graphql,
                            token: sessionStorage.getItem('access_token'),
                            usageDetailsInput: {
                                serviceId: JSON.parse(
                                    sessionStorage.getItem('MobileVobbPSTNServiceFiltered')
                                )[idIndex].id,
                                serviceType: 'POSTPAY',
                                billingPeriod: sessionStorage.getItem('billingPeriod'),
                                // billingPeriod: moment().format('YYYYMM'),
                                usageType: '',
                                pageNumber: pgNum,
                                size: 1000,
                            },
                        });
                        const data2 = response2.data;
                        if (data2.getMyUsageDetails.usage.content) {
                            tempDetails = tempDetails.concat(data2.getMyUsageDetails.usage.content);
                        } else {
                            // console.log("TEMPDETAILS ERROR",tempDetails);
                        }
                    }
                    setUsageDetails(tempDetails);
                    setCurrentUsageLoading(false);
                    setCurrentUsageLoadingError(false);
                } else {
                    setUsageDetails(data.getMyUsageDetails.usage.content);
                    setCurrentUsageLoading(false);
                    setCurrentUsageLoadingError(false);
                }
            }
        }
    } catch (error) {
        setCurrentUsageLoadingError(true);

        console.error('error in fetching usage detail ', error.message);
        if (error.response != undefined && error.response.status != 200) {
            // setCurrentUsageLoadingError(true);
        }
        // console.log(error);
    }
};

/**
 * @name retryCurrentUsage - gets usageSummary of a given serviceID and sets it to usageSummary state variable
 * @param {Object} Obj - attributes for the function
 * @param {GraphQL} Obj.graphql - appoloclient graphql constant
 * @param {Number} Obj.selectedServiceIndex - index of ServiceID array for which current usage is being displayed for
 * @param {useState} Obj.setUsageDetails - useState function which sets usage details
 * @param {useState} Obj.setUsageSummary - useState function which sets billing period
 * @param {useState} Obj.setUsageDetailsText - useState function which sets usage detials text
 * @param {useState} Obj.setCurrentUsageLoading - useState function which sets loader for current usage
 * @param {useState} Obj.setCurrentUsageLoadingError - useState function which sets usage loading error
 * @param {GraphQL} graphql - appoloclient graphql constant
 * @param {Number} index - index of ServiceID array for which current usage is being displayed for
 * @param {useState} setUsageDetails - useState function which sets usage details
 * @param {useState} setUsageSummary - useState function which sets billing period
 * @param {useState} setUsageDetailsText - useState function which sets usage detials text
 * @param {useState} setCurrentUsageLoading - useState function which sets loader for current usage
 * @param {useState} setCurrentUsageLoadingError - useState function which sets usage loading error
 */
const retryCurrentUsage = ({
    graphql,
    selectedServiceIndex,
    setUsageSummary,
    setUsageDetails,
    setUsageDetailsText,
    setCurrentUsageLoading,
    setCurrentUsageLoadingError,
}) => {
    getUsageSummary({
        graphql,
        index: selectedServiceIndex,
        setUsageSummary,
        setUsageDetailsText,
        setCurrentUsageLoadingError,
    });
    getUsageDetails({
        graphql,
        index: selectedServiceIndex,
        setUsageDetails,
        setCurrentUsageLoading,
        setCurrentUsageLoadingError,
    });
};

/**
 * @name filterDetails - gets usageSummary of a given serviceID and sets it to usageSummary state variable
 * @param {Object} Obj - attributes for the function
 * @param {useState} Obj.usageDetails - useState varibale which has usage details
 * @param {useState} Obj.setUsageDetailsCharged - useState function which sets charged usage details
 * @param {useState} Obj.setUsageDetailsFree - useState function which sets free usage details
 * @param {useState} Obj.setUsageDetailsCall - useState function which sets usage details for calls
 * @param {useState} Obj.setUsageDetailsText - useState function which sets usage details for text
 * @param {useState} Obj.setUsageDetailsData - useState function which sets usage details for data
 * @param {useState} Obj.setChargedTotal - useState function which sets charged usage details total
 * @param {useState} Obj.setFreeTotal - useState function which sets free usage details total
 * @param {useState} Obj.setUsageDetailsOLDText - useState function which sets usage details old
 * @param {useState} usageDetails - useState varibale which has usage details
 * @param {useState} setUsageDetailsCharged - useState function which sets charged usage details
 * @param {useState} setUsageDetailsFree - useState function which sets free usage details
 * @param {useState} setUsageDetailsCall - useState function which sets usage details for calls
 * @param {useState} setUsageDetailsText - useState function which sets usage details for text
 * @param {useState} setUsageDetailsData - useState function which sets usage details for data
 * @param {useState} setChargedTotal - useState function which sets charged usage details total
 * @param {useState} setFreeTotal - useState function which sets free usage details total
 * @param {useState} setUsageDetailsOLDText - useState function which sets usage details old
 */
const filterDetails = ({
    usageDetails,
    setUsageDetailsCharged,
    setUsageDetailsFree,
    setUsageDetailsCall,
    setUsageDetailsText,
    setUsageDetailsData,
    setChargedTotal,
    setFreeTotal,
    setUsageDetailsOLDText,
}) => {
    setUsageDetailsCharged([]);
    setUsageDetailsFree([]);
    setUsageDetailsCall([]);
    setUsageDetailsText([]);
    setUsageDetailsData([]);
    setChargedTotal(prevState => ({
        quantity: 0,
        amount: 0,
    }));
    setFreeTotal(prevState => ({
        quantity: 0,
        amount: 0,
    }));
    usageDetails.map((item, index) => {
        if (item.invoiceText === 'CALL') {
            setUsageDetailsCall(prev => [...prev, item]);
            if (item.amount === 0) {
                setUsageDetailsFree(prev => [...prev, item]);
                setFreeTotal(prevState => ({
                    quantity: prevState.quantity + item.duration,
                    amount: prevState.amount + item.amount,
                }));
            } else {
                setUsageDetailsCharged(prev => [...prev, item]);
                setChargedTotal(prevState => ({
                    quantity: prevState.quantity + item.duration,
                    amount: prevState.amount + item.amount,
                }));
            }
        } else if (item.invoiceText === 'TEXT') {
            setUsageDetailsOLDText(prev => [...prev, item]);
            if (item.amount === 0) {
                setUsageDetailsFree(prev => [...prev, item]);
                setFreeTotal(prevState => ({
                    quantity: prevState.quantity + item.duration,
                    amount: prevState.amount + item.amount,
                }));
            } else {
                setUsageDetailsCharged(prev => [...prev, item]);
                setChargedTotal(prevState => ({
                    quantity: prevState.quantity + item.duration,
                    amount: prevState.amount + item.amount,
                }));
            }
        } else if (item.invoiceText === 'DATA') {
            setUsageDetailsData(prev => [...prev, item]);
            if (item.amount === 0) {
                setUsageDetailsFree(prev => [...prev, item]);
                setFreeTotal(prevState => ({
                    quantity: prevState.quantity + item.duration,
                    amount: prevState.amount + item.amount,
                }));
            } else {
                setUsageDetailsCharged(prev => [...prev, item]);
                setChargedTotal(prevState => ({
                    quantity: prevState.quantity + item.duration,
                    amount: prevState.amount + item.amount,
                }));
            }
        }
    });
};

/**
 * @name getUsageAmount - gets usage and amount for each type and sets it to usetate variables
 * @param {Object} Obj - attributes for the function
 * @param {useState} Obj.usageSummary - useState variable which has usage summary
 * @param {useState} Obj.setCallTotal - useState function which sets call usage
 * @param {useState} Obj.setSMSTotal - useState function which sets sms usage
 * @param {useState} Obj.setDataTotal - useState function which sets data usage
 * @param {useState} Obj.setDataNormal - useState function which sets normal data usage
 * @param {useState} Obj.setDataRoaming - useState function which sets roaming data usage
 * @param {useState} usageSummary - useState variable which has usage summary
 * @param {useState} setCallTotal - useState function which sets call usage
 * @param {useState} setSMSTotal - useState function which sets sms usage
 * @param {useState} setDataTotal - useState function which sets data usage
 * @param {useState} setDataNormal - useState function which sets normal data usage
 * @param {useState} setDataRoaming - useState function which sets roaming data usage
 */
const getUsageAmount = ({
    usageSummary,
    setCallTotal,
    setSMSTotal,
    setDataTotal,
    setDataNormal,
    setDataRoaming,
}) => {
    setCallTotal(prevState => ({
        quantity: 0,
        amount: 0,
    }));
    setSMSTotal(prevState => ({
        quantity: 0,
        amount: 0,
    }));
    setDataTotal(prevState => ({
        quantity: 0,
        amount: 0,
    }));
    setDataNormal(prevState => ({
        quantity: 0,
        amount: 0,
    }));
    setDataRoaming(prevState => ({
        quantity: 0,
        amount: 0,
    }));
    usageSummary.map(item => {
        if (item.ratingSubtotalTypeDisplayName.toUpperCase().indexOf('CALL') > -1) {
            setCallTotal(prevState => ({
                quantity: prevState.quantity + item.duration,
                amount: prevState.amount + item.amount,
            }));
        } else if (item.ratingSubtotalTypeDisplayName.toUpperCase().indexOf('SMS') > -1) {
            setSMSTotal(prevState => ({
                quantity: prevState.quantity + item.quantity,
                amount: prevState.amount + item.amount,
            }));
        } else if (item.ratingSubtotalTypeDisplayName.toUpperCase().indexOf('DATA') > -1) {
            setDataTotal(prevState => ({
                quantity: prevState.quantity + item.quantity,
                amount: prevState.amount + item.amount,
            }));
            if (item.ratingSubtotalTypeDisplayName.toUpperCase().indexOf('ROAMING') > -1) {
                setDataRoaming(prevState => ({
                    quantity: prevState.quantity + item.quantity,
                    amount: prevState.amount + item.amount,
                }));
            } else {
                setDataNormal(prevState => ({
                    quantity: prevState.quantity + item.quantity,
                    amount: prevState.amount + item.amount,
                }));
            }
        }
    });
};

/**
 * @name formatDate - function which converts date to Date Month Year format
 * @param {Date} date - input date
 * @returns - returns the date in Date Month Year format
 * */

const formatDate = date => {
    try {
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.getFullYear();

        return `${day} ${month} ${year}`;
    } catch (error) {
        console.log('error while converting date to required format', error);
        return '';
    }
};

/**
 * @name formattingDate - function which converts date to Date with Suffix of Month Year format
 * @param {Date} date - input date
 * @returns - returns the date in Date with Suffix of Month Year format
 * */
const formattingDate = date => {
    try {
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.getFullYear();

        return `${day}${suffixChecker(day)} of ${month} ${year}`;
    } catch (error) {
        console.log('error while converting date to required format', error);
        return '';
    }
};

/**
 * @name suffixChecker - function which is used to get the suffix for date
 * @returns - returns the suffix as th, st, nd or rd format depending on the Date value
 */
const suffixChecker = day => {
    try {
        if (day > 3 && day < 21) return 'th ';
        switch (day % 10) {
            case 1:
                return 'st ';
            case 2:
                return 'nd ';
            case 3:
                return 'rd ';
            default:
                return 'th ';
        }
    } catch (error) {
        console.log('error while adding the suffix', error);
        return '';
    }
};

export {
    handleUsageQuantity,
    convertDataUsage,
    convertDuration,
    convertTimeFormat,
    convertTimeOnly,
    convertDateOnly,
    convertDateAndTime,
    hideAndUnhideSummary,
    handleBillingPeriod,
    servicesInfo,
    getUsageDetailsByUsageType,
    getUsageSummary,
    getUsageDetails,
    retryCurrentUsage,
    filterDetails,
    getUsageAmount,
    formatDate,
    formattingDate,
    suffixChecker,
};
