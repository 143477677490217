import React, { useEffect, useState } from 'react';
import HeaderContainer from '../HeaderContainer';
import FooterContainer from '../FooterContainer';
import MyEirPhoneNumber from './MyEirPhoneNumber';
import { useHistory } from 'react-router-dom';
import MyEirVerificationCode from './MyEirVerificationCode';
import MyEirEnterAccountNumber from './MyEirEnterAccountNumber';
import MyEirRegisterEmail from './MyEirRegisterEmail';
import MyEirCreatePasswordFormPage from '../my-eir-createPassword/MyEirCreatePasswordFormPage';
import MyEirForgotPasswordForm from '../my-eir-forgotPassword/MyEirForgotPasswordForm';

function PasswordContainer() {
    const [step, setStep] = useState(0);
    const [resetScreen, setResetScreen] = useState(false);
    const [formData, setFormData] = useState({});

    const history = useHistory();
    const handleBackButton = () => {
        if (step == 0) {
            // console.log('back from step1');
        } else {
            setStep(step - 1);
        }
    };

    const handleNextFlow1 = () => {
        if (step == 5) {
            // console.log('Last Step');
        } else {
            setStep(step + 1);
        }
    };

    const closeBtn = () => {
        setResetScreen(true);
    };

    return (
        <div className='my-eir-reset-page'>
            {
                {
                    0: (
                        <MyEirCreatePasswordFormPage
                            handleBackButton={handleBackButton}
                            handleNextFlow1={handleNextFlow1}
                        />
                    ),
                }[step]
            }
        </div>
    );
}
export default PasswordContainer;
