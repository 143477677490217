import { makeStyles } from '@material-ui/core/styles';

export const useMyEirUsageStyle = makeStyles(theme => ({
    paper: {
        marginTop: ' 0px !important',
        marginBottom: theme.spacing(3),
        boxShadow: '0px 3px 25px #00000024',
        borderRadius: '9px 9px 9px 9px',
        opacity: '1',
        [theme.breakpoints.up(1000 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(6),
            // paddingBottom: theme.spacing(3),
        },
        '@media  (max-width: 1025px)': {
            paddingBottom: '24px',
        },
    },
    Usagefilter: {
        paddingTop: theme.spacing(3),
        '@media  (max-width: 768px)': {
            paddingTop: theme.spacing(1),
        },
        '& .MuiOutlinedInput-notchedOutline,& .MuiOutlinedInput-root:hover>.MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgb(124 33 154 / 34%) ',
            borderWidth: '2px',
            '&:hover,&:focus': {
                borderColor: 'rgb(124 33 154 / 34%) ',
            },
        },
        '& .MuiOutlinedInput-touchedOutline': {
            borderColor: 'rgb(124 33 154 / 34%) ',
        },
        '& .MuiSelect-select': {
            borderRadius: '25px',
            opacity: '1',
        },
        '& .MuiOutlinedInput-root': {
            position: 'relative',
            borderRadius: '25px',
        },
    },
    UsagePaging: {
        '& .MuiOutlinedInput-inputMarginDense': {
            paddingTop: '6.5px',
            paddingBottom: '6.5px',
            '@media  (max-width: 800px)': {
                paddingTop: '4.5px',
                paddingBottom: '4.5px',
            },
        },
        fontSize: '16px',
        '@media  (max-width: 800px)': {
            fontSize: '12px !important',
        },
    },
    UsageContainer: {
        background: '#f4f5f9 0% 0% no-repeat',
        position: 'relative',
        '& .errorMessage': {
            width: '100%',
            marginTop: '.25rem',
            color: '#f44336',
            display: 'block',
            marginBottom: 0,
        },
        '& .layout': {
            marginRight: theme.spacing(2),
            marginLeft: theme.spacing(2),
        },
        '& .pagingPlacing': {
            justifyContent: 'flex-end',
            '@media  (max-width: 425px)': {
                justifyContent: 'center',
            },
        },

        '& .usageSectionHolder': {
            width: '92%',
            margin: 'auto',
            padding: '3% 5%',
            '@media (min-width: 959.95px) and (max-width: 1024px)': {
                width: '100%',
                padding: '1% 1%',
            },
            '@media  (max-width: 959.95px)': {
                width: '100%',
                padding: '2% 0%',
            },
            '& .formLogoSection ,& .loginContainer': {
                display: 'none',
            },
            '& .usageContent': {
                paddingTop: theme.spacing(4),
            },
        },
        '& .usageTitle': {
            color: '#470A68 !important',
        },
        '& .usageFilter': {
            color: '#470A68 !important',
            fontFamily: 'eir-medium',
            fontSize: '20px',
        },
        '& .usageFilterBold': {
            color: '#470A68 !important',
            fontFamily: 'eir-bold',
            fontSize: '20px',
            '@media  (max-width: 425px)': {
                fontSize: '15px !important',
            },
        },
        '& .dropDownStyle': {
            width: '80px',
            '@media  (max-width: 425px)': {
                width: '70px',
            },
        },
        '& .rowsPerPageStyle': {
            textAlign: 'start',
            marginTop: '5px',
            '@media  (max-width: 425px)': {
                textAlign: 'end',
            },
        },

        '& h1': {
            fontFamily: 'eir-medium',
            color: '#892BA0',
            marginBottom: '20px',
            '@media  (max-width: 1024px)': {
                fontSize: '30px !important',
            },
        },
        '& h2': {
            fontFamily: 'eir-medium',
            fontSize: '30px !important',
            color: '#892BA0',
            '@media  (max-width: 1024px)': {
                fontSize: '25px !important',
            },
        },
        '& h3': {
            fontFamily: 'eir-medium',
            fontSize: '24px !important',
            color: '#470A68',
            textAlign: 'left',
            '@media  (max-width: 1024px)': {
                fontSize: '20px !important',
            },
            '& span': {
                color: '#892BA0',
            },
        },
        '& p': {
            color: '#25262A',
            fontSize: '16px ',
            fontFamily: 'gt-walsheim-regular ',
            // '@media  (max-width: 767px)': {
            //     fontSize: '14px !important',
            // },
        },
        '& .usagetext': {
            textAlign: 'justify',
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
            fontSize: '13px',
            fontFamily: 'gt-walsheim-regular ',
        },
        '& form': {
            width: '100%',
            margin: 'auto',
            '@media  (max-width: 768px)': {
                width: '100%',
            },
        },

        '& .deskMargin': {
            marginRight: '12px',
            '@media (max-width: 767px)': {
                marginRight: '0px',
            },
        },
        '& .boldFont': {
            fontFamily: 'eir-bold !important',
        },
        '& .messageBoldFont': {
            fontFamily: 'eir-bold !important',
            color: '#470A68 !important',
        },
        '& .mediumFont': {
            fontFamily: 'eir-medium !important',
            fontSize: '14px !important',
            MarginTop: '10px',
        },
        '& .fontLarge': {
            fontSize: '20px !important',
            '@media (max-width: 425px)': {
                fontSize: '16px !important',
            },
        },

        '& .usageCarousel': {
            paddingTop: theme.spacing(2),
            marginRight: theme.spacing(2),
            marginLeft: theme.spacing(2),
            '@media (max-width: 425px)': {
                marginRight: theme.spacing(0),
                marginLeft: theme.spacing(0),
            },

            '& button': {
                outline: 'none',
                boxShadow: '0px 0px 10px #00000029',
                backgroundColor: '#fff !important',
                color: '#323435 !important',
                padding: '0px',
                '@media (max-width: 1024px)': {
                    margin: '0 2px',
                },
                '&:hover,&:active': {
                    backgroundColor: '#fff',
                    color: '#323435',
                },
            },
            '& .iconLayout': {
                '& img': {
                    height: '45px',
                    width: '45px',

                    padding: '0px',
                    marginTop: '-20px',

                    borderRadius: '100%',
                    '@media  (max-width: 1024px)': {
                        height: '40px',
                        width: '40px',
                    },
                },
            },
            '& .usageScrollItem': {
                background:
                    ' transparent linear-gradient(180deg, #892BA0 0%, #5F0A8D 100%) 0% 0% no-repeat padding-box',

                opacity: '1',
                color: 'white',
                padding: '0px 14px 14px 14px',
                width: '130px',
                height: '131px',
                borderRadius: '100%',
                display: 'inline-block',
                margin: '0px 8px 0px 4px',
                '@media (max-width: 768px)': {
                    width: '115px',
                    height: '116px',
                    padding: '0px',
                },
                '@media (max-width: 1024px)': {
                    margin: '0px 4px 16px 4px',
                },

                '& p': {
                    color: '#fff',
                    fontFamily: 'eir-medium',
                    fontSize: '15px',
                    marginBottom: '0px',
                },
            },
            '& .Carousel-indicator': {
                color: '#892BA0',
                opacity: '0.35',
            },
            '& .Carousel-indicator-active': {
                color: '#892BA0',
                opacity: '1',
            },
        },
        '& .paddingTop': {
            paddingTop: theme.spacing(3),
            '@media  (max-width: 768px)': {
                paddingTop: theme.spacing(1),
            },
        },
        '& .usagePaddingTop': {
            paddingTop: theme.spacing(5),
            '@media  (max-width: 768px)': {
                paddingTop: theme.spacing(2),
            },
        },
        '& .ShowMobile': {
            display: 'none',
            '@media  (max-width: 1024px)': {
                display: 'block',
            },
        },
        '& .usageShowMobile': {
            display: 'none',
            '@media  (max-width: 425px)': {
                display: 'block',
            },
        },
        '& .hideMobile': {
            display: 'block',
            '@media  (max-width: 1023px)': {
                display: 'none',
            },
        },
        '& .paddingTopusage': {
            paddingTop: theme.spacing(1),
            textAlign: 'end',
            '@media  (max-width: 768px)': {
                paddingTop: theme.spacing(0),
                textAlign: 'center',
            },
        },
        '& .paddingBottom': {
            paddingBottom: theme.spacing(3),
            '@media  (max-width: 768px)': {
                paddingBottom: theme.spacing(0),
            },
        },
        '& .paddingBottomDetail': {
            paddingBottom: theme.spacing(3),
        },
        '& .faqSection': {
            padding: theme.spacing(4),
            '& h1': {
                textAlign: 'left',
            },
            '& p': {
                textAlign: 'left',
                color: '#323435',
                fontFamily: 'eir-medium',
                fontSize: '16px',
                '@media  (max-width: 768px)': {
                    fontSize: '14px',
                },
            },
        },
        '& .faqLink,& .faqLink:hover': {
            color: '#892BA0',
        },
        '& .faq-card': {
            border: 0,
            boxShadow: 'none',
            '&.MuiAccordion-root:before': {
                backgroundColor: 'transparent',
            },
        },
        '& .iconColor': {
            color: '',
        },

        '& .faqBtn': {
            background: '#FFFFFF 0% 0% no-repeat ',
            border: '2px solid #DB12A9',
            borderRadius: '29px',
            color: '#470A68',
            fontFamily: 'gt-walsheim-medium !important',
            outline: 'none',
            fontSize: '18px !important',
            textTransform: 'initial',
            padding: theme.spacing(1, 7),
            margin: '20px',
            '&:hover': {
                background: '#892BA0 0% 0% no-repeat  !important',
                border: '2px solid #892BA0',

                '& a': {
                    color: '#fff !important',
                },
            },
            '@media  (max-width: 320px)': {
                padding: ' 8px 16px',
                fontSize: '14px !important',
                marginBottom: '15px',
            },
            '@media  (min-width: 321px) and (max-width: 767px)': {
                fontSize: '14px !important',
                padding: theme.spacing(1, 4),
                marginBottom: '15px',
            },
            '@media  (min-width: 768px) and (max-width: 1025px)': {
                fontSize: '16px !important',
                padding: theme.spacing(1, 4),
                marginBottom: '15px',
            },
            '& a': {
                color: '#470A68',
                textDecoration: 'none',
                '&:hover': {
                    color: '#FFFFFF',
                },
            },
        },
        '& .faqCaption': {
            textAlign: 'left',
            color: '#323435',
            fontFamily: 'eir-medium',
            fontSize: '20px',
            borderBottom: '2px dotted hsla(0,0%,78%,.4)',
            '@media  (max-width: 768px)': {
                fontSize: '16px !important',
            },
        },
    },
}));
