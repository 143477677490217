import React, { useEffect } from 'react';
import { Grid, Button, Paper, Tooltip, Typography } from '@material-ui/core';
import HelpOutlinedIcon from '@material-ui/icons/HelpOutlined';
import SimCardOutlinedIcon from '@material-ui/icons/SimCardOutlined';

function MyEirAcountDetails(props) {
    return (
        <div className='myEirDetails'>
            <h1>
                <span className='iconLayout'>
                    {' '}
                    <img src='/opencms/export/system/modules/my.portal.opencms/myeir_ion/images/my-eir-account.png'></img>
                </span>
                My details
            </h1>
            {props.subscription ? (
                props.planDetails ? (
                    <div>
                        <Paper className='paper'>
                            <span></span>{' '}
                            <p>
                                <SimCardOutlinedIcon className='iconUsagePink' />
                                My PIN:{' '}
                                {props.planDetails.pin1Code
                                    ? props.planDetails.pin1Code
                                    : props.planDetails.pin}
                                <Tooltip
                                    enterTouchDelay={20}
                                    leaveTouchDelay={15000}
                                    title='You need this four-digit code to enable the SIM card. Once you have inserted the SIM into your phone, you will need to enter the PIN to be able to access our mobile network.'
                                    style={{ overflow: 'visible' }}
                                    placement='top-end'
                                >
                                    <HelpOutlinedIcon
                                        className='iconUsage'
                                        style={{ float: 'right' }}
                                    />
                                </Tooltip>
                            </p>
                        </Paper>
                        <Paper className='paper'>
                            <p>
                                <SimCardOutlinedIcon className='iconUsagePink' />
                                My PUK:{' '}
                                {props.planDetails.puk1Code
                                    ? props.planDetails.puk1Code
                                    : props.planDetails.puk}
                                <Tooltip
                                    enterTouchDelay={20}
                                    leaveTouchDelay={15000}
                                    title='Your PUK is used to reset the PIN number on a phone. You will need to enter your PUK if you enter your PIN number incorrectly 3 times.'
                                    style={{ overflow: 'visible' }}
                                    placement='top-end'
                                >
                                    <HelpOutlinedIcon
                                        className='iconUsage'
                                        style={{ float: 'right' }}
                                    />
                                </Tooltip>
                            </p>
                        </Paper>
                    </div>
                ) : (
                    <Grid container justify='center'>
                        <Grid list md={12} xs={12} sm={12}>
                            <span className='errorText'>
                                There was an error loading your details
                            </span>
                        </Grid>
                        <Grid list md={12} xs={12} sm={12}>
                            <a className='tryAgain' onClick={() => window.location.reload()}>
                                {' '}
                                Click here to try again
                            </a>
                        </Grid>
                    </Grid>
                )
            ) : (
                <Grid container justify='center'>
                    <Grid list md={12} xs={12} sm={12}>
                        <span className='errorText'>There was an error loading your details</span>
                    </Grid>
                    <Grid list md={12} xs={12} sm={12}>
                        <a className='tryAgain' onClick={() => window.location.reload()}>
                            {' '}
                            Click here to try again
                        </a>
                    </Grid>
                </Grid>
            )}

            <Grid item xs={12} sm={12} md={12}>
                <div>
                    <Button className='faqBtn topupbtn'>
                        <a href='/prepay/profile'>View my profile</a>
                    </Button>
                </div>
            </Grid>
        </div>
    );
}

export default MyEirAcountDetails;
