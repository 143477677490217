import React, { useState, useEffect } from 'react';
import { useFormContext, useForm, Controller, ErrorMessage } from 'react-hook-form';
import { Grid, Select, MenuItem, InputAdornment, Container } from '@material-ui/core';
import { useDashboardStyle } from './useDashboardStyle';

function MyEirBanner(props) {
    const classes = useDashboardStyle();
    const defaultValue = 'yes';
    const { register, control } = useForm({
        reValidateMode: 'onChange',
    });
    const [name, setName] = useState(null);
    const [phone, setPhone] = useState(null);
    const [services, setServices] = useState([]);
    const [showDropdown, setShowDropdown] = useState(true);

    useEffect(() => {
        setName(props.contactDetails.firstName);
        setPhone(props.services[0] ? props.services[0].msisdn : null);
        if (props.services.length < 2) {
            setShowDropdown(false);
        }
        setServices(props.services);
    }, []);

    const numberFormat = phoneNumber => {
        return ' ' + phoneNumber.substr(0, 3) + ' ' + phoneNumber.substr(3, 10);
    };

    const handleChange = event => {
        props.onChange(event.target.value);
    };
    return (
        <div className='myEirBanner'>
            <div className='UserInfo'>
                <Container>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={7} md={7}>
                            <h1>Hi {name}</h1>
                            <p>Welcome to my eir</p>
                        </Grid>
                        <Grid item xs={12} sm={5} md={5}>
                            <p className='mobileText arrow-text'>
                                Phone number:{' '}
                                {!showDropdown && phone && <span>{numberFormat(phone)}</span>}
                                {showDropdown && (
                                    <span className='phone-drop-down'>
                                        {/* <select onChange={handleChange}>
                                        {subscriptions
                                            ? subscriptions.subscriptions.map(sub => (
                                                  <option value={sub.subscriptionId}>
                                                      {numberFormat(sub.services[0].name)}
                                                  </option>
                                              ))
                                            : ''}
                                    </select> */}
                                        <Controller
                                            control={control}
                                            name='mobileNumber'
                                            id='mobileNumber'
                                            defaultValue={sessionStorage.getItem('ServiceId')}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        <i className='arrow-downs arrow-phone-select'></i>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            as={
                                                <Select
                                                    id='mobileNumber'
                                                    MenuProps={{
                                                        classes: { paper: classes.dropdownStyle },
                                                    }}
                                                >
                                                    {services.length > 0 &&
                                                        services.map(service => (
                                                            <MenuItem
                                                                value={service.id}
                                                                key={service.subscriptionId}
                                                            >
                                                                {numberFormat(service.msisdn)}
                                                            </MenuItem>
                                                        ))}
                                                </Select>
                                            }
                                            onChange={([event]) => {
                                                handleChange(event);
                                                return event.target.value;
                                            }}
                                        />
                                    </span>
                                )}
                                {/* <i className='arrow-down arrow-phone-select'></i> */}
                            </p>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </div>
    );
}

export default MyEirBanner;
