import React, { useState, useEffect } from 'react';
import { Box, Checkbox, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { myBalanceStyles } from './myBalanceStyles';
import { downloadInvoicePDF } from '../my-bills/myBills.util';
import { useApolloClient } from '@apollo/client';
import BillDetails from '../my-bills/BillDetails';
import { DownloadIcon } from '../../../sharedcomponents/inputs/svg-icons/CustomSvgIcons';
import emptyIcon from '../images/emptyIcon.svg';
import { getServiceId } from '../../getServiceID';
import MyEirInactiveUser from '../Inactive-user/MyEirInactiveUser';
import { formattingDate } from './MyEirBalanceText';
import theme from '../../../theme/MyEirTheme';

const MyEirBillBrokedown = ({
    paymentDue,
    monthlyCharges,
    usage,
    totalBalance,
    balanceOverdue,
    billData,
    lastBillDueDate,
    updatebillbreakLink,
}) => {
    const classes = myBalanceStyles();
    const graphql = useApolloClient();
    const [isopen, setIsOpen] = useState(false);
    // const [balanceFlag, setbalanceFlag] = useState(true);
    const [hidePDF, setHidePDF] = useState(false);
    const [initialUser, setInitialUser] = useState(false);
    const firstIndex = billData ? billData[0] : [];

    const handlebreak = () => {
        setIsOpen(!isopen);
    };

    const downloadInvoicePDFUtil = async () => {
        await downloadInvoicePDF({ graphql, invoiceNumber: firstIndex.invoiceNumber, setHidePDF });
    };

    useEffect(() => {
        const sessionValue = JSON.parse(sessionStorage.getItem('LoggedinServices'));
        console.log('session value', sessionValue);
        sessionValue?.forEach(element => {
            if (element.status === 'INITIAL') {
                setInitialUser(true);
            }
        });
    }, []);

    return (
        <>
            <Box className={classes.mainBillContainer}>
                <Grid container className={classes.gridContainer}>
                    <Grid item sm={12} xs={12}>
                        <Box className={classes.gridContent}>
                            <Box className={classes.itemHeading}>Bill breakdown</Box>
                        </Box>
                        {initialUser ? (
                            <Box className={classes.inactiveBillUserContainer}>
                                <MyEirInactiveUser
                                    flag='bills'
                                    emptyIcon={emptyIcon}
                                    title={'Welcome to My eir!'}
                                    description={
                                        <p>
                                            As a new customer, you don't have any bills to display
                                            yet.
                                            <br />
                                            Check back soon to view your first bill
                                        </p>
                                    }
                                />
                            </Box>
                        ) : billData?.length === 0 ? (
                            <Box className={classes.inactiveBillUserContainer}>
                                <MyEirInactiveUser
                                    flag='bills'
                                    emptyIcon={emptyIcon}
                                    title={'Welcome to My eir!'}
                                    description={
                                        <p>
                                            There are currently no bills available to display on
                                            your account.
                                            <br />
                                            Due to temporary technical issue, a small number of
                                            customers may have difficulty accessing online bill
                                            pdfs. Please contact us on 1901.
                                        </p>
                                    }
                                />
                            </Box>
                        ) : (
                            <>
                                <Box className={classes.gridContent}>
                                    <Box className={classes.itemTitle}>Payment due</Box>
                                    <Box className={classes.itemValue}>
                                        {formattingDate(lastBillDueDate)}
                                    </Box>
                                </Box>
                                <Box className={classes.gridContent}>
                                    <Box className={classes.itemTitle}>Monthly charges</Box>
                                    <Box className={classes.itemValue}>
                                        {!isNaN(monthlyCharges) && (
                                            <> €{(Math.abs(monthlyCharges) / 100).toFixed(2)} </>
                                        )}
                                    </Box>
                                </Box>
                                {/* {usage == 'NA' || usage == 0 ? ( */}
                                {usage == 'NA' ? (
                                    <></>
                                ) : (
                                    <Box className={classes.gridContent}>
                                        <Box className={classes.itemTitle}>Usage</Box>
                                        <Box className={classes.itemValue}>
                                            {!isNaN(usage) && (
                                                <> €{(Math.abs(usage) / 100).toFixed(2)} </>
                                            )}
                                        </Box>
                                    </Box>
                                )}
                                <Box className={classes.gridContent}>
                                    <Box className={classes.itemBold}>Total</Box>
                                    <Box className={classes.itemBold}>
                                        {!isNaN(totalBalance) && (
                                            <>
                                                {' '}
                                                €{(Math.abs(totalBalance) / 100).toFixed(2)}{' '}
                                                {balanceOverdue && ''}
                                            </>
                                        )}
                                    </Box>
                                </Box>

                                <Box className={classes.gridContent}>
                                    {firstIndex?.billingSource === 'ION' ? (
                                        <Box className={classes.itemLinks} onClick={handlebreak}>
                                            Full bill breakdown
                                        </Box>
                                    ) : (
                                        ''
                                    )}
                                    {firstIndex?.billingSource === 'ION' && isopen ? (
                                        <BillDetails
                                            isopen={isopen}
                                            setIsOpen={setIsOpen}
                                            invoiceNumber={firstIndex.invoiceNumber}
                                            invoiceDate={firstIndex.invoiceDate}
                                            dueDate={firstIndex.dueDate}
                                            index={0}
                                            lastBillDueDate={lastBillDueDate}
                                        />
                                    ) : (
                                        ''
                                    )}

                                    <Box className={classes.item}>
                                        <>
                                            <Box className={classes.itemPdf}>PDF</Box>
                                            <DownloadIcon
                                                fill1={theme.palette.primary.sunset}
                                                className={
                                                    classes.pdfImg + (hidePDF ? 'hide' : ' ')
                                                }
                                                onClick={() => {
                                                    downloadInvoicePDFUtil(
                                                        firstIndex.invoiceNumber
                                                    );
                                                }}
                                            />
                                        </>
                                    </Box>
                                </Box>
                            </>
                        )}
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default MyEirBillBrokedown;
