import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { onBoardingStyles } from './onBoardingStyles';

function WelcomeScreen({ backgroundImage, title, backgroundGradient, altText }) {
    const classes = onBoardingStyles();

    return (
        <>
            <Box className={classes.welcomeContainer}>
                <Box
                    style={{
                        backgroundImage: backgroundGradient,
                    }}
                    className={classes.welcomeGradient}
                />
                <Box
                    component='img'
                    src={backgroundImage}
                    alt={altText}
                    className={classes.welcomeImage}
                />
                <Typography className={classes.welcomeText}>{title} </Typography>
            </Box>
        </>
    );
}

export default WelcomeScreen;
