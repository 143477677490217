import { makeStyles } from '@material-ui/core/styles';

export const useForgotEmailFormStyle = makeStyles(theme => ({
    paper: {
        marginTop: ' 0px !important',

        boxShadow: '0px 3px 25px #00000024',
        borderRadius: '9px 9px 9px 9px',
        opacity: '1',
        [theme.breakpoints.up(1000 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(6),
            paddingBottom: theme.spacing(3),
        },
        // '@media  (max-width: 1025px)': {
        //     paddingBottom: '24px',
        // },
    },
    ForgotEmailForm: {
        background: '#f4f5f9 0% 0% no-repeat',
        position: 'relative',
        '& form': {
            width: '75%',
            margin: 'auto',
            paddingBottom: '20px',
            '@media (max-width: 767px)': {
                width: '100%',
            },
        },
        '& .createForm': {
            width: '80% !important',
            paddingBottom: '0px !important',
            '@media (max-width: 767px)': {
                width: '100%',
            },
        },
        '& .paddingTop': {
            paddingTop: '1rem',
            paddingBottom: '8px',
        },
        '& .arrow-right': {
            border: 'solid #470A68',
            borderWidth: '0 2px 2px 0',
            display: 'inline-block',
            padding: '5px',
            transform: 'rotate(135deg)',
            marginRight: '10px',
        },

        '& h1': {
            textAlign: 'center',
            color: '#470A68',
            fontFamily: 'eir-medium',
            fontSize: '35px',
            '@media  (max-width: 281px)': {
                fontSize: '25px !important',
            },
            '@media  (min-width: 281px) and (max-width: 767px)': {
                fontSize: '30px !important',
            },
        },
        '& h2': {
            textAlign: 'center',
            color: '#470A68',
            marginBottom: '2rem',
            fontFamily: 'eir-medium',
            fontSize: '20px',
            '@media  (max-width: 281px)': {
                fontSize: '16px !important',
            },
            '@media  (min-width: 281px) and (max-width: 767px)': {
                fontSize: '18px !important',
            },
        },
        '& button': {
            outline: 'none',
            '&:focus,&:hover': {
                outline: 'none',
            },
        },
        '& .marginTop': {
            marginTop: '1rem',
        },
        '& .paddingBottom': {
            paddingBottom: '1.5rem',
        },
        '& a': {
            textDecoration: 'none',
            textAlign: 'center',
            color: '#470A68',
            fontFamily: 'eir-medium',
            fontSize: '20px',

            '@media  (max-width: 767px)': {
                fontSize: '18px !important',
            },
        },
        '& .emailform': {
            width: '75%',
            margin: 'auto',
            padding: '3% 5%',
            '@media (min-width: 767px) and (max-width: 1023px)': {
                width: '100%',
                padding: '5% 1%',
            },
            '@media  (max-width: 767px)': {
                width: '100%',
                padding: '8% 0%',
            },

            '& .regcontainer': {
                paddingTop: theme.spacing(4),
            },
            '& .formLogoSection': {
                display: 'block !important',
            },
        },

        '& p': {
            textAlign: 'center',
            color: '#323435',
            fontFamily: 'gt-walsheim-regular !important',
            fontSize: '16px',

            '@media  (max-width: 767px)': {
                fontSize: '14px !important',
            },
        },
        '& .savePwd': {
            padding: '8px 32px !important',
        },
        '& .emailBtn': {
            background: '#FFFFFF 0% 0% no-repeat ',
            border: '2px solid #DB12A9',
            marginTop: '30px',
            borderRadius: '29px',
            color: '#470A68',
            fontFamily: 'eir-medium !important',
            outline: 'none',
            fontSize: '20px !important',
            textTransform: 'initial',
            padding: theme.spacing(1, 2),
            marginBottom: '30px',
            '&:hover': {
                background: '#892BA0 0% 0% no-repeat ',
                border: '2px solid #892BA0',
                color: '#fff',
            },
            '@media  (max-width: 281px)': {
                fontSize: '12px !important',
                padding: theme.spacing(1, 2),
                marginTop: '20px',
                marginBottom: '20px',
            },
            '@media  (min-width: 281px) and (max-width: 767px)': {
                fontSize: '15px !important',
                padding: theme.spacing(1, 2),
                marginTop: '20px',
                marginBottom: '20px',
            },
        },

        '& .formLogoSection': {
            height: '100px',
            boxShadow: '0px 3px 25px #00000024',
            borderRadius: '9px 9px 0px 0px',
            background:
                'url(/opencms/export/system/modules/my.portal.opencms/myeir_ion/images/login-header-image.jpg) ',
            '& img': {
                width: '128px',
                marginTop: '8px',
                '@media  (max-width: 1025px)': {
                    width: '115px',
                    marginTop: '15px',
                },
            },
        },

        '& .errorMessage': {
            width: '100%',
            fontFamily: 'gt-walsheim-regular !important',
            textAlign: 'left',
            marginTop: '.25rem',
            fontSize: '16px !important',
            color: '#e40046 !important',
            display: 'block',
            '@media (max-width: 767px)': {
                textAlign: 'left',
                fontSize: '14px !important',
            },
        },
        '& .forRegform': {
            '@media (max-width: 1024px)': {
                paddingBottom: '15px',
            },
        },
    },
}));
