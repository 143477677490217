import { withStyles } from '@material-ui/core/styles';
import { Switch } from '@material-ui/core';

export const MyEirSwitch = withStyles(theme => ({
    root: {
        width: 30,
        height: 18,
        padding: 0,
        display: 'flex',
        marginTop: '3px',
    },
    switchBase: {
        padding: 2,
        // color: theme.palette.grey[500],
        color: '#FFFFFF',
        '&$checked': {
            transform: 'translateX(12px)',
            // color: theme.palette.common.white,
            color: '#D011C9', //red

            '& + $track': {

                // backgroundColor: theme.palette.primary.main,
                // borderColor: theme.palette.primary.main,
                backgroundColor: '#D011C9',
                borderColor: '#D011C9',
                opacity: '0.38',
            },
        },
    },
    thumb: {
        width: 14,
        height: 14,
        boxShadow: 'none',
    },
    track: {
        border: `1px solid #949494`,
        borderRadius: 18 / 2,
        opacity: 1,
        // backgroundColor: theme.palette.common.white,
        backgroundColor: '#949494',
    },
    checked: {},
}))(Switch);
