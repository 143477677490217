import React, { useState, useEffect, Fragment, useReducer } from 'react';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import '../sass/app.scss';
import '../components/loading/loading.scss';
// import '../components/my-eir-dashboard/rtm.scss';
import MyEirLogout from '../components/my-eir-logout/MyEirLogout';
import MyEirTopUp from '../components/my-eir-topup/MyEirTopUp';
import MyEirDashboardConatiner from '../components/my-eir-dashboard/MyEirDashboardContainer';
import MyEirProfileContainer from '../components/my-eir-profile/MyEirProfileContainer';
import MyEirUsageContainer from '../components/my-eir-usage/MyEirUsageContainer';
import MyEirAddonContainer from '../components/my-eir-addons/MyEirAddonContainer';
import PrepayApp from '../components/PrepayApp';
import MobileApp from '../mobilecomponents/MobileApp';
import Session from '../components/shared/Session';
import useGTMPageView from '../hooks/useGTMPageView';
import HeaderContainer from './HeaderContainer';
import FooterContainer from './FooterContainer';
import MyEirEmailConfirmationModal from './MyEirPostpayRegistration/MyEirEmailConfirmationModal';
import MyEirCreatePassword from './MyEirPostpayRegistration/MyEirCreatePassword';
import MyEirAlreadyRegistered from './MyEirPostpayRegistration/MyEirAlreadyRegistered';
import MyEirDOB from './MyEirPostpayRegistration/MyEirDOB';
import MyEirEnterAccountNumber from './MyEirPostpayRegistration/MyEirEnterAccountNumber';
import MyEirPhoneNumber from './MyEirPostpayRegistration/MyEirPhoneNumber';
import MyEirRegisterEmail from './MyEirPostpayRegistration/MyEirRegisterEmail';
import MyEirVerificationCode from './MyEirPostpayRegistration/MyEirVerificationCode';
import MyEirForgotPasswordForm from './my-eir-forgotPassword/MyEirForgotPasswordForm';
import BillDetails from '../mobilecomponents/bill-details/BillDetails';
// import PaymentMethod from '../mobilecomponents/payment-flow/PaymentMethod';
import RegisterContainer from './MyEirPostpayRegistration/RegisterContainer';
import { MY_EIR_CONST } from '../components/myeirconstant/myEirConstant';
import ResetPasswordContainer from './MyEirPostpayRegistration/ResetPasswordContainer';
import MyEirCreatePasswordForm from './my-eir-createPassword/MyEirCreatePasswordForm';
import PasswordContainer from './MyEirPostpayRegistration/PasswordContainer';
import MyEirAccountContainer from '../mobilecomponents/my-account/MyEirAccountContainer';
import MyEirCreatePasswordFormPage from './my-eir-createPassword/MyEirCreatePasswordFormPage';
import MyEirRegisterForm from '../components/my-eir-register/MyEirRegisterForm';
import PaymentFlowModal from '../mobilecomponents/payment-flow/PaymentFlowModal';
import PaymentStatusModal from '../mobilecomponents/payment-flow/PaymentStatusModal';
import ForgotUsernameContainer from './MyEirPostpayRegistration/ForgotUsernameContainer';
import MyBalance from '../mobilecomponents/my-balance/MyBalance';
import PaymentAddCard from '../mobilecomponents/payment-flow/PaymentAddCard';
import AmazonPrimeVideo from '../mobilecomponents/eir-amazon-prime-video/AmazonPrimeVideo';
import DeviceManagement from '../mobilecomponents/my-plan/manage-my-device/DeviceManagement';
import MyEirBalanceContainer from '../mobilecomponents/redesign-5.2/my-balance/MyEirBalanceContainer';
import MyEirNavigationBarContainer from '../mobilecomponents/redesign-5.2/navigation-bar/MyEirNavigationBarContainer';
import SimReplacement from '../mobilecomponents/redesign-5.2/SimReplacement';
import MobileHeaderRedesign from '../mobilecomponents/redesign-5.2/MobileHeaderRedesign';
import MyPreferences from '../../src/mobilecomponents/redesign-5.2/preferences/MyPreferences';
import RedesignApp from '../mobilecomponents/redesign-5.2/RedesignApp';
import MyAccountDetails from '../../src/mobilecomponents/redesign-5.2/account-details/MyAccountDetails';
import MyEirContracts from '../mobilecomponents/redesign-5.2/contract-details/MyEirContracts';
import AccountSimreplacement from '../mobilecomponents/redesign-5.2/sim-replacement/AccountSimreplacement';
import MyEirServicesContainer from '../mobilecomponents/redesign-5.2/my-services/MyEirServicesContainer';
import MyEirOnboardingContainer from '../mobilecomponents/redesign-5.2/onboarding-screens/MyEirOnboardingContainer';
import MyEirBillContainer from '../../src/mobilecomponents/redesign-5.2/my-bills/MyEirBillContainer';
import MyEirMyPaymentsNew from '../../src/mobilecomponents/redesign-5.2/my-payment/MyEirMyPaymentsNew';
import FAQ from '../mobilecomponents/FAQ';
import ActiveAddonsContainer from '../mobilecomponents/redesign-5.2/manage-my-addons/ActiveAddonsContainer';
import RecomendedOffersContainer from '../mobilecomponents/redesign-5.2/recomended-offers/RecomendedOffersContainer';
import { initialState, redesignReducer, RedesignContext } from '../global-context-redesign';
import MyEirOfferDetail from '../mobilecomponents/redesign-5.2/offer-details/MyEirOfferDetail';
import NoticeOfMultipleAccount from '../mobilecomponents/my-login/NoticeOfMultipleAccount';
import LoginContainer from '../mobilecomponents/my-login/LoginContainer';

function App() {
    const [loginSuccess, setLoginsuccess] = useState(
        JSON.parse(sessionStorage.getItem('loginSuccess')) == true ? true : false
    );
    const [state, dispatch] = useReducer(redesignReducer, initialState);
    // const [token, setToken] = useState();
    const history = useHistory();
    // const [path, setPath] = useState(window.location.pathname);
    const isR6Account = sessionStorage.getItem('hasR6');
    const [hasR6Acc, setHasR6Acc] = useState(false);

    useEffect(() => {
        const billType = sessionStorage.getItem('LoggedinUserBillType');
        const pathname = window.location.pathname;

        // setPath(pathname);
        if (billType == 'Prepay') {
            pathname.indexOf('/account') == 0 && history.push('/prepay/dashboard');

            pathname == '/' && history.push('/prepay/dashboard');
        }
        if (billType == 'Bill') {
            pathname.indexOf('/prepay') == 0 && history.push('/prepay/dashboard');

            pathname == '/' && history.push('/account/dashboard');
            // pathname == '/' && history.push('/account/redesign-dashboard');
        }

        if (billType == 'Fixed_Bill') {
            (pathname.indexOf('/prepay') == 0 || pathname.indexOf('/account') == 0) &&
                (window.location.href = location.origin);
            loginSuccess && pathname == '/' && (window.location.href = '/bundles/dashboard');
        }
    }, [window.location.pathname]);

    useEffect(() => {
        setHasR6Acc(isR6Account);
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            const badge = document.querySelector('.grecaptcha-badge');

            if (badge) {
                badge.style.zIndex = '9999';
                badge.style.setProperty('bottom', '84px', 'important');

                badge.addEventListener('click', function (e) {
                    e.preventDefault();
                    e.stopPropagation();

                    if (badge.style.transform === 'translateY(0px)') {
                        badge.style.transform = 'translateY(60px)';
                    } else {
                        badge.style.transform = 'translateY(0px)';
                    }
                });
                clearInterval(interval);
            }
        }, 100);
    }, []);

    useGTMPageView();
    return (
        <div>
            {loginSuccess && <Session />}
            <RedesignContext.Provider value={{ state, dispatch }}>
                <Switch>
                    <Route exact path='/'>
                        <div id='my-eir-mobile' className='myeir-mobile-background'>
                            <HeaderContainer appname={'mobile'} loginSuccess={loginSuccess} />

                            <LoginContainer />
                            <FooterContainer appname={'mobile'} />
                        </div>
                    </Route>
                    <Route exact path='/register'>
                        <div
                            id='my-eir-mobile'
                            className='myeir-mobile-background myeir-background-large '
                        >
                            <HeaderContainer appname={'mobile'} loginSuccess={loginSuccess} />
                            <RegisterContainer />
                            <FooterContainer appname={'mobile'} />
                        </div>
                    </Route>
                    <Route exact path='/forgot-email'>
                        <div
                            id='my-eir-mobile'
                            className='myeir-mobile-background myeir-background-large'
                        >
                            <HeaderContainer appname={'mobile'} loginSuccess={loginSuccess} />
                            <ForgotUsernameContainer />
                            <FooterContainer appname={'mobile'} />
                        </div>
                    </Route>

                    <Route exact path='/forgot-password'>
                        <div id='my-eir-mobile' className='myeir-mobile-background'>
                            <HeaderContainer appname={'mobile'} loginSuccess={loginSuccess} />
                            <ResetPasswordContainer />
                            <FooterContainer appname={'mobile'} />
                        </div>
                    </Route>
                    <Route exact path='/create-password'>
                        <div id='my-eir-mobile' className='myeir-mobile-background'>
                            <HeaderContainer appname={'mobile'} loginSuccess={loginSuccess} />
                            <PasswordContainer />
                            <FooterContainer appname={'mobile'} />
                        </div>
                    </Route>
                    {/* <Route exact path='/mobile/test'>
                    <MobileApp loginSuccess={loginSuccess}>
                        <div style={{ background: '#FFFFFF' }}>
                            TV
                            <AmazonPrimeVideo apvCustomerType='TV' />
                            Broadband
                            <AmazonPrimeVideo apvCustomerType='Broadband' />
                            DeviceManagement
                            <DeviceManagement />
                        </div>
                    </MobileApp>
                </Route>
                <Route exact path='/mobile/test/payment'>
                    <MobileApp loginSuccess={loginSuccess}>
                        <PaymentFlowModal
                            open={true}
                            toggle={console.log(true)}
                            amount={1.2}
                            buttonRequired={false}
                        />
                    </MobileApp>
                </Route>*/}
                    {loginSuccess ? (
                        <Fragment>
                            <Route exact path='/prepay/dashboard'>
                                <PrepayApp>
                                    <MyEirDashboardConatiner />
                                </PrepayApp>
                            </Route>
                            <Route exact path='/prepay/topup'>
                                <PrepayApp>
                                    <MyEirTopUp />
                                </PrepayApp>
                            </Route>
                            <Route exact path='/prepay/logout'>
                                <PrepayApp>
                                    <MyEirLogout />
                                </PrepayApp>
                            </Route>
                            <Route exact path='/prepay/profile'>
                                <PrepayApp>
                                    <MyEirProfileContainer />
                                </PrepayApp>
                            </Route>
                            <Route exact path='/prepay/my-usage'>
                                <PrepayApp>
                                    <MyEirUsageContainer />
                                </PrepayApp>
                            </Route>
                            <Route exact path='/prepay/my-addons'>
                                <PrepayApp>
                                    <MyEirAddonContainer />
                                </PrepayApp>
                            </Route>
                            {/* <Route exact path='/account/dashboard'>
                            <MobileApp loginSuccess={loginSuccess} />
                        </Route> */}
                            <Route exact path='/account/dashboard'>
                                {/* <MobileApp loginSuccess={loginSuccess} /> */}
                                <RedesignApp loginSuccess={loginSuccess} offsetBackgroundImage>
                                    <MyEirOnboardingContainer />
                                    {hasR6Acc === 'true' && <NoticeOfMultipleAccount />}
                                    <MyEirBalanceContainer />

                                    <MyEirServicesContainer />
                                    <ActiveAddonsContainer />
                                    <RecomendedOffersContainer />
                                    <FAQ />

                                    <MyEirNavigationBarContainer />
                                </RedesignApp>
                            </Route>
                            {/* <Route exact path='/account/redesign-dashboard'>
                            <RedesignApp loginSuccess={loginSuccess} offsetBackgroundImage>
                                <MyEirBalanceContainer />

                                <MyEirServicesContainer />

                                <MyEirNavigationBarContainer />
                            </RedesignApp>
                        </Route> */}
                            <Route exact path='/account/details'>
                                <RedesignApp loginSuccess={loginSuccess}>
                                    <MyAccountDetails />
                                </RedesignApp>
                            </Route>
                            <Route exact path='/account/offers'>
                                <RedesignApp loginSuccess={loginSuccess}>
                                    <MyEirOfferDetail />
                                    {/* commeted the below component for testers to test offerPage in prod mobile view/ */}
                                    <MyEirNavigationBarContainer />
                                </RedesignApp>
                            </Route>
                            <Route exact path='/account/simreplacement'>
                                <RedesignApp loginSuccess={loginSuccess}>
                                    <AccountSimreplacement />
                                </RedesignApp>
                            </Route>
                            <Route exact path='/account/contracts'>
                                <RedesignApp loginSuccess={loginSuccess}>
                                    <MyEirContracts />
                                </RedesignApp>
                            </Route>
                            <Route exact path='/account/preferences'>
                                <RedesignApp loginSuccess={loginSuccess}>
                                    <MyPreferences />
                                </RedesignApp>
                            </Route>
                            <Route exact path='/account/bills'>
                                <RedesignApp loginSuccess={loginSuccess}>
                                    <MyEirBillContainer />
                                    <MyEirNavigationBarContainer />
                                </RedesignApp>
                            </Route>
                            <Route exact path='/account/payments'>
                                <RedesignApp loginSuccess={loginSuccess} offsetBackgroundImage>
                                    <MyEirMyPaymentsNew />
                                    <MyEirNavigationBarContainer />
                                </RedesignApp>
                            </Route>
                        </Fragment>
                    ) : (
                        <Redirect to={{ pathname: '/' }} />
                    )}
                    <Route>
                        {/* this is used for 404. */}
                        <Redirect to={{ pathname: '/' }} />
                    </Route>
                </Switch>
            </RedesignContext.Provider>
        </div>
    );
}

export default App;
