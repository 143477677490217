import { makeStyles } from '@material-ui/core/styles';
export const recomendedOfferStyles = makeStyles(theme => ({
    mainContainer: {
        maxWidth: '1076px',
        margin: 'auto',
        paddingBottom: '30px',
        '@media (min-width: 601px) and (max-width: 1024px)': {
            width: '100%',
            maxWidth: '694px',
            padding: '0px 24px',
            marginBottom: '44px',
        },
    },
    navigateIcon: {
        color: theme.palette.primary.sunset,
    },
    mainHeading: {
        fontFamily: theme.typography.h6.fontFamily,
        fontStyle: 'normal',
        // fontWeight: 700,
        fontSize: theme.typography.h3.fontSize,
        lineHeight: '38px',
        color: theme.palette.primary.heather,
        letterSpacing: '0.25px',
        textAlign: 'left',
        '@media  (max-width: 600px)': {
            textAlign: 'center',
            fontSize: '24px',
            lineHeight: '32px',
            padding: '0px 10px 32px 10px',
        },
    },
    cardContainer: {
        cursor: 'pointer',
        boxShadow: `0px 4px 18px 3px ${theme.palette.primary.boxShadow2Lite}, 0px 10px 14px 1px ${theme.palette.primary.boxShadow2Medium}, 0px 6px 6px -3px r${theme.palette.primary.boxShadow2Lite}`,
        '@media  (max-width: 600px)': {
            marginBottom: '24px',
            width: '203px',
            height: '278px',
            padding: '7px',
            borderRadius: '6px',
            backgroundColor: theme.palette.primary.white,
        },

        '@media  (min-width: 601px) and (max-width:1020px)': {
            width: '203px',
            height: '278px',
            padding: '7px',
            borderRadius: '6px',
            backgroundColor: theme.palette.primary.white,
        },
        '@media  (min-width: 1021px)': {
            width: '343px',
            height: '468px',
            borderRadius: '10px',
            padding: '12px 12px 52px 11px',
            backgroundColor: theme.palette.primary.white,
            position: 'relative',
        },
    },
    swiperContainer: {
        '& .swiper-container': {
            overflow: 'initial',
        },
        '& .swiper-slide': {
            width: 'auto !important',
        },
        '& .swiper-wrapper': {
            display: 'flex',
            gap: '24px',
            justifyContent: 'flex-start',
        },
        paddingBottom: '50px',
        '@media  (max-width: 600px)': {
            paddingBottom: '0px',
            marginLeft: '-44px',
            '& .swiper-container': {
                overflow: 'hidden',
            },
            '& .swiper-wrapper': {
                gap: '10px',
                paddingLeft: '3.95rem',
            },
        },
    },

    dotsContainer: {
        background: 'transparent !important',
        justifyContent: 'center !important',
        alignItems: 'center !important',
        padding: '0px 0px 18px 0px !important',
    },

    stepDot: {
        '& .MuiStepIcon-root': {
            width: '8px !important',
            height: '8px !important',
            color: `${theme.palette.primary.gray550} !important`,
        },
        '& .MuiStepIcon-text': {
            display: 'none !important',
        },
        '& .MuiStepIcon-active': {
            color: `${theme.palette.primary.heather} !important`,
        },
        paddingLeft: '2px !important',
        paddingRight: '2px !important',
        cursor: 'pointer !important',
    },

    // Offer Box Styles begins here

    offerBoxContainer: {
        position: 'relative',
        height: '51px',
        width: '175px',
        marginTop: '-25px',
        marginInline: 'auto',
        // margin: '0px 13px',
        borderRadius: '6.16px',
        backdropFilter: 'blur(9.24274px)',

        '@media  (min-width: 1021px)': {
            width: '295px',
            height: '82px',
            marginTop: '-40px',
        },
    },
    leftTextContainer: {
        padding: '10px 16px',
        margin: 'auto',
        textAlign: 'left',
        '@media  (max-width: 1020px)': {
            padding: '6px 10px',
        },
    },
    rightTextContainer: {
        padding: '10px 16px',
        margin: 'auto',
        '@media  (max-width: 1020px)': {
            padding: '8px 10px',
        },
    },
    leftTopHeading: {
        fontFamily: theme.typography.h6.fontFamily,
        // fontWeight: 700,
        fontSize: '21px',
        lineHeight: '30px',
        // color: theme.palette.primary.white,

        '@media  (max-width: 1020px)': {
            fontSize: '13px',
            lineHeight: '18px',
        },
    },
    leftBottomHeading: {
        fontFamily: theme.typography.body1.fontFamily,
        fontWeight: 400,
        fontSize: theme.typography.subtitle2.fontSize,
        lineHeight: '22px',
        // color: theme.palette.primary.white,
        '@media  (max-width: 1020px)': {
            fontSize: '8px',
            lineHeight: '13px',
        },
    },
    divider: {
        width: '0.8px',
        height: '44px',
        margin: 'auto',
        '@media  (max-width: 1020px)': {
            height: '26px',
            width: '0.47px',
        },
    },

    rightTopHeading: {
        fontFamily: theme.typography.body1.fontFamily,
        fontWeight: 400,
        fontSize: theme.typography.inputLabel.fontSize,
        lineHeight: '18px',
        // color: theme.palette.primary.white,
        '@media  (max-width: 1020px)': {
            fontSize: '8px',
            lineHeight: '10px',
        },
    },
    rightBottomHeading: {
        fontFamily: theme.typography.body1.fontFamily,
        fontWeight: 600,
        fontSize: '20px',
        lineHeight: '32px',
        // color: theme.palette.primary.white,
        '@media  (max-width: 1020px)': {
            fontSize: theme.typography.subtitle2.fontSize,
            lineHeight: '19px',
        },
    },

    cardImg: {
        width: '100%',
        height: '100%',
        '@media  (max-width: 1020px)': {
            maxWidth: '191px',
            maxHeight: '241px',
        },
    },

    preferences: {
        fontFamily: theme.typography.body1.fontFamily,
        fontWeight: 400,
        fontSize: theme.typography.body1.fontSize,
        lineHeight: '24px',
        color: theme.palette.primary.heather,
        '@media  (max-width: 600)': {
            marginBottom: '20px',
        },
    },
    preferencesLight: {
        fontFamily: theme.typography.body1.fontFamily,
        fontWeight: 400,
        fontSize: theme.typography.body1.fontSize,
        lineHeight: '24px',
        color: theme.palette.primary.sunset,
        textDecoration: 'underline',
        cursor: 'pointer',
    },
    dropper: {
        width: '100%',
        height: '47px',
        bottom: '-47px',
        left: 0,
        marginTop: '-1px',
        position: 'absolute',
        background: '#f3f5f9',
        color: theme.palette.primary.heather,
        textAlign: 'center',
        fontFamily: theme.typography.body1.fontFamily,
        fontWeight: 400,
        fontSize: theme.typography.subtitle2.fontSize,
        lineHeight: '22px',
        textDecoration: 'underline',
        cursor: 'pointer',
        borderRadius: '0px 0px 10px 10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: `0px 4px 18px 3px ${theme.palette.primary.boxShadow2Lite}, 0px 10px 14px 1px ${theme.palette.primary.boxShadow2Medium}, 0px 6px 6px -3px ${theme.palette.primary.boxShadow2Lite}`,
    },
    mobile: {
        width: '100%',
        height: '47px',
        bottom: '-15px',
        left: 0,
        marginTop: '-1px',
        position: 'absolute',
        background: '#f3f5f9',
        color: theme.palette.primary.heather,
        textAlign: 'center',
        fontFamily: theme.typography.body1.fontFamily,
        fontWeight: 400,
        fontSize: theme.typography.subtitle2.fontSize,
        lineHeight: '22px',
        textDecoration: 'underline',
        cursor: 'pointer',
        borderRadius: '0px 0px 10px 10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        boxShadow: `0px 4px 18px 3px ${theme.palette.primary.boxShadow2Lite}, 0px 10px 14px 1px ${theme.palette.primary.boxShadow2Medium}, 0px 6px 6px -3px ${theme.palette.primary.boxShadow2Lite}`,
    },
    offersContainer: {
        position: 'relative',
        display: 'flex',
        maxWidth: '1077px',
        maxHeight: '405px',
        height: '405px',
        background: theme.palette.primary.white,
        margin: 'auto',
        borderRadius: '10px',
        marginBottom: '60px',
        marginTop: '12px',
        padding: '10px',
        boxShadow: `0px 4px 18px 3px ${theme.palette.primary.boxShadow2Lite}, 0px 10px 14px 1px ${theme.palette.primary.boxShadow2Medium}, 0px 6px 6px -3px ${theme.palette.primary.boxShadow2Lite}`,
    },
    blanketPic: {
        position: 'absolute',
        right: '0',
        bottom: 0,
    },
    cover: {
        flex: 1,
        width: '100%',
    },
    offersContent: {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        marginTop: '100px',
        marginBottom: '100px',
        marginLeft: '37px',
        textAlign: 'left',
    },
    offersText: {
        width: '295px',
        fontFamily: theme.typography.h6.fontFamily,
        marginBottom: '32px',
        color: theme.palette.primary.heather,
        fontSize: theme.typography.h3.fontSize,
        lineHeight: '38px',
        letterSpacing: '0.25px',
    },
    makePayContainer: {
        width: '155px',
        // background: theme.palette.primary.sunset,
        // borderRadius: '4100px',
        display: 'flex',
        height: '40px',
        alignItems: 'center',
        marginBottom: '40px',
        cursor: 'pointer',
        justifyContent: 'center',
        // boxShadow: '0px 4px 45px -9px rgba(209, 17, 201, 0.4)',
        [theme.breakpoints.down('xs')]: {
            width: '296px',
            height: '41px',
            margin: '0 auto',
        },
    },
    paymentContent: {
        fontSize: theme.typography.body1.fontSize,
        textTransform: 'unset',
        fontFamily: theme.typography.h6.fontFamily,
        lineHeight: '24px',
        color: theme.palette.primary.white,
        margin: '0 auto',
        paddingTop: '8px',
        paddingBottom: '8px',
        alignItems: 'center',
        justifyContent: 'center',
        '@media (min-width: 600px) and (max-width:1019px)': {
            fontSize: theme.typography.subtitle2.fontSize,
            lineHeight: '22px',
            width: 'fit-content',
            marginTop: '2px',
        },
    },
    eye: {
        marginLeft: '8px',
        marginTop: '-4px',
    },
}));
