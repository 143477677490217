import { gql } from '@apollo/client';

const ENDPOINT = {
    GET_ADDRESSLIST: gql`
        query getAddress($eirCode: String!) {
            addressList(eirCode: $eirCode) {
                addresses {
                    addressLine1
                    addressLine2
                    addressLine3
                    town
                    county
                    eircode
                }
            }
        }
    `,

    SUBMIT_SIM_REPLACEMENT: gql`
        mutation simReplacement($request: PublicSimOrderRequest!, $token: String!) {
            orderSim(request: $request, token: $token)
        }
    `,

    GET_SIM_ELIGIBILITY_CHECK: gql`
        query getSimCardEligibilty($token: String!, $serviceId: Int!) {
            getSimCardEligibilty(token: $token, serviceId: $serviceId) {
                serviceId
                waived
                barServices
                iccid
                imsi
                status
                type
                simSwapAddonCode
                charges {
                    catalogChargeCode
                    catalogPricePlanCode
                    description
                    billingType
                    defaultPriceVatIncluded
                }
                firstName
                lastName
                email
                phoneNumber
                appliedFees
                updatedAt
                createdAt
                address {
                    addressLine1
                    addressLine2
                    town
                    county
                    code
                }
            }
        }
    `,

    SIM_ACTIVATE: gql`
        mutation activateSim($token: String!, $activateSim: ActivateSimRequest!) {
            activateSim(token: $token, activateSim: $activateSim)
        }
    `,

    GET_SIM_CHARGES: gql`
        query getSimSwapCharges($serviceId: Int!, $token: String!) {
            getSimSwapCharges(serviceId: $serviceId, token: $token) {
                charges {
                    billingType
                    catalogChargeCode
                    catalogPricePlanCode
                    defaultPriceVatIncluded
                    description
                }
                ongoingRequest
            }
        }
    `,
}

export const getAddressList = async ({ graphql, eirCode }) => {

    try {
        const { data } = await graphql.query({
            query: ENDPOINT.GET_ADDRESSLIST,
            variables: {
                eirCode: eirCode,
            },
        });

        return data.addressList.addresses;
    } catch (error) {
        throw new Error(error);
    } 
};

export const submitSimReplacement = async ({ graphql,request, token}) => {

    try {
       const { data } = await graphql.mutate({
            mutation: ENDPOINT.SUBMIT_SIM_REPLACEMENT,
            variables:{
                request,
                token,
            },
        });

        return data;
    } catch (error) {
        throw new Error(error);
    } 
};

export const getSimEligibilityCheck = async ({ graphql, token, serviceId }) => {
    try {
        const { data } = await graphql.query({
            query: ENDPOINT.GET_SIM_ELIGIBILITY_CHECK,
            variables: {
                token: token,
                serviceId: serviceId,
            },
        });

        return data;
    } catch (error) {
        throw new Error(error);
    }
};

export const activateSimAPI = async ({ token, graphql, activateSim }) => {
    try {
        const { data } = await graphql.mutate({
            mutation: ENDPOINT.SIM_ACTIVATE,
            variables: {
                token,
                activateSim
            },
        });
        return data.activateSim;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const getSimCardCharges = async ({ graphql, token, serviceId }) => {

    try {
        const { data } = await graphql.query({
            query: ENDPOINT.GET_SIM_CHARGES,
            variables: {
                token: token,
                serviceId: serviceId,
            },
        });

        return data;
    } catch (error) {
        throw new Error(error);
    } 
};