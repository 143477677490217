/**
 *
 * @name sortAsc -- Sort itme in ascending order
 * @returns - sorted Array
 */

const sortAsc = items => items?.sort((a, b) => a.sortOrder - b.sortOrder);

/**
 *
 * @name structureData --- a function used to structure the data coming from getTariff
 * @param {Object} --- offer object to be structured
 * @property {String} type - represents the type of the attributes like feature, headerimg..etc
 * @property {String} value - represents the value of the attribute
 * @property {String} icon - icon associated with the type of attribute
 * @property {String} title - display Name of the Offer
 * @property {Number} price - display price of the Offer
 * @property {Number}  discountDuration- display the duration of offer in months
 * @property {Array}  descriptions- An array of attriutes associated with the offer
 * @property {String} sash - gives the contract details
 * @property {String} description - gives the offer descriptions
 * @returns {Object} - An Object containing the structure data
 */

export const structureData = offer => ({
    title: offer?.displayName ? offer?.displayName : offer?.description,
    image: offer?.attributes.filter(attr => attr.type === 'headerImage').map(attr => attr.value),
    price: offer?.displayPrice / 100,
    afterPrice: offer?.defaultPrice ? `€${offer.defaultPrice / 100} a month thereafter` : '',
    description: offer?.attributes
        .filter(attr => attr.type === 'MyEirOfferCardDescription')
        .map(attr => attr.value),
    title1: offer?.attributes
        .filter(attr => attr.type === 'MyEirOfferCardDisplayName')
        .map(attr => attr.value)[0],
    title2: offer?.attributes
        .filter(attr => attr.type === 'MyEirOfferCardDisplayName')
        .map(attr => attr.value)[1],
    discountDuration: offer?.discountDuration,
    commitmentDuration: offer?.commitmentDuration,
    priceIncreaseTagline: offer?.attributes
        .filter(attr => attr.type === 'priceIncreaseTagline')
        .map(attr => attr.value),
    sash: offer?.attributes.filter(attr => attr.type === 'sash').map(attr => attr.value),
    serviceGroup: offer?.serviceGroupCode,
    propositionId: offer?.adobeOffer?.propositionId,
    descriptions: sortAsc(offer?.attributes?.filter(attr => attr?.type === 'feature')).map(
        attr => ({
            value: attr?.value,
            icon: window.location.origin + attr?.icon,
        })
    ),
    addonNote:offer?.attributes
    .filter(attr => attr.type === 'myEirAddonNote')
    .map(attr => attr.value),
    addonSuccessTitle:offer?.attributes
    .filter(attr => attr.type === 'myEirAddonSuccessTitle')
    .map(attr => attr.value),
    addonSuccessSubTitle:offer?.attributes
    .filter(attr => attr.type === 'myEirAddonSuccessSubTitle')
    .map(attr => attr.value),
});

// function to remove duplicat array element

export const removeDuplicateArrayElements = (arr, propertyToCheck) => {
    return arr.filter((element, index, self) => {
        const firstIndex = self.findIndex(e => e[propertyToCheck] === element[propertyToCheck]);

        return index === firstIndex;
    });
};

export function formatAddress(option) {
    let addressLine1 = '';
    let addressLine2 = '';
    let addressLine3 = '';
    let town = '';
    let county = '';
    if (option?.addressLine1) {
        addressLine1 = option.addressLine1
            .toLowerCase()
            .split(' ')
            .map(s => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
    }

    if (option?.addressLine2) {
        addressLine2 = option.addressLine2
            .toLowerCase()
            .split(' ')
            .map(s => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        addressLine2 = `, ${addressLine2}`;
    }
    if (option?.addressLine3) {
        addressLine3 = option.addressLine3
            .toLowerCase()
            .split(' ')
            .map(s => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        addressLine3 = `, ${addressLine3}`;
    }
    if (option?.town) {
        town = option.town
            .toLowerCase()
            .split(' ')
            .map(s => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        town = `, ${town}`;
    }
    if (option?.county) {
        county = option.county;
        county = county.slice(3);
        county = county
            .toLowerCase()
            .split(' ')
            .map(s => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        county = `, ${county}`;
    }
    return addressLine1 + addressLine2 + addressLine3 + town + county;
}
