import TagManager from 'react-gtm-module';
import { UAParser } from 'ua-parser-js';
var parser = new UAParser();

console.log('parser', parser.getResult());

var parserResult = parser.getResult();

const useGTMForPWAA2HPopup = () => {
    const tagManagerArgs = {
        dataLayer: {
            app: 'my-eir-ion',
            event: 'PwaA2HS',
            device: parserResult.device,
            os: parserResult.os,
            browser: parserResult.browser,
        },
        dataLayerName: 'datalayer',
    };
    TagManager.dataLayer(tagManagerArgs);
};

export default useGTMForPWAA2HPopup;
