export const FAQ_QUESTIONS = {
    faqQuestions: [
        {
            faqQuestionTitle: 'What is "No Limits Data"?',
            faqQuestionDiscription:
                'Now on eir mobile, there are really no limits to the amount of data you can use. Whether you like to binge on endless series on Netflix, stream music for hours on end, game into the wee small hours or simply watch your favourite content on YouTube, with NO LIMITS DATA there really are no limits!',
        },
        {
            faqQuestionTitle: 'Where can I upgrade my prepay mobile phone?',
            faqQuestionDiscription:
                'Find our full range of prepay mobile phones <a href="https://www.eir.ie/mobile/phones/prepay/" class="faqLink" target="_blank">here</a>',
        },
        {
            faqQuestionTitle: 'How do I opt into an offer?',
            faqQuestionDiscription:
                "Simply choose the Simplicity offer that's right for you <a href='https://www.eir.ie/mobile/prepay/activate/'  class='faqLink' target='_blank'>here</a>, text the opt-in code to 50104 and top up by the correct amount. Your offer should be active in minutes, just wait for two texts to confirm your top up has been successful.",
        },
    ],
};
