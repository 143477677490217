import axios from 'axios';
import { nanoid } from 'nanoid';

/**
 * @name getStrapiData - gets data from Strapi CMS
 * @param {String} page - the page data
 * @returns {Promise} cmsData
 */
const getStrapiData = async page => {
    const traceId = nanoid(10);
    try {
        const URL = `/online-acquisition-cms/api/${page}?populate=deep`;
        console.log(`[${traceId}][STRAPI] ---> ${URL}`);
        const { data: cmsData } = await axios.get(URL);
        return cmsData;
    } catch (error) {
        console.error(`[${traceId}][STRAPI] <--- ${JSON.stringify(error)}`);
    }
};

export default getStrapiData;
