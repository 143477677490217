import { gql } from '@apollo/client';

const ENDPOINT = {
    GET_ONBOARDING_STATUS: gql`
        mutation getOnboardingStatus($emailAddress: String!) {
            getOnboardingStatus(emailAddress: $emailAddress) {
                SHOW_ONBOARDING_STATUS
                ONBOARDING_VERSION
            }
        }
    `,
    SET_ONBOARDING_STATUS: gql`
        mutation updateShowDisplayStatus($emailAddress: String!) {
            updateShowDisplayStatus(emailAddress: $emailAddress) {
                SHOW_ONBOARDING_STATUS
            }
        }
    `,
    GET_ONBOARDING_DETAILS: gql`
        query getOnboardingScreenContents($onboardingVersion: Int!) {
            getOnboardingScreenContents(onboardingVersion: $onboardingVersion) {
                onboardingVersion
                welcomeScreen {
                    title
                    description
                    backgroundImageDesktop {
                        imageURL
                        imageAtlText
                        imageGradient
                    }
                    backgroundImageMobile {
                        imageURL
                        imageAtlText
                        imageGradient
                    }
                }
                otherScreen {
                    title
                    description
                    backgroundImageMobile {
                        imageURL
                        imageAtlText
                        imageGradient
                    }
                    backgroundImageDesktop {
                        imageURL
                        imageAtlText
                        imageGradient
                    }
                }
            }
        }
    `,
};

/**
 * 
  @name getOnboardingStatusApi - calls the onboarding status api and returns response
  @param {Object} Obj - attributes for the function
  @param {GraphQL} Obj.graphql - appoloclient graphql constant


 */
export const getOnboardingStatusApi = async ({ graphql }) => {
    const emailAddress = sessionStorage.getItem('LoggedinUser');
    try {
        const data = await graphql.mutate({
            mutation: ENDPOINT.GET_ONBOARDING_STATUS,
            variables: {
                emailAddress: emailAddress,
            },
        });
        return data;
    } catch (error) {
        throw new Error(error);
    }
};
/**
 * 
  @name setOnboardingStatusApi - calls the set onboarding status api and returns response
  @param {Object} Obj - attributes for the function
  @param {GraphQL} Obj.graphql - appoloclient graphql constant
  

 */
export const setOnboardingStatusApi = async ({ graphql }) => {
    const emailAddress = sessionStorage.getItem('LoggedinUser');
    try {
        const data = await graphql.mutate({
            mutation: ENDPOINT.SET_ONBOARDING_STATUS,
            variables: {
                emailAddress: emailAddress,
            },
        });
        return data;
    } catch (error) {
        throw new Error(error);
    }
};

/**
 * 
 *@name getOnboardingScreenContentsApi - gets the onboarding data from the api and returns it 
  @param {Object} Obj - attributes for the function
  @param {GraphQL} Obj.graphql - appoloclient graphql constant
 */

export const getOnboardingScreenContentsApi = async ({ graphql }) => {
    try {
        const onboardingVersion = parseInt(sessionStorage.getItem('OnboardingVersion'));
        // const onboardingVersion = 1;
        const { data } = await graphql.query({
            query: ENDPOINT.GET_ONBOARDING_DETAILS,
            variables: {
                onboardingVersion: onboardingVersion,
            },
        });
        return data.getOnboardingScreenContents;
    } catch (error) {
        throw new Error(error);
    }
};
