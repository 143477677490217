import React, { useState, Fragment, useEffect } from 'react';
import MyEirContactDetails from './MyEirContactDetails';
import { MyEirDirectoryPreference } from '../my-eir-register/MyEirDirectoryPreference';
import { MyEirMarketingPreferences } from '../my-eir-register/MyEirMarketingPreferences';
import { Controller, useForm, FormContext } from 'react-hook-form';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { MARKETING_PREFERENCES } from '../my-eir-register/constants';
import { useRegisterFormStyle } from '../my-eir-register/useRegisterFormStyle';
import { useApolloClient } from '@apollo/client';
import ENDPOINT from '../graphql/GraphQLConstants';
import Loading from '../loading/Loading';
import {
    Grid,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Container, Button, Paper } from '@material-ui/core';
const theme = createMuiTheme({
    typography: {
        textTransform: 'none',
        textAlign: 'left',
        letterSpacing: '0px',
        fontFamily: 'gt-walsheim-regular',
        opacity: 1,
        fontSize: '16px',
    },
    palette: {
        primary: {
            main: '#892BA0',
        },
    },
    overrides: {
        MuiOutlinedInput: {
            root: {
                fontSize: '16px',
                fontFamily: 'gt-walsheim-medium !important',
                '@media (max-width: 1024px)': {
                    fontSize: '14px',
                },
            },
        },
        MuiInput: {
            input: {
                '&::placeholder': {
                    color: 'gray',
                },
                color: 'white', // if you also want to change the color of the input, this is the prop you'd use
            },
        },
        MuiFormControlLabel: {
            label: {
                '@media (max-width: 1024px)': {
                    fontSize: '14px',
                },
            },
        },
        MuiFormLabel: {
            asterisk: {
                color: '#f44336',
            },
        },
        MuiSelect: {
            icon: {
                color: '#892BA0',
            },
        },
        MuiTextField: {
            root: {
                fontSize: '16px',
                color: 'black',
                '@media (max-width: 1024px)': {
                    fontSize: '14px',
                },
            },
        },

        MuiInputLabel: {
            root: {
                color: 'black',
                fontSize: '16px',
                '@media (min-width:768px) and (max-width: 1024px)': {
                    fontSize: '14px',
                },
                '@media (min-width:322px) and (max-width: 415px)': {
                    fontSize: '12px',
                },
                '@media (min-width:281px) and (max-width: 321px)': {
                    fontSize: '10px',
                },
                '@media (min-width:279px) and (max-width: 280px)': {
                    fontSize: '8px',
                },
            },
            shrink: {
                fontSize: '16px',
                '@media (min-width:320px) and (max-width: 541px)': {
                    fontSize: '14px',
                },
                '@media (min-width:280px) and (max-width: 321px)': {
                    fontSize: '12px',
                },
            },
        },
    },
});

function MyEirProfileContainer() {
    const classes = useRegisterFormStyle();
    const graphql = useApolloClient();
    const [profileStatus, setProfileStatus] = useState(false);
    const [nddStatus, setNddStatus] = useState(false);
    const [permissionStatus, setPermissionStatus] = useState(false);
    const [directoryPreference, setDirectoryPreference] = useState('');
    const [contactGroupList, setContactGroupList] = useState(MARKETING_PREFERENCES);
    const [contactDetails, setContactDetails] = useState([]);
    const [newContactGroupList, setNewContactGroupList] = useState(null);
    const [profileUpdateStatus, setProfileUpdateStatus] = useState('');
    const [dialogOpen, setDialogOpen] = useState(false);
    const [continueDisabled, setContinueDisabled] = useState(false);
    const [loading, setLoading] = useState(true);
    const methods = useForm({ mode: 'onChange' });
    const token = sessionStorage.getItem('access_token');
    const serviceId = parseInt(sessionStorage.getItem('ServiceId'));

    const handleClose = () => {
        setDialogOpen(false);
    };

    const onSubmit = async data => {
        setContinueDisabled(true);
        if (token && serviceId) {
            const permissionGroupList = JSON.parse(data.permissionGroupList);
            try {
                await graphql.mutate({
                    mutation: ENDPOINT.UPDATE_NDDPREFERENCE,
                    variables: {
                        token,
                        serviceId,
                        nddPreference: '{ "nddPreference": "' + directoryPreference + '"}',
                    },
                    refetchQueries: [
                        {
                            query: ENDPOINT.GET_NDDPREFERENCE,
                            variables: {
                                token,
                                serviceId,
                            },
                        },
                    ],
                });

                const permissionGroups = [
                    {
                        permissionGroup: 'ACTIVE_CUSTOMER',
                        permissions: [
                            {
                                enabled: permissionGroupList[0].preferenceList[0].enabled,
                                permission: 'ALLOW_EMAIL_CONTACT',
                            },
                            {
                                enabled: permissionGroupList[0].preferenceList[1].enabled,
                                permission: 'ALLOW_SMS_CONTACT',
                            },
                            {
                                enabled: permissionGroupList[0].preferenceList[2].enabled,
                                permission: 'ALLOW_PHONE_CONTACT',
                            },
                            {
                                enabled: permissionGroupList[0].preferenceList[3].enabled,
                                permission: 'ALLOW_FOTS_CONTACT',
                            },
                            {
                                enabled: permissionGroupList[0].preferenceList[4].enabled,
                                permission: 'ALLOW_DIRECT_MAIL_CONTACT',
                            },
                        ],
                    },
                    {
                        permissionGroup: 'NO_LONGER_CUSTOMER',
                        permissions: [
                            {
                                enabled: permissionGroupList[1].preferenceList[0].enabled,
                                permission: 'ALLOW_EMAIL_CONTACT',
                            },
                            {
                                enabled: permissionGroupList[1].preferenceList[1].enabled,
                                permission: 'ALLOW_SMS_CONTACT',
                            },
                            {
                                enabled: permissionGroupList[1].preferenceList[2].enabled,
                                permission: 'ALLOW_PHONE_CONTACT',
                            },
                            {
                                enabled: permissionGroupList[1].preferenceList[3].enabled,
                                permission: 'ALLOW_FOTS_CONTACT',
                            },
                            {
                                enabled: permissionGroupList[1].preferenceList[4].enabled,
                                permission: 'ALLOW_DIRECT_MAIL_CONTACT',
                            },
                        ],
                    },
                ];
                const allowThirdParty = permissionGroupList[2]
                    ? permissionGroupList[2].enabled
                        ? true
                        : false
                    : false;
                await graphql.mutate({
                    mutation: ENDPOINT.UPDATE_CONTACT_PERMISSION_GROUP,
                    variables: {
                        token,
                        allowThirdParty,
                        permissionGroups,
                    },
                    refetchQueries: [
                        {
                            query: ENDPOINT.GET_PERMISSION,
                            variables: {
                                token,
                                serviceId,
                            },
                        },
                    ],
                });
                setProfileUpdateStatus('Profile updated successfully');
                setDialogOpen(true);
                setContinueDisabled(false);
            } catch (error) {
                console.error('Unable to update Marketing Preferences : ' + error.message);
                setProfileUpdateStatus('Sorry, something went wrong,  please try again later');
                setDialogOpen(true);
                setContinueDisabled(false);
            }
        } else {
            console.error('Unable to update Marketing Preferences : Invalid Token or Service ID');
            setProfileUpdateStatus('Sorry, something went wrong,  please try again later');
            setDialogOpen(true);
            setContinueDisabled(false);
        }
    };
    useEffect(() => {
        const fetchProfileDetails = async () => {
            try {
                //throw new Error('Error thrown in fetchProfileDetails****');
                const res = await graphql.query({
                    query: ENDPOINT.GET_PROFILE_DETAILS,
                    variables: {
                        token,
                        serviceId,
                    },
                    errorPolicy: 'all',
                });
                if (res.data.getMyContactDetails) {
                    //contact details
                    setContactDetails(res.data.getMyContactDetails);
                    setProfileStatus(true);
                }
                if (res.data.getMyNDDPreference) {
                    //NDD Preference
                    if (res.data.getMyNDDPreference.nddPreference) {
                        setDirectoryPreference(res.data.getMyNDDPreference.nddPreference);
                        setNddStatus(true);
                    }
                }
                if (res.data.getMyPermissionDetails) {
                    //permission details
                    setNewContactGroupList(res.data.getMyPermissionDetails);
                    let newContactGroupList = res.data.getMyPermissionDetails;
                    if (newContactGroupList != null) {
                        contactGroupList[2].enabled =
                            res.data.getMyPermissionDetails.allowThirdParty;
                        contactGroupList[1].preferenceList[0].enabled =
                            newContactGroupList.permissionGroupResponse[1].permissions[0].enabled;
                        contactGroupList[1].preferenceList[1].enabled =
                            newContactGroupList.permissionGroupResponse[1].permissions[1].enabled;
                        contactGroupList[1].preferenceList[2].enabled =
                            newContactGroupList.permissionGroupResponse[1].permissions[2].enabled;
                        contactGroupList[1].preferenceList[3].enabled =
                            newContactGroupList.permissionGroupResponse[1].permissions[3].enabled;
                        contactGroupList[1].preferenceList[4].enabled =
                            newContactGroupList.permissionGroupResponse[1].permissions[4].enabled;
                        contactGroupList[0].preferenceList[0].enabled =
                            newContactGroupList.permissionGroupResponse[0].permissions[0].enabled;
                        contactGroupList[0].preferenceList[1].enabled =
                            newContactGroupList.permissionGroupResponse[0].permissions[1].enabled;
                        contactGroupList[0].preferenceList[2].enabled =
                            newContactGroupList.permissionGroupResponse[0].permissions[2].enabled;
                        contactGroupList[0].preferenceList[3].enabled =
                            newContactGroupList.permissionGroupResponse[0].permissions[3].enabled;
                        contactGroupList[0].preferenceList[4].enabled =
                            newContactGroupList.permissionGroupResponse[0].permissions[4].enabled;

                        const preferenceIndex = contactGroupList[1].preferenceList.findIndex(
                            preference => preference.enabled === true
                        );
                        if (preferenceIndex < 0) {
                            contactGroupList[1].enabled = false;
                        } else {
                            contactGroupList[1].enabled = true;
                        }

                        const selectedIndex = contactGroupList[0].preferenceList.findIndex(
                            preference => preference.enabled === true
                        );
                        if (selectedIndex < 0) {
                            contactGroupList[0].enabled = false;
                        } else {
                            contactGroupList[0].enabled = true;
                        }

                        setNewContactGroupList(contactGroupList);
                        setContactGroupList(contactGroupList);
                    }
                    setPermissionStatus(true);
                }
                setLoading(false);
            } catch (err) {
                setLoading(false);
                console.error('Error in fetching Profile Details : ' + err.message);
            }
        };
        if (serviceId) {
            fetchProfileDetails();
        } else {
            setLoading(false);
        }
    }, []);
    return (
        <Fragment>
            <ThemeProvider theme={theme}>
                <div className={classes.RegisterForm}>
                    <Container>
                        <div className='regform'>
                            <h1 className='profileTitle'>My profile</h1>
                            {profileUpdateStatus && (
                                <Dialog
                                    PaperProps={{ className: classes.dialogPaper }}
                                    open={dialogOpen}
                                    onClose={handleClose}
                                    aria-labelledby='alert-dialog-title'
                                    aria-describedby='alert-dialog-description'
                                    className={classes.eirDialogContainer}
                                >
                                    <DialogTitle
                                        id='alert-dialog-title'
                                        className={classes.dialogTitle}
                                    ></DialogTitle>
                                    <IconButton
                                        aria-label='close'
                                        className={classes.closeButton}
                                        onClick={handleClose}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                    <DialogContent className={classes.eirDialogContent}>
                                        <DialogContentText
                                            id='alert-dialog-description'
                                            className={classes.notification}
                                        >
                                            {profileUpdateStatus}
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            align='center'
                                            className={classes.eirDialog}
                                        >
                                            <Button
                                                className={classes.cancelBtn}
                                                onClick={handleClose}
                                            >
                                                Close
                                            </Button>
                                        </Grid>
                                    </DialogActions>
                                </Dialog>
                            )}

                            <FormContext {...methods}>
                                <form
                                    onSubmit={methods.handleSubmit(onSubmit)}
                                    className='regcontainer'
                                >
                                    {' '}
                                    <Paper className={classes.paper + ' ' + 'paddingTop'}>
                                        {loading && (
                                            <span className='loadingPosition'>
                                                <Loading />
                                            </span>
                                        )}
                                        {profileStatus && !loading && contactDetails != '' && (
                                            <MyEirContactDetails
                                                contactDetails={contactDetails}
                                                profileStatus={profileStatus}
                                            />
                                        )}

                                        {!profileStatus && !loading && (
                                            <MyEirContactDetails
                                                contactDetails={contactDetails}
                                                profileStatus={profileStatus}
                                            />
                                        )}
                                    </Paper>
                                    {nddStatus && permissionStatus && !loading && (
                                        <Paper className={classes.paper + ' ' + 'paddingTop'}>
                                            {newContactGroupList != null && (
                                                <MyEirMarketingPreferences
                                                    contactGroupList={contactGroupList}
                                                    setContactGroupList={setContactGroupList}
                                                    profile={'profile'}
                                                    nddStatus={nddStatus}
                                                />
                                            )}
                                            <MyEirDirectoryPreference
                                                directoryPreference={directoryPreference}
                                                setDirectoryPreference={setDirectoryPreference}
                                                nddStatus={nddStatus}
                                            />
                                            <Grid item xs={12} sm={12} md={12}>
                                                <div className='mobileFloat'>
                                                    <Button
                                                        onClick={methods.handleSubmit(onSubmit)}
                                                        type='submit'
                                                        className='saveBtn loginBtn'
                                                        disabled={continueDisabled}
                                                    >
                                                        Save changes
                                                    </Button>
                                                </div>
                                            </Grid>
                                        </Paper>
                                    )}
                                    {(!nddStatus || !permissionStatus) && !loading && (
                                        <Paper className={classes.paper + ' ' + 'paddingTop'}>
                                            <p className='errorNddText'>Marketing preferences</p>
                                            <Grid container justify='center'>
                                                <Grid list md={12} xs={12} sm={12}>
                                                    <span className='errorText'>
                                                        There was an error loading your details
                                                    </span>
                                                </Grid>
                                                <Grid list md={12} xs={12} sm={12}>
                                                    <a
                                                        className='tryAgain'
                                                        onClick={() => window.location.reload()}
                                                    >
                                                        {' '}
                                                        Click here to try again
                                                    </a>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    )}
                                </form>
                            </FormContext>
                        </div>
                    </Container>
                </div>
            </ThemeProvider>
        </Fragment>
    );
}

export default MyEirProfileContainer;
