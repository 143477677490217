import React, { useState, Fragment, useEffect } from 'react';
import MyEirFaq from './MyEirFaq';
import { createMuiTheme, ThemeProvider, makeStyles } from '@material-ui/core/styles';
import {
    Grid,
    Container,
    Button,
    Paper,
    Dialog,
    Typography,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
} from '@material-ui/core';
import { useDashboardStyle } from './useDashboardStyle';
import MyEirAcountDetails from './MyEirAcountDetails';
import MyEirbalance from './MyEirbalance';
import MyEirUsage from './MyEirUsage';
import MyEirPlans from './MyEirPlans';
import MyEirRTM from './MyEirRTM';
import MyEirBanner from './MyEirBanner';
import Loading from '../loading/Loading';
import { useApolloClient } from '@apollo/client';
import ENDPOINT from '../graphql/GraphQLConstants';
import CloseIcon from '@material-ui/icons/Close';
import { MY_EIR_CONST } from '../myeirconstant/myEirConstant';
const theme = createMuiTheme({
    typography: {
        textTransform: 'none',
        textAlign: 'left',
        letterSpacing: '0px',
        opacity: 1,
        fontSize: '16px',
    },
    palette: {
        primary: {
            main: '#892BA0',
        },
    },
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: '14px',
            },
        },
        MuiSelect: {
            icon: {
                color: 'transparent',
                border: 'solid #470A68',
                borderWidth: '0 3px 3px 0',
                display: 'inline-block',
                // padding: '4px',
                width: '11px',
                height: '11px',
                transform: 'rotate(45deg)',
                marginLeft: '4px',
                marginBottom: '4px',
            },
            iconOpen: {
                transform: 'rotate(45deg)',
            },
        },
    },
});
const useSessionStyle = makeStyles(theme => ({
    dialogPaper: {
        overflowY: 'visible !important',
        maxWidth: '700px',
    },

    eirDialogContainer: {
        textAlign: 'center',
    },

    eirDialogTitle: {
        marginBottom: '0.5rem',
        fontFamily: 'eir-medium',
        fontWeight: '500',
        lineHeight: '1.2',
        color: '#892ba0',
        fontSize: '24px',
    },

    eirDialogContent: {
        padding: '0px 100px',
        '@media (max-width: 767px)': {
            padding: '8px 24px',
        },
    },

    notification: {
        color: '#323435',
        //fontSize: '18px',
        textAlign: 'center',
        padding: '18px 56px 0px',
        fontFamily: ' eir-medium !important',
        '@media (max-width: 767px)': {
            fontSize: '16px',
            padding: '0px',
        },
    },

    eirDialog: {
        padding: '8px 0px 40px',
        textAlign: 'center',
        '@media (max-width: 767px)': {
            fontSize: '18px',
            padding: '0px 0px 18px',
        },
    },

    button: { outline: 'none' },

    eirButton: {
        borderRadius: '2rem',
        fontSize: 18,
        textTransform: 'initial',
        padding: '8px 48px',
        border: '1px solid #892ba0',
        background: '#fff',
        marginRight: '10px',
        marginBottom: '10px',

        '&:hover,&:focus': {
            background: '#f2e7f4',
        },

        '@media (min-width:280px) and (max-width: 320px)': {
            fontSize: 12,
            padding: '6px 24px',
        },
        '@media (min-width:321px) and (max-width: 767px)': {
            padding: '8px 32px',
            fontSize: 16,
        },
        '@media (min-width:768px) and (max-width: 1024px)': {
            padding: '8px 48px',
            fontSize: 16,
        },
    },

    purpleBtn: {
        borderRadius: '2rem',
        fontSize: 18,
        textTransform: 'initial',
        padding: '8px 45px',
        border: '1px solid #f217bb',
        background: '#f217bb',
        color: '#fff',
        marginRight: '10px',
        marginBottom: '10px',
        '@media (min-width:280px) and (max-width: 320px)': {
            fontSize: 12,
            padding: '6px 22px',
        },
        '@media (min-width:321px) and (max-width: 767px)': {
            padding: '8px 30px',
            fontSize: 16,
        },
        '@media (min-width:768px) and (max-width: 1024px)': {
            fontSize: 16,
        },
        '&:focus,&:hover': {
            outline: 'none',
            background: '#990c76',
            color: '#fff',
        },
    },
}));
function MyEirDashboardContainer() {
    const classes = useDashboardStyle();
    const popClasses = useSessionStyle();
    const graphql = useApolloClient();
    const [loading, setLoading] = useState(true);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [serviceStatus, setServiceStatus] = useState(false);
    const [service, setService] = useState([]);
    const [subscriptionStatus, setSubscriptionStatus] = useState(false);
    const [contacts, setContacts] = useState(null);
    const [balance, setBalance] = useState(null);
    const [myOffer, setMyOffer] = useState(null);
    const [addons, setAddons] = useState(null);
    const [simSwapDetails, setSimSwapDetails] = useState(null);
    const token = sessionStorage.getItem('access_token');

    useEffect(() => {
        getServiceIdAndContact();
    }, []);
    useEffect(() => {
        if (serviceStatus) {
            getBalanceDetailsAddons();
        }
    }, [serviceStatus]);

    const handleChange = serviceID => {
        sessionStorage.setItem('ServiceId', serviceID);
        setLoading(true);
        getBalanceDetailsAddons();
    };
    const getServiceIdAndContact = async () => {
        try {
            if (token) {
                const { data } = await graphql.query({
                    query: ENDPOINT.GET_SUBSCRIPTION_ID_AND_CONTACT,
                    variables: {
                        token: token,
                    },
                    errorPolicy: 'all',
                });
                if (data.getMySubscriptions) {
                    const prepaySubscriptions = data.getMySubscriptions.content.filter(
                        sub =>
                            (sub.serviceGroup === 'MOBILE' || sub.serviceGroup === 'MBB') &&
                            sub.type === 'PREPAY'
                    );
                    prepaySubscriptions.map((sub, index) => getServiceId(sub.id, index));
                    setSubscriptionStatus(true);
                } else {
                    console.error(
                        'GraphQL query getMySubscriptions was not successful, please refer to the network tab for further information! '
                    );
                    setLoading(false);
                    setDialogOpen(true);
                }
                if (data.getMyContactDetails) {
                    setContacts(data.getMyContactDetails);
                }
            } else {
                console.error('Unable to fetch Subscription and Service Details : Invalid Token');
                setLoading(false);
                setDialogOpen(true);
            }
        } catch (err) {
            console.error('Error encountered in getServiceIdAndContact : ' + err);
            setLoading(false);
            setDialogOpen(true);
        }
    };

    const getServiceId = async (subscriptionId, index) => {
        try {
            if (token && subscriptionId) {
                const { data } = await graphql.query({
                    query: ENDPOINT.SERVICE_ID,
                    variables: {
                        token: token,
                        subscriptionId: subscriptionId,
                    },
                });
                if (index === 0) {
                    if (data.getMyServices) {
                        sessionStorage.setItem('ServiceId', data.getMyServices[0].id);
                        sessionStorage.setItem('selectedPhone', data.getMyServices[0].msisdn);
                        setServiceStatus(true);
                        setService(service => service.concat(data.getMyServices));
                    } else {
                        console.error(
                            'GraphQL query getMyServices is not successful, please refer to the network tab for further information!'
                        );
                        setLoading(false);
                        setDialogOpen(true);
                    }
                } else {
                    if (data.getMyServices) {
                        setService(service => service.concat(data.getMyServices));
                    } else {
                        console.error(
                            'GraphQL query getMyServices is not successful, please refer to the network tab for further information!'
                        );
                    }
                }
            } else {
                console.error('Unable to fetch Service Details : Invalid Token or Subscription');
                setLoading(false);
                setDialogOpen(true);
            }
        } catch (err) {
            console.error('Error encountered in getServiceId :', err);
        }
    };

    const getBalanceDetailsAddons = async () => {
        try {
            const serviceId = sessionStorage.getItem('ServiceId');
            setBalance(null);
            setMyOffer(null);
            setAddons(null);
            if (token && serviceId) {
                const res = await graphql.query({
                    query: ENDPOINT.GET_BALANCE_DETAILS_ADDONS,
                    variables: {
                        token: token,
                        serviceId: serviceId,
                    },
                    errorPolicy: 'all',
                });
                if (res.data.getMyPrepayBalance) {
                    setBalance(res.data.getMyPrepayBalance);
                }
                if (res.data.getMyDetails) {
                    setMyOffer(res.data.getMyDetails);
                    sessionStorage.setItem(
                        'selectedPhone',
                        res.data.getMyDetails.customerOfferDTO.msisdn
                    );
                }
                if (res.data.getPinPukDetails) {
                    setSimSwapDetails(res.data.getPinPukDetails);
                }
                if (res.data.getMyAddons) {
                    setAddons(res.data.getMyAddons);
                }
                if (res.errors) {
                    const rootQuery = graphql.cache.data.data.ROOT_QUERY;
                    Object.keys(rootQuery).forEach(key => {
                        if (key.match(`${sessionStorage.getItem('ServiceId')}`)) {
                            graphql.cache.evict({ id: 'ROOT_QUERY', fieldName: key });
                        }
                    });
                }
                setLoading(false);
            } else {
                console.error(
                    'Unable to fetch Balance Details and Addons : Invalid Token or Service'
                );
                setLoading(false);
            }
        } catch (err) {
            console.error('Error encountered in getBalanceDetailsAddons : ', err);
            setLoading(false);
        }
    };

    const logout = () => {
        setDialogOpen(false);
        sessionStorage.clear();
        window.location.href = MY_EIR_CONST.url.REDIRECT_URL;
    };
    return (
        <Fragment>
            <ThemeProvider theme={theme}>
                <Dialog
                    PaperProps={{ className: popClasses.dialogPaper }}
                    open={dialogOpen}
                    onClose={logout}
                    maxWidth={'xs'}
                    fullWidth={true}
                    disableBackdropClick={true}
                    aria-labelledby='alert-dialog-title'
                    aria-describedby='alert-dialog-description'
                    className={popClasses.eirDialogContainer}
                >
                    <DialogTitle id='alert-dialog-title'></DialogTitle>
                    <DialogContent className={popClasses.eirDialogContent}>
                        <DialogContentText
                            id='alert-dialog-description'
                            className={popClasses.notification}
                        >
                            <h2 className={popClasses.eirDialogTitle}>Something went wrong.</h2>
                            Please try log in again or contact customer support.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            align='center'
                            className={popClasses.eirDialog}
                        >
                            <Button onClick={logout} className={popClasses.purpleBtn}>
                                Try again
                            </Button>
                        </Grid>
                    </DialogActions>
                </Dialog>
                {loading && (
                    <span className='loadingPosition'>
                        <Loading />
                    </span>
                )}
                {!loading && serviceStatus && (
                    <div className={classes.dashboardContainer}>
                        {contacts && service.length > 0 ? (
                            <MyEirBanner
                                contactDetails={contacts}
                                services={service}
                                onChange={handleChange}
                            />
                        ) : (
                            ''
                        )}
                        <div className='dashboardContentHolder'>
                            <Container>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6} md={6} className='noPaddingBottom'>
                                        <Paper className={classes.paper}>
                                            {balance ? (
                                                <MyEirbalance
                                                    balanceDetails={balance}
                                                    subscription={subscriptionStatus}
                                                />
                                            ) : (
                                                <MyEirbalance subscription={subscriptionStatus} />
                                            )}
                                        </Paper>
                                        <Paper className={classes.paper}>
                                            {simSwapDetails ? (
                                                <MyEirAcountDetails
                                                    planDetails={simSwapDetails}
                                                    subscription={subscriptionStatus}
                                                />
                                            ) : myOffer ? (
                                                <MyEirAcountDetails
                                                    planDetails={myOffer}
                                                    subscription={subscriptionStatus}
                                                />
                                            ) : (
                                                <MyEirAcountDetails
                                                    planDetails={simSwapDetails}
                                                    subscription={subscriptionStatus}
                                                />
                                            )}
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} className='noPaddingTop'>
                                        <Paper className={classes.paper}>
                                            <MyEirPlans
                                                planDetails={myOffer}
                                                addons={addons}
                                                noaddons={true}
                                                subscription={subscriptionStatus}
                                            />
                                            {/* {subscriptionStatus?
                                                  ( {addons != null && myOffer!=null && (
                                                    <MyEirPlans
                                                        planDetails={myOffer}
                                                        addons={addons}
                                                        noaddons={false}
                                                        subscription={subscriptionStatus}
                                                    />
                                                )}
                                                {addons == null && myOffer==null && (
                                                    <MyEirPlans
                                                        planDetails={myOffer}
                                                        addons={addons}
                                                        noaddons={true}
                                                        subscription={subscriptionStatus}
                                                    />
                                                )})
                                            :
                                                <MyEirPlans
                                                planDetails={myOffer}
                                                addons={addons}
                                                noaddons={true}
                                                subscription={subscriptionStatus}
                                            />
                                            } */}
                                        </Paper>
                                    </Grid>
                                </Grid>

                                {myOffer ? (
                                    <MyEirRTM msisdn={myOffer.customerOfferDTO.msisdn} />
                                ) : (
                                    ''
                                )}

                                <Paper className={classes.paper}>
                                    <MyEirFaq />
                                </Paper>
                            </Container>
                        </div>
                    </div>
                )}
            </ThemeProvider>
        </Fragment>
    );
}

export default MyEirDashboardContainer;
