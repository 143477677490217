import React, { useState } from 'react';
import { useLoginFormStyle } from '../css/useLoginFormStyle';
import { Typography } from '@material-ui/core';
import { ArrowBackIcon } from '../../sharedcomponents/inputs/svg-icons/CustomSvgIcons';

const MyEirLoginHeader = props => {
    const styleClass = useLoginFormStyle();

    return (
        <div className={styleClass.headerContainer}>
            <div className='login-header-back-icon-div'>
                <ArrowBackIcon
                    className={
                        'login-header-back-icon' +
                        (props.flag == 'login' || props.loginScreen == 1 ? ' hide' : '')
                    }
                    onClick={props.handleBackButton}
                />
            </div>

            <div className='login-header-text'>
                <Typography variant='h4'>{props.headerText}</Typography>
                <div className='login-header-line'></div>
            </div>
        </div>
    );
};

export default MyEirLoginHeader;
