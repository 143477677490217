import React, { useState, Fragment, useEffect } from 'react';
import { Grid, Container, Button, Paper } from '@material-ui/core';
import { useAddonStyle } from './useAddonStyle';

function MyEirOneOffDataAddon() {
    const classes = useAddonStyle();
    return (
        <Fragment>
            <Container>
                <Grid container spacing={2} className='profileMargin'>
                    <Grid item xs={12} sm={7}>
                        <h2>One off data add ons</h2>
                        <p>
                            Choose a Booster when you need a little extra to tide you over. You can
                            buy as many as you want, whenever you need them.
                        </p>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <div className='mobileFloat'>
                            <img
                                className='addonImg'
                                src='/opencms/export/system/modules/my.portal.opencms/myeir_ion/images/my-eir-add-on-one-off-booster.png'
                            ></img>
                        </div>
                    </Grid>
                </Grid>
                <Grid container spacing={2} className='profileMargin'>
                    <Grid item xs={12} sm={6}>
                        <Paper className={classes.paper + ' ' + 'boxLayout '}>
                            <p>10GB Booster One Off Data Add-on</p>
                            <h3>€9.99</h3>

                            <p className='freeText'>
                                <span className='iconLayout'>
                                    {' '}
                                    <img src='/opencms/export/system/modules/my.portal.opencms/myeir_ion/images/my-eir-text.png'></img>
                                </span>
                                Freetext <span>BOOSTER10GB</span> to <span>50104</span>
                            </p>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Paper className={classes.paper + ' ' + 'boxLayout marginLeft'}>
                            <p>1GB Booster One Off Data Add-on</p>
                            <h3>€5.99</h3>

                            <p className='freeText'>
                                <span className='iconLayout'>
                                    {' '}
                                    <img src='/opencms/export/system/modules/my.portal.opencms/myeir_ion/images/my-eir-text.png'></img>
                                </span>
                                Freetext<span> BOOSTER1</span> to <span>50104</span>
                            </p>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </Fragment>
    );
}
export default MyEirOneOffDataAddon;
