import {
    Box,
    Button,
    MobileStepper,
    Modal,
    Step,
    StepIcon,
    Stepper,
    Typography,
    useTheme,
} from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import WelcomeScreen from './WelcomeScreen';
import OtherScreens from './OtherScreens';
import { useHistory } from 'react-router-dom';
import { onBoardingStyles } from './onBoardingStyles';
import { useWindowDimension } from '../../../hooks/windowDimensions.js';
import { useApolloClient } from '@apollo/client';
import 'swiper/swiper-bundle.min.css';
import { getOnboardingStatus, setOnboardingStatus } from './onboarding.util';
import sadsmily from '../../images/sad.png';
import Loading from '../../../components/loading/Loading';
import EirButton from '../../../theme/EirButton';

SwiperCore.use([Navigation]);

export default function MyEirOnboardingContainer() {
    const [activeStep, setActiveStep] = useState(0);
    const [welcomeData, setWelcomeData] = useState({});
    const [otherScreensData, setOtherScreensData] = useState([]);
    const [lenArray, setLenArray] = useState(0);
    const [welcomeObjectLength, setWelcomeObjectLength] = useState(0);
    const [otherScreenObjectLength, setOtherScreenObjectLength] = useState(0);
    const [close, setClose] = useState(false);
    const [open, setOpen] = useState(true);
    const [width, height] = useWindowDimension();
    const [showOnBoarding, setShowOnBoarding] = useState(false);
    const [onboardingData, setOnboardingData] = useState({});
    const [isApiFails, setIsApiFails] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const classes = onBoardingStyles();
    const history = useHistory();
    const graphql = useApolloClient();
    const swiperRef = useRef(null);
    const handleNext = () => {
        const swiper = swiperRef.current.swiper;
        const nextStep = activeStep + 1;
        setActiveStep(nextStep);

        if (nextStep === lenArray) {
            setOnboardingStatus({ graphql, setShowOnBoarding });
            history.push('/account/dashboard');
            setClose(true);
            setOpen(false);
        } else {
            const nextSlide = nextStep.toString();
            swiper.slideTo(nextSlide);
        }
    };

    const handleStepClick = stepIndex => {
        const swiper = swiperRef.current.swiper;
        setActiveStep(stepIndex);
        swiper.slideTo(stepIndex);
    };

    useEffect(() => {
        getOnboardingStatus({
            graphql,
            setShowOnBoarding,
            setWelcomeData,
            setOnboardingData,
            setOtherScreensData,
            setOtherScreenObjectLength,
            setWelcomeObjectLength,
            setIsApiFails,
            setIsLoading,
        });
    }, []);

    useEffect(() => {
        setLenArray(() =>
            welcomeObjectLength === 0 ? otherScreenObjectLength : otherScreenObjectLength + 1
        );
    }, [welcomeObjectLength, otherScreenObjectLength]);

    const renderContent = () => {
        const origin = window.location.origin;
        if (welcomeObjectLength > 0 && otherScreenObjectLength > 0) {
            return (
                <Swiper
                    ref={swiperRef}
                    navigation={{ prevEl: '', nextEl: '' }}
                    showArrows={false}
                    onSlideChange={swiper => setActiveStep(swiper.activeIndex)}
                    allowTouchMove={width >= 1020 ? false : true}
                    className={classes.swiperContainer}
                >
                    <SwiperSlide>
                        <WelcomeScreen
                            altText={
                                width < 600
                                    ? onboardingData.welcomeScreen.backgroundImageMobile
                                          .imageAtlText
                                    : onboardingData.welcomeScreen.backgroundImageDesktop
                                          .imageAtlText
                            }
                            backgroundGradient={
                                width < 600
                                    ? onboardingData.welcomeScreen.backgroundImageMobile
                                          .imageGradient
                                    : onboardingData.welcomeScreen.backgroundImageDesktop
                                          .imageGradient
                            }
                            backgroundImage={
                                width < 600
                                    ? origin +
                                      onboardingData.welcomeScreen.backgroundImageMobile.imageURL
                                    : origin +
                                      onboardingData.welcomeScreen.backgroundImageDesktop.imageURL
                            }
                            title={onboardingData.welcomeScreen.title}
                        />
                    </SwiperSlide>
                    {onboardingData.otherScreen.map((screen, index) => (
                        <SwiperSlide key={index}>
                            <OtherScreens
                                title={screen.title}
                                description={screen.description}
                                altText={
                                    width < 600
                                        ? screen.backgroundImageMobile.imageAtlText
                                        : screen.backgroundImageDesktop.imageAtlText
                                }
                                backgroundImageGradient={
                                    width < 600
                                        ? screen.backgroundImageMobile.imageGradient
                                        : screen.backgroundImageDesktop.imageGradient
                                }
                                backgroundImage={
                                    width < 600
                                        ? origin + screen.backgroundImageMobile.imageURL
                                        : origin + screen.backgroundImageDesktop.imageURL
                                }
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
            );
        } else if (otherScreenObjectLength === 0 && welcomeObjectLength > 0) {
            return (
                <Swiper
                    ref={swiperRef}
                    navigation={{ prevEl: '', nextEl: '' }}
                    showArrows={false}
                    onSlideChange={swiper => setActiveStep(swiper.activeIndex)}
                    className={classes.swiperContainer}
                    allowTouchMove={width >= 1020 ? false : true}
                >
                    <SwiperSlide>
                        <WelcomeScreen
                            altText={
                                width < 600
                                    ? onboardingData.welcomeScreen.backgroundImageMobile
                                          .imageAtlText
                                    : onboardingData.welcomeScreen.backgroundImageDesktop
                                          .imageAtlText
                            }
                            backgroundGradient={
                                width < 600
                                    ? onboardingData.welcomeScreen.backgroundImageMobile
                                          .imageGradient
                                    : onboardingData.welcomeScreen.backgroundImageDesktop
                                          .imageGradient
                            }
                            backgroundImage={
                                width < 600
                                    ? origin +
                                      onboardingData.welcomeScreen.backgroundImageMobile.imageURL
                                    : origin +
                                      onboardingData.welcomeScreen.backgroundImageDesktop.imageURL
                            }
                            title={onboardingData.welcomeScreen.title}
                        />
                    </SwiperSlide>
                </Swiper>
            );
        } else if (otherScreenObjectLength > 0 && welcomeObjectLength === 0) {
            return (
                <Swiper
                    className={classes.swiperContainer}
                    ref={swiperRef}
                    navigation={{ prevEl: '', nextEl: '' }}
                    showArrows={false}
                    allowTouchMove={width >= 1020 ? false : true}
                    onSlideChange={swiper => setActiveStep(swiper.activeIndex)}
                >
                    {onboardingData.otherScreen.map((screen, index) => (
                        <SwiperSlide key={index}>
                            <OtherScreens
                                title={screen.title}
                                description={screen.description}
                                altText={
                                    width < 600
                                        ? screen.backgroundImageMobile.imageAtlText
                                        : screen.backgroundImageDesktop.imageAtlText
                                }
                                backgroundImageGradient={
                                    width < 600
                                        ? screen.backgroundImageMobile.imageGradient
                                        : screen.backgroundImageDesktop.imageGradient
                                }
                                backgroundImage={
                                    width < 600
                                        ? origin + screen.backgroundImageMobile.imageURL
                                        : origin + screen.backgroundImageDesktop.imageURL
                                }
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
            );
        } else {
            if (isLoading) {
                return renderLoading();
            } else {
                return renderSomethingWentWrong();
            }
        }
    };

    const renderLoading = () => {
        return (
            <Box className={classes.loaderContainer}>
                <Loading />
            </Box>
        );
    };

    const renderSomethingWentWrong = () => {
        return (
            <Box className={classes.imgContent}>
                <Typography className={classes.imgText}>Oops something went wrong!</Typography>
                <Box>
                    <Box
                        component='img'
                        src={sadsmily}
                        alt='sad-smily'
                        className={classes.sadImg}
                    />
                </Box>
                <Box>
                    <Typography className={classes.sadText}>Please try again later.</Typography>
                    <EirButton
                        variants='primaryButtomMedium'
                        style={{ padding: '0.5 2rem' }}
                        // className={classes.cancelBtn}
                        onClick={() => {
                            setIsApiFails(false),
                                getOnboardingStatus({
                                    graphql,
                                    setShowOnBoarding,
                                    setWelcomeData,
                                    setOnboardingData,
                                    setOtherScreensData,
                                    setOtherScreenObjectLength,
                                    setWelcomeObjectLength,
                                    setIsApiFails,
                                    setIsLoading,
                                });
                        }}
                    >
                        Try Again
                    </EirButton>
                    <EirButton
                        // className={classes.closeBtn}
                        style={{ padding: '0.5rem 2.5rem' }}
                        variants='primaryButtonMedium'
                        onClick={() => {
                            setClose(true);
                            setOpen(false);
                        }}
                    >
                        Close
                    </EirButton>
                </Box>
            </Box>
        );
    };

    const renderButtonText = () => {
        if (activeStep === 0) {
            return 'Get Started';
        } else if (activeStep === lenArray - 1) {
            return 'Finish';
        } else {
            return 'Continue';
        }
    };

    const steps = Array.from({ length: lenArray });
    return (
        <>
            {showOnBoarding && (
                <Modal open={open} onClose={close} className={classes.modalContainer}>
                    <Box className={classes.modalBody}>
                        {isApiFails ? (
                            renderSomethingWentWrong()
                        ) : (
                            <>
                                {renderContent()}
                                <Box className={classes.stepperContainer}>
                                    <Stepper
                                        activeStep={activeStep}
                                        connector={null}
                                        className={classes.dotsContainer}
                                    >
                                        {steps.map((_, index) => (
                                            <Step
                                                key={index}
                                                completed={false}
                                                onClick={() => handleStepClick(index)}
                                                className={classes.stepDot}
                                            >
                                                <StepIcon />
                                            </Step>
                                        ))}
                                    </Stepper>
                                    {lenArray ? (
                                        <Button
                                            variant='contained'
                                            className={classes.stepperButton}
                                            size='small'
                                            onClick={handleNext}
                                        >
                                            {renderButtonText()}
                                        </Button>
                                    ) : (
                                        ' '
                                    )}
                                </Box>
                            </>
                        )}
                    </Box>
                </Modal>
            )}
        </>
    );
}
