import React, { useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import IconBadge from '../../../sharedcomponents/inputs/icon-badge/IconBadge';
import useStyles from './navbarStyles';
import ServicesIcon from '../my-services/ServicesIcon';
import { useHistory } from 'react-router-dom';
import { publish } from 'pubsub-js';

const MyEirNavItems = ({ item, selectedNav, setSelectedNav, offersVal, navNumbers }) => {
    const classes = useStyles();
    const history = useHistory();

    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const handleNavClick = val => {
        const location = window.location.href.indexOf('/account/dashboard') > -1;
        const paymentLoc = window.location.href.indexOf('/account/payments') > -1;

        switch (val) {
            case 'Home':
                val == 'Home' && location
                    ? goToTop()
                    : val == 'Home' && paymentLoc
                    ? ''
                    : history.push('/account/dashboard', { selectedNav: 'Home' });
                goToTop();
                break;
            case 'Services':
                !location && history.push('/account/dashboard', { selectedNav: 'Services' });
                publish('SCROLL_TO_SERVICES');
                break;
            case 'Bills':
                history.push('/account/bills', { selectedNav: 'Bills' });
                goToTop();
                break;
            case 'Offers':
                history.push('/account/offers', { selectedNav: 'Offers' });
                goToTop();
                break;
            case 'Help':
                // We cannot use window.open as Safari browser blocks it.
                let link = document.createElement('a');
                link.href = 'https://eir.ie/helpandsupport/';
                link.target = '_blank';
                link.rel = 'noopener noreferrer';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                goToTop();
                break;

            default:
                console.log('Empty action received.');
        }
        setSelectedNav(val);
    };

    useEffect(() => {
        if (history.location?.state?.selectedNav) {
            handleNavClick(history.location?.state?.selectedNav);
        } else {
            handleNavClick('Home');
        }
    }, []);
    window.onbeforeunload = function () {
        window.scrollTo(0, 0);
    };
    useEffect(() => {
        if (window.location.href.indexOf('/account/dashboard') > -1) {
            const handleScroll = () => {
                if (window.pageYOffset == 0) {
                    handleNavClick('Home');
                }
            };
            window.addEventListener('scroll', handleScroll);
            return () => window.removeEventListener('scroll', handleScroll);
        }
    }, []);
    return (
        <>
            {(offersVal.length === 0 || offersVal === undefined) && item.name === 'Offers' ? (
                ''
            ) : (
                <Box
                    key={item.name}
                    className={
                        `${classes.itemIcon} ` +
                        (item.name === selectedNav
                            ? navNumbers === 5
                                ? classes.activeWithOffer
                                : classes.active
                            : '')
                    }
                    onClick={() => handleNavClick(item.name)}
                >
                    <Box
                        className={
                            `${classes.icon} ` +
                            (item.name === selectedNav
                                ? navNumbers === 5
                                    ? classes.blackWithOffers
                                    : classes.black
                                : '')
                        }
                    >
                        <Box className={item.name === selectedNav ? classes.iconMT : ''}>
                            {item.badgeContent !== '' ? (
                                <IconBadge
                                    badgeContent={item.badgeContent}
                                    border={item.badgeColor}
                                    className={classes.badgeIcon}
                                >
                                    {item.icon(item.name === selectedNav)}
                                </IconBadge>
                            ) : (
                                <Box>{item.icon(item.name === selectedNav)}</Box>
                            )}
                        </Box>
                        <Box
                            className={
                                `${classes.option} ` +
                                (item.name === selectedNav ? classes.dark : '')
                            }
                        >
                            {item.name}
                        </Box>
                    </Box>
                </Box>
            )}
        </>
    );
};

export default MyEirNavItems;
