import { useApolloClient } from '@apollo/client';
import { Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import OfferBox from './OfferBox';

import { recomendedOfferStyles } from './recomendedOfferStyles';

const ApvOfferCard = ({
    cardImg,
    leftTopHeading,
    leftBottomHeading,
    rightTopHeading,
    rightBottomHeading,
    rightBottomLastHeading,
    background,
    divider2Color,
    divider1Color,
    apvToggle,
    setApvToggle,
    links,
    fontColor,
}) => {
    const classes = recomendedOfferStyles();

    const strippedText = new DOMParser().parseFromString(leftBottomHeading, 'text/html').body
        .innerText;
    const handleClick = () => {
        setApvToggle(true);
    };
    return (
        <>
            <Box className={classes.cardContainer}>
                <Box
                    component='img'
                    src={cardImg}
                    className={classes.cardImg}
                    onClick={() => handleClick()}
                />

                <Box onClick={() => handleClick()}>
                    <OfferBox
                        leftTopHeading={leftTopHeading}
                        leftBottomHeading={strippedText}
                        rightTopHeading={rightTopHeading}
                        rightBottomHeading={rightBottomHeading}
                        rightBottomLastHeading={rightBottomLastHeading}
                        background={background}
                        divider1Color={divider1Color}
                        divider2Color={divider2Color}
                        links={links}
                        apvToggle={apvToggle}
                        setApvToggle={setApvToggle}
                        fontColor={fontColor}
                    />
                </Box>
            </Box>
        </>
    );
};

export default ApvOfferCard;
