import React, { useEffect, useState } from 'react';
import { Box, Grid, Hidden, Typography } from '@material-ui/core';
import useStyles from './style';
import mySimIcon from '../.././images/mySimIcon.png';
import MyEirSimReplacementContainer from './MyEirSimReplacementContainer';
import { getSimEligibilityResponse, getSimChargesResponse } from './simReplacement.util';
import { useApolloClient } from '@apollo/client';
import MyEirActivateSimReplacementContainer from './MyEirActivateSimReplacementContainer';

const MyEirSimReplacementTile = ({ ContactDetails, msisdn, serviceId }) => {
    const classes = useStyles();
    const graphql = useApolloClient();
    const [updateOrderLink, setUpdateOrderLink] = useState('hide');
    const [linkFlag, setLinkFlag] = useState(false);
    const [simCost, setSimCost] = useState(0);
    const [reorderForm, setReorderForm] = useState();
    const [reorderCost, setReorderCost] = useState(0);

    //order OR re-order link

    const getSimEligibilityResponseUtil = async () => {
        await getSimEligibilityResponse({
            graphql,
            serviceId,
            setUpdateOrderLink,
            setLinkFlag,
            setReorderForm,
            setReorderCost,
        });
    };
    //cost charges for sim

    const getSimChargesResponseUtil = async () => {
        await getSimChargesResponse({ graphql, serviceId, setSimCost });
    };

    useEffect(() => {
        getSimEligibilityResponseUtil();
        getSimChargesResponseUtil();
    }, [msisdn]);

    return (
        <Box
            className={
                `simTile ` +
                `${linkFlag ? ` sim-link ` : ''}` +
                `${updateOrderLink === 'Re-order' ? 'block' : ''}`
            }
        >
            <Box className='sim'>
                <Box component='img' src={mySimIcon} alt='mysim' />
                <Typography variant='subtitle1' className='phonenumber'>
                    {msisdn}
                </Typography>
            </Box>
            <Grid
                item
                className={
                    `details-topic-order ` + `${updateOrderLink === 'Re-order' ? 'no-flex' : ''}`
                }
            >
                {updateOrderLink === 'Order' ? (
                    <MyEirSimReplacementContainer
                        linkName={'Order'}
                        getSimEligibilityResponse={getSimEligibilityResponseUtil}
                        ContactDetails={ContactDetails}
                        serviceId={serviceId}
                        simCost={simCost}
                        flag={false}
                    />
                ) : updateOrderLink === 'Re-order' ? (
                    <>
                        <Box item xs={12} md={12} component='span' className='Activate-SIM'>
                            <MyEirActivateSimReplacementContainer serviceId={serviceId} />
                        </Box>
                        <Box component='span' className='verticalLine'>
                            |
                        </Box>
                        <Box className='tile-reorder'>
                            <MyEirSimReplacementContainer
                                linkName={'Re-order'}
                                reorderForm={reorderForm}
                                ContactDetails={ContactDetails}
                                serviceId={serviceId}
                                reorderCost={reorderCost}
                                flag={true}
                                getSimEligibilityResponse={getSimEligibilityResponseUtil}
                            />
                        </Box>
                    </>
                ) : updateOrderLink === 'hide' ? (
                    ''
                ) : (
                    <Box item xs={12} md={12} component='span' className='Activate-SIM'>
                        <MyEirActivateSimReplacementContainer serviceId={serviceId} />
                    </Box>
                )}
            </Grid>
        </Box>
    );
};

export default MyEirSimReplacementTile;
