import React, { useState, Fragment, useEffect } from 'react';
// import { Grid, TextField, Button, Tooltip, InputAdornment, IconButton } from '@material-ui/core';
// import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import {
    Grid,
    InputAdornment,
    IconButton,
    FormControl,
    InputLabel,
    OutlinedInput,
    TextField,
} from '@material-ui/core';
import errormesages from '../../components/my-eir-register/errormessages';
import HelpIcon from '@material-ui/icons/Help';
import { Controller, useForm, ErrorMessage } from 'react-hook-form';
import ENDPOINT from '../../components/graphql/GraphQLConstants';
import { useApolloClient } from '@apollo/client';
import { MY_EIR_CONST } from '../../components/myeirconstant/myEirConstant';
import { commonRegisterStyles } from '../css/myEirRegistrationStyles/commonRegisterStyles';
import { useEnterAcccountNumberStyle } from '../css/myEirRegistrationStyles/useEnterAcccountNumberStyle';
import { useVerificationCodeStyles } from '../css/useVerificationCodeStyles';
import MyEirRegisterHeader from '../MyEirPostpayRegistration/reusable/MyEirRegisterHeader';
import MyEirRegisterFooter from '../MyEirPostpayRegistration/reusable/MyEirRegisterFooter';
import MyEirAlreadyRegistered from '../MyEirPostpayRegistration/MyEirAlreadyRegistered';

function MyEirCreatePasswordForm(props) {
    const { validation } = errormesages;
    // const { token } = useParams();
    const [password, setPassword] = useState('');
    const [showPassLabel,setShowPassLabel] =useState(false);
    const [showConfirmPassLabel,setShowConfirmPassLabel] =useState(false);
    const [show, setShow] = useState(false);
    const [errMsg,setErrMsg] = useState(false);
    const [errorMsg,setErrorMsg] = useState(false);
    const [confirmBtn,setConfirmBtn] =useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [PasswordError, setPasswordError] = useState(false);
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);
    const [confirmPasswordMsg, setConfirmPasswordMsg] = useState(false);
    const graphql = useApolloClient();
    const [isOpen1, setIsopen1] = useState(false);
    const [isOpen2, setIsopen2] = useState(false);

    const handleNextClick=()=>{
        toggle2();
    }
    const toggle1 = () => {
        setIsopen1(!isOpen1);
    };

    const toggle2 = () => {
        setIsopen2(!isOpen2);
    };

    const { register, control, errors, handleSubmit, setValue, clearError } = useForm({
        mode: 'onChange',
    });
    const [tokenValue, setTokenValue] = useState('');
    useEffect(() => {
        let searchParams = new URLSearchParams(window.location.search);
        setTokenValue(searchParams.get('token'));

        // if (
        //     searchParams.get('token') == '' ||
        //     searchParams.get('token') == null ||
        //     searchParams.get('token') == undefined
        // ) {
        //     sessionStorage.clear();
        //     // window.location.href = MY_EIR_CONST.url.REDIRECT_URL;
        //     //window.location.href= window.location.origin;
        // }
    }, []);
    const handlePasswordChange = event => {
        const passwordValue = event.target.value;
        setPassword(passwordValue);
        var regex = /^(?=.*\d)(?=.*[A-Z]).{8,}$/;
        if (errors.password) {
            setPasswordError(false);
            setErrMsg(false);
        }
        if (regex.test(passwordValue) == false) {
            setPasswordError(true);
            setErrMsg(true);
            if (errors.password) {
                clearError('password');
            }
        } else {
            setPasswordError(false);
            setErrMsg(false);
        }
    };
    const handleConfirmPasswordChange = event => {
        const confirmpasswordValue = event.target.value;
        if (errors.confirmPassword) {
            setConfirmPasswordError(false);
            setErrorMsg(false);
        }
        if (confirmpasswordValue == password) {
            setConfirmPasswordError(false);
            setErrorMsg(false);
            setConfirmBtn(true);
        } else {
            setConfirmPasswordError(true);
            setErrorMsg(true);
        }
    };

    const onSubmit = async data => {
        // console.log(data);
        // console.log('ttttttt', { token });
        try {
            const res = await graphql.mutate({
                mutation: ENDPOINT.SET_PASSWORD,
                variables: {
                    password: data.password,
                    token: tokenValue,
                },
            });

            if (res.data.setPassword) {
                setConfirmPasswordMsg(true);
            }
        } catch (err) {}
    };

    const commonStyle=commonRegisterStyles();
    const useStyle = useVerificationCodeStyles();

    const handleClickShow=()=>{
        setShow(!show);
    }

    const handleMouseDownPass =()=>{
        setShow(!show);
    }
    const handleCancel=()=>{
        window.location.href="/";
    }

    const handlePassLabel = (val) =>{
        setShowPassLabel(val);
    }
    const handleConfirmPassLabel = (val) =>{
        setShowConfirmPassLabel(val);
    }

    return (
      
        <div className={commonStyle.registrationContainer}>
            <div className={commonStyle.cardContainer}>
                    <MyEirRegisterHeader headerText='Reset password'/>
        
            <form onSubmit={handleSubmit(onSubmit)} className='createForm'>
                {!confirmPasswordMsg && (
                    <div className={`${useStyle.body} verification-body`}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12}>
                            
                                 <div>
                                    <Controller
                                        as={<TextField type={show ?'text':'password'} 
                                        className={`${useStyle.codes} email-code reset-password`} variant="outlined" color="primary"
                                        InputLabelProps={{classes:{ root: "text-field-input-label-props-root", shrink:("text-field-input-label-props-shrink"+
                                        (errMsg?" text-field-input-label-props-shrink-invalid":"")), focused:("text-field-input-label-props-focused"+
                                        (errMsg ?" text-field-input-label-props-focused-invalid":""))}}}
                                        
                                        InputProps={{classes:{ root: ("text-field-input-props-root"+(!!password?" text-field-input-props-root-filled":"")), 
                                        focused:"text-field-input-props-focused",  notchedOutline: ("text-field-input-props-notchedOutline"+
                                        (errMsg?" text-field-input-props-notchedOutline-invalid":"")), input: "text-field-input-props-input"},
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShow}
                                                onMouseDown={handleMouseDownPass}
                                                >
                                                {show ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                            )
                                        }}
                                        
                                        />}
                                        onFocus={()=>{handlePassLabel(true);}}
                                        onBlur={()=>{handlePassLabel(false);}}
                                        onChange={([event]) => {
                                            handlePasswordChange(event);
                                            return event.target.value;
                                        }}
                                        required
                                        control={control}
                                        fullWidth
                                        id='password'
                                        name='password'
                                        label='New password'
                                        variant='outlined'
                                        size='small'
                                        value={password}
                                        defaultValue={password}
                                        rules={{
                                            required: true,
                                        }}
                                      
                                    />
                                </div> 
                                {/* {errors.password && errors.password.type === 'required' && (
                                    <span className='invalid-messages'>Please enter password</span>
                                )} */}
                                {PasswordError && !errors.password && (
                                    <span className='invalid-messages'>
                                        Password must be atleast 8 characters long including Uppercase, Lowercase and Digits.
                                    </span>
                                )}
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12}>
                                <div>
                                    <Controller
                                        as={<TextField type='password' 
                                        className={`${useStyle.codes} email-code confirm-password`} variant="outlined" color="primary"
                                        InputLabelProps={{classes:{ root: "text-field-input-label-props-root", shrink:("text-field-input-label-props-shrink"+(errorMsg?" text-field-input-label-props-shrink-invalid":"")), focused:("text-field-input-label-props-focused"+(errorMsg?" text-field-input-label-props-focused-invalid":""))}}}
                                        InputProps={{classes:{ root: ("text-field-input-props-root"+(!!confirmPassword?" text-field-input-props-root-filled":"")), focused:"text-field-input-props-focused",  notchedOutline: ("text-field-input-props-notchedOutline"+(errorMsg?" text-field-input-props-notchedOutline-invalid":"")), input: "text-field-input-props-input"}}}
                                   
                                        />}
                                        onFocus={()=>{handleConfirmPassLabel(true);}}
                                        onBlur={()=>{handleConfirmPassLabel(false);}}
                                        onChange={([event]) => {
                                            handleConfirmPasswordChange(event);
                                            return event.target.value;
                                        }}
                                        required
                                        control={control}
                                        fullWidth
                                        id='passconfirmPasswordword'
                                        name='confirmPassword'
                                        label='Confirm password'
                                        variant='outlined'
                                        size='small'
                                        value={confirmPassword}
                                        defaultValue={confirmPassword}
                                        rules={{
                                            required: true,
                                        }}
                                    />
                                </div>
                                {/* {errors.confirmPassword &&
                                    errors.confirmPassword.type === 'required' && (
                                        <span className='invalid-messages'>Please enter password</span>
                                    )} */}
                                {confirmPasswordError && !errors.confirmPassword && (
                                    <span className='invalid-messages'>
                                        Please make sure the Passwords match.
                                    </span>
                                )}
                            </Grid>
                        </Grid>
                        {/* <p className='text-left' style={{ marginTop: '16px' }}>
                            Your password must be at least 8 characters long. For security reasons
                            it must include at least one capital letter (R), one lowercase letter
                            (p) and one number (7).
                        </p> */}

                        {/* <Grid item xs={12} sm={12} md={12}>
                            <div className='mobileFloat'>
                                <Button
                                    onClick={handleSubmit(onSubmit)}
                                    type='submit'
                                    className='emailBtn savePwd'
                                >
                                    Save password
                                </Button>
                            </div>
                        </Grid> */}
                    </div>
                    
                )}
                <MyEirRegisterFooter button1Text='Confirm' button1Enabled={confirmBtn? true : false} 
                handleButton1Click={handleNextClick} button2Text='Cancel' handleButton2Click={handleCancel}/>

                {/* {confirmPasswordMsg && (
                    <Fragment>
                        <h1 style={{ marginTop: '30px' }}>Good news!</h1>
                        <p className='notification' style={{ textAlign: 'center' }}>
                            Password updated successfully.
                        </p>

                        <div className='forRegform'>
                            <a className='link' href='/prepay/login'>
                                <span className='arrow-right'></span>
                                Back to log in
                            </a>
                        </div>
                    </Fragment>
                )} */}
                <MyEirAlreadyRegistered
                            toggle={toggle2}
                            isOpen={isOpen2}
                            heading={'Reset password'}
                            headingMobile={'Reset password'}
                            newsContentText={'Good news!'}
                            verifiedText={'Password updated successfully'}
                            verifiedTextMobile={'Password updated successfully'}
                            subtext={' '}
                            buttonText={'Close'}
                            footerText={''}
                        />
            </form>
            </div>
        </div>
       
    );
}

export default MyEirCreatePasswordForm;
