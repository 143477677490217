import { gql } from '@apollo/client';

const ENDPOINT = {
    GET_OFFER_ELIGIBILITY: gql`
        query getOfferElligibilty($token: String!, $subscriptionId: Int!) {
            getOfferEligibilityDetails(token: $token, subscriptionId: $subscriptionId) {
                eligibleAt
                inContract
                inGracePeriod
            }
        }
    `,
    GET_ADOBE_OFFERS: gql`
        query adobeOffers($accountId: String!, $maxCount: Int, $token: String!) {
            getAdobeOffers(accountId: $accountId, maxCount: $maxCount, token: $token) {
                availableOffers {
                    propositionId
                    offerId
                    offerSpaceId
                    weight
                    rank
                    tariff_code
                    type
                    subscriptionID
                    price
                }
                recommendedOffers {
                    propositionId
                    offerId
                    offerSpaceId
                    weight
                    rank
                    tariff_code
                    type
                    price
                }
            }
        }
    `,
    CHANGE_OFFER_UPGRADE_ELIGIBILITY: gql`
        query changeOffer($subscriptionId: String!, $token: String!) {
            changeOfferUpgradeEligibility(subscriptionId: $subscriptionId, token: $token) {
                changeOffer
                contractRenewal
                earlyCeaseChargeList {
                    totalAmountVatIncluded
                }
            }
        }
    `,
    CREATE_CHANGE_OFFER: gql`
        mutation changeOffer(
            $channel: String!
            $subscriptionId: Int!
            $email: String
            $token: String!
        ) {
            createChangeOffer(
                channel: $channel
                subscriptionId: $subscriptionId
                email: $email
                token: $token
            ) {
                amount
                brand
                channel
                channelGroup
                contractEmailId
                contractFileUuid
                contractRenewal
                contractRenewalEndDate
                contractRenewalStartDate
                contractSentAt
                deliveryContactUuid
                eposReference
                paymentUuid
                prequalId
                status
                subscriptionId
                uuid
                valid
                waiveEarlyCeaseCharge {
                    approver
                    reason
                }
                paymentDetails {
                    paymentMethod {
                        paymentMethodType
                        prospectReference
                        cardholderName
                        expirationDate
                        partialDigits
                        providerReference
                        type
                    }
                    payment {
                        amount
                        authCode
                        correlationTransactionUuid
                        paymentReference
                    }
                    payer {
                        payerReference
                    }
                }
            }
        }
    `,
    GET_TARIFF: gql`
        query getTariff(
            $serviceGroupCode: String!
            $acquisitionType: String!
            $catalogTariffCode: [String]
        ) {
            getTariffs(
                serviceGroupCode: $serviceGroupCode
                acquisitionType: $acquisitionType
                catalogTariffCode: $catalogTariffCode
            ) {
                tariffs {
                    code
                    offerCode
                    commitmentDuration
                    acquisitionType
                    discountDuration
                    description
                    displayPrice
                    displayName
                    portIn
                    simOnly
                    defaultPrice
                    serviceGroupCode
                    attributes {
                        type
                        value
                        sortOrder
                        icon
                    }
                }
            }
        }
    `,
};

/**
 * @name getOfferElligibiltyApi - get if the subscription is eligible, in contract and in grace period
 * @param {GraphQL} Obj.graphql - appoloclient graphql constant
 * @param {Int} Obj.subscriptionId - subscription id of the subscription
 * @returns
 */

export const getOfferElligibiltyApi = async ({ graphql, subscriptionId }) => {
    try {
        const res = await graphql.query({
            query: ENDPOINT.GET_OFFER_ELIGIBILITY,
            variables: {
                token: sessionStorage.getItem('access_token'),
                subscriptionId: subscriptionId,
            },
        });
        if (res.getOfferEligibilityDetails) {
            return res.getOfferEligibilityDetails;
        } else {
            throw 'getOfferEligibilityDetails was not returned from API';
        }
    } catch (error) {
        throw new Error(error);
    }
};

/**
 * @name getAdobeOffersApi - get my services & recommended offers
 * @param {GraphQL} Obj.graphql - appoloclient graphql constant
 * @returns
 */

export const getAdobeOffersApi = async ({ graphql }) => {
    try {
        const res = await graphql.query({
            query: ENDPOINT.GET_ADOBE_OFFERS,
            variables: {
                token: sessionStorage.getItem('access_token'),
                accountId: sessionStorage.getItem('LoggedinUserAccountID'),
                // accountId: '10003486', // mock data needs to be updated once api gives response
                maxCount: 5,
            },
        });
        let adobeData = {
            data: {
                getAdobeOffers: {
                    availableOffers: [
                        {
                            propositionId: '40519401',
                            offerId: '313456310',
                            offerSpaceId: '313454028',
                            weight: '1',
                            rank: '2',
                            tariff_code: '100MB_BB_12_CR_10_ONLINE',
                            type: 'RECONTRACT',
                            subscriptionID: '154836',
                            price: '3499',
                            __typename: 'AvailableOffers',
                        },
                        {
                            propositionId: '59474380',
                            offerId: '346283360',
                            offerSpaceId: '313454028',
                            weight: '60',
                            rank: '1',
                            tariff_code: 'WIFI_MESH_6',
                            type: 'addon',
                            subscriptionID: '154836',
                            price: '0599',
                            __typename: 'AvailableOffers',
                        },
                    ],
                    recommendedOffers: [
                        {
                            propositionId: '56556101',
                            offerId: '329145349',
                            offerSpaceId: '313454028',
                            weight: '190',
                            rank: '1',
                            tariff_code: 'BP_EM_CONN_30D_5_CS_PORT',
                            type: 'Cross_Sell',
                            price: '999',
                            __typename: 'RecommendedOffers',
                        },
                    ],
                    __typename: 'AdobeOffers',
                },
            },
        };
        if (res.data) {
            return res.data;
        } else {
            throw 'getAdobeOffers was not returned from API';
        }
    } catch (error) {
        throw new Error(error);
    }
};

/**
 * @name getChangeOfferEligibiltyApi - to check whether user is eligible for change offer
 * @param {GraphQL} Obj.graphql - appoloclient graphql constant
 * @param {String} Obj.subscriptionId - subscription id of the subscription
 * @returns
 */

export const getChangeOfferEligibiltyApi = async ({ graphql, subscriptionId }) => {
    try {
        const res = await graphql.query({
            query: ENDPOINT.CHANGE_OFFER_UPGRADE_ELIGIBILITY,
            variables: {
                token: sessionStorage.getItem('access_token'),
                subscriptionId: subscriptionId,
            },
        });

        if (res.data) {
            return res.data;
        }
    } catch (error) {
        throw new Error(error);
    }
};

/**
 * @name getMyServicesOffersApi - A function to get myservices offers
 * @param {GraphQL} Obj.graphql - appoloclient graphql constant
 * @param {String} Obj.serviceGroupCode - SeriveGroup like Dualplay, TV.
 * @param {String } Obj.catalogTariffCode - Tariff Code we recieve from the getadobeOffers
 * @returns
 */
export const getMyServicesOffersApi = async ({ graphql, serviceGroupCode, catalogTariffCode }) => {
    try {
        const res = await graphql.query({
            query: ENDPOINT.GET_TARIFF,
            variables: {
                serviceGroupCode: serviceGroupCode,
                catalogTariffCode: catalogTariffCode,
                acquisitionType: 'CONTRACT_RENEWAL',
            },
        });
        if (res.data) {
            return res.data;
        } else {
            throw 'services offers was not returned from API';
        }
    } catch (error) {
        throw new Error(error);
    }
};

/**
 * @name createChaneOfferProspectApi - to create a change offer
 * @param {GraphQL} Obj.graphql - appoloclient graphql constant
 * @param {String} Obj.subscriptionId - subscription id of the subscription
 * @returns
 */

export const createChaneOfferProspectApi = async ({ graphql, subscriptionId }) => {
    try {
        const res = await graphql.mutate({
            mutation: ENDPOINT.CREATE_CHANGE_OFFER,
            variables: {
                token: sessionStorage.getItem('access_token'),
                // channel: 'MYEIR',
                channel: 'ESHOP',
                subscriptionId: subscriptionId,
                email: sessionStorage.getItem('LoggedinUser'),
            },
        });

        if (res.data) {
            return res.data;
        }
    } catch (error) {
        throw new Error(error);
    }
};
