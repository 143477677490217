import React, { useState, Fragment, useEffect } from 'react';
import { Grid, TextField, Button, Typography } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import errormesages from '../../components/my-eir-register/errormessages';
import { Controller, useForm, ErrorMessage } from 'react-hook-form';
import ENDPOINT from '../../components/graphql/GraphQLConstants';
import { useApolloClient } from '@apollo/client';
import { commonRegisterStyles } from '../css/myEirRegistrationStyles/commonRegisterStyles';
import { useEnterAcccountNumberStyle } from '../css/myEirRegistrationStyles/useEnterAcccountNumberStyle';
import { useVerificationCodeStyles } from '../css/useVerificationCodeStyles';
import MyEirRegisterHeader from '../MyEirPostpayRegistration/reusable/MyEirRegisterHeader';
import MyEirRegisterFooter from '../MyEirPostpayRegistration/reusable/MyEirRegisterFooter';
import { useResetPasswordFormStyles } from '../css/myEirRegistrationStyles/useResetPasswordFormStyles';
import { useResetEmailStyles } from '../css/myEirRegistrationStyles/useResetEmailStyles';
import MyEirEmailConfirmationModal from '../MyEirPostpayRegistration/MyEirEmailConfirmationModal';
import MyEirErrorHandle from '../../mobilecomponents/eir-porting/MyEirErrorHandle';
import { ACQUISITION } from '../../components/myeirconstant/myEirConstant';

function MyEirForgotPasswordForm(props) {
    const { validation } = errormesages;
    const [errMsg, setErrMsg] = useState(false);
    const [validEmail, setValidEmail] = useState(false);
    const graphql = useApolloClient();
    const [emailAddress, setEmailAddress] = useState('');
    const [apiError, setApiError] = useState('');
    const [isOpen1, setIsopen1] = useState(false);
    const [isOpen2, setIsopen2] = useState(false);
    const [isFailure, setIsFailure] = useState(false);
    const [isDisable, setIsDisable] = useState(false);
    const [isAcquisition, setIsAcquisition] = useState(false);

    const handleNextClick = () => {
        // console.log('clicked flow1');
        // console.log(props.test);
        props.handleNextFlow();
        // console.log('clicked flow2');
    };
    const toggle1 = () => {
        setIsopen1(!isOpen1);
    };

    const toggle2 = () => {
        setIsopen2(!isOpen2);
    };
    const { register, control, errors, handleSubmit, setValue } = useForm({
        mode: 'onChange',
    });

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const fromAcquisition = searchParams.get('isAcquisition');
        if (fromAcquisition === 'true') {
            sessionStorage.setItem('fromAcquisition', fromAcquisition);

            // Replace the current history state with the new URL
            window.history.replaceState(null, '', window.location.pathname);
            setIsAcquisition(sessionStorage.getItem('fromAcquisition'));
        }
    }, []);

    useEffect(() => {
        const emailPattern = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;

        if (emailAddress == '') {
            setErrMsg(false);
        } else if (emailPattern.test(emailAddress)) {
            setValidEmail(true);
            setErrMsg(false);
        } else {
            setErrMsg(true);
            setValidEmail(false);
        }
    }, [emailAddress]);

    const onSubmit = async data => {
        try {
            setIsDisable(true);
            const res = await graphql.mutate({
                mutation: ENDPOINT.RESET_PASSWORD,
                variables: {
                    emailAddress: data.EmailAddress,
                },
            });

            if (res.data.resetPassword) {
                setEmailAddress(data.EmailAddress);
                toggle2();
                setIsDisable(false);
            }
        } catch (err) {
            setIsFailure(!isFailure);
            setIsDisable(false);
            setApiError(err.message);
        }
    };
    const handleValueChange = event => {
        setApiError('');
        setEmailAddress(event.target.value);
        return event.target.value;
    };

    const commonStyle = commonRegisterStyles();
    const useStyle = useVerificationCodeStyles();
    const borderStyle = useEnterAcccountNumberStyle();
    const resetStyles = useResetEmailStyles();

    const handleBackClick = () => {
        window.location.href = '/';
    };

    const handleRedirectAcquisition = () => {
        const redirectUrl = `${ACQUISITION.url.REDIRECT_URL}/shop/broadband?enable=${true}`;
        window.location.href = redirectUrl;
    };

    return (
        <div className={commonStyle.registrationContainer}>
            <div className={commonStyle.cardContainer}>
                <MyEirRegisterHeader headerText='Reset password' registerScreen='1' />
                <p className={resetStyles.emailText}>
                    Please enter your email address to reset your password
                </p>

                <form onSubmit={handleSubmit(onSubmit)} className='createForm'>
                    <div className={`${resetStyles.bodyContainer} `}>
                        <Grid container spacing={2} className='register-email-body'>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                className='register-email-grid-email'
                            >
                                <div>
                                    <Controller
                                        as={
                                            <TextField
                                                className='register-grid-input-text-field'
                                                variant='outlined'
                                                color='primary'
                                                InputLabelProps={{
                                                    classes: {
                                                        root: 'text-field-input-label-props-root',
                                                        shrink:
                                                            'text-field-input-label-props-shrink' +
                                                            (errMsg
                                                                ? ' text-field-input-label-props-shrink-invalid'
                                                                : ''),
                                                        focused:
                                                            'text-field-input-label-props-focused' +
                                                            (errMsg
                                                                ? ' text-field-input-label-props-focused-invalid'
                                                                : ''),
                                                    },
                                                }}
                                                InputProps={{
                                                    classes: {
                                                        root:
                                                            'new-password-root ' +
                                                            (errMsg ? 'new-password-error' : ''),

                                                        focused: 'new-password-input-focused',
                                                        notchedOutline:
                                                            'new-password-input-notched',
                                                        input: 'new-password-input',
                                                    },
                                                }}
                                            />
                                        }
                                        // required
                                        control={control}
                                        fullWidth
                                        id='EmailAddress'
                                        name='EmailAddress'
                                        label='Email address'
                                        variant='outlined'
                                        size='small'
                                        placeholder='Email Address'
                                        value={emailAddress}
                                        defaultValue={emailAddress}
                                        onChange={([event]) => {
                                            handleValueChange(event);
                                            return event.target.value;
                                        }}
                                        rules={{
                                            required: true,
                                            pattern: /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/,
                                        }}
                                    />
                                </div>
                                {/* {errors.EmailAddress && errors.EmailAddress.type === 'required' && (
                                <span className='invalid-messages'>Please enter email address</span>
                            )} */}
                                {errors.EmailAddress && errors.EmailAddress.type === 'pattern' && (
                                    <Typography
                                        variant='inputLabel'
                                        className='invalid-email-format'
                                        style={{ textAlign: 'left', paddingLeft: '6px' }}
                                    >
                                        Please enter a valid email id
                                    </Typography>
                                )}
                                {/* <p className={resetStyles.emailApiError}>{apiError}</p> */}
                            </Grid>
                        </Grid>
                        {/* <p className={resetStyles.emailApiError}>{apiError}</p> */}
                    </div>
                    <MyEirRegisterFooter
                        button1Text='Reset password'
                        button1Enabled={validEmail && !isDisable ? true : false}
                        handleButton1Click={handleSubmit(onSubmit)}
                        button2Text='Back to log in'
                        handleButton2Click={
                            isAcquisition ? handleRedirectAcquisition : handleBackClick
                        }
                    />

                    <MyEirEmailConfirmationModal
                        handleNext1={props.handleNextFlow}
                        emailAddress={emailAddress}
                        toggle={toggle2}
                        isOpen={isOpen2}
                        heading={'Reset Password'}
                        subHeading={'We’ve just sent you an email.'}
                        text={
                            'Please check your email and follow the instructions to reset your password'
                        }
                        flag='security'
                    />

                    {isFailure && <MyEirErrorHandle open={isFailure} setOpen={setIsFailure} />}
                </form>
            </div>
        </div>
    );
}

export default MyEirForgotPasswordForm;
