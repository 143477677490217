import { makeStyles } from '@material-ui/core/styles';

export const useAddonStyle = makeStyles(theme => ({
    paper: {
        marginTop: ' 0px !important',

        boxShadow: '0px 3px 25px #00000024',
        borderRadius: '9px 9px 9px 9px',
        opacity: '1',
        [theme.breakpoints.up(1000 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(6),
            paddingBottom: theme.spacing(3),
        },
        '@media  (max-width: 767px)': {
            paddingBottom: '16px',
        },
    },

    button: {
        outline: 'none',
    },

    Addons: {
        background: '#f4f5f9 0% 0% no-repeat',
        position: 'relative',
        '& .regform': {
            width: '92%',
            margin: 'auto',
            padding: '3% 5%',
            '@media (min-width: 959.95px) and (max-width: 1024px)': {
                width: '100%',
                padding: '1% 1%',
            },
            '@media  (max-width: 959.95px)': {
                width: '100%',
                padding: '1% 5%',
            },
            '& .formLogoSection ,& .loginContainer': {
                display: 'none',
            },
            '& .regcontainer': {
                paddingTop: theme.spacing(4),
            },
            '& .profileTitle': {
                '@media  (max-width: 1024px)': {
                    fontSize: '35px !important',
                    marginTop: '8px !important',
                },
            },
        },
        '& .subText': {
            textAlign: 'center',
            width: '70%',
            margin: 'auto auto 35px',
            '@media  (max-width: 767px)': {
                width: '100%',
            },
        },

        '& h2': {
            fontSize: '30px !important',
            fontFamily: 'eir-medium',
            color: '#892BA0',
            textAlign: 'left',
            marginTop: '1rem',
            marginBottom: '1rem',
            '@media  (max-width: 767px)': {
                fontSize: '28px !important',
            },
        },
        '& h3': {
            fontSize: '30px !important',
            fontFamily: 'eir-bold',
            color: '#892BA0',
            textAlign: 'center',
        },
        '& p': {
            textAlign: 'left',
            color: '#323435',
            fontFamily: 'gt-walsheim-regular',
            fontSize: '16px',
            width: '90%',
            '@media  (max-width: 767px)': {
                textAlign: 'justify',
                width: '100%',
            },
        },

        '& .paddingTop': {
            paddingTop: '1rem',
            marginBottom: '3rem',
        },

        '& .noPadding': {
            padding: ' 8px 0px !important',
            '@media  (max-width: 1024px)': {
                padding: '8px !important',
            },
        },
        '& .profileMargin': {
            '@media  (max-width: 1024px)': {
                marginBottom: '10px',
            },
        },
        '& h1': {
            fontFamily: 'eir-medium',
            color: '#470A68',
            marginBottom: '20px',
            '@media  (max-width: 1024px)': {
                fontSize: '30px !important',
            },
        },
        '& button': {
            outline: 'none',
            '&:focus,&:hover': {
                outline: 'none',
            },
        },

        '& .editBtn': {
            marginTop: '1rem',
            background: '#FFFFFF 0% 0% no-repeat ',
            border: '2px solid #DB12A9',
            color: '#470A68',
            fontSize: '16px ',
            padding: theme.spacing(1, 4),
            fontFamily: 'gt-walsheim-medium !important',
            borderRadius: '29px',
            float: 'right',
            textTransform: 'inherit',
            '&:hover': {
                border: '2px solid #892BA0',
                background: '#892BA0 0% 0% no-repeat ',
                color: '#FFFFFF',
            },
            '@media  (max-width: 767px)': {
                float: 'none',
                marginTop: '0px',
                marginBottom: '0.5rem',
            },
            '@media  (max-width: 1025px)': {
                fontSize: '14px ',
                padding: theme.spacing(1, 4),
            },
        },
        '& .marginLeft': {
            marginLeft: '30px',
            '@media  (max-width: 767px)': {
                margin: '0px',
            },
        },
        '& .marginRight': {
            marginRight: '20px',
            '@media  (max-width: 767px)': {
                margin: '0px',
            },
        },
        '& .addonImg': {
            width: '40%',
            '@media  (max-width: 767px)': {
                width: '50%',
            },
        },
        '& .iconLayout': {
            '& img': {
                height: '40px',
                width: '40px',
                marginRight: '10px',
                marginBottom: '10px',
                padding: '7px',
                background: '#DB12A9',
                borderRadius: '100%',
                '@media  (max-width: 1024px)': {
                    height: '35px',
                    width: '35px',
                },
            },
        },
        '& .boxLayout': {
            textAlign: 'center',
            padding: '24px',

            '@media  (max-width: 767px)': {
                margin: '0px',
                padding: '20px 10px',
            },
            '& p': {
                fontFamily: 'gt-walsheim-regular',
                fontSize: '16px',
                textAlign: 'center',
                width: '100%',
                '@media  (max-width: 767px)': {
                    fontSize: '16px',
                },
            },
            '& .freeText': {
                fontFamily: 'gt-walsheim-medium !important',
                fontSize: '18px',
                '@media  (max-width: 767px)': {
                    fontSize: '16px',
                },
            },
            '& span': {
                color: '#892BA0',
                fontSize: '18px',
                fontFamily: 'gt-walsheim-bold',
                '@media  (max-width: 767px)': {
                    fontSize: '16px',
                },
            },
        },
        '& .borderBox': { borderTop: '3px solid #00000024', marginBottom: '1.5rem' },
    },
}));
