import ENDPOINT from '../../mobilecomponents/graphql/GraphQLConstants';
import END_POINT from '../../components/graphql/GraphQLConstants';

// verify user with account
export const postVerifyIonAccount = async ({ graphql, accountNumber }) => {
    try {
        const {
            data: { postVerifyIonAccount },
        } = await graphql.query({
            query: ENDPOINT.GET_POST_VERIFY_ION,
            variables: {
                mobileNumber: sessionStorage.getItem('reg_primary_number'),
                account: accountNumber,
            },
        });
        return { data: postVerifyIonAccount };
    } catch (error) {
        console.error(error);
        throw new Error(error);
    }
};

export const sendEmailVerification = async ({ graphql }) => {
    try {
        const respEmail = await graphql.mutate({
            mutation: ENDPOINT.SEND_VERIFICATION_EMAIL,
            variables: {
                email: sessionStorage.getItem('reg_email'),
            },
        });
        // console.log('response_email>>', respEmail.data);
        return respEmail.data.sendVerificationEmail;
    } catch (err) {
        console.error(err);
        return 'Failure';
    }
};

// verify user by otp
export const getUsernameFromOTP = async ({ graphql, code }) => {
    try {
        const { data } = await graphql.query({
            query: ENDPOINT.GET_USERNAME_FROM_OTP,
            variables: {
                billType: sessionStorage.getItem('reg_bill_type'),
                mobileNumber: sessionStorage.getItem('reg_primary_number'),
                otpuuid: sessionStorage.getItem('reg_uuid'),
                otpcode: code,
            },
        });
        return data.verifyOTP;
    } catch (error) {
        console.error(error);
        throw new Error(error);
    }
};

export const resendOTP = async ({ graphql }) => {
    try {
        const { data } = await graphql.mutate({
            mutation: END_POINT.NUMBER_VALIDATION,
            variables: {
                registerWith: 'NUMBER' || 'EMAIL',
                registerValue: sessionStorage.getItem('reg_phone_number'),
                recaptcha: '12345678', // NEED TO SEND PROPER RECAPTCHA HERE
            },
        });
        return data.validateUser;
    } catch (error) {
        console.error(error);
        throw new Error(error);
    }
};

export const clearSessions = () => {
    // step 1
    sessionStorage.removeItem('reg_phone_number');
    sessionStorage.removeItem('reg_primary_number');
    sessionStorage.removeItem('reg_uuid');
    sessionStorage.removeItem('reg_bill_type');
    // step 2 or step 3
    sessionStorage.removeItem('reg_email');
    sessionStorage.removeItem('reg_email_status');
    // step 3
    // step prepay-form
};
