import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';
import { RetryLink } from 'apollo-link-retry';
const httpLink = new HttpLink({
    //uri: 'https://online-api-gateway.dev.webmail.comhar.local/graphql',
    // uri: 'http://mytt01.eir.ie/graphql',
    // uri: `https://myeir-test02.eir.ie/graphql/`,
    // uri: 'https://myeir-preprod.eir.ie/graphql/',
    uri: `${window.location.origin}/graphql/`,
});
//Create a custom RetryLink function that retries until success
const retryLink = new RetryLink({
    delay: {
        initial: 1000, // Initial delay in milliseconds between retries
        max: 5000, // Maximum delay in milliseconds between retries
        jitter: true, //Adds jitter to the delays
    },
    attempts: {
        max: 5, //Retry indefinitely untill successs
        retryIf: (error, _operation) => !!error, // Retry on any network error
    },
});
const link = retryLink.concat(httpLink); // Concarenate with HTTP link
const client = new ApolloClient({
    link,
    cache: new InMemoryCache(),
    defaultOptions: {
        query: {
            fetchPolicy: 'network-only',
        },
    },
});

export default client;
