import { makeStyles } from '@material-ui/core/styles';

export const useToggleBtnStyle = makeStyles(theme => ({
    toggleSection: {
        margin: '24px 0px',

        '& .toggleBtn': {
            position: 'relative',
            background: '#FFFFFF 0% 0% no-repeat ',
            border: '2px solid #DB12A9',
            borderRadius: '29px',
            color: '#470A68',
            fontFamily: 'eir-medium',
            outline: 'none',
            fontSize: '20px',
            padding: theme.spacing(1, 5),
            '@media(max-width:282px)': {
                padding: theme.spacing(1, 3),
                fontSize: '16px',
            },
            '@media(min-width:283px) and (max-width: 767px)': {
                fontSize: '16px',
                padding: theme.spacing(1, 4),
            },
        },
        '& .regbtn': {
            padding: theme.spacing(1, 4),
            marginLeft: theme.spacing(8),
            '@media(max-width:281px)': {
                padding: '8px 16px !important',
                fontSize: '14px',
            },
            '@media(min-width:282px) and (max-width: 412px)': {
                padding: '8px 24px !important',
            },

            '@media  (max-width: 767px)': {
                marginLeft: theme.spacing(3),
            },
        },
        '& .alignbtn': {
            display: 'inline-block',
        },
        '& .ActiveBtn': {
            background: '#892BA0',
            color: '#fff',

            border: '2px solid #892BA0 !important',
        },
        '& .loginBtn': {
            background: '#FFFFFF 0% 0% no-repeat ',
            border: '2px solid #DB12A9',
            borderRadius: '29px',
            color: '#005670',
            fontFamily: 'eir-medium !important',
            outline: 'none',
            fontSize: '20px !important',
            textTransform: 'initial',
            padding: theme.spacing(1, 7),
            '@media  (max-width: 767px)': {
                fontSize: '14px !important',
                padding: theme.spacing(1, 4),
            },
        },

        '& .arrow-down': {
            width: '0',
            height: '0',
            position: 'absolute',
            marginTop: theme.spacing(6),
            marginLeft: '-5.2%',
            zIndex: '2',
            borderLeft: '12px solid transparent',
            borderRight: '12px solid transparent',
            borderTop: '12px solid #892BA0',
            borderBottom: ' 12px solid transparent',
            '@media(max-width:767px)': {
                borderLeft: '8px solid transparent',
                borderRight: '8px solid transparent',
                borderTop: '8px solid #892BA0',
                borderBottom: ' 8px solid transparent',
                marginTop: theme.spacing(5.3),
            },
            '@media(min-width:321px) and (max-width: 397px)': {
                marginLeft: '-17.2%',
            },
            '@media(max-width:282px)': {
                marginLeft: '-19.2%',
            },
            '@media(min-width:282px) and (max-width: 320px)': {
                marginLeft: '-19.2%',
            },
            '@media(min-width:398px) and (max-width: 440px)': {
                marginLeft: '-15.2%',
            },
            '@media(min-width:441px) and (max-width: 480px)': {
                marginLeft: '-14.2%',
            },
            '@media(min-width:481px) and (max-width: 510px)': {
                marginLeft: '-13.2%',
            },
            '@media(min-width:510px) and (max-width: 556px)': {
                marginLeft: '-12.2%',
            },
            '@media(min-width:556px) and (max-width: 599px)': {
                marginLeft: '-11.2%',
            },
            '@media(min-width:600px) and (max-width: 669px)': {
                marginLeft: '-10.2%',
            },
            '@media(min-width:670px) and (max-width: 728px)': {
                marginLeft: '-9.2%',
            },
            '@media(min-width:729px) and (max-width: 822px)': {
                marginLeft: '-10.2%',
            },
            '@media(min-width:822px) and (max-width: 1024px)': {
                marginLeft: '-8%',
            },
            '@media(min-width:1025px) and (max-width: 1125px)': {
                marginLeft: '-7.2%',
            },
            '@media(min-width:1125px) and (max-width: 1255px)': {
                marginLeft: '-7.2%',
            },
            '@media(min-width:1300px) and (max-width: 1367px)': {
                marginLeft: '-6.1%',
            },
        },
    },
}));
