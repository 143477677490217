import React, { useEffect, useState } from 'react';
import { Grid, Button, Paper } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DoneIcon from '@material-ui/icons/Done';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import CancelIcon from '@material-ui/icons/Cancel';
function MyEirPlans(props) {
    const { addons } = props;
    const [activeDate, setActiveDate] = useState(0);
    useEffect(() => {
        var formatter = new Intl.DateTimeFormat('en-us', {
            year: 'numeric',
            month: 'long',
            day: '2-digit',
        });
        if (props.planDetails) {
            var formatted = formatter.formatToParts(
                new Date(props.planDetails.customerOfferDTO.activationDate)
            );
            var day = formatted[2].value;
            setActiveDate(day + suffixChecker(day) + formatted[0].value);
        }
    }, [props]);

    const suffixChecker = day => {
        if (day > 3 && day < 21) return 'th ';
        switch (day % 10) {
            case 1:
                return 'st ';
            case 2:
                return 'nd ';
            case 3:
                return 'rd ';
            default:
                return 'th ';
        }
    };
    return (
        <div className='myEirPlans height'>
            <h1>
                <span className='iconLayout'>
                    {' '}
                    <img src='/opencms/export/system/modules/my.portal.opencms/myeir_ion/images/my-eir-plan.png'></img>
                </span>
                My eir plan
            </h1>
            {props.subscription ? (
                <div>
                    <Paper className='paper'>
                        <Grid container spacing={2}>
                            <Grid
                                item
                                xs={
                                    //props.noaddons
                                    props.planDetails != null &&
                                    props.planDetails.customerOfferDTO.offerStatus != 'ACTIVE'
                                        ? 5
                                        : 6
                                }
                                sm={
                                    //props.noaddons
                                    props.planDetails != null &&
                                    props.planDetails.customerOfferDTO.offerStatus != 'ACTIVE'
                                        ? 6
                                        : 6
                                }
                                md={
                                    //props.noaddons
                                    props.planDetails != null &&
                                    props.planDetails.customerOfferDTO.offerStatus != 'ACTIVE'
                                        ? 5
                                        : 7
                                }
                            >
                                <p className='purpleHeading'>My offers</p>
                            </Grid>
                            <Grid
                                item
                                xs={
                                    //props.noaddons
                                    props.planDetails != null &&
                                    props.planDetails.customerOfferDTO.offerStatus != 'ACTIVE'
                                        ? 7
                                        : 6
                                }
                                sm={
                                    // props.noaddons
                                    props.planDetails != null &&
                                    props.planDetails.customerOfferDTO.offerStatus != 'ACTIVE'
                                        ? 6
                                        : 6
                                }
                                md={
                                    // props.noaddons
                                    props.planDetails != null &&
                                    props.planDetails.customerOfferDTO.offerStatus != 'ACTIVE'
                                        ? 7
                                        : 5
                                }
                            >
                               {props.planDetails != null &&
                                    props.planDetails.customerOfferDTO.status != 'ACTIVE' && (
                                        <p className='greenHeading'>
                                            Offer not active
                                            <CancelIcon
                                                style={{ marginLeft: '6px', marginBottom: '10px' }}
                                            />
                                        </p>
                                    )}
                                 {props.planDetails != null &&
                                    props.planDetails.customerOfferDTO.status == 'ACTIVE' && (
                                        <p className='greenHeading'>
                                            Offer active
                                            <CheckCircleIcon
                                                style={{ marginLeft: '6px', marginBottom: '10px' }}
                                            />
                                        </p>
                                    )}
                            </Grid>
                        </Grid>
                        {props.planDetails == null && (
                            <p>
                                <Grid container justify='center'>
                                    <Grid list md={12} xs={12} sm={12}>
                                        <span className='errorText'>
                                            There was an error loading your details
                                        </span>
                                    </Grid>
                                    <Grid list md={12} xs={12} sm={12}>
                                        <a
                                            className='tryAgain'
                                            onClick={() => window.location.reload()}
                                        >
                                            {' '}
                                            Click here to try again
                                        </a>
                                    </Grid>
                                </Grid>
                            </p>
                        )}
                       

                        {props.planDetails != null &&
                            (props.planDetails ? (
                                props.planDetails.customerOfferDTO ? (
                                    props.planDetails.customerOfferDTO.name ? (
                                        <p>
                                            <DoneIcon className='planIcon' />
                                            { props.planDetails.customerOfferDTO.name}
                                        </p>
                                    ) : (
                                        <p>
                                            <ClearOutlinedIcon className='planIcon' />
                                            No active offers
                                        </p>
                                    )
                                ) : (
                                    <p>
                                        <ClearOutlinedIcon className='planIcon' />
                                        No active offers
                                    </p>
                                )
                            ) : (
                                <p>
                                    <ClearOutlinedIcon className='planIcon' />
                                    No active offers
                                </p>
                            ))}
                        {props.planDetails != null && (
                            <p>
                                <DateRangeOutlinedIcon className='planIcon dateIcon' />
                                Plan activation date: {activeDate ? activeDate : ''}
                            </p>
                        )}
                    </Paper>
                    <Paper className='paper'>
                        <div>
                            <p className='purpleHeading'>My add-ons</p>
                        </div>
                        {/* {(props.noaddons || (!props.noaddons && props.addons.length === 0)) && (
                            <p>
                                <ClearOutlinedIcon className='planIcon' />
                                No active add-ons
                            </p>
                        )} */}
                        {props.addons == null ? (
                            <p>
                                <Grid container justify='center'>
                                    <Grid list md={12} xs={12} sm={12}>
                                        <span className='errorText'>
                                            There was an error loading your details
                                        </span>
                                    </Grid>
                                    <Grid list md={12} xs={12} sm={12}>
                                        <a
                                            className='tryAgain'
                                            onClick={() => window.location.reload()}
                                        >
                                            {' '}
                                            Click here to try again
                                        </a>
                                    </Grid>
                                </Grid>
                            </p>
                        ) : //(
                        //     (props.noaddons || (!props.noaddons && props.addons.length === 0)) && (
                        //         <p>
                        //             <ClearOutlinedIcon className='planIcon' />
                        //             No active add-ons
                        //         </p>
                        //     )
                        // )

                        addons.filter(ele => !ele.description.includes('Simply')).length >= 1 ? (
                            <>
                                {addons
                                    .filter(ele => !ele.description.includes('Simply'))
                                    .map((item, index) => (
                                        <p key={index}>
                                            <DoneIcon className='planIcon' />
                                            {item['description']}
                                        </p>
                                    ))}
                            </>
                        ) : (
                            <p>
                                <ClearOutlinedIcon className='planIcon' />
                                No active addons
                            </p>
                        )}
                        {!props.noaddons &&
                            props.addons.map(addon => (
                                <p>
                                    <DoneIcon className='planIcon' />
                                    {addon.description}
                                </p>
                            ))}
                    </Paper>
                </div>
            ) : (
                <Grid container justify='center' className='errorTextPadding'>
                    <Grid list md={12} xs={12} sm={12}>
                        <span className='errorText'>There was an error loading your details</span>
                    </Grid>
                    <Grid list md={12} xs={12} sm={12}>
                        <a className='tryAgain' onClick={() => window.location.reload()}>
                            {' '}
                            Click here to try again
                        </a>
                    </Grid>
                </Grid>
            )}

            <Grid container spacing={2}>
                <Grid item xs={5} sm={6} md={6} className='offerLeft'>
                    <div>
                        <Button className='faqBtn topupbtn offerBtn' style={{ float: 'right' }}>
                            <a href='https://www.eir.ie/mobile/prepay/activate/' target='_blank'>
                                View offers
                            </a>
                        </Button>
                    </div>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                    <div>
                        <Button className='faqBtn topupbtn offerBtn' style={{ float: 'left' }}>
                            <a href='https://www.eir.ie/mobile/prepay/add-ons/' target='_blank'>
                                View add-ons
                            </a>
                        </Button>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

export default MyEirPlans;
