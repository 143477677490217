import { makeStyles } from '@material-ui/core/styles';
export const useBillDetails = makeStyles(theme => ({
    WrapBillDetails: {
        backgroundColor: theme.palette.primary.error,
    },
    billDetailsmodal: {
        background: theme.palette.primary.gray550,
        backdropFilter: 'blur(30px)',
    },

    billDetailsModal: {
        // background: theme.palette.primary.gray550,
        // backdropFilter: 'blur(30px)',
        marginLeft: 'auto',
        marginRight: 'auto',
        //marginTop: '100px',
        paddingTop: '100px',
        maxWidth: 'unset',
        height: '100%',
        marginTop: '0',
        marginBottom: '0',

        '& .modal-content': {
            margin: '0 auto',
            width: '760px',
            border: 'none',
            [theme.breakpoints.down('xs')]: {
                width: '100%',
                height: '100%',
            },
            '@media(min-width:600px) and (max-width:1019px)': {
                width: '100%',
            },
        },

        [theme.breakpoints.down('xs')]: {
            paddingLeft: '8px',
            paddingRight: '8px',
            paddingTop: '8px',
            //margin: 'unset'
        },
        '@media(min-width:600px) and (max-width:1019px)': {
            paddingLeft: '99px',
            paddingRight: '99px',
            //paddingTop: '8px',
            //margin: 'unset'
        },

        '& .hide': {
            visibility: 'hidden',
        },
    },

    wrapperstyle: {
        // position: 'absolute',
        // top: '50%',
        // left: '50%',
        // transform: 'translate(-50%, -50%)',
        backgroundColor: theme.palette.primary.white,
        boxShadow: 24,
        width: '760px',
        borderRadius: '10px',
        padding: 'unset',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            //padding: '8px 8px 0 8px'
        },
        '@media(min-width:600px) and (max-width:1019px)': {
            width: '100%',
        },
    },
    PDFIcon: {
        height: '29px',
        cursor: 'pointer',
        [theme.breakpoints.down('xs')]: {
            height: '25px',
        },
    },

    BackIcon: {
        paddingLeft: '32px',
        //paddingBottom: '24px',
        paddingTop: '28px',
        color: theme.palette.primary.sunset,
        width: '24px',
        height: '24px',
        boxSizing: 'unset',
        cursor: 'pointer',
        [theme.breakpoints.down('xs')]: {
            width: '20px',
            height: '20px',
            paddingLeft: '15.33px',
            paddingTop: '20.33px',
        },
        '@media(min-width:600px) and (max-width:1019px)': {
            paddingLeft: '15.33px',
            paddingTop: '20.33px',
        },
    },

    modalTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: `1px solid ${theme.palette.primary.borderColor}`,
        paddingBottom: '14px',
        [theme.breakpoints.down('xs')]: {
            paddingBottom: '13px',
            paddingLeft: '8px',
            paddingRight: '8px',
        },
        '@media(min-width:600px) and (max-width:1019px)': {
            paddingBottom: '13px',
            paddingLeft: '8px',
            paddingRight: '8px',
        },
    },
    billDetails: {
        fontSize: theme.typography.h4.fontSize,
        // fontWeight: '700',
        lineHeight: '26px',
        fontFamily: theme.typography.body3Medium.fontFamily,
        color: theme.palette.primary.heather,
        //paddingLeft: '32px',
        [theme.breakpoints.down('xs')]: {
            fontSize: theme.typography.h4.fontSize,
            lineHeight: '26px',
        },
        '@media(min-width:600px) and (max-width:1019px)': {},
    },

    billAttribute: {
        paddingLeft: '32px',
        paddingRight: '32.35px',
        paddingTop: '24px',
        [theme.breakpoints.down('xs')]: {
            //paddingLeft: '24px',
            //paddingRight: '24px',
            paddingTop: '17.33px',
            paddingLeft: '16px',
            paddingRight: '16px',
        },
        '@media(min-width:600px) and (max-width:1019px)': {
            //paddingLeft: '24px',
            //paddingRight: '24px',
            paddingTop: '17.33px',
            paddingLeft: '16px',
            paddingRight: '16px',
        },
    },

    modalDescription: {
        padding: '11px 57px 56px 56px',
        [theme.breakpoints.down('xs')]: {
            padding: '6px 16px 60px 16px',
        },
        '@media(min-width:600px) and (max-width:1019px)': {
            padding: '6px 16px 60px 16px',
        },
    },

    value: {
        fontFamily: theme.typography.body2.fontFamily,
        fontWeight: 'normal',
        color: theme.palette.primary.heather,
        fontStyle: 'normal',
        textAlign: 'right',
        padding: '9px 0 0px 0',
        [theme.breakpoints.down('xs')]: {
            padding: '7px 0 0px 0',
        },
        '@media(min-width:600px) and (max-width:1019px)': {
            padding: '7px 0 0px 0',
        },
    },

    valueBold: {
        fontFamily: theme.typography.h5.fontFamily,
        // fontWeight: 'bold',
        color: theme.palette.primary.heather,
        fontStyle: 'normal',
        textAlign: 'right',
        padding: '9px 0 0px 0',
        [theme.breakpoints.down('xs')]: {
            padding: '7px 0 0px 0',
        },
        '@media(min-width:600px) and (max-width:1019px)': {
            padding: '7px 0 0px 0',
        },
    },

    title: {
        padding: '9px 0 0px 0',
        fontSize: theme.typography.body1.fontSize,
        lineHeight: '25px',
        fontFamily: theme.typography.body3Medium.fontFamily,
        fontWeight: '500',
        color: theme.palette.primary.heather,
        fontStyle: 'normal',
        [theme.breakpoints.down('xs')]: {
            padding: '7px 0 0px 0',
        },
        '@media(min-width:600px) and (max-width:1019px)': {
            padding: '7px 0 0px 0',
        },
    },
    titleRed: {
        padding: '9px 0 0px 0',
        fontSize: theme.typography.subtitle2.fontSize,
        lineHeight: '16px',
        fontFamily: theme.typography.body3Medium.fontFamily,
        fontWeight: 'normal',
        color: theme.palette.primary.error,
        fontStyle: 'normal',
        [theme.breakpoints.down('xs')]: {
            padding: '7px 0 0px 0',
            fontSize: theme.typography.inputLabel.fontSize,
        },
        '@media(min-width:600px) and (max-width:1019px)': {
            padding: '7px 0 0px 0',
        },
    },
    paymentButton: {
        backgroundColor: '#D111c9',
        boxShadow: `0px 3px 1px -2px ${theme.palette.primary.boxShadow2Dark}, 0px 2px 2px ${theme.palette.primary.boxShadow2Dark}, 0px 1px 5px ${theme.palette.primary.boxShadow2Dark}`,
        borderRadius: '4100px',
        padding: '8px 24px',
        fontFamily: theme.typography.body3Medium.fontFamily,
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: theme.typography.body1.fontSize,
        lineHeight: '24px',
        color: theme.palette.primary.white,
        textTransform: 'unset',
        '&:hover': {
            outline: 'none',
            backgroundColor: theme.palette.primary.sunset,
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            // padding: '8px 84.5px',
            flex: 'none',
            fontFamily: theme.typography.body3Medium.fontFamily,
        },
        '@media(min-width:600px) and (max-width:1019px)': {
            padding: '8px 84.5px',
            flex: 'none',
            width: '100%',
        },
    },

    makePayment: {
        //borderBottom: '1px solid #E0E0E0',
        paddingTop: '21px',
        //paddingBottom: '29px',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        display: 'flex',
        [theme.breakpoints.down('xs')]: {
            paddingTop: '19px',
            //paddingBottom: '21px',
            paddingLeft: '8px',
            paddingRight: '8px',
        },
        '@media(min-width:600px) and (max-width:1019px)': {
            paddingLeft: '8px',
            paddingRight: '8px',
        },
    },

    titleBold: {
        fontSize: theme.typography.body3Medium.fontSize,
        lineHeight: '20px',
        fontFamily: theme.typography.body3Medium.fontFamily,
        fontWeight: 'bold',
        color: theme.palette.primary.heather,
        fontStyle: 'normal',
        //paddingLeft: '64px',
        //paddingTop: '20px',
        paddingBottom: '16px',
        [theme.breakpoints.down('xs')]: {
            fontSize: theme.typography.body1.fontSize,
            paddingBottom: '12px',
        },
        '@media(min-width:600px) and (max-width:1019px)': {},
    },

    valueAttribute: {
        fontSize: theme.typography.body3Medium.fontSize,
        lineHeight: '18px',
        fontFamily: theme.typography.body3Medium.fontFamily,
        // fontWeight: '600',
        color: theme.palette.primary.heather,
        fontStyle: 'normal',
        //paddingRight: '64px',
        textAlign: 'right',
        //paddingTop: '20px',
        [theme.breakpoints.down('xs')]: {
            fontSize: theme.typography.body1.fontSize,
        },
        '@media(min-width:600px) and (max-width:1019px)': {},
    },

    subAttribute: {
        fontFamily: theme.typography.body3Medium.fontFamily,
        fontWeight: '400',
        color: theme.palette.primary.heather,
        fontStyle: 'normal',
        //paddingLeft: '64px',
        fontSize: theme.typography.subtitle2.fontSize,
        lineHeight: '16px',
        //paddingBottom: '22px',
        //borderBottom: '1px solid #E0E0E0',
        [theme.breakpoints.down('xs')]: {
            lineHeight: '17px',
        },
        '@media(min-width:600px) and (max-width:1019px)': {},
    },

    totalBold: {
        fontSize: theme.typography.h5.fontSize,
        lineHeight: '26px',
        fontFamily: theme.typography.h5.fontFamily,
        fontWeight: 'bold',
        color: theme.palette.primary.heather,
        fontStyle: 'normal',
        //paddingLeft: '64px',
        //paddingTop: '18px',
        //paddingBottom: '82px',
        [theme.breakpoints.down('xs')]: {
            //paddingTop: '40px'
        },
    },

    totalValue: {
        fontSize: theme.typography.h5.fontSize,
        lineHeight: '31px',
        fontFamily: theme.typography.body3Medium.fontFamily,
        fontWeight: '500',
        color: theme.palette.primary.heather,
        fontStyle: 'normal',
        //paddingRight: '64px',
        textAlign: 'right',
        //paddingTop: '18px',
        [theme.breakpoints.down('xs')]: {
            position: 'relative',
            top: '-2px',
        },
    },

    vatValue: {
        fontSize: theme.typography.inputLabel.fontSize,
        lineHeight: '18px',
        fontFamily: theme.typography.body3Medium.fontFamily,
        fontWeight: '500',
        color: theme.palette.primary.heather,
        fontStyle: 'normal',
        //paddingRight: '64px',
        textAlign: 'right',
        paddingTop: '1px',
    },

    descriptionAttribute: {
        borderBottom: `1px solid ${theme.palette.primary.borderColor}`,
        paddingTop: '16px',
        paddingBottom: '19px',
        paddingLeft: '8px',
        paddingRight: '8px',
        [theme.breakpoints.down('xs')]: {
            paddingTop: '21px',
            paddingBottom: '8px',
            paddingLeft: '8px',
            paddingRight: '8px',
        },
        '@media(min-width:600px) and (max-width:1019px)': {
            paddingTop: '21px',
            paddingBottom: '8px',
            paddingLeft: '8px',
            paddingRight: '8px',
        },
    },

    descriptionAttributeComplete: {
        borderTop: `1px solid ${theme.palette.primary.borderColor}`,
        borderBottom: `1px solid ${theme.palette.primary.borderColor}`,
        paddingTop: '20px',
        paddingBottom: '19px',
        paddingLeft: '8px',
        paddingRight: '8px',
        marginTop: '16px',
        [theme.breakpoints.down('xs')]: {
            marginTop: '6px',
            paddingTop: '15px',
            paddingBottom: '8px',
            paddingLeft: '8px',
            paddingRight: '8px',
        },
        '@media(min-width:600px) and (max-width:1019px)': {
            marginTop: '6px',
            paddingTop: '15px',
            paddingBottom: '8px',
            paddingLeft: '8px',
            paddingRight: '8px',
        },
    },
    descriptionAttributeUsage: {
        borderBottom: `1px solid ${theme.palette.primary.borderColor}`,
        paddingTop: '20px',
        paddingBottom: '19px',
        paddingLeft: '8px',
        paddingRight: '8px',
        [theme.breakpoints.down('xs')]: {
            paddingTop: '21px',
            paddingBottom: '8px',
            paddingLeft: '8px',
            paddingRight: '8px',
        },
        '@media(min-width:600px) and (max-width:1019px)': {
            paddingTop: '21px',
            paddingBottom: '8px',
            paddingLeft: '8px',
            paddingRight: '8px',
        },
    },
    topAttribute: {
        borderTop: `1px solid ${theme.palette.primary.borderColor}`,
        paddingTop: '18px',
        paddingLeft: '8px',
        paddingRight: '8px',
        //paddingBottom: '56px',
        [theme.breakpoints.down('xs')]: {
            paddingTop: '41px',
            paddingLeft: '8px',
            paddingRight: '8px',
        },
        '@media(min-width:600px) and (max-width:1019px)': {
            paddingTop: '41px',
            paddingLeft: '8px',
            paddingRight: '8px',
        },
    },
    topGrid: {
        paddingLeft: '8px',
        paddingRight: '8px',
        [theme.breakpoints.down('xs')]: {
            paddingLeft: '8px',
            paddingRight: '8px',
        },
        '@media(min-width:600px) and (max-width:1019px)': {
            paddingLeft: '8px',
            paddingRight: '8px',
        },
    },
}));
