import React, { Fragment } from 'react';
import { Grid, FormControlLabel, RadioGroup, Radio, FormLabel, Container } from '@material-ui/core';
import { DIRECTORY_PREFERENCES } from './constants';

export const MyEirDirectoryPreference = props => {
    const { directoryPreference, setDirectoryPreference } = props;

    const handleChange = event => {
        setDirectoryPreference(event.target.value);
    };

    return (
        <Fragment>
            <Container>
                <div className='accountAddress'>
                    <p className='marginTop'>National Directory Enquiries</p>
                    <Grid container spacing={1}>
                        <Grid item sm={12}>
                            <p className='formLabelColor'>
                                {' '}
                                Would you like your phone to be listed on directory enquiries?
                            </p>
                        </Grid>
                        <Grid item sm={12}>
                            <RadioGroup
                                name='directoryPreference'
                                value={directoryPreference}
                                onChange={handleChange}
                            >
                                <FormControlLabel
                                    value='EXDIRECTORY'
                                    className='formLabelColor'
                                    control={<Radio color='primary' />}
                                    label={DIRECTORY_PREFERENCES.EXDIRECTORY}
                                />
                                <FormControlLabel
                                    value='LISTED'
                                    className='formLabelColor'
                                    control={<Radio color='primary' />}
                                    label={DIRECTORY_PREFERENCES.LISTED}
                                />
                                <FormControlLabel
                                    value='UNLISTED'
                                    className='formLabelColor'
                                    control={<Radio color='primary' />}
                                    label={DIRECTORY_PREFERENCES.UNLISTED}
                                />
                            </RadioGroup>
                        </Grid>
                    </Grid>
                </div>
            </Container>
        </Fragment>
    );
};
