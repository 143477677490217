import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: '1076px',
        paddingBottom: '40px',

        '@media (min-width: 1020px) and (max-width:1199px)': {
            maxWidth: '1076px',
            margin: 'auto',
            paddingLeft: '30px',
            paddingRight: '30px',
        },

        '@media (min-width: 600px) and (max-width:1019px)': {
            maxWidth: '694px',
            paddingLeft: '30px',
            paddingRight: '30px',
        },

        [theme.breakpoints.down('sm')]: {
            width: '100%',
            padding: '0px 24px',
            paddingBottom: '30px',
        },
    },
    redesignLink: {
        fontSize: theme.typography.subtitle2.fontSize,
        textAlign: 'left',
        fontFamily: theme.typography.body2.fontFamily,
        fontWeight: 400,
        display: 'flex',
        alignItems: 'center',
    },

    heading: {
        color: theme.palette.primary.heather,
        fontFamily: theme.typography.h3.fontFamily,
        fontStyle: 'normal',
        // fontWeight: '700',
        fontSize: theme.typography.h3.fontSize,
        lineHeight: theme.typography.h3.lineHeight,
        display: 'flex',
        alignItems: 'center',
        letterSpacing: '0.249px',
        marginBottom: '32px',
        paddingLeft: '5px',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
            marginBottom: '33px',
        },
        '@media (min-width: 600px) and (max-width: 1019px)': {
            justifyContent: 'flex-start',
        },
    },
    heading1: {
        fontFamily: theme.typography.subtitle1.fontFamily,
        fontStyle: 'normal',
        fontSize: '24px',
        lineHeight: '32px',
        color: theme.palette.primary.heather,
        textAlign: 'center',
        fontFeatureSettings: " 'clig' off, 'liga' off",
        paddingTop: '32px',
        paddingBottom: '16px',
    },
    description: {
        fontFamily: theme.typography.body2.fontFamily,
        fontStyle: 'normal',
        fontSize: theme.typography.body1.fontSize,
        lineHeight: '24px',
        color: theme.palette.primary.heather,
        textAlign: 'center',
        fontFeatureSettings: " 'clig' off, 'liga' off",
        paddingBottom: '24px',
        paddingLeft: '24px',
        paddingRight: '24px',
    },
    card: {
        maxHeight: '291.95px',
        borderRadius: '10px',
        textAlign: 'left',
        background: 'white',
        boxShadow: 'none',

        marginBottom: '48.5px',

        filter:
            'drop-shadow(0px 5.97857px 5.97857px rgba(0, 0, 0, 0.1)) drop-shadow(0px 3.98571px 13.95px rgba(0, 0, 0, 0.07)) drop-shadow(0px 3.98571px 17.9357px rgba(0, 0, 0, 0.12))',
        [theme.breakpoints.down('xs')]: {
            maxHeight: '100%',
            marginBottom: '24.7px',
        },
        '&.MuiCard-root': {
            overflow: 'unset !important',
        },
    },
    // Single card Css
    singleServiceCard: {
        maxHeight: '162px',
        borderRadius: '10px',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'left',
        justifyContent: 'space-between',
        filter:
            'drop-shadow(0px 5.97857px 5.97857px rgba(0, 0, 0, 0.1)) drop-shadow(0px 3.98571px 13.95px rgba(0, 0, 0, 0.07)) drop-shadow(0px 3.98571px 17.9357px rgba(0, 0, 0, 0.12))',
        overflow: 'initial',

        [theme.breakpoints.down('sm')]: {
            maxHeight: '293px',
            flexDirection: 'column',
            width: '343px',
        },
        '@media (min-width: 600px) and (max-width: 1019px)': {
            flexDirection: 'row',
            height: '162.7px',
            width: '100%',
        },
    },
    singleServiceinfo: {
        maxHeight: '162.73px',
        width: '50%',
        position: 'relative',
        maxWidth: '100%',
        marginBottom: '20px',
        paddingLeft: '23.91px',
        paddingBottom: '15px',
        paddingRight: '23.91px',
        [theme.breakpoints.down('sm')]: {
            maxHeight: '163px',
        },
    },
    singleServiceImage: {
        height: '162.73px',
        width: '540px',
        [theme.breakpoints.down('sm')]: {
            height: '129px',
            width: '100%',
        },
        '@media (min-width: 600px) and (max-width: 1019px)': {
            width: '50%',
            height: '100%',
        },
    },
    image: {
        height: '130px',
        width: '100%',
    },

    info: {
        position: 'relative',
        paddingBottom: '15px',
        paddingLeft: '23.91px',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '24.71px',
        },
    },

    pipe: {
        padding: '0px 9.96px',
        color: theme.palette.primary.gray400,
    },

    linkBox: {
        marginBottom: '3px',
    },
    typeWithTitle: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    type: {
        width: '69.9px',
        height: '15.9px',
        fontFamily: theme.typography.body2.fontFamily,
        color: theme.palette.primary.white,
        fontSize: theme.typography.tooltip.fontSize,
        lineHeight: '10px',
        backgroundColor: theme.palette.primary.success,
        padding: '2.99px 3.99px',
        margin: '16px 16px 0 0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '15.9px',
    },
    arrow: {
        marginLeft: '8px',
        marginTop: '7px',
        width: '11px',
        height: '11px',
    },

    links: {
        display: 'flex',
        position: 'relative',
        height: '22px',
        fontFamily: theme.typography.body2.fontFamily,
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '13.95px',
        color: theme.palette.primary.sunset,
        cursor: 'pointer',
        opacity: '0.9',
        textAlign: 'left',
        '&:hover': {
            color: theme.palette.primary.sunset,
        },
    },
    mainHeading: {
        // fontFamily: theme.typography.subtitle1.fontFamily,
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'row',
        padding: '23.91px 0px 15.94px 0px',
        fontStyle: 'normal',
        // fontWeight: '700',
        fontSize: theme.typography.body1.fontSize,
        textTransform: 'capitalize',
        color: theme.palette.primary.heather,
        lineHeight: '25px',
        [theme.breakpoints.down('sm')]: {
            padding: '24.73px 0px 16.4px 0px',
            lineHeight: '157%',
            fontWeight: '400',
        },
    },

    subHeading: {
        // fontFamily: theme.typography.subtitle1.fontFamily,
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'row',
        margin: '0px 33.11px 7.97px 0px',
        fontStyle: 'normal',
        // fontWeight: '700',
        // fontSize:theme.typography.subtitle2.fontSize,
        // textTransform: 'capitalize',
        color: theme.palette.primary.heather,
        lineHeight: '18px',
        [theme.breakpoints.down('sm')]: {
            margin: '0px 0px 8.24px 0px',
            lineHeight: '19px',
            fontWeight: '400',
        },
    },
    phoneNumber: {
        fontFamily: theme.typography.body2.fontFamily,
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'row',
        height: '22px',
        margin: '0px 40.01px 7.97px 0px',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: theme.typography.subtitle2.fontSize,
        color: theme.palette.primary.heather,
        lineHeight: '22px',
        [theme.breakpoints.down('sm')]: {
            lineHeight: '23px',
        },
    },
    infoCard: {
        position: 'relative',
        padding: '1rem',
        textAlign: 'left',
    },
    horizontalCurve: {
        position: 'absolute',
        bottom: '-19px',
        marginLeft: '-15px',
        [theme.breakpoints.down('sm')]: {
            marginLeft: '-8px',
        },
    },
    verticalCurve: {
        position: 'absolute',
        right: '-19px',
        top: '23%',
        [theme.breakpoints.down('sm')]: {
            top: '20%',
        },
        [theme.breakpoints.up('sm')]: {
            top: '30%',
        },
    },
    curve: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: '1',
    },
    imgIcon: {
        position: 'absolute',
        bottom: '7px',
        zIndex: 1,
    },
    verticalIcon: {
        position: 'absolute',
        zIndex: 1,
    },
    imagebanner: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        // borderRadius: '0px 0px 10px 10px',

        [theme.breakpoints.only('xs')]: {
            height: '133.4px',
            // borderRadius: '0px 0px 10px 10px',
        },
    },
    singleBanner: {
        width: '100%',
        height: '100%',
        borderRadius: '0 10px 10px 0',
    },

    mainBanner: {
        position: 'relative',
        width: '100%',
        height: '100%',
    },
    cardLinks: {
        display: 'flex',
        alignItems: 'center',
        minHeight: '25px',
    },
    PinPukWrapper: {
        background: 'white',

        '& .pinPuk-modal': {
            // background: "rgba(51, 51, 51, 0.5)",
            // backdropFilter: "blur(4.5px)",

            '@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none)': {
                background: theme.palette.primary.gray550,
                backdropFilter: 'blur(4.5px)',
            },
            '@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none))': {
                background: theme.palette.primary.boxShadow2Lite,
            },

            '@media(max-width: 599px)': {
                maxWidth: '100vw',
            },
        },

        '& .pinPuk-main': {
            marginLeft: 'auto',
            marginRight: 'auto',
            padding: '0 30px',
            maxWidth: '754px',

            '@media(max-width: 1019px)': {
                padding: '0 99px',
                maxWidth: '858px',
            },
            '@media(max-width: 599px)': {
                marginTop: '8px',
                marginBottom: '0px',
                // marginLeft: "0px",
                // marginRight: "0px",
                padding: '0 8px',
            },
        },

        '& .pinPuk-content': {
            // padding: "0 30px",
            border: 'none',
            borderRadius: '10px',
            // wordWrap: "break-word",

            '@media(max-width: 599px)': {
                minHeight: 'calc(max(100vh , 640px) - 8px)',
                borderRadius: '10px 10px 0px 0px',
                wordWrap: 'break-word',
            },
        },

        '& .pinPuk-body': {
            background: theme.palette.primary.white,
            padding: '24px 32px',
            borderRadius: '10px',

            boxShadow: `0px 6px 6px -3px ${theme.palette.primary.boxShadow2Dark}, 0px 10px 14px 1px ${theme.palette.primary.boxShadow2Medium}, 0px 4px 18px 3px ${theme.palette.primary.boxShadow2Lite}`,

            '@media(max-width: 599px)': {
                padding: '16px 16px 16px 16px',
                minHeight: '632px',
                borderRadius: '10px 10px 0px 0px',
            },

            '& .pinPuk-body-header': {
                borderBottom: `1px solid ${theme.palette.primary.heather}`,
                paddingTop: '1.42px',

                '@media(max-width: 599px)': {},

                '& .pinPuk-header-back-icon': {
                    display: 'flex',
                    marginLeft: '-4px',
                    color: theme.palette.primary.sunset,
                    cursor: 'pointer',

                    '@media(max-width: 599px)': {
                        height: '20px',
                        width: '20px',
                        marginLeft: '-4px',
                    },
                },

                '& .pinPuk-header-text': {
                    display: 'flex',
                    marginTop: '15.58px',
                    minHeight: '45px',
                    fontFamily: theme.typography.body3Medium.fontFamily,
                    fontStyle: 'normal',
                    // fontWeight: 700,
                    fontSize: '24px',
                    lineHeight: '26px',
                    color: theme.palette.primary.heather,

                    position: 'relative',

                    '@media(max-width: 599px)': {
                        paddingLeft: '8px',
                        marginTop: '13.58px',
                        minHeight: '44px',
                        fontSize: '20px',
                    },

                    '& .pinPuk-header-line': {
                        position: 'absolute',
                        left: '0%',
                        right: '0%',
                        bottom: '3px',
                        border: `1px solid ${theme.palette.primary.borderColor}`,
                        borderTopWidth: '0px',

                        '@media(max-width: 599px)': {
                            bottom: '0px',
                        },
                    },
                },

                '& .hide': {
                    visibility: 'hidden',
                },
            },

            '& .pinPuk-body-content': {
                maxWidth: '406px',
                margin: '48px auto 0px',

                '@media(max-width: 599px)': {
                    margin: '26px auto 0px',
                    paddingLeft: '24px',
                    paddingRight: '24px',
                },

                '& .pinPuk-pin-info, & .pinPuk-puk-info, & .pinPuk-pin-description, & .pinPuk-puk-description': {
                    marginTop: '24px',
                },

                '& .pinPuk-body-content-header': {
                    fontFamily: theme.typography.subtitle1.fontFamily,
                    fontStyle: 'normal',
                    // fontWeight: 700,
                    fontSize: theme.typography.body1.fontSize,
                    lineHeight: '24px',
                    color: theme.palette.primary.heather,
                },

                '& .pinPuk-body-content-info': {
                    fontFamily: theme.typography.body3Medium.fontFamily,
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: theme.typography.h5.fontSize,
                    lineHeight: '24px',
                    letterSpacing: '8px',
                    color: theme.palette.primary.heather80,

                    marginTop: '5px',
                },

                '& .pinPuk-body-content-description': {
                    fontFamily: theme.typography.body2.fontFamily,
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: theme.typography.subtitle2.fontSize,
                    lineHeight: '22px',
                    color: theme.palette.primary.heather80,

                    marginTop: '8px',
                },
            },

            '& .pinPuk-body-footer': {
                marginTop: '3.5rem',
                marginBottom: '2.1rem',
                display: 'flex',
                justifyContent: 'center',

                '& .pinPuk-body-footer-cta': {
                    background: 'none',
                    color: 'inherit',
                    border: 'none',
                    padding: '0px',
                    font: 'inherit',
                    outline: 'inherit',

                    fontFamily: theme.typography.subtitle1.fontFamily,
                    fontStyle: 'normal',
                    fontWeight: theme.typography.subtitle1.fontWeight,
                    fontSize: theme.typography.subtitle1.fontSize,
                    lineHeight: theme.typography.subtitle1.lineHeight,
                    color: theme.palette.primary.sunset,

                    paddingLeft: '10px',
                    paddingRight: '10px',
                },
            },
        },
    },
    inactiveErrorText: {
        fontFamily: theme.typography.body2.fontFamily,
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: theme.typography.subtitle2.fontSize,
        lineHeight: '22px',
        color: theme.palette.primary.error,
        marginBottom: '8px',
    },

    modalContainer: {
        boxShadow: `0px 3px 1px -2px ${theme.palette.primary.boxShadow2Dark}, 0px 2px 2px ${theme.palette.primary.boxShadow2Dark}, 0px 1px 5px ${theme.palette.primary.boxShadow2Dark}`,

        margin: 0,

        width: '100%',
        border: 'none',
        margin: '0 auto',
        borderRadius: '10px',

        justifyContent: 'center',
        backdropFilter: 'blur(15px)',
        display: 'flex',
        marginTop: 'auto',
        marginBottom: 'auto',
        overflowY: 'auto',
        background: 'transparent',
        height: '100vh',
        alignItems: 'center',

        '& .modal': {
            display: 'flex !important',
            justifyContent: 'center',
            alignItems: 'center !important',
        },

        '& .modal-dialog': {
            maxWidth: 'none',
            alignItems: 'center !important',
        },
    },
    modalBody: {
        position: 'relative',
        width: '100%',
        maxWidth: '778px',
        backgroundColor: theme.palette.primary.white,
        overflow: 'hidden',
        borderRadius: '10px',
        margin: '0 auto',
        '&:focus-visible': {
            outline: 'none',
        },
        [theme.breakpoints.down('xs')]: {
            maxWidth: 'calc(344 / 360 * 100vw)',
            width: '100%',
            height: 'fit-content',
            borderRadius: '10px 10px 0 0',
            marginTop: 'auto',
            overflowY: 'auto',
            marginBottom: 'auto',
        },
        '@media(min-width: 600px) and (max-width: 1019px)': {
            width: '93%',
            border: 0,
        },
    },
    bannerImage: {
        height: '180px',
        width: '100%',
        borderRadius: '10px 10px 0 0',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            marginTop: '-2px',
        },
    },
    contract: {
        background: theme.palette.primary.heather,
        textAlign: 'center',
        fontSize: theme.typography.body1.fontSize,
        fontFamily: theme.typography.body2.fontFamily,
        fontWeight: 400,
        lineHeight: '24px',
        color: theme.palette.primary.white,
    },
    offerContent: {
        display: 'flex',
        [theme.breakpoints.down('xs')]: {
            display: 'block',
        },
    },
    listImage: {
        position: 'relative',
        width: '310px',
        // marginRight: '47px',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            marginRight: '0px',
            background: 'rgba(119, 147, 186, 0.1)',
        },
    },
    banner: {
        position: 'relative',
    },
    arrow: {
        position: 'absolute',
        left: 0,
        width: '16px',
        height: '16px',
        marginTop: '20px',
        marginLeft: '20px',
        cursor: 'pointer',
        [theme.breakpoints.down('xs')]: {
            width: '13px',
            height: '13px',
            marginTop: '12px',
            marginLeft: '12px',
        },
    },
    lists: {
        position: 'absolute',
        top: '0',
        margin: '24px 0 24px 24px',
        backdropFilter: 'blur(30px)',
        background: 'rgba(119, 147, 186, 0.1)',
        width: '286px',
        height: '292px',
        borderRadius: '10px',
        padding: '20px 24px',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            height: 'max-content',
            margin: '0px',
            backdropFilter: 'blur(5px)',
            borderRadius: '0px',
            position: 'initial',
            background: 'none',
        },
    },
    item: {
        display: 'flex',
        width: '238px',
        marginTop: '20px',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            marginTop: '11px',
            marginBottom: '25px',
        },
    },
    circle: {
        width: '32px',
        height: '32px',
        background: theme.palette.primary.white,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: '16px',
        boxShadow: `0px 2px 3.33333px -0.666667px ${theme.palette.primary.boxShadow2Dark}, 0px 3.33333px 5.33333px ${theme.palette.primary.boxShadow2Medium}, 0px 0.666667px 9.33333px ${theme.palette.primary.boxShadow2Lite}`,
        borderRadius: '33.3333px',
        flexShrink: '0',
    },
    contentText: {
        marginTop: '23px',
        marginLeft: '32px',
        [theme.breakpoints.down('xs')]: {
            boxShadow: `0px 3px 1px -2px ${theme.palette.primary.boxShadow2Dark}, 0px 2px 2px ${theme.palette.primary.boxShadow2Dark}, 0px 1px 5px ${theme.palette.primary.boxShadow2Dark}`,
            borderRadius: '0px 0px 25px 25px',
            padding: '24px',
            marginTop: '0px',
            marginLeft: '0px',
        },
    },
    boldText: {
        fontSize: '32px',
        fontFamily: theme.typography.subtitle1.fontFamily,
        // fontWeight: '700',
        lineHeight: '40px',
        color: theme.palette.primary.heather,
        marginBottom: '8px',
        [theme.breakpoints.down('xs')]: {
            fontSize: '24px',
            lineHeight: '33px',
        },
    },
    text: {
        fontSize: theme.typography.body1.fontSize,
        fontFamily: theme.typography.body2.fontFamily,
        fontWeight: '400',
        lineHeight: '24px',
        color: theme.palette.primary.heather,
        marginLeft: '8px',
    },
    charges: {
        display: 'flex',
    },
    bold: {
        fontSize: '24px',
        fontFamily: theme.typography.subtitle1.fontFamily,
        // fontWeight: '700',
        lineHeight: '32px',
        color: theme.palette.primary.heather,
        marginBottom: '16px',
        letterSpacing: '0.25px',
        [theme.breakpoints.down('xs')]: {
            fontSize: theme.typography.h5.fontSize,
            lineHeight: '30px',
        },
    },
    light: {
        fontSize: theme.typography.inputLabel.fontSize,
        fontFamily: theme.typography.body2.fontFamily,
        fontWeight: '400',
        lineHeight: '24px',
        color: theme.palette.primary.heather,
        marginLeft: '9px',
        marginTop: '10px',
        [theme.breakpoints.down('xs')]: {
            marginTop: '8px',
        },
    },
    message: {
        fontSize: theme.typography.subtitle2.fontSize,
        fontFamily: theme.typography.body2.fontFamily,
        fontWeight: '400',
        lineHeight: '22px',
        color: theme.palette.primary.heather,
        marginBottom: '40px',
        [theme.breakpoints.down('xs')]: {
            marginBottom: '0px',
            fontSize: theme.typography.body1.fontSize,
            lineHeight: '24px',
        },
    },
    textIcon: {
        fontSize: theme.typography.subtitle2.fontSize,
        fontFamily: theme.typography.subtitle1.fontFamily,
        // fontWeight: '700',
        lineHeight: '22px',
        color: theme.palette.primary.heather,
    },
    textLight: {
        fontSize: theme.typography.subtitle2.fontSize,
        fontFamily: theme.typography.body2.fontFamily,
        fontWeight: '400',
        lineHeight: '22px',
        color: theme.palette.primary.heather,
        marginLeft: '3px',
    },
    makePayContainer: {
        width: '143px',
        // background: '#D111C9',
        // borderRadius: '4100px',
        display: 'flex',
        height: '40px',
        alignItems: 'center',
        marginBottom: '40px',
        cursor: 'pointer',
        boxShadow: `0px 4px 45px -9px ${theme.palette.primary.boxShadow2Dark}`,

        [theme.breakpoints.down('xs')]: {
            width: '296px',
            height: '41px',
            margin: '0 auto',
        },
    },
    paymentContent: {
        fontSize: theme.typography.body1.fontSize,
        textTransform: 'unset',
        fontFamily: theme.typography.body1.fontFamily,
        lineHeight: '24px',
        color: theme.palette.primary.white,
        margin: '0 auto',
        fontWeight: '700',
        paddinTop: '8px',
        paddingBottom: '8px',

        '@media (min-width: 600px) and (max-width:1019px)': {
            fontSize: theme.typography.subtitle2.fontSize,
            lineHeight: '22px',
            width: 'fit-content',
            marginTop: '2px',
        },
    },
    link: {
        color: theme.palette.primary.heather80,
        fontSize: theme.typography.subtitle2.fontSize,
        fontFamily: theme.typography.body2.fontFamily,
        fontWeight: '400',
        lineHeight: '22px',
        textDecoration: 'underline',
        cursor: 'pointer',
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center',
            marginTop: '25px',
        },
    },
}));

export default useStyles;
