import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    MenuItem,
    Modal,
    OutlinedInput,
    Select,
    Typography,
} from '@material-ui/core';
import useStyle from './styles.js';
import arrow from '../images/pinkArrow.png';
import React, { useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { formatAddress } from '../../../utils/Format.util.js';
import { getRecomendedOffersProspectUtil } from '../recomended-offers/recomendedOffers.util';
import MyEirErrorHandle from '../../eir-porting/MyEirErrorHandle';
import { useApolloClient } from '@apollo/client';
import subImg from '../images/subModal.png';
import { useWindowDimension } from '../../../hooks/windowDimensions.js';
import EirButton from '../../../theme/EirButton.js';
const SubscriptionSelectionModal = ({
    modalImage,
    openSelectionModal,
    setOpenSelectionModal,
    csTvData,
    selectedParentSubId,
    setSelectedParentSubId,
    handleRedirectValues,
    setIsModalOpen,
    isLoading,
    setIsLoading,
}) => {
    const graphql = useApolloClient();
    const [width, height] = useWindowDimension();
    const [selectedAddress, setSelectedAddress] = useState('');
    const [openError, setOpenError] = useState(false);
    const [mobileMarginTop, setMobileMarginTop] = useState('56px');
    const [desktopMarginTop, setDesktopMarginTop] = useState('50px');
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const classes = useStyle();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const MenuProps = {
        PaperProps: {
            style: {
                position: 'absolute',
                backgroundColor: '#F3F5F9',
                borderRadius: '4px',
                minWidth: 'unset',
                width: '408px',
                marginTop: desktopMarginTop,
                marginLeft: '-6px',
                boxShadow: `0px 3px 1px -2px ${theme.palette.primary.boxShadow2Dark}, 0px 2px 2px ${theme.palette.primary.boxShadow2Dark}, 0px 1px 5px ${theme.palette.primary.boxShadow2Dark}`,
            },
        },
        MenuListProps: { disablePadding: true },
    };
    const MenuPropsMobile = {
        PaperProps: {
            style: {
                position: 'relative',
                backgroundColor: '#F3F5F9',
                paddingTop: 0,
                paddingBottom: 0,
                borderRadius: '4px',
                minWidth: 'unset',
                width: '82%',
                marginLeft: '-6px',
                marginTop: mobileMarginTop,
                boxShadow: `0px 3px 1px -2px ${theme.palette.primary.boxShadow2Dark}, 0px 2px 2px ${theme.palette.primary.boxShadow2Dark}, 0px 1px 5px ${theme.palette.primary.boxShadow2Dark}`,
            },
        },
        MenuListProps: { disablePadding: true },
    };

    const handleChange = event => {
        const selectedValue = event.target.value;

        setSelectedAddress(selectedValue);

        if (selectedValue) {
            setMobileMarginTop('101px');
            setDesktopMarginTop('86px');
        } else {
            setMobileMarginTop('50px');
            setDesktopMarginTop('50px');
        }

        setIsButtonDisabled(selectedValue === '' ? true : false);
    };

    let formattedDetails = [];
    let formattedAddresses = [];

    for (let i = 0; i < csTvData?.getCSTVDetails?.length; i++) {
        let fAddress = formatAddress(csTvData?.getCSTVDetails[i]?.addressInfo);
        let id = csTvData?.getCSTVDetails[i]?.id;
        let isEligible = csTvData?.getCSTVDetails[i]?.csEligiblity?.dpEligibity[0]?.eligible;
        let sipNumber = csTvData?.getCSTVDetails[i]?.servicesForSubscription[i]?.sipNumber;
        let dsl = csTvData?.getCSTVDetails[i]?.servicesForSubscription[i]?.dsl;
        formattedDetails.push({
            fAddress,
            id,
            isEligible,
            sipNumber,
            dsl,
        });
        formattedAddresses.push(fAddress);
    }

    const handleConfirmed = async () => {
        setIsLoading(true);
        const { data } = await getRecomendedOffersProspectUtil({
            graphql,
            setOpenError,
            openError,
            setIsModalOpen,
        });
        const reference = data.createCrossSellProspect;
        const redirectPath = handleRedirectValues(reference);

        if (redirectPath !== undefined) window.location.href = redirectPath;
    };

    return (
        <Modal
            id='offer-upgrade-selection-modal'
            open={openSelectionModal}
            onClose={() => setOpenSelectionModal(false)}
            className={classes.modalContainer}
        >
            <>
                <Box className={classes.modalBody}>
                    <Box className={classes.banner}>
                        <Box
                            component='img'
                            className={classes.bannerImage}
                            src={modalImage}
                            alt='banner'
                        />

                        <Box
                            component='img'
                            className={classes.arrow}
                            src={arrow}
                            alt='arrow'
                            onClick={() => setOpenSelectionModal(false)}
                        />
                    </Box>
                    <Typography className={classes.heading}>
                        Which address would you like to add eir TV?
                    </Typography>
                    <Box className={classes.selectionContainer}>
                        <FormControl className={classes.formControl}>
                            <Select
                                className={classes.selectComponent}
                                displayEmpty
                                value={selectedAddress}
                                onChange={handleChange}
                                input={<OutlinedInput />}
                                renderValue={selected => {
                                    if (!selected) {
                                        return (
                                            <Typography className={classes.defaultMenuItemText}>
                                                Select address
                                            </Typography>
                                        );
                                    }

                                    return selected;
                                }}
                                inputProps={{ 'aria-label': 'Without label' }}
                                MenuProps={matches ? MenuPropsMobile : MenuProps}
                            >
                                {formattedDetails.map(item => (
                                    <MenuItem
                                        onClick={() => setSelectedParentSubId(item.id)}
                                        className={classes.menuItems}
                                        key={item?.id}
                                        value={item?.fAddress}
                                        style={{
                                            backgroundColor:
                                                selectedParentSubId === item.id
                                                    ? '#FBECFB '
                                                    : '#F3F5F9 ',
                                        }}
                                        disabled={!item?.isEligible}
                                    >
                                        {item?.sipNumber
                                            ? `${item?.fAddress}, ${item?.sipNumber}`
                                            : `${item?.fAddress}, ${item?.dsl}`}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {isLoading ? (
                            <EirButton
                                variants='primaryButtonMedium'
                                style={{ padding: '0.5rem 2rem' }}
                                disabled={isButtonDisabled}
                                // variant='contained'
                                // className={classes.confirmButton}
                                onClick={handleConfirmed}
                            >
                                <CircularProgress
                                    disableShrink
                                    style={{
                                        color: theme.palette.primary.white,
                                        width: '17px',
                                        height: '17px',
                                        marginRight: '5px',
                                    }}
                                />
                                Confirming..
                            </EirButton>
                        ) : (
                            <EirButton
                                variants='primaryButtonMedium'
                                style={{ padding: '0.5rem 2rem' }}
                                disabled={isButtonDisabled}
                                // variant='contained'
                                // className={classes.confirmButton}
                                onClick={handleConfirmed}
                            >
                                Confirm
                            </EirButton>
                        )}
                    </Box>
                    {width > 600 && <Box className={classes.image} component='img' src={subImg} />}
                </Box>
                {openError ? <MyEirErrorHandle open={openError} setOpen={setOpenError} /> : ''}
            </>
        </Modal>
    );
};

export default SubscriptionSelectionModal;
