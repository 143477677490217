import React, { useEffect, useState } from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { commonRegisterStyles } from '../css/myEirRegistrationStyles/commonRegisterStyles';
import { useEnterAcccountNumberStyle } from '../css/myEirRegistrationStyles/useEnterAcccountNumberStyle';
import {
    Grid,
    FormControl,
    InputLabel,
    OutlinedInput,
    TextField,
    Typography,
} from '@material-ui/core';
import { useWindowDimension } from '../../hooks/windowDimensions';
import MyEirRegisterHeader from './reusable/MyEirRegisterHeader';
import MyEirRegisterFooter from './reusable/MyEirRegisterFooter';
import MyEirAlreadyRegistered from './MyEirAlreadyRegistered';
import MyEirEmailConfirmationModal from './MyEirEmailConfirmationModal';
import { useApolloClient } from '@apollo/client';
import ENDPOINT from '../../mobilecomponents/graphql/GraphQLConstants';
import { postVerifyIonAccount } from './register.util';

const MyEirEnterAccountNumber = props => {
    const [accountNumber, setAccountNumber] = useState('');
    const [showLabel, setShowLabel] = useState(false);
    const [validEntry, setValidEntry] = useState(false);
    const [invalidEntry, setInvalidEntry] = useState(false);
    const [errAccount, setErrAccount] = useState(false);
    const [width, height] = useWindowDimension();
    const graphql = useApolloClient();
    const [isOpen1, setIsopen1] = useState(false);
    const [isOpen2, setIsopen2] = useState(false);

    // const handleNextClick=()=>{
    //     toggle2();
    // }
    const toggle1 = () => {
        setIsopen1(!isOpen1);
    };

    const toggle2 = () => {
        setIsopen2(!isOpen2);
    };

    const handleChange = event => {
        setAccountNumber(event.target.value);
        setErrAccount(false);
    };

    const handleLabel = val => {
        setShowLabel(val);
    };

    const commonStyleClass = commonRegisterStyles();
    const styleClass = useEnterAcccountNumberStyle();
    const sendEmailVerification = async email => {
        try {
            const respEmail = await graphql.mutate({
                mutation: ENDPOINT.SEND_VERIFICATION_EMAIL,
                variables: {
                    email,
                },
            });
            return respEmail.data.sendVerificationEmail;
        } catch (err) {
            console.error(err);
            return 'Failure';
        }
    };

    const getPostVerifyIonAccount = async () => {
        try {
            const { data } = await postVerifyIonAccount({ graphql, accountNumber });
            props.handleNext({ username: data.emailAddress, emailStatus: data.emailStatus });
        } catch (err) {
            console.error(err);
            if (
                err.graphQLErrors !== undefined &&
                err.graphQLErrors.length > 0 &&
                err.graphQLErrors[0].message != undefined
            ) {
                setErrAccount(true);
            } else {
                setErrAccount(true);
            }
        }
    };

    useEffect(() => {
        if (!!accountNumber) {
            if (/\D/.test(accountNumber)) {
                // console.log("ERROR non numeric detected");
                setValidEntry(false);
                setInvalidEntry(true);
            } else {
                setInvalidEntry(false);
                if (accountNumber.length >= 8 && accountNumber.length <= 10) {
                    setValidEntry(true);
                } else {
                    setValidEntry(false);
                }
            }
        } else {
            setValidEntry(false);
            setInvalidEntry(false);
        }
    }, [accountNumber]);

    // console.log("Account number",accountNumber);
    const handleBackButton = () => {
        props.handleBackButton();
    };

    return (
        <div className={commonStyleClass.registrationContainer}>
            <div className={commonStyleClass.cardContainer}>
                <MyEirRegisterHeader
                    headerText={props.headerText}
                    handleBackButton={handleBackButton}
                    //  registerScreen={props.registerScreen} handleBackButton={handleBackButton} //define these while integrating all the screens together
                />
                <div className={styleClass.bodyContainer}>
                    <Grid
                        container
                        direction={width > 1019 ? 'column' : 'column-reverse'}
                        className='register-account-body'
                    >
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            className='register-grid-text-information'
                        >
                            <Typography variant='body1' className='register-grid-text-content'>
                                This is a 8 or 10 digit number that can be found on the top of your
                                bill or in your order confirmation email.
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            className='register-grid-input-field'
                        >
                            <TextField
                                className='register-grid-input-text-field'
                                id='account-number'
                                label={
                                    showLabel || !!accountNumber
                                        ? 'Account number'
                                        : 'Enter your account number'
                                }
                                type='text'
                                //  placeholder="Enter your account number"
                                variant='outlined'
                                color='primary'
                                value={accountNumber}
                                error={errAccount || invalidEntry}
                                InputLabelProps={{
                                    classes: {
                                        root: 'text-field-input-label-props-root',
                                        shrink:
                                            'text-field-input-label-props-shrink' +
                                            (errAccount || invalidEntry
                                                ? ' text-field-input-label-props-shrink-invalid'
                                                : ''),
                                        focused:
                                            'text-field-input-label-props-focused' +
                                            (errAccount || invalidEntry
                                                ? ' text-field-input-label-props-focused-invalid'
                                                : ''),
                                    },
                                }}
                                InputProps={{
                                    classes: {
                                        root:
                                            'text-field-input-props-root' +
                                            (!!accountNumber
                                                ? ' text-field-input-props-root-filled'
                                                : ''),
                                        focused: 'text-field-input-props-focused',
                                        notchedOutline:
                                            'text-field-input-props-notchedOutline' +
                                            (errAccount || invalidEntry
                                                ? ' text-field-input-props-notchedOutline-invalid'
                                                : ''),
                                        input: 'text-field-input-props-input',
                                    },
                                }}
                                onFocus={() => {
                                    handleLabel(true);
                                }}
                                onBlur={() => {
                                    handleLabel(false);
                                }}
                                onChange={handleChange}
                                inputProps={{
                                    inputmode: 'numeric',
                                    pattern: '[0-9]*',
                                    maxLength: 10,
                                }}
                            />
                            {invalidEntry && (
                                <div className='invalid-account-number-entered'>
                                    Please enter numbers only
                                </div>
                            )}
                            {errAccount && (
                                <div className='invalid-account-number-entered'>
                                    Please enter valid account number
                                </div>
                            )}
                        </Grid>
                    </Grid>
                </div>
                <MyEirRegisterFooter
                    button1Text='Confirm'
                    button1Enabled={validEntry && !errAccount}
                    handleButton1Click={getPostVerifyIonAccount} // define while integrating all the registration screen flows
                />
            </div>
        </div>
    );
};

export default MyEirEnterAccountNumber;
