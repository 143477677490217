import React from 'react';
import { getNameAndAccountID } from '../getNameAndAccountNumber';
import { getServiceId } from '../getServiceID';
import { triggerAdobeApi } from '../redesign-5.2/redesign.api';
import { useApolloClient } from '@apollo/client';
import { useLoginFormStyle } from '../css/useLoginFormStyle';
import MyEirLoginHeader from './MyEirLoginHeader';
import MyEirLoginFooter from './MyEirLoginFooter';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const AccountSelection = props => {
    const accounts = ['eir broadband and/or eir mobile billpay', 'eir mobile prepay'];
    const graphql = useApolloClient();
    const styleClass = useLoginFormStyle();
    const handleBrandClick = async val => {
        if (val.includes('prepay')) {
            sessionStorage.setItem('LoggedinUserBillType', 'Prepay');
            window.location.href = `/prepay/dashboard`;
        } else if (val.includes('billpay')) {
            sessionStorage.setItem('LoggedinUserBillType', 'Bill');
            console.log('call analytcs......');
            const response2 = await getNameAndAccountID(graphql);
            const serviceResponse = await getServiceId(graphql);

            await triggerAdobeApi({ graphql, name: 'login' });

            window.location.href = `/account/dashboard`;
        }
    };
    const handleBackButton = () => {
        props.handleBackButton();
    };

    return (
        <>
            <form
                onSubmit={e => {
                    e.preventDefault();
                }}
                className={styleClass.cardContainer}
            >
                <MyEirLoginHeader
                    headerText='Log in'
                    loginScreen={props.loginScreen + 1}
                    handleBackButton={handleBackButton}
                />
                <div className={styleClass.bodyContainer}>
                    <div className='accounts-field'>
                        <>
                            <div className='accounts-header'>Select one of your accounts:</div>
                            <div className='accounts-list'>
                                {accounts.map(val => {
                                    return (
                                        <div
                                            className='accounts-list-item'
                                            onClick={() => handleBrandClick(val)}
                                        >
                                            <div className='account-number'>{val}</div>

                                            <ArrowForwardIosIcon className='account-arrow-right' />
                                        </div>
                                    );
                                })}
                            </div>
                        </>
                    </div>
                </div>
                <MyEirLoginFooter />
            </form>
        </>
    );
};

export default AccountSelection;
