import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import FooterContainer from '../../sharedcomponents/FooterContainer';
import MobileHeaderRedesign from './MobileHeaderRedesign';
import { useEffect } from 'react';
import { subscribe } from 'pubsub-js';
import PaymentFlowModal from '../payment-flow/PaymentFlowModal';
import { useWindowDimension } from '../../hooks/windowDimensions';
// import HeaderContainer from '../../sharedcomponents/HeaderContainer';

function RedesignApp(props) {
    const [paymentModal, setPaymentModal] = useState(false);
    const [width, height] = useWindowDimension();
    const [imageUrl, setImageUrl] = useState('');

    const desktopImage =
        window.location.origin === 'https://my.eir.ie'
            ? window.location.origin + '/uploads/homepage_desktop_627250e5ff.png'
            : window.location.origin + '/uploads/homepage_desktop_0de08c866c.png';

    const mobileImage =
        window.location.origin === 'https://my.eir.ie'
            ? window.location.origin + '/uploads/Home_Page_Mobile_8a51b6a781.png'
            : window.location.origin + '/uploads/Home_Page_Mobile_f00312a2de.png';

    useEffect(() => {
        subscribe('paymentModal', paymentFlow);

        // Disabling drag on the entire web page
        document.addEventListener('touchaction', handleTouchAction, { passive: false });

        // Disabling drag on specific elements
        const elementsToDisableDrag = document.querySelectorAll('.disable-drag');
        for (let i = 0; i < elementsToDisableDrag.length; i++) {
            elementsToDisableDrag[i].addEventListener('touchaction', handleTouchAction, {
                passive: false,
            });
        }

        return () => {
            document.removeEventListener('touchaction', handleTouchAction);
            for (let i = 0; i < elementsToDisableDrag.length; i++) {
                elementsToDisableDrag[i].removeEventListener('touchaction', handleTouchAction);
            }
        };
    }, []);

    const paymentFlow = () => {
        setPaymentModal(!paymentModal);
    };

    const handleTouchAction = event => {
        if (event.touches.length > 1) {
            event.preventDefault();
        }
    };

    const location = window.location.href.indexOf('/account/dashboard') > -1;

    useEffect(() => {
        function setBackgroundImage() {
            if (width > 600) {
                setImageUrl(desktopImage);
            } else {
                setImageUrl(mobileImage);
            }
        }

        setBackgroundImage();
    }, [width]);

    return (
        <Box
            id='my-eir-mobile'
            style={{
                backgroundImage: location && `url(${imageUrl})`,
            }}
            className={
                location
                    ? `myeir-mobile-redesign-background  ${
                          props.offsetBackgroundImage
                              ? 'myeir-mobile-redesign-background-offset'
                              : ''
                      }`
                    : `myeir-mobile-redesign-backgroundchange`
            }
        >
            <header style={{ position: 'sticky', top: '0', zIndex: '10' }}>
                <MobileHeaderRedesign loginSuccess={props.loginSuccess} />
            </header>
            {props.children}
            {paymentModal && <PaymentFlowModal />}
            <FooterContainer appname={'mobile'} />
        </Box>
    );
}

export default RedesignApp;
