import { makeStyles } from '@material-ui/core/styles';

export const useRegisterEmailStyle = makeStyles(theme => ({
    bodyContainer: {
        minHeight: '155px',
        paddingTop: '42px',
        paddingBottom: '20px',
        position: 'relative',

        '@media(max-width: 599px)': {
            minHeight: '294px',
            paddingTop: '24px',
            paddingBottom: '24px',
            paddingLeft: '16px',
            paddingRight: '16px',
        },

        '& .register-email-body': {
            maxWidth: '376px',
            margin: '0px auto',

            '& input[type=number]::-webkit-inner-spin-button,& input[type=number]::-webkit-outer-spin-button': {
                WebkitAppearance: 'none',
                margin: '0',
            },
            '& input[type=number]': {
                MozAppearance: 'textfield',
            },
            '& .register-email-text-information': {
                marginBottom: '32px',
                '@media(max-width: 599px)': {
                    marginBottom: '24px',
                },
            },
        },
        '& .register-email-text-content': {
            fontFamily: theme.typography.body2.fontFamily,
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: theme.typography.subtitle2.fontSize,
            lineHeight: '22px',
            color: theme.palette.primary.heather,

            textAlign: 'left',
        },
        '& .register-grid-text-content': {
            // fontFamily: "gt-walsheim-regular",
            // fontStyle: "normal",
            // fontWeight: 400,
            // fontSize: "14px",
            // lineHeight: "22px",
            // color: "rgba(50, 52, 53, 0.87)",
            color: theme.palette.primary.heather,

            textAlign: 'left',
        },

        ' & .register-email-grid-email, & .register-email-grid-confirm-email': {
            marginBottom: '20px',
            '@media(max-width: 599px)': {
                marginBottom: '27px',
            },

            ' & .register-grid-input-text-field': {
                width: '100%',

                fontFamily: theme.typography.body2.fontFamily,
                fontStyle: 'normal',
                fontWeight: 400,

                ' & .text-field-input-label-props-root': {
                    color: theme.palette.primary.heather80,
                    fontFamily: theme.typography.body2.fontFamily,
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: theme.typography.body1.fontSize,
                    lineHeight: '19px',
                    letterSpacing: '0.15px',
                },
                '& .text-field-input-label-props-focused, & .text-field-input-label-props-shrink': {
                    color: theme.palette.primary.heather,
                    // fontSize: "12px",
                    lineHeight: '16px',
                },
                '& .text-field-input-label-props-focused-invalid, & .text-field-input-label-props-shrink-invalid': {
                    color: theme.palette.primary.error,
                },
                '& .text-field-input-label-props-shrink-nonMatch': {
                    color: theme.palette.primary.error,
                },
                ' & .text-field-input-props-root': {
                    '& .text-field-input-props-input': {
                        color: theme.palette.primary.heather,
                        fontFamily: theme.typography.body2.fontFamily,
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: theme.typography.body1.fontSize,
                        lineHeight: '19px',
                        letterSpacing: '0.15px',

                        padding: '19px 12px 18px 12px',
                    },
                },
                ' & .text-field-input-props-root-filled': {
                    ' & .text-field-input-props-notchedOutline': {
                        borderColor: 'unset',
                        borderColor: theme.palette.primary.heather,
                        borderWidth: '2px',
                    },
                    ' & .text-field-input-props-notchedOutline-invalid': {
                        borderColor: 'unset',
                        borderColor: theme.palette.primary.error,
                        borderWidth: '2px',
                    },
                },
                ' & .text-field-input-props-focused, & .text-field-input-props-root:hover': {
                    ' & .text-field-input-props-notchedOutline': {
                        borderColor: 'unset',
                        borderColor: theme.palette.primary.heather,
                    },
                    ' & .text-field-input-props-notchedOutline-invalid': {
                        borderColor: 'unset',
                        borderColor: theme.palette.primary.error,
                    },
                },
                '& .text-field-input-props-root:hover': {
                    ' & .text-field-input-props-notchedOutline-notFocus': {
                        borderColor: 'unset',
                        borderColor: theme.palette.primary.heather,
                    },
                },
            },

            '& .invalid-email-format': {
                fontFamily: theme.typography.inputLabel.fontFamily,
                // fontStyle: 'normal',
                // fontWeight: 400,
                // fontSize:theme.typography.inputLabel.fontSize,
                // lineHeight: '20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                letterSpacing: '0.4px',
                color: theme.palette.primary.error,

                marginTop: '3px',
            },
        },
    },
}));
