import React, { useState } from 'react';
import '../sass/app.scss';
import '../components/loading/loading.scss';
import '../components/my-eir-dashboard/rtm.scss';
import HeaderContainer from '../sharedcomponents/HeaderContainer';
import FooterContainer from '../sharedcomponents/FooterContainer';
import Session from './shared/Session';
function PrepayApp(props) {
    const [loginSuccess, setLoginsuccess] = useState(
        JSON.parse(sessionStorage.getItem('loginSuccess')) == true ? true : false
    );

    return (
        <div>
            <div className='parent-container'>
                {/* header */}
                <HeaderContainer appname={'prepay'} loginSuccess={loginSuccess} />
                {/* {loginSuccess && <Session />} */}

                {/* body */}
                {props.children}

                {/* footer */}
                <FooterContainer appname={'prepay'} />
            </div>
        </div>
    );
}

export default PrepayApp;
