import { isIOS } from 'react-device-detect';
// import '../../src/components/generic/addtohomescreen.js';
import '../../src/sharedcomponents/css/addtohomescreen.css';
import { MY_EIR_CONST } from '../../src/components/myeirconstant/myEirConstant';
import useGTMForPWAA2HPopup from '../hooks/useGTMForPWAA2HPopup';
import useGTMForPWAInstall from '../hooks/useGTMForPWAInstall';
import useGTMForPWAUninstall from '../hooks/useGTMForPWAUnistall';

export const initialisePWA = () => {
    // Register serviceWorker if browser supports it
    if ('serviceWorker' in navigator) {
        window.addEventListener('load', () => {
            navigator.serviceWorker
                .register('/service-worker.js')
                .then(registration => {
                    console.log('SW registered: ', registration);
                })
                .catch(registrationError => {
                    console.log('SW registration failed: ', registrationError);
                });
        });
    }

    // Detects if device is in standalone mode
    const isInStandaloneMode = () =>
        'standalone' in window.navigator && window.navigator.standalone;

    function addToHomeScreen() {
        console.log('inside addto home screen');
        let a2hsBtn = document.querySelector('.ad2hs-prompt'); // hide our user interface that shows our A2HS button
        a2hsBtn.style.display = 'none'; // Show the prompt
        useGTMForPWAA2HPopup();
        deferredPrompt.prompt(); // Wait for the user to respond to the prompt
        deferredPrompt.userChoice.then(function (choiceResult) {
            if (choiceResult.outcome === 'accepted') {
                console.log('User accepted the A2HS prompt');
            } else {
                console.log('User dismissed the A2HS prompt');
            }

            deferredPrompt = null;
        });
    }
    window.addEventListener('appuninstalled', () => {
        useGTMForPWAUninstall();
    });
    window.addEventListener('appinstalled', () => {
        // Hide the app-provided install promotion
        // hideInstallPromotion();
        let a2hsBtn = document.querySelector('.ad2hs-prompt');
        a2hsBtn.style.display = 'none';

        // Clear the deferredPrompt so it can be garbage collected
        deferredPrompt = null;
        // Optionally, send analytics event to indicate successful install

        useGTMForPWAInstall();
        console.log('PWA was installed');
    });

    function showAddToHomeScreen() {
        let iosPrompt = document.querySelector('.ios-prompt');
        let a2hsBtn = document.querySelector('.ad2hs-prompt');
        if (window.location.href == MY_EIR_CONST.url.REDIRECT_URL) {
            a2hsBtn.style.display = 'block';
            iosPrompt.style.display = 'none';
            console.log('inside android');
        }
        a2hsBtn.addEventListener('click', addToHomeScreen);
    }

    let deferredPrompt;
    window.addEventListener('beforeinstallprompt', function (e) {
        // Prevent Chrome 67 and earlier from automatically showing the prompt
        e.preventDefault();
        // Stash the event so it can be triggered later.
        deferredPrompt = e;
        console.log('inside beforeinstallpromt');
        // uncomment it for android popup to show
        if (!isIos() && !isInStandaloneMode()) {
            showAddToHomeScreen();
        }
    });

    function showIosInstall() {
        let iosPrompt = document.querySelector('.ios-prompt');
        let a2hsBtn = document.querySelector('.ad2hs-prompt'); // hide our user interface that shows our A2HS button
        if (window.location.href == MY_EIR_CONST.url.REDIRECT_URL) {
            a2hsBtn.style.display = 'none';
            iosPrompt.style.display = 'block';
            console.log('inside ios');
        }
        iosPrompt.addEventListener('click', () => {
            iosPrompt.style.display = 'none';
        });
    }

    // Detects if device is on iOS
    const isIos = () => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        return /iphone|ipad|ipod/.test(userAgent);
    };

    // console.log('window.navigator.standalone', isInStandaloneMode());

    if (isInStandaloneMode() && isIos()) {
        useGTMForPWAInstall();
    }
    // Checks if should display install popup notification:

    // uncomment it for IOS  popup to show
    if (isIos() && !isInStandaloneMode()) {
        // this.setState({ showInstallMessage: true });
        showIosInstall();
    }
};
