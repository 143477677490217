import { Box, Grid, Typography } from '@material-ui/core';
import useStyles from './style';
import React, { useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import MyEirSimReplacementTile from './MyEirSimReplacementTile';
import { getData } from '../account-details/myAccount.util';
import { getMsisdns } from './simReplacement.util';
import Loading from '../../../components/loading/Loading';
import { useHistory } from 'react-router-dom';
import { publish } from 'pubsub-js';
import { ArrowBackIcon } from '../../../sharedcomponents/inputs/svg-icons/CustomSvgIcons';

const AccountSimreplacement = () => {
    const [contactDetails, setContactDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [retry, setRetry] = useState('');
    const classes = useStyles();
    const graphql = useApolloClient();
    const [msisdnVal, setMsisdnVal] = useState([]);
    const history = useHistory();

    const getDataUtil = async () => {
        await getData({ graphql, setContactDetails, setLoading, setRetry });
    };

    const getMisisdnsUtil = async () => {
        await getMsisdns({ setMsisdnVal });
    };

    const arrowAction = () => {
        history.push('/account/dashboard');
        publish('openhamburger');
    };

    useEffect(() => {
        getDataUtil();
        getMisisdnsUtil();
    }, []);

    return (
        <Box className={classes.AccountWrapper}>
            <Box className={classes.DetailsContainer}>
                <Box className={classes.headerBackIcon}>
                    <ArrowBackIcon onClick={arrowAction} className={classes.arrow} />
                </Box>
                <Box className={classes.detailsHeaderbackText}>SIM replacement</Box>
                <Box className={classes.DividerLine}></Box>

                {loading ? (
                    retry == 'api-error' ? (
                        <div class={classes.ApiError}>
                            Something went wrong, please try again later.
                            {/* <span>Retry?</span> */}
                        </div>
                    ) : (
                        <Loading />
                    )
                ) : (
                    contactDetails !== null && (
                        <Grid container spacing={2} className={classes.SimreplacementWrapper}>
                            <Grid item>
                                <Typography variant='h5' className='details-topic-title'>
                                    Select your SIM:
                                </Typography>
                            </Grid>

                            {msisdnVal.map((item, index) => {
                                return (
                                    <Grid item className={`sim-img `}>
                                        <MyEirSimReplacementTile
                                            msisdn={item.msisdn}
                                            serviceId={item.id}
                                            ContactDetails={contactDetails}
                                        />
                                        <Box className='divider-line'></Box>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    )
                )}
            </Box>
        </Box>
    );
};

export default AccountSimreplacement;
