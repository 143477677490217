import React, { useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';

import { useAlreadyRegistered } from '../css/useAlreadyRegistered';
import alreadyRegisteredIcon from '../images/alreadyRegisteredIcon.png';

const MyEirAlreadyRegistered = props => {
    // const [isopen, setIsOpen] = useState(false);
    // const toggle = () => setIsOpen(!isopen);
    const classes = useAlreadyRegistered();

    const handleClickClose = () => {
        props.toggle();
    };
    const handleClickReset = () => {
        window.location.href = '/forgot-password';
    };

    const redirectToLogin = () => {
        handleClickClose();
        window.location.href = '/';
    };

    return (
        <React.Fragment>
            {/* <div className={classes.alreadyRegisteredFooter} onClick={toggle}>
                <button className={'primary-cta footer-cta'}>Confirm</button>
            </div> */}
            <Modal
                className={classes.alreadyRegistredMain}
                wrapClassName={classes.alreadyRegistredMainWrapper}
                modalClassName={classes.alreadyRegistredMainModal}
                backdropClassName={classes.alreadyRegistredMainBackDrop}
                contentClassName={classes.alreadyRegistredMainContent}
                isOpen={props.isOpen}
                toggle={props.toggle}
                modalTransition={{ timeout: 300 }}
                centered
            >
                <ModalBody className={classes.alreadyRegisteredWrapper}>
                    <div className={classes.registerTitleContainer}>
                        <div className={classes.registerPhone}>
                            <div className={classes.registerTitle}>{props.heading}</div>
                            <div className={classes.registerTitleMobile}>{props.headingMobile}</div>
                        </div>
                    </div>
                    <div className={classes.alreadyRegisteredBody}>
                        <div className={classes.tickWrapper}>
                            <img
                                className={classes.tick}
                                src={alreadyRegisteredIcon}
                                alt='already Registered icon'
                            />
                        </div>
                        <div className={classes.newsTextWrapper}>
                            <div className={classes.newsText}>{props.newsContentText}</div>
                        </div>
                        <div className={classes.emailVerifiedWrapper}>
                            <div className={classes.emailVerifiedText}>{props.verifiedText}</div>
                            <div className={classes.emailVerifiedTextMobile}>
                                {props.verifiedTextMobile}
                            </div>
                        </div>
                        <div className={classes.emailVerifiedUsername}>{props.usernameEmail}</div>
                        <div className={classes.passwordInstructionWrapper}>
                            <div
                                className={classes.passwordInstructionPara}
                                dangerouslySetInnerHTML={{ __html: props.subtext }}
                            />
                        </div>
                    </div>
                    <div className={classes.alreadyRegisteredFooter}>
                        <button
                            className={'primary-cta footer-cta'}
                            onClick={
                                window.location.href.indexOf('/account/dashboard') > -1
                                    ? handleClickClose
                                    : window.location.href.indexOf('/create-password') > -1 ||
                                      window.location.href.indexOf('/forgot-email') > -1
                                    ? redirectToLogin
                                    : handleClickReset
                            }
                        >
                            {props.buttonText}
                        </button>

                        <div className='secondary-cta footer-cta'>
                            <span
                                style={{ cursor: 'pointer' }}
                                onClick={
                                    window.location.href.indexOf('/register') > -1
                                        ? redirectToLogin
                                        : handleClickClose
                                }
                            >
                                {props.footerText}
                            </span>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
            {/* {resetScreen && <MyEirCreatePasswordFormPage />} */}
        </React.Fragment>
    );
};

export default MyEirAlreadyRegistered;
