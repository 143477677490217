import { Box, Typography } from '@material-ui/core';
import { myBalanceStyles } from './myBalanceStyles';
import React from 'react';
import textImage from '../images/image.svg';
import { suffixChecker } from '../current-usage/currentUsage.util';
import moment from 'moment-timezone';

export const formattingDate = timestamp => {
    const date = moment(timestamp);
    const day = date.date();
    // const month = date.toLocaleString('default', { month: 'short' });
    const month = date.format('MMM');
    const year = date.year() % 100;
    return `${day}${suffixChecker(day)}  ${month} ${year}`;
};

function MyEirBalanceText({ debitDate, bal, lastBillDueDate }) {
    const classes = myBalanceStyles();

    const balLength = bal[0].length;

    return (
        // <Box className={classes.myEirBalanceTextContainer}>
        <Box className={balLength > 3 ? classes.myEirFourDigit : classes.myEirBalanceTextContainer}>
            <Box component='img' src={textImage} alt='TextImage' />
            <Box>
                <Typography variant='h5' component='h2' align='center' className={classes.texts}>
                    Your direct debit will be applied for on or after the{' '}
                    <Typography component='span' variant='body2' className={classes.paymentDueText}>
                        {formattingDate(lastBillDueDate)}
                    </Typography>
                </Typography>
            </Box>
        </Box>
    );
}

export default MyEirBalanceText;
