import React, { useEffect, useState, useRef } from 'react';
import { useVerificationCodeStyles } from '../css/useVerificationCodeStyles';
import { TextField, Grid } from '@material-ui/core';
import { commonRegisterStyles } from '../css/myEirRegistrationStyles/commonRegisterStyles';
import MyEirRegisterHeader from './reusable/MyEirRegisterHeader';
import MyEirRegisterFooter from './reusable/MyEirRegisterFooter';
import { useApolloClient } from '@apollo/client';
// import ENDPOINT from '../../mobilecomponents/graphql/GraphQLConstants';
import END_POINT from '../../components/graphql/GraphQLConstants';
// import MyEirAlreadyRegistered from './MyEirAlreadyRegistered';
// import MyEirEmailConfirmationModal from './MyEirEmailConfirmationModal';
import { Typography } from '@material-ui/core';
import { Box } from '@material-ui/core';
import { getUsernameFromOTP, sendEmailVerification } from './register.util';
import ReCAPTCHA from 'react-google-recaptcha';
import MyEirRegistrationErrorsPopup from './MyEirRegistrationErrorsPopup';

function MyEirVerificationCode(props) {
    const [code, setCode] = useState('');
    const [activeCode, setActiveCode] = useState(false);
    const [valid, setValid] = useState(false);
    const [invalid, setInvalid] = useState(false);
    const [enableInput, setEnableInput] = useState(true);
    const [enableSubmit, setEnableSubmit] = useState(false);
    const [err, setErr] = useState(false);
    const [warning, setWarning] = useState(false);
    const graphql = useApolloClient();
    const commonStyle = commonRegisterStyles();
    const useStyle = useVerificationCodeStyles({ activeCode });
    const [captchaFailed, setCaptchaFailed] = useState('');
    const [showCaptcha, setShowCaptcha] = useState(false);
    const [isRecaptchaNull, setIsRecaptchaNull] = useState(false);
    const recaptchaRef = useRef(null);
    const [showError, setShowError] = useState(false);

    const [errorText, setErrorText] = useState({
        headText: '',
        subText: '',
    });

    const handleBackButton = () => {
        sessionStorage.removeItem('reg_email');
        sessionStorage.removeItem('reg_email_status');
        props.handleBackButton();
    };

    const handleOnChange = async value => {
        try {
            if (value == null) {
                setIsRecaptchaNull(true);
            } else {
                setIsRecaptchaNull(false);
                setCaptchaFailed('');
                const res = await graphql.mutate({
                    mutation: END_POINT.NUMBER_VALIDATION,
                    variables: {
                        registerWith: 'NUMBER' || 'EMAIL',
                        registerValue: sessionStorage.getItem('reg_phone_number'),
                        recaptcha: value, // NEED TO SEND PROPER RECAPTCHA HERE
                    },
                });

                if (
                    res.data.validateUser.statusCode == 201 ||
                    res.data.validateUser.statusCode == 202
                ) {
                    sessionStorage.setItem('reg_uuid', res.data.validateUser.otpUuid);
                    setShowCaptcha(false);
                }
                if (res.data.validateUser.statusCode == 203) {
                }
            }
        } catch (err) {
            console.error(err.message);
            setCaptchaFailed('Captcha is not valid');
        }
    };

    const resendOTP = () => {
        setShowCaptcha(true);
        setCode('');
        setValid(true);
        setErr(false);
    };

    const verifyOTP = async () => {
        try {
            setEnableSubmit(false);
            setEnableInput(false);
            const data = await getUsernameFromOTP({ graphql, code });
            if (data.username === null && sessionStorage.getItem('reg_bill_type') != 'PREPAY') {
                setShowError(!showError);
                setErrorText({
                    headText: 'Oops something went wrong',
                    subText: 'Email not found',
                });
            } else {
                props.handleNext({
                    statusCode: data?.statusCode,
                    emailStatus: data?.status,
                    username: data?.username,
                });
            }
        } catch (error) {
            setValid(false);
            // console.log(err);
            if (error.graphQLErrors !== undefined) {
                if (error.graphQLErrors[0].message.indexOf('Incorrect') > -1) {
                    setErr(error.graphQLErrors[0].message);
                } else {
                    setErr('Something went wrong');
                }
            } else {
                if (error.message.indexOf('Incorrect otpcode') > -1) {
                    setErr('Invalid verification code');
                } else {
                    setErr(error.message);
                }
            }
            console.error(error);
        } finally {
            setEnableSubmit(true);
            setEnableInput(true);
        }
    };

    useEffect(() => {
        if (sessionStorage.getItem('reg_uuid') === null) {
            handleBackButton();
        }
    }, []);

    const handleChange = event => {
        setCode(event.target.value);
        setEnableSubmit(true);
        if (event.target.value) {
            if (/\D/.test(event.target.value)) {
                // console.log('Not a numeric value');
                // setInValid(false);
                setInvalid(true);
                setValid(false);
            } else {
                if (event.target.value.length !== 6) {
                    // console.log('Must be 6 digits long.');
                    setValid(false);
                    setInvalid(false);
                    // setErr('Must be 6 digit number'); // !! Do We need this ?
                    // !!props.forgotUserName ? props.unameSetErr(true) : '';
                    setWarning(false);
                } else {
                    setInvalid(false);
                    setErr(false);
                    // !!props.forgotUserName ? props.unameSetErr(false) : '';
                    // console.log('good');
                    setWarning(false);
                    setValid(true);
                }
            }
        } else {
            setErr(false);
            // !!props.forgotUserName ? props.unameSetErr(false) : '';
            setInvalid(false);
            setValid(false);
            setWarning(false);
        }
    };

    return (
        <div className={commonStyle.registrationContainer}>
            <div className={commonStyle.cardContainer}>
                <MyEirRegisterHeader
                    headerText={props.headerText}
                    handleBackButton={handleBackButton}
                />

                {/* body part */}
                <div className={`${useStyle.body} verification-body`}>
                    <Grid container>
                        <Grid item xs={12}>
                            <div className={useStyle.text}>
                                <Typography className={useStyle.text}>
                                    We have sent the verification code
                                    <Typography className={useStyle.middleText}>
                                        {' '}
                                        to the contact number on your account.
                                    </Typography>
                                    <Typography className={useStyle.lastText}>
                                        This code will expire in 1 minute.
                                    </Typography>
                                </Typography>
                            </div>

                            <TextField
                                className={`${useStyle.codes} verification-code`}
                                id='verification-code'
                                type='text'
                                label='Verification code'
                                variant='outlined'
                                color='primary'
                                onChange={handleChange}
                                value={code}
                                disabled={!enableInput}
                                onClick={() => setActiveCode(!activeCode)}
                                inputProps={{
                                    inputMode: 'numeric',
                                    pattern: '[0-9]*',
                                    maxLength: 6,
                                }}
                                error={invalid || Boolean(err) || warning}
                                InputLabelProps={{
                                    classes: {
                                        root: 'text-field-input-label-props-root',
                                        shrink:
                                            'text-field-input-label-props-shrink' +
                                            (invalid || err
                                                ? ' text-field-input-label-props-shrink-invalid'
                                                : ''),
                                        focused:
                                            'text-field-input-label-props-focused' +
                                            (invalid || err
                                                ? ' text-field-input-label-props-focused-invalid'
                                                : ''),
                                    },
                                }}
                                InputProps={{
                                    classes: {
                                        root:
                                            'text-field-input-props-root' +
                                            (!!code ? ' text-field-input-props-root-filled' : ''),
                                        focused: 'text-field-input-props-focused',
                                        notchedOutline:
                                            'text-field-input-props-notchedOutline' +
                                            (invalid ||
                                            (!!props.forgotUserName ? props.unameErr : err)
                                                ? ' text-field-input-props-notchedOutline-invalid'
                                                : ''),
                                        input: 'text-field-input-props-input',
                                    },
                                }}
                            />

                            {invalid ? (
                                <div className='invalid-messages'>Not a numeric value.</div>
                            ) : err ? (
                                <div className='invalid-messages'>{err}</div>
                            ) : warning ? (
                                <div className='invalid-messages'>
                                    Oops! The code you entered is incorrect or may have expired.
                                    Please try again.
                                </div>
                            ) : (
                                ''
                            )}
                            <div className={useStyle.resend} onClick={resendOTP}>
                                Resend a new code
                            </div>

                            {/* recaptcha */}
                            {showCaptcha && (
                                <Grid
                                    container
                                    sm={12}
                                    xs={12}
                                    md={12}
                                    className={useStyle.gridRecaptcha}
                                >
                                    <div className={useStyle.reCaptcha}>
                                        <ReCAPTCHA
                                            ref={recaptchaRef}
                                            className={useStyle.reCaptchaRobot}
                                            sitekey='6LfZsRYTAAAAAJMBqawqK3sy7NmHDC8M9rKdkX36'
                                            render='explicit'
                                            onChange={handleOnChange}
                                        />
                                    </div>
                                    <Grid item xs={12} className={useStyle.errormessage}>
                                        {isRecaptchaNull ? (
                                            <div className='invalid-messages'>{captchaFailed}</div>
                                        ) : (
                                            ''
                                        )}
                                    </Grid>
                                </Grid>
                            )}
                            {sessionStorage.getItem('reg_bill_type') !== 'PREPAY' ? (
                                <Box>
                                    <Typography className={useStyle.msg}>
                                        Alternatively, you can continue by providing your eir
                                        <Typography
                                            onClick={() =>
                                                props.headerText === 'Register'
                                                    ? props.handleNext(null, 3)
                                                    : props.headerText === 'Forgot email'
                                                    ? props.handleNext(null, 2)
                                                    : props.handleNext(null, 3)
                                            }
                                            className={useStyle.accountnumberLink}
                                        >
                                            {' '}
                                            account number.
                                        </Typography>
                                    </Typography>
                                </Box>
                            ) : (
                                ''
                            )}
                        </Grid>
                    </Grid>
                </div>
                <MyEirRegisterFooter
                    button1Text='Next'
                    /* button1Enabled={valid ? true : false}*/
                    // handleButton1Click={props.handleNextFooter}
                    handleButton1Click={verifyOTP}
                    button1Enabled={!isRecaptchaNull && enableSubmit}
                />
                {showError ? (
                    <MyEirRegistrationErrorsPopup
                        open={showError}
                        setOpen={setShowError}
                        headText={errorText.headText}
                        subText={errorText.subText}
                    />
                ) : (
                    ''
                )}
            </div>
        </div>
    );
}

export default MyEirVerificationCode;
