import { ADDON_STATUS } from '../../../components/myeirconstant/myEirConstant';
import { getMyAddresses } from '../../getMyAdresses';
import { getActiveAddons, getAddOnsApi } from './manageAddons.api';

const { ONGOING_ADDITION, ONGOING_TERMINATION } = ADDON_STATUS;
const structureAddons = activeAddons => {
    return {
        title: activeAddons?.displayName || '',
        subtitle: activeAddons.details?.replace(/<\/?p>/gi, '').replace(/<br\s*\/?>/gi, ' ') || '',
        channel: activeAddons?.channel || '',
        addonId: activeAddons?.id || '',
        price: activeAddons?.price || '',
        billingType: activeAddons?.billingType || '',
        code: activeAddons?.code || '',
        serviceId: activeAddons?.serviceID,
        status: activeAddons?.status,
    };
};

export const getAddOns = async ({ graphql, serviceGroupCode, codes, statusFilter }) => {
    try {
        const data = await getAddOnsApi({
            graphql,
            serviceGroupCode,
            codes,
        });

        let newArr = [];
        for (let i = 0; i < data.length; i++) {
            for (let j = 0; j < statusFilter.length; j++) {
                if (
                    data[i].code === statusFilter[j].code &&
                    data[i].charges.price === statusFilter[j].price
                )
                    newArr = [
                        ...newArr,
                        {
                            ...data[i],
                            code: data[i].code,
                            id: statusFilter[j].id,
                            price: statusFilter[j].price,
                            billingType: statusFilter[j].billingType,
                            serviceID: statusFilter[j].serviceID,
                            status: statusFilter[j].status,
                        },
                    ];
            }
        }

        const formattedAddon = newArr?.map(addOns => {
            if (Object.keys(addOns).length !== 0 && addOns.constructor === Object) {
                return structureAddons(addOns);
            }
        });

        return formattedAddon;
    } catch (err) {
        console.error(err);
        return null;
    }
};

/**
 * @name getCurrentlyActiveAddons - get the acive addons for active service ids
 * @param {Object} Obj - attributes for the function
 * @param {GraphQL} Obj.graphql - appoloclient graphql constant
 * @param {useState} Obj.servicesIds - array of active service ids
 * @param {GraphQL} graphql - appoloclient graphql constant
 * @param {useState} servicesIds - array of active service ids
 *  @param {useState} setIsLoading - useState for loading
 *  @param {useState} setIsError - api error
 * @param {const} value - dispatch value for useContext
 */
export const getCurrentlyActiveAddons = async ({
    graphql,
    sessionValue,
    setShowAddons,
    setIsLoading,
    setIsError,
    value,
}) => {
    try {
        setIsLoading(true);
        let arrTemp = [];
        for (let i = 0; i < sessionValue.length; i++) {
            let addons;

            if (sessionValue[i].status === 'ACTIVE') {
                try {
                    addons = await getActiveAddons({
                        graphql,
                        servicesIds: sessionValue[i].id,
                    });
                } catch (error) {
                    console.log('error while fetching getCurrentlySubscribedAddons =====>', error);
                }
                if (addons?.getCurrentlySubscribedAddons?.length !== 0) {
                    const statusFilter = addons.getCurrentlySubscribedAddons
                        .filter(
                            addon => addon.code !== 'FREE_AMAZON_TV' && addon.code !== 'AMAZON_TV'
                        )

                        .map(addon => {
                            return {
                                code: addon.code,
                                price: addon.charges[0].priceVatIncluded,
                                billingType: addon.charges[0].billingType,
                                id: addon.id,
                                serviceID: sessionValue[i].id,
                                serviceGroup: sessionValue[i].domain,
                                status: addon.status,
                            };
                        });

                    const mapFilter = statusFilter.map(add => ({
                        addonPrice: add?.price,
                        addonId: add?.id,
                        serviceId: add?.serviceID,
                        serviceGroup: add?.serviceGroup,
                        billingType: add?.billingType,
                        status: add?.status,
                    }));

                    let displayTitle;

                    try {
                        displayTitle = await getAddOns({
                            graphql,
                            serviceGroupCode: sessionValue[i]?.domain,
                            codes: statusFilter.map(addonFilterCode => addonFilterCode?.code),
                            addonId: statusFilter.map(addonFilterId => addonFilterId?.id),
                            statusFilter: statusFilter,
                            serviceId: statusFilter.map(
                                addonFilterServiceId => addonFilterServiceId?.serviceID
                            ),
                        });

                        arrTemp = [...arrTemp, ...displayTitle];

                        setIsLoading(false);
                        setIsError(false);
                    } catch (error) {
                        setIsError(true);
                        console.log('error while fetching getAddOns  =====>', error);
                    }
                }
            }
        }

        setShowAddons(arrTemp);

        value.dispatch({
            type: 'myAddons',
            payload: { name: 'showAddons', value: arrTemp },
        });

        // return addons;
    } catch (error) {
        console.log('error in fetching addons ', error.message);
    }
};

/**
 * @name getBllingAddress - Returns billing address of the container
 * @param {GraphQL} graphql - appoloclient graphql constant
 * @return {object} - Returns addresses of the user
 */
export const getBllingAddress = async graphql => {
    const data = await getMyAddresses(graphql);
    return data;
};

/**
 * @name getAddonStatus - Returns ststus of the addons
 * @param {string} status - status returned from addons
 * @return {string} - Returns status of the  sddon
 */
export const getAddonStatus = status => {
    if (status === ONGOING_ADDITION) {
        return 'Activation in progress';
    } else if (status === ONGOING_TERMINATION) {
        return 'Cancellation in progress';
    }
};
