import React, { useState, useRef, useEffect } from 'react';
import errormessages from './errorMessages';
import { usePaymentFlowStyles } from '../css/usePaymentFlowStyles';
import { useApolloClient } from '@apollo/client';
import ENDPOINT from '../graphql/GraphQLConstants';

export default function PaymentAddCard(props) {

    const classes = usePaymentFlowStyles();

    useEffect(() => {
        props.setHeader('Payment method')
    })
    const graphql = useApolloClient();
    

    return (
        <div id='payment-add-card' className={classes.addCard}>   
            <div className='add-card-body'>
                <h2 className='add-card-title'>Add another payment method</h2>
                <div className='add-card-type'>
                    <div className='card-logo'></div>
                    <div className='card-type-details'>
                        <h3 className='card-type-title'>Credit Card</h3>
                        <h3 className='card-type-description'>Pay with Mastercard or Visa</h3>
                    </div>
                    <div className='right-arrow'></div>
                </div>
            </div>
        </div>
    );
}
