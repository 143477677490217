import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { subscribe } from 'pubsub-js';
import { publish } from 'pubsub-js';
import { useMyAccountStyle } from './useMyAccountStyle';
import ENDPOINT from '../../graphql/GraphQLConstants';
import { useApolloClient } from '@apollo/client';
import { formatCounty } from '../../addressFormat';
import { useHistory } from 'react-router-dom';
import MyEirChangeAddressContainer from '../../my-account/MyEirChangeAddressContainer';
import MyEirChangeEmail from '../../my-account/MyEirChangeEmail';
import MyEirChangeContactNumber from '../../my-account/MyEirChangeContactNumber';
import MyEirChangePasswordAndEmailToggle from '../../my-account/MyEirChangePasswordAndEmailToggle';
import PaymentAddCardFlow from '../../payment-cards/PaymentAddCardFlow';
import Loading from '../../../components/loading/Loading';
import { useWindowDimension } from '../../../hooks/windowDimensions';
import { getMsisdnAndOffer, getData, getAuthUser, getPaymentDetailsData } from './myAccount.util';
import MyEirAuthorisedUsersContainer from '../../my-account/authorised-users/MyEirAuthorisedUsersContainer';
import MyEirErrorHandle from '../../eir-porting/MyEirErrorHandle';
import { ArrowBackIcon } from '../../../sharedcomponents/inputs/svg-icons/CustomSvgIcons';
import { ACCOUNT_STATUS } from '../../../components/myeirconstant/myEirConstant';

const MyAccountDetails = () => {
    const classes = useMyAccountStyle();
    const [contactDetails, setContactDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const [retry, setRetry] = useState('');
    const [width, height] = useWindowDimension();
    const [authUser, setAuthUser] = useState(null);
    const [contact, setContact] = useState(contactDetails);
    const [contactInfo, setContactInfo] = useState({});
    const [payDetails, setPayDetails] = useState([]);
    const [msisdnLoadingError, setMsisdnLoadingError] = useState(false);
    const [msisdnAndOffer, setMsisdnAndOffer] = useState([]);
    const [openError, setOpenError] = useState(false);
    const auth = authUser;
    const payData = payDetails;
    const history = useHistory();
    const [isOpen1, setIsopen1] = useState(false);
    const [isOpen2, setIsopen2] = useState(false);

    const userStatus = sessionStorage.getItem('Account_status');

    const graphql = useApolloClient();

    const toggle1 = () => {
        setIsopen1(!isOpen1);
    };
    const [isOpen, setIsopen] = useState(false);
    function toggle() {
        setIsopen(!isOpen);
    }

    useEffect(() => {
        setLoading(true);
        getDataUtil();
        subscribe('updateAddress', getData);
        subscribe('updateContactNumber', getData);
    }, []);

    useEffect(() => {
        getAuthUserUtil();
    }, []);

    const toggleError = () => {
        setOpenError(!openError);
    };

    useEffect(() => {
        getPaymentDetailsDataUtil();

        subscribe('UPDATE_CARD', (msg, data) => {
            getPaymentDetailsDataUtil();
        });
    }, []);

    const getDataUtil = async () => {
        await getData({ graphql, setContactDetails, setLoading, setRetry });
    };

    const getAuthUserUtil = async () => {
        await getAuthUser({ graphql, setAuthUser });
    };
    const getPaymentDetailsDataUtil = async () => {
        await getPaymentDetailsData({ graphql, setPayDetails });
    };

    const handleContactInfo = () => {
        const primaryContact = contactDetails?.phoneNumbers.find(ele => ele?.type == 'MOBILE');
        const secondaryContact = contactDetails?.phoneNumbers.find(ele => ele?.type == 'FIXE');
        setContactInfo(prev => ({ ...prev, primaryContact, secondaryContact }));
    };

    const onChangePassword = async () => {
        try {
            const res = await graphql.mutate({
                mutation: ENDPOINT.RESET_PASSWORD,
                variables: {
                    emailAddress: sessionStorage.getItem('LoggedinUser'),
                },
            });

            if (res.data.resetPassword) {
                toggle1();
            }
        } catch (err) {
            toggleError();
        }
    };

    const toggle2 = () => {
        setIsopen2(!isOpen2);
    };
    const onChangePaymentMethod = () => {
        toggle2();
    };

    useEffect(() => {
        setContact(contactDetails);
        handleContactInfo();
    }, [contactDetails]);

    const arrowAction = () => {
        history.push('/account/dashboard');
        publish('openhamburger');
    };

    return (
        <>
            <Box className={classes.AccountWrapper}>
                <Box className={classes.DetailsContainer}>
                    <Box className={classes.headerBackicon}>
                        <ArrowBackIcon onClick={arrowAction} />
                    </Box>
                    <Box className={classes.detailsHeaderbackText}>Account details</Box>

                    <Grid container className={classes.DetailsWrapper}>
                        {loading ? (
                            retry == 'api-error' ? (
                                <Box className={classes.TextWrapper}>
                                    We are having trouble loading account details, please try again
                                    later
                                </Box>
                            ) : (
                                <Box className={classes.TextWrapper}>
                                    <Loading />
                                </Box>
                            )
                        ) : (
                            contactDetails !== null &&
                            authUser !== null &&
                            payDetails !== null && (
                                <>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={width >= 1020 ? 6 : 12}
                                        className='details-grid-first-half'
                                    >
                                        {contact?.firstName && contact?.lastName && (
                                            <div className='details-topic'>
                                                <div className='details-topic-title'>Full name</div>
                                                <div className='details-topic-content'>
                                                    {contact.firstName} {contact.lastName}
                                                </div>
                                            </div>
                                        )}
                                        {contact?.emails.length > 0 && (
                                            <div className='details-topic'>
                                                <div className='details-topic-title-change'>
                                                    <div className='details-topic-title'>
                                                        Login email
                                                    </div>
                                                    {/* <div className='details-topic-change'>Change</div> */}

                                                    {/* ***Hidden for now to avoid issues in prod***
                                                    {userStatus === ACCOUNT_STATUS.BILLABLE || userStatus === ACCOUNT_STATUS.INITIAL ?
                                                    <MyEirChangeEmail
                                                        loginEmail={contact?.emails[0].email}
                                                    /> :''} 
                                                    */}
                                                </div>
                                                <div className='details-topic-content'>
                                                    {contact?.emails.length > 0
                                                        ? contact?.emails[0].email
                                                        : ''}
                                                </div>
                                            </div>
                                        )}
                                        <div className='details-topic'>
                                            <div className='details-topic-title-change'>
                                                <div className='details-topic-title'>Password</div>
                                                <div
                                                    className='details-topic-change'
                                                    onClick={onChangePassword}
                                                >
                                                    Change
                                                </div>
                                            </div>
                                            <div className='details-topic-content'>**********</div>
                                        </div>
                                        <div className='details-topic'>
                                            <div className='details-topic-title-change'>
                                                <div className='details-topic-title'>
                                                    Payment method
                                                </div>
                                                <div
                                                    className='details-topic-change'
                                                    onClick={onChangePaymentMethod}
                                                >
                                                    Change
                                                </div>
                                            </div>
                                            <div className='details-topic-content'>
                                                {payDetails
                                                    .filter(y => y.default == true)
                                                    .map(element =>
                                                        element.paymentMethodType === 'MANUAL' ||
                                                        element.paymentMethodType === 'CASH'
                                                            ? 'Manual'
                                                            : element.paymentMethodType ==
                                                              'DIRECT_DEBIT'
                                                            ? element.iban.replace(/X/g, '*')
                                                            : element.paymentMethodType !==
                                                                  'DIRECT_DEBIT' &&
                                                              element.partialDigits == null
                                                            ? `**** **** **** ****`
                                                            : element.partialDigits.replace(
                                                                  /x/g,
                                                                  '*'
                                                              )
                                                    )}
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={width >= 1020 ? 6 : 12}
                                        className='details-grid-second-half'
                                    >
                                        {width >= 1020 && <div className='divider'></div>}
                                        <div className='details-topic'>
                                            <div className='details-topic-title-change'>
                                                <div className='details-topic-title'>
                                                    Billing address
                                                </div>
                                                {/* ***Hidden for now to avoid issues in prod***
                                                {userStatus === ACCOUNT_STATUS.BILLABLE || userStatus === ACCOUNT_STATUS.INITIAL ?
                                                <div
                                                    className='details-topic-change'
                                                    onClick={toggle}
                                                >
                                                    Change
                                                </div> :''}
                                                */}
                                            </div>
                                            {contact?.addresses.length > 0 ? (
                                                <>
                                                    {contact?.addresses.map(element =>
                                                        element.type !== 'BILLING' ? (
                                                            ''
                                                        ) : (
                                                            <>
                                                                <div className='details-topic-content details-topic-content-billing-address'>
                                                                    {element.addressLine1}
                                                                </div>
                                                                <div className='details-topic-content details-topic-content-billing-address'>
                                                                    {element.addressLine2}
                                                                </div>
                                                                <div className='details-topic-content details-topic-content-billing-address'>
                                                                    {element.addressLine3}
                                                                </div>
                                                                <div className='details-topic-content details-topic-content-billing-address'>
                                                                    {element.town}
                                                                </div>
                                                                <div className='details-topic-content details-topic-content-billing-address'>
                                                                    {formatCounty(element.county)}
                                                                </div>
                                                                <div className='details-topic-content details-topic-content-billing-address'>
                                                                    {element.code}
                                                                </div>
                                                            </>
                                                        )
                                                    )}
                                                </>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                        {contactInfo?.primaryContact && (
                                            <div className='details-topic'>
                                                <div className='details-topic-title-change'>
                                                    <div className='details-topic-title'>
                                                        Primary contact number
                                                    </div>
                                                    {/* ***Hidden for now to avoid issues in prod*** 
                                                    {userStatus === ACCOUNT_STATUS.BILLABLE || userStatus === ACCOUNT_STATUS.INITIAL ?
                                                    <MyEirChangeContactNumber
                                                        phoneNumberId={
                                                            contactInfo?.primaryContact?.id
                                                        }
                                                        phoneNumber={
                                                            contactInfo?.primaryContact?.phoneNumber
                                                        }
                                                        phoneNumberType={
                                                            contactInfo?.primaryContact?.type
                                                        }
                                                    />: ''}
                                                     */}
                                                </div>
                                                <div className='details-topic-content details-topic-content-contact-number'>
                                                    {contactInfo?.primaryContact?.phoneNumber}
                                                </div>
                                            </div>
                                        )}
                                        {contactInfo?.secondaryContact && (
                                            <div className='details-topic'>
                                                <div className='details-topic-title-change'>
                                                    <div className='details-topic-title'>
                                                        Secondary contact number
                                                    </div>
                                                    {userStatus === ACCOUNT_STATUS.BILLABLE ||
                                                    userStatus === ACCOUNT_STATUS.INITIAL ? (
                                                        <MyEirChangeContactNumber
                                                            phoneNumberId={
                                                                contactInfo?.secondaryContact?.id
                                                            }
                                                            phoneNumber={
                                                                contactInfo?.secondaryContact
                                                                    ?.phoneNumber
                                                            }
                                                            phoneNumberType={
                                                                contactInfo?.secondaryContact?.type
                                                            }
                                                        />
                                                    ) : (
                                                        ''
                                                    )}
                                                </div>
                                                <div className='details-topic-content details-topic-content-contact-number'>
                                                    {contactInfo?.secondaryContact?.phoneNumber}
                                                </div>
                                            </div>
                                        )}
                                        <MyEirAuthorisedUsersContainer />
                                    </Grid>
                                    <MyEirChangePasswordAndEmailToggle
                                        toggle={toggle1}
                                        email={''}
                                        isopen={isOpen1}
                                        heading={'Change Password'}
                                        headingMobile={'Change Password'}
                                        newsContentText={'We’ve just sent you an email.'}
                                        verifiedText={''}
                                        verifiedTextMobile={''}
                                        subtext={''}
                                        subtextContinued={
                                            'Please follow the instructions to change your password and don’t forget to check your spam email!'
                                        }
                                        buttonText={'Close'}
                                        footerText={''}
                                    />
                                    <MyEirChangeAddressContainer toggle={toggle} isOpen={isOpen} />
                                    <PaymentAddCardFlow toggle2={toggle2} isOpen2={isOpen2} />
                                </>
                            )
                        )}
                    </Grid>
                </Box>
                {openError ? <MyEirErrorHandle open={openError} setOpen={toggleError} /> : ''}
            </Box>
        </>
    );
};

export default MyAccountDetails;
