import React from 'react';

import { Grid, Box, Typography } from '@material-ui/core';
import { onBoardingStyles } from './onBoardingStyles';

const OtherScreens = ({
    title,
    description,
    backgroundImage,
    backgroundImageGradient,
    altText,
}) => {
    const classes = onBoardingStyles();
    return (
        <Box className={classes.otherScreenContainer}>
            <Grid container direction='column' style={{ height: '100%' }}>
                <Grid
                    item
                    style={{
                        background: backgroundImageGradient,
                    }}
                    className={classes.imageGridContainer}
                >
                    <Box
                        className={classes.otherScreenImageContainer}
                        component='img'
                        src={backgroundImage}
                        alt={altText}
                    />
                </Grid>

                <Grid item className={classes.descriptionGridContainer}>
                    <Box className={classes.otherScreenTextContainer}>
                        <Typography className={classes.otherScreenTitle}>{title}</Typography>
                        <Typography className={classes.otherScreenDescription}>
                            {description}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default OtherScreens;
