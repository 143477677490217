import {
    getAddressList,
    submitSimReplacement,
    getSimEligibilityCheck,
    activateSimAPI,
    getSimCardCharges,
} from './simReplacement.api';

import { triggerAdobeApi } from '../redesign.api';

export const handleSubmitSimRequest = async ({
    submitFormData,
    ReFormatCounty,
    graphql,
    isTicked,
    serviceId,
    setOpenSimModal,
    setOpenCustomPopup,
    setOpenInactiveModal,
    setIsError,
}) => {
    try {
        console.log('form values', submitFormData);
        const data = await submitSimReplacement({
            graphql,
            request: {
                address: {
                    addressLine1: submitFormData.address.addressLine1,
                    addressLine2: submitFormData.address.addressLine2,
                    code: submitFormData.address.code,
                    county: ReFormatCounty(submitFormData.address.county),
                    town: submitFormData.address.town,
                },

                barServices: isTicked,
                email: submitFormData.email,
                firstName: submitFormData.firstName,
                lastName: submitFormData.lastName,
                phoneNumber: submitFormData.phoneNum,
                serviceId: serviceId,
            },
            token: sessionStorage.getItem('access_token'),
        });
        console.log('Submit order sim:', data);
        setOpenCustomPopup(true);
        const simreplacementfetch = await triggerAdobeApi({ graphql, name: 'sim' });
        console.log('Submit trigger sim:', simreplacementfetch);
    } catch (error) {
        setOpenSimModal(true);
        setIsError(true);
        setOpenCustomPopup(false);
        setOpenInactiveModal(false);
        setIsError(true);
        console.log('Sim submit error', error);
    }
};

export const getSimChargesResponse = async ({ graphql, serviceId, setSimCost }) => {
    try {
        const data = await getSimCardCharges({
            graphql,
            token: sessionStorage.getItem('access_token'),
            serviceId: serviceId,
        });
        setSimCost(data.getSimSwapCharges.charges[0].defaultPriceVatIncluded);
    } catch (error) {
        console.log('Sim charges error', error);
    }
};

export const handleActivateSim = async ({
    graphql,
    serviceId,
    code,
    setData,
    setOpenCustomPopup,
    setOpenError,
    openError,
}) => {
    try {
        const data = await activateSimAPI({
            graphql,
            activateSim: {
                serviceId: serviceId,
                puk: code,
            },
            token: sessionStorage.getItem('access_token'),
        });
        if (data === 'success') {
            setData &&
                setData({
                    serviceId: serviceId,
                    puk: code,
                });
            setOpenCustomPopup(true);
        } else {
            setOpenError(!openError);
        }
    } catch (error) {
        setOpenError(!openError);
        console.log('Sim activate error', error);
    }
};

export const getAddresses = async ({
    graphql,
    value,
    setAddressData,
    setIsConfirm,
    setSubmitFormData,
    setIsEnabled,
    submitFormAction,
    setSingleAddress,
    setAddressList,
    addressList,
    formatCounty,
}) => {
    try {
        const data = await getAddressList({ graphql, eirCode: value });
        if (data.length == 0) {
            setAddressData(null);
            setIsConfirm(true);
            setSubmitFormData({
                address: {
                    addressLine1: '',
                    addressLine2: '',
                    addressLine3: '',
                    town: '',
                    county: '',
                },
                email: '',
                phoneNum: '',
                firstName: '',
                lastName: '',
            });
            setIsEnabled(false);
        } else if (data.length == 1) {
            submitFormAction({
                address: {
                    addressLine1: data[0]?.addressLine1,
                    addressLine2: data[0]?.addressLine2,
                    addressLine3: data[0]?.addressLine3,
                    code: data[0]?.code,
                    town: data[0]?.town,
                    county: formatCounty(data[0]?.county),
                },
            });
            setIsEnabled(true);
            setIsConfirm(false);
            setSingleAddress(data);
        } else {
            setIsConfirm(false);
            console.log('address list length', data);
            setAddressList(data);

            if (addressList.length > 1) {
                // handleNext();
            }
        }
    } catch (err) {
        console.error('address error:', err);
    }
};

export async function getMsisdns({ setMsisdnVal }) {
    const services = JSON.parse(sessionStorage.getItem('LoggedinServices'));
    const subscription = JSON.parse(
        sessionStorage.getItem('LoggedinUserSubscriptionIDandAccountID')
    );
    let temp = [];
    for (let i = 0; i < services?.length; i++) {
        const matchedSubscriptionId = subscription.find(
            sub => sub.id === services[i].subscriptionId && sub.serviceGroup === 'MBB'
        );
        if (
            services[i].domain === 'MOBILE' &&
            services[i].status === 'ACTIVE' &&
            !matchedSubscriptionId
        ) {
            temp.push(services[i]);
        }
    }
    setMsisdnVal(temp);
}

export const getSimEligibilityResponse = async ({
    graphql,
    serviceId,
    setUpdateOrderLink,
    setLinkFlag,
    setReorderForm,
    setReorderCost,
}) => {
    try {
        const data = await getSimEligibilityCheck({
            graphql,
            token: sessionStorage.getItem('access_token'),
            serviceId: serviceId,
        });
        const datas = data.getSimCardEligibilty;
        if (
            data.getSimCardEligibilty.length === 0 ||
            data?.getSimCardEligibilty[0]?.status === 'DONE' ||
            data?.getSimCardEligibilty[0]?.status === 'ERROR'
        ) {
            setUpdateOrderLink('Order');
        } else if (data?.getSimCardEligibilty[0]?.status === 'IN_DELIVERY') {
            setUpdateOrderLink('Re-order');
            setLinkFlag(true);
            setReorderForm(data.getSimCardEligibilty[0]);
            setReorderCost(data.getSimCardEligibilty[0].charges[0].defaultPriceVatIncluded);
        } else if (
            data?.getSimCardEligibilty[0]?.status === 'IN_ACTIVATION' ||
            data?.getSimCardEligibilty[0]?.status === 'VALIDATED' ||
            data?.getSimCardEligibilty[0]?.status === 'CANCELED'
        ) {
            setUpdateOrderLink('hide');
        }
    } catch (error) {
        console.log('Sim eligibility error', error);
    }
};
