import React, { useEffect, useState } from 'react';
import { Grid, Typography, Box } from '@material-ui/core';

import { useMyAccountStyle } from '../account-details/useMyAccountStyle';

import Loading from '../../../components/loading/Loading';

import {
    getContractsDetailsValues,
    getUANDetals,
} from '../../my-account/getContractsDetailsValues';
import { useHistory } from 'react-router-dom';
import MyEirDisplayUAN from '../../my-account/MyEirDisplayUAN';
import { useApolloClient } from '@apollo/client';
import { getAllContracts } from '../account-details/myAccount.util';
import { publish } from 'pubsub-js';
import { useWindowDimension } from '../../../hooks/windowDimensions';
import MyEirInactiveUser from '../Inactive-user/MyEirInactiveUser';
import Icon from '../images/inactiveIcon.png';
import theme from '../../../theme/MyEirTheme';
import { ArrowBackIcon } from '../../../sharedcomponents/inputs/svg-icons/CustomSvgIcons';
import { DOMAINS, ACCOUNT_STATUS } from './../../../components/myeirconstant/myEirConstant';
import TerminatedTile from '../../reusable-components/TerminatedTile';

function MyEirContracts({}) {
    const classes = useMyAccountStyle();
    const graphql = useApolloClient();
    const [width, height] = useWindowDimension();
    const [initialUser, setInitialUser] = useState(false);
    const history = useHistory();
    const [detailsData, setDetailsData] = useState([]);
    const [uanDetails, setUANDetails] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [isContractsError, setIsContractsError] = useState(false);
    const [contractInfo, setContractInfo] = useState([]);
    const [contracts, setContracts] = useState([]);
    const description = `As a new customer, you will see your contract(s) displayed once your services become active.`;
    const { VOIP, FIBER, DSL, MOBILE, IPTV } = DOMAINS;
    const userStatus = sessionStorage.getItem('Account_status');

    async function getDetailsData() {
        const objectValues = await getContractsDetailsValues(graphql);
        const uanData = await getUANDetals(graphql);
        setUANDetails(uanData);
        setDetailsData(objectValues);

        if (objectValues.isError == false) {
            setIsLoading(false);
            // setIsContractsError(false);
        } else {
            setIsContractsError(true);
        }
    }
    useEffect(() => {
        getDetailsData();
    }, []);

    useEffect(() => {
        getAllContractsUtil();
    }, []);

    useEffect(() => {
        const sessionValue = JSON.parse(sessionStorage.getItem('LoggedinServices'));
        const initial = sessionValue?.find(user => user.status === 'INITIAL');

        if (initial) {
            setInitialUser(true);
        }
    }, []);

    const getAllContractsUtil = async () => {
        await getAllContracts({ graphql, setContracts });
    };
    const getVOIPDetails = ele => {
        const descr = detailsData.offerVal.find(
            offerEle => offerEle.subscriptionId === ele.subscriptionId
        );
        const subsTermination = detailsData.subscriptionDetails.find(
            sub => sub.id === ele.subscriptionId
        );
        const cont = contracts.find(
            contractEle => contractEle.subscriptionId === ele.subscriptionId
        );
        const uan = uanDetails.find(uanEle => uanEle.subscriptionId === ele.subscriptionId);
        const broad = detailsData.msisdn.find(
            broadEle =>
                broadEle.domain === FIBER &&
                broadEle.subscriptionId === ele.subscriptionId &&
                broadEle.dsl !== null
        );
        const newContracts = {
            name: ele.sipNumber + (broad?.dsl !== null ? ` | ${broad.dsl}` : ''),
            description: descr?.description,
            startAt: cont?.startAt,
            endedAt: cont?.endedAt,
            subscriptionId: ele.subscriptionId,
            status: ele.status,
            domain: 'Broadband & Landline',
            fiberUan: uan?.fiberUan,
            voipUan: uan?.voipUan,
            termination: subsTermination?.terminatedAt,
        };

        return newContracts;
    };

    const getPSTNDetails = ele => {
        const descr = detailsData.offerVal.find(
            offerEle => offerEle.subscriptionId === ele.subscriptionId
        );
        const subsTermination = detailsData.subscriptionDetails.find(
            sub => sub.id === ele.subscriptionId
        );
        const cont = contracts.find(
            contractEle => contractEle.subscriptionId === ele.subscriptionId
        );
        const uan = uanDetails.find(uanEle => uanEle.subscriptionId === ele.subscriptionId);

        const broad = detailsData.msisdn.find(
            broadEle =>
                broadEle.domain === FIBER &&
                broadEle.subscriptionId === ele.subscriptionId &&
                broadEle.dsl !== null
        );

        const newContracts = {
            name:
                ele.phoneNumber +
                (broad === undefined ? '' : broad?.dsl !== null ? ` | ${broad.dsl}` : ''),
            description: descr?.description,
            startAt: cont?.startAt,
            endedAt: cont?.endedAt,
            subscriptionId: ele.subscriptionId,
            status: ele.status,
            domain: uan.serviceGroup === 'SA_PSTN' ? 'SA_PSTN' : 'DUALPLAY_PSTN',
            fiberUan: uan?.fiberUan,
            termination: subsTermination?.terminatedAt,
        };

        return newContracts;
    };
    const getTVDetails = ele => {
        const descr = detailsData.offerVal.find(
            offerEle => offerEle.subscriptionId === ele.subscriptionId
        );
        const subsTermination = detailsData.subscriptionDetails.find(
            sub => sub.id === ele.subscriptionId
        );
        const cont = contracts.find(
            contractEle => contractEle.subscriptionId === ele.subscriptionId
        );
        const newContracts = {
            name: ele.bbdsl
                ? `${ele.bbdsl.substring(0, 3)} ${ele.bbdsl.substring(3, 6)} ${ele.bbdsl.substring(
                      6
                  )}`
                : '',
            description: descr?.description,
            startAt: cont?.startAt,
            endedAt: cont?.endedAt,
            subscriptionId: ele.subscriptionId,
            status: ele.status,
            domain: ele.domain,
            termination: subsTermination?.terminatedAt,
        };
        return newContracts;
    };

    const getMOBILEDetails = ele => {
        const descr = detailsData.offerVal.find(
            offerEle => offerEle.subscriptionId === ele.subscriptionId
        );
        const subsTermination = detailsData.subscriptionDetails.find(
            sub => sub.id === ele.subscriptionId
        );
        const cont = contracts.find(
            contractEle => contractEle.subscriptionId === ele.subscriptionId
        );
        const newContracts = {
            name: ele.msisdn
                ? `${ele.msisdn.substring(0, 3)} ${ele.msisdn.substring(
                      3,
                      6
                  )} ${ele.msisdn.substring(6)}`
                : null,
            description: descr?.description,
            startAt: cont?.startAt,
            endedAt: cont?.endedAt,
            subscriptionId: ele.subscriptionId,
            status: ele.status,
            domain: ele.domain,
            termination: subsTermination?.terminatedAt,
        };
        return newContracts;
    };

    const getFIXEDDetails = ele => {
        const descr = detailsData.offerVal.find(
            offerEle => offerEle.subscriptionId === ele.subscriptionId
        );
        const subsTermination = detailsData.subscriptionDetails.find(
            sub => sub.id === ele.subscriptionId
        );
        const cont = contracts.find(
            contractEle => contractEle.subscriptionId === ele.subscriptionId
        );
        const newContracts = {
            name: ele.msisdn
                ? `${ele.msisdn.substring(0, 3)} ${ele.msisdn.substring(
                      3,
                      6
                  )} ${ele.msisdn.substring(6)}`
                : null,
            description: descr?.description,
            startAt: cont?.startAt,
            endedAt: cont?.endedAt,
            subscriptionId: ele.subscriptionId,
            status: ele.status,
            domain: ele.domain,
            termination: subsTermination?.terminatedAt,
        };
        return newContracts;
    };

    const createContractList = () => {
        setContractInfo([]); // start with empty list
        // find all VOIP and Broadband services
        try {
            const temp_VOBB_Broadband = detailsData.msisdn.filter(ele => {
                if (
                    userStatus === ACCOUNT_STATUS.BILLABLE_EMPTY ||
                    userStatus === ACCOUNT_STATUS.UNBILLABLE
                ) {
                    if (
                        ele.domain === VOIP &&
                        ele.sipNumber !== null &&
                        ele.status === 'TERMINATED'
                    ) {
                        const newContracts = getVOIPDetails(ele);

                        setContractInfo(prev => [...prev, newContracts]);
                    }
                } else {
                    if (ele.domain === VOIP && ele.sipNumber !== null && ele.status === 'ACTIVE') {
                        const newContracts = getVOIPDetails(ele);
                        setContractInfo(prev => [...prev, newContracts]);
                    }
                }
            });
        } catch (err) {
            console.error('error while filtering VOIP & Broadband contracts');
        }

        // find all PSTN services
        try {
            const temp_PSTN = detailsData.msisdn.filter(ele => {
                if (
                    userStatus === ACCOUNT_STATUS.BILLABLE_EMPTY ||
                    userStatus === ACCOUNT_STATUS.UNBILLABLE
                ) {
                    if (ele.domain === 'PSTN' && ele.status === 'TERMINATED') {
                        const newContracts = getPSTNDetails(ele);
                        setContractInfo(prev => [...prev, newContracts]);
                    }
                } else {
                    if (ele.domain === 'PSTN' && ele.status === 'ACTIVE') {
                        const newContracts = getPSTNDetails(ele);
                        setContractInfo(prev => [...prev, newContracts]);
                    }
                }
            });
        } catch (err) {
            console.error('error while filtering PSTN contracts');
        }
        // find all TV services
        try {
            const temp_TV = detailsData.msisdn.filter(ele => {
                if (
                    userStatus === ACCOUNT_STATUS.BILLABLE_EMPTY ||
                    userStatus === ACCOUNT_STATUS.UNBILLABLE
                ) {
                    if (ele.domain === IPTV && ele.status === 'TERMINATED') {
                        const newContracts = getTVDetails(ele);
                        setContractInfo(prev => [...prev, newContracts]);
                    }
                } else {
                    if (ele.domain === IPTV && ele.status === 'ACTIVE') {
                        const newContracts = getTVDetails(ele);
                        setContractInfo(prev => [...prev, newContracts]);
                    }
                }
            });
        } catch (err) {
            console.error('error while filtering TV contracts');
        }
        // find all MOBILE services
        try {
            const temp_MOBILE = detailsData.msisdn.filter(ele => {
                if (
                    userStatus === ACCOUNT_STATUS.BILLABLE_EMPTY ||
                    userStatus === ACCOUNT_STATUS.UNBILLABLE
                ) {
                    if (
                        ele.domain === MOBILE &&
                        ele.msisdn !== null &&
                        ele.status === 'TERMINATED'
                    ) {
                        const newContracts = getMOBILEDetails(ele);
                        setContractInfo(prev => [...prev, newContracts]);
                    }
                } else {
                    if (ele.domain === MOBILE && ele.status === 'ACTIVE' && ele.msisdn !== null) {
                        const newContracts = getMOBILEDetails(ele);
                        setContractInfo(prev => [...prev, newContracts]);
                    }
                }
            });
        } catch (err) {
            console.error('error while filtering Mobile contracts');
        }
        // find all FIXED services
        try {
            const temp_FIXED = detailsData.msisdn.filter(ele => {
                if (
                    userStatus === ACCOUNT_STATUS.BILLABLE_EMPTY ||
                    userStatus === ACCOUNT_STATUS.UNBILLABLE
                ) {
                    if (ele.domain === DSL && ele.msisdn !== null && ele.status === 'TERMINATED') {
                        const newContracts = getFIXEDDetails(ele);
                        setContractInfo(prev => [...prev, newContracts]);
                    }
                } else {
                    if (ele.domain === DSL && ele.status === 'ACTIVE' && ele.msisdn !== null) {
                        const newContracts = getFIXEDDetails(ele);
                        setContractInfo(prev => [...prev, newContracts]);
                    }
                }
            });
        } catch (err) {
            console.error('error while filtering Fixed contracts');
        }
    };

    useEffect(() => {
        if (contracts?.length > 0 && detailsData?.msisdn?.length > 0) {
            createContractList();
        }
    }, [detailsData, contracts]);

    function convertDateFormat(str) {
        const strArr = str.substring(0, 10);
        const dateArr = strArr.split('-');
        const myDate = new Date(`${dateArr[0]}-${dateArr[1]}-${dateArr[2]}`);
        const myMonth = myDate.toLocaleString('default', { month: 'short' });
        return `${dateArr[2]} ${myMonth} ${dateArr[0]}`;
    }
    const arrowAction = () => {
        history.push('/account/dashboard');
        publish('openhamburger');
    };
    return (
        <>
            <Box className={classes.ContractContainerWrapper}>
                <Box className={classes.ContractDetailsContainer}>
                    <Box className={classes.headerBackArrow}>
                        <ArrowBackIcon onClick={arrowAction} />
                    </Box>
                    <Box
                        style={{
                            fontFamily: theme.typography.h4.fontFamily,
                            fontSize: theme.typography.h4.fontSize,
                        }}
                        className={classes.detailsHeaderText}
                    >
                        Contracts
                    </Box>
                    <Box className={classes.bottomLine}></Box>

                    <Grid container className={classes.ContractsWrapper}>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            // lg={width >= 1020 ? 6 : 12}
                            className='contracts-grid-column'
                        >
                            {isLoading ? (
                                <div
                                    className='contracts-loader'
                                    style={{ justifyContent: 'center' }}
                                >
                                    {isContractsError ? (
                                        <>
                                            <div className='contracts-content-loading'>
                                                Something went wrong, please try again later.
                                                <span
                                                    className='pinPuk-retry-loading'
                                                    style={{
                                                        cursor: 'pointer',
                                                        textDecoration: 'underline',
                                                    }}
                                                    onClick={() => {
                                                        setIsContractsError(false);
                                                        getDetailsData();
                                                    }}
                                                >
                                                    Retry?
                                                </span>
                                            </div>
                                        </>
                                    ) : (
                                        <Loading className='contracts-loading-animation' />
                                    )}
                                </div>
                            ) : initialUser ? (
                                <Box className={classes.inActiveUserContainer}>
                                    <MyEirInactiveUser
                                        title='Welcome to My eir!'
                                        description={description}
                                        emptyIcon={Icon}
                                    />
                                </Box>
                            ) : (
                                contractInfo.map((item, index) => {
                                    if (item.name !== null) {
                                        return (
                                            <Box
                                                style={{
                                                    backgroundColor:
                                                        index % 2 === 0 ? '#FFFFFF' : '#F3F5F9',
                                                }}
                                                className={`contracts-topic ${
                                                    contractInfo.length - 1 !== index
                                                        ? 'contacts-not-Last-child'
                                                        : ''
                                                }`}
                                            >
                                                {' '}
                                                <Box className='contracts-topic-title-change'>
                                                    <Box className='contracts-topic-title'>
                                                        {/* {item.customerOfferDTO.msisdn.substring(0, 3)} {item.customerOfferDTO.msisdn.substring(3, 6)} {item.customerOfferDTO.msisdn.substring(6)} */}
                                                        {item.description}
                                                    </Box>
                                                    <Box className='contracts-topic-content'>
                                                        {item.name}
                                                    </Box>
                                                    {width > 1020 &&
                                                    (userStatus === ACCOUNT_STATUS.BILLABLE_EMPTY ||
                                                        userStatus ===
                                                            ACCOUNT_STATUS.UNBILLABLE) ? (
                                                        <Box className='contracts-topic-content'>
                                                            <TerminatedTile
                                                                terminatedCopy='This service is no longer active.'
                                                                terminatedDate={convertDateFormat(
                                                                    item.termination
                                                                )}
                                                            />
                                                        </Box>
                                                    ) : (
                                                        ''
                                                    )}
                                                </Box>
                                                <Box className='contracts-topic-change-content'>
                                                    <Box className='contracts-topic-change'>
                                                        {/* <Typography className={classes.contract}>
                                                            Contract:
                                                        </Typography> */}
                                                        {width >= 1020 ? (
                                                            <Box className='contract-word'>
                                                                Contract:{' '}
                                                            </Box>
                                                        ) : (
                                                            ' '
                                                        )}

                                                        <Typography
                                                            component='span'
                                                            className='contracts-date'
                                                        >
                                                            {convertDateFormat(item.startAt)} -{' '}
                                                            {convertDateFormat(item.endedAt)}
                                                        </Typography>
                                                    </Box>
                                                    {width < 1020 &&
                                                    (userStatus === ACCOUNT_STATUS.BILLABLE_EMPTY ||
                                                        userStatus ===
                                                            ACCOUNT_STATUS.UNBILLABLE) ? (
                                                        <Box className='contracts-topic-content'>
                                                            <TerminatedTile
                                                                terminatedCopy='This service is no longer active.'
                                                                terminatedDate={convertDateFormat(
                                                                    item.termination
                                                                )}
                                                            />
                                                        </Box>
                                                    ) : (
                                                        ' '
                                                    )}
                                                    {(item.domain === 'Broadband & Landline' ||
                                                        item.domain === 'SA_PSTN' ||
                                                        item.domain === 'DUALPLAY_PSTN') &&
                                                    (item.fiberUan !== null ||
                                                        item.voipUan !== null) ? (
                                                        <MyEirDisplayUAN
                                                            fiberUan={item.fiberUan}
                                                            voipUan={item.voipUan}
                                                            serviceGroup={item.domain}
                                                        />
                                                    ) : (
                                                        <Box className='contracts-topic-change'></Box>
                                                    )}
                                                </Box>
                                            </Box>
                                        );
                                    }
                                })
                            )}
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </>
    );
}

export default MyEirContracts;
