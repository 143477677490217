import React from 'react';
import { Grid, Box } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import styles from './styles';

const EirChannelList = ({ icons }) => {
    const theme = useTheme();
    const classes = styles();
    console.log('logos', icons);
    return (
        <Grid container className={classes.channelListRow} spacing={2}>
            {icons.length > 0 &&
                icons?.map((ele, index) => (
                    <Grid item key={index} className={classes.channelListRowElement}>
                        <Box
                            component='img'
                            className={classes.channelIcon}
                            alt={ele?.Logo?.data?.attributes?.alternativeText || 'icon'}
                            src={`${window.origin}${ele?.Logo?.data?.attributes?.url}`}
                        />
                    </Grid>
                ))}
        </Grid>
    );
};

export default EirChannelList;
