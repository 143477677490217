import { makeStyles } from '@material-ui/core/styles';

export const useLoginFormStyle = makeStyles(theme => ({
    loginContainer: {
        maxWidth: '754px',
        marginTop: '40px',
        marginBottom: '120px',
        marginLeft: 'auto',
        marginRight: 'auto',
        // padding: '0px 30px',
        padding: '0px 15px',

        '@media(max-width: 599px)': {
            // padding: '0px 24px',
            padding: '0px 12px',
            width: '100%',
            marginTop: '48px',
            marginBottom: '0px',
        },
    },
    cardContainer: {
        width: '100%',
        height: '100%',
        paddingLeft: '32px',
        paddingRight: '30px',
        paddingTop: '24px',
        paddingBottom: '24px',

        background: theme.palette.primary.white,
        boxShadow: `0px 6px 6px -3px ${theme.palette.primary.boxShadow2Dark}, 0px 10px 14px 1px ${theme.palette.primary.boxShadow2Medium}, 0px 4px 18px 3px ${theme.palette.primary.boxShadow2Lite}`,
        borderRadius: '10px',

        '@media(max-width: 1019px)': {
            maxWidth: '570px',
            margin: '0 auto',
        },

        '@media(max-width: 599px)': {
            background: theme.palette.primary.white,
            boxShadow: `0px 6px 6px -3px ${theme.palette.primary.boxShadow2Dark}, 0px 10px 14px 1px ${theme.palette.primary.boxShadow2Medium}, 0px 4px 18px 3px ${theme.palette.primary.boxShadow2Lite}`,
            borderRadius: '10px 10px 0px 0px',

            paddingLeft: '16px',
            paddingRight: '16px',
            paddingTop: '17.5px',
            paddingBottom: '17.5px',

            maxWidth: 'calc(312 / 360 * 100vw)',
            margin: '0 auto',
        },
    },
    headerContainer: {
        '& .login-header-back-icon-div': {
            display: 'flex',
            marginLeft: '-4px',
            justifyContent: 'flex-start',

            width: '100%',
            position: 'relative',

            '@media(max-width: 599px)': {
                marginLeft: '-4px',
            },
        },

        '& .login-header-back-icon': {
            color: theme.palette.primary.sunset,
            cursor: 'pointer',

            '@media(max-width: 599px)': {
                height: '20px',
                width: '20px',
            },
        },

        '& .login-header-text': {
            display: 'flex',
            marginTop: '19px',
            minHeight: '47px',
            // fontFamily: theme.typography.body3Medium.fontFamily,
            // fontStyle: 'normal',
            // // fontWeight: 700,,
            // fontSize: theme.typography.h4.fontSize,
            // lineHeight: '26px',
            // color: '#892BA0',
            color: theme.palette.primary.heather,
            position: 'relative',

            '@media(max-width: 599px)': {
                paddingLeft: '8px',
                marginTop: '13.5px',
                minHeight: '45px',
                // fontSize:theme.typography.h4.fontSize,
            },

            '& .login-header-line': {
                position: 'absolute',
                left: '0%',
                right: '0%',
                bottom: '3px',
                border: `1px solid ${theme.palette.primary.heather80}`,
                borderTopWidth: '0px',

                '@media(max-width: 599px)': {
                    bottom: '0px',
                },
            },
        },

        '& .hide': {
            visibility: 'hidden',
        },
    },
    bodyContainer: {
        minHeight: '153px',
        paddingTop: '40px',
        paddingBottom: '40px',
        position: 'relative',

        '@media(max-width: 599px)': {
            minHeight: '295px',
            paddingTop: '38px',
            paddingLeft: '16px',
            paddingRight: '16px',
            paddingBottom: '16px',
        },

        '& .brand-password': {
            maxWidth: '376px',
            margin: '0 auto',
            textAlign: 'left',

            marginTop: '-2.19px',
            paddingBottom: '42.19px',

            '@media(max-width: 599px)': {
                marginTop: '0px',
                paddingTop: '12px',
                paddingBottom: '24px',
            },

            '& .password-brand-type': {
                fontFamily: theme.typography.subtitle1.fontFamily,
                fontStyle: 'normal',
                // fontWeight: 700,,
                fontSize: theme.typography.subtitle2.fontSize,
                lineHeight: '16px',
                color: theme.palette.primary.heather,

                paddingBottom: '4px',

                '@media(max-width: 599px)': {
                    paddingBottom: '3px',
                },
            },

            '& .password-brand-type-hide-brandName': {
                visibility: 'hidden',
            },

            '& .password-brand-number': {
                fontFamily: theme.typography.body2.fontFamily,
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: theme.typography.subtitle2.fontSize,
                lineHeight: '20px',
                color: theme.palette.primary.heather,
            },
        },

        '& .text-field': {
            position: 'relative',
            maxWidth: '376px',
            // minHeight: "79px",
            margin: '0 auto',

            '& .visibility-off, & .visibility-on': {
                position: 'absolute',
                top: '16px',
                right: '12px',
                color: theme.palette.primary.heather,
                cursor: 'pointer',
            },

            '& .text-field-name': {
                fontFamily: theme.typography.inputLabel.fontFamily,
                fontSize: theme.typography.inputLabel.fontSize,
                // fontStyle: 'normal',
                // fontWeight: 400,
                // fontSize:theme.typography.inputLabel.fontSize,
                // lineHeight: '12px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: theme.palette.primary.heather80,

                position: 'absolute',
                top: '-8px',
                left: '12px',

                maxWidth: '62px',
                background: theme.palette.primary.white,
            },

            '& .text-field-name-invalid': {
                color: theme.palette.primary.error,
            },

            '& .input-text': {
                fontFamily: theme.typography.body1.fontFamily,
                fontStyle: 'normal',
                fontWeight: theme.typography.body1.fontWeight,
                fontSize: theme.typography.body1.fontSize,
                lineHeight: theme.typography.body1.fontSize,
                display: 'flex',
                alignItems: 'center',
                letterSpacing: '0.15px',
                color: '#493553',
                width: '100%',
                minHeight: '56px',
                paddingLeft: '12px',
                paddingRight: '12px',

                border: `1px solid ${theme.palette.primary.heather80}`,
                boxSizing: 'border-box',
                borderRadius: '4px',
            },

            '& .input-text:focus': {
                outline: 'transparent',
            },

            '& .visibility-icon-padding': {
                paddingRight: '44px',
            },

            '& .text-empty': {
                outlineColor: theme.palette.primary.heather80,
                color: theme.palette.primary.heather80,
            },

            '& .text-entered': {
                border: `2px solid ${theme.palette.primary.heather80}`,
                outlineColor: theme.palette.primary.heather80,
                color: theme.palette.primary.heather80,
            },

            '& .text-entered-invalid': {
                border: `2px solid ${theme.palette.primary.error}`,
                outlineColor: theme.palette.primary.error,
                color: theme.palette.primary.heather80,
            },

            '& .invalid-email-format, & .username-not-found': {
                fontFamily: theme.typography.inputLabel.fontFamily,
                // fontStyle: 'normal',
                // fontWeight: 400,
                // fontSize:theme.typography.inputLabel.fontSize,
                // lineHeight: '20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                letterSpacing: '0.4px',
                color: theme.palette.primary.error,
                marginTop: '3px',
            },

            '& .error-and-reset': {
                display: 'flex',
                justifyContent: 'space-between',
            },

            '& .username-not-found': {
                flexGrow: '1',
                paddingRight: '20px',
                textAlign: 'left',
            },

            '& .reset-password': {
                flexGrow: '0',

                fontFamily: theme.typography.body2.fontFamily,
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: theme.typography.inputLabel.fontSize,
                lineHeight: '20px',
                letterSpacing: '0.4px',
                textDecorationLine: 'underline',
                color: theme.palette.primary.heather,

                marginTop: '3px',
                cursor: 'pointer',
            },

            '& .forgotten-input-text': {
                // fontFamily: theme.typography.body2.fontFamily,
                // fontStyle: 'normal',
                // fontWeight: 400,
                // fontSize:theme.typography.subtitle2.fontSize,
                // lineHeight: '20px',
                display: 'flex',
                alignItems: 'center',
                letterSpacing: '0.4px',
                textDecorationLine: 'underline',
                color: theme.palette.primary.heather80,
                cursor: 'pointer',
                marginTop: '3px',
            },

            '& .remember-me-next-time': {
                textAlign: 'left',
                marginTop: '26px',
                display: 'flex',
                alignItems: 'center',

                '& .remember-me': {
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',

                    '& .checkbox-unchecked, & .checkbox-checked': {
                        width: '22px',
                        height: '22px',
                        color: theme.palette.primary.heather80,
                    },

                    '& p': {
                        fontFamily: theme.typography.body2.fontFamily,
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: theme.typography.inputLabel.fontSize,
                        lineHeight: '20px',
                        color: theme.palette.primary.heather,

                        marginBottom: '0px',
                        marginLeft: '7px',
                    },
                },
            },
        },

        '& .terminateTile': {
            display: 'flex',
            justifyContent: 'center',
            marginTop: '32px',
        },

        '& .accounts-field': {
            maxWidth: '377px',
            paddingTop: '19px',
            margin: '0 auto',
            textAlign: 'left',

            '@media(max-width: 599px)': {
                paddingTop: '8px',
            },

            '& .accounts-header': {
                fontFamily: theme.typography.subtitle1.fontFamily,
                fontStyle: 'normal',
                // fontWeight: 700,,
                fontSize: theme.typography.body1.fontSize,
                lineHeight: '24px',
                color: theme.palette.primary.heather,

                '@media(max-width: 599px)': {
                    paddingLeft: '8px',
                },
            },

            '& .accounts-list': {
                marginTop: '27px',

                '@media(max-width: 599px)': {
                    marginTop: '18px',
                },

                '& .accounts-list-item': {
                    position: 'relative',
                    padding: '15.81px 8px 9.19px 8px',
                    marginBottom: '1px',
                    boxShadow: `0px 1px 0px ${theme.palette.primary.boxShadow2Dark}`,
                    cursor: 'pointer',

                    '& .account-type': {
                        fontFamily: theme.typography.subtitle1.fontFamily,
                        fontStyle: 'normal',
                        // fontWeight: 700,,
                        fontSize: theme.typography.subtitle2.fontSize,
                        lineHeight: '16px',
                        color: theme.palette.primary.heather,

                        paddingBottom: '4px',
                    },

                    '& .account-type-hide-brandName': {
                        display: 'none',
                    },

                    '& .account-number': {
                        fontFamily: theme.typography.body2.fontFamily,
                        fontStyle: 'normal',
                        fontWeight: 500,
                        fontSize: theme.typography.subtitle2.fontSize,
                        lineHeight: '20px',
                        color: theme.palette.primary.heather,
                    },

                    '& .account-arrow-right': {
                        position: 'absolute',
                        color: theme.palette.primary.sunset,
                        width: '14.8px',

                        top: '20px',
                        right: '5px',
                    },
                },

                '& .accounts-list-item-hide-brandName': {
                    display: 'flex',
                    alignItems: 'center',
                    minHeight: '65px',
                },

                '& .accounts-list-item:hover': {
                    boxShadow: `0px 2px 1px ${theme.palette.primary.sunset}`,
                    padding: '15.81px 8px 8.19px 8px',
                    marginBottom: '2px',
                },
            },
        },
    },
    reCaptchaError: {
        margin: '0 auto',
        display: 'flex',
        '@media(min-width:600px)': {
            alignItems: 'center',
            justifyContent: 'center',
        },
    },
    reCaptcha: {
        paddingTop: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('xs')]: {
            paddingTop: '39px',
            transform: 'scale(0.77)',
            transformOrigin: '0 0',
        },
        '@media(min-width:600px) and (max-width:1019px)': {
            paddingTop: '39px',
            marginLeft: '75px',
        },
    },
    footerContainer: {
        minHeight: '99px',
        // '& .cta': {
        //     width: '114px',
        // },
        '@media(max-width: 599px)': {
            minHeight: '93.5px',
            paddingLeft: '8px',
            paddingRight: '8px',
        },

        '& .footer-cta': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '0 auto',
            width: '183px',

            borderRadius: '4100px',
            fontFamily: theme.typography.subtitle1.fontFamily,
            fontStyle: 'normal',
            fontWeight: theme.typography.subtitle1.fontWeight,
            fontSize: theme.typography.subtitle1.fontSize,
            lineHeight: theme.typography.subtitle1.lineHeight,
            textAlign: 'center',

            '@media(max-width: 599px)': {
                width: '100%',
            },
        },

        '& .primary-cta': {
            minHeight: '42px',
            marginBottom: '3px',
            borderColor: 'transparent',
        },

        '& .secondary-cta': {
            minHeight: '41px',
            color: theme.palette.primary.sunset,
            width: 'fit-content',
            '&:hover': {
                color: theme.palette.primary.sunset30,
            },
        },

        '& .cta-diabled': {
            // background: '#ECECEC',
            // color: '#888B8D',

            '@media(max-width: 599px)': {
                marginBottom: '4px',
            },
        },

        '& .cta-enabled': {
            background: theme.palette.primary.sunset,
            boxShadow: `0px 3px 1px -2px ${theme.palette.primary.boxShadow2Dark}, 0px 2px 2px ${theme.palette.primary.boxShadow2Dark}, 0px 1px 5px ${theme.palette.primary.boxShadow2Dark}`,
            color: theme.palette.primary.white,

            '&:hover': {
                background: theme.palette.primary.sunset,
            },

            '@media(max-width: 599px)': {
                minHeight: '41px',
                marginBottom: '5px',
            },
        },
    },
    footerContainer1: {
        '@media(min-width: 600px)': {
            minHeight: '56px',
        },
    },
    footerContainerNone: {
        '@media(min-width: 600px)': {
            minHeight: '0px',
            marginTop: '-16px',
        },
    },
}));
