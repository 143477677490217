import React, { useState, Fragment, useEffect } from 'react';
import { BrowserRouter, Route, Link } from 'react-router-dom';
import { Controller, useForm, FormContext } from 'react-hook-form';
import errormesages from './errormessages';
import MyEirTogglebtn from '../my-eir-toggleBtn/MyEirtogglebtn';
import { useRegisterFormStyle } from './useRegisterFormStyle';
import MyEirWhyMyEir from '../my-eir-whymyeir/MyEirWhyMyEir';
import MyEirCustomerDetails from './MyEirCustomerDetails';
import { MARKETING_PREFERENCES } from './constants';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import MyEirRegisterMobileNumber from './MyEirRegisterMobileNumber';
import MyEirAddressDetails from './MyEirAddressDetails';
import MyEirSecurityDetails from './MyEirSecurityDetails';
import { MyEirDirectoryPreference } from './MyEirDirectoryPreference';
import { MyEirMarketingPreferences } from './MyEirMarketingPreferences';
import MyEirCreatePasswordContainer from '../my-eir-createPassword/MyEirCreatePasswordContainer';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import ENDPOINT from '../graphql/GraphQLConstants';
import { useApolloClient } from '@apollo/client';
import MyEirForgotPasswordResponse from '../my-eir-forgotPassword/MyEirForgotPasswordResponse';
import {
    Grid,
    TextField,
    Container,
    Button,
    Icon,
    Paper,
    FormControlLabel,
    Select,
    Dialog,
    Typography,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
} from '@material-ui/core';
const theme = createMuiTheme({
    typography: {
        textTransform: 'none',
        textAlign: 'left',
        letterSpacing: '0px',
        fontFamily: 'gt-walsheim-regular !important',
        opacity: 1,
        fontSize: '16px',
    },
    palette: {
        primary: {
            main: '#892BA0 ',
        },
    },
    overrides: {
        MuiInput: {
            input: {
                '&::placeholder': {
                    color: 'gray',
                },
                color: 'white', // if you also want to change the color of the input, this is the prop you'd use
            },
        },
        MuiFormControlLabel: {
            label: {
                '@media (max-width: 1024px)': {
                    fontSize: '14px',
                },
            },
        },
        MuiFormLabel: {
            asterisk: {
                color: '#f44336',
            },
        },
        MuiSelect: {
            icon: {
                color: '#892BA0',
            },
        },
        MuiTextField: {
            root: {
                fontSize: '16px',
                color: 'black',
                '@media (max-width: 1024px)': {
                    fontSize: '14px',
                },
            },
        },

        MuiInputLabel: {
            root: {
                color: 'black',
                fontSize: '16px',
                '@media (min-width:768px) and (max-width: 1024px)': {
                    fontSize: '14px',
                },
                '@media (min-width:322px) and (max-width: 415px)': {
                    fontSize: '12px',
                },
                '@media (min-width:281px) and (max-width: 321px)': {
                    fontSize: '10px',
                },
                '@media (min-width:279px) and (max-width: 280px)': {
                    fontSize: '8px',
                },
            },
            shrink: {
                fontSize: '16px',
                '@media (min-width:320px) and (max-width: 541px)': {
                    fontSize: '14px',
                },
                '@media (min-width:280px) and (max-width: 321px)': {
                    fontSize: '12px',
                },
            },
        },
    },
});

function MyEirRegisterForm() {
    const classes = useRegisterFormStyle();
    const DATE_FORMAT = 'DD/MM/YYYY';
    const graphql = useApolloClient();
    const { errormessage, validation } = errormesages;
    const [directoryPreference, setDirectoryPreference] = useState('EXDIRECTORY');
    const [contactGroupList, setContactGroupList] = useState(MARKETING_PREFERENCES);
    const [mobileNumber, setMobileNumber] = useState('');
    const [regFormSection, setRegFormSection] = useState(false);
    const [createPwd, setcreatePwd] = useState(false);
    const [formSuccessMsg, setformSuccessMsg] = useState('');
    const methods = useForm({ mode: 'onChange' });
    const [emailAddress, setEmailAddress] = useState('');
    const [emailConfirmationDisplay, setEmailConfirmationDisplay] = useState(false);
    const [alreadyRegistered, setalreadyRegistered] = useState(false);
    const [registerStatus, setregisterStatus] = useState(false);
    const [mailConfirmation, setMailConfirmation] = useState(false);
    const [dialogOpen, setDialogOpen] = React.useState(true);
    // const { register, errors, control, setValue, setError, clearError, handleSubmit } = useForm({
    //     mode: 'onChange',
    // });
    const handleClose = () => {
        setDialogOpen(false);
        setregisterStatus(false);
        // window.location.href = '/prepay/register';
    };
    const isEirCodeConfirmed = () => {
        setformSuccessMsg('');
        setMailConfirmation(false);
        let yourAddress = methods.getValues('deliveryAddressyourAddress');
        let eirCodeConfirmed = true;
        let billingAddressline = methods.getValues('billingAddressyourAddress');
        if (methods.getValues('sameDeliveryAddress')) {
            if (billingAddressline === undefined) {
                methods.setError(
                    'billingAddressEirCode',
                    'manual',
                    errormessage.eircodeNotConfirmed
                );
                eirCodeConfirmed = false;
                var billingEirCode = document.querySelector('#billingAddressEirCode');
                billingEirCode.scrollIntoView();
            }
        }
        if (!methods.getValues('sameDeliveryAddress')) {
            let deliveryAddressline = methods.getValues('deliveryAddressyourAddress');

            if (deliveryAddressline === undefined) {
                methods.setError(
                    'deliveryAddressEirCode',
                    'manual',
                    errormessage.eircodeNotConfirmed
                );
                eirCodeConfirmed = false;
                var deliveryEirCode = document.querySelector('#deliveryAddressEirCode');
                deliveryEirCode.scrollIntoView();
            }
        }

        return eirCodeConfirmed;
    };
    const scrollBackToTop = () => {
        let scrollStep = -window.pageYOffset / (1000 / 15);
        let scrollInterval = setInterval(function () {
            if (window.pageYOffset != 0) {
                window.scrollBy(0, scrollStep);
            } else clearInterval(scrollInterval);
        }, 15);
    };
    const onSubmit = async data => {
        let isError = false;
        setformSuccessMsg('');
        // setregisterStatus(true);
        setEmailConfirmationDisplay(false);
        // console.log('data register', data);
        const permissionGroupList = JSON.parse(data.permissionGroupList);
        const birthDate = moment(data.birthDate, DATE_FORMAT).format('YYYY-MM-DD');
        setEmailAddress(data.emailAddress);
        if (isEirCodeConfirmed()) {
            // console.log(data);
            let customerDetailForRegistrationInput;
            let deliveryaddressLine1;
            let deliveryaddressLine2;
            let deliveryaddressLine3;
            let deliveryTown;
            let deliveryCounty;
            let deliveryEirCode;
            // if delivery address same as billing address
            if (data.sameDeliveryAddress) {
                deliveryaddressLine1 = data.billingAddressaddressLine1;
                deliveryaddressLine2 = data.billingAddressaddressLine2;
                deliveryaddressLine3 = data.billingAddressaddressLine3;
                deliveryTown = data.billingAddresstown;
                deliveryCounty = data.billingAddresscounty;
                deliveryEirCode = data.billingAddressEirCode;
            } else {
                deliveryaddressLine1 = data.deliveryAddressaddressLine1;
                deliveryaddressLine2 = data.deliveryAddressaddressLine2;
                deliveryaddressLine3 = data.deliveryAddressaddressLine3;
                deliveryTown = data.deliveryAddresstown;
                deliveryCounty = data.deliveryAddresscounty;
                deliveryEirCode = data.deliveryAddressEirCode;
            }
            customerDetailForRegistrationInput = {
                owner: {
                    email: data.emailAddress,
                    mobileNumber: data.mobileNumber,
                    person: {
                        birthDate: birthDate,
                        firstName: data.firstName,
                        lastName: data.lastName,
                    },
                },
                deliveryAddress: {
                    addressLine1: deliveryaddressLine1,
                    addressLine2: deliveryaddressLine2,
                    addressLine3: deliveryaddressLine3,
                    county: deliveryCounty,
                    eircode: deliveryEirCode.replace(/ /g, ''),
                    town: deliveryTown,
                },
                billingAddress: {
                    addressLine1: data.billingAddressaddressLine1,
                    addressLine2: data.billingAddressaddressLine2,
                    addressLine3: data.billingAddressaddressLine3,
                    county: data.billingAddresscounty,
                    eircode: data.billingAddressEirCode.replace(/ /g, ''),
                    town: data.billingAddresstown,
                },

                contactPermission: {
                    allowThirdParty: permissionGroupList[2].enabled,
                    permissions: [
                        {
                            name: 'An active customer',

                            permissionGroup: 'ACTIVE_CUSTOMER',
                            permissions: [
                                {
                                    enabled: permissionGroupList[0].preferenceList[0].enabled,
                                    name: 'Email',
                                    permission: 'ALLOW_EMAIL_CONTACT',
                                },
                                {
                                    enabled: permissionGroupList[0].preferenceList[1].enabled,
                                    name: 'SMS',
                                    permission: 'ALLOW_SMS_CONTACT',
                                },
                                {
                                    enabled: permissionGroupList[0].preferenceList[2].enabled,
                                    name: 'Phone',
                                    permission: 'ALLOW_PHONE_CONTACT',
                                },
                                {
                                    enabled: permissionGroupList[0].preferenceList[3].enabled,
                                    name: 'FOTS',
                                    permission: 'ALLOW_FOTS_CONTACT',
                                },
                                {
                                    enabled: permissionGroupList[0].preferenceList[4].enabled,
                                    name: 'Direct mail',
                                    permission: 'ALLOW_DIRECT_MAIL_CONTACT',
                                },
                            ],
                        },
                        {
                            name: 'No longer a customer',
                            permissionGroup: 'NO_LONGER_CUSTOMER',

                            permissions: [
                                {
                                    enabled: permissionGroupList[1].preferenceList[0].enabled,
                                    name: 'Email',
                                    permission: 'ALLOW_EMAIL_CONTACT',
                                },
                                {
                                    enabled: permissionGroupList[1].preferenceList[1].enabled,
                                    name: 'SMS',
                                    permission: 'ALLOW_SMS_CONTACT',
                                },
                                {
                                    enabled: permissionGroupList[1].preferenceList[2].enabled,
                                    name: 'Phone',
                                    permission: 'ALLOW_PHONE_CONTACT',
                                },
                                {
                                    enabled: permissionGroupList[1].preferenceList[3].enabled,
                                    name: 'FOTS',
                                    permission: 'ALLOW_FOTS_CONTACT',
                                },
                                {
                                    enabled: permissionGroupList[1].preferenceList[4].enabled,
                                    name: 'Direct mail',
                                    permission: 'ALLOW_DIRECT_MAIL_CONTACT',
                                },
                            ],
                        },
                    ],
                },
                securityQuestion: {
                    questionCode: data.securityQuestion.toString(),
                    response: data.securityAnswer,
                },
            };
            try {
                const res = await graphql.mutate({
                    mutation: ENDPOINT.UPDATE_REGISTER_DETAILS,
                    variables: {
                        msisdn: mobileNumber,
                        customerDetailForRegistrationInput: customerDetailForRegistrationInput,
                    },
                });
                // console.log('success', res.data.registration);

                if (res.data.registration) {
                    // console.log('success');
                    setformSuccessMsg(res.data.registration);
                    setMailConfirmation(true);
                    setRegFormSection(false);
                    setEmailConfirmationDisplay(false);
                }
            } catch (err) {
                setregisterStatus(true);
                setDialogOpen(true);
                // console.log(err.message);
                setformSuccessMsg('');
                // setMailConfirmation(false);
                // setRegFormSection(false);
                setEmailConfirmationDisplay(false);
            }
            scrollBackToTop();
        }
    };
    useEffect(() => {
        var arrow = document.createElement('SPAN');
        arrow.id = 'triangleSpan';
        arrow.classList.add('arrow-down');
        // document.querySelector('#triangleSpan').remove();
        for (let i = 0; i < document.querySelectorAll('.toggleBtn').length; i++) {
            document.querySelectorAll('.toggleBtn')[i].classList.remove('ActiveBtn');
        }
        document.querySelector('.toggleBtn.secondary').classList.add('ActiveBtn');
        document.querySelector('.toggleBtn.secondary').parentNode.appendChild(arrow);
    }, []);
    const setMobileNumberValue = response => {
        setMobileNumber(response);
        console.log('responnd', response);
    };
    const setFormstatus = response => {
        setRegFormSection(response);
    };
    const setEmailStatus = response => {
        setEmailConfirmationDisplay(response);
    };
    const setemailValue = response => {
        setEmailAddress(response);
    };

    const setalreadyRegisteredResponse = response => {
        setalreadyRegistered(response);
    };

    const redirectToReset = () => {
        window.location.href = '/prepay/forgot-password';
    };

    return (
        <Fragment>
            <ThemeProvider theme={theme}>
                {registerStatus && (
                    <Dialog
                        PaperProps={{ className: classes.RegdialogPaper }}
                        open={dialogOpen}
                        onClose={handleClose}
                        aria-labelledby='alert-dialog-title'
                        aria-describedby='alert-dialog-description'
                        className={classes.eirDialogContainer}
                    >
                        <DialogTitle id='alert-dialog-title'></DialogTitle>
                        <DialogContent className={classes.eirDialogContent}>
                            <DialogContentText
                                id='alert-dialog-description'
                                className={classes.notification}
                            >
                                <h2 className={classes.eirDialogTitle}>Something went wrong.</h2>
                                Please try again.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                align='center'
                                className={classes.eirDialog}
                            >
                                <Button onClick={handleClose} className={classes.pupleBtn}>
                                    Try again
                                </Button>
                            </Grid>
                        </DialogActions>
                    </Dialog>
                )}
                <div className={classes.RegisterForm}>
                    <Container>
                        <div className={regFormSection ? 'regform' : 'formHolder'}>
                            {!regFormSection && (
                                <Paper className={classes.paper}>
                                    <div className='formLogoSection'>
                                        <img src='/opencms/export/system/modules/my.portal.opencms/myeir_ion/images/my-eir-logo-white-small.png'></img>
                                    </div>

                                    {mailConfirmation && (
                                        <MyEirForgotPasswordResponse
                                            emailAddress={emailAddress}
                                            formSuccessMsg={formSuccessMsg}
                                        />
                                    )}
                                    {emailConfirmationDisplay && (
                                        <MyEirForgotPasswordResponse
                                            emailAddress={emailAddress}
                                            emailConfirmationDisplay={emailConfirmationDisplay}
                                        />
                                    )}
                                    {alreadyRegistered && (
                                        <Fragment>
                                            <p>Good news!</p>
                                            <p
                                                className='notification'
                                                style={{ textAlign: 'center' }}
                                            >
                                                It looks like you are already registered. If you are
                                                having trouble logging in, <br />
                                                why not reset your password?
                                            </p>

                                            <Grid item xs={12} sm={12} md={12}>
                                                <div className='mobileFloat'>
                                                    <Button
                                                        className='loginBtn'
                                                        style={{ marginTop: '0px' }}
                                                        onClick={redirectToReset}
                                                    >
                                                        Reset password
                                                        <span className='fontLarge'></span>
                                                    </Button>
                                                </div>
                                            </Grid>
                                            <div className='forRegform'>
                                                <a className='link' href='/prepay/login'>
                                                    <span className='arrow-right'></span>
                                                    Back to log in
                                                </a>
                                            </div>
                                        </Fragment>
                                    )}
                                    {!mailConfirmation &&
                                        !emailConfirmationDisplay &&
                                        !alreadyRegistered && (
                                            <Fragment>
                                                {' '}
                                                <div className='loginContainer'>
                                                    <MyEirTogglebtn />
                                                </div>
                                                <MyEirRegisterMobileNumber
                                                    getMobileNumber={setMobileNumberValue}
                                                    getFormstatus={setFormstatus}
                                                    getEmailStatus={setEmailStatus}
                                                    getemailValue={setemailValue}
                                                    getalreadyRegistered={
                                                        setalreadyRegisteredResponse
                                                    }
                                                />
                                            </Fragment>
                                        )}
                                </Paper>
                            )}
                            {!mailConfirmation && regFormSection && (
                                <FormContext {...methods}>
                                    <form
                                        onSubmit={methods.handleSubmit(onSubmit)}
                                        className='regcontainer'
                                    >
                                        <h1 className='mobileShow'>Register as a new customer</h1>
                                        <Paper className={classes.paper + ' ' + 'paddingTop'}>
                                            <h1 className='mobileHide'>
                                                Register as a new customer
                                            </h1>
                                            <MyEirCustomerDetails />
                                            <MyEirAddressDetails />
                                        </Paper>
                                        <Paper className={classes.paper + ' ' + 'paddingTop'}>
                                            <MyEirSecurityDetails />
                                            {contactGroupList != '' && (
                                                <MyEirMarketingPreferences
                                                    contactGroupList={contactGroupList}
                                                    setContactGroupList={setContactGroupList}
                                                />
                                            )}
                                            {/* <MyEirDirectoryPreference
                                                directoryPreference={directoryPreference}
                                                setDirectoryPreference={setDirectoryPreference}
                                            /> */}
                                            {/* <Grid container spacing={2}>
                                                {formSuccessMsg != '' && (
                                                    <p className='errorMessage'>{formSuccessMsg}</p>
                                                )}
                                            </Grid> */}

                                            <Grid item xs={12} sm={12} md={12}>
                                                <div className='mobileFloat'>
                                                    <Button
                                                        onClick={methods.handleSubmit(onSubmit)}
                                                        type='submit'
                                                        className='loginBtn'
                                                    >
                                                        Register
                                                        <span className='fontLarge'></span>
                                                    </Button>
                                                </div>
                                            </Grid>
                                        </Paper>
                                    </form>
                                </FormContext>
                            )}
                        </div>
                    </Container>
                </div>

                <MyEirWhyMyEir />
            </ThemeProvider>
        </Fragment>
    );
}

export default MyEirRegisterForm;
