import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    mainNavContainer: {
        width: '100%',
        position: 'relative',
        paddingTop: ' 20px',
    },

    innerNavbar: {
        position: 'fixed',
        zIndex: '100',
        width: '100%',
        maxHeight: '68px',
        bottom: '0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: theme.palette.primary.heather,
        boxShadow: `0px -4px 17.9357px ${theme.palette.primary.boxShadow2Dark}`,
        borderRadius: '10px 10px 0 0',
        backdropFilter: 'blur(5px)',

        [theme.breakpoints.up('sm')]: {
            paddingLeft: '62px',
            paddingRight: '62px',
            justifyContent: 'space-between',
            borderRadius: '10px 10px 20px 20px',
        },
    },

    imgIcon: {
        width: '100%',
        height: '100%',
    },
    itemIcon: {
        width: '100px',
        height: '60px',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'flex-start',
        marginTop: '10.4px',
        justifyContent: 'center',
    },
    option: {
        fontSize: theme.typography.inputLabel.fontSize,
        fontWeight: 500,
        fontFamily: theme.typography.body2.fontFamily,
        color: theme.palette.primary.backgroundColor2,
        lineHeight: '12px',
        marginTop: '5.5px',
        '@media (max-width: 300px)': {
            fontSize: '8px',
            marginTop: '4.5px',
        },
    },
    icon: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        color: 'transparent',
    },
    black: {
        width: '60px',
        height: '60px',
        // width: '56px',
        // height: '56px',
        borderRadius: '50%',
        background: theme.palette.primary.backgroundColor2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '6px',
        // marginTop:'8px',
        '@media (max-width:300px)': {
            width: '43px',
            height: '53px',
            // width: '36px',
            // height: '53px',
        },
    },
    blackWithOffers: {
        width: '56px',
        height: '56px',
        borderRadius: '50%',
        background: theme.palette.primary.backgroundColor2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '6px',
        // marginTop:'8px',
        '@media (max-width:300px)': {
            width: '36px',
            height: '53px',
        },
    },
    iconMT: {
        // marginTop: '5px',
    },
    dark: {
        color: theme.palette.primary.heather,
        marginTop: '3px',
    },
    active: {
        width: '76px',
        height: '76px',
        borderRadius: '50%',
        background: 'linear-gradient(180deg, rgba(104, 27, 123, 0.64) 0%, #470A68 100%)',
        background: theme.palette.primary.heather,
        backdropFilter: 'blur(5px)',
        marginTop: '-9px',
        transition: '0.3s',
        display: 'flex',
        alignItems: 'center',
        '@media (max-width: 300px)': {
            width: '70px',
            height: '70px',
            // width: '79px',
            // height: '70px',
        },
    },
    activeWithOffer: {
        width: '76px',
        height: '76px',
        borderRadius: '50%',
        background: 'linear-gradient(180deg, rgba(104, 27, 123, 0.64) 0%, #470A68 100%)',
        background: theme.palette.primary.heather,
        backdropFilter: 'blur(5px)',
        marginTop: '-9px',
        transition: '0.3s',
        display: 'flex',
        alignItems: 'center',
        '@media (max-width: 300px)': {
            width: '79px',
            height: '70px',
        },
        '@media (min-width: 301px) and (max-width: 600px)': {
            width: '94px',
            height: '72px',
        },
    },
    badgeIcon: {
        '&.MuiBadge-badge': {
            width: '15px !important',
            height: '15px',
        },
    },
}));

export default useStyles;
