import React, { useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import info from '../redesign-5.2/images/infoBlue.png';
import cancel from '../redesign-5.2/images/cancelGrey.png';
import noticeStyles from './noticeStyles';
import { clearAllCookies } from '../redesign-5.2/redesign.api';

const NoticeOfMultipleAccount = () => {
    const classes = noticeStyles();
    const [isCanceled, setIsCanceled] = useState(false);

    const handlePortal = () => {
        const email = sessionStorage.getItem('LoggedinUser');
        const hasION = sessionStorage.getItem('hasION');
        const token = sessionStorage.getItem('access_token');

        const baseUrl = '/bundles/login?email=' + email;
        const isIonValue = JSON.parse(hasION);

        const contextVal = {
            isIon: isIonValue,
            isNoticeClicked: true,
        };
        const context = encodeURIComponent(JSON.stringify(contextVal));
        clearAllCookies();
        sessionStorage.clear();
        window.location.href = `${baseUrl}&context=${context}`;
    };

    const closeAction = () => {
        setIsCanceled(true);
    };
    return (
        <>
            {!isCanceled && (
                <Box className={classes.noticeContainer}>
                    <Box className={classes.noticeElements}>
                        <Box component='img' src={info} alt='info' className={classes.info} />
                        <Box className={classes.text}>
                            To access your other eir account, please login
                            <Typography
                                component='span'
                                className={classes.textBold}
                                onClick={handlePortal}
                            >
                                here
                            </Typography>
                        </Box>
                        <Box
                            component='img'
                            src={cancel}
                            alt='cancel'
                            className={classes.cancel}
                            onClick={closeAction}
                        />
                    </Box>
                </Box>
            )}
        </>
    );
};

export default NoticeOfMultipleAccount;
