import { Box, Typography } from '@material-ui/core';
import React from 'react';
import TerminatedTile from '../../reusable-components/TerminatedTile';
import useStyles from './myServicesStyles';
import { ACCOUNT_STATUS } from '../../../components/myeirconstant/myEirConstant';

function ServicesInfo({ title, offerName, number, errorMessage, contractType }) {
    const classes = useStyles();
    const userStatus = sessionStorage.getItem('Account_status');
    let input = number;
    let result =
        input !== null
            ? input
                  .replace(/null/g, '')
                  .replace(/\s*\|\s*/g, '|')
                  .replace(/\|+/g, ' | ')
                  .replace(/^\s*\|\s*|\s*\|\s*$/g, '')
                  .trim()
            : '';
    let array = result.split('|');
    let updatedArray = array.map(item => item.replace(/-/g, ' '));
    let uniqueArray = updatedArray
        .map(value => value.trim())
        .filter((value, index, self) => {
            return value !== '' && self.indexOf(value) === index;
        });

    let finalResult = uniqueArray.join(' | ');

    return (
        <>
            <Box className={classes.typeWithTitle}>
                <Typography variant='h6' className={classes.mainHeading}>
                    {' '}
                    {title}{' '}
                </Typography>
                {contractType && <Box className={classes.type}> {contractType} </Box>}
            </Box>
            <Typography variant='subtitle2' className={classes.subHeading}>
                {offerName}
            </Typography>
            {errorMessage != '' ? (
                <Typography className={classes.inactiveErrorText}>{errorMessage}</Typography>
            ) : (
                <Typography className={classes.phoneNumber}>{finalResult}</Typography>
            )}
            {userStatus === ACCOUNT_STATUS.BILLABLE_EMPTY ||
            userStatus === ACCOUNT_STATUS.UNBILLABLE ? (
                <TerminatedTile terminatedCopy='This service is no longer active' />
            ) : (
                ''
            )}
        </>
    );
}

export default ServicesInfo;
