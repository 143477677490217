import { useState, useEffect } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const useRecaptchaV3 = action => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [recaptchaV3Token, setRecaptchaV3Token] = useState(null);

    const handleReCaptchaVerify = async () => {
        if (!executeRecaptcha) {
            console.log('execute recaptcha not yet available');
            return;
        }
        try {
            const useToken = await executeRecaptcha(action);
            setRecaptchaV3Token(useToken);
        } catch (error) {
            console.error('Error executing recaptcha:', error);
        }
    };

    useEffect(() => {
        console.log('recaptcha hook initialized');

        handleReCaptchaVerify();
    }, [executeRecaptcha, action]);

    return { recaptchaV3Token, executeRecaptcha };
};

export default useRecaptchaV3;
