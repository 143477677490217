import React, { useState, useEffect } from 'react';
import { Button, Typography, Modal, Box } from '@material-ui/core';
import portalStyles from './portalStyles';
import smileyIcon from '../images/smileyIcon.png';
function PortalResetPasswordScreen({ isOpen, email }) {
    const styles = portalStyles();
    const [openReset, setOpenReset] = useState(isOpen);

    const handleOpenReset = () => {
        setOpenReset(true);
    };
    const handleCloseReset = () => {
        setOpenReset(false);
    };
    return (
        <Modal open={openReset} onClose={handleCloseReset}>
            <Box className={styles.modalContainer}>
                <Box className={styles.container}>
                    <Box className={styles.topContainer}>
                        <Typography component='p' className={styles.heading}>
                            Reset password
                        </Typography>
                    </Box>
                    <Box className={styles.innerContainer}>
                        <Box className={styles.img}>
                            <Box
                                component='img'
                                src={smileyIcon}
                                alt='smile'
                                className={styles.smile}
                            />
                        </Box>
                        <Typography component='p' className={styles.title}>
                            We’ve just sent you an email.
                        </Typography>
                        <Typography component='p' className={styles.desc}>
                            Please check your email and follow the instructions to reset your
                            password
                        </Typography>
                        <Typography component='p' className={styles.email}>
                            An email has been sent to
                            <br />
                            <Typography component='span' className={styles.user}>
                                {email}
                            </Typography>
                        </Typography>
                        <Typography component='p' className={styles.spam}>
                            Don't forget to check spam email!
                        </Typography>

                        <Box className={styles.close}>
                            <a href='/'>
                                <button className={styles.cta}>Close</button>
                            </a>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
}

export default PortalResetPasswordScreen;
