import { makeStyles } from '@material-ui/core/styles';
export const useMyAccountStyle = makeStyles(theme => ({
    MyAccountWrapper: {
        // background: "purple",

        marginTop: '40px',
        marginBottom: '40px',
        paddingLeft: '30px',
        paddingRight: '30px',

        '@media(max-width: 1019px)': {
            paddingLeft: '30px',
            paddingRight: '30px',
        },

        '@media(max-width: 599px)': {
            paddingLeft: '24px',
            paddingRight: '24px',
            marginTop: '0px',
            marginBottom: '36px',
        },
        '& .api-error': {
            '& span': {
                textDecoration: 'underline',
            },
        },
        '& .accountContainer': {
            maxWidth: '1144px',
            // background: "#FFFFFF",
            boxShadow: `0px 6px 6px -3px ${theme.palette.primary.boxShadow2Dark}, 0px 10px 14px 1px ${theme.palette.primary.boxShadow2Medium}, 0px 4px 18px 3px ${theme.palette.primary.boxShadow2Lite}`,
            borderRadius: '10px',
            margin: 'auto',

            '@media(max-width: 1019px)': {
                maxWidth: '570px',
            },

            '@media(max-width: 599px)': {
                maxWidth: 'calc(312 / 360 * 100vw)',
            },
        },

        '& .account-accordion': {
            borderRadius: '10px',
            background: theme.palette.primary.white,
        },

        '& .account-accordion-expanded': {
            paddingBottom: '13px',

            [theme.breakpoints.down('xs')]: {
                minHeight: '429px',
                paddingBottom: '43px',
            },
        },

        '& .account-header': {
            fontFamily: theme.typography.body3Medium.fontFamily,
            fontStyle: 'normal',
            // fontWeight: 700,
            fontSize: theme.typography.h3.fontSize,
            lineHeight: '38px',
            display: 'flex',
            alignItems: 'center',
            textAlign: 'center',
            letterSpacing: '0.25px',
            color: theme.palette.primary.heather,

            minHeight: '120px',
            paddingTop: '41px',
            alignItems: 'flex-start',
            // paddingTop: "38px",

            '@media(max-width: 1019px)': {
                paddingTop: '21.25px',
            },
            '@media(max-width: 599px)': {
                paddingTop: '16px',
                fontSize: theme.typography.h4.fontSize,
                lineHeight: '32px',
            },
        },

        '& .account-header-block': {
            display: 'flex',
            // alignItems: "center",

            '@media(min-width: 1020px)': {
                paddingRight: '6px',
            },
            '@media(max-width: 599px)': {
                paddingTop: '2px',
            },
        },

        '& .account-header-block-img-div': {
            display: 'flex',
            alignItems: 'center',

            marginRight: '23px',

            '@media(max-width: 599px)': {
                display: 'none',
            },
        },

        '& .account-header-block-img': {
            display: 'block',

            '@media(max-width: 599px)': {
                display: 'none',
            },
        },

        '& .account-expand-icon': {
            width: '36px',
            height: '36px',
            color: theme.palette.primary.sunset,
        },

        '& .account__header-expandIcon': {
            margin: '0px',
            padding: '0px',

            '@media(max-width: 1019px)': {
                display: 'none',
            },

            '@media(max-width: 599px)': {
                display: 'none',
            },
        },

        '& .account-header.account__header-expanded': {
            minHeight: '90px',

            '@media(max-width: 1019px)': {
                minHeight: '80px',
            },
            '@media(max-width: 599px)': {
                minHeight: '50px',
            },
        },

        '& .account__header-content': {
            flexGrow: '0',
            margin: '0',
        },

        '& .account-number': {
            display: 'block',
            justifyContent: 'center',
            paddingTop: '29px',
            paddingBottom: '26.72px',

            '@media(max-width: 1019px)': {
                paddingTop: '12px',
                paddingBottom: '12px',
            },

            '@media(max-width: 599px)': {
                paddingTop: '12px',
                paddingBottom: '47px',
            },

            '& .account-number-normal': {
                fontFamily: theme.typography.body2.fontFamily,
                fontWeight: 400,
                fontStyle: 'normal',
                fontSize: theme.typography.body3Medium.fontSize,
                lineHeight: '30px',
                color: theme.palette.primary.heather,

                '@media(max-width: 599px)': {
                    fontSize: theme.typography.body1.fontSize,
                    lineHeight: '24px',
                },
            },

            '& .account-number-medium': {
                fontFamily: theme.typography.body3Medium.fontFamily,
                fontWeight: 500,
                fontStyle: 'normal',
                fontSize: theme.typography.body3Medium.fontSize,
                lineHeight: '30px',
                color: theme.palette.primary.heather,

                '@media(max-width: 599px)': {
                    fontSize: theme.typography.body1.fontSize,
                    lineHeight: '24px',
                },
            },

            '& .account-number-bold': {
                fontFamily: theme.typography.h4.fontFamily,
                // fontWeight: 700,
                fontStyle: 'normal',
                fontSize: theme.typography.body3Medium.fontSize,
                lineHeight: '30px',
                color: theme.palette.primary.heather,

                '@media(max-width: 599px)': {
                    fontSize: theme.typography.body1.fontSize,
                    lineHeight: '24px',
                },
            },
        },

        '& .account-details, & .account-preferences, & .account-notifications, & .account-contracts': {
            display: 'block',
            padding: '0px',
            margin: '0px 31.5px',
            marginBottom: '2px',
            background: theme.palette.primary.white,
            boxShadow: `0px 1px 0px ${theme.palette.primary.boxShadow2Dark}`,

            '@media(max-width: 599px)': {
                margin: '0px 16px',
                marginBottom: '1px',
            },
        },
        '& .account-simreplacement': {
            display: 'block',
            padding: '0px',
            margin: '0px 31.5px',
            marginBottom: '1px',
            background: theme.palette.primary.white,
            [theme.breakpoints.down('xs')]: {
                margin: '0px 16px',
                marginBottom: '1px',
            },
        },

        '& .account-secondary-accordion': {
            boxShadow: 'none',
        },

        '& .account-secondary-header': {
            fontFamily: theme.typography.body3Medium.fontFamily,
            fontStyle: 'normal',
            // fontWeight: 700,
            fontSize: theme.typography.h4.fontSize,
            lineHeight: '26px',
            color: theme.palette.primary.heather,

            padding: '0px',
            minHeight: '59.23px',

            '@media(max-width: 599px)': {
                minHeight: '58px',
            },
        },

        '& .account-secondary__header-content': {
            margin: '0px',
            marginTop: '13.28px',
            marginBottom: '13.96px',
            minHeight: '32px',

            '@media(max-width: 599px)': {
                marginTop: '13px',
                marginBottom: '13px',
            },
        },

        // '& .account-secondary__header-content.account-secondary__header-expanded': {
        //     marginBottom: "32px",

        //     '@media(max-width: 599px)': {
        //         marginBottom: "16px",
        //     },
        // },

        '& .account-secondary__header-expandIcon': {
            padding: '0px',
            margin: '0px',
        },

        '& .account-expand-icon-secondary': {
            width: '20px',
            height: '20px',
            color: theme.palette.primary.sunset,
        },
    },
    DetailsWrapper: {
        marginTop: '18px',
        '@media(max-width: 1019px)': {
            paddingRight: '32px',
            paddingLeft: '32px',
        },
        '@media(max-width: 599px)': {
            paddingLeft: '20px',
            paddingRight: '20px',
        },

        '& .details-grid-first-half,& .details-grid-second-half': {
            paddingLeft: '50px',
            paddingRight: '50px',

            '@media(max-width: 1019px)': {
                paddingLeft: '0px',
                paddingRight: '0px',
            },

            '@media(max-width: 599px)': {
                paddingLeft: '0px',
                paddingRight: '0px',
            },
        },

        '& .details-grid-second-half': {
            position: 'relative',
            paddingTop: '7px',

            '@media(max-width: 1019px)': {
                paddingTop: '0px',
            },
            '@media(max-width: 599px)': {
                paddingTop: '0px',
            },

            // "&:before": {
            //     display: "block",
            //     height: "90%",
            //     content: '',
            //     borderLeft: "1px #E0E0E0 solid",
            // },

            // "& :before": {
            //     display: "block",
            //     height: "90%",
            //     content: '',
            //     borderLeft: "1px #E0E0E0 solid",
            // },
        },

        // '& .details-grid-second-half::before': {
        //     display: "block",
        //     height: "90%",
        //     content: '',
        //     borderRight: "1px #E0E0E0 solid",
        // },

        '& .divider': {
            position: 'absolute',
            height: '85%',
            width: '1px',
            top: '0',
            left: '0',
            background: theme.palette.primary.borderColor,
        },

        '& .details-topic-title-change': {
            display: 'flex',
        },

        '& .details-topic': {
            marginBottom: '24px',
            '@media(max-width: 599px)': {
                marginBottom: '16px',
            },
        },

        '& .details-topic-title': {
            fontFamily: theme.typography.h4.fontFamily,
            // fontWeight: 700,
            fontStyle: 'normal',
            fontSize: theme.typography.body3Medium.fontSize,
            lineHeight: '24px',
            color: theme.palette.primary.heather,

            flexGrow: '1',
            textAlign: 'left',

            wordWrap: 'break-word',

            '@media(max-width: 599px)': {
                fontSize: theme.typography.subtitle2.fontSize,
                lineHeight: '24px',
            },
        },

        '& .details-topic-change': {
            fontFamily: theme.typography.body3Medium.fontFamily,
            fontWeight: 600,
            fontStyle: 'normal',
            fontSize: theme.typography.body1.fontSize,
            lineHeight: '25px',
            color: theme.palette.primary.sunset,
            textDecorationLine: 'underline',
            cursor: 'pointer',

            flexGrow: '0',
            textAlign: 'left',

            paddingRight: '9px',
            '@media(max-width: 1019px)': {
                paddingRight: '0px',
            },
            '@media(max-width: 599px)': {
                paddingRight: '0px',
                fontSize: theme.typography.inputLabel.fontSize,
                lineHeight: '19px',
                marginBottom: '17px',
            },
        },

        '& .details-topic-PinPuk': {
            fontFamily: theme.typography.body3Medium.fontFamily,
            fontWeight: 600,
            fontStyle: 'normal',
            fontSize: theme.typography.body1.fontSize,
            lineHeight: '24px',
            color: theme.palette.primary.sunset,
            textDecorationLine: 'underline',
            cursor: 'pointer',

            flexGrow: '0',
            textAlign: 'left',

            position: 'relative',
            height: '28px',

            marginTop: '2px',
            marginBottom: '2px',

            // paddingRight: "9px",

            position: 'relative',
            whiteSpace: 'nowrap',

            '@media(max-width: 599px)': {
                fontSize: theme.typography.inputLabel.fontSize,
                lineHeight: '19px',

                height: 'unset',

                marginTop: '0px',
                marginBottom: '0px',
            },
        },

        '& .details-topic-PinPuk-hidden': {
            visibility: 'hidden',
            '@media(max-width: 1019px)': {
                display: 'none',
            },
            '@media(max-width: 599px)': {
                display: 'none',
            },
        },

        '& .details-topic-keep-old-number': {
            position: 'absolute',
            top: '0',
            textDecorationLine: 'underline',
            cursor: 'pointer',
            zIndex: '1',

            '@media(max-width: 1019px)': {
                position: 'relative',
            },
            '@media(max-width: 599px)': {
                position: 'relative',
            },
        },

        '& .details-topic-content, & .details-topic-content-mobile-connect': {
            fontFamily: theme.typography.body2.fontFamily,
            fontWeight: 400,
            fontStyle: 'normal',
            fontSize: theme.typography.body3Medium.fontSize,
            lineHeight: '28px',
            color: theme.palette.primary.heather,

            textAlign: 'left',

            wordWrap: 'break-word',

            '@media(max-width: 599px)': {
                fontSize: theme.typography.subtitle2.fontSize,
                lineHeight: '22px',
            },
        },

        '& .details-topic-content-mobile-connect': {
            flexGrow: '1',
        },

        '& .details-topic-content-billing-address, & .details-topic-content-contact-number': {
            lineHeight: '24px',
        },

        '& .details-see-UAN': {
            paddingLeft: '50px',
            paddingRight: '50px',
            marginBottom: '40px',

            '@media(max-width: 1019px)': {
                paddingLeft: '0px',
                paddingRight: '0px',
                // marginBottom: "24px",
            },

            '@media(max-width: 599px)': {
                paddingLeft: '0px',
                paddingRight: '0px',
                marginBottom: '24px',
            },
        },

        '& .see-my-uan': {
            fontFamily: theme.typography.body3Medium.fontFamily,
            fontWeight: 600,
            fontStyle: 'normal',
            fontSize: theme.typography.body1.fontSize,
            lineHeight: '25px',
            color: theme.palette.primary.sunset,
            textDecorationLine: 'underline',
            cursor: 'pointer',

            // flexGrow: "0",
            textAlign: 'left',

            '&:hover': {
                textDecorationColor: theme.palette.primary.sunset30,
            },

            '@media(max-width: 1019px)': {
                // paddingLeft: "0px",
            },
            '@media(max-width: 599px)': {
                fontSize: theme.typography.subtitle2.fontSize,
                lineHeight: '22px',
            },
        },
    },
    SimreplacementWrapper: {
        '& .details-Activate-SIM': {
            fontFamily: theme.typography.body3Medium.fontFamily,
            fontWeight: 400,
            fontStyle: 'normal',
            fontSize: '.875rem',
            lineHeight: '157%',
            color: theme.palette.primary.sunset,
            textDecorationLine: 'underline',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: '.5rem',
            [theme.breakpoints.down('sm')]: {
                fontFamily: theme.typography.body3Medium.fontFamily,
                fontWeight: 400,
                fontStyle: 'normal',
                fontSize: '.875rem',
                color: theme.palette.primary.sunset,
            },
        },
        '& .verticalLine': {
            borderLeft: '0px solid lightgray',
            color: theme.palette.primary.borderColor,
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '.7rem',
            paddingRight: '.7rem',
            textAlign: 'right',
        },
        '& .details-topic-order': {
            fontFamily: theme.typography.body3Medium.fontFamily,
            fontWeight: 400,
            fontStyle: 'normal',
            display: 'flex',
            fontSize: '.875rem',
            lineHeight: '157%',
            color: theme.palette.primary.sunset,
            textDecorationLine: 'underline',
            cursor: 'pointer',
            flexGrow: '0',
            textAlign: 'right',
            [theme.breakpoints.down('sm')]: {
                fontFamily: theme.typography.body3Medium.fontFamily,
                fontWeight: 400,
                fontStyle: 'normal',
                display: 'flex',
                fontSize: '.875rem',
                lineHeight: '157%',
                color: theme.palette.primary.sunset,
                position: 'absolute',
                right: '0',
            },
        },
        '& .no-flex': {
            [theme.breakpoints.down('xs')]: {
                left: '0',
            },
        },
        '& .Activate-SIM': {
            fontFamily: theme.typography.body2.fontFamily,
            fontWeight: 400,
            fontStyle: 'normal',
            width: '200px',
            fontSize: theme.typography.subtitle2.fontSize,
            lineHeight: '157%',
            color: theme.palette.primary.sunset,
            textDecorationLine: 'underline',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            // padding: '.5rem',
            [theme.breakpoints.down('sm')]: {
                fontFamily: theme.typography.body3Medium.fontFamily,
                fontWeight: 400,
                fontStyle: 'normal',
                fontSize: '.875rem',
                color: theme.palette.primary.sunset,
            },
        },
        '& .tile-reorder': {
            width: '60px',
        },
        '& .details-topic-title': {
            fontFamily: theme.typography.h4.fontFamily,
            // fontWeight: 700,
            fontStyle: 'normal',
            fontSize: theme.typography.body3Medium.fontSize,
            lineHeight: '1.0625rem',
            color: theme.palette.primary.heather,
            textAlign: 'left',

            [theme.breakpoints.down('sm')]: {
                fontSize: theme.typography.body1.fontSize,
                lineHeight: '1.0625rem',
            },
        },

        '& .sim': {
            fontFamily: theme.typography.h4.fontFamily,
            fontStyle: 'normal',

            fontSize: theme.typography.subtitle2.fontSize,
            fontWeight: 600,
            color: theme.palette.primary.heather,
            display: 'flex',
            width: '100%',
            [theme.breakpoints.down('xs')]: {
                display: 'flex',
                fontSize: theme.typography.subtitle2.fontSize,
                fontFamily: theme.typography.h4.fontFamily,
                fontWeight: 600,
                width: '100%',
            },
        },
        '& .phonenumber': {
            fontFamily: theme.typography.h4.fontFamily,
            fontStyle: 'normal',
            marginLeft: '16px',
            fontSize: theme.typography.subtitle2.fontSize,
            fontWeight: 600,
            color: theme.palette.primary.heather,
            display: 'flex',
            alignItems: 'center',
            [theme.breakpoints.down('xs')]: {
                display: 'flex',
                alignItems: 'center',
            },
        },
        '& .sim-img': {
            alignItems: 'flex-end',
            width: '100%',
            // display: 'flex',
            // padding: '24px 6px',
            padding: '24px 8px 5px 8px',
            [theme.breakpoints.down('xs')]: {
                // display: 'flex',
                padding: '24px 6px',
            },
        },
        '& .sim-link': {
            display: 'flex',
            [theme.breakpoints.down('xs')]: {
                display: 'block !important',
            },
        },
        '& .sim-img:last-child': {
            '& .divider-line': {
                display: 'none',
            },
        },
        '& .simTile': {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            [theme.breakpoints.down('xs')]: {
                position: 'relative',
            },
        },
        '& .block': {
            [theme.breakpoints.down('xs')]: {
                display: 'block',
            },
        },
        '& .sim-img-container': {
            alignItems: 'flex-end',
            width: '100%',
            display: 'flex',
            padding: '24px 5px',
            paddingBottom: '20px',
            [theme.breakpoints.down('xs')]: {
                display: 'flex',
                padding: '24px 5px',
                paddingBottom: '0px',
                flexDirection: 'column',
            },
        },

        '& .divider-line': {
            color: theme.palette.primary.white,
            boxShadow: `0px 1px 0px ${theme.palette.primary.boxShadow2Dark}`,
            // width: '150%',
            height: '1px',

            background: theme.palette.primary.boxShadow2Dark,
            marginTop: '27px',
            [theme.breakpoints.down('xs')]: {
                width: '100%',
                marginTop: '24px',
            },
        },
    },

    seeMore: {
        textAlign: 'center',
        [theme.breakpoints.down('xs')]: {
            marginTop: '58px',
        },
    },
    moreDetails: {
        alignItems: 'center',
        fontFamily: theme.typography.h6.fontFamily,
        fontStyle: 'normal',
        // fontWeight: '700',
        fontSize: theme.typography.body1.fontSize,
        lineHeight: '24px',
        color: theme.palette.primary.sunset,
        textAlign: 'center',
        cursor: 'pointer',
        paddingTop: '24px',
        margin: '0',
        [theme.breakpoints.down('xs')]: {
            margin: '0 0 16px',
        },
    },
    infoIcon: {
        backgroundImage: `url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.0006 4.15527C9.34645 4.15527 8.8252 4.67653 8.8252 5.33072C8.8252 5.9849 9.34645 6.50616 10.0006 6.50616C10.6548 6.50616 11.1761 5.9849 11.1761 5.33072C11.1761 4.67653 10.6583 4.15527 10.0006 4.15527Z' fill='white'/%3E%3Cpath d='M10.8423 7.78418H9.16309V15.8443H10.8423V7.78418Z' fill='white'/%3E%3Cpath d='M10.0018 0C4.47788 0 0 4.47787 0 10.0017C0 12.3456 0.808119 14.5041 2.16198 16.2078C2.16198 16.2078 2.58877 16.4212 2.97009 16.0329C3.35141 15.6481 3.166 15.1723 3.1695 15.1758C2.07802 13.738 1.42732 11.9433 1.42732 9.99825C1.42732 5.265 5.265 1.42732 9.99825 1.42732C14.7315 1.42732 18.5692 5.265 18.5692 9.99825C18.5692 14.7315 14.7315 18.5692 9.99825 18.5692C8.03568 18.5692 6.23054 17.9115 4.78923 16.8025C4.78923 16.8025 4.32045 16.6486 3.96362 17.0439C3.60679 17.4392 3.80619 17.852 3.80969 17.852C5.51338 19.1954 7.66136 20 9.99825 20C15.5221 20 20 15.5221 20 9.99825C20 4.47438 15.5256 0 10.0018 0Z' fill='white'/%3E%3C/svg%3E%0A")`,
        height: 84,
        width: 84,
        backgroundRepeat: `no-repeat`,
        backgroundColor: theme.palette.primary.sunset,
        backgroundPosition: 'center',
        borderRadius: 64,
        marginBottom: 39,
        '@media (max-width: 600px)': {
            height: 64,
            width: 64,
            marginBottom: 16,
        },
    },
    PreferenceWrapper: {
        // background: "orange",

        '& .loader-position': {
            position: 'fixed',
            top: '50%',
        },
        '& .iconUsage': {
            backgroundImage: `url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 3.33333H7.33333V4.66667H6V3.33333ZM6 6H7.33333V10H6V6ZM6.66667 0C2.98667 0 0 2.98667 0 6.66667C0 10.3467 2.98667 13.3333 6.66667 13.3333C10.3467 13.3333 13.3333 10.3467 13.3333 6.66667C13.3333 2.98667 10.3467 0 6.66667 0ZM6.66667 12C3.72667 12 1.33333 9.60667 1.33333 6.66667C1.33333 3.72667 3.72667 1.33333 6.66667 1.33333C9.60667 1.33333 12 3.72667 12 6.66667C12 9.60667 9.60667 12 6.66667 12Z' fill='purple'/%3E%3C/svg%3E%0A")`,
            backgroundRepeat: `no-repeat`,
            height: '20px',
            width: '20px',
            backgroundPosition: 'center',
            '@media (max-width: 600px)': {
                marginBottom: 16,
            },
            '@media  (max-width: 768px)': { margin: '0px ' },
        },
        marginTop: '18px',
        '@media(max-width: 1019px)': {
            paddingRight: '32px',
            paddingLeft: '32px',
        },
        '@media(max-width: 599px)': {
            paddingLeft: '20px',
            paddingRight: '20px',
        },
        '& .MuiSwitch-colorSecondary': {
            '& .Mui-checked': {
                color: theme.palette.primary.sunset,
            },
        },

        '& .MuiSwitch-colorSecondary.Mui-checked, & .MuiSwitch-track': {
            color: theme.palette.primary.sunset,
        },
        '& .MuiSwitch-colorSecondary.Mui-checked + & .MuiSwitch-track': {
            backgroundColor: theme.palette.primary.sunset,
        },

        '& .NDD-Toggle': {
            display: 'flex',

            '@media(max-width: 420px)': {
                justifyContent: 'space-between',

                '& .MuiToggleButtonGroup-root': {
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                },
            },
            '@media (min-width: 600px) and (max-width:1019px)': {
                width: '75%',
            },
            '& .MuiToggleButton-root': {
                textTransform: 'capitalize',
                border: 'none',
                borderRadius: '6px',
                marginRight: '10px',
                width: '122px',
                height: '49px',
                background: '#F3F5F9',
                fontFamily: 'GT Walsheim',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: theme.typography.subtitle2.fontSize,
                lineHeight: '22px',
                color: theme.palette.primary.heather,

                '@media(max-width: 600px)': {
                    width: '100px',
                    height: 'inherit',
                },
            },

            '& .Mui-selected': {
                border: `1px solid ${theme.palette.primary.sunset}`,
                background: 'rgba(209,17,201,0.2)',
            },
            '& .Mui-checked': {
                color: theme.palette.primary.sunset,
            },
        },
        '& .details-grid-first-half': {
            paddingLeft: '50px',
            paddingRight: '65px',

            '@media(max-width: 1019px)': {
                paddingLeft: '0px',
                paddingRight: '0px',
            },

            '@media(max-width: 599px)': {
                paddingLeft: '0px',
                paddingRight: '0px',
            },
        },

        '& .details-grid-second-half': {
            position: 'relative',
            paddingLeft: '65px',
            paddingRight: '50px',

            '@media(max-width: 1019px)': {
                paddingTop: '0px',
            },
            '@media(max-width: 599px)': {
                paddingTop: '0px',
            },
            '@media(max-width: 1019px)': {
                paddingLeft: '0px',
                paddingRight: '0px',
            },

            '@media(max-width: 599px)': {
                paddingLeft: '0px',
                paddingRight: '0px',
            },
        },

        '& .divider': {
            position: 'absolute',
            height: '85%',
            width: '1px',
            top: '0',
            left: '0',
            background: theme.palette.primary.borderColor,
        },

        '& .vertical-divider': {
            height: '1px',
            width: '100%',
            top: '0',
            left: '0',
            background: theme.palette.primary.borderColor,
            marginBottom: '20px',
        },

        '& .details-topic-title-change': {
            display: 'flex',
            justifyContent: 'space-between',
        },

        '& .details-topic': {
            marginBottom: '20px',
            '@media(max-width: 599px)': {
                marginBottom: '16px',
            },
        },

        '& .details-topic-title': {
            fontFamily: theme.typography.h4.fontFamily,
            // fontWeight: 700,
            fontStyle: 'normal',
            fontSize: theme.typography.body1.fontSize,
            lineHeight: '24px',
            color: theme.palette.primary.heather,
            paddingBottom: '15px',

            flexGrow: '1',
            textAlign: 'left',

            wordWrap: 'break-word',
            display: 'flex',
            justifyContent: 'space-between',

            '@media(max-width: 599px)': {
                fontSize: theme.typography.body1.fontSize,
                lineHeight: '24px',
            },
            '& p': {
                margin: 0,
            },
        },
        '& .details-topic-sub-title': {
            fontFamily: theme.typography.subtitle2.fontFamily,
            fontWeight: 500,
            fontStyle: 'normal',
            fontSize: theme.typography.subtitle2.fontSize,
            lineHeight: '21.98px',
            paddingBottom: '15px',

            flexGrow: '1',
            textAlign: 'left',

            wordWrap: 'break-word',

            '@media(max-width: 599px)': {
                fontSize: theme.typography.subtitle2.fontSize,
                lineHeight: '24px',
            },
        },

        '& .details-topic-change': {
            fontFamily: theme.typography.body3Medium.fontFamily,
            // fontWeight: 600,
            fontStyle: 'normal',
            fontSize: theme.typography.body1.fontSize,
            lineHeight: '25px',
            color: theme.palette.primary.sunset,
            textDecorationLine: 'underline',
            cursor: 'pointer',

            flexGrow: '0',
            textAlign: 'left',

            paddingRight: '9px',
            '@media(max-width: 1019px)': {
                paddingRight: '0px',
            },
            '@media(max-width: 599px)': {
                paddingRight: '0px',
                fontSize: theme.typography.inputLabel.fontSize,
                lineHeight: '19px',
            },
        },

        '& .details-topic-PinPuk': {
            fontFamily: theme.typography.body3Medium.fontFamily,
            fontWeight: 600,
            fontStyle: 'normal',
            fontSize: theme.typography.body1.fontSize,
            lineHeight: '24px',
            color: theme.palette.primary.sunset,
            textDecorationLine: 'underline',
            cursor: 'pointer',

            flexGrow: '0',
            textAlign: 'left',

            position: 'relative',
            height: '28px',

            marginTop: '2px',
            marginBottom: '2px',

            // paddingRight: "9px",

            position: 'relative',
            whiteSpace: 'nowrap',

            '@media(max-width: 599px)': {
                fontSize: theme.typography.inputLabel.fontSize,
                lineHeight: '19px',

                height: 'unset',

                marginTop: '0px',
                marginBottom: '0px',
            },
        },

        '& .details-topic-PinPuk-hidden': {
            visibility: 'hidden',
            '@media(max-width: 1019px)': {
                display: 'none',
            },
            '@media(max-width: 599px)': {
                display: 'none',
            },
        },

        '& .details-topic-keep-old-number': {
            position: 'absolute',
            top: '0',
            textDecorationLine: 'underline',
            cursor: 'pointer',
            zIndex: '1',

            '@media(max-width: 1019px)': {
                position: 'relative',
            },
            '@media(max-width: 599px)': {
                position: 'relative',
            },
        },

        '& .details-topic-content, & .details-topic-content-mobile-connect': {
            fontFamily: theme.typography.body2.fontFamily,
            fontWeight: 400,
            fontStyle: 'normal',
            fontSize: theme.typography.subtitle2.fontSize,
            lineHeight: '18px',
            color: theme.palette.primary.heather,

            textAlign: 'left',

            wordWrap: 'break-word',

            '@media(max-width: 599px)': {
                fontSize: theme.typography.subtitle2.fontSize,
                lineHeight: '22px',
            },

            '& .details-topic-content-heading': {
                // fontWeight: 700,
                fontFamily: theme.typography.h4.fontFamily,
                '& .details-headerAccountsDropdown': {
                    // width: "calc(344 / 696 * 100%)",
                    width: '344px',
                    display: 'flex',
                    margin: '0 auto 0 0',
                    // minHeight: "56px",
                    '@media(max-width: 599px)': {
                        width: '100%',
                    },

                    '& .details-dropdownSelect': {
                        paddingRight: '43px',
                    },
                    '& .details-dropdownSelect:focus': {
                        background: theme.palette.primary.white,
                        borderRadius: '4px',
                    },

                    '& .details-dropdownIcon': {
                        marginRight: '12px',
                    },

                    '& .details-dropdownIcon.details-disabled': {
                        display: 'none',
                    },
                },
            },
        },
        '& .radio-directory-preferences-block': {
            display: 'flex',
            paddingBottom: '14px',
            width: '100%',
            justifyContent: 'space-between',
        },
        '& .details-form-label': {
            margin: '0 0 0.5rem',
        },
        '& .details-topic-content-radio': {
            '@media(max-width: 599px)': {
                fontSize: theme.typography.inputLabel.fontSize,
            },
        },
        '& .directory-options': {
            '@media(max-width: 599px)': {
                display: 'flex',
            },
        },

        '& .details-topic-content-mobile-connect': {
            flexGrow: '1',
        },

        '& .details-topic-content-billing-address, & .details-topic-content-contact-number': {
            lineHeight: '24px',
        },

        '& .details-see-UAN': {
            paddingLeft: '50px',
            paddingRight: '50px',
            marginBottom: '40px',

            '@media(max-width: 1019px)': {
                paddingLeft: '0px',
                paddingRight: '0px',
                // marginBottom: "24px",
            },

            '@media(max-width: 599px)': {
                paddingLeft: '0px',
                paddingRight: '0px',
                marginBottom: '24px',
            },
        },

        '& .see-my-uan': {
            fontFamily: theme.typography.body3Medium.fontFamily,
            fontWeight: 600,
            fontStyle: 'normal',
            fontSize: theme.typography.body1.fontSize,
            lineHeight: '25px',
            color: theme.palette.primary.sunset,
            textDecorationLine: 'underline',
            cursor: 'pointer',

            // flexGrow: "0",
            textAlign: 'left',

            '&:hover': {
                textDecorationColor: theme.palette.primary.sunset30,
            },

            '@media(max-width: 1019px)': {
                // paddingLeft: "0px",
            },
            '@media(max-width: 599px)': {
                fontSize: theme.typography.subtitle2.fontSize,
                lineHeight: '22px',
            },
        },
    },

    ContractsWrapper: {
        //background: "orange",
        marginTop: '18px',
        paddingRight: '0px',

        '@media(max-width: 1019px)': {
            marginTop: '16px',
            paddingRight: '0px',
        },
        '@media(max-width: 599px)': {
            marginTop: '16px',
            paddingRight: '0px',
        },

        '& .contracts-grid-column': {
            paddingLeft: '3px',
            paddingRight: '3px',

            '@media(max-width: 1019px)': {
                paddingLeft: '3px',
                paddingRight: '3px',
            },

            '@media(max-width: 599px)': {
                paddingLeft: '3px',
                paddingRight: '3px',
            },
        },
        '& .contracts-topic-title-change-head': {
            display: 'flex',
            maxHeight: '24px',
            marginBottom: '1px',
            '@media(max-width: 599px)': {
                // marginBottom: "16px",
                display: 'block',
                maxHeight: '44px',
            },
            '@media(max-width: 1019px)': {
                display: 'block',
                maxHeight: '44px',
            },
        },
        '& .contracts-topic-title-change': {
            marginBottom: '-5px',
            flex: '1',
            '@media(max-width: 1019px)': {
                display: 'block',
                maxHeight: '44px',
            },
            '@media(max-width: 599px)': {
                // marginBottom: "16px",
                display: 'block',
                maxHeight: 'none',
            },
        },
        '& .contracts-topic-change-content': {
            display: 'flex',
            justifyContent: 'space-between',
            flex: '1',
            '@media(max-width: 1019px)': {
                flexDirection: 'column',
            },
        },
        '& .contracts-topic-head': {
            marginBottom: '30px',
            '@media(max-width: 599px)': {
                marginBottom: '23px',
            },
            '@media(max-width: 1019px)': {
                marginBottom: '23px',
            },
        },

        '& .contracts-topic': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '25px 0',
            paddingLeft: '20px',

            '@media(max-width: 599px)': {
                marginBottom: '8px',
                paddingLeft: '14px',
            },
            '@media(max-width: 1019px)': {
                marginBottom: '8px',
                flexDirection: 'column',
                alignItems: 'flex-start',
                padding: '0.5rem 0',
                paddingLeft: '14px',
            },
        },

        '& .contacts-display-justify-center': {
            justifyContent: 'space-between',
        },
        '& .contracts-topic-title-head': {
            fontFamily: theme.typography.h4.fontFamily,
            // fontWeight: 700,
            fontStyle: 'normal',
            fontSize: theme.typography.body3Medium.fontSize,
            lineHeight: '24px',
            color: theme.palette.primary.heather,

            // flexGrow: "1",
            textAlign: 'left',
            width: '29.76%',
            marginRight: '18.8%',

            '@media(max-width: 599px)': {
                fontSize: theme.typography.body1.fontSize,
                lineHeight: '22px',
                width: '100%',
                marginRight: '0',
            },
            '@media(min-width: 600px) and (max-width: 1019px)': {
                fontSize: theme.typography.body1.fontSize,
                lineHeight: '22px',
                width: '100%',
                marginRight: '0',
            },
            '@media(min-width: 1020px)and (max-width: 1029px)': {
                // fontSize: "16px",
                // lineHeight: "22px",
                width: 'auto',
                // marginRight:'0',
            },
        },

        '& .contracts-topic-title': {
            fontFamily: theme.typography.h4.fontFamily,
            // fontWeight: 700,
            fontStyle: 'normal',
            fontSize: theme.typography.body3Medium.fontSize,
            lineHeight: '24px',
            color: theme.palette.primary.heather,
            // flexGrow: "1",
            textAlign: 'left',
            // width: '29.76%',

            '@media(max-width: 599px)': {
                fontSize: theme.typography.body1.fontSize,
                lineHeight: '24px',
                width: '100%',
                marginRight: '0',
            },
            '@media(max-width: 1019px)': {
                fontSize: theme.typography.body1.fontSize,
                lineHeight: '24px',
                width: '100%',
                marginRight: '0',
            },
        },

        '& .contracts-topic-change-head': {
            // display: 'none',
            flexGrow: 0,
            fontSize: theme.typography.body3Medium.fontSize,
            fontStyle: 'normal',
            textAlign: 'left',
            fontFamily: theme.typography.body2.fontFamily,
            fontWeight: '500',
            lineHeight: '157%',
            width: '41.4%',
            // paddingRight: '9px',
            /* text-decoration-line: underline; */
            color: theme.palette.primary.heather,

            // paddingRight: "9px",
            '@media(max-width: 1019px)': {
                paddingRight: '0px',
                fontSize: theme.typography.subtitle2.fontSize,
                lineHeight: '157%',
                width: '100%',
            },
            '@media(max-width: 599px)': {
                paddingRight: '0px',
                fontSize: theme.typography.subtitle2.fontSize,
                lineHeight: '157%',
                width: '100%',
            },
        },
        '& .contract-word': {
            fontSize: theme.typography.body3Medium.fontSize,
            fontStyle: 'normal',
            fontFamily: theme.typography.h1.fontFamily,
            // fontWeight: '700',
            lineHeight: '157%',
            display: 'contents',
            color: theme.palette.primary.heather,
        },

        '& .contracts-date': {
            flexGrow: 0,
            fontSize: theme.typography.body3Medium.fontSize,
            fontStyle: 'normal',
            textAlign: 'left',
            fontFamily: theme.typography.body2.fontFamily,
            fontWeight: '400',
            lineHeight: '157%',
            width: '41.4%',
            color: theme.palette.primary.heather,
            [theme.breakpoints.down('sm')]: {
                paddingRight: '0px',
                fontSize: theme.typography.subtitle2.fontSize,
                lineHeight: '157%',
                width: '100%',
                fontWeight: '400',
            },
        },
        '& .contracts-topic-change': {
            // display: 'none',
            flexGrow: 0,
            fontSize: theme.typography.body3Medium.fontSize,
            fontStyle: 'normal',
            textAlign: 'left',
            fontFamily: theme.typography.body2.fontFamily,
            fontWeight: '500',
            lineHeight: '157%',
            marginLeft: '-3.4rem',
            // paddingRight: '9px',
            /* text-decoration-line: underline; */
            color: theme.palette.primary.heather,

            // paddingRight: "9px",
            '@media(max-width: 1019px)': {
                paddingRight: '0px',
                fontSize: theme.typography.subtitle2.fontSize,
                lineHeight: '157%',
                width: '100%',
                marginTop: '15px',
                marginLeft: '0rem',
            },
            '@media(max-width: 599px)': {
                paddingRight: '0px',
                fontSize: theme.typography.subtitle2.fontSize,
                lineHeight: '157%',
                width: '100%',
                fontWeight: '500',
                marginTop: '8px',
                marginLeft: '0rem',
            },
        },
        '& .contracts-topic-content': {
            fontFamily: theme.typography.body2.fontFamily,
            fontWeight: '400',
            fontStyle: 'normal',
            fontSize: theme.typography.body3Medium.fontSize,
            lineHeight: '24px',
            color: theme.palette.primary.heather,
            // marginRight: '18.8%',,
            textAlign: 'left',
            marginTop: '8px',

            '@media(max-width: 599px)': {
                fontSize: theme.typography.subtitle2.fontSize,
                lineHeight: '157%',
                width: ' 100%',
                marginBottom: '8px',
            },
            '@media(max-width: 1019px)': {
                fontSize: theme.typography.subtitle2.fontSize,
                lineHeight: '157%',
                width: ' 100%',
                marginBottom: '0px',
                marginTop: '6px',
            },
        },
        '& .contracts-see-my-uan': {
            fontFamily: theme.typography.body2.fontFamily,
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: theme.typography.body1.fontSize,
            lineHeight: '22px',
            textDecorationLine: 'underline',
            color: theme.palette.primary.sunset,
            opacity: 0.9,
            cursor: 'pointer',
            marginRight: '32px',

            '@media(max-width: 599px)': {
                marginLeft: '0px',
            },
            '@media(max-width: 1019px)': {
                marginLeft: '0px',
            },
        },
    },

    ContractContainerWrapper: {
        margin: 'auto',
        maxWidth: '995px',
        marginBottom: '60px',
        position: 'relative',
        // padding: '24px 32px 50px',

        '@media (min-width: 500px) and (max-width:599px)': {
            maxWidth: '570px',
            paddingLeft: '30px',
            paddingRight: '30px',
        },

        '@media (min-width: 600px) and (max-width:1019px)': {
            maxWidth: '694px',
            paddingLeft: '30px',
            paddingRight: '30px',
            marginBottom: '51px',
        },
        '@media (min-width: 0px) and (max-width:499px)': {
            maxWidth: '570px',
            padding: '0px',
        },

        // '@media (min-width: 1020px) and (max-width:1199px)': {
        //     maxWidth: '1076px',
        //     margin: 'auto',
        //     marginTop: '40px',
        //     paddingLeft: '30px',
        //     marginBottom: '40px',
        //     paddingRight: '30px',
        // },
        '@media (min-width: 1920px)': {
            maxWidth: '1076px',
        },
    },
    ContractDetailsContainer: {
        background: theme.palette.primary.white,
        boxShadow: `0px 6px 6px -3px ${theme.palette.primary.boxShadow2Dark}, 0px 10px 14px 1px ${theme.palette.primary.boxShadow2Medium}, 0px 4px 18px 3px ${theme.palette.primary.boxShadow2Lite}`,
        borderRadius: '10px',
        margin: 'auto',
        padding: '24px 32px 50px',

        '@media(max-width: 1019px)': {
            maxWidth: '694px',
            padding: '24px 22px 50px',
        },

        '@media(max-width: 599px)': {
            maxWidth: 'calc(344 / 360 * 100vw)',
            padding: '24px 22px 50px',
        },
    },
    AccountWrapper: {
        margin: 'auto',
        maxWidth: '995px',
        marginBottom: '60px',
        position: 'relative',

        '@media (min-width: 500px) and (max-width:599px)': {
            maxWidth: '570px',
            paddingLeft: '30px',
            paddingRight: '30px',
        },

        '@media (min-width: 600px) and (max-width:1019px)': {
            maxWidth: '694px',
            paddingLeft: '30px',
            paddingRight: '30px',
            marginBottom: '51px',
        },
        '@media (min-width: 0px) and (max-width:499px)': {
            maxWidth: '344px',
        },

        '@media (min-width: 1020px) and (max-width:1199px)': {
            maxWidth: '995px',
            margin: 'auto',
            marginTop: '40px',
            paddingLeft: '30px',
            marginBottom: '40px',
            paddingRight: '30px',
        },
        '@media (min-width: 1920px)': {
            maxWidth: '1076px',
        },
        '& .MuiSwitch-colorSecondary.Mui-checked +  .MuiSwitch-track': {
            backgroundColor: theme.palette.primary.sunset,
        },
    },

    DetailsContainer: {
        background: theme.palette.primary.white,
        boxShadow: `0px 6px 6px -3px ${theme.palette.primary.boxShadow2Dark}, 0px 10px 14px 1px ${theme.palette.primary.boxShadow2Medium}, 0px 4px 18px 3px ${theme.palette.primary.boxShadow2Lite}`,
        borderRadius: '10px',
        margin: 'auto',

        '@media(max-width: 1019px)': {
            maxWidth: '694px',
        },

        '@media(max-width: 599px)': {
            maxWidth: 'calc(344 / 360 * 100vw)',
        },
    },

    TextWrapper: {
        margin: 'auto',
        marginBottom: '10px',
    },
    DetailsBodyheader: {
        borderBottom: `1px solid ${theme.palette.primary.borderColor}`,
        marginLeft: '32px',
        marginRight: '32px',

        '@media(max-width: 599px)': {
            paddingTop: '1.42px',
            borderBottom: 'unset',
            marginLeft: '15px',
            flexGrow: '0',
        },
    },

    headerBackicon: {
        height: '16px',
        width: '16px',
        display: 'flex',
        color: theme.palette.primary.sunset,
        cursor: 'pointer',
        paddingBottom: '21px',
        paddingTop: '29px',
        paddingLeft: '32px',
        marginLeft: '-0.4rem',

        '@media(max-width: 599px)': {
            height: '13.33px',
            width: '13.33px',
            paddingBottom: '18px',
            paddingLeft: '15px',
            marginLeft: ' -0.2rem',
        },
    },
    headerBackArrow: {
        height: '16px',
        width: '16px',
        display: 'flex',
        color: theme.palette.primary.sunset,
        cursor: 'pointer',
        paddingBottom: '21px',
        marginLeft: '-5px',
        // paddingTop: '10px',
        '@media(max-width: 599px)': {
            height: '13.33px',
            width: '13.33px',
            paddingBottom: '18px',
            marginLeft: '-1px',
        },
    },
    bottomLine: {
        borderBottom: `1px solid ${theme.palette.primary.borderColor}`,
    },
    detailsHeaderbackText: {
        display: 'flex',
        marginTop: '21px',
        minHeight: '45px',
        fontFamily: theme.typography.h4.fontFamily,
        fontStyle: 'normal',
        // fontWeight: 700,
        fontSize: theme.typography.h4.fontSize,
        lineHeight: '26px',
        color: theme.palette.primary.heather,
        marginLeft: '32px',
        marginRight: '32px',
        borderBottom: `1px solid ${theme.palette.primary.borderColor}`,
        position: 'relative',

        '@media(max-width: 599px)': {
            marginTop: '18.58px',
            borderBottom: `1px solid ${theme.palette.primary.borderColor}`,
            minHeight: '44px',
            fontSize: theme.typography.h4.fontSize,
            marginLeft: '16.5px',
            // paddingBottom: '50px',
        },

        '& .changeEmail-header-line': {
            position: 'absolute',
            left: '0%',
            right: '0%',
            bottom: '3px',
            border: `1px solid ${theme.palette.primary.borderColor}`,
            borderTopWidth: '0px',

            '@media(max-width: 599px)': {
                bottom: '0px',
            },
        },
    },
    detailsHeaderText: {
        display: 'flex',
        marginTop: '21px',
        minHeight: '45px',
        fontFamily: theme.typography.body3Medium.fontFamily,
        fontStyle: 'normal',
        // fontWeight: 700,
        fontSize: theme.typography.h4.fontSize,
        lineHeight: '26px',
        color: theme.palette.primary.heather,
        marginRight: '32px',
        // borderBottom: '1px solid #E0E0E0',
        position: 'relative',

        '@media(max-width: 599px)': {
            marginTop: '18.58px',
            // borderBottom: '1px solid #E0E0E0',
            minHeight: '44px',
            fontSize: theme.typography.h4.fontSize,
            marginLeft: '4px',
        },

        '& .changeEmail-header-line': {
            position: 'absolute',
            left: '0%',
            right: '0%',
            bottom: '3px',
            border: `1px solid ${theme.palette.primary.borderColor}`,
            borderTopWidth: '0px',

            '@media(max-width: 599px)': {
                bottom: '0px',
            },
        },
    },
}));
