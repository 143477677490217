import React, { useState, Fragment } from 'react';
import {
    List,
    ListItem,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Grid,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { FAQ_QUESTIONS } from './FaqConstants';

function MyEirFaq(props) {
    const [collapse, setcollapse] = useState(false);
    const [expanded, setExpanded] = useState(false);

    const toggle = () => {
        setcollapse(!collapse);
    };
    const renderContent = content => {
        return { __html: content };
    };
    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div className='faqSection'>
            <h1>My eir support</h1>
            {FAQ_QUESTIONS.faqQuestions.map((faq, index) => (
                <Accordion
                    key={index}
                    className='faq-card'
                    expanded={expanded === index}
                    onChange={handleChange(index)}
                >
                    <AccordionSummary
                        onClick={toggle}
                        className='faqCaption'
                        id={faq.faqQuestionTitle + '-header'}
                        aria-controls={faq.faqQuestionTitle + '-content'}
                        expandIcon={
                            expanded === index ? (
                                <RemoveIcon style={{ color: '#892BA0' }} />
                            ) : (
                                <AddIcon style={{ color: '#892BA0' }} />
                            )
                        }
                    >
                        {faq.faqQuestionTitle}
                    </AccordionSummary>
                    <AccordionDetails className='faq-card__body' {...collapse}>
                        <p dangerouslySetInnerHTML={renderContent(faq.faqQuestionDiscription)}></p>
                    </AccordionDetails>
                </Accordion>
            ))}
            <Grid item xs={12} sm={12} md={12}>
                <div>
                    <Button className='faqBtn'>
                        <a href='https://www.eir.ie/support/search/?q=prepay'>View Support</a>
                    </Button>
                </div>
            </Grid>
        </div>
    );
}

export default MyEirFaq;
