import { DOMAINS, ACCOUNT_STATUS } from '../../../components/myeirconstant/myEirConstant';
import {
    updateNDDPREFERENCEApi,
    getNDDPREFERENCEApi,
    updateMyContactPermissionApi,
    permissionApi,
    updateBillDeliveryTypeApi,
    getProfileDetailsApi,
    getMyAccountIDApi,
} from './myPreference.api';

const { VOIP, MOBILE } = DOMAINS;

/**
 * @name handleToggleChange2 - gets ndd numbers and sets it to setDirectoryPreferences state variable
 * @param {Object} Obj - attributes for the function
 * @param {useState} setLoading - useState function which sets loading variable
 * @param {useState} selectedServiceId - useState function which sets selectedServiceId
 * @param {useState} setRetry - useState variable which sets retry error
 * @param {String} nddNumbers - ndd number
 * @param {useState} setDirectoryPreferences - useState function which sets Directory preferences
 */
export const handleToggleChange2 = async ({
    setLoading,
    graphql,
    selectedServiceId,
    value,
    setRetry,
    nddNumbers,
    setDirectoryPreferences,
}) => {
    const token = sessionStorage.getItem('access_token');
    if (token && parseInt(localStorage.getItem('NDDServiceId')) && parseInt(selectedServiceId)) {
        try {
            await updateNDDPREFERENCEApi({
                graphql,
                token: token,
                serviceId: parseInt(localStorage.getItem('NDDServiceId')),
                nddPreference: '{ "nddPreference": "' + value + '"}',

                refetchQueries: [
                    await getNDDPREFERENCEApi({
                        graphql,
                        token: token,
                        serviceId: parseInt(selectedServiceId),
                    }),
                ],
            });
            await getNDDForNumber({
                number: nddNumbers.filter(
                    number => number.serviceId === localStorage.getItem('NDDServiceId')
                )[0].phoneNo,
                serviceId: parseInt(localStorage.getItem('NDDServiceId')),
                setDirectoryPreferences,
                setLoading,
                graphql,
                token,
                setRetry,
            });
            setRetry('');
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
            setRetry('api-error');
        }
    } else {
        setLoading(false);
        console.log('Unable to update NDD Preference');
    }
};
/**
 * @name updateProfileDetails - updating profile details
 * @param {string} updatedpermissionGroups - requested permission groups
 * @param {string} allow_third_party - requested allow_third_party
 * @param {useState} setLoading - useState function which sets loading variable
 * @param {useState} state - useState function which sets setstate
 * @param {string} serviceId - requested serviceId
 * @param {useState} setRetry - useState variable which sets retry error
 * @param {string} token - requested user access token
 */
export const updateProfileDetails = async ({
    updatedpermissionGroups,
    allow_third_party,
    setLoading,
    graphql,
    state,
    token,
    serviceId,
    setRetry,
}) => {
    if (token && allow_third_party != null && updatedpermissionGroups) {
        try {
            const resp = await updateMyContactPermissionApi({
                graphql,
                token: token,
                allowThirdParty: allow_third_party ? !state.allowThirdParty : state.allowThirdParty,
                permissionGroups: updatedpermissionGroups,

                refetchQueries: [
                    await permissionApi({
                        graphql,

                        token,
                        serviceId,
                    }),
                ],
            });
            if (resp) {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            console.error('Error in updateProfileDetails : ', error);
            setRetry('api-error');
        }
        setRetry('');
        setLoading(false);
    } else {
        setLoading(false);
        console.log('Unable to update profile details!');
    }
};
/**
 * @name updateBillDeliveryType - updating bill delivery type
 * @param {string} event - requested event
 * @param {useState} state - useState function which sets state
 * @param {useState} setLoading - useState function which sets loading variable
 * @param {useState} setState - useState function which sets setstate
 * @param {useState} setRetry - useState variable which sets retry error
 * @param {string} token - requested user access token
 */
export const updateBillDeliveryType = async ({
    event,
    setLoading,
    setState,
    state,
    graphql,
    token,
    setRetry,
}) => {
    setLoading(true);
    setState({
        ...state,
        [event.target.name]: event.target.checked,
    });
    let invoiceMethod;
    if (!state.invoiceDeliveryType) {
        invoiceMethod = 'ONLINE';
    } else {
        invoiceMethod = 'POSTAL';
    }
    try {
        const resp = await updateBillDeliveryTypeApi({
            graphql,
            token,
            accountId: sessionStorage.getItem('LoggedinUserAccountID'),
            invoiceDeliveryMethod: '{ "invoiceDeliveryMethod": "' + invoiceMethod + '"}',
        });

        if (resp) {
            setLoading(false);
        }
    } catch (error) {
        console.log(
            'error while updating billing type',
            sessionStorage.getItem('LoggedinUserAccountID')
        );
        setLoading(false);
        setRetry('api-error');
    }
};
/**
 * @name getNDDNumbers - calls getNDDNumbers api and returns response
 * @param {GraphQL} Obj.graphql - appoloclient graphql constant
 * @param {useState} setNddNumbers - useState function which sets NddNumbers
 * @param {useState} setLoading - useState function which sets loading variable
 * @param {useState} setDirectoryPreferences - useState function which sets directory preferences
 * @param {useState} setRetry - useState variable which sets retry error
 * @param {String} Obj.token - user access token
 * @returns
 */
export const getNDDNumbers = async ({
    setNddNumbers,
    setselectedServiceId,
    setDirectoryPreferences,
    setLoading,
    graphql,
    token,
    setRetry,
}) => {
    const services = JSON.parse(sessionStorage.getItem('LoggedinServices'));
    const userStatus = sessionStorage.getItem('Account_status');
    let ndd_array = [];
    for (let itr = 0; itr < services?.length; itr++) {
        if (
            userStatus === ACCOUNT_STATUS.BILLABLE_EMPTY ||
            userStatus === ACCOUNT_STATUS.UNBILLABLE
        ) {
            if (
                services[itr].status === 'TERMINATED' &&
                (services[itr].domain === MOBILE || services[itr].domain === VOIP)
            ) {
                var service = {
                    phoneNo: String(
                        services[itr].domain === MOBILE
                            ? services[itr].msisdn
                            : services[itr].domain === VOIP
                            ? services[itr].sipNumber
                            : ''
                    ),
                    serviceId: String(services[itr].id),
                };
                ndd_array.push(service);
            }
        } else {
            if (
                services[itr].status === 'ACTIVE' &&
                (services[itr].domain === MOBILE || services[itr].domain === VOIP)
            ) {
                var service = {
                    phoneNo: String(
                        services[itr].domain === MOBILE
                            ? services[itr].msisdn
                            : services[itr].domain === VOIP
                            ? services[itr].sipNumber
                            : ''
                    ),
                    serviceId: String(services[itr].id),
                };
                ndd_array.push(service);
            }
        }
    }
    setNddNumbers(ndd_array);
    if (ndd_array.length > 0) {
        setselectedServiceId(ndd_array[0].serviceId);
        await getNDDForNumber({
            number: ndd_array[0].phoneNo,
            serviceId: Number(ndd_array[0].serviceId),
            setDirectoryPreferences,
            setLoading,
            graphql,
            token,
            setRetry,
        });
    }
};
/**
 * @name getNDDForNumber - calls getNDDForNumber api and returns response
 * @param {GraphQL} Obj.graphql - appoloclient graphql constant
 * @param {useState} setNddNumbers - useState function which sets NddNumbers
 * @param {useState} setLoading - useState function which sets loading variable
 * @param {useState} setDirectoryPreferences - useState function which sets directory preferences
 * @param {useState} setRetry - useState variable which sets retry error
 * @param {string} serviceId - serviceId is being requested
 * @param {string} Obj.token - user access token
 * @returns
 */
export const getNDDForNumber = async ({
    number,
    serviceId,
    setDirectoryPreferences,
    setLoading,
    graphql,
    token,
    setRetry,
}) => {
    if (token && serviceId) {
        try {
            const { data } = await getProfileDetailsApi({
                graphql,

                token,
                serviceId,

                errorPolicy: 'all',
            });
            if (data.getMyNDDPreference) {
                var ndd = {
                    phoneNo: String(number),
                    serviceId: String(serviceId),
                    NDDPreference: data.getMyNDDPreference.nddPreference,
                };

                localStorage.setItem('NDDServiceId', ndd.serviceId);
                setDirectoryPreferences(ndd);
            } else {
                throw 'NDD was not returned from API';
            }
        } catch (error) {
            setRetry('api-error');
        }
        setRetry('');
        setLoading(false);
    } else {
        console.log('Unable to fetch NDD for Service ID', serviceId);
    }
    setLoading(false);
    setRetry('api-error');
};
/**
 * @name fetchProfileDetails - calls getprofiledetails api and returns response
 * @param {useState} setState - useState function which sets state
 * @param {useState} setLoading - useState function which sets loading variable
 * @param {string} Obj.token - user access token
 * @param {string} serviceId - serviceId is being requested
 * @param {useState} setRetry - useState variable which sets retry error
 * @returns
 */
export const fetchProfileDetails = async ({
    graphql,
    setState,
    setLoading,
    token,
    serviceId,
    setRetry,
    state,
}) => {
    if (token && serviceId && sessionStorage.getItem('LoggedinUserAccountID')) {
        var invoice_Delivery_Method;
        try {
            const res = await getProfileDetailsApi({
                graphql,
                token,
                serviceId,
                errorPolicy: 'all',
            });
            try {
                setLoading(true);
                const response2 = await getMyAccountIDApi({
                    graphql,
                    token,
                    accountId: sessionStorage.getItem('LoggedinUserAccountID'),
                });
                let data2 = response2.data;
                if (data2.getMyAccountID.invoiceDeliveryMethod != undefined) {
                    invoice_Delivery_Method = data2.getMyAccountID.invoiceDeliveryMethod;
                }
            } catch (error) {
                setLoading(false);
                setRetry('api-error');
                console.error(error);
            }
            if (res.data.getMyPermissionDetails) {
                var active_permission = res.data.getMyPermissionDetails.permissionGroupResponse[0];
                var nonactive_permission =
                    res.data.getMyPermissionDetails.permissionGroupResponse[1];
                setState({
                    ...state,
                    activeCustomer_email: active_permission.permissions[0].enabled,
                    activeCustomer_sms: active_permission.permissions[1].enabled,
                    activeCustomer_phone: active_permission.permissions[2].enabled,
                    activeCustomer_ftf: active_permission.permissions[3].enabled,
                    activeCustomer_directMail: active_permission.permissions[4].enabled,
                    nonactiveCustomer_email: nonactive_permission.permissions[0].enabled,
                    nonactiveCustomer_sms: nonactive_permission.permissions[1].enabled,
                    nonactiveCustomer_phone: nonactive_permission.permissions[2].enabled,
                    nonactiveCustomer_ftf: nonactive_permission.permissions[3].enabled,
                    nonactiveCustomer_directMail: nonactive_permission.permissions[4].enabled,
                    allowThirdParty:
                        res.data.getMyPermissionDetails.allowThirdParty === null
                            ? false
                            : res.data.getMyPermissionDetails.allowThirdParty,
                    invoiceDeliveryType: invoice_Delivery_Method == 'POSTAL' ? false : true,
                });
                setLoading(false);
            } else {
                setLoading(false);
            }
        } catch (err) {
            setLoading(false);
            setRetry('api-error');
            console.error(error);
        }
        setRetry('');
        setLoading(false);
    } else {
        console.log(
            'Unable to fetch fetchProfileDetails : Invalid Token or Service ID or Account ID'
        );
    }
};
