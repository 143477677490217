import React, { useState, Fragment, useEffect } from 'react';
import { Controller, useForm, FormContext } from 'react-hook-form';
import { Grid, Button } from '@material-ui/core';
import { usePrepayFormStyle } from '../css/usePrepayFormStyle';
import { MARKETING_PREFERENCES } from '../../components/my-eir-register/constants';
import MyEirAddressDetails from '../../components/my-eir-register/MyEirAddressDetails';
import MyEirCustomerDetails from '../../components/my-eir-register/MyEirCustomerDetails';
import { MyEirMarketingPreferences } from '../../components/my-eir-register/MyEirMarketingPreferences';
import MyEirSecurityDetails from '../../components/my-eir-register/MyEirSecurityDetails';
import MyEirRegisterHeader from './reusable/MyEirRegisterHeader';
import MyEirRegisterFooter from './reusable/MyEirRegisterFooter';
import { commonRegisterStyles } from '../css/myEirRegistrationStyles/commonRegisterStyles';
import moment from 'moment';
import ENDPOINT from '../../components/graphql/GraphQLConstants';
import ENDPOINT1 from '../../mobilecomponents/graphql/GraphQLConstants';
import { useApolloClient } from '@apollo/client';
import MyEirEmailConfirmationModal from './MyEirEmailConfirmationModal';
import EirButton from '../../theme/EirButton';

function MyEirPrepayForm(props) {
    const classes = usePrepayFormStyle();
    const commonStyle = commonRegisterStyles();
    const methods = useForm({
        mode: 'onChange',
    });
    const [contactGroupList, setContactGroupList] = useState(MARKETING_PREFERENCES);
    const [isOpen2, setIsopen2] = useState(false);
    const [email, setEmail] = useState('');
    const [verifiedEmail, setVerifiedEmail] = useState('');
    const [formStatus, setFormStatus] = useState({
        success: true,
        errormessage: '',
    });
    const graphql = useApolloClient();
    const handleBackButton = () => {
        props.handleBackButton();
    };
    const sendEmailVerification = async regEmail => {
        try {
            const respEmail = await graphql.mutate({
                mutation: ENDPOINT.SEND_VERIFICATION_EMAIL,
                variables: {
                    email: regEmail,
                },
            });
            // console.log('response_email>>', respEmail.data);
            return respEmail.data.sendVerificationEmail;
        } catch (err) {
            console.error(err);
            return 'Failure';
        }
        // return "Failure";
    };
    const toggle2 = () => {
        setIsopen2(!isOpen2);
    };

    const scrollBackToTop = () => {
        let scrollStep = -window.pageYOffset / (1000 / 15);
        let scrollInterval = setInterval(function () {
            if (window.pageYOffset != 0) {
                window.scrollBy(0, scrollStep);
            } else clearInterval(scrollInterval);
        }, 15);
    };
    const onSubmit = async data => {
        // console.log(data);
        console.log('onsubmit data', data.emailAddress);
        try {
            console.log('after try');
            const response = await graphql.query({
                query: ENDPOINT1.GET_BRAND,

                variables: { emailAddress: data.emailAddress },
            });
            let data1 = response.data;
            console.log('response value is', response);
            if (data1.getBrands != undefined) {
                console.log(' verified email 1', verifiedEmail);
                setVerifiedEmail('Email address is already registered');
                console.log(' verified email 2', verifiedEmail);
            } else {
                /* const permissionGroupList = JSON.parse(data.permissionGroupList);
                const birthDate = moment(data.birthDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
                console.log('else email');
                setEmail(data.emailAddress);
                const customerDetailForRegistrationInput = () => ({
                    owner: {
                        email: data.emailAddress,
                        mobileNumber: data.mobileNumber,
                        person: {
                            birthDate: birthDate,
                            firstName: data.firstName,
                            lastName: data.lastName,
                        },
                    },
                    deliveryAddress: {
                        addressLine1: data.billingAddressaddressLine1,
                        addressLine2: data.billingAddressaddressLine2,
                        addressLine3: data.billingAddressaddressLine3,
                        county: data.billingAddresscounty,
                        eircode: data.billingAddressEirCode.replace(/ /g, ''),
                        town: data.billingAddresstown,
                    },
                    billingAddress: {
                        addressLine1: data.billingAddressaddressLine1,
                        addressLine2: data.billingAddressaddressLine2,
                        addressLine3: data.billingAddressaddressLine3,
                        county: data.billingAddresscounty,
                        eircode: data.billingAddressEirCode.replace(/ /g, ''),
                        town: data.billingAddresstown,
                    },

                    contactPermission: {
                        allowThirdParty: permissionGroupList[2].enabled,
                        permissions: [
                            {
                                name: 'An active customer',

                                permissionGroup: 'ACTIVE_CUSTOMER',
                                permissions: [
                                    {
                                        enabled: permissionGroupList[0].preferenceList[0].enabled,
                                        name: 'Email',
                                        permission: 'ALLOW_EMAIL_CONTACT',
                                    },
                                    {
                                        enabled: permissionGroupList[0].preferenceList[1].enabled,
                                        name: 'SMS',
                                        permission: 'ALLOW_SMS_CONTACT',
                                    },
                                    {
                                        enabled: permissionGroupList[0].preferenceList[2].enabled,
                                        name: 'Phone',
                                        permission: 'ALLOW_PHONE_CONTACT',
                                    },
                                    {
                                        enabled: permissionGroupList[0].preferenceList[3].enabled,
                                        name: 'FOTS',
                                        permission: 'ALLOW_FOTS_CONTACT',
                                    },
                                    {
                                        enabled: permissionGroupList[0].preferenceList[4].enabled,
                                        name: 'Direct mail',
                                        permission: 'ALLOW_DIRECT_MAIL_CONTACT',
                                    },
                                ],
                            },
                            {
                                name: 'No longer a customer',
                                permissionGroup: 'NO_LONGER_CUSTOMER',

                                permissions: [
                                    {
                                        enabled: permissionGroupList[1].preferenceList[0].enabled,
                                        name: 'Email',
                                        permission: 'ALLOW_EMAIL_CONTACT',
                                    },
                                    {
                                        enabled: permissionGroupList[1].preferenceList[1].enabled,
                                        name: 'SMS',
                                        permission: 'ALLOW_SMS_CONTACT',
                                    },
                                    {
                                        enabled: permissionGroupList[1].preferenceList[2].enabled,
                                        name: 'Phone',
                                        permission: 'ALLOW_PHONE_CONTACT',
                                    },
                                    {
                                        enabled: permissionGroupList[1].preferenceList[3].enabled,
                                        name: 'FOTS',
                                        permission: 'ALLOW_FOTS_CONTACT',
                                    },
                                    {
                                        enabled: permissionGroupList[1].preferenceList[4].enabled,
                                        name: 'Direct mail',
                                        permission: 'ALLOW_DIRECT_MAIL_CONTACT',
                                    },
                                ],
                            },
                        ],
                    },
                    securityQuestion: {
                        questionCode: data.securityQuestion.toString(),
                        response: data.securityAnswer,
                    },
                });
                console.log('form data:', customerDetailForRegistrationInput());
                try {
                    const res = await graphql.mutate({
                        mutation: ENDPOINT.UPDATE_REGISTER_DETAILS,
                        variables: {
                            msisdn: sessionStorage.getItem('reg_phone_number'),
                            customerDetailForRegistrationInput: customerDetailForRegistrationInput(),
                        },
                    });
                    if (res.data.registration == 'success') {
                        console.log(res.data);
                        setFormStatus({ success: true, errormessage: '' });
                        const emailVerificationStatus = await sendEmailVerification(
                            data.emailAddress
                        );
                        if (emailVerificationStatus == 'success') {
                            toggle2();
                        }
                        // else it should throw some error message
                    } else {
                        setFormStatus({ success: false, errormessage: '' });
                    }
                } catch (err) {
                    console.error(err);
                    setFormStatus({ success: false, errormessage: err.message });
                }
                scrollBackToTop(); */
            }
        } catch (error) {
            console.error(error.message);
            if (error.message === 'Email not found') {
                const permissionGroupList = JSON.parse(data.permissionGroupList);
                const birthDate = moment(data.birthDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
                console.log('else email');
                setEmail(data.emailAddress);
                const customerDetailForRegistrationInput = () => ({
                    owner: {
                        email: data.emailAddress,
                        mobileNumber: data.mobileNumber,
                        person: {
                            birthDate: birthDate,
                            firstName: data.firstName,
                            lastName: data.lastName,
                        },
                    },
                    deliveryAddress: {
                        addressLine1: data.billingAddressaddressLine1,
                        addressLine2: data.billingAddressaddressLine2,
                        addressLine3: data.billingAddressaddressLine3,
                        county: data.billingAddresscounty,
                        eircode: data.billingAddressEirCode.replace(/ /g, ''),
                        town: data.billingAddresstown,
                    },
                    billingAddress: {
                        addressLine1: data.billingAddressaddressLine1,
                        addressLine2: data.billingAddressaddressLine2,
                        addressLine3: data.billingAddressaddressLine3,
                        county: data.billingAddresscounty,
                        eircode: data.billingAddressEirCode.replace(/ /g, ''),
                        town: data.billingAddresstown,
                    },

                    contactPermission: {
                        allowThirdParty: permissionGroupList[2].enabled,
                        permissions: [
                            {
                                name: 'An active customer',

                                permissionGroup: 'ACTIVE_CUSTOMER',
                                permissions: [
                                    {
                                        enabled: permissionGroupList[0].preferenceList[0].enabled,
                                        name: 'Email',
                                        permission: 'ALLOW_EMAIL_CONTACT',
                                    },
                                    {
                                        enabled: permissionGroupList[0].preferenceList[1].enabled,
                                        name: 'SMS',
                                        permission: 'ALLOW_SMS_CONTACT',
                                    },
                                    {
                                        enabled: permissionGroupList[0].preferenceList[2].enabled,
                                        name: 'Phone',
                                        permission: 'ALLOW_PHONE_CONTACT',
                                    },
                                    {
                                        enabled: permissionGroupList[0].preferenceList[3].enabled,
                                        name: 'FOTS',
                                        permission: 'ALLOW_FOTS_CONTACT',
                                    },
                                    {
                                        enabled: permissionGroupList[0].preferenceList[4].enabled,
                                        name: 'Direct mail',
                                        permission: 'ALLOW_DIRECT_MAIL_CONTACT',
                                    },
                                ],
                            },
                            {
                                name: 'No longer a customer',
                                permissionGroup: 'NO_LONGER_CUSTOMER',

                                permissions: [
                                    {
                                        enabled: permissionGroupList[1].preferenceList[0].enabled,
                                        name: 'Email',
                                        permission: 'ALLOW_EMAIL_CONTACT',
                                    },
                                    {
                                        enabled: permissionGroupList[1].preferenceList[1].enabled,
                                        name: 'SMS',
                                        permission: 'ALLOW_SMS_CONTACT',
                                    },
                                    {
                                        enabled: permissionGroupList[1].preferenceList[2].enabled,
                                        name: 'Phone',
                                        permission: 'ALLOW_PHONE_CONTACT',
                                    },
                                    {
                                        enabled: permissionGroupList[1].preferenceList[3].enabled,
                                        name: 'FOTS',
                                        permission: 'ALLOW_FOTS_CONTACT',
                                    },
                                    {
                                        enabled: permissionGroupList[1].preferenceList[4].enabled,
                                        name: 'Direct mail',
                                        permission: 'ALLOW_DIRECT_MAIL_CONTACT',
                                    },
                                ],
                            },
                        ],
                    },
                    securityQuestion: {
                        questionCode: data.securityQuestion.toString(),
                        response: data.securityAnswer,
                    },
                });
                console.log('form data:', customerDetailForRegistrationInput());
                try {
                    const res = await graphql.mutate({
                        mutation: ENDPOINT.UPDATE_REGISTER_DETAILS,
                        variables: {
                            msisdn: sessionStorage.getItem('reg_phone_number'),
                            customerDetailForRegistrationInput: customerDetailForRegistrationInput(),
                        },
                    });
                    if (res.data.registration == 'success') {
                        console.log(res.data);
                        setFormStatus({ success: true, errormessage: '' });
                        const emailVerificationStatus = await sendEmailVerification(
                            data.emailAddress
                        );
                        if (emailVerificationStatus == 'success') {
                            toggle2();
                        }
                        // else it should throw some error message
                    } else {
                        setFormStatus({ success: false, errormessage: '' });
                    }
                } catch (err) {
                    console.error(err);
                    setFormStatus({ success: false, errormessage: err.message });
                }
                scrollBackToTop();
            } else {
                return 'error';
            }
        }
        scrollBackToTop();
    };
    useEffect(() => {
        if (
            sessionStorage.getItem('reg_bill_type') != 'PREPAY' &&
            sessionStorage.getItem('reg_phone_number') == null
        ) {
            props.handleBackButton(0);
        } else if (
            sessionStorage.getItem('reg_email') == null &&
            sessionStorage.getItem('reg_email_status') == null
        ) {
            props.handleBackButton(1);
        }
    }, []);
    return (
        <FormContext {...methods}>
            <form
                className={commonStyle.registrationContainer}
                onSubmit={methods.handleSubmit(onSubmit)}
                // className='regcontainer'
            >
                <div className={commonStyle.cardContainer}>
                    <MyEirRegisterHeader
                        headerText='Register'
                        handleBackButton={handleBackButton}
                    />
                    <div className={classes.prepayFormBody}>
                        <MyEirCustomerDetails emailVerified={verifiedEmail} />
                        <MyEirAddressDetails />
                        <MyEirSecurityDetails />
                        {contactGroupList != '' && (
                            <MyEirMarketingPreferences
                                contactGroupList={contactGroupList}
                                setContactGroupList={setContactGroupList}
                            />
                        )}
                    </div>

                    {/* <MyEirDirectoryPreference
                    directoryPreference={directoryPreference}
                    setDirectoryPreference={setDirectoryPreference}
                /> */}
                    <Grid container spacing={2}>
                        {!formStatus.success && (
                            <p className='errorMessage'>{formStatus.errormessage}</p>
                        )}
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                        <div className='mobileFloat'>
                            <EirButton
                                variants='primaryButtonMedium'
                                style={{ padding: '0.5rem 2.5rem' }}
                                onClick={methods.handleSubmit(onSubmit)}
                                type='submit'
                                className={classes.prepayButton}
                            >
                                Register
                                <span className='fontLarge'></span>
                            </EirButton>
                        </div>
                    </Grid>
                </div>
            </form>
            <MyEirEmailConfirmationModal
                toggle={toggle2}
                isOpen={isOpen2}
                registeredEmail={email}
                heading={"You're almost there"}
                subHeading={'Your account registration is not yet complete'}
                text={
                    'To complete the registration process, please verify your email and follow the instructions to set your password'
                }
            />
        </FormContext>
    );
}

export default MyEirPrepayForm;
