import React, { useEffect, Fragment, useState } from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { isMobile, isMobileOnly, isTablet, isBrowser } from 'react-device-detect';
import { useRegisterFormStyle } from '../my-eir-register/useRegisterFormStyle';
import { Container } from '@material-ui/core';
import { useApolloClient } from '@apollo/client';
import ENDPOINT from '../graphql/GraphQLConstants';
import Loading from '../loading/Loading';

const theme = createMuiTheme({
    typography: {
        textTransform: 'none',
        textAlign: 'left',
        letterSpacing: '0px',
        fontFamily: 'gt-walsheim-regular',
        opacity: 1,
        fontSize: '16px',
    },
    palette: {
        primary: {
            main: '#00A0BD',
        },
    },
    overrides: {
        MuiOutlinedInput: {
            root: {
                fontSize: '16px',
                fontFamily: 'gt-walsheim-medium !important',
                '@media (max-width: 1024px)': {
                    fontSize: '14px',
                },
            },
        },
        MuiInput: {
            input: {
                '&::placeholder': {
                    color: 'gray',
                },
                color: 'white', // if you also want to change the color of the input, this is the prop you'd use
            },
        },
        MuiFormControlLabel: {
            label: {
                '@media (max-width: 1024px)': {
                    fontSize: '14px',
                },
            },
        },
        MuiFormLabel: {
            asterisk: {
                color: '#f44336',
            },
        },
        MuiSelect: {
            icon: {
                color: '#00A0BD',
            },
        },
        MuiTextField: {
            root: {
                fontSize: '16px',
                color: 'black',
                '@media (max-width: 1024px)': {
                    fontSize: '14px',
                },
            },
        },

        MuiInputLabel: {
            root: {
                color: 'black',
                fontSize: '16px',
                '@media (min-width:768px) and (max-width: 1024px)': {
                    fontSize: '14px',
                },
                '@media (min-width:322px) and (max-width: 415px)': {
                    fontSize: '12px',
                },
                '@media (min-width:281px) and (max-width: 321px)': {
                    fontSize: '10px',
                },
                '@media (min-width:279px) and (max-width: 280px)': {
                    fontSize: '8px',
                },
            },
            shrink: {
                fontSize: '16px',
                '@media (min-width:320px) and (max-width: 541px)': {
                    fontSize: '14px',
                },
                '@media (min-width:280px) and (max-width: 321px)': {
                    fontSize: '12px',
                },
            },
        },
    },
});
function MyEirTopUp() {
    const classes = useRegisterFormStyle();
    const [vestaToken, setVestaToken] = useState('');
    const [vestaURI, setVestaURI] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const graphql = useApolloClient();

    useEffect(() => {
        async function fetchVestaToken() {
            try {
                const res = await graphql.query({
                    query: ENDPOINT.GET_VESTA_TOKEN,
                    variables: {
                        msisdn: sessionStorage.getItem('selectedPhone'),
                    },
                });
                if (res.data.getVestaToken) {
                    setLoading(false);
                    console.log('getVestaToken:', { vestaToken });
                    setVestaToken(res.data.getVestaToken.vestaToken);
                    console.log('getVestaToken');
                    setVestaURI(
                        'https://topup.eir.ie/EirExternalWeb/processPrepaidLoginForm.do?AuthToken=' +
                            res.data.getVestaToken.vestaToken
                    );
                    console.log('getVestaToken');
                }
            } catch (err) {
                setLoading(false);
                console.log('getVestaToken');
                console.log('Error>>>', err);
            }

            // const data = { msisdn: '0851838374' };
            // const data = { msisdn: sessionStorage.getItem('selectedPhone') };
            // const settings = {
            //     method: 'POST',
            //     headers: {
            //         Accept: 'application/json',
            //         'Content-Type': 'application/json',
            //     },
            //     body: JSON.stringify(data),
            // };

            // const response = await fetch(`${window.location.origin}/getVestaToken`, settings);
            // // const response = await fetch(`http://localhost:8083/getVestaToken`, settings);
            // if (!response.ok) throw Error(response.message);

            // try {
            //     const data = await response.json();
            //     setVestaToken(data.vestaToken);
            //     setVestaURI(
            //         'https://topup.eir.ie/EirExternalWeb/processPrepaidLoginForm.do?AuthToken=' +
            //             data.vestaToken
            //     );
            //     console.log('date' + data.vestaToken);
            // } catch (err) {
            //     throw err;
            // }
        }
        //fetchVestaToken();
        if (sessionStorage.getItem('selectedPhone') != null) {
            fetchVestaToken();
        } else {
            setTimeout(fetchVestaToken, 5000);
        }
    }, []);
    return (
        <Fragment>
            <ThemeProvider theme={theme}>
                <div className={classes.RegisterForm}>
                    <Container>
                        <div className='regform'>
                            <h1 className='profileTitle'>Top up</h1>
                            {error ? (
                                <div className='outage'>
                                    <h2>Outage notice</h2>
                                    <p>
                                        Prepay Quick Top-Up Direct, Online Top-Ups & Banking Top-Ups
                                        will not be accessible from 8pm Sat, Oct 8th to 12pm Sun,
                                        Oct 9th due to maintenance works. Topping up with a voucher
                                        will be unaffected.
                                    </p>
                                    {/* <p>
                                        We are unable to process your top-up request. The service
                                        should be available again shortly. Please visit{' '}
                                        <a href='https://www.eir.ie/mobile/top-up/'>topup</a> for
                                        more information.
                                    </p> */}
                                </div>
                            ) : null}
                            {loading ? (
                                <span className='loadingPosition'>
                                    <Loading />
                                </span>
                            ) : (
                                <iframe
                                    src={vestaURI}
                                    frameBorder='0'
                                    scrolling='no'
                                    width='100%'
                                    height='100%'
                                ></iframe>
                            )}
                        </div>
                    </Container>
                </div>
            </ThemeProvider>
        </Fragment>
    );
}

export default MyEirTopUp;
