import React, { useContext } from 'react';
import { Grid, FormControl, MenuItem, Select, InputLabel } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import moment from 'moment';
import { useMyEirUsageStyle } from './useMyEirUsageStyle';
import { UsageDetailsContext } from './MyEirUsageContainer';

function MyEirUsageFilter(props) {
    const classes = useMyEirUsageStyle();
    const {
        selectedTimePeriod,
        selectedType,
        handleTimePeriodChange,
        handleUsageTypeChange,
    } = useContext(UsageDetailsContext);
    const { usageTypeList } = props;

    const { control } = useForm();

    const monthStart = moment(selectedTimePeriod, 'YYYYMM').startOf('month').format('DD/MM/YYYY');
    const monthEnd = moment(selectedTimePeriod, 'YYYYMM').endOf('month').format('DD/MM/YYYY');

    const timeFilterOptions = [
        { label: 'Current month', value: moment().format('YYYYMM') },
        {
            label: moment().subtract(1, 'month').format('MMMM'),
            value: moment().subtract(1, 'month').format('YYYYMM'),
        },
        {
            label: moment().subtract(2, 'month').format('MMMM'),
            value: moment().subtract(2, 'month').format('YYYYMM'),
        },
    ];

    return (
        <form className={classes.Usagefilter}>
            <div className='layout'>
                <Grid container spacing={4}>
                    <Grid container>
                        <Grid item xs={12} sm={12} display='flex' justifyContent='center'>
                            <span className='usageFilter'>Usage from </span>
                            <span className='usageFilterBold'>{monthStart} </span>
                            <span className='usageFilter'>to </span>
                            <span className='usageFilterBold'> {monthEnd}</span>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className='usagePaddingTop'>
                        <Grid item xs={12} sm={2}></Grid>
                        <Grid item xs={12} sm={2}>
                            <p className='paddingTopusage'>Filter usage:</p>
                        </Grid>
                        <Grid item xs={1} sm={2} className='usageShowMobile'></Grid>
                        <Grid item xs={10} sm={5}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <FormControl variant='outlined' fullWidth size='small'>
                                        <InputLabel>Type</InputLabel>
                                        <Controller
                                            control={control}
                                            name='usageType'
                                            id='usageType'
                                            defaultValue={selectedType}
                                            as={
                                                <Select
                                                    id='usageType'
                                                    label='Type'
                                                    inputProps={{
                                                        className: 'text-left',
                                                    }}
                                                >
                                                    <MenuItem key='all' value='all'>
                                                        All
                                                    </MenuItem>

                                                    {usageTypeList.map(usageType => (
                                                        <MenuItem
                                                            key={usageType.ratingSubtotalTypeKey}
                                                            value={usageType.ratingSubtotalTypeKey}
                                                        >
                                                            {
                                                                usageType.ratingSubtotalTypeDisplayName
                                                            }
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            }
                                            onChange={([event]) => {
                                                handleUsageTypeChange(event.target.value);
                                                return event.target.value;
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl variant='outlined' fullWidth size='small'>
                                        <InputLabel>Time period</InputLabel>
                                        <Controller
                                            control={control}
                                            name='usageMonth'
                                            id='usageMonth'
                                            defaultValue={selectedTimePeriod}
                                            as={
                                                <Select
                                                    id='usageMonth'
                                                    label='Time period'
                                                    inputProps={{
                                                        className: 'text-left',
                                                    }}
                                                >
                                                    {timeFilterOptions.map(option => (
                                                        <MenuItem
                                                            key={option.value}
                                                            value={option.value}
                                                        >
                                                            {option.label}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            }
                                            onChange={([event]) => {
                                                handleTimePeriodChange(event.target.value);
                                                return event.target.value;
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={1} sm={2} className='usageShowMobile'></Grid>
                        <Grid item xs={1} sm={2} className='usageShowMobile'></Grid>

                        <Grid item xs={1} sm={3}></Grid>
                    </Grid>
                </Grid>
            </div>
        </form>
    );
}
export default MyEirUsageFilter;
