import React, { useState, useEffect } from 'react';
import { Grid, Box, Stack, IconButton, Modal } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import { useTheme } from '@material-ui/core/styles';
import styles from './styles';

import EirChannelCategory from './EirChannelCategory';
import EirInformation from '../eir-information/EirInformation';

/**
 * @name EirActionModal - jsx component for reusable action modal
 * @param {boolean} open - specifies when modal is open or close.
 * @param {Function} handleClose - function to be executed when modal needs to be closed,(usually toggles value of open variable
 * @param {boolean} [disableCloseOnBackdropClick] - if true then modal will not close on clicking the modal backdrop.
 * @param {boolean} [disableCloseOnEscapeKeyDown] - if true then modal will not close on pressing escape key.
 */
const EirChannelModal = ({
    heading,
    id,
    open,
    handleClose,
    disableCloseOnBackdropClick,
    disableCloseOnEscapeKeyDown,
    tvChannels = [],
}) => {
    console.log('channel props:', heading, id, open, tvChannels);
    const theme = useTheme();
    const classes = styles();
    const [dataColumn1, setDataColumn1] = useState([]);
    const [dataColumn2, setDataColumn2] = useState([]);

    const handleDefaultClose = (event, reason) => {
        if (
            !(
                (reason === 'backdropClick' && disableCloseOnBackdropClick === true) ||
                (reason === 'escapeKeyDown' && disableCloseOnEscapeKeyDown === true)
            )
        ) {
            handleClose();
        }
    };

    useEffect(() => {
        if (tvChannels.length > 0) {
            setDataColumn1(tvChannels?.filter(item => item.CategoryName !== 'Entertainment'));
            setDataColumn2(tvChannels?.filter(item => item.CategoryName === 'Entertainment'));
        }
    }, [tvChannels]);
    return (
        <Modal
            id={id}
            open={open}
            onClose={(event, reason) => {
                handleDefaultClose(event, reason);
            }}
            className={classes.modalRoot}
        >
            <Box className={classes.modalContainer}>
                <Box className={classes.channelContainer}>
                    <EirInformation
                        title={heading}
                        style={{
                            marginLeft: '2rem',
                        }}
                    />
                    <IconButton
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        <CloseIcon className={classes.close} />
                    </IconButton>
                </Box>
                <Grid container spacing={3} className={classes.modalContent}>
                    <Grid item container className={classes.subModalContent}>
                        <Grid item xs={12} md={6} style={{ paddingTop: '20px !important' }}>
                            {dataColumn1.length > 0 &&
                                dataColumn1?.map((ele, index) => (
                                    <EirChannelCategory data={ele} key={index} />
                                ))}
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={6}
                            style={{ marginTop: { sm: '0rem' }, paddingTop: '20px !important' }}
                        >
                            {dataColumn2.length > 0 &&
                                dataColumn2?.map((ele, index) => (
                                    <EirChannelCategory data={ele} key={index} />
                                ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    );
};

export default EirChannelModal;
