import React, { useState, Fragment, useEffect } from 'react';
import { Container, Paper } from '@material-ui/core';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { useForgotEmailFormStyle } from '../my-eir-forgotemail/useForgotEmailFormStyle';
import errormesages from '../../components/my-eir-register/errormessages';
import MyEirCreatePasswordForm from './MyEirCreatePasswordForm';
const theme = createMuiTheme({
    typography: {
        textTransform: 'none',
        textAlign: 'left',
        letterSpacing: '0px',
        fontFamily: 'gt-walsheim-regular ',
        opacity: 1,
        fontSize: '16px',
    },
    palette: {
        primary: {
            main: '#892BA0 !important',
        },
    },
    overrides: {
        MuiInput: {
            input: {
                '&::placeholder': {
                    color: 'gray',
                },
                color: 'white',
            },
        },
        MuiFormControlLabel: {
            label: {
                '@media (max-width: 1024px)': {
                    fontSize: '14px',
                },
            },
        },
        MuiFormLabel: {
            asterisk: {
                color: '#f44336',
            },
        },

        MuiTextField: {
            root: {
                fontSize: '16px',
                color: 'black',
                marginBottom: '10px',
                '@media (max-width: 1024px)': {
                    fontSize: '14px',
                },
            },
        },

        MuiInputLabel: {
            root: {
                color: 'black',
                fontSize: '16px',
                '@media (min-width:768px) and (max-width: 1024px)': {
                    fontSize: '14px',
                },
                '@media (min-width:322px) and (max-width: 415px)': {
                    fontSize: '14px',
                },
                '@media (min-width:281px) and (max-width: 321px)': {
                    fontSize: '14px',
                },
                '@media (min-width:279px) and (max-width: 280px)': {
                    fontSize: '13px',
                },
            },
            shrink: {
                fontSize: '16px',
                '@media (min-width:320px) and (max-width: 541px)': {
                    fontSize: '14px',
                },
                '@media (min-width:280px) and (max-width: 321px)': {
                    fontSize: '14px',
                },
            },
        },
    },
});
function MyEirCreatePasswordContainer(props) {
    const classes = useForgotEmailFormStyle();
    return (
        <Fragment>
            <ThemeProvider theme={theme}>
                <div className={classes.ForgotEmailForm}>
                    <Container>
                        <div className='emailform'>
                            <Paper className={classes.paper}>
                                <div className='formLogoSection'>
                                    <img src='/opencms/export/system/modules/my.portal.opencms/myeir_ion/images/my-eir-logo-white-small.png'></img>
                                </div>
                                <Container>
                                    <MyEirCreatePasswordForm pwd={props.createPwd} />
                                </Container>
                            </Paper>
                        </div>
                    </Container>
                </div>
            </ThemeProvider>
        </Fragment>
    );
}

export default MyEirCreatePasswordContainer;
