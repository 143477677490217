import React, { Fragment } from 'react';
import { Box, Link, Typography } from '@material-ui/core';
import useStyles from './myServicesStyles';
import ArrowToRight from '.././../images/arrow-to-right.png';
const ServicesLink = ({ links, isActiveTv }) => {
    const classes = useStyles();
    return (
        <>
            {links !== '' &&
                links.map(({ url, text }, index) => (
                    <Box key={index} className={classes.linkBox}>
                        {text.length === undefined ? (
                            isActiveTv ? (
                                <div className={classes.links} href={url}>
                                    {text === 'Sign up for Amazon Prime' ||
                                    text === 'Cancel Amazon Prime' ? (
                                        <div className={classes.activeTv}>
                                            {text}
                                            <Typography component='span' className={classes.pipe}>
                                                |
                                            </Typography>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                    {/* <Box> */}
                                    {text}

                                    {index < links.length - 1 && text?.props?.linkName && (
                                        <Typography component='span' className={classes.pipe}>
                                            |
                                        </Typography>
                                    )}
                                    {/* </Box> */}
                                </div>
                            ) : (
                                <div className={classes.links} href={url}>
                                    {text}
                                    {text === 'Sign up for Amazon Prime' && (
                                        <Box
                                            component='img'
                                            src={ArrowToRight}
                                            alt='arrow'
                                            className={classes.arrow}
                                        />
                                    )}
                                    {index < links.length - 1 && text?.props?.linkName && (
                                        <Typography component='span' className={classes.pipe}>
                                            |
                                        </Typography>
                                    )}
                                </div>
                            )
                        ) : (
                            ''
                        )}
                    </Box>
                ))}
        </>
    );
};
export default ServicesLink;
