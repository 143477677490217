import { getNameAndAccountID } from '../../getNameAndAccountNumber';
import { getBillingHistoryApi } from '../redesign.api';
import { getMyBillDetailsApi } from '../my-balance/myBalance.api';
import { saveAs } from 'file-saver';
import { downloadInvoicePdfApi } from '../redesign.api';

/**
 * @name getBillingHistory - gets billing history of logged in account
 * @param {Object} Obj - attributes for the function
 * @param {GraphQL} Obj.graphql - appoloclient graphql constant
 * @param {useState} Obj.setBillData - useState function which sets billing history data
 * @param {useState} Obj.setLoading - useState function which sets loading variable
 * @param {useState} Obj.setBillsLoadingError - useState function which sets billing history loading error
 * @param {GraphQL} graphql - appoloclient graphql constant
 * @param {useState} setBillData - useState function which sets billing history data
 * @param {useState} setLoading - useState function which sets loading variable
 * @param {useState} setBillsLoadingError - useState function which sets billing history loading error
 */
export const getBillingHistory = async ({
    graphql,
    setBillData,
    setLoading,
    setBillsLoadingError,
    setNumberOfBills,
    // setUpdatebillbreakLink,
    value,

    setInitialUser,
    setIsNoBills,
}) => {
    try {
        if (
            sessionStorage.getItem('LoggedinUserAccountID') == undefined ||
            sessionStorage.getItem('LoggedinUserAccountID') == ''
        ) {
            const accountResponse = await getNameAndAccountID(graphql);
        }

        const response = await getBillingHistoryApi({ graphql });
        const { data } = response;

        if (data?.getBillingHistory?.length === 0) {
            setLoading(false);
            setBillsLoadingError(false);
            setIsNoBills(true);
        } else {
            setBillData(data?.getBillingHistory);
            value.dispatch({
                type: 'myBalance',
                payload: { name: 'billData', value: data?.getBillingHistory },
            });
            setNumberOfBills(Math.min(data?.getBillingHistory?.length, 12));
            setLoading(false);
            setBillsLoadingError(false);
            setInitialUser(false);
            setIsNoBills(false);
        }
    } catch (error) {
        setBillsLoadingError(true);
        // setUpdatebillbreakLink(!updatebillbreakLink);

        console.error('error in fetching bills ', error.message);
    }
};

/**
 * @name base64ToBlob - converts data from base64 format to required type/format
 * @param {Object} Obj - attributes for the function
 * @param {String} Obj.base64 - data in base64 format
 * @param {String} Obj.type - data type for output, default is 'application/octet-stream'
 * @param {String} base64 - data in base64 format
 * @param {String} type - data type for output, default is 'application/octet-stream'
 * @returns {<Promise>} - base64 data converted to required format
 */
export const base64ToBlob = ({ base64, type = 'application/octet-stream' }) => {
    return new Promise((resolve, reject) => {
        try {
            const binStr = atob(base64);

            const len = binStr.length;
            const arr = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
                arr[i] = binStr.charCodeAt(i);
            }
            resolve(new Blob([arr], { type }));
        } catch (err) {
            reject(err);
        }
    });
};

/**
 * @name downloadInvoicePDF - downloads pdf of a given bill invoive number
 * @param {Object} Obj - attributes for the function
 * @param {GraphQL} Obj.graphql - appoloclient graphql constant
 * @param {String} Obj.invoiceNumber - bill invoice number
 * @param {useState} Obj.setHidePDF - useState function which hides PDF icon
 * @param {GraphQL} graphql - appoloclient graphql constant
 * @param {String} invoiceNumber - bill invoice number
 * @param {useState} setHidePDF - useState function which hides PDF icon
 */
export const downloadInvoicePDF = async ({ graphql, invoiceNumber, setHidePDF }) => {
    try {
        setHidePDF(true);

        const response = await downloadInvoicePdfApi({ graphql, invoiceNumber });

        const data = await response.data;
        // decoding and saving here
        const blob = await base64ToBlob({ base64: data.getInvoicePDF });

        saveAs(blob, `${invoiceNumber}.pdf`);
        setHidePDF(false);
    } catch (error) {
        setHidePDF(false);
        console.error('Error while fetching invoice PDF', error);
    }
};
/**
 * @name recurringAmount - Recurring amounts
 * @param {GraphQL} Obj.graphql - appoloclient graphql constant
 * @param {String}  Obj.token - user access token
 * @param {String} invoiceId - bill invoiceid
 * @param {useState} setRecurringCharges - useState function which sets setRecurringCharges
 * @param {useState} setOneOff - useState function which sets setOneOff
 * @param {useState} setUsageCharges - useState function which sets setUsageCharges
 * @param {useState} setTotalBill - useState function which sets setTotalBill
 * @param {useState} setError - useState function which sets setError
 */
export const recurringAmount = async ({
    graphql,
    token,
    invoiceId,
    setRecurringCharges,
    setOneOff,
    setUsageCharges,
    setTotalBill,
    setError,
}) => {
    try {
        const res = await getMyBillDetailsApi({ graphql, token, invoiceId });
        if (
            res.data.getMyBillDetails &&
            res.data.getMyBillDetails.bills != null &&
            res.data.getMyBillDetails.bills.length > 0
        ) {
            setRecurringCharges(res.data.getMyBillDetails.bills[0].billAmount.recurringAmount);
            setOneOff(res.data.getMyBillDetails.bills[0].billAmount.oneOffAmount);
            setUsageCharges(res.data.getMyBillDetails.bills[0].billAmount.usageAmount);
            setTotalBill(
                res.data.getMyBillDetails.bills[0].billAmount.invoiceAmount //invoice amount includes VAT amount
            );

            setError(false);
        }
    } catch (err) {
        setError(true);
    }
};
