import { makeStyles } from '@material-ui/core/styles';

const noticeStyles = makeStyles(theme => ({
    noticeContainer: {
        background: '#E9F5FE',
        borderRadius: '4px',
        margin: '0 auto',

        marginBottom: '13px',
        width: '94%',
        height: '40px',
        padding: '10px 34px',
        maxWidth: '1077px',

        '@media(min-width: 600px) and (max-width: 1019px)': {
            height: '40px',
            padding: '10px 14px',
            maxWidth: '696px',
        },
        '@media(max-width: 599px)': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            // width: '306px',
            width: '86%',
            // height: '66px',
            height: 'fit-content',
            padding: '8px 11px 8px 10px',
        },
    },
    alertContainer: {
        background: '#E9F5FE',
        borderRadius: '4px',
        margin: '0 auto',

        marginBottom: '-15px',

        // height: '65px',
        height: 'fit-content',
        padding: '10px 34px',
        maxWidth: '1077px',

        '@media(min-width: 600px) and (max-width: 1019px)': {
            // height: '40px',
            padding: '10px 14px',
            maxWidth: '696px',
        },
        '@media(max-width: 599px)': {
            // width: '306px',
            width: '100%',
            // height: '66px',
            padding: '8px 11px 8px 10px',
        },
    },
    noticeElements: {
        display: 'flex',
        alignItems: 'center',
        '@media(max-width: 599px)': {
            alignItems: 'start',
        },
    },
    info: {
        width: '20px',
        height: '20px',
        marginTop: '2px',
    },
    text: {
        color: theme.palette.primary.heather,
        fontFamily: theme.typography.body2.fontFamily,
        fontStyle: 'normal',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '16px',
        marginLeft: '10px',
        marginRight: '11px',
        textAlign: 'initial',
        '@media(min-width: 600px) and (max-width: 1019px)': {
            marginLeft: '14px',
            marginRight: '14px',
            width: '85%',
            display: 'flex',
            alignItems: 'flex-start',
        },

        '@media(min-width: 1020px)': {
            width: '909px',
            marginLeft: '34px',
            marginRight: '34px',
            display: 'flex',
            alignItems: 'flex-start',
        },
    },
    alertText: {
        color: theme.palette.primary.heather,
        fontFamily: theme.typography.body2.fontFamily,
        fontStyle: 'normal',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '16px',
        marginLeft: '10px',
        marginRight: '11px',
        textAlign: 'initial',
        '@media(min-width: 600px) and (max-width: 1019px)': {
            marginLeft: '14px',
            marginRight: '14px',
            width: '85%',
            // display: 'flex',
            // alignItems: 'flex-start',
        },

        '@media(min-width: 1020px)': {
            width: '909px',
            marginLeft: '34px',
            marginRight: '34px',
            // display: 'flex',
            // alignItems: 'flex-start',
        },
    },
    textBold: {
        color: theme.palette.primary.heather,
        fontFamily: 'eirsans-bold',
        fontStyle: 'normal',
        fontSize: '12px',
        fontWeight: 700,
        lineHeight: '16px',
        marginLeft: '3px',
        cursor: 'pointer',
        textDecoration: 'underline',
    },
    cancel: {
        // width: '9.3px',
        // height: '9.3px',
        width: '16px',
        height: '16px',
        padding: '3px',
        cursor: 'pointer',
        '@media(min-width:600px)': {
            marginLeft: '25px',
        },
    },
}));

export default noticeStyles;
