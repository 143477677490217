import React, { useState, Fragment, useEffect, useRef } from 'react';
import { useFormContext, Controller, ErrorMessage } from 'react-hook-form';
import {
    Grid,
    FormControl,
    TextField,
    MenuItem,
    Select,
    InputLabel,
    Container,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Loading from '../shared/Loading';
import { SECURITY_QUESTIONS } from './constants';
import errormesages from './errormessages';
import { usePrepayFormStyle } from '../../sharedcomponents/css/usePrepayFormStyle';
import axios from 'axios';
import { useApolloClient } from '@apollo/client';
import ENDPOINT from '../graphql/GraphQLConstants';

function MyEirSecurityDetails() {
    const classes = usePrepayFormStyle();
    const defaultValue = '';
    const graphql = useApolloClient();
    const { register, errors, control } = useFormContext();
    const [securityQuestionList, setSecurityQuestionList] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const { validation } = errormesages;
    const answerInputRef = useRef();
    const questionInputRef = useRef();
    const getSecurityQuestions = async () => {
        setIsLoading(true);
        try {
            const response = await graphql.query({
                query: ENDPOINT.GET_SECURITYQUESTIONS,
            });

            if (response.data != null) {
                return response.data;
            } else {
                //set default values
                return SECURITY_QUESTIONS;
            }
        } catch (err) {
            //set default values
            return SECURITY_QUESTIONS;
        }
    };

    useEffect(() => {
        getSecurityQuestions().then(data => {
            setSecurityQuestionList(data.securityQuestions);
            setIsLoading(false);
        });
    }, []);

    return (
        <Fragment>
            <Container>
                <div className={classes.securityDetailsHeader}>
                    <p>Security question</p>
                </div>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <FormControl className={classes.textField} variant='outlined' fullWidth size='small'>
                            <InputLabel required >Security question</InputLabel>
                            <Controller
                                control={control}
                                name='securityQuestion'
                                id='securityQuestion'
                                defaultValue={defaultValue}
                                rules={{
                                    required: {
                                        value: true,
                                        message: validation.securityQuestionRequired,
                                    },
                                }}
                                as={
                                    <Select
                                        inputRef={questionInputRef}
                                        id='securityQuestion'
                                        label='Security question *'
                                        inputProps={{
                                            className: 'text-left',
                                        }}
                                        IconComponent={() => <ExpandMoreIcon className={classes.logoField + " MuiSelect-icon MuiSelect-iconOutlined"} />}
                                    >
                                        {!isLoading &&
                                            securityQuestionList.map(securityQuestion => (
                                                <MenuItem className={classes.menuSecurityItem}
                                                    key={securityQuestion.code}
                                                    value={securityQuestion.code}
                                                >
                                                    {securityQuestion.question}
                                                </MenuItem>
                                            ))}
                                        {isLoading && <Loading />}
                                    </Select>
                                }
                                onFocus={() => {
                                    questionInputRef.current.focus();
                                }}
                            />
                            <ErrorMessage errors={errors} name='securityQuestion'>
                                {({ message }) => <p className={classes.errorMessage}>{message}</p>}
                            </ErrorMessage>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Controller
                            as={<TextField className={classes.textField} inputRef={answerInputRef} />}
                            control={control}
                            fullWidth
                            id='securityAnswer'
                            name='securityAnswer'
                            label='Answer'
                            defaultValue={defaultValue}
                            variant='outlined'
                            size='small'
                            required
                            rules={{
                                required: {
                                    value: true,
                                    message: validation.securityAnswerRequired,
                                },
                            }}
                            onFocus={() => {
                                answerInputRef.current.focus();
                            }}
                        />
                        <ErrorMessage errors={errors} name='securityAnswer'>
                            {({ message }) => <p className={classes.errorMessage}>{message}</p>}
                        </ErrorMessage>
                    </Grid>
                </Grid>
            </Container>
        </Fragment>
    );
}
export default MyEirSecurityDetails;
