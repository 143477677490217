import React from 'react';
import Carousel from 'react-material-ui-carousel';

import moment from 'moment';

function MyEirUsageSummary(props) {
    const { listItem } = props;
    const autoPlay = false;
    const convertToEuro = amount => {
        return (amount / 100).toFixed(2);
    };
    const formatUsageSummary = usageSummary => {
        switch (usageSummary.measuringUnit) {
            case 'SECOND':
                return {
                    amount: convertToEuro(usageSummary.amount),
                    quantity: moment.utc(usageSummary.duration * 1000).format('HH:mm:ss'),
                    iconSrc:
                        '/opencms/export/system/modules/my.portal.opencms/myeir_ion/images/my-eir-usage-voice.png',
                };
            case 'KB':
                return {
                    amount: convertToEuro(usageSummary.amount),
                    quantity: `${(usageSummary.quantity / 1073741824).toFixed(3)}GB`,
                    iconSrc:
                        '/opencms/export/system/modules/my.portal.opencms/myeir_ion/images/my-eir-usage-data.png',
                };
            case 'B':
                return {
                    amount: convertToEuro(usageSummary.amount),
                    quantity: `${(usageSummary.quantity / 1073741824).toFixed(3)}GB`,
                    iconSrc:
                        '/opencms/export/system/modules/my.portal.opencms/myeir_ion/images/my-eir-usage-data.png',
                };
            case 'EVENT':
                return {
                    amount: convertToEuro(usageSummary.amount),
                    quantity: usageSummary.eventCount,
                    iconSrc:
                        '/opencms/export/system/modules/my.portal.opencms/myeir_ion/images/my-eir-usage-sms.png',
                };
            default:
                return {
                    amount: convertToEuro(usageSummary.amount),
                    quantity: usageSummary.quantity,
                    iconSrc:
                        '/opencms/export/system/modules/my.portal.opencms/myeir_ion/images/my-eir-usage-data.png',
                };
        }
    };
    const renderSummary = listItem.map(summaryItem => {
        return summaryItem.map((usageSummary, index) => {
            const usage = formatUsageSummary(usageSummary);
            return (
                <div key={index} className='usageScrollItem'>
                    <p>
                        <span className='iconLayout'>
                            <img src={usage.iconSrc}></img>
                        </span>
                    </p>

                    <p className='mediumFont' style={{ height: '41px', marginLeft: '2px' }}>
                        {usageSummary.ratingSubtotalTypeDisplayName}
                    </p>
                    <p className='boldFont fontLarge'>&euro;{usage.amount}</p>
                    <p className='mediumFont'>{usage.quantity}</p>
                </div>
            );
        });
    });

    return (
        <div className='usagePaddingTop'>
            {listItem.length > 0 && (
                <Carousel
                    className='usageCarousel'
                    autoPlay={autoPlay}
                    animation='false'
                    indicators={listItem.length > 1 ? true : false}
                    activeIndicatorProps={{ className: 'Carousel-indicator-active' }}
                    indicatorProps={{ className: 'Carousel-indicator' }}
                    navButtonsAlwaysVisible={listItem.length > 1 ? true : false}
                >
                    {renderSummary}
                </Carousel>
            )}
        </div>
    );
}

export default MyEirUsageSummary;
