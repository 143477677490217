import { getServiceId } from '../../getServiceID';
import { getNameAndAccountID } from '../../getNameAndAccountNumber';
import { getMyDetailsApi, getOfferDetailsApi } from '../redesign.api';
import {
    getMySubscriptionsAndContactDetailsApi,
    getMyAuthorizedUserApi,
    getPaymentMethodApi,
    getContractsApi,
} from './myAccount.api';

export const getOffer = async ({ graphql, catalogOfferCode, setCatalogOffer }) => {
    const services = JSON.parse(sessionStorage.getItem('LoggedinServices'));
    const subscriptionList = JSON.parse(
        sessionStorage.getItem('LoggedinUserSubscriptionIDandAccountID')
    );
    let arrTemp = [];
    try {
        for (let itr = 0; itr < subscriptionList.length; itr++) {
            const catalogOfferCode = subscriptionList[itr].catalogOfferCode;
            const res = await getOfferDetailsApi({
                graphql,
                catalogOfferCode,
            });
            const data = res.data;
            if (data.getOfferDetails) {
                for (let i = 0; i < services.length; i++) {
                    if (subscriptionList[itr].id === services[i].subscriptionId) {
                        arrTemp = arrTemp.concat(data.getOfferDetails);
                    }
                }
            } else {
                throw 'getOfferDetails was not returned from API';
            }
        }
    } catch (error) {
        console.log('Error while fetching offer details');
    }

    setCatalogOffer(arrTemp);
};
export const getMsisdnAndOffer = async ({
    graphql,
    serviceId,
    setMsisdnLoadingError,
    setMsisdnAndOffer,
}) => {
    try {
        if (
            sessionStorage.getItem('LoggedinServices') == undefined ||
            sessionStorage.getItem('LoggedinServices') == ''
        ) {
            const serviceResponse = await getServiceId(graphql);
        }

        let tempArr = [];
        let totalServices = 0;
        for (
            let itr = 0;
            itr <
            JSON.parse(sessionStorage.getItem('LoggedinUserSubscriptionIDandAccountID')).length;
            itr++
        ) {
            try {
                const serviceId = JSON.parse(sessionStorage.getItem('LoggedinServices'))[itr].id;
                const response = await getMyDetailsApi({
                    graphql,
                    serviceId,
                });
                const data = response.data;

                if (data.getMyDetails) {
                    tempArr = tempArr.concat(data.getMyDetails);
                } else {
                    throw 'getMyDetails was not returned from API';
                }
            } catch (error) {
                totalServices += 1;
                setMsisdnLoadingError(true);
                console.log(
                    'error while fetching getMyDetails',
                    JSON.parse(sessionStorage.getItem('LoggedinServices'))[itr].id
                );
            }
        }

        if (
            totalServices ==
            JSON.parse(sessionStorage.getItem('LoggedinUserSubscriptionIDandAccountID')).length
        ) {
            setMsisdnLoadingError(true);
        } else {
            setMsisdnAndOffer(tempArr);
            setLoadingContracts(false);
            setMsisdnLoadingError(false);
        }
    } catch (error) {
        setMsisdnLoadingError(true);
        console.error(
            'Error while fetching MSISDN and offer of contracts (getMyDetails) :',
            error.message
        );
    }
};
export const getData = async ({ graphql, setContactDetails, setLoading, setRetry }) => {
    try {
        const token = sessionStorage.getItem('access_token');
        if (token) {
            const response = await getMySubscriptionsAndContactDetailsApi({
                graphql,
                token,
            });
            const data = await response.data;

            const mySubscriptions = data.getMySubscriptions;
            const myContactDetails = data.getMyContactDetails;

            sessionStorage.setItem('contactUUID', myContactDetails.uuid);
            setContactDetails(myContactDetails);
            setRetry('');
            setLoading(false);
        } else {
            console.log('Unable to fetch Contact Details : Invalid Token');
             setLoading(false);
            setRetry('api-error');
        }
    } catch (err) {
        setRetry('api-error');
        console.error('Error in fetching Contact Details : ' + err.message);
    }
};

export const getAuthUser = async ({ graphql, setAuthUser }) => {
    try {
        const token = sessionStorage.getItem('access_token');
        let accountId = sessionStorage.getItem('LoggedinUserAccountID');
        if (!accountId) {
            await getNameAndAccountID(graphql);
            accountId = sessionStorage.getItem('LoggedinUserAccountID');
        }
        if (token && accountId) {
            const response = await getMyAuthorizedUserApi({
                graphql,
                token,
                accountId,
            });
            setAuthUser(response.data.getMyAuthorizedUsers);
        }
    } catch (err) {
        console.error('Error in fetching authorized users : ', err.message);
    }
};

export const getPaymentDetailsData = async ({ graphql, token, setPayDetails }) => {
    try {
        const response = await getPaymentMethodApi({
            graphql,
            token,
        });
        const data = await response.data;
        setPayDetails(data.getPaymentMethod.paymentMethods);
    } catch (err) {
        console.error('err in payment:', err);
    }
};

export const getAllContracts = async ({ graphql, setContracts }) => {
    try {
        if (
            sessionStorage.getItem('LoggedinServices') == undefined ||
            sessionStorage.getItem('LoggedinServices') == ''
        ) {
            // const serviceResponse = await getServiceId(graphql);
            const subscriptionResponse = await getNameAndAccountID(graphql);
        }

        let tempArr = [];
        let totalServices = 0;
        const services = JSON.parse(sessionStorage.getItem('LoggedinServices'));
        const subscriptionList = JSON.parse(
            sessionStorage.getItem('LoggedinUserSubscriptionIDandAccountID')
        );

        for (let itr = 0; itr < subscriptionList.length; itr++) {
            try {
                const response = await getContractsApi({
                    graphql,
                    subscriptionId: subscriptionList[itr].id,
                });

                const data = response.data;
                if (data.getMyServiceContract) {
                    for (let i = 0; i < services.length; i++) {
                        if (subscriptionList[itr].id === services[i].subscriptionId) {
                            tempArr = tempArr.concat({
                                ...data.getMyServiceContract,
                                subscriptionId: services[i].subscriptionId,
                            });
                        }
                    }
                } else {
                    totalServices += 1;
                    throw 'getMyDetails was not returned from API';
                }
            } catch (error) {
                console.error('Error while fetching contracts :', error.message);
            }
        }
        setContracts(tempArr);

        if (
            totalServices ==
            JSON.parse(sessionStorage.getItem('LoggedinUserSubscriptionIDandAccountID')).length
        ) {
            console.log('total services are equal to LoggedinUserSubscriptionIDandAccountID ');
        } else {
            setContracts(tempArr);
        }
    } catch (error) {
        console.error(error);
    }
};
