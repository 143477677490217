import { getNameAndAccountID } from '../../getNameAndAccountNumber';
import { getBalanceApi, getMyBillDetailsApi } from './myBalance.api';
import { getBillingPeriod } from '../../getBillingCycle';
import { getBillingHistoryApi } from '../redesign.api';

export const getMyBalance = async ({
    graphql,
    billingAccountId,
    token,
    setBalanceOverdue,
    setBalance,
    setIsError,
    setLoading,
    setBillDate,
    value,
    setLastBillDueDate,
}) => {
    try {
        if (!billingAccountId) {
            await getNameAndAccountID(graphql);
            billingAccountId = sessionStorage.getItem('LoggedinUserAccountID');
        }
        if (token && billingAccountId) {
            const res = await getBalanceApi({ graphql, token, billingAccountId });
            if (res.data.getBalanceDetails) {
                if (res.data.getBalanceDetails[0].nonOverdueAmount < 0) {
                    setBalanceOverdue(true);
                }
                setBalance(
                    res.data.getBalanceDetails[0].nonOverdueAmount +
                        res.data.getBalanceDetails[0].overdueAmount
                );

                value.dispatch({
                    type: 'myBalance',
                    payload: {
                        name: 'balance',
                        value:
                            res.data.getBalanceDetails[0].nonOverdueAmount +
                            res.data.getBalanceDetails[0].overdueAmount,
                    },
                });

                sessionStorage.setItem(
                    'myBalance',
                    res.data.getBalanceDetails[0].nonOverdueAmount +
                        res.data.getBalanceDetails[0].overdueAmount
                );

                setLastBillDueDate(res?.data?.getBalanceDetails[0]?.lastBillOverdueDate);
                value.dispatch({
                    type: 'myBalance',
                    payload: {
                        name: 'billDueDate',
                        value: res?.data?.getBalanceDetails[0]?.lastBillOverdueDate,
                    },
                });
                setIsError(false);
            }

            if (
                window.location.href.indexOf('mytt') > 0 ||
                window.location.href.indexOf('myeir-test') > 0 ||
                window.location.href.indexOf('localhost') > 0 ||
                window.location.href.indexOf('myeir-dev') > 0
            ) {
                if (res.data.getBalanceDetails) {
                    setBillDate(
                        res.data.getBalanceDetails[0].date != null
                            ? convertDate(res.data.getBalanceDetails[0].date)
                            : 'NA'
                    );
                    value.dispatch({
                        type: 'myBalance',
                        payload: {
                            name: 'billDate',
                            value:
                                res.data.getBalanceDetails[0].date != null
                                    ? convertDate(res.data.getBalanceDetails[0].date)
                                    : 'NA',
                        },
                    });
                    setIsError(false);
                }
            }
        }
    } catch (err) {
        setIsError(true);
    } finally {
        setLoading(false);
    }
};

export const billingCycle = async ({ graphql, setForPeriod, setToDate, setIsError, value }) => {
    try {
        if (
            sessionStorage.getItem('lastInvoiceDate') == undefined ||
            sessionStorage.getItem('lastInvoiceDate') == null ||
            sessionStorage.getItem('lastInvoiceDate') == '' ||
            sessionStorage.getItem('nextInvoiceDate') == undefined ||
            sessionStorage.getItem('nextInvoiceDate') == null ||
            sessionStorage.getItem('nextInvoiceDate') == ''
        ) {
            await getBillingPeriod(graphql);
        }
        if (
            sessionStorage.getItem('lastInvoiceDate') != undefined &&
            sessionStorage.getItem('lastInvoiceDate') != '' &&
            sessionStorage.getItem('nextInvoiceDate') != undefined &&
            sessionStorage.getItem('nextInvoiceDate') != null &&
            sessionStorage.getItem('nextInvoiceDate') != ''
        ) {
            setForPeriod(
                sessionStorage.getItem('lastInvoiceDate') !== 'null'
                    ? sessionStorage.getItem('lastInvoiceDate')
                    : sessionStorage.getItem('firstInvoiceDate')
            );
            value.dispatch({
                type: 'myBalance',
                payload: {
                    name: 'forPeriod',
                    value:
                        sessionStorage.getItem('lastInvoiceDate') !== 'null'
                            ? sessionStorage.getItem('lastInvoiceDate')
                            : sessionStorage.getItem('firstInvoiceDate'),
                },
            });
            setToDate(sessionStorage.getItem('nextInvoiceDate'));
            value.dispatch({
                type: 'myBalance',
                payload: { name: 'toDate', value: sessionStorage.getItem('nextInvoiceDate') },
            });
            setIsError(false);
        } else {
            // setIsError(true);
        }
    } catch (err) {
        setIsError(true);
    }
};

export const monthlyCharge = async ({
    billingAccountId,
    token,
    graphql,
    setBillDate,
    setPaymentDue,
    setDebitDate,
    setMonthlyCharges,
    setUsage,
    setTotalBalance,
    setOneoffCharges,
    setIsError,
    value,
}) => {
    try {
        if (!billingAccountId) {
            await getNameAndAccountID(graphql);
            billingAccountId = sessionStorage.getItem('LoggedinUserAccountID');
        }
        if (token && billingAccountId) {
            const response = await getBillingHistoryApi({ graphql });

            let data = await response.data;
            if (data.getBillingHistory) {
                if (data.getBillingHistory.length > 0) {
                    setBillDate(convertDate(data?.getBillingHistory[0]?.invoiceDate));
                    value.dispatch({
                        type: 'myBalance',
                        payload: {
                            name: 'billDate',
                            value: convertDate(data?.getBillingHistory[0]?.invoiceDate),
                        },
                    });
                    setPaymentDue(convertDebitDate(data?.getBillingHistory[0]?.dueDate));
                    value.dispatch({
                        type: 'myBalance',
                        payload: {
                            name: 'paymentDue',
                            value: convertDebitDate(data?.getBillingHistory[0]?.dueDate),
                        },
                    });
                    setDebitDate(convertDebitDate(data?.getBillingHistory[0]?.dueDate));
                    value.dispatch({
                        type: 'myBalance',
                        payload: {
                            name: 'debitDate',
                            value: convertDebitDate(data?.getBillingHistory[0]?.dueDate),
                        },
                    });
                }
            }

            if (data?.getBillingHistory[0].billingSource === 'ION') {
                const res = await getMyBillDetailsApi({
                    graphql,
                    token,
                    invoiceId: `${data?.getBillingHistory[0]?.invoiceNumber}`,
                });

                if (res.data.getMyBillDetails) {
                    setMonthlyCharges(
                        res.data.getMyBillDetails.bills[0].billAmount.recurringAmount
                    );
                    value.dispatch({
                        type: 'myBalance',
                        payload: {
                            name: 'monthlyCharges',
                            value: res.data.getMyBillDetails.bills[0].billAmount.recurringAmount,
                        },
                    });

                    setUsage(res.data.getMyBillDetails.bills[0].billAmount.usageAmount);
                    value.dispatch({
                        type: 'myBalance',
                        payload: {
                            name: 'usage',
                            value: res.data.getMyBillDetails.bills[0].billAmount.usageAmount,
                        },
                    });

                    setTotalBalance(res.data.getMyBillDetails.bills[0].billAmount.invoiceAmount);
                    value.dispatch({
                        type: 'myBalance',
                        payload: {
                            name: 'totalBalance',
                            value: res.data.getMyBillDetails.bills[0].billAmount.invoiceAmount,
                        },
                    });

                    setOneoffCharges(res.data.getMyBillDetails.bills[0].billAmount.oneOffAmount);
                    value.dispatch({
                        type: 'myBalance',
                        payload: {
                            name: 'oneoffCharges',
                            value: res.data.getMyBillDetails.bills[0].billAmount.oneOffAmount,
                        },
                    });
                    setIsError(false);
                }
            }
        }
    } catch (err) {
        // setIsError(true);
    }
};

export const payDue = async ({
    billingAccountId,
    graphql,
    token,
    setPaymentDue,
    setIsError,
    value,
    setDebitDate,
}) => {
    try {
        if (!billingAccountId) {
            await getNameAndAccountID(graphql);
            billingAccountId = sessionStorage.getItem('LoggedinUserAccountID');
        }
        if (token && billingAccountId) {
            const res = await getBalanceApi({ graphql, token, billingAccountId });
            if (res.data.getBalanceDetails) {
                setPaymentDue(
                    res.data.getBalanceDetails[0].date != null
                        ? convertDebitDate(res.data.getBalanceDetails[0].date)
                        : 'NA'
                );
                value.dispatch({
                    type: 'myBalance',
                    payload: {
                        name: 'paymentDue',
                        value:
                            res.data.getBalanceDetails[0].date != null
                                ? convertDebitDate(res.data.getBalanceDetails[0].date)
                                : 'NA',
                    },
                });
                setDebitDate(
                    res.data.getBalanceDetails[0].date != null
                        ? convertDebitDate(res.data.getBalanceDetails[0].date)
                        : 'NA'
                );
                value.dispatch({
                    type: 'myBalance',
                    payload: {
                        name: 'debitDate',
                        value:
                            res.data.getBalanceDetails[0].date != null
                                ? convertDebitDate(res.data.getBalanceDetails[0].date)
                                : 'NA',
                    },
                });
                setIsError(false);
            }
        }
    } catch (err) {
        setIsError(true);
    }
};

export function convertDateFormat(str) {
    if (str !== 'NA') {
        const dateArr = str.split('-');
        const myDate = new Date(`${dateArr[0]}-${dateArr[1]}-${dateArr[2]}`);
        const myMonth = myDate.toLocaleString('default', { month: 'short' });
        return `${dateArr[2]} ${myMonth} ${dateArr[0]}`;
    }
}

export function convertToDateFormat(str) {
    if (str !== 'NA') {
        const toDateArr = str.split('-');
        const myToDate = new Date(`${toDateArr[0]}-${toDateArr[1]}-${toDateArr[2]}`);
        const converted = new Date();
        converted.setTime(myToDate.getTime() - 24 * 60 * 60 * 1000);
        const toDateArrNew = converted.toString().split(' ');
        return `${toDateArrNew[2]} ${toDateArrNew[1]} ${toDateArrNew[3]}`;
    }
}

export function convertDate(str) {
    if (str !== 'NA') {
        const extractDate = str.split('T');
        const newExtractDate = `${extractDate[0]}`;
        const dateExtract = newExtractDate.split('-');
        const myDateExtract = new Date(`${dateExtract[0]}-${dateExtract[1]}-${dateExtract[2]}`);
        const myMonthExtract = myDateExtract.toLocaleString('default', { month: 'short' });
        return `${parseInt(dateExtract[2])} ${myMonthExtract} ${dateExtract[0]}`;
    }
}
export function convertDueDate(str) {
    if (str !== 'NA') {
        const toDateArr = str.split('-');
        const myToDate = new Date(`${toDateArr[0]}-${toDateArr[1]}-${toDateArr[2]}`);
        const converted = new Date();
        converted.setTime(myToDate.getTime() + 7 * (24 * 60 * 60 * 1000));
        const toDateArrNew = converted.toString().split(' ');
        return `${toDateArrNew[2]} ${toDateArrNew[1]} ${toDateArrNew[3]}`;
    }
}

export function convertDebitDate(str) {
    if (str !== 'NA') {
        const toDateArr = str.split('-');
        const myToDate = new Date(`${toDateArr[0]}-${toDateArr[1]}-${toDateArr[2]}`);
        const converted = new Date();
        converted.setTime(myToDate.getTime());
        const toDateArrNew = converted.toString().split(' ');
        return `${toDateArrNew[2]} ${toDateArrNew[1]} ${toDateArrNew[3]}`;
    }
}
