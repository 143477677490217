import React, { useState } from 'react';
import MyEirPhoneNumber from './MyEirPhoneNumber';
import { useHistory } from 'react-router-dom';
import MyEirVerificationCode from './MyEirVerificationCode';
import MyEirAlreadyRegistered from './MyEirAlreadyRegistered';
import MyEirEnterAccountNumber from './MyEirEnterAccountNumber';

function ForgotUsernameContainer() {
    const [step, setStep] = useState(0);
    const [isOpen, setIsopen] = useState(false);

    const history = useHistory();
    const handleBackButton = () => {
        if (step == 0) {
            console.log('back from step1');
            history.push('/');
        } else {
            setStep(step - 1);
        }
    };
    const handleNext = (jumpTo = false) => {
        if (jumpTo) setStep(jumpTo);
        else if (step == 2) {
            console.log('Last Step');
        } else {
            setStep(step + 1);
        }
    };

    const toggle = () => {
        setIsopen(!isOpen);
    };

    const handleUserVerification = (data, skip) => {
        if (!data && skip) handleNext(skip);
        else {
            sessionStorage.setItem('reg_email', data.username);
            toggle();
        }
    };

    return (
        <div className='my-eir-reset-page'>
            {
                {
                    0: (
                        <MyEirPhoneNumber
                            handleBackButton={handleBackButton}
                            handleNext={handleNext}
                            headerText='Forgot email'
                            forgotUserName={true}
                        />
                    ),
                    1: (
                        <MyEirVerificationCode
                            handleBackButton={handleBackButton}
                            handleNext={handleUserVerification}
                            headerText='Forgot email'
                            forgotUserName={true}
                        />
                    ),
                    2: (
                        <MyEirEnterAccountNumber
                            headerText='Forgot email'
                            handleBackButton={handleBackButton}
                            handleNext={handleUserVerification}
                        />
                    ),
                    // /
                }[step]
            }
            {/*  */}
            <MyEirAlreadyRegistered
                toggle={toggle}
                isOpen={isOpen}
                heading={'Forgot email'}
                headingMobile={'Forgot email'}
                newsContentText={'Good news!'}
                verifiedText={'Your email is:'}
                verifiedTextMobile={'Your email is:'}
                usernameEmail={sessionStorage.getItem('reg_email')}
                // subtext={''}
                buttonText={'Close'}
                // footerText={''}
            />
        </div>
    );
}

export default ForgotUsernameContainer;
