import React, { useEffect, useState } from 'react';
import { Grid, Icon, LinearProgress, Button } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import AssessmentRoundedIcon from '@material-ui/icons/AssessmentRounded';
const BorderLinearProgress = withStyles(theme => ({
    root: {
        height: 16,
        borderRadius: 9,
        marginBottom: 16,

        '@media  (max-width: 768px)': {
            height: 10,
        },
    },
    colorPrimary: {
        backgroundColor: '#892BA033',
    },
    bar: {
        borderRadius: 0,
        backgroundColor: '#892BA0',
    },
}))(LinearProgress);

function MyEirUsage() {
    const [primaryProgress, setPrimaryProgress] = React.useState(0);
    const [secondaryProgress, setSecondaryProgress] = React.useState(0);
    const [ternaryProgress, setTernaryProgress] = React.useState(0);
    useEffect(() => {
        let primaryProgress = (23.7 / 50.0) * 100;
        let secondaryProgress = (35.7 / 50.0) * 100;
        let ternaryProgress = (14.7 / 50.0) * 100;
        setPrimaryProgress(primaryProgress);
        setSecondaryProgress(secondaryProgress);
        setTernaryProgress(ternaryProgress);
    }, []);

    return (
        <div className='usageSection'>
            <h1>
                <span className='iconLayout'>
                    {' '}
                    <img src='/opencms/export/system/modules/my.portal.opencms/myeir_ion/images/my-eir-usage.png'></img>
                </span>
                Usage this period
            </h1>
            <div className='padding'>
                <Grid container spacing={6}>
                    <Grid item xs={8} sm={9} md={9}>
                        <p>Simply Unlimited Anytime – Unlimited Data and Any Net Calls+Texts 20e</p>
                    </Grid>
                    <Grid item xs={4} sm={3} md={3}>
                        <p className='AlignRight'>23.7/50.0 GB</p>
                    </Grid>
                </Grid>
                <BorderLinearProgress variant='determinate' value={primaryProgress} />
                <Grid container spacing={6}>
                    <Grid item xs={8} sm={9} md={9}>
                        <p>eir Mobile Data addon 15GB booster</p>
                    </Grid>
                    <Grid item xs={4} sm={3} md={3}>
                        <p className='AlignRight'>23.7/50.0 GB</p>
                    </Grid>
                </Grid>
                <BorderLinearProgress variant='determinate' value={secondaryProgress} />
                <Grid container spacing={6}>
                    <Grid item xs={8} sm={9} md={9}>
                        <p>eir Mobile Data addon 15GB booster</p>
                    </Grid>
                    <Grid item xs={4} sm={3} md={3}>
                        <p className='AlignRight'>14.7/50.0 GB</p>
                    </Grid>
                </Grid>
                <BorderLinearProgress variant='determinate' value={ternaryProgress} />
                <Grid item xs={12} sm={12} md={12}>
                    <div>
                        <Button className='faqBtn usageBtn'>
                            <a href='#'>View usage in detail</a>
                        </Button>
                    </div>
                </Grid>
            </div>
        </div>
    );
}

export default MyEirUsage;
