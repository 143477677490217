import React from 'react';
import { Box, Typography } from '@material-ui/core';
import info from '../redesign-5.2/images/info.png';
import useStyles from './styles';

const TerminatedTile = ({ terminatedCopy, terminatedDate, flag }) => {
    const style = useStyles();
    return (
        <Box className={flag ? style.terminatedFlagMain : style.terminatedMain}>
            <Box
                className={style.terminatedIconWrap}
                style={{ marginRight: flag ? '15px' : '8px' }}
            >
                <Box className={style.terminatedIcon} component='img' src={info} alt='icon-info' />
            </Box>
            <Box className={style.terminatedText}>
                {terminatedCopy ? terminatedCopy : ''}{' '}
                {terminatedDate ? `Terminated on ${terminatedDate}` : ''}
            </Box>
        </Box>
    );
};

export default TerminatedTile;
