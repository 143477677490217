import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import KeycloakData from '../../JSON/keycloak.json';
import { MY_EIR_CONST } from '../myeirconstant/myEirConstant';

function MyEirLogout(props) {
    useEffect(() => {
        sessionStorage.clear();
        window.location.href = MY_EIR_CONST.url.REDIRECT_URL;

        props.history.push('/prepay/');
    });
    return <div></div>;
}

export default withRouter(MyEirLogout);
