import { makeStyles } from '@material-ui/core';

const styles = makeStyles(theme => ({
    modalRoot: {
        [theme.breakpoints.down('sm')]: {
            overflow: 'overlay',
        },
        '& .MuiBackdrop-root': {
            '@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none)': {
                background: theme.palette.primary.gray550,
                backdropFilter: 'blur(4.5px)',
            },
            '@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none))': {
                background: theme.palette.primary.boxShadow2Lite,
            },
        },
    },
    channelContainer: {
        margin: 0,
        height: '56px',
        background: theme.palette.primary.gray250,
        display: 'flex',
        position: 'sticky',
        top: 0,
        zIndex: 100,
        borderTopLeftRadius: '0.625rem',
        borderTopRightRadius: '0.625rem',
        [theme.breakpoints.down('sm')]: {
            height: '36px !important',
        },
    },
    modalContainer: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 'calc(100% - 23.3rem)',
        maxWidth: '991px',
        bgcolor: 'background.paper',
        borderRadius: '0.625rem',
        boxShadow: 24,
        background: theme.palette.primary.white,
        outline: 'none',

        '&::-webkit-scrollbar': {
            width: '0rem',
        },
        [theme.breakpoints.up('sm')]: {
            maxHeight: '90vh',
            overflowY: 'overlay',
        },
        '@media (min-width: 601px) and (max-width:1199px)': {
            position: 'relative',
            top: '25%',
            left: 'unset',
            WebkitTransform: 'unset',
            transform: 'unset',
            display: 'flex',
            flexDirection: 'column',
            // overflow: 'unset',
            margin: '0.5rem auto 0',
            padding: 0,
            // height: '100%',
            width: 'calc(100vw - 1rem)',
            borderRadius: '0.625rem',
        },
        '@media (max-width: 600px) ': {
            position: 'unset',
            top: 'unset',
            left: 'unset',
            WebkitTransform: 'unset',
            transform: 'unset',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'unset',
            margin: '0.5rem auto 0',
            padding: 0,
            // height: '100%',
            width: 'calc(100vw - 1rem)',
            borderRadius: '0.625rem',
        },
    },
    modalContent: {
        marginTop: 0,
        width: '100% !important',
        marginLeft: '0rem',
    },
    category: {
        marginTop: '20px',
        [theme.breakpoints.down('sm')]: {
            marginTop: '16px',
        },
    },
    close: {
        color: theme.palette.primary.sunset,
        fontSize: theme.typography.h4.fontSize,
        [theme.breakpoints.down('sm')]: {
            fontSize: theme.typography.h4.fontSize,
        },
    },
    subModalContent: {
        position: 'relative',
        overflowY: 'scroll',
        marginTop: '0rem !important',
        paddingTop: '0rem !important',
        paddingLeft: '48px !important',
        marginTop: 0,
        paddingRight: '48px !important',

        [theme.breakpoints.between('sm', 'md')]: {
            paddingLeft: '24px !important',
            marginTop: 0,
            paddingRight: '24px !important',
            height: '53rem',
            '&::-webkit-scrollbar': {
                width: '0.813rem',
            },
            '&::-webkit-scrollbar-track': {
                boxShadow: `inset 0 0 6px ${theme.palette.primary.boxShadow4}`,
                webkitBoxShadow: `inset 0 0 6px ${theme.palette.primary.boxShadow4}`,
                border: `1px solid ${theme.palette.primary.borderColor}`,
                height: '100%',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.primary.gray550,
                border: '0.25rem solid transparent',
                borderRadius: '10rem',
                backgroundClip: 'padding-box',
            },
        },
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '16px !important',
            paddingRight: '16px !important',
        },
    },
    title: {
        marginTop: '10px',
        textAlign: 'center',
        color: theme.palette.primary.heather,
        fontSize: theme.typography.h4.fontSize,
        fontFamily: theme.typography.h6.fontFamily,
        marginBottom: '10px',
        lineHeight: '32px',
        letterSpacing: '0.25px',
        [theme.breakpoints.down('sm')]: {
            fontSize: theme.typography.h4.fontSize,
            lineHeight: '28px',
            marginBottom: '4px',
            marginTop: '4px',
        },
    },
    categoryName: {
        fontFamily: theme.typography.subtitle1.fontFamily,
        fontStyle: 'normal',

        fontSize: '1rem',
        lineHeight: '1.5rem',
        color: theme.palette.primary.heather,
    },
    categoryCount: {
        fontFamily: theme.typography.body2.fontFamily,
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '0.75rem',
        lineHeight: '1.5rem',
        color: theme.palette.primary.heather80,
    },
    channelListMain: {
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '0.5rem !important',
        },
    },
    channelListRow: {
        // paddingTop: '10px',
        paddingBottom: '40px',
        [theme.breakpoints.down('sm')]: {
            paddingBottom: '4px',
            marginBottom: '12px',

            flexWrap: 'unset',
            overflow: 'overlay',
            '&::-webkit-scrollbar': {
                display: 'none',
            },
        },
        [theme.breakpoints.up('sm')]: {
            flexWrap: 'wrap',
        },
    },
    channelListRowElement: {
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '0rem !important',
            marginLeft: '1rem',
            paddingTop: '0rem !important',
            height: '4.19rem',
            maxWidth: '4.19rem',
            marginBottom: '8px',
            marginTop: '8px',
        },
    },
    channelIcon: {
        width: '3.75rem',
        height: '3.75rem',
        marginRight: '8px',
        borderRadius: '52rem',
        filter: 'drop-shadow(2px 2px 2px rgba(0,0,0,0.1))',
        [theme.breakpoints.down('sm')]: {
            width: '4.19rem',
            height: '4.19rem',
            marginBottom: '8px',
            marginTop: '8px',
        },
    },
}));

export default styles;
