import {
    getOnboardingScreenContentsApi,
    getOnboardingStatusApi,
    setOnboardingStatusApi,
} from './onboarding.api';

const handleOnboardingData = async ({
    graphql,
    setWelcomeData,
    setOnboardingData,
    setOtherScreensData,
    setOtherScreenObjectLength,
    setWelcomeObjectLength,
    setIsApiFails,
    setIsLoading,
}) => {
    try {
        // call onboarding ocat api here

        const response = await getOnboardingScreenContentsApi({ graphql });
        setOnboardingData(response);
        setIsLoading(true);

        if (response?.welcomeScreen) {
            setWelcomeData(response.welcomeScreen);
            setWelcomeObjectLength(() =>
                response.welcomeScreen ? Object.keys(response.welcomeScreen)?.length : 0
            );
        }
        if (response?.otherScreen) {
            setOtherScreensData(response.otherScreen);
            setOtherScreenObjectLength(() =>
                response.otherScreen ? response.otherScreen?.length : 0
            );
        }
    } catch (error) {
        setIsApiFails(true);
        setIsLoading(true);
        console.error(error);
    }
};

/**
 *
 * @name getOnboardingStatus -gets the onboarding status of the user
 * @param {Object} Obj - attributes for the function
 * @param {GraphQL} Obj.graphql - appoloclient graphql constant
 * @param {useState} Obj.setOnboardingStatus - useState function which sets whether the onboarding screen needs to be displayed or not
 */

export const getOnboardingStatus = async ({
    graphql,
    setShowOnBoarding,
    setWelcomeData,
    setOnboardingData,
    setOtherScreensData,
    setOtherScreenObjectLength,
    setWelcomeObjectLength,
    setIsApiFails,
    setIsLoading,
}) => {
    try {
        const response = await getOnboardingStatusApi({ graphql });
        setIsLoading(true);
        sessionStorage.setItem(
            'OnboardingVersion',
            response.data.getOnboardingStatus.ONBOARDING_VERSION
        );
        if (response) {
            if (response.data.getOnboardingStatus.SHOW_ONBOARDING_STATUS === false) {
                setShowOnBoarding(false);
            } else {
                setShowOnBoarding(true);
            }
        }
    } catch (error) {
        console.error(error);
        setIsLoading(true);
    } finally {
        handleOnboardingData({
            graphql,
            setWelcomeData,
            setOnboardingData,
            setOtherScreensData,
            setOtherScreenObjectLength,
            setWelcomeObjectLength,
            setIsApiFails,
            setIsLoading,
        });
    }
};

/**
 * @name setOnboardingStatus -set the onboarding status to false when user is present in db
 * @param {Object} Obj - attributes for the function
 * @param {GraphQL} Obj.graphql - appoloclient graphql constant
 * @param {useState} Obj.setOnboardingStatus - useState function which sets whether the onboarding screen needs to be displayed or not
 *
 */
export const setOnboardingStatus = async ({ graphql, setShowOnBoarding }) => {
    try {
        const response = await setOnboardingStatusApi({ graphql });

        if (response) {
            setShowOnBoarding(false);
        } else {
            setShowOnBoarding(true);
        }
    } catch (error) {
        console.error(error);
    }
};
