import React, { useEffect, useState } from 'react';
// import HeaderContainer from '../HeaderContainer';
// import FooterContainer from '../FooterContainer';
import MyEirPhoneNumber from './MyEirPhoneNumber';
import { useHistory } from 'react-router-dom';
import MyEirVerificationCode from './MyEirVerificationCode';
import MyEirEnterAccountNumber from './MyEirEnterAccountNumber';
import MyEirRegisterEmail from './MyEirRegisterEmail';
import MyEirPrepayForm from './MyEirPrepayForm';
import { Box } from '@material-ui/core';
import MyEirAlreadyRegistered from './MyEirAlreadyRegistered';
import MyEirEmailConfirmationModal from './MyEirEmailConfirmationModal';
import { clearSessions, sendEmailVerification } from './register.util';
import { useApolloClient } from '@apollo/client';
import MyEirEmailAddress from './MyEirEmailAddress';

function RegisterContainer() {
    const graphql = useApolloClient();
    const [step, setStep] = useState(0);
    const [openModal1, setOpenModal1] = useState(false);
    const [openModal2, setOpenModal2] = useState(false);
    const [flag, setFlag] = useState(false);

    const toggleModal1 = () => setOpenModal1(!openModal1);
    const toggleModal2 = () => setOpenModal2(!openModal2);

    const FIRST_STEP = 0;
    const LAST_STEP = 3;

    const history = useHistory();
    const handleBackButton = () => {
        if (step == FIRST_STEP) {
            clearSessions();
            history.push('/');
        } else if (step == 4) {
            setStep(1);
        } else if (step == 5) {
            setStep(0);
        } else if (step == 2 && flag === false) {
            setStep(step - 2);
        } else if (step == 2 && flag === true) {
            setStep(step - 1);
        } else {
            setStep(step - 1);
        }
    };

    const handleNext = (jumpTo = false) => {
        if (jumpTo != false) {
            setStep(jumpTo);
        } else if (step === 0) {
            setStep(step + 2);
        } else if (step === 1) {
            setStep(step + 1);
            setFlag(true);
        } else {
            setStep(step + 2);
        }
    };

    // open modal or skip to prepay form
    const handleUserVerification = async (data, skip) => {
        if (skip) {
            handleNext(skip);
            return;
        }
        try {
            // console.log(resp.data);
            sessionStorage.setItem('reg_email', data.username);
            sessionStorage.setItem('reg_email_status', data.emailStatus);
            const type = sessionStorage.getItem('reg_bill_type');
            // PREPAY or BILLPAY, for both it will show email status modal
            // PREPAY - skip to prepay form screen

            if (!Boolean(data.username) && type === 'PREPAY')
            {
                handleNext(5); // open prepay register form since username is null & status unverified
            }
             
            else if (data.emailStatus.toUpperCase() === 'VERIFIED') {

                toggleModal1(); // verified - open verified email modal
            } else if (data.emailStatus.toUpperCase() === 'UNVERIFIED') {

                const emailVerificationStatus = await sendEmailVerification({ graphql });
                if (emailVerificationStatus === 'success') {
                    toggleModal2(); // unverified - open unverified email modal
                }
                // else it should throw some error message
            } else if (data.emailStatus.toUpperCase() === 'DUMMY'){

                handleNext(4); // Dummy email - skip to create email screen
            } 
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        return () => {
            // console.log('unmount register container');
            clearSessions();
        };
    }, []);

    return (
        <Box className='my-eir-register-page'>
            {
                {
                    0: (
                        <MyEirPhoneNumber
                            handleBackButton={handleBackButton}
                            handleNext={handleNext}
                            headerText='Register'
                        />
                    ),
                    1: (
                        <MyEirEmailAddress
                            handleBackButton={handleBackButton}
                            handleNext={handleNext}
                            headerText='Register'
                            setFlag={setFlag}
                        />
                    ),

                    2: (
                        <MyEirVerificationCode
                            handleBackButton={handleBackButton}
                            handleNext={handleUserVerification}
                            headerText='Register'
                            setFlag={setFlag}
                        />
                    ),
                    3: (
                        <MyEirEnterAccountNumber
                            handleBackButton={handleBackButton}
                            handleNext={handleUserVerification}
                            headerText='Register'
                        />
                    ),
                    4: (
                        <MyEirRegisterEmail
                            handleBackButton={handleBackButton}
                            handleNext={handleNext}
                        />
                    ),
                    5: (
                        <MyEirPrepayForm
                            handleBackButton={handleBackButton}
                            handleNext={handleNext}
                        />
                    ),
                }[step]
            }
            {/* for verified email modal */}
            <MyEirAlreadyRegistered
                toggle={toggleModal1}
                isOpen={openModal1}
                heading={'Register successful'}
                headingMobile={'Register'}
                newsContentText={'Good news!'}
                verifiedText={'Looks like you’re already registered'}
                verifiedTextMobile={'Looks like you’re already registered'}
                subtext='If you have already set your password you can log in, or you can reset your password below.'
                buttonText={'Reset password '}
                footerText={<a>Back to login</a>}
            />
            {/* for unverified email modal */}
            <MyEirEmailConfirmationModal
                toggle={toggleModal2}
                isOpen={openModal2}
                registeredEmail={sessionStorage.getItem('reg_email')}
                heading={"You're almost there"}
                subHeading={'Your account registration is not yet complete'}
                text={
                    'To complete the registration process, please verify your email and follow the instructions to set your password'
                }
            />
        </Box>
    );
}
export default RegisterContainer;
