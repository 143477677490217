import React, { useState, Fragment, useEffect } from 'react';
import { Grid, Container, Button, Paper } from '@material-ui/core';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { useAddonStyle } from './useAddonStyle';
import MyEirRecurringAddons from './MyEirRecurringAddons';
import MyEirOneOffDataAddon from './MyEirOneOffDataAddon';
import MyEirRoamingAddon from './MyEirRoamingAddon';
const theme = createMuiTheme({
    typography: {
        textTransform: 'none',
        textAlign: 'left',
        letterSpacing: '0px',
        fontFamily: 'gt-walsheim-regular',
        opacity: 1,
        fontSize: '16px',
    },
    palette: {
        primary: {
            main: '#892BA0',
        },
    },
});
function MyEirAddonContainer() {
    const classes = useAddonStyle();
    return (
        <Fragment>
            <ThemeProvider theme={theme}>
                <div className={classes.Addons}>
                    <Container>
                        <div className='regform'>
                            <h1 className='profileTitle'>My add ons</h1>
                            <p className='subText'>
                                Running out of data? We’ve got great options for you! Select a
                                recurring Add-on and we’ll add it for you automatically every 4
                                weeks or buy a Booster for a once-off top up of data.
                            </p>
                            <Paper className={classes.paper + ' ' + 'paddingTop'}>
                                <Container>
                                    <MyEirRecurringAddons />
                                </Container>
                            </Paper>
                            <Paper className={classes.paper + ' ' + 'paddingTop'}>
                                <Container>
                                    <MyEirOneOffDataAddon />
                                </Container>
                            </Paper>
                            <Paper className={classes.paper + ' ' + 'paddingTop'}>
                                <Container>
                                    <MyEirRoamingAddon />
                                </Container>
                            </Paper>
                        </div>
                    </Container>
                </div>
            </ThemeProvider>
        </Fragment>
    );
}

export default MyEirAddonContainer;
