import React,{ Fragment, useState } from 'react';
import { Box, Typography,Checkbox } from '@material-ui/core';
import useStyles from './style';

const MyEirSimBlockMyService =({setIsTicked})=>{

    const classes = useStyles();
    const [checked,setChecked] = useState(false);
    
    const handleCheckBox=()=>{
        setChecked(!checked);
        setIsTicked(!checked);
    }
    
    return(
        <Fragment>
            <Box className={classes.eirContainer}>
                <Box className={`${classes.delivery} ${classes.marginBlock}`}>Block my service</Box>
                <Box className={classes.blockText}>
                    <Typography className={`${classes.text} ${classes.serviceText}`}>
                    If your SIM is lost or stolen you can block services on your SIM.
                    Remember this will block the services on your SIM immediately and you won’t be able to use your phone until your replacement SIM arrives.
                    Once your replacement SIM is activated your services will be unblocked.
                    </Typography>
                </Box>
                <Box className={classes.checkBlock}>
                    <Checkbox className={classes.checkbox} checked={checked} onClick={handleCheckBox}/> 
                    <Typography className={classes.checkText} >I also want to block my services</Typography>
                </Box>
            </Box>
        </Fragment>
    )
}

export default MyEirSimBlockMyService;