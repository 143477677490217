import React, { useEffect, useState } from 'react';
import { useVerificationCodeStyles } from '../css/useVerificationCodeStyles';
import { TextField, Grid,InputLabel ,FormControl} from "@material-ui/core";
import { commonRegisterStyles } from '../css/myEirRegistrationStyles/commonRegisterStyles';
import MyEirRegisterHeader from './reusable/MyEirRegisterHeader';
import MyEirRegisterFooter from './reusable/MyEirRegisterFooter';

function MyEirDOB(){

    const useStyle= useVerificationCodeStyles();
    const commonStyle =commonRegisterStyles();
    
    const [active,setActive] = useState(false);
    const [error,setError] = useState(false);
    
    const [dob, setDob] = useState({
        day:'',
        month:'',
        year:'',
        
    });

    // useEffect(()=>{
    //     if(dob.day || dob.month || dob.year){

    //         if(/\D/.test(dob.day || dob.month || dob.year)){
    //             console.log('Not a numeric value');
    //             setError(true);
    //         }else{
    //             setError(false);
    //             setActive(true);
    //         }

    //     }
    //     else{
    //         setError(false);
    //         setActive(false);
    //     }
        

    // },[(dob.day || dob.month || dob.year)])


    const handleChange=(event)=>{
        const value= event.target.value;
        const name= event.target.name;

        setDob((preval)=>{
            return{
                ...preval,
                [name]:value,
            }
        })
        if(dob.day!='' && dob.month!='' && dob.year!=''){
            // if((dob.day > 0 && dob.day <32) && (dob.month > 0 && dob.month <13) && (dob.year.length==4) ){
            //     console.log('year length',dob.year.length)
            //      setActive(true);
            // }else{
            //     console.log('Invalid Date of birth...');
            //     setActive(false);
            // }
            
            return setActive(true);
        }else{
            setActive(false);
        }

        

    }


    return(
        <div className={commonStyle.registrationContainer}>
        <div className={commonStyle.cardContainer}>
           
             <MyEirRegisterHeader headerText='Verify your date of birth'/>

         {/* body part */}
            <div className={`${useStyle.body} verification-body`}>

                 <Grid container spacing={2} className='dob-container'>
                     <Grid item xs={4}>
                        <TextField className={useStyle.codes} variant='outlined' id='dob' label='DD' color='primary'
                            value={dob.day}
                            name='day'
                            onChange={handleChange}
                            

                            InputLabelProps={{classes:{ root: "text-field-input-label-props-root", shrink:("text-field-input-label-props-shrink"+
                             (error?" text-field-input-label-props-shrink-invalid":"")), focused:("text-field-input-label-props-focused"+
                             (error?" text-field-input-label-props-focused-invalid":""))}}}
                             InputProps={{classes:{ root: ("text-field-input-props-root"+
                             (!!dob.day?" text-field-input-props-root-filled":"")), focused:"text-field-input-props-focused",  notchedOutline: ("text-field-input-props-notchedOutline"+
                             (error?" text-field-input-props-notchedOutline-invalid":"")), input: "text-field-input-props-input"}}}

                            inputProps={{ inputmode: 'numeric', pattern: '[0-9]*', maxLength: 2, }}/>
                     </Grid>
                     <Grid item xs={4}> 
                        <TextField className={useStyle.codes} variant='outlined' id='dob' label='MM' color='primary'
                            value={dob.month}
                            name='month'
                            onChange={handleChange}
                            

                            InputLabelProps={{classes:{ root: "text-field-input-label-props-root", shrink:("text-field-input-label-props-shrink"+
                             (error?" text-field-input-label-props-shrink-invalid":"")), focused:("text-field-input-label-props-focused"+
                             (error?" text-field-input-label-props-focused-invalid":""))}}}
                             InputProps={{classes:{ root: ("text-field-input-props-root"+
                             (!!dob.month?" text-field-input-props-root-filled":"")), focused:"text-field-input-props-focused",  notchedOutline: ("text-field-input-props-notchedOutline"+
                             (error?" text-field-input-props-notchedOutline-invalid":"")), input: "text-field-input-props-input"}}}

                            inputProps={{ inputmode: 'numeric', pattern: '[0-9]*', maxLength: 2, }}/> 
                     
                     </Grid>
                     <Grid item xs={4}> 
                        <TextField className={useStyle.codes} variant='outlined' id='dob' label='YYYY' color='primary'
                            value={dob.year}
                            name='year'
                            onChange={handleChange}
                           

                            InputLabelProps={{classes:{ root: "text-field-input-label-props-root", shrink:("text-field-input-label-props-shrink"+
                             (error?" text-field-input-label-props-shrink-invalid":"")), focused:("text-field-input-label-props-focused"+
                             (error?" text-field-input-label-props-focused-invalid":""))}}}
                             InputProps={{classes:{ root: ("text-field-input-props-root"+
                             (!!dob.year?" text-field-input-props-root-filled":"")), focused:"text-field-input-props-focused",  notchedOutline: ("text-field-input-props-notchedOutline"+
                             (error?" text-field-input-props-notchedOutline-invalid":"")), input: "text-field-input-props-input"}}}

                            inputProps={{ inputmode: 'numeric', pattern: '[0-9]*', maxLength: 4, }}/>
                     </Grid>
                     {/* {error ? <div className='invalid-messages'>Not a numeric value.</div> : ''} */}
                               

                 </Grid>
            </div>
            <MyEirRegisterFooter button1Text='Confirm' button1Enabled={active ? true : false} button2Text='Cancel'/>
        </div>
        </div>
    )
}

export default MyEirDOB