import React, { useState, useEffect } from 'react';
import { Box, Button, CircularProgress, Link, Modal, Typography } from '@material-ui/core';
import useStyle from './styles.js';
import MyEirErrorHandle from '../../eir-porting/MyEirErrorHandle';
import singleBanner from '../images/banner-offer.png';
import mobBanner from '../images/mob-banner.png';
import swishBg from '../images/Swish-bg.png';
import arrow from '../images/pinkArrow.png';
import MyEirNewErrorPopup from '../my-services/MyEirNewErrorPopup';
import { useWindowDimension } from '../../../hooks/windowDimensions.js';
import { useApolloClient } from '@apollo/client';
import SubscriptionSelectionModal from '../subscription-selection-modal/SubscriptionSelectionModal.js';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';
import getStrapiData from '../integration/strapi.client';
import EirChannelModal from '../eir-channel-modal/EirChannelModal';
import { useToggle } from '../../../hooks/useToggle';
import MyEirRegistrationErrorsPopup from '../../../sharedcomponents/MyEirPostpayRegistration/MyEirRegistrationErrorsPopup.js';
import EirButton from '../../../theme/EirButton.js';
import theme from '../../../theme/MyEirTheme.js';
import { ArrowBackIcon } from '../../../sharedcomponents/inputs/svg-icons/CustomSvgIcons.js';
const OfferUpgradeModal = ({
    attributesData,
    ctaName,
    isModalOpen,
    handleUpgrade,
    handleNotInterested,
    handleRedirectValues,
    openError,
    setOpenError,
    openChangeError,
    setOpenChangeError,
    setIsModalOpen,
    modalImage,
    openSelectionModal,
    setOpenSelectionModal,
    csTvData,
    selectedParentSubId,
    setSelectedParentSubId,
    notInterestedPopUp,
    setNotInterestedPopUp,
    errorText,
    attributes,
    contractType,
    price,
    isLoading,
    setErrorText,
    setIsLoading,
}) => {
    const classes = useStyle();
    const [width, height] = useWindowDimension();
    const [isMoreInfoClicked, setIsMoreInfoClicked] = useState(false);
    const eirChannelModal = useToggle(false);
    const [cmsDataValue, setCmsDataValue] = useState();
    const ineligibleMessage = 'Unfortunately you are not eligible to make changes at this time';

    function extractText(input) {
        const parser = new DOMParser();
        const doc = parser?.parseFromString(input, 'text/html');

        const aElement = doc?.querySelector('a');

        return {
            htmlText: doc?.body?.innerHTML?.replace(aElement?.outerHTML, '').trim(),
            aText: doc?.querySelector('a')?.innerText.trim(),
        };
    }

    const getStrapiDataContents = async () => {
        const cmsData = await getStrapiData('broadband');
        setCmsDataValue(cmsData);
        return cmsData;
    };

    const openDialog = () => {
        eirChannelModal.enable();
    };

    useEffect(() => {
        getStrapiDataContents();
    }, []);

    return (
        <Box className={classes.classDiv}>
            <Modal
                id='offer-upgrade-modal'
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                className={classes.modalContainer}
            >
                <Box className={classes.modalBody}>
                    <Box className={classes.banner}>
                        <Box
                            component='img'
                            className={classes.bannerImage}
                            src={modalImage}
                            alt='banner'
                        />
                        <Box className={classes.arrow}>
                            <ArrowBackIcon onClick={() => setIsModalOpen(false)} />
                        </Box>
                    </Box>
                    <Box className={classes.contract}>{attributesData?.sash}</Box>
                    {width >= 600 ? (
                        <Box className={classes.offerContent}>
                            <Box className={classes.listImage}>
                                <Box className={classes.swishImage} />
                                <Box className={classes.lists}>
                                    {attributesData?.descriptions?.map(item => {
                                        return (
                                            <Box className={classes.item}>
                                                <Box className={classes.circle}>
                                                    <Box
                                                        component='img'
                                                        className={classes.icon}
                                                        src={item?.icon}
                                                        alt=''
                                                    />
                                                </Box>
                                                {item?.value?.includes('tvChannelPopup') ? (
                                                    <Typography
                                                        component='p'
                                                        className={classes.textLight}
                                                        onClick={() => {
                                                            openDialog();
                                                        }}
                                                    >
                                                        <span
                                                            dangerouslySetInnerHTML={{
                                                                __html: item?.value,
                                                            }}
                                                        />
                                                    </Typography>
                                                ) : (
                                                    <Typography
                                                        component='p'
                                                        className={classes.textLight}
                                                    >
                                                        <span
                                                            dangerouslySetInnerHTML={{
                                                                __html: item?.value,
                                                            }}
                                                        />
                                                    </Typography>
                                                )}
                                            </Box>
                                        );
                                    })}
                                </Box>
                            </Box>

                            <Box className={classes.contentText}>
                                {attributesData?.title1 === undefined &&
                                attributesData?.title2 === undefined ? null : (
                                    <Typography>
                                        {attributesData?.title1?.includes('styledText') ? (
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html: attributesData?.title1 + ' ',
                                                }}
                                            />
                                        ) : (
                                            <span
                                                className={
                                                    contractType === 'addon'
                                                        ? classes.addonRegularText
                                                        : classes.regularText
                                                }
                                                dangerouslySetInnerHTML={{
                                                    __html: attributesData?.title1 + ' ',
                                                }}
                                            />
                                        )}
                                        <Typography component='span'>
                                            {attributesData?.title2?.includes('styledText') ? (
                                                <span
                                                    dangerouslySetInnerHTML={{
                                                        __html: attributesData?.title2 + ' ',
                                                    }}
                                                />
                                            ) : (
                                                <span
                                                    className={classes.regularText}
                                                    dangerouslySetInnerHTML={{
                                                        __html: attributesData?.title2,
                                                    }}
                                                />
                                            )}
                                        </Typography>
                                    </Typography>
                                )}

                                <Box className={classes.charges}>
                                    <Box className={classes.bold}>
                                        €
                                        {contractType === 'addon'
                                            ? (attributes?.charges?.price / 100).toFixed(2)
                                            : attributesData?.price}
                                    </Box>
                                    {contractType !== 'addon' ? (
                                        <Box className={classes.light}>
                                            a month for{' '}
                                            {contractType === 'addon'
                                                ? '24'
                                                : attributesData?.discountDuration
                                                ? attributesData?.discountDuration
                                                : attributesData?.commitmentDuration}{' '}
                                            Months
                                        </Box>
                                    ) : (
                                        <Box className={classes.light}>a month</Box>
                                    )}
                                </Box>
                                {contractType !== 'addon' && (
                                    <Typography className={classes.cpiTextLine}>
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: extractText(
                                                    attributesData?.priceIncreaseTagline?.[0]
                                                ).htmlText,
                                            }}
                                        />

                                        <Link
                                            href='https://eir.ie/annual-price-increase/'
                                            target='_blank'
                                        >
                                            <Typography
                                                component='span'
                                                className={classes.underlinedCpiText}
                                            >
                                                {
                                                    extractText(
                                                        attributesData?.priceIncreaseTagline?.[0]
                                                    ).aText
                                                }
                                            </Typography>
                                        </Link>
                                    </Typography>
                                )}
                                <Typography className={classes.message}>
                                    {attributesData?.description}
                                </Typography>
                                {contractType === 'addon' && (
                                    <Typography className={classes.addonNote}>
                                        {attributesData?.addonNote}
                                    </Typography>
                                )}
                                <Box className={classes.makePayContainer} onClick={handleUpgrade}>
                                    {!isLoading ? (
                                        <EirButton
                                            variants='primaryButtonMedium'
                                            style={{ padding: '0.5rem 2.5rem' }}
                                            // className={classes.paymentContent}
                                        >
                                            {ctaName}
                                        </EirButton>
                                    ) : (
                                        <EirButton
                                            variants='primaryButtonMedium'
                                            style={{ padding: '0.5rem 2.5rem' }}
                                            // className={classes.paymentContent}
                                        >
                                            <CircularProgress
                                                disableShrink
                                                style={{
                                                    color: theme.palette.primary.white,
                                                    width: '17px',
                                                    height: '17px',
                                                    marginRight: '5px',
                                                }}
                                            />
                                            {contractType === 'addon'
                                                ? 'Adding...'
                                                : 'Upgrading...'}
                                        </EirButton>
                                    )}
                                </Box>
                                <Box className={classes.link} onClick={handleNotInterested}>
                                    I’m not interested right now
                                </Box>
                            </Box>
                        </Box>
                    ) : (
                        <Box>
                            <Box className={classes.contentMobile}>
                                <Box className={classes.contentText}>
                                    {attributesData?.title1 === undefined &&
                                    attributesData?.title2 === undefined ? null : (
                                        <Typography>
                                            {attributesData?.title1?.includes('styledText') ? (
                                                <span
                                                    dangerouslySetInnerHTML={{
                                                        __html: attributesData?.title1 + ' ',
                                                    }}
                                                />
                                            ) : (
                                                <span
                                                    className={classes.regularText}
                                                    dangerouslySetInnerHTML={{
                                                        __html: attributesData?.title1 + ' ',
                                                    }}
                                                />
                                            )}
                                            <Typography component='span'>
                                                {attributesData?.title2?.includes('styledText') ? (
                                                    <span
                                                        dangerouslySetInnerHTML={{
                                                            __html: attributesData?.title2 + ' ',
                                                        }}
                                                    />
                                                ) : (
                                                    <span
                                                        className={classes.regularText}
                                                        dangerouslySetInnerHTML={{
                                                            __html: attributesData?.title2,
                                                        }}
                                                    />
                                                )}
                                            </Typography>
                                        </Typography>
                                    )}

                                    {/* <Box className={classes.charges}>
                                        <Box className={classes.bold}>€{attributesData?.price}</Box>
                                        <Box className={classes.light}>
                                            a month for{' '}
                                            {attributesData?.discountDuration
                                                ? attributesData?.discountDuration
                                                : attributesData?.commitmentDuration}{' '}
                                            Months
                                        </Box>
                                    </Box> */}
                                    <Box className={classes.charges}>
                                        <Box className={classes.bold}>
                                            €
                                            {contractType === 'addon'
                                                ? (attributes?.charges?.price / 100).toFixed(2)
                                                : attributesData?.price}
                                        </Box>
                                        {contractType !== 'addon' ? (
                                            <Box className={classes.light}>
                                                a month for{' '}
                                                {contractType === 'addon'
                                                    ? '24'
                                                    : attributesData?.discountDuration
                                                    ? attributesData?.discountDuration
                                                    : attributesData?.commitmentDuration}{' '}
                                                Months
                                            </Box>
                                        ) : (
                                            <Box className={classes.light}>a month</Box>
                                        )}
                                    </Box>
                                    {contractType !== 'addon' && (
                                        <Typography className={classes.cpiTextLine}>
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html: extractText(
                                                        attributesData?.priceIncreaseTagline?.[0]
                                                    ).htmlText,
                                                }}
                                            />
                                            <Link
                                                href='https://eir.ie/annual-price-increase/'
                                                target='_blank'
                                            >
                                                <Typography
                                                    component='span'
                                                    className={classes.underlinedCpiText}
                                                >
                                                    {
                                                        extractText(
                                                            attributesData
                                                                ?.priceIncreaseTagline?.[0]
                                                        ).aText
                                                    }
                                                </Typography>
                                            </Link>
                                        </Typography>
                                    )}
                                    <Typography className={classes.message}>
                                        {attributesData?.description}
                                    </Typography>
                                    {contractType === 'addon' && (
                                        <Typography className={classes.addonNote}>
                                            {attributesData?.addonNote}
                                        </Typography>
                                    )}
                                </Box>
                            </Box>
                            <Box className={classes.listImage}>
                                <Box className={classes.lists}>
                                    {attributesData?.descriptions?.map(item => {
                                        return (
                                            <Box className={classes.item}>
                                                <Box className={classes.circle}>
                                                    <Box
                                                        component='img'
                                                        className={classes.icon}
                                                        src={item?.icon}
                                                        alt=''
                                                    />
                                                </Box>

                                                {item?.value?.includes('tvChannelPopup') ? (
                                                    <Typography
                                                        component='p'
                                                        className={classes.textLight}
                                                        onClick={() => {
                                                            openDialog();
                                                        }}
                                                    >
                                                        <span
                                                            dangerouslySetInnerHTML={{
                                                                __html: item?.value,
                                                            }}
                                                        />
                                                    </Typography>
                                                ) : (
                                                    <Typography
                                                        component='p'
                                                        className={classes.textLight}
                                                    >
                                                        <span
                                                            dangerouslySetInnerHTML={{
                                                                __html: item?.value,
                                                            }}
                                                        />
                                                    </Typography>
                                                )}
                                            </Box>
                                        );
                                    })}
                                </Box>
                                <Box className={classes.makePayContainer} onClick={handleUpgrade}>
                                    {!isLoading ? (
                                        <EirButton
                                            variants='primaryButtonMedium'
                                            style={{ padding: '0.5rem 2.5rem' }}
                                            // className={classes.paymentContent}
                                        >
                                            {ctaName}
                                        </EirButton>
                                    ) : (
                                        <EirButton
                                            variants='primaryButtonMedium'
                                            style={{ padding: '0.5rem 2.5rem' }}
                                            // className={classes.paymentContent}
                                        >
                                            <CircularProgress
                                                disableShrink
                                                style={{
                                                    color: theme.palette.primary.white,
                                                    width: '17px',
                                                    height: '17px',
                                                    marginRight: '5px',
                                                }}
                                            />
                                            {contractType === 'addon'
                                                ? 'Adding...'
                                                : 'Upgrading...'}
                                        </EirButton>
                                    )}
                                </Box>
                                <Box className={classes.link} onClick={handleNotInterested}>
                                    I’m not interested right now
                                </Box>
                            </Box>
                        </Box>
                    )}
                    {contractType !== 'addon' && (
                        <Typography
                            className={classes.moreInfo}
                            onClick={() => setIsMoreInfoClicked(!isMoreInfoClicked)}
                        >
                            {!isMoreInfoClicked ? (
                                <>
                                    More info{' '}
                                    <Typography
                                        component='span'
                                        style={{ paddingLeft: '16px', display: 'flex' }}
                                    >
                                        <ExpandMoreIcon style={{ height: '16px', width: '16px' }} />
                                    </Typography>
                                </>
                            ) : (
                                <>
                                    {attributesData?.afterPrice}
                                    <Typography
                                        component='span'
                                        style={{ paddingLeft: '16px', display: 'flex' }}
                                    >
                                        <CloseIcon style={{ height: '16px', width: '16px' }} />
                                    </Typography>
                                </>
                            )}
                        </Typography>
                    )}
                </Box>
            </Modal>
            {openError ? <MyEirErrorHandle open={openError} setOpen={setOpenError} /> : ''}
            {openChangeError ? (
                <MyEirNewErrorPopup
                    open={openChangeError}
                    setOpen={setOpenChangeError}
                    message={ineligibleMessage}
                />
            ) : (
                ''
            )}
            {openSelectionModal ? (
                <SubscriptionSelectionModal
                    openSelectionModal={openSelectionModal}
                    setOpenSelectionModal={setOpenSelectionModal}
                    csTvData={csTvData}
                    modalImage={modalImage}
                    selectedParentSubId={selectedParentSubId}
                    setSelectedParentSubId={setSelectedParentSubId}
                    handleRedirectValues={handleRedirectValues}
                    setIsModalOpen={setIsModalOpen}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                />
            ) : (
                ''
            )}
            {eirChannelModal.value && (
                <EirChannelModal
                    heading='eir TV'
                    id='channel-modal'
                    open={eirChannelModal.value}
                    handleClose={eirChannelModal.disable}
                    disableCloseOnBackdropClick={false}
                    disableCloseOnEscapeKeyDown={false}
                    tvChannels={cmsDataValue?.data?.attributes?.TVChannels || []}
                />
            )}
            {notInterestedPopUp ? (
                <MyEirRegistrationErrorsPopup
                    open={notInterestedPopUp}
                    setOpen={setNotInterestedPopUp}
                    headText={errorText.headText}
                    subText={errorText.subText}
                />
            ) : (
                ''
            )}
        </Box>
    );
};

export default OfferUpgradeModal;
