import ENDPOINT from './graphql/GraphQLConstants';
import addressFormatter from './redesign-5.2/redesign.util';

export async function getMyAddresses(graphql) {
    try {
        let getdeliveryAddress;
        let getBillingAddress;
        const token = sessionStorage.getItem('access_token');
        if (token) {
            const response = await graphql.query({
                query: ENDPOINT.GET_SUBSCRIPTION_ID_AND_CONTACT,
                variables: {
                    token: token,
                },
            });
            let data = response.data;

            if (data?.getMyContactDetails) {
                getdeliveryAddress = data?.getMyContactDetails?.addresses?.find(
                    address => address.type === 'DELIVERY'
                );
                getBillingAddress = data?.getMyContactDetails?.addresses?.find(
                    address => address.type === 'BILLING'
                );
            }
            return {
                deliveryAddress: addressFormatter(getdeliveryAddress),
                deliveryAddressId: getdeliveryAddress?.id,
                billingAddress: addressFormatter(getBillingAddress),
                billingAddressId: getBillingAddress?.id,
            };
        } else {
            console.log('Unable to fetch Address Details : Invalid Token');
        }
    } catch (error) {
        console.error('Error in fetching Addresses : ' + error.message);
    }
}
