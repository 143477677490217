import { makeStyles } from '@material-ui/core/styles';

export const useResetPasswordFormStyles = makeStyles(theme => ({
    bodyContainer: {
        minHeight: '155px',
        paddingTop: '42px',
        paddingBottom: '40px',
        position: 'relative',

        '@media(max-width: 599px)': {
            minHeight: '294px',
            paddingTop: '65px',
            paddingBottom: '24px',
            paddingLeft: '16px',
            paddingRight: '16px',
        },

        '& .register-email-body': {
            maxWidth: '376px',
            margin: '0px auto',

            '& input[type=number]::-webkit-inner-spin-button,& input[type=number]::-webkit-outer-spin-button': {
                WebkitAppearance: 'none',
                margin: '0',
            },
            '& input[type=number]': {
                MozAppearance: 'textfield',
            },
        },

        '& .register-grid-text-content': {
            // fontFamily: "gt-walsheim-regular",
            // fontStyle: "normal",
            // fontWeight: 400,
            // fontSize: "14px",
            // lineHeight: "22px",
            // color: "rgba(50, 52, 53, 0.87)",
            color: theme.palette.primary.heather,

            textAlign: 'left',
        },

        ' & .register-email-grid-email, & .register-email-grid-confirm-email': {
            marginBottom: '24px',
            height: '56px',
            '@media(max-width: 599px)': {
                marginBottom: '27px',
            },

            ' & .register-grid-input-text-field': {
                width: '100%',
                height: '56px',
                fontFamily: theme.typography.body2.fontFamily,
                fontStyle: 'normal',
                fontWeight: 400,
                '& .email-code': {
                    width: '376px',
                    color: theme.palette.primary.heather,
                    borderColor: theme.palette.primary.heather,
                    [theme.breakpoints.down('xs')]: {
                        width: '248px',
                    },
                },

                ' & .text-field-input-label-props-root': {
                    color: theme.palette.primary.heather80,
                    fontFamily: theme.typography.body2.fontFamily,
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: theme.typography.body1.fontSize,
                    lineHeight: '19px',
                    letterSpacing: '0.15px',
                    marginTop: '6px',
                },
                '& .text-field-input-label-props-focused, & .text-field-input-label-props-shrink': {
                    color: theme.palette.primary.heather,
                    // fontSize: "12px",
                    lineHeight: '16px',
                    marginTop: '0px !important',
                },
                '& .text-field-input-label-props-shrink-tempRemoval': {
                    marginTop: '0px !important',
                },
                '& .label-success': {
                    color: '#4caf50 !important',
                },
                '& .text-field-input-label-props-focused-invalid, & .text-field-input-label-props-shrink-invalid': {
                    color: theme.palette.primary.error,
                    marginTop: '0px !important',
                },
                '& .text-field-input-label-props-shrink-nonMatch': {
                    color: theme.palette.primary.error,
                    marginTop: '0px !important',
                },
                '& .new-password-root': {
                    color: theme.palette.primary.heather,
                    fontFamily: theme.typography.body2.fontFamily,
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: theme.typography.body1.fontSize,
                    lineHeight: '19px',
                    letterSpacing: '0.15px',

                    padding: '8px',
                    '&.new-password-input-focused': {
                        '& .new-password-input-notched': {
                            borderColor: theme.palette.primary.heather,
                        },
                    },
                    '&:hover': {
                        '& .new-password-input-notched': {
                            borderColor: theme.palette.primary.heather,
                        },
                    },
                    '&.new-password-error': {
                        '& .new-password-input-notched': {
                            borderColor: `${theme.palette.primary.error} !important`,
                            border: '2px solid',
                        },
                    },
                    '&.new-password-success': {
                        '& .new-password-input-notched': {
                            border: '2px solid #4caf50 !important',
                        },
                    },
                    '& .new-password-input-notched': {
                        borderColor: theme.palette.primary.heather,
                    },
                },
            },

            '& .invalid-email-format': {
                fontFamily: theme.typography.inputLabel.fontFamily,
                // fontStyle: 'normal',
                // fontWeight: 400,
                // fontSize:theme.typography.inputLabel.fontSize,
                // lineHeight: '20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                letterSpacing: '0.4px',
                color: theme.palette.primary.error,

                marginTop: '3px',
            },
        },
    },
    emailText: {
        marginTop: '43px',
        marginBottom: '24px',
        maxWidth: '376px',
        maxHeight: '44px',
        textAlign: 'left',
        fontFamily: theme.typography.body2.fontFamily,
        fontWeight: 'normal',
        fontSize: theme.typography.subtitle2.fontSize,
        lineHeight: '157%',
        color: theme.palette.primary.heather,
        margin: '0 auto',
        [theme.breakpoints.down('xs')]: {
            marginTop: '24px',
            maxWidth: '248px',
            paddingLeft: '8px',
            paddingRight: '8px',
        },
    },
    listTag: {
        fontFamily: 'eirsans-bold',
        fontSize: '14px',
        fontWeight: 700,
        width: 'calc(100% + 16px)',
        margin: '0 auto',
        maxWidth: '376px',
        textAlign: 'start',
        paddingLeft: '8px',
        [theme.breakpoints.down('xs')]: {
            fontSize: '12px',
        },
    },
    list: {
        listStyleType: 'none',
        padding: 0,
        margin: 0,
    },
    icon: {
        width: '12px',
    },
    listOptions: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '3px',
    },
    policy: {
        paddingLeft: '6.5px',
        fontSize: '12px',
        fontWeight: 300,
        fontFamily: 'eirsans-light',
    },
}));
