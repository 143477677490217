import { makeStyles } from '@material-ui/core/styles';

export const useVerificationCodeStyles = makeStyles(theme => ({
    body: {
        minHeight: '155px',
        margin: '0 auto',
        maxWidth: '391px',
        '@media(min-width:600px)': {
            paddingBottom: '40px',
        },
        [theme.breakpoints.down('xs')]: {
            minHeight: '294px',
            width: '256px',
        },
        '& .verification-code': {
            marginTop: '18px',
            width: '387px',
            color: theme.palette.primary.heather,
            borderColor: theme.palette.primary.heather,
            [theme.breakpoints.down('xs')]: {
                marginTop: '23px',
                width: '256px',
            },
        },

        '& .email-code': {
            width: '376px',
            color: theme.palette.primary.heather,
            borderColor: theme.palette.primary.heather,
            [theme.breakpoints.down('xs')]: {
                width: '248px',
            },
        },
        '& .reset-password': {
            marginTop: '51px',
            [theme.breakpoints.down('xs')]: {
                marginTop: '66px',
            },
        },
        '& .confirm-password': {
            marginTop: '21px',
            [theme.breakpoints.down('xs')]: {
                marginTop: '26px',
            },
        },

        '& .invalid-messages': {
            fontSize: theme.typography.inputLabel.fontSize,
            lineHeight: theme.typography.inputLabel.lineHeight,
            letterSpacing: '0.4px',
            color: theme.palette.primary.error,
            marginTop: '3px',
            fontFamily: theme.typography.inputLabel.fontFamily,
            fontStyle: 'normal',
            fontWeight: theme.typography.inputLabel.fontWeight,
            textAlign: 'left',
        },

        '& .dob-container': {
            marginTop: '51px',
            '@media(max-width: 599px)': {
                marginTop: '59px',
            },
        },
    },
    text: {
        marginTop: '42px',
        marginBottom: '16px',
        maxWidth: '387px',
        maxHeight: '44px',
        textAlign: 'left',
        fontFamily: theme.typography.body1.fontFamily,
        fontWeight: theme.typography.body1.fontWeight,
        fontSize: theme.typography.subtitle2.fontFamily,
        lineHeight: '157%',
        color: theme.palette.primary.heather,

        [theme.breakpoints.down('xs')]: {
            marginTop: '24px',
        },
    },
    middleText: {
        fontWeight: theme.typography.body1.fontWeight,
        //marginTop: '42px',
        //marginBottom: '16px',
        maxWidth: '256px',
        //maxHeight: '66px',
        textAlign: 'left',
        fontFamily: theme.typography.body1.fontFamily,
        fontSize: theme.typography.subtitle2.fontFamily,
        lineHeight: '157%',
        color: theme.palette.primary.heather,
        display: 'inline',
    },
    lastText: {
        display: 'inline',
        fontFamily: theme.typography.body1.fontFamily,
        fontSize: theme.typography.subtitle2.fontFamily,
        fontWeight: theme.typography.body1.fontWeight,
        color: theme.palette.primary.heather,
        [theme.breakpoints.down('xs')]: {
            display: 'block',
        },
    },

    emailText: {
        marginTop: '43px',
        marginBottom: '24px',
        maxWidth: '376px',
        maxHeight: '44px',
        textAlign: 'left',
        fontWeight: theme.typography.body2.fontWeight,
        fontSize: theme.typography.body2.fontSize,
        fontFamily: theme.typography.body2.fontFamily,
        lineHeight: '157%',
        color: theme.palette.primary.heather,
        margin: '0 auto',
        [theme.breakpoints.down('xs')]: {
            marginTop: '24px',
            maxWidth: '248px',
            paddingLeft: '8px',
            paddingRight: '8px',
        },
    },

    msg: {
        maxHeight: '44px',
        textAlign: 'left',
        fontWeight: theme.typography.body2.fontWeight,
        fontSize: theme.typography.body2.fontSize,
        lineHeight: '157%',
        color: theme.palette.primary.heather,
        fontFamily: theme.typography.body2.fontFamily,
        [theme.breakpoints.down('xs')]: {
            marginBottom: '56px',
        },
    },
    accountnumberLink: {
        color: theme.palette.primary.heather,
        textDecoration: 'underline',
        fontWeight: theme.typography.body3Medium.fontWeight,
        fontSize: theme.typography.subtitle2.fontSize,
        display: 'inline',
        fontFamily: theme.typography.body3Medium.fontFamily,
        cursor: 'pointer',
    },
    phone: {
        marginBottom: '3px',
        maxWidth: '203px',
        maxHeight: '16px',
        textAlign: 'left',
        fontFamily: theme.typography.caption.fontFamily,
        fontWeight: theme.typography.caption.fontWeight,
        fontSize: theme.typography.caption.fontSize,
        lineHeight: theme.typography.caption.lineHeight,
        color: theme.palette.primary.heather,
    },
    number: {
        maxWidth: '203px',
        maxHeight: '21px',
        textAlign: 'left',
        fontFamily: theme.typography.caption.fontFamily,
        fontWeight: theme.typography.caption.fontWeight,
        fontSize: theme.typography.caption.fontSize,
        lineHeight: theme.typography.caption.lineHeight,
        color: theme.palette.primary.heather,
    },
    codes: {
        ' & .text-field-input-label-props-root': {
            color: theme.palette.primary.heather,
            fontFamily: theme.typography.inputText.fontFamily,
            fontStyle: 'normal',
            fontWeight: theme.typography.inputText.fontWeight,
            fontSize: theme.typography.inputText.fontSize,
            lineHeight: theme.typography.inputText.lineHeight,
            letterSpacing: '0.15px',
            // paddingTop:'6px',
        },
        '& .text-field-input-label-props-focused, & .text-field-input-label-props-shrink': {
            color: theme.palette.primary.heather80,
            // fontSize: "12px",
            lineHeight: '16px',
        },
        '& .text-field-input-label-props-focused-invalid, & .text-field-input-label-props-shrink-invalid': {
            color: theme.palette.primary.error,
        },
        ' & .text-field-input-props-root': {
            '& .text-field-input-props-input': {
                color: theme.palette.primary.heather,
                fontFamily: theme.typography.inputText.fontFamily,
                fontStyle: 'normal',
                fontWeight: theme.typography.inputText.fontWeight,
                fontSize: theme.typography.inputText.fontSize,
                lineHeight: theme.typography.inputText.lineHeight,
                letterSpacing: '0.15px',

                padding: '19px 12px 18px 12px',
            },
        },
        ' & .text-field-input-props-root-filled': {
            ' & .text-field-input-props-notchedOutline': {
                borderColor: theme.palette.primary.heather80,
                borderWidth: '2px',
            },
            ' & .text-field-input-props-notchedOutline-invalid': {
                borderColor: theme.palette.primary.error,
            },
        },
        ' & .text-field-input-props-focused, & .text-field-input-props-root:hover': {
            ' & .text-field-input-props-notchedOutline': {
                borderColor: theme.palette.primary.heather80,
            },
            ' & .text-field-input-props-notchedOutline-invalid': {
                borderColor: theme.palette.primary.error,
            },
        },
    },

    resend: {
        marginBottom: '32px',
        maxWidth: '128px',
        maxHeight: '20px',
        textAlign: 'left',
        cursor: 'pointer',
        fontFamily: theme.typography.body2.fontFamily,
        fontWeight: 'normal',
        fontSize: theme.typography.body2.fontSize,
        lineHeight: theme.typography.body2.lineHeight,
        color: theme.palette.primary.heather,
        textDecoration: 'underline',
        letterSpacing: '0.4px',
        [theme.breakpoints.down('xs')]: {
            fontSize: theme.typography.overline.fontSize,
            marginBottom: '16px',
        },
    },

    reCaptcha: {
        paddingBottom: '32px',

        [theme.breakpoints.down('xs')]: {
            paddingTop: '16px',
            paddingBottom: '16px',
            transform: 'scale(0.77)',
            transformOrigin: '0 0',
        },
        '@media(min-width:600px) and (max-width:1019px)': {
            paddingBottom: '32px',
            // marginLeft: '75px',
        },
    },

    errormessage: {
        '& .invalid-messages': {
            fontSize: theme.typography.overline.fontSize,
            lineHeight: theme.typography.overline.lineHeight,
            letterSpacing: '0.4px',
            color: theme.palette.primary.error,
            marginTop: '-22px',
            fontFamily: theme.typography.overline.fontFamily,
            fontStyle: 'normal',
            fontWeight: theme.typography.overline.fontWeight,
            textAlign: 'left',
        },
    },
}));
